import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { personGrowthResourcesRoute } from 'routes';
import PersonGrowthResources from './PersonGrowthResources';
import NotFound from '../../NotFound';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_PERSONGROWTHRESOURCES } from '../../../rightsController';

class PersonGrowthResourcesRoutes extends Component {
    render() {
        return (
            <Switch>
                <ProtectedRoute
                    exact
                    path={personGrowthResourcesRoute.url}
                    component={PersonGrowthResources}
                    allowedRoles={ALLOWED_MODERATE_PERSONGROWTHRESOURCES}
                />
                <Route component={NotFound} />
            </Switch>
        );
    }
}

export default PersonGrowthResourcesRoutes;
