import React, { useState, memo } from 'react';
import { connect } from 'react-redux';
import InfoField from 'components/Lk/Uikit/InfoField';
import { formatDate } from 'utils.js';
import { Select } from 'components/Lk/Uikit/Select/Select';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import { WORK_DATE_FORMATE, DATE_CONFIG } from './constants';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox/StyledCheckBox';
import classnames from 'classnames';
import NoData from './NoData';
import { MENU_PROFILE_GOVERNMENT } from './constants';

const GovernmentInfo = props => {
    const {
        person,
        isWrap,
        setWrap,
        onChange,
        isEdit,
        governmentServiceKinds,
        setVisibleWrapSection,
    } = props;

    const {
        governmentServiceInfo: { governmentServices },
    } = person;

    const [currentId, setCurrentId] = useState(false);

    const currentRecord = governmentServices?.find(item => item.id === currentId) || {};

    const selectRecord = id => () => {
        setCurrentId(id);
        setWrap(true);
        setVisibleWrapSection(MENU_PROFILE_GOVERNMENT);
    };

    const onAdd = () => {
        const id = -new Date().getTime();
        const newRecord = { id };
        onChange(
            {
                ...person,
                governmentServiceInfo: { governmentServices: [...governmentServices, newRecord] },
            },
            'GovernmentServiceBlock',
        );
    };

    const onChangeState = name => value => {
        const newRecord = { ...currentRecord, [name]: value };

        const newRecords = governmentServices.map(item =>
            item.id === newRecord.id ? newRecord : item,
        );
        onChange(
            { ...person, governmentServiceInfo: { governmentServices: newRecords } },
            'GovernmentServiceBlock',
        );
    };

    const onRemove = id => e => {
        e.stopPropagation();
        onChange(
            {
                ...person,
                governmentServiceInfo: {
                    governmentServices: governmentServices.filter(a => a.id !== id),
                },
            },
            'GovernmentServiceBlock',
        );
    };

    const getPeriod = (hireDate, quitDate, isCurrent) =>{
        const start = hireDate && formatDate(hireDate, WORK_DATE_FORMATE) 
        const end = isCurrent ? 'наст. вр' : (quitDate &&formatDate(quitDate, WORK_DATE_FORMATE));

        return [start,end].filter(x=>x).join(' - ')
    
    }
        

    return (
        <div
            className={classnames('PersonalInfo', {
                'PersonalInfo--bottom-margin': isEdit || (isWrap && currentId !== false),
            })}
        >
            {isWrap && currentId !== false ? (
                <>
                    <InfoField
                        title="Тип службы"
                        value={currentRecord.governmentServiceKind?.name}
                        isEdit={isEdit}
                        editComponent={
                            <Select
                                items={governmentServiceKinds}
                                value={currentRecord.governmentServiceKind?.id}
                                onChange={onChangeState('governmentServiceKind')}
                            />
                        }
                        noIcon
                    />
                    <InfoField
                        title="Вид службы (род войск)"
                        isEdit={isEdit}
                        value={currentRecord.branch || ''}
                        onChange={onChangeState('branch')}
                        maxLength={250}
                        noIcon
                    />
                    <InfoField
                        title="Должность"
                        isEdit={isEdit}
                        value={currentRecord.position}
                        onChange={onChangeState('position')}
                        maxLength={250}
                        noIcon
                    />
                    <InfoField
                        title="Звание"
                        isEdit={isEdit}
                        value={currentRecord.rank}
                        maxLength={250}
                        onChange={onChangeState('rank')}
                        noIcon
                    />

                    <InfoField
                        title="Начало службы"
                        isEdit={isEdit}
                        inputProps={{ ...DATE_CONFIG }}
                        value={currentRecord.startDate}
                        type="date"
                        onChange={onChangeState('startDate')}
                        footer={
                            <StyledCheckBox
                                title="По настоящее время"
                                checked={currentRecord.isCurrent || false}
                                onChange={isEdit ? onChangeState('isCurrent') : undefined}
                            />
                        }
                        noIcon
                    >
                        {currentRecord.startDate &&
                            formatDate(currentRecord.startDate, WORK_DATE_FORMATE)}
                    </InfoField>
                    {!currentRecord.isCurrent && (
                        <InfoField
                            title="Окончание службы"
                            isEdit={isEdit}
                            inputProps={{ ...DATE_CONFIG }}
                            type="date"
                            value={currentRecord.endDate}
                            onChange={onChangeState('endDate')}
                            noIcon
                        >
                            {currentRecord.endDate &&
                                formatDate(currentRecord.endDate, WORK_DATE_FORMATE)}
                        </InfoField>
                    )}
                    <InfoField
                        title="Дополнительные сведения"
                        isEdit={isEdit}
                        value={currentRecord.extraInfo}
                        onChange={onChangeState('extraInfo')}
                        maxLength={450}
                        noIcon
                    />
                </>
            ) : (
                <>
                    {
                        (governmentServices.size && governmentServices.size !== 0) || (governmentServices.length && governmentServices.length !== 0)
                            ? governmentServices.map(
                                ({
                                    governmentServiceKind,
                                    rank,
                                    position,
                                    isCurrent,
                                    startDate,
                                    endDate,
                                    id,
                                }) => (
                                    <InfoField
                                        icon={
                                            isEdit && {
                                                icon: 'minus-circle',
                                                size: 'xs',
                                                color: 'red',
                                                onClick: onRemove(id),
                                            }
                                        }
                                        key={id}
                                        title={rank}
                                        onWrap={selectRecord(id)}
                                        footer={getPeriod(startDate, endDate, isCurrent)}
                                        noIcon={!isEdit}
                                    >
                                        {`${position || 'должность'}${(governmentServiceKind &&
                                            ` (${governmentServiceKind?.name})`) ||
                                            '(тип службы)'}`}
                                    </InfoField>
                                ),
                                )
                            : (!isEdit && <NoData text='Нет данных' />)
                    }
                    {isEdit && (
                        <InfoField noIcon>
                            <ProfileButton onClick={onAdd}>добавить</ProfileButton>
                        </InfoField>
                    )}
                </>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    governmentServiceKinds: state.catalogs.governmentServiceKinds.data,
});

export default connect(mapStateToProps)(memo(GovernmentInfo));
