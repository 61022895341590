import React, { PureComponent } from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { DataCard } from "components/common/DataCard";
import EditPersonnelInfoBlock from './Edit/PersonnelInfoBlock';
import { formatDate } from 'view';

function getCompetitionLevelText(competitionLevel) {
    return competitionLevel ? `(${competitionLevel.name})` : "";
}

class PersonnelInfoBlock extends PureComponent {
    renderAward = award => {
        
        return award.id && (
            <div key={award.id}>
                {award.name}
                {" "}
                {getCompetitionLevelText(award.selectedCompetitionLevel)}
            </div>
        );
    };

    renderAwards = (awards) =>
        <div className="kr_tr">
            <div className="kr_td">Награды / достижения</div>
            <div className="kr_td">
                {awards.map(this.renderAward)}
            </div>
        </div>;

    renderContent = () => {
        const {
            selectedDataOrigin,
            recommenderName,
            curatorName,
            selectedExperienceLevel,
            socialActivity,
            memberOfSocialOrganizations,
            socialAchivements,
            extraInfo,
            inclusionDate,
            inclusionReason,
            reserveLevel,
            totalManagementExperience,
            totalRegionSpentTime,
            awards,
            selectedManagerLevel,
            selectedGeoLevel,
            governmentWorkPositions,
        } = this.props;

        return (
            <React.Fragment>
                {socialActivity && (
                    <div className="kr_tr">
                        <div className="kr_td">Социальная активность</div>
                        <div className="kr_td">{socialActivity}</div>
                    </div>
                )}
                {memberOfSocialOrganizations && (
                    <div className="kr_tr">
                        <div className="kr_td">Членство в общественных организациях</div>
                        <div className="kr_td">{memberOfSocialOrganizations}</div>
                    </div>
                )}
                {socialAchivements && (
                    <div className="kr_tr">
                        <div className="kr_td">Деятельность в качестве руководителя</div>
                        <div className="kr_td">{socialAchivements}</div>
                    </div>
                )}
                {(awards && awards.length > 0) && this.renderAwards(awards)}
                {selectedDataOrigin && (
                    <div className="kr_tr">
                        <div className="kr_td">Источник данных</div>
                        <div className="kr_td">{selectedDataOrigin.name}</div>
                    </div>
                )}
                {selectedExperienceLevel && (
                    <div className="kr_tr">
                        <div className="kr_td">Уровень подготовленности участника</div>
                        <div className="kr_td">{selectedExperienceLevel.name}</div>
                    </div>
                )}
                {recommenderName && (
                    <div className="kr_tr">
                        <div className="kr_td">Рекомендатель</div>
                        <div className="kr_td">{recommenderName}</div>
                    </div>
                )}
                {curatorName && (
                    <div className="kr_tr">
                        <div className="kr_td">Куратор</div>
                        <div className="kr_td">{curatorName}</div>
                    </div>
                )}
                {selectedGeoLevel && (
                    <div className="kr_tr">
                        <div className="kr_td">Географический уровень</div>
                        <div className="kr_td">{selectedGeoLevel?.label}</div>
                    </div>
                )}
                {selectedManagerLevel && (
                    <div className="kr_tr">
                        <div className="kr_td">Уровень менеджмента</div>
                        <div className="kr_td">{selectedManagerLevel.label}</div>
                    </div>
                )}
                {inclusionDate && (
                    <div className="kr_tr">
                        <div className="kr_td">Дата включения в резерв</div>
                        <div className="kr_td">{formatDate(inclusionDate)}</div>
                    </div>
                )}
                {inclusionReason && (
                    <div className="kr_tr">
                        <div className="kr_td">Основание включения</div>
                        <div className="kr_td">{inclusionReason}</div>
                    </div>
                )}
                {totalManagementExperience > 0 ? (
                    <div className="kr_tr">
                        <div className="kr_td">Общий опыт руководящей работы</div>
                        <div className="kr_td">{totalManagementExperience}</div>
                    </div>
                ) : null}
                {totalRegionSpentTime > 0 ? (
                    <div className="kr_tr">
                        <div className="kr_td">Время проживания в регионе</div>
                        <div className="kr_td">{totalRegionSpentTime}</div>
                    </div>
                ) : null}
                {reserveLevel && (
                    <div className="kr_tr">
                        <div className="kr_td">Уровень резерва</div>
                        <div className="kr_td">{reserveLevel}</div>
                    </div>
                )}
                {governmentWorkPositions && (
                    <div className="kr_tr">
                        <div className="kr_td">Работа на выборных должностях</div>
                        <div className="kr_td">{governmentWorkPositions}</div>
                    </div>
                )}
                {extraInfo && (
                    <div className="kr_tr">
                        <div className="kr_td">Дополнительные сведения</div>
                        <div className="kr_td">{extraInfo}</div>
                    </div>
                )}
            </React.Fragment>
        );
    };

    renderView = () => {
        return (
            <DataCard
                shadow
                title="Дополнительная информация"
                className="kr_profile-anketa-view"
                onChange={this.props.canEditPerson ? () => this.props.setEditBlock('PersonnelInfoBlock') : null}
            >
                <div className="kr_like-table">
                    {this.props.selectedDataOrigin ? this.renderContent() : this.props.renderEmptyBlock()}
                </div>
            </DataCard>
        );
    };

    renderEdit = () => {
        return (
            <EditPersonnelInfoBlock {...this.props} setEditBlock={this.props.setEditBlock} />
        );
    };

    render() {
        return this.props.isEditBlock ? this.renderEdit() : this.renderView();
    }
}

export default ToggleOpen(PersonnelInfoBlock);
