import React from 'react';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import { MENU_ACTION } from './constants';

const SearchEventMenuItems = props => {
    const { disabledButtons, setMenu } = props;
    return (
        <>
            <IconButton
                className="EventMenuItem"
                disabled={disabledButtons}
                onClick={setMenu(MENU_ACTION)}
                type="settings"
            >
                <span className="EventMenuItem__Text">Действие</span>
            </IconButton>
        </>
    );
};

export default SearchEventMenuItems;