import React from "react";
import PropTypes from "prop-types";

function Form(props) {
    const {
        children,
        onSubmit = () => {},
        autoCompete = false,
        className = ""
    } = props;
    
    return (
        <form
            className={className}
            autoComplete={autoCompete ? "on" : "off"} 
            onSubmit={onSubmit}
        >
            {children}
        </form>
    );
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    autoComplete: PropTypes.bool,
    className: PropTypes.string,
};

export default Form;
