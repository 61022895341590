import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Popover from 'components/Lk/Uikit/Popover';

const ElepsisText = props => {
    const { className, children, alreadyHavePopover } = props;
    const spanRef = useRef();

    const [isTooltipVisible, setVisibleTooltip] = useState(false);
    const windowWidth = window.innerWidth;

    const onResize = useCallback(() => {
        if (!spanRef.current) {
            return;
        }
        setVisibleTooltip(spanRef.current.offsetWidth < spanRef.current.scrollWidth);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [windowWidth, onResize]);

    return (
        <>
            { isTooltipVisible && !alreadyHavePopover ? 
                <Popover tooltip={children} childrenClassName='ElepsisText'>
                    <span ref={spanRef} className={classnames('ElepsisText', className)}>
                        {children}
                    </span>
                </Popover> 
                :
                <span ref={spanRef} className={classnames('ElepsisText', className)}>
                    {children}
                </span> 
            }
        </>
    );
};

export default ElepsisText;
