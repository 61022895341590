import React, { useEffect, useState } from 'react';
import { DataCard } from 'components/Lk/Uikit/DataCard';
import Loader from 'components/common/Loader';
import { getUserShortName } from 'utils';
import NoDataBlock from 'components/Lk/Common/NoDataBlock';
import './GroupList.scss';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';
import { CHECKBOX_TYPES } from 'components/Lk/Uikit/StyledCheckBox/constants';
import classnames from 'classnames';
import PersonCard from 'components/Lk/Common/PersonCard';
import ModalSummaryCard from 'components/Lk/Common/ModalPersonSummaryCard';

const GroupList = props => {
    const {
        location,
        items,
        loading,
        loadPersons,
        isGroupLoading,
        onPersonSelect,
        isInCart,
        altCardText,
        withSelect,
        existScrollWrapper,
        className,
        noDataMsg,
        withCheckboxes,
        onCheckAll,
        selectedIds = [],
        type = 'personGrowth',
        isWishlist,
    } = props;

    const [modalSummary, setModalSummary] = useState(false);
    const openSummaryCard = person => () => setModalSummary(person);
    const closeSummaryCard = () => setModalSummary(false);

    const [personsIds, setPersonIds] = useState(null);

    useEffect(() => {
        let mounted = true;

        const getPersonIds = arr =>
            arr.reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr.teamId]: curr.persons
                        .filter(person =>
                            type === 'evaluate'
                                ? true
                                : !(
                                      person.progressPercent ||
                                      person.percent ||
                                      person.alreadyHavePersonGrowthPlan
                                  ),
                        )
                        .map(x => x.id),
                };
            }, {});

        if (items.length > 0) {
            const follIds = getPersonIds(items);
            mounted && setPersonIds(follIds);
        }

        return () => {
            mounted = false;
        };
    }, [items, type]);

    const canSelect =
        (location.state !== undefined && location.state ? location.state.canSelect : false) ||
        withSelect;

    const onCheckTeam = persons => (check, e) => {
        const checkPersons = persons.filter(person => filterPersonByPersonGrowth(person));
        e.stopPropagation();
        onCheckAll(checkPersons, check);
    };

    const renderTeam = team => {
        const personsInTeam = team.entriesCount || team.persons.length;

        const personIds = personsIds[team.teamId];

        const iconsStartFrom = +(
            personIds.some(x => selectedIds.indexOf(x) >= 0) && personIds.length !== 0
        );

        const checkboxes = CHECKBOX_TYPES.TRIPLE.slice(iconsStartFrom);

        const personsInTeamChecked = personIds.filter(x => selectedIds.indexOf(x) >= 0).length;

        const teamPersons = team.persons;

        const getTitle = group => {
            if (isWishlist && group.isDefault) {
                return `Моё избранное  (${personsInTeam})`;
            }

            return group.teamName ? (
                <div>
                    {group.teamName} ({personsInTeam})
                </div>
            ) : (
                (isWishlist ? 'Подборка' : `Команда`) + ` (${personsInTeam})`
            );
        };

        const titleCheckBox = (
            <>
                {canSelect && onCheckAll && (
                    <StyledCheckBox
                        checkboxes={checkboxes}
                        onChange={onCheckTeam(teamPersons, team.teamId)}
                        checked={
                            personIds.every(x => selectedIds.indexOf(x) >= 0) &&
                            personIds.length !== 0
                        }
                        disabled={personIds.length === 0}
                    />
                )}
            </>
        );

        const teamTitle = (
            <div className="LkGroupCheckBoxField">
                <div className="LKGroupTitle">{getTitle(team)}</div>
                {canSelect && onCheckAll && (
                    <div className="LKGroupTitle__PersonsInGroupChecked">
                        {personsInTeamChecked}
                    </div>
                )}
            </div>
        );

        const teamSubTitle = (
            <div className="LKGroupSubTitle">
                <div className="LKGroupSubTitle__Data">
                    <div>Выбрано: {personsInTeamChecked}</div>
                </div>
            </div>
        );

        return (
            <DataCard
                key={team.teamId}
                title={teamTitle}
                titleCheckbox={titleCheckBox}
                titleModifire="group"
                unwrap={loadPersons && loadPersons(team.teamId)}
                loading={team.persons.length === 0 && isGroupLoading}
                className={className}
                chevronClassName={classnames({
                    LKDataCard__ChevronButton: canSelect && withCheckboxes,
                })}
            >
                {renderPersons(team, teamSubTitle)}
            </DataCard>
        );
    };

    const renderSubTitle = subTitle => {
        return <> {withCheckboxes && canSelect && subTitle} </>;
    };

    const renderPersons = (item, teamSubTitle) => {
        if (item.persons && item.persons.length !== 0) {
            return (
                <>
                    {renderSubTitle(teamSubTitle)}
                    {item.persons.map((x, index) => renderPerson(x, index, teamSubTitle))}
                </>
            );
        } else {
            return <div className="LKMainTeams__NoTeamData">{noDataMsg}</div>;
        }
    };

    const renderPerson = x => {
        const selectPerson = (canSelect && checkIfSelectionIsInvalid(x) && onPersonSelect(x.id, x)) || openSummaryCard(x);
        return (
            <PersonCard key={x.id} onClick={selectPerson}>
                <PersonCard.CardAvatar
                    id={x.id}
                    isCheckable={!loading && canSelect && checkIfSelectionIsInvalid(x)}
                    isChecked={(canSelect || withSelect) && !!isInCart(x.id)}
                    onClick={selectPerson}
                />
                <PersonCard.CardInfo>
                    <PersonCard.CardTitle title={getUserShortName(x)} />
                    <PersonCard.CardInfoText
                        text={altCardText ? altCardText(x) : x.currentPosition || x.currentJobTitle}
                    />
                </PersonCard.CardInfo>
            </PersonCard>
        );
    };

    const renderGroups = () => {
        return (
            <>
                {items.length !== 0 && !!personsIds ? (
                    <div className={existScrollWrapper ? null : 'LKScrollWrapper__Groups'}>
                        {items.map(x => renderTeam(x))}
                    </div>
                ) : (
                    <NoDataBlock text="Нет данных" />
                )}
                <ModalSummaryCard
                    isOpen={!!modalSummary}
                    onClose={closeSummaryCard}
                    person={modalSummary}
                    profileRoute={props.personProfileUrl}
                />
            </>
        );
    };

    const checkIfSelectionIsInvalid = person => {
        if (location.state && !location.state.canSelect && !withSelect) {
            return false;
        }

        return filterPersonByPersonGrowth(person);
    };

    const filterPersonByPersonGrowth = person => {
        return type === 'evaluate'
            ? true
            : !(person.progressPercent || person.percent || person.alreadyHavePersonGrowthPlan);
    };

    return loading ? <Loader /> : renderGroups();
};

export default GroupList;
