import React, {useState} from 'react';
import ResourceCard from 'components/Lk/Shared/Profile/Progress/ResourceCard';
import Label from 'components/uikit/Label';
import classnames from 'classnames';
import LazyLoad from 'react-lazy-load';
import Loader from 'components/common/Loader';

const ResourceItem = props => {
    const { item, checked, onClick, mobile } = props;
    const [isVisible, setVisible] = useState(false);

    const clickHandler = e => {
        e.stopPropagation();
        onClick(item, !checked);
    };

    const onVisible = () => setVisible(true);

    return (
        <div
            className={classnames('LKProgressResourceList__Item', {
                'LKProgressResourceList__Item--mobile': mobile,
            })}
            onClick={clickHandler}
        >
            {!isVisible  && <Loader style={{zIndex:9}} absolute/>}
            <LazyLoad onContentVisible={onVisible} throttle={100} width="100%">
                <div className="LKProgressResourceList__Wrapper">
                    <ResourceCard
                        className={classnames('LKProgressResourceList__Card', {
                            'LKProgressResourceList__Card--mobile': mobile,
                        })}
                        mainClass="LKProgressResourceCard"
                        itemId={item.id}
                        name={item.name}
                        mobile={mobile}
                    />

                    <input type="checkbox" checked={checked} readOnly hidden />
                    <Label className="LKProgressResourceList__CheckBox"></Label>
                </div>
            </LazyLoad>
        </div>
    );
};

export default ResourceItem;
