import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    fullTextSearchPersons,
    fullTextSearchSelect,
    fullTextSearchAmount,
    fullTextSearchSelectWithProgress,
} from '../api';
import { appName } from '../constants';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';
import { withPageLoader } from './PageLoader';
import { INDUSTRIES, WORK_AREAS, REGIONS, COMPETITION_LEVELS } from './Catalog';
import { serviceResultCode, getError } from 'serviceErrors';
import { getAge } from 'utils';

const moduleName = 'external-search';
export const SEARCH = `${appName}/${moduleName}/SEARCH`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;

export const FETCH_PROFILES = `${appName}/${moduleName}/FETCH_PROFILES`;
export const SET_PROFILES = `${appName}/${moduleName}/SET_PROFILES`;

export const FETCH_SELECT_START = `${appName}/${moduleName}/FETCH_SELECT_START`;
export const FETCH_SELECT_SUCCESS = `${appName}/${moduleName}/FETCH_SELECT_SUCCESS`;
export const FETCH_SELECT_FAILED = `${appName}/${moduleName}/FETCH_SELECT_FAILED`;

export const FETCH_AMOUNT_START = `${appName}/${moduleName}/FETCH_AMOUNT_START`;
export const FETCH_AMOUNT_SUCCESS = `${appName}/${moduleName}/FETCH_AMOUNT_SUCCESS`;
export const FETCH_AMOUNT_FAILED = `${appName}/${moduleName}/FETCH_AMOUNT_FAILED`;

export const FETCH_CATEGORIES = `${appName}/${moduleName}/FETCH_CATEGORIES`;
export const SET_CATEGORIES = `${appName}/${moduleName}/SET_CATEGORIES`;

export const FETCH_SELECT_WITH_PROGRESS_START = `${appName}/${moduleName}/FETCH_SELECT_WITH_PROGRESS_START`;

export const UPDATE_PERSON_DATA = `${appName}/${moduleName}/UPDATE_PERSON_DATA`;

export const CLEAR_SEARCH = `${appName}/${moduleName}/CLEAR_SEARCH`;

export const SET_SEARCH_DATA = `${appName}/${moduleName}/SET_SEARCH_DATA`;

export const CHANGE_PERSON_DATA = `${appName}/${moduleName}/CHANGE_PERSON_DATA`;

export const REFRESH_PERSONS = `${appName}/${moduleName}/REFRESH_PERSONS`;

export const SET_CRITERIA = `${appName}/${moduleName}/SET_CRITERIA`;

export const CHANGE_PERSONS_DATA = `${appName}/${moduleName}/CHANGE_PERSONS_DATA`;

const profiles = [
    { title: 'Политики', count: 2884 },
    { title: 'Государственные служащие', count: 43005 },
    { title: 'Бизнес-управленцы', count: 18 },
    { title: 'Судьи', count: 5 },
];

const searchCategories = [
    {
        id: 'catAge',
        name: 'Возраст',
        isOpen: false,
        items: [
            {
                code: 'Age',
                title: 'Возраст',
                fields: [
                    { code: 'fullYears', title: 'Кол-во лет', type: 'Number' },
                    { code: 'birthDate', title: 'Дата рождения', type: 'Date' },
                ],
            },
        ],
    },
    {
        id: 'catJob',
        name: 'Профессия',
        isOpen: false,
        items: [
            {
                code: 'Job',
                title: 'Профессия',
                fields: [
                    {
                        code: 'specialization',
                        title: 'Проф. специализация',
                        type: 'Select',
                        dict: WORK_AREAS,
                    },
                ],
            },
        ],
    },
    {
        id: 'catIndustry',
        name: 'Отрасль',
        isOpen: false,
        items: [
            {
                code: 'Industry',
                title: 'Отрасль',
                fields: [
                    {
                        code: 'industry',
                        title: 'Отраслевая специализация ',
                        type: 'Select',
                        dict: INDUSTRIES,
                    },
                ],
            },
        ],
    },
    {
        id: 'catRegion',
        name: 'Связь с регионами',
        isOpen: false,
        items: [
            {
                code: 'Region',
                title: 'Связь с регионами',
                fields: [
                    {
                        code: 'birthDayPlace',
                        title: 'Место рождения',
                        type: 'Select',
                        dict: REGIONS,
                    },
                    {
                        code: 'livingPlace',
                        title: 'Место проживания',
                        type: 'Select',
                        dict: REGIONS,
                    },
                    {
                        code: 'workPlace',
                        title: 'Место работы',
                        type: 'Select',
                        dict: REGIONS,
                    },
                ],
            },
        ],
    },
    {
        id: 'catCompany',
        name: 'Связь с организациями',
        isOpen: false,
        items: [
            {
                code: 'Company',
                title: 'Связь с организациями',
                fields: [],
            },
        ],
    },
    {
        id: 'catCompetence',
        name: 'Компетенции',
        isOpen: false,
        items: [
            {
                code: 'Competence',
                title: 'Компетенции',
                fields: [
                    {
                        code: 'competence',
                        title: 'Лидерство',
                        type: 'Select',
                        dict: COMPETITION_LEVELS,
                    },
                    {
                        code: 'competence',
                        title: 'Управление',
                        type: 'Select',
                        dict: COMPETITION_LEVELS,
                    },
                    {
                        code: 'competence',
                        title: 'Исполнение',
                        type: 'Select',
                        dict: COMPETITION_LEVELS,
                    },
                ],
            },
        ],
    },
    {
        id: 'catSocial',
        name: 'Общественная деятельность',
        isOpen: false,
        items: [
            {
                code: 'Social',
                title: 'Общественная деятельность',
                fields: [],
            },
        ],
    },
    {
        id: 'catManagement',
        name: 'Управленческий уровень',
        items: [
            {
                code: 'Management',
                title: 'Управленческий уровень',
                fields: [],
            },
        ],
    },
];

const initialState = {
    loading: false,
    loadComplete: false,
    totalAmount: null,
    data: {
        payload: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
    criteria: {
        hasMore: true,
        paging: { pageNum: 1, pageSize: 10 },
        sorters: [],
    },
    searchData: [],
    profiles: [],
    categories: [],
    error: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_START:
        case FETCH_SELECT_START:
        case FETCH_AMOUNT_START:
        case FETCH_SELECT_WITH_PROGRESS_START:
            return {
                ...state,
                loading: true,
                loadComplete: false,
                error: '',
                criteria: { ...state.criteria, hasMore: false },
            };

        case FETCH_SUCCESS:
            return {
                ...state,
                loadComplete: true,
                loading: false,
                data: payload.data,
                totalAmount: payload.data.meta.foundCount,
                criteria: {
                    ...state.criteria,
                    hasMore:
                        payload.data.meta.pageCount > state.criteria.paging.pageNum ? true : false,
                },
            };

        case SET_CRITERIA:
            return {
                ...state,
                criteria: payload.isMerge
                    ? { ...state.criteria, ...payload.criteria }
                    : payload.criteria,
            };

        case UPDATE_PERSON_DATA:
            const newState = {
                ...state,
                data: {
                    ...state.data,
                    payload: state.data.payload.map((p) =>
                        p.id === payload.person.id
                            ? {
                                  ...p,
                                  ...payload.person,
                              }
                            : p,
                    ),
                },
            };
            return newState;

        case FETCH_FAILED:
        case FETCH_SELECT_FAILED:
        case FETCH_AMOUNT_FAILED:
            return {
                ...state,
                loadComplete: true,
                loading: false,
                error: error.message,
            };

        case SET_PROFILES:
            return { ...state, profiles: payload };

        case SET_CATEGORIES:
            return { ...state, categories: payload };

        case SET_SEARCH_DATA:
            return {
                ...state,
                searchData: payload.isMerge ? state.searchData.concat(payload.data) : payload.data,
            };

        case CHANGE_PERSON_DATA:
            return {
                ...state,
                searchData: state.searchData.map((p) =>
                    p.id === payload?.id
                        ? {
                              ...p,
                              ...payload,
                          }
                        : p,
                ),
            };

        case CHANGE_PERSONS_DATA:
            return {
                ...state,
                searchData: state.searchData.map((el) => ({
                    ...el,
                    ...payload.find((s) => s.personId === el.id),
                })),
            };

        case REFRESH_PERSONS:
            return {
                ...state,
                searchData: state.searchData.map((p) =>
                    payload.includes(p.id)
                        ? {
                              ...p,
                              updated: new Date(),
                          }
                        : p,
                ),
            };

        case FETCH_SELECT_SUCCESS:
            return {
                ...state,
                loadComplete: true,
                loading: false,
                data: {
                    ...payload,
                    payload: payload.payload.map((x) => ({ ...x, age: getAge(x.birthDate) })),
                },
                totalAmount: payload.meta.foundCount,
                criteria: {
                    ...state.criteria,
                    hasMore: payload.meta.pageCount > state.criteria.paging.pageNum ? true : false,
                },
            };

        case FETCH_AMOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                loadComplete: true,
                totalAmount: payload,
            };
        case CLEAR_SEARCH:
            return { ...initialState };
        default:
            return state;
    }
}

export const search = (criteria) => {
    return {
        type: SEARCH,
        payload: { criteria },
    };
};

const fetchStart = () => {
    return {
        type: FETCH_START,
    };
};

const fetchSuccess = (data) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data },
    };
};

const fetchFailed = (error) => {
    return {
        type: FETCH_FAILED,
        error,
    };
};

export const setCriteria = (criteria, isMerge = true) => ({
    type: SET_CRITERIA,
    payload: { criteria, isMerge },
});

export const updatePersonData = (person) => {
    return {
        type: UPDATE_PERSON_DATA,
        payload: { person },
    };
};

export const changePersonsData = (payload) => {
    return {
        type: CHANGE_PERSONS_DATA,
        payload,
    };
};

export const refreshSearchPersons = (payload = []) => ({ type: REFRESH_PERSONS, payload });

export const clearSearch = () => ({ type: CLEAR_SEARCH });

export const setSearchData = (data, isMerge = true) => ({
    type: SET_SEARCH_DATA,
    payload: { data, isMerge },
});

export const changePersonData = (person) => {
    return {
        type: CHANGE_PERSON_DATA,
        payload: person,
    };
};

export const fetchCategories = () => ({ type: FETCH_CATEGORIES });
const setCategories = (payload) => ({ type: SET_CATEGORIES, payload });

export const fetchProfiles = () => ({ type: FETCH_PROFILES });

const setProfiles = (payload) => ({
    type: SET_PROFILES,
    payload,
});

const fetchSelectSuccess = (payload) => ({ type: FETCH_SELECT_SUCCESS, payload });
const fetchSelectFiled = (error) => ({ type: FETCH_SELECT_FAILED, error });

export const fetchSelect = (criteria) => {
    return {
        type: FETCH_SELECT_START,
        payload: criteria,
    };
};

export const fetchSelectWithProgress = (criteria) => {
    return {
        type: FETCH_SELECT_WITH_PROGRESS_START,
        payload: criteria,
    };
};

const fetchAmountSuccess = (payload) => ({ type: FETCH_AMOUNT_SUCCESS, payload });
const fetchAmountFiled = (error) => ({ type: FETCH_AMOUNT_FAILED, error });

export const fetchAmount = (criteria) => {
    return {
        type: FETCH_AMOUNT_START,
        payload: criteria,
    };
};

export const searchForPersons = async (criteria) => {
    try {
        const response = await fullTextSearchPersons(criteria);
        return {
            ok: {
                payload: response,
            },
        };
    } catch (error) {
        return {
            error: {
                message: 'При поиске произошла ошибка',
                payload: error,
            },
        };
    }
};

export const searchForPersonsFilter = async (criteria) => {
    try {
        const response = await fullTextSearchSelect(criteria);
        return {
            ok: {
                payload: response,
            },
        };
    } catch (error) {
        return {
            error: {
                message: 'При поиске произошла ошибка',
                payload: error,
            },
        };
    }
};

function* searchSaga(action) {
    yield put(fetchStart());

    const { criteria } = action.payload;

    const response = yield call(withPageLoader, async () => await searchForPersons(criteria));
    if (response.ok) {
        yield put(fetchSuccess(response.ok.payload.data));
    } else {
        const reqError = new RequestError(response.error.payload, response.error.message);
        yield all([put(fetchFailed(reqError)), put(showErrorAlert(reqError.message))]);
    }
}

function* fetchProfilesSaga() {
    const response = yield call(withPageLoader, () => profiles); //TODO api

    yield put(setProfiles(response));
}

function* fetchCategoriesSaga() {
    const response = yield call(withPageLoader, () => searchCategories); //TODO api

    yield put(setCategories(response));
}

function* fetchSelectSaga(action) {
    try {
        const response = yield call(withPageLoader, () => fullTextSearchSelect(action.payload));
        yield put(fetchSelectSuccess(response.data));
    } catch (error) {
        const reqError = getError(error, getFetchError());

        yield put(fetchSelectFiled(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

function* fetchSelectWithProgressSaga(action) {
    try {
        const response = yield call(withPageLoader, () =>
            fullTextSearchSelectWithProgress(action.payload),
        );
        yield put(fetchSelectSuccess(response.data));
    } catch (error) {
        const reqError = getError(error, getFetchError());

        yield put(fetchSelectFiled(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

function* fetchAmountSaga(action) {
    try {
        const response = yield call(withPageLoader, () => fullTextSearchAmount(action.payload));
        yield put(fetchAmountSuccess(response.data));
    } catch (error) {
        const reqError = getError(error, getFetchError());

        yield put(fetchAmountFiled(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

const getFetchError = () => (code) => {
    switch (code) {
        case serviceResultCode.QueryGeneratorCategoryNotFound:
            return 'Категория не найдена';
        case serviceResultCode.QueryGeneratorFieldNotFound:
            return 'Поле не найдено';
        case serviceResultCode.QueryGeneratorOperationNotFound:
            return 'Операция не найдена';
        case serviceResultCode.QueryGeneratorUnacceptableOperation:
            return 'Недопустимая операция';
        case serviceResultCode.QueryGeneratorJoinOperationNotFound:
            return 'Недопустимая операция соединения';
        case serviceResultCode.QueryGeneratorEmptyValue:
            return 'Указано пустое значение';
        case serviceResultCode.QueryGeneratorWrongValue:
            return 'Указано неверное значение';
        default:
            return 'Произошла непредвиденная ошибка';
    }
};

export function* saga() {
    yield all([
        takeLatest([SEARCH], searchSaga),
        takeEvery([FETCH_PROFILES], fetchProfilesSaga),
        takeEvery([FETCH_CATEGORIES], fetchCategoriesSaga),
        takeLatest([FETCH_SELECT_START], fetchSelectSaga),
        takeLatest([FETCH_AMOUNT_START], fetchAmountSaga),
        takeLatest([FETCH_SELECT_WITH_PROGRESS_START], fetchSelectWithProgressSaga),
    ]);
}
