import classnames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    deleteTeam as deleteTeamApi,
    getTeamPersonSummary,
    putTeam as putTeamApi,
    printTeamReport,
    generateTeamReport,
} from 'api';
import { isNullOrWhitespace } from 'utils';
import { Card, CardLine } from 'components/common/Card';
import ModalDialog from 'components/common/ModalDialog';
import PersonSearchSelect from 'components/common/PersonSearchSelect';
import { useRefState } from 'components/hooks';
import Button from 'components/uikit/Button';
import ChevronButton from 'components/uikit/ChevronButton';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { serviceResultCode, getValidationErrors } from 'serviceErrors';
import PersonShortInfo from './PersonShortInfo';
import { mapTeamToWriteModel } from './common';
import './Team.scss';
import ContactIcons from 'components/uikit/ContactIcons';
import RoundButton from 'components/uikit/Button/RoundButton';
import Badge from 'components/uikit/Badge';
import Media from 'react-media';
import { mediaScreenMinThresholds } from 'constants.js';
import Field from 'components/uikit/Field';
import Input from 'components/uikit/Input';
import Label from 'components/uikit/Label';

const changeTypes = {
    saving: 'saving',
    deleting: 'deleting',
};

const Team = props => {
    const {
        dndKey = '',
        team,
        onTeamChanged = () => {},
        onTeamDeleted = () => {},
        onError = () => {},
        onWarning = () => {},
    } = props;

    const { id, leaderPerson: leader, followerPersons: followers } = team;
    const droppableId = `team-followers-${id}-${dndKey}`;

    const [isOpen, setIsOpen] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [changeType, setChangeType] = useState(changeTypes.saving);
    const [isChanging, isChangingRef, setIsChanging] = useRefState(false);
    const [editedTeam, setEditedTeam] = useState(team);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [isDeletionModalOpen, setDeletionModalOpen] = useState(false);
    const [isSaveModalOpen, setSaveModalOpen] = useState(false);
    const [buildReport, setBuildReportState] = useState(false);

    const currentFollowers = isEditing ? editedTeam.followerPersons : followers;

    const selectPersonIds = useMemo(() => {
        return [leader.id].concat(currentFollowers.map(f => f.id));
    }, [ leader, currentFollowers]);

    const isSelectedPersonPresent = useMemo(
        () => selectedPerson && selectPersonIds.indexOf(selectedPerson.value.id) >= 0,
        [selectedPerson,selectPersonIds],
    );

    const toggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    const startEditing = useCallback(() => {
        setIsEditing(true);
        setEditedTeam(team);
    }, [team]);

    const onPrint = id => async () => {
        setBuildReportState(true);
        try {
            const response = await generateTeamReport(id);
            if (!response.data.fileId) {
                return;
            }

            const downloadLink = printTeamReport(response.data.fileId);
            window.location = downloadLink;
        } catch (err) {
            const [error] = getValidationErrors(err);
            showErrorAlert(error?.message || 'Не удалось сгенерировать PDF');
        } finally {
            setBuildReportState(false);
        }
    };

    const cancelEditing = useCallback(() => {
        setIsEditing(false);
        setEditedTeam(team);
    }, [team]);

    const deleteTeam = useCallback(async () => {
        if (isChangingRef && isChangingRef.current) {
            return;
        }

        setChangeType(changeTypes.deleting);
        setIsChanging(true);
        try {
            await deleteTeamApi(team.id);
            setIsEditing(false);
            onTeamDeleted(team.id);
        } catch (error) {
            const errorCode = error.response && error.response.data && error.response.data.code;
            switch (errorCode) {
                case serviceResultCode.NotFound:
                    setIsEditing(false);
                    onTeamDeleted(team.id);
                    break;
                default:
                    onError('Произошла непредвиденная ошибка при удалении команды резервиста');
            }
        } finally {
            setIsChanging(false);
            setDeletionModalOpen(false);
        }
    }, [team, onTeamDeleted, isChangingRef, setIsChanging, onError]);

    const saveTeam = useCallback(async () => {
        if (isChangingRef && isChangingRef.current) {
            return;
        }

        if (isNullOrWhitespace(editedTeam.name)) {
            onWarning('Необходимо заполнить имя команды');
            return;
        }

        setChangeType(changeTypes.saving);
        setIsChanging(true);
        try {
            const teamWriteModel = mapTeamToWriteModel(editedTeam);
            const response = await putTeamApi(teamWriteModel);
            setIsEditing(false);
            onTeamChanged(response.data);
        } catch (error) {
            const errorCode = error.response && error.response.data && error.response.data.code;
            switch (errorCode) {
                case serviceResultCode.ConcurrencyError:
                    onWarning(
                        'Команда резервиста была изменена другим пользователем. Пожалуйста, обновите страницу и внесите изменения повторно',
                    );
                    break;
                case serviceResultCode.InvalidTeamName:
                    onError('Имя команды не может быть пустым или превышать 250 символов');
                    break;
                default:
                    onError('Произошла непредвиденная ошибка при сохранении команды резервиста');
            }
        } finally {
            setIsChanging(false);
            setSaveModalOpen(false);
        }
    }, [editedTeam, onTeamChanged, isChangingRef, setIsChanging, onWarning, onError]);

    const addFollower = useCallback(async () => {
        if (isChangingRef && isChangingRef.current) {
            return;
        }

        try {
            const response = await getTeamPersonSummary(selectedPerson.value.id);
            setEditedTeam({
                ...editedTeam,
                followerPersons: [...editedTeam.followerPersons, response.data],
            });
            setSelectedPerson(null);
        } catch (error) {
            onError('Произошла непредвиденная ошибка при загрузке данных резервиста');
        }
    }, [selectedPerson, editedTeam, isChangingRef, onError]);

    const removeFollower = useCallback(
        index => () => {
            if (isChangingRef && isChangingRef.current) {
                return;
            }

            setEditedTeam({
                ...editedTeam,
                followerPersons: editedTeam.followerPersons.filter((_, i) => i !== index),
            });
        },
        [editedTeam, isChangingRef],
    );

    const onDragEnd = useCallback(
        ({ source, destination }) => {
            if (isChangingRef && isChangingRef.current) {
                return;
            }

            if (
                !source ||
                !destination ||
                source.droppableId !== droppableId ||
                source.droppableId !== destination.droppableId
            ) {
                return;
            }

            if (source.index === destination.index) {
                return;
            }

            const newFollowers = [...editedTeam.followerPersons];
            newFollowers.splice(source.index, 1);
            newFollowers.splice(destination.index, 0, editedTeam.followerPersons[source.index]);

            setEditedTeam({ ...editedTeam, followerPersons: newFollowers });
        },
        [droppableId, editedTeam, isChangingRef],
    );

    const toggleDeletionModal = useCallback(() => setDeletionModalOpen(!isDeletionModalOpen), [
        isDeletionModalOpen,
    ]);

    const toggleSaveModal = useCallback(() => setSaveModalOpen(!isSaveModalOpen), [
        isSaveModalOpen,
    ]);

    const renderTeamTitle = () => {
        if (!props.isPersonLeader) {
            return <PersonShortInfo person={leader} />;
        }

        if (isEditing) {
            return (
                <div className="TeamHeader__Title">
                    <Field className="TeamHeader__TeamName">
                        <Label className="TeamHeader__Label" htmlFor="teamName">
                            Название команды
                        </Label>
                        <Input
                            className="TeamHeader__Input"
                            id="teamName"
                            value={editedTeam.name}
                            onChange={e => setTeamName(e)}
                        />
                    </Field>
                </div>
            );
        }

        return <div className="TeamHeader__Title">{team.name || 'Название команды'}</div>;
    };

    const setTeamName = e => {
        setEditedTeam({
            ...editedTeam,
            name: e.target.value,
        });
    };

    const renderHeader = () => {
        return (
            <div className="TeamHeader">
                <div
                    className={
                        !isEditing
                            ? 'TeamHeader__Content'
                            : 'TeamHeader__Content TeamHeader__Content--edit'
                    }
                >
                    {renderTeamTitle()}
                    <div
                        className={
                            !isEditing
                                ? 'TeamHeaderControls'
                                : 'TeamHeaderControls TeamHeaderControls--edit'
                        }
                    >
                        <div className="TeamHeaderControls__Badge">
                            {props.isLeaderPage ? (
                                <div className="TeamHeader__Icons">
                                    <ContactIcons showMail={true} showPhone={false} />
                                    <div className="TeamHeaderBadge__Count">
                                        <div className="TeamHeaderBadge__Text">
                                            {currentFollowers.length} чел.&nbsp;в&nbsp;команде
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Badge className="TeamHeaderBadge">
                                    <div className="TeamHeaderBadge__Count">
                                        {currentFollowers.length}
                                    </div>
                                    <Media query={mediaScreenMinThresholds.xlarge}>
                                        {matches =>
                                            matches && (
                                                <div className="TeamHeaderBadge__Text">
                                                    чел.&nbsp;в&nbsp;команде
                                                </div>
                                            )
                                        }
                                    </Media>
                                </Badge>
                            )}
                        </div>

                        {!isEditing && (
                            <div className="TeamHeaderControls__ChevronButton">
                                <ChevronButton isUp={isOpen} onClick={toggleIsOpen} />
                            </div>
                        )}
                        {props.isLeaderPage && props.isPersonLeader && (
                            <div className="TeamHeaderControls__RoundButton">
                                <RoundButton
                                    id="btnEdit"
                                    type="edit"
                                    onClick={() => {
                                        if (isOpen) {
                                            startEditing();
                                        } else {
                                            toggleIsOpen();
                                            startEditing();
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderDraggableFollowerCard = (key, index, draggingOverWith, follower) => {
        return (
            <Draggable
                key={key}
                draggableId={key}
                index={index}
                isDragDisabled={!isEditing || isChanging}
            >
                {(provided, snapshot) => (
                    <div
                        className="TeamFollowerCard"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <Card
                            className="TeamFollowerCard__Card"
                            isDraggable={isEditing}
                            isClosable={isEditing}
                            onClose={removeFollower(index)}
                            fade={snapshot.isDragging && !draggingOverWith}
                            dragHandleProps={provided.dragHandleProps}
                        >
                            <CardLine>
                                <div className="TeamFollowerBlock">
                                    <PersonShortInfo person={follower} />
                                    {!isEditing && props.isLeaderPage && (
                                        <ContactIcons
                                            showMail={true}
                                            showPhone={true}
                                            iconSize={'2x'}
                                            number={follower && follower.phone}
                                            email={follower && follower.email}
                                            tooltipId={follower.id}
                                        />
                                    )}
                                </div>
                            </CardLine>
                        </Card>
                    </div>
                )}
            </Draggable>
        );
    };

    const renderFollowers = () => {
        if (currentFollowers.length === 0) {
            return null;
        }

        return (
            <div className="TeamFollowersContainer">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId={droppableId}>
                        {(provided, snapshot) => (
                            <div
                                className="TeamFollowers"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {currentFollowers.map((follower, index) =>
                                    renderDraggableFollowerCard(
                                        follower.id,
                                        index,
                                        snapshot.draggingOverWith,
                                        follower,
                                    ),
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    };

    const renderFooter = () => {
        const renderRightButtons = () => {
            if (isEditing) {
                return (
                    <div className="TeamFooterButtons__Item TeamFooterRightButtons">
                        <Button
                            className="TeamFooterRightButtons__Button ConfirmButton"
                            color="primary"
                            size="md"
                            onClick={toggleSaveModal}
                            disabled={isChanging}
                        >
                            Применить
                        </Button>
                        <Button
                            className="TeamFooterRightButtons__Button"
                            color="light_primary"
                            size="md"
                            onClick={cancelEditing}
                            disabled={isChanging}
                        >
                            Отмена
                        </Button>
                    </div>
                );
            }
            return (
                <div className="TeamFooterButtons__Item TeamFooterRightButtons">
                    <>
                        {!!props.isPersonLeader && props.canEditTeam && (
                            <Button
                                className="TeamFooterRightButtons__Button"
                                color="primary"
                                size="md"
                                onClick={startEditing}
                            >
                                Редактировать
                            </Button>
                        )}
                        <Button
                            className="TeamFooterRightButtons__Button"
                            color="primary"
                            size="md"
                            onClick={onPrint(team.id)}
                            loading={buildReport}
                        >
                            Командный отчет
                        </Button>
                    </>
                </div>
            );
        };

        const buttonsClassName = classnames('TeamFooterButtons', {
            'TeamFooterButtons--Editing': isEditing,
        });

        return (
            <div className="TeamFooter">
                {isEditing && (
                    <div className="TeamFooter__Container">
                        <div className="TeamFooterSearch">
                            <PersonSearchSelect
                                className="TeamFooterSearch__Input"
                                onChange={setSelectedPerson}
                                value={selectedPerson}
                                disabled={isChanging}
                                filter={{excludePersonIds:selectPersonIds}}
                                placeholderTitle={'Найти члена команды'}
                            />
                            <Button
                                className="TeamFooterSearch__Button"
                                size="md"
                                onClick={addFollower}
                                disabled={!selectedPerson || isSelectedPersonPresent || isChanging}
                            >
                                Добавить
                            </Button>
                        </div>
                    </div>
                )}
                <div className="TeamFooter__Container">
                    <div className={buttonsClassName}>
                        {isEditing && (
                            <Button
                                className="TeamFooterButtons__Item RemoveTeamButton"
                                color="danger"
                                size="md"
                                onClick={toggleDeletionModal}
                                disabled={isChanging}
                            >
                                Удалить команду
                            </Button>
                        )}
                        {renderRightButtons()}
                    </div>
                </div>
            </div>
        );
    };

    const renderModals = () => {
        return (
            <div>
                <ModalDialog
                    onClick={deleteTeam}
                    onCloseModal={toggleDeletionModal}
                    modalOpen={isDeletionModalOpen}
                    modalHeader="Удаление команды"
                    isDangerHeader={true}
                    btnOktext="Да"
                    btnOkColor="danger"
                    btnCanceltext="Нет"
                    processing={isChanging && changeType === changeTypes.deleting}
                    size="lg"
                >
                    Вы действительно хотите удалить команду?
                </ModalDialog>
                <ModalDialog
                    onClick={saveTeam}
                    onCloseModal={toggleSaveModal}
                    modalOpen={isSaveModalOpen}
                    modalHeader="Изменение команды"
                    btnOktext="Да"
                    btnOkColor="primary"
                    btnCanceltext="Нет"
                    processing={isChanging && changeType === changeTypes.saving}
                    size="lg"
                >
                    Вы действительно хотите применить изменения?
                </ModalDialog>
            </div>
        );
    };

    return (
        <div>
            {renderHeader()}
            {isOpen && renderFollowers()}
            {isOpen && renderFooter()}
            {renderModals()}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    onError: bindActionCreators(showErrorAlert, dispatch),
    onWarning: bindActionCreators(showWarningAlert, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(Team);
