import React, { useState, useEffect, useCallback } from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import { showWarningAlert } from 'ducks/Alert';
import WishlistAttributeSelector from 'components/Lk/Common/Selectors/WishlistAttributeSelector';
import './ManageFavorites.scss';

const NewWishlist = props => {
    const {createWishList, handleStateChange, tryCreateNewWishlist = false} = props;
    const [selected, setSelected] = useState('Everyone');
    const [title, setTitle] = useState('');

    const selectItem = id => () => {
        title.trim() && handleStateChange({newWishlistUpdated: true});
        setSelected(id);
    };
    
    const onChangeTitle = title => {
        handleStateChange({ newWishlistUpdated: !!title.trim() });
        setTitle(title);
    };

    const createNewWishList = useCallback(async () => {
        if (!title.trim() || !selected) {
                return;
        }
        try {
            await createWishList({
                name: title,
                visibility: selected,
            });
        } catch (e) {
            return showWarningAlert('Ошибка создания подборки');
        }
    }, [selected, title, createWishList]);
    
    useEffect(() => {
        if (tryCreateNewWishlist) {
            createNewWishList();
        }
    }, [tryCreateNewWishlist, createNewWishList]);
    
    return (
        <div className="LKManageFavoritesNewWishList">
            <div>
                <div className="LKManageFavoritesNewWishList__Title">Создание подборки</div>
            </div>

            <div className="LKManageFavoritesNewWishList__Item">
                <InfoField
                    noIcon
                    isEdit
                    onChange={onChangeTitle}
                    value={title || ''}
                    title="Название подборки"
                />
            </div>
            <div className="LKManageFavoritesNewWishList__Item">
                <WishlistAttributeSelector selected={selected} selectItem={selectItem} />
            </div>
        </div>
    );
};

export default NewWishlist;
