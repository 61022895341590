import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { getEmailColors, getAllowedGroups } from 'api';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Select from 'components/uikit/Select';
import ModalDialog from 'components/common/ModalDialog';
import ParagraphTemplate from './ParagraphTemplate';
import ButtonTemplate from './ButtonTemplate';
import UserMessageTemplate from './UserMessageTemplate';
import HeaderTemplate from './HeaderTemplate';
import InputText from 'components/uikit/InputText';
import { ParagraphModal } from './ParagraphModal';
import { TEMPLATES_TYPES_ENUM } from 'constants.js';

const Email = (props) => {
    const {
        handleTemplateTypeChange,
        handleTemplateNameChange,
        handleTemplateSubjectChange,
        handleTemplateChildrenChange,
        handleTemplateAttributesChange,
        handleTemplateGroupChange,
        type,
        name,
        subject,
        isSubmitted,
        user,
        groupId,
        isUsedByDefault,
    } = props;

    const templatesTypes = TEMPLATES_TYPES_ENUM;

    const [colorOptions, setColorOptions] = useState([]);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const getColorOptions = async () => {
            const colorsData = await getEmailColors();
            let colors = [];
            for (const color of Object.entries(colorsData.data)) {
                colors.push(color[1]);
            }
            setColorOptions(colors);

            const groupsData = await getAllowedGroups(user.groupId);
            if (groupsData && groupsData.status === 200 && groupsData.data) {
                const groupsOptions = groupsData.data
                    .filter((x) => x.type !== 'Scope')
                    .map((x) => ({ value: x.id, label: x.title }));
                setGroups(groupsOptions);
            }
        };
        getColorOptions();

        const inputs = document.querySelectorAll('input');
        for (const i of inputs) {
            i.addEventListener('dragover', (e) => {
                e.preventDefault();
            });
            i.addEventListener('drop', (e) => {
                e.preventDefault();
            });
        }
    }, [user]);

    const deleteTemplateChild = (index) => () => {
        const newChildren = props.body.children.map((x) => ({
            ...x,
            attributes: { ...x.attributes },
        }));

        newChildren.splice(index, 1);
        handleTemplateChildrenChange(newChildren);
    };

    const renderEmailFields = () => {
        const groupValue = groupId && groups.find((x) => x.value === groupId);
        return (
            <div>
                <div>
                    <Field
                        className="EmailTypeField"
                        required
                        filled={type}
                        invalid={isSubmitted && !type}
                    >
                        <Label>Тип</Label>
                        <Select
                            maxLength={250}
                            options={templatesTypes}
                            value={templatesTypes.find((x) => x.value === type) || ''}
                            onChange={handleTemplateTypeChange}
                            isDisabled={!!isUsedByDefault}
                        />
                    </Field>
                </div>
                <div>
                    <Field
                        className="EmailGroupField"
                        required
                        filled={groupId}
                        invalid={isSubmitted && !groupId}
                    >
                        <Label>Группа</Label>
                        <Select
                            maxLength={250}
                            options={groups}
                            value={groupValue || ''}
                            onChange={handleTemplateGroupChange}
                            isDisabled={!!isUsedByDefault}
                        />
                    </Field>
                </div>

                <div>
                    <Field required filled={name} invalid={isSubmitted && !name}>
                        <Label>Название</Label>
                        <Input
                            maxLength={250}
                            value={name || ''}
                            onChange={handleTemplateNameChange}
                        />
                    </Field>
                </div>

                <div>
                    <Field required filled={subject} invalid={isSubmitted && !subject}>
                        <Label>Заголовок</Label>
                        <Input
                            maxLength={250}
                            value={subject || ''}
                            onChange={handleTemplateSubjectChange}
                        />
                    </Field>
                </div>
            </div>
        );
    };

    const moveTemplateChild = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        const newChildren = props.body.children.map((x) => ({
            ...x,
            attributes: { ...x.attributes },
        }));
        const movedField = newChildren[source.index];
        newChildren.splice(source.index, 1);
        newChildren.splice(destination.index, 0, movedField);
        handleTemplateChildrenChange(newChildren);
    };

    const onDragEnd = (result) => {
        moveTemplateChild(result);
    };

    const renderDropZone = () => {
        return props.body.children.length === 0 ? (
            'Перетащите сюда элемент'
        ) : (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppableId">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{ width: '100%' }}
                        >
                            {renderTemplateItems()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    const handleDividerToggle = (className) => (e) =>
        e.target.classList && e.target.classList.toggle(className);

    const checkUrl = (index, attributes) => {
        const { text } = attributes;
        const checkUrlReg = /\((?!(http(s?):\/\/))(\S*?\.\S{1,4})\)/g;
        const newUrl = '(https://$3)';
        attributes.text = text.trim().replace(checkUrlReg, newUrl);
        handleTemplateAttributesChange(index, attributes);
    };

    const handleModalOpen = (index) => () => {
        setIsModalOpen(true);
        setEditItem({ ...props.body.children[index], index: index });
    };

    const handleAttributesChange = (position, key, value) => {
        const attributes = { ...editItem.attributes };
        setEditItem({ ...editItem, index: position, attributes: { ...attributes, [key]: value } });
    };

    const getHexColor = (color) => {
        return color && colorOptions.length !== 0
            ? colorOptions.find((x) => x.code === color).hex
            : '';
    };
    const renderItem = (item, index) => {
        switch (item.kind) {
            case 'Paragraph':
                return (
                    <ParagraphTemplate
                        handleUpDividerDragEnter={handleDividerToggle(
                            'TemplateItemContainer__UpDivider--over',
                        )}
                        handleUpDividerDragLeave={handleDividerToggle(
                            'TemplateItemContainer__UpDivider--over',
                        )}
                        handleDownDividerDragEnter={handleDividerToggle(
                            'TemplateItemContainer__DownDivider--over',
                        )}
                        handleDownDividerDragLeave={handleDividerToggle(
                            'TemplateItemContainer__DownDivider--over',
                        )}
                        index={index}
                        text={item.attributes.text}
                        deleteTemplateChild={deleteTemplateChild}
                        handleModalOpen={handleModalOpen}
                    />
                );
            case 'Button':
                return (
                    <ButtonTemplate
                        handleUpDividerDragEnter={handleDividerToggle(
                            'TemplateItemContainer__UpDivider--over',
                        )}
                        handleUpDividerDragLeave={handleDividerToggle(
                            'TemplateItemContainer__UpDivider--over',
                        )}
                        handleDownDividerDragEnter={handleDividerToggle(
                            'TemplateItemContainer__DownDivider--over',
                        )}
                        handleDownDividerDragLeave={handleDividerToggle(
                            'TemplateItemContainer__DownDivider--over',
                        )}
                        index={index}
                        text={item.attributes.text}
                        deleteTemplateChild={deleteTemplateChild}
                        handleModalOpen={handleModalOpen}
                        color={item.attributes.color ? getHexColor(item.attributes.color.code) : ''}
                    />
                );
            case 'UserMessage':
                return (
                    <UserMessageTemplate
                        handleUpDividerDragEnter={handleDividerToggle(
                            'TemplateItemContainer__UpDivider--over',
                        )}
                        handleUpDividerDragLeave={handleDividerToggle(
                            'TemplateItemContainer__UpDivider--over',
                        )}
                        handleDownDividerDragEnter={handleDividerToggle(
                            'TemplateItemContainer__DownDivider--over',
                        )}
                        handleDownDividerDragLeave={handleDividerToggle(
                            'TemplateItemContainer__DownDivider--over',
                        )}
                        index={index}
                        text={item.attributes.text}
                        deleteTemplateChild={deleteTemplateChild}
                    />
                );
            case 'Header':
                return (
                    <HeaderTemplate
                        handleUpDividerDragEnter={handleDividerToggle(
                            'TemplateItemContainer__UpDivider--over',
                        )}
                        handleUpDividerDragLeave={handleDividerToggle(
                            'TemplateItemContainer__UpDivider--over',
                        )}
                        handleDownDividerDragEnter={handleDividerToggle(
                            'TemplateItemContainer__DownDivider--over',
                        )}
                        handleDownDividerDragLeave={handleDividerToggle(
                            'TemplateItemContainer__DownDivider--over',
                        )}
                        index={index}
                        text={item.attributes.text}
                        deleteTemplateChild={deleteTemplateChild}
                        handleModalOpen={handleModalOpen}
                    />
                );
            default:
                return <></>;
        }
    };

    const renderTemplateItems = () => {
        const list = props.body.children.map((x, index) => (
            <div key={x.id}>
                <Draggable draggableId={x.id} index={index} key={x.id}>
                    {(provided) => (
                        <div
                            key={x.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            {renderItem(x, index)}
                        </div>
                    )}
                </Draggable>
            </div>
        ));

        return list;
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const renderModalDialog = () => {
        return (
            <ModalDialog
                size={'xl'}
                modalHeader={renderModalHeader()}
                modalOpen={isModalOpen}
                btnOktext={'Применить'}
                onClick={() => saveAttributes(editItem.index, editItem.attributes)}
                onCloseModal={() => setIsModalOpen(false)}
            >
                <div className={'Modal-Children'}>{renderModalBody()}</div>
            </ModalDialog>
        );
    };

    const saveAttributes = (index, attributes) => {
        checkUrl(index, attributes);
        setIsModalOpen(false);
        handleTemplateAttributesChange(index, attributes);
    };

    const renderModalHeader = () => {
        if (!editItem) {
            return '';
        }
        switch (editItem.kind) {
            case 'Paragraph':
                return 'Текст параграфа';
            case 'Button':
                return 'Свойства кнопки';
            case 'Header':
                return 'Текст заголовка';
            default:
                return '';
        }
    };

    const renderModalBody = () => {
        if (!editItem) {
            return '';
        }
        switch (editItem.kind) {
            case 'Paragraph':
                return (
                    <ParagraphModal
                        editItem={editItem}
                        handleAttributesChange={handleAttributesChange}
                        className="Modal-Children__Line"
                    />
                );
            case 'Button':
                return renderButtonModalBody();
            case 'Header':
                return renderHeaderModalBody();
            default:
                return <></>;
        }
    };

    const renderButtonModalBody = () => {
        return (
            <>
                <div>
                    <Field>
                        <Label>Цвет</Label>
                        <Select
                            defaultValue={colorOptions[0]}
                            options={colorOptions}
                            onChange={(e) => handleAttributesChange(editItem.index, 'color', e)}
                            value={editItem.attributes.color}
                            catalog
                        />
                    </Field>
                </div>

                <div>
                    <Field>
                        <Label>Текст</Label>
                        <Input
                            value={editItem.attributes.text}
                            onChange={(e) =>
                                handleAttributesChange(editItem.index, 'text', e.target.value)
                            }
                        />
                    </Field>
                </div>
            </>
        );
    };

    const renderHeaderModalBody = () => {
        return (
            <div className={'Modal-Children__Line'}>
                <div>
                    <Field size={'30'}>
                        <Label>Текст</Label>
                        <InputText
                            maxLength={4000}
                            value={editItem.attributes.text}
                            onChange={(e) =>
                                handleAttributesChange(editItem.index, 'text', e.target.value)
                            }
                        />
                    </Field>
                </div>
            </div>
        );
    };

    const handleDragOver = (e) => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        return false;
    };
    const handleDragEnter = (e) =>
        e.target.classList && e.target.classList.add('TemplateEmail__DropZone--over');
    const handleDragLeave = (e) =>
        e.target.classList && e.target.classList.remove('TemplateEmail__DropZone--over');
    const handleDrop = (e) => {
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        e.target.classList.remove('TemplateEmail__DropZone--over');
        const data = e.dataTransfer.getData('text');
        const index =
            e.target.classList &&
            (e.target.classList.contains('TemplateItemContainer__UpDivider') ||
                e.target.classList.contains('TemplateItemContainer__DownDivider'))
                ? e.target.dataset.index
                : 0;
        e.target.tagName.toLowerCase() !== 'button' && addTemplateChild(JSON.parse(data), index);
        document
            .querySelectorAll('.TemplateItemContainer__UpDivider')
            .forEach((x) => x.classList.remove('TemplateItemContainer__UpDivider--over'));
        document
            .querySelectorAll('.TemplateItemContainer__DownDivider')
            .forEach((x) => x.classList.remove('TemplateItemContainer__DownDivider--over'));
        return false;
    };

    const handleDragEnd = (e) =>
        e.target.classList && e.target.classList.remove('TemplateEmail__DropZone--over');

    const addTemplateChild = (data, index = 0) => {
        const newChildren = props.body.children.map((x) => ({
            ...x,
            attributes: { ...x.attributes },
        }));

        const appendData = {
            id: uuid(),
            kind: data.type,
            attributes: {
                text: data.text,
            },
            children: [],
        };

        newChildren.splice(index, 0, appendData);
        handleTemplateChildrenChange(newChildren);
    };

    return (
        <>
            <div className="TemplateEmail">
                {renderEmailFields()}
                <div
                    className="TemplateEmail__DropZone"
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onDragEnd={handleDragEnd}
                >
                    {renderDropZone()}
                </div>
            </div>
            {renderModalDialog()}
        </>
    );
};

Email.propTypes = {
    handleTemplateTypeChange: PropTypes.func.isRequired,
    handleTemplateNameChange: PropTypes.func.isRequired,
    handleTemplateSubjectChange: PropTypes.func.isRequired,
    handleTemplateChildrenChange: PropTypes.func.isRequired,
    handleTemplateAttributesChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

export default connect(mapStateToProps, null)(Email);
