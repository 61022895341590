import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InfoText = props => {
    const { text, isSubText, percentType, isSearchCard } = props;
    return (
        <div
            className={classnames('LKPersonCardInfoText', {
                'LKPersonCardInfoText--sub-text': isSubText,
                'LKPersonCardInfoText--is-search': isSearchCard,
                [`LKPersonCardInfoText--percent LKPersonCardInfoText--percent-${percentType}`]: percentType,
            })}
        >
            {text}
        </div>
    );
};

InfoText.defaultProps = {
    isSubText: false,
    percentType: '',
    isSearchCard: false,
};

InfoText.propTypes = {
    text: PropTypes.string,
    isSubText: PropTypes.bool,
    percentType: PropTypes.oneOf(["low", "mid", "high", ""]),
    isSearchCard: PropTypes.bool,
};

export default InfoText;
