import { appName } from 'constants.js';
import { takeLeading, put, call, all, delay } from 'redux-saga/effects';
import {
    getDashboardPersonsWithoutResultAmount,
    getDashboardEvaluationRegionRating,
    getDashboardTeamGrowthAmount,
} from 'api';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';

const moduleName = 'dashboard';

const PERSON_WITHOUT_RESULT_AMOUNT_FETCH_START = `${appName}/${moduleName}/PERSON_WITHOUT_RESULT_AMOUNT_FETCH_START`;
const PERSON_WITHOUT_RESULT_AMOUNT_FETCH_SUCCESS = `${appName}/${moduleName}/PERSON_WITHOUT_RESULT_AMOUNT_FETCH_SUCCESS`;
const PERSON_WITHOUT_RESULT_AMOUNT_FETCH_ERROR = `${appName}/${moduleName}/PERSON_WITHOUT_RESULT_AMOUNT_FETCH_ERROR`;

const REGION_RATING_FETCH = `${appName}/${moduleName}/REGION_RATING_FETCH`;
const REGION_RATING_FETCH_START = `${appName}/${moduleName}/REGION_RATING_FETCH_START`;
const REGION_RATING_FETCH_SUCCESS = `${appName}/${moduleName}/REGION_RATING_FETCH_SUCCESS`;
const REGION_RATING_FETCH_ERROR = `${appName}/${moduleName}/REGION_RATING_FETCH_ERROR`;

const TEAM_GROWTH_AMOUNT_FETCH = `${appName}/${moduleName}/TEAM_GROWTH_AMOUNT_FETCH`;
const TEAM_GROWTH_AMOUNT_FETCH_SUCCESS = `${appName}/${moduleName}/TEAM_GROWTH_AMOUNT_FETCH_SUCCESS`;

const DELAY_PER_REQUEST = 1000 * 60 * 2; // 2 min

const initialState = {
    withoutEvaluation: {
        amount: null,
        percent: null,
    },
    regionRating: { loading: true, data: [] },
    teamGrowth: {
        amount: null,
        percent: null,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PERSON_WITHOUT_RESULT_AMOUNT_FETCH_START:
            return state;

        case PERSON_WITHOUT_RESULT_AMOUNT_FETCH_SUCCESS:
            return { ...state, withoutEvaluation: { ...action.payload } };
        case PERSON_WITHOUT_RESULT_AMOUNT_FETCH_ERROR:
            return state;

        case REGION_RATING_FETCH_START:
            return {
                ...state,
                regionRating: { ...state.regionRating, loading: true },
            };
        case REGION_RATING_FETCH_SUCCESS:
            return {
                ...state,
                regionRating: { ...state.regionRating, data: action.payload, loading: false },
            };
        case REGION_RATING_FETCH_ERROR:
            return {
                ...state,
                regionRating: { ...state.regionRating, loading: false },
            };

        case TEAM_GROWTH_AMOUNT_FETCH_SUCCESS:
            return {
                ...state,
                teamGrowth: { ...action.payload },
            };

        default:
            return state;
    }
}

export function fetchPersonWithoutResultAmount() {
    return {
        type: PERSON_WITHOUT_RESULT_AMOUNT_FETCH_START,
    };
}

const fetchPersonWithoutResultAmountSuccess = (data) => {
    return {
        type: PERSON_WITHOUT_RESULT_AMOUNT_FETCH_SUCCESS,
        payload: data,
    };
};

const fetchPersonWithoutResultAmountError = (data) => {
    return {
        type: PERSON_WITHOUT_RESULT_AMOUNT_FETCH_ERROR,
        payload: { data },
    };
};

export function fetchRegionRating() {
    return {
        type: REGION_RATING_FETCH,
    };
}

function fetchRegionRatingStart() {
    return {
        type: REGION_RATING_FETCH_START,
    };
}

const fetchRegionRatingSuccess = (data) => {
    return {
        type: REGION_RATING_FETCH_SUCCESS,
        payload: data,
    };
};

const fetchRegionRatingError = (data) => {
    return {
        type: REGION_RATING_FETCH_ERROR,
        payload: { data },
    };
};

export function fetchTeamGrowth(data) {
    return {
        type: TEAM_GROWTH_AMOUNT_FETCH,
        payload: {
            personId: data,
        }
    };
}

const fetchTeamGrowthSuccess = (data) => {
    return {
        type: TEAM_GROWTH_AMOUNT_FETCH_SUCCESS,
        payload: data,
    };
};

function* fetchPersonWithoutResultAmountSaga() {
    try {
        const response = yield call(getDashboardPersonsWithoutResultAmount);
        yield put(fetchPersonWithoutResultAmountSuccess(response.data));
        yield delay(DELAY_PER_REQUEST);
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке количества произошла ошибка');

        yield put(fetchPersonWithoutResultAmountError(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

function* fetchRegionRatingSaga() {
    try {
        yield put(fetchRegionRatingStart());
        const response = yield call(getDashboardEvaluationRegionRating);
        yield put(fetchRegionRatingSuccess(response.data));
        yield delay(DELAY_PER_REQUEST);
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке рейтинга произошла ошибка');

        yield put(fetchRegionRatingError(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

function* fetchTeamGrowthSaga(action) {
    try {
        const response = yield call(getDashboardTeamGrowthAmount, action.payload.personId);
        yield put(fetchTeamGrowthSuccess(response.data));

        yield delay(DELAY_PER_REQUEST);
    } catch (error) {
        const reqError = new RequestError(
            error,
            'При загрузке информации о команде произошла ошибка',
        );

        yield put(showErrorAlert(reqError.message));
    }
}

export function* saga() {
    yield all([
        takeLeading(PERSON_WITHOUT_RESULT_AMOUNT_FETCH_START, fetchPersonWithoutResultAmountSaga),
        takeLeading(REGION_RATING_FETCH, fetchRegionRatingSaga),
        takeLeading(TEAM_GROWTH_AMOUNT_FETCH, fetchTeamGrowthSaga),
    ]);
}
