import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './styles/index.scss'; // TODO: должен быть выше ./App.js
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'components/App';
import history from './history';
import './libs/font-awesome';
import './polyfills';
import store from './store';
import 'auth';

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
);
