import React from 'react';
import Select from 'components/uikit/Select';
import CheckCard from 'components/common/CheckCard';

import './CompetenciesSelector.scss';

const CompetenciesSelector = (props) => {

    const { competencyInfo } = props;
    
    const getCurrentCompetencyInfo = () => props.educationEvent.currentCompetencyInfo || props.competencyInfo[0];
    
    const handleChange = item => selected => {
        const { onChange, educationEvent } = props;
        selected
            ? onChange("competencyIds", [...educationEvent.competencyIds.map(x => x), item.id])
            : onChange("competencyIds", educationEvent.competencyIds.filter(x => x !== item.id));
    };

    const handleCompetencyModelChange = (value) => {
        if (getCurrentCompetencyInfo() === value) {
            return;
        }
        props.handleCompetencyModelChange(value); 
    };

    const renderCompetencies = () => {
        if (!!competencyInfo) {
            return competencyInfo.length === 1
                ? renderSingleModel(competencyInfo[0])
                : renderMultiModel(competencyInfo)
        }
        return null;
    };

    const renderSingleModel = (competencySingleInfo) => {
        const { competencyModel, competencies, competencyResultsByTagId } = competencySingleInfo;
        return (
            <div>
                <div className="CompetenciesSelector__Title">
                    {competencyModel.name}
                </div>
                {renderCompetencyList(competencies, competencyResultsByTagId)}
            </div>
        );
    };

    const renderMultiModel = (competencyInfo) => {
        const currentCompetencyInfo = getCurrentCompetencyInfo();
        const { competencies, competencyResultsByTagId } = currentCompetencyInfo;
        return (
            <div>
                <div className="CompetenciesSelector__Title">
                    <Select
                        value={currentCompetencyInfo}
                        onChange={handleCompetencyModelChange}
                        options={competencyInfo}
                        getOptionValue={(c) => c.competencyModel.id}
                        getOptionLabel={(c) => c.competencyModel.name}
                    />
                </div>
                {renderCompetencyList(competencies, competencyResultsByTagId)}
            </div>
        );
    };

    const renderCompetencyList = (competencies) => {
        const { educationEvent } = props; 
        return (
            competencies && competencies.map((item) => {
                const isChecked = educationEvent.competencyIds.includes(item.id);
                return (
                    <div className="CompetenciesSelector__CheckCard" key={item.id}>
                        <CheckCard
                            title={item.name}
                            value={item.id}
                            checked={educationEvent.competencyIds.find(x => x === item.id) || false}
                            onChange={handleChange(item)}
                            disabled={!isChecked && educationEvent.competencyIds && educationEvent.competencyIds.length === 3}
                        />
                    </div>
                )
            })
        );
    };

    const noAvailableCompetencies = competencyInfo && Array.isArray(competencyInfo) && competencyInfo.length === 0;
    
    return (
        <div className="CompetenciesSelector">
            {
                noAvailableCompetencies &&
                    <div className="CompetenciesSelector__NoData">
                        Нет доступных компетенций
                    </div>
            }
            { competencyInfo && !noAvailableCompetencies && renderCompetencies() }
        </div>
    );
};

export default CompetenciesSelector;
