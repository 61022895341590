import React from 'react';
import classnames from 'classnames';

const Field = props => {
    const { size, invalid, error, className = '' } = props;

    const finalClass = classnames('LKField', {
        [`LKField--${size}`]: size,
        LKField__Invalid: invalid,
        LKField__WithError: !!error,
        [`${className}`]: className,
    });

    return (
        <div className={finalClass}>
            {props.children}
            {error && invalid && <small className="LKField__Error">{error}</small>}
        </div>
    );
};

export default Field;
