import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchTeams } from 'ducks/Teams';
import { setProgressCartPersons } from 'ducks/ProgressCart';

import { getDashboardTeamGrowth } from 'api';

import './GroupList.scss';
import GroupList from './GroupList';

const TeamList = props => {
    const { personProfileUrl, filters, personId, fetchTeams, teams } = props;
    const [teamsGrowth, setTeamsGrowth] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTeams(personId);
    }, [fetchTeams, personId]);

    const getTeams = useCallback(() => {
        const getTeamPeople = team => {
            const persons = [];

            team.leaderPerson.id !== personId && persons.push(team.leaderPerson);
            for (const p of team.followerPersons) {
                p.id !== personId && persons.push(p);
            }

            return persons.map(person => {
                const percentItem =
                    teamsGrowth &&
                    teamsGrowth.find(x => x.teamId === team.id && x.personId === person.id);

                return {
                    ...person,
                    percent: percentItem && percentItem.percent,
                };
            });
        };

        let readyTeams = [];

        if (teams && teams.leading && teams.leading.length !== 0) {
            for (const x of teams.leading) {
                const persons = getTeamPeople(x);
                readyTeams = [...readyTeams, { teamId: x.id, teamName: x.name, persons: persons }];
            }
        }

        if (filters) {
            for (const f of filters) {
                readyTeams = f.func(readyTeams, ...f.params)
            }
        }

        return readyTeams;
    }, [teams, personId, teamsGrowth, filters]);

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await getDashboardTeamGrowth(personId);
                mounted && setTeamsGrowth(result.data);
            } finally {
                mounted && setLoading(false);
            }
        };

        fetchData();

        return () => mounted = false;
    }, [personId]);

    return (
        <>
            <GroupList 
                items={getTeams()} 
                {...props} 
                loading={loading} 
                isCount
                withCheckboxes
                personProfileUrl={personProfileUrl}
                className="LKDataCard--Line"
                noDataMsg="В этой команде пока никого нет"
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        personId: state.auth.user.personId,
        teams: state.teams,
        progressCart: state.progressCart,
    };
};

const actions = { fetchTeams, setProgressCartPersons, push };

export default connect(
    mapStateToProps,
    actions,
)(TeamList);
