import React from 'react';
import { Element } from 'react-scroll';
import BlockHeader from './BlockHeader';
import classnames from 'classnames';

const ProfileInfoBlock = props => {
    const {blockId, header, setScrollTo, isEdit, isWrap, setWrap, setVisibleWrapSection} = props;

    const wrapperClassname =  classnames('LKPersonProfileSectionWrapper', {'LKPersonProfileSectionWrapper--view': !isEdit});

    return (
        <>
            <Element name={blockId} className={wrapperClassname}>
                <BlockHeader 
                    header={header} 
                    setScrollTo={setScrollTo} 
                    scrollElem={blockId} 
                    isEdit={isEdit} 
                    isWrap={isWrap} 
                    setWrap={setWrap} 
                    setVisibleWrapSection={setVisibleWrapSection}
                />
                {props.children}
            </Element>
            <div className='LKPersonProfileWrapper--divider' />
        </>
    );
};

export default ProfileInfoBlock;