import React from 'react';
import { helpEmail } from 'constants.js';
import './NoContentSearchResults.scss';

const NoContentSearchResults = props => {
    const { searchQuery = "" } = props;
    return (
        <div className="NoContentSearchResults">
            <div className="NoContentSearchResults__Block">
                В&nbsp;Помощи нет статьи по&nbsp;запросу &laquo;{searchQuery}&raquo;.<br />
                Как можно найти ответ на&nbsp;Ваш вопрос:
            </div>

            <div className="NoContentSearchResults__Block">
                <span className="NoContentSearchResults__Header">Пользуйтесь подсказками</span><br />
                Пока Вы&nbsp;вводите вопрос, появляются подсказки, которые приведут Вас к&nbsp;ответу.
            </div>

            <div className="NoContentSearchResults__Block">
                <span className="NoContentSearchResults__Header">Попробуйте сформулировать вопрос общими словами</span><br />
                Составьте вопрос из&nbsp;ключевых слов, например: &laquo;задачи&raquo;, &laquo;поиск&raquo;.
            </div>

            <div className="NoContentSearchResults__Block">
                <span className="NoContentSearchResults__Header">Обратитесь в&nbsp;службу поддержки</span><br />
                Если ответ все-таки не&nbsp;найден, напишите письмо на&nbsp;<a href={`mailto: ${helpEmail}`}>{helpEmail}</a>&nbsp;&mdash; и&nbsp;мы&nbsp;ответим Вам в&nbsp;ближайшее время.
            </div>                                    
        </div>
    );
};

export default NoContentSearchResults;