import throttle from "lodash.throttle";
import React, { Component, createContext } from "react";

export const AdaptiveProviderContext = createContext("AdaptiveProvider");

function getContext() {
    const width = window.innerWidth;
    const isMobile = width <= 1023;

    return {
        isMobile,
    };
}

class AdaptiveProvider extends Component {
    state = getContext()

    componentDidMount() {
        window.addEventListener("resize", this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
    }

    render() {
        return (
            <AdaptiveProviderContext.Provider value={this.state}>
                {this.props.children}
            </AdaptiveProviderContext.Provider>
        );
    }

    onWindowResize = throttle(() => {
        this.setState(getContext());
    }, 200)
}

export default AdaptiveProvider;
