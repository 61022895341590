import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import Comment from './components/Comment';
import Loader from 'components/common/Loader';
import classnames from 'classnames';
import './PersonComments.scss';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import { EditPersonComment } from './components';

class PersonComments extends React.Component {
    state = {
        deleteModalOpen: false,
        isEdit: false,
        selectedComment: null,
    };
    componentDidMount() {
        this.props.onFetch(this.props.personId, 1);
    }

    onLoad = () => {
        const { pageNum, onFetch, personId } = this.props;
        onFetch(personId, pageNum + 1, true);
    };

    onClose = () => {
        this.setState({ isEdit: false, selectedComment: null });
    };

    onEdit = comment => e => {
        e.stopPropagation();
        this.setState({ isEdit: true, selectedComment: comment });
    };

    deleteComment = () => {
        this.props.onDelete(this.state.commentId);
    };

    openModal = (e, id) => {
        e.stopPropagation();
        this.setState({ deleteModalOpen: true, commentId: id });
    };

    onCloseModal = () => {
        this.setState({ deleteModalOpen: false, commentId: null });
    };

    render() {
        const { comments, hasMore, user, loading, isLoaded, onSave } = this.props;
        const { isEdit, deleteModalOpen } = this.state;
        if (isEdit) {
            return (
                <div className="LKPersonComments__EditModal">
                    <EditPersonComment
                        comment={this.state.selectedComment}
                        onClose={this.onClose}
                        onSubmit={onSave}
                        personIds={[this.props.personId]}
                        withStickyFooter={this.props.withStickyFooter}
                        withScroll={this.props.withScroll}
                        onModalShown={this.props.onModalShown}
                        user={this.props.user}
                    />
                </div>
            );
        }
        return (
            <div className="LKPersonComments__View">
                <Loader show={loading || !isLoaded} absolute />
                <div
                    className={classnames('LKPersonComments__List', {
                        'LKPersonComments__List--insideModal': this.props.insideModal,
                    })}
                >
                    {!comments.some(x => user?.id === x.author.id && x.type === 'Person') && (
                        <div className="LKPersonComments__Header">
                            <div
                                className="LKPersonComments__Header--AddCommentButton"
                                onClick={this.onEdit(null)}
                            >
                                + добавить заметку
                            </div>
                        </div>
                    )}
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.onLoad}
                        hasMore={hasMore}
                        useWindow={false}
                    >
                        {comments.map(c => (
                            <Comment
                                key={c.id}
                                onEdit={this.onEdit}
                                data={c}
                                user={user}
                                onRemove={this.openModal}
                            />
                        ))}
                    </InfiniteScroll>
                </div>
                <ModalPopup
                    isModalOpen={deleteModalOpen}
                    header={'Вы действительно хотите удалить заметку?'}
                    confirm={this.deleteComment}
                    onModalClose={this.onCloseModal}
                    actionButtons={['Нет', 'Да']}
                    showCloseIcon
                ></ModalPopup>
            </div>
        );
    }
}

PersonComments.propTypes = {
    onFetch: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    comments: PropTypes.array.isRequired,
    hasMore: PropTypes.bool,
    pageNum: PropTypes.number,
    user: PropTypes.object,
    loading: PropTypes.bool,
    isLoad: PropTypes.bool,
};

export default PersonComments;
