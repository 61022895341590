import React from 'react';
import Sorter from 'components/Lk/Uikit/Filter/Sorter';
import './SearchResultSorters.scss';

const SearchResultSorters = props => {
    const { sorters, setSorters } = props;
    const category = 'Persons';
    
    const handleSorter = field => state => {
        const newSorters = state 
            ? sorters.filter(x => x.field !== field).concat({category, field, direction: state})
            : sorters.filter(x => x.field !== field)
        setSorters(newSorters);
    };

    return (
        <div className='LKSearchResultSorters'>
            <div className='LKSearchResultSorters__Item'>
                <Sorter value='А-Я' size='small'  onClick={handleSorter('FullName')} />
            </div>
            <div className='LKSearchResultSorters__Item'>
                <Sorter value='Возраст' size='small'  onClick={handleSorter('Age')} />
            </div>
            <div className='LKSearchResultSorters__Item'>
                <Sorter value='Дата изменения' size='small' onClick={handleSorter('Modified')} />
            </div>
        </div>
    );
};

export default SearchResultSorters;