import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import './Tabs.scss';

const Tabs = props => {
    const { children, defaultTab, onChangeTab = ()=>{} } = props;

    const [activeTab, setActiveTab] = useState(defaultTab);
    const onClickTabItem = tab => {
        onChangeTab();
        setActiveTab(tab);
    };

    return (
        <div>
            <ol className="TabList">
                {children.map((child, index) => {
                    const { label } = child.props;
                    return (
                        <Tab
                            activeTab={activeTab}
                            key={label}
                            label={label}
                            onClick={onClickTabItem}
                            index={index}
                        />
                    );
                })}
            </ol>
            <div className="Tab__Content">
                {children.map((child, index) => {
                    if (index !== activeTab) return undefined;
                    return (
                        <div className="Tab__Child" key={index}>
                            {child.props.children}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    defaultTab: PropTypes.number.isRequired,
    onChangeTab: PropTypes.func,
};

export default Tabs;
