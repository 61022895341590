import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fullTextSearchSelect } from 'api';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "components/common/Loader";
import Input from 'components/Lk/Uikit/Input';
import Button from 'components/Lk/Uikit/Buttons/Button';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemPerson from 'components/Lk/Common/CardItemPerson';
import CardItemPersonData from 'components/Lk/Common/CardItemPerson/CardItemPersonData';
import { getUserShortName } from 'utils';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import { showWarningAlert } from 'ducks/Alert';
import classnames from "classnames";

class CommonSearchCard extends Component {
    state = {
        searchTerm: '',
        newData: [],
        hasMore: true,
        paging: { pageNum: 1, pageSize: 10 },
        searchResults: [],
        searchMeta: {},
        isLoading: false,
        cart: [],
        personsInTeamsIds: [],
        tempSelectionCart: [],
    };

    onSearch = async (cleanData = false) => {
        
        if (this.state.isLoading) {
            return
        }
        
        const { searchTerm, paging } = this.state;

        if (searchTerm.trim() === '') { 
            return 
        }

        this.setState({isLoading: true});
        
        cleanData && this.setState(
            {
                newData: [], 
                paging: { pageNum: 1, pageSize: 10 },
                cart: [],
            }
        );

        const { editTeam } = this.props;
        const personsInTeamsIds = editTeam && editTeam.followerPersons
            ? editTeam.followerPersons.map(x => +x.id) 
            : [];

        const searchData = await fullTextSearchSelect({ searchTerm, paging: cleanData ? { pageNum: 1, pageSize: 10 } : paging });

        const includeSelected = this.state.tempSelectionCart.filter(x => !this.state.cart.map(x => x.id).includes(+x.id));

        const includeToCartFromTeams = searchData.data.payload
            .filter(x => personsInTeamsIds.includes(+x.id))
            .filter(x => !includeSelected.map(x => x.id).includes(+x.id));
        
        this.setState(
            {
                searchResults: searchData.data.payload,
                searchMeta: searchData.data.meta,
                isLoading: false,
                personsInTeamsIds: personsInTeamsIds,
                cart: [...this.state.cart, ...includeToCartFromTeams, ...includeSelected],
                tempSelectionCart: [...this.state.tempSelectionCart, ...includeToCartFromTeams],
            }
        )
    };

    

    handleLoadMore = () => {
        this.setState({hasMore: false});
        this.onSearch();
    };


    onChangeSearchTerm = e => {
        this.setState({searchTerm: e.target.value});
    };

    onSearchFieldKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.onSearch(true);
        }
    };

    handleCardClick = (e) => {
        e.preventDefault();
        const { newData } = this.state;
        const { dataset } = e.currentTarget;

        const person = newData.find(x => +x.id === +dataset.personId);
        const personCheck = document.getElementById(`Name__Id${dataset.personId}`);

        if (personCheck.checked ) {
            const newCart = this.state.cart.filter(x => +x.id !== +dataset.personId);
            const newTempSelectionCart = this.state.tempSelectionCart.filter(x => +x.id !== +dataset.personId);
            this.setState({ cart: [...newCart], tempSelectionCart: newTempSelectionCart });
        } else {
            this.setState({
                cart: [...this.state.cart, {...person, currentJobTitle: person.currentPosition}],
                tempSelectionCart: [ ...this.state.tempSelectionCart, {...person, currentJobTitle: person.currentPosition}],
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { searchResults, searchMeta, newData, paging } = this.state;

        if (prevState.searchResults !== searchResults) {
            this.setState(() => {
                return { 
                    newData: [...newData, ...searchResults ],
                    paging: {...paging, pageNum: paging.pageNum + 1},
                    hasMore: searchMeta.pageCount > paging.pageNum,
                };
            }); 
        }
    }

    clearAllChecks = () => {
        const checkboxes = document.querySelectorAll('input[type=checkbox]');
        for (const check of checkboxes) {
            check.checked = false;
        }
    };

    onSelect = () => {
        const newPersons = this.state.cart.filter(x => !this.state.personsInTeamsIds.includes(+x.id));
        if (newPersons && newPersons.length !== 0) {
            this.props.handleSelected(this.state.cart);
        }
        else {
            this.props.showWarningAlert('Выбрано 0 новых участников команды');
            this.onCancelSelect();
        }
    };

    onCancelSelect = () => {
        this.props.handleCancelSelect();
    };

    isInCart = (personId) => {
        return this.state.cart.find(x => +x.id === +personId);
    };

    render() {
        const { searchTerm, hasMore, newData, cart } = this.state;
        const { activeCard, setActiveCard, fromProfile } = this.props;
        setActiveCard(activeCard);

        return (
            <div className={classnames('LKCommonSearchCard LKCommonSearchCard--isMobileActive', {'LKCommonSearchCard__FromProfile': fromProfile})}>
                <div className='LKCommonSearchCardSearchLine' >  
                    <Input 
                        value={searchTerm}
                        onChange={this.onChangeSearchTerm}  
                        onKeyPress={this.onSearchFieldKeyPress}
                    />
                    <Button onClick={() => this.onSearch(true)}/>
                </div>
                <div className='LKScrollWrapper'>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.handleLoadMore}
                        hasMore={hasMore}
                        initialLoad={false}
                        useWindow={false}
                    >
                        
                        <div className='LKCommonSearchCardResults'>
                            {newData && newData.length !== 0 && newData.map(x => (
                                    <CardItemBlock key={x.id}>
                                        <CardItemPerson 
                                            person={x}
                                            id={x.id} 
                                            checkbox
                                            clickable
                                            checked={this.isInCart(x.id)}
                                            onCardClick={this.handleCardClick}
                                        >
                                            <CardItemPersonData data={getUserShortName(x)} position={x.currentPosition}/>
                                        </CardItemPerson>
                                    </CardItemBlock>
                                ))
                            }  
                        </div>
                        
                    </InfiniteScroll> 
                    {this.state.isLoading && <Loader />}    
                </div>
                <StickyFooter>
                    <div className='LKCommonSearchAcceptMenu'>
                        <div className='LKCommonSearchAcceptMenu__Cancel' onClick={this.onCancelSelect}>Отменить</div>
                        {cart.length !== 0 && <div className='LKCommonSearchAcceptMenu__Accept' onClick={this.onSelect}>Выбрать {cart.length !== 0 && ` (${cart.length})`}</div>}
                    </div>
                </StickyFooter>
            </div>
        );
    }
}

export default connect(null, { showWarningAlert })(CommonSearchCard);
