export const mainCardMenuItems = [
    {
        id: 'services',
        name: 'Сервисы',
        icon: 'services',
        link: '/services',
    },
    {
        id: 'team',
        name: 'Команды',
        icon: 'team',
        link: '/teams',
    },
    {
        id: 'widgets',
        name: 'Избранное',
        icon: 'widget',
        link: '/widgets',
    }, 
    {
        id: 'content',
        name: 'Контент',
        icon: 'content',
        link: '/content',
    }, 
];

export const evaluateCardMenuItems = [
    {
        id: 'team',
        name: 'Команда оценки',
        icon: 'team',
        link: '/services/evaluate/team',
    },
    {
        id: 'favorite',
        name: 'Избранные',
        icon: 'favorite',
        link: '/services/evaluate/favorites',
    }, 
    {
        id: 'search',
        name: 'Поиск',
        icon: 'search',
        link: '/services/evaluate/search',
    }, 
];

export const evaluateResultCardMenuItems = [
    {
        id: 'team',
        name: 'Команда оценки',
        icon: 'team',
        link: '/services/evaluate-results/team',
    },
    {
        id: 'favorite',
        name: 'Избранные',
        icon: 'favorite',
        link: '/services/evaluate-results/favorites',
    }, 
    {
        id: 'search',
        name: 'Поиск',
        icon: 'search',
        link: '/services/evaluate-results/search',
    }, 
];

export const progressCardMenuItems = [
    {
        id: 'team',
        name: 'Команды',
        icon: 'team',
        link: '/services/progress-team',
    },
    {
        id: 'favorite',
        name: 'Избранные',
        icon: 'favorite',
        link: '/services/progress-favorite',
    }, 
    {
        id: 'search',
        name: 'Поиск',
        icon: 'search',
        link: '/services/progress-search',
    }, 
];