import React from 'react';
import InputMask from '../InputMask';
import { snilsMask } from 'constants.js';
import './SnilsInput.scss';
import { isNullOrWhitespace } from 'utils';

const SnilsInput = (props) => {

    const handleChange = (value) => {
        if (isNullOrWhitespace(value)) {
            props.onChange('');
            return;
        }

        if (props.onChange) {
            props.onChange(value);
        }
    };

    return (
        <div className="SnilsInput__Container">
            <InputMask
                mask={snilsMask}
                maskChar="_"
                value={props.value}
                onChange={(e) => handleChange(e.target.value)}
                placeholder=""
                disabled={props.disabled}
            />
        </div>
    );
};

export default SnilsInput;
