import React from 'react';
import './ExtraInfo.scss';
import { declineAge } from 'utils';

const ExtraInfo = props => {
    const { person } = props;

    const getActualWorkPlace = () => {
        return person?.workInfo?.workPlaces.find(x => x.isActual) || {};
    };

    const getActualEducation = () => {
        return person?.educationInfo?.educations.find(x => x.isActual) || {};
    };

    const actialWorkPlace = getActualWorkPlace();

    const locality = person.personalInfo?.locality?.name;
    const region = person.personalInfo?.region?.name;
    const age = (person.personalInfo.age && declineAge(person.personalInfo.age)) || null;

    const actialEducation = getActualEducation();
    const educationLevel = actialEducation?.educationLevel?.name;
    const graduationYear = actialEducation?.graduationYear;
    const university = actialEducation?.university;
    const specialty = actialEducation?.specialty;


    return (
        <div className='LKProfileExtraInfo'>
            {actialWorkPlace &&
            <div className='LKProfileExtraInfo__Block LKProfileExtraInfo__Block--header'>
                <div className='LKProfileExtraInfo__Line'>
                    {actialWorkPlace.position || ''}
                </div>
                <div className='LKProfileExtraInfo__Line'>
                    {actialWorkPlace.company || ''}
                </div>
            </div>
            }
            {actialWorkPlace?.industry?.name &&
            <div className='LKProfileExtraInfo__Block'>
                <div className='LKProfileExtraInfo__Line'>
                    Отрасль: {actialWorkPlace.industry.name}
                </div>
            </div>
            }
            <div className='LKProfileExtraInfo__Block'>
                <div className='LKProfileExtraInfo__Line'>
                    {locality || ''}
                    {locality && region ? ', ' : ''}
                    {region || ''}
                </div>
            </div>
            {age &&
            <div className='LKProfileExtraInfo__Block'>
                {age}
            </div>
            }
            {actialEducation &&
            <div className='LKProfileExtraInfo__Block'>
                <div className='LKProfileExtraInfo__Line'>
                    {educationLevel || ''}
                    {educationLevel && graduationYear ? ', ' : ''}
                    {graduationYear ? `${graduationYear} год` : ''}
                </div>
                <div className='LKProfileExtraInfo__Line'>
                    {university || ''}
                </div>
                <div className='LKProfileExtraInfo__Line'>
                    {specialty ? `Специальность "${specialty}"` : ''}
                </div>
            </div>
            }
        </div>
    );
};

export default ExtraInfo;