import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ChevronButton from 'components/Lk/Uikit/Buttons/ChevronButton';
import RoundButton from 'components/uikit/Button/RoundButton';
import Loader from 'components/common/Loader';
import "./DataCard.scss";

function EditButton(props) {
    const { onClick } = props;

    return (
        <div className="LKDataCard__EditButton">
            <RoundButton type="edit" position="absolute" onClick={onClick} />
        </div>
    );
}

class DataCard extends Component {
    state = {
        isOpen: typeof this.props.defaultIsOpen !== 'undefined' ? this.props.defaultIsOpen : false,
    };

    componentDidUpdate(prevProps) {
       if (prevProps.isOpen !== this.props.isOpen) {
           this.setState({isOpen: this.props.isOpen});
       }
    }

    render() {
        let {
            title,
            titleCheckbox,
            children,
            className,
            titleClassName,
            chevronClassName,
            onChange,
            controls,
            isOpen,
            shadow,
            button = "ChevronButton",
            titleModifire,
            isCount,
            preCount,
            action,
            loading = false,
            dataTest,
            ...rest
        } = this.props;

        const restProps = {...rest};
        delete restProps.defaultIsOpen;
        delete restProps.hasValidationErrors;
        delete restProps.handleAction;
        delete restProps.unwrap;

        isOpen = isOpen || this.state.isOpen;
        
        const finalChevronClassName = chevronClassName ? classnames(`${chevronClassName}--${isOpen ? 'down' : 'up'}`) : null;

        const thisBtn = (button) => {
            switch (button) {
                case 'ChevronButton':
                    return <ChevronButton className={finalChevronClassName} isUp={isOpen} onClick={this.handleToggle} data-test={`${dataTest}_Chevron--button`} />;
                case 'lightChevron':
                    return <ChevronButton isUp={isOpen} onClick={this.handleToggle} data-test={`${dataTest}_Chevron--button`} />;
                default:
                    return `Произошла непредвиденная ошибка`;
            }
        };

        const finalClassName = classnames('LKDataCard', className, {
            'LKDataCard--shadow': shadow,
        });
        const titleFinalClassName = classnames('LKDataCard__Title', titleClassName, {
            [`LKDataCard__Title--${titleModifire}`]: titleModifire,
        });

        const childrenIsCount = preCount || 
            (children && children.length) ||  
            (React.Children.toArray(children).length !== 0 && React.Children.toArray(children)[0].props.data && React.Children.toArray(children)[0].props.data.length) || 0;
        const ifTitleCount = isCount ? ` (${childrenIsCount})` : '';

        return (
            <div className={finalClassName} {...restProps}>
                {onChange && (
                    <div className='LKDataCard__Controls'>
                        <EditButton onClick={this.handleChange} />
                        {controls}
                    </div>
                )}
                {title && (
                    <div className={titleFinalClassName} >
                        {action && (
                            <div className='LKDataCardAction' onClick={this.onActionClick}>
                                <div className={`LKDataCardAction LKDataCardAction__${action}`} />
                            </div>
                        )}
                        {titleCheckbox && (<div>{titleCheckbox}</div>)}
                        <div className="LKDataCard__Title-Text" data-test={`${dataTest}_OpenWishList--button`} onClick={this.handleToggle}>
                            {title}{ifTitleCount}
                        </div>
                        {!loading && thisBtn(button)}
                    </div>
                )}
                <div className={classnames("LKDataCard__Content", {"LKDataCard__Content--hidden": !isOpen})}>
                    {loading ? <Loader /> : children}
                </div>
            </div>
        );
    }

    handleToggle = e => {
        if (this.props.disabled) {
            return;
        }
        
        if (!this.props.loading) {
            e.preventDefault();
            e.stopPropagation();
            if (!this.state.isOpen && this.props.unwrap) {
                this.props.unwrap();
            }
            this.setState({ isOpen: !this.state.isOpen });
        }
    };

    handleChange = e => {
        if (this.props.disabled) {
            return;
        }
        e.preventDefault();
        this.props.onChange();
    };

    onActionClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.handleAction();
    }
}

DataCard.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    onChange: PropTypes.func,
    defaultIsOpen: PropTypes.bool,
    controls: PropTypes.element,
    isOpen: PropTypes.bool,
};

export default DataCard;
