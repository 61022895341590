import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip } from "recharts";
import { Progress } from "react-sweet-progress";
import SidesChart from "components/common/SidesChart";
import { isNullOrWhitespace } from 'utils';
import ReactTooltip from 'react-tooltip';
import { deepClone } from 'utils';
import { EVALUATION_TYPE_CAREER, EVALUATIONTEST_CRITERIONRESULT_STATUS, EVALUATIONTEST_STATUS } from 'constants.js';
import classnames from "classnames";

const progressColors = {
    low: "rgb(236, 137, 24)",
    mid: "rgb(247, 211, 54)",
    high: "rgb(4, 158, 81)"
};

export const groups = [
    { id: "Abilities", name: "Способности", groupResult: true, visualType: "Web" },
    { id: "Competences", name: "Компетенции", groupResult: false },
    { id: "MotivationAndDestructors", name: "Мотивация и деструкторы", groupResult: false },
    { id: "Characteristics", name: "Личностные характеристики", groupResult: false },
];

export const typesDescr = [
    {
        type: "Competency",
        name: "Компетенции",
        Descr: "За счет чего добивается результатов, уровень развития управленческих компетенций.",
        groupId: "Competences",
    },
    {
        type: "Behavior",
        name: "Личностные характеристики",
        Descr: "Для решения каких задач и в каких ситуациях наиболее эффективен.",
        groupId: "Characteristics",
    },
    {
        type: "MotivationAndDestructors",
        name: "Мотивация и вовлеченность",
        Descr: "Что дает энергию и позволяет добиваться результатов.",
        groupId: "MotivationAndDestructors",
    },
    {
        type: "Abilities",
        name: "Способности",
        Descr: "Тесты способностей показывают умение анализировать словесную информацию и делать на её основании корректные выводы, работать с абстрактной информацией и выявлять закономерности.",
        groupId: "Abilities",
    },
    {
        type: "Potential",
        name: "Потенциал",
        Descr: "Эффективность в новой деятельности - в ее освоении и использовании необходимых навыков и знаний.",
        groupId: "Competences",
    },

    // "Результативность деятельности": появится на 3 этапе
    {
        type: "",
        name: "Результативность деятельности",
        Descr: "Каковы реальные результаты работы.",
        chartType: "",
        groupId: "",
        groupName: "",
    },

    // "Профессиональные знания": ВОЗМОЖНО появится на 3 этапе. Вид графика - "паутина"
    {
        type: "",
        name: "Профессиональные знания",
        Descr: "",
        chartType: "",
        groupId: "",
        groupName: "",
    },
];

const getDescription = (data, chartParams, withDescr) => {
    return isNullOrWhitespace(data.description)
        ? withDescr && chartParams && chartParams.Descr
        : data.description;
}

export const renderSideChart = (personId, data, withHeader = false, withDescr = false) => {

    const chartParams = getChartParams(data.kind);
    const description = getDescription(data, chartParams, withDescr);
    const isTestActive = data.resultType === EVALUATIONTEST_STATUS.Relevant;

    return (
        <div key={data?.result?.id} className="Evaluate-Block">
            <SidesChart
                personId={personId}
                withHeader={withHeader}
                withDescr={withDescr}
                description={description}
                isTestActive={isTestActive}
                {...data}
            />
        </div>
    );
};

export const renderProgressChart = (personId, data, getCompetenceDescription, withHeader = false, withDescr = false) => {

    let subHeader = null;
    let criterias = [];

    // хард код для карьерной мотивации
    if (data.id === EVALUATION_TYPE_CAREER) {

        const getPosition = (evaluationTestCriterionId) => {
            if (!evaluationTestCriterionId) {
                return 0;
            }
            return data.criteria?.[evaluationTestCriterionId]?.position || 0;
        };

        let newCriterias = Object.values(data.criteria)
            .sort((a, b) => a.position - b.position)
            .map((c) => {
                let criteriaFound = data.result.criteria.find(x => x.evaluationTestCriterionId === c.id);
                if (!criteriaFound) {
                    criteriaFound = {
                        evaluationTestResultId: data.result.id,
                        evaluationTestCriterionId: c.id,
                        position: c.position,
                        status: "Unknown"
                    };
                }
                return { ...criteriaFound, position: c.position };
            });

        let innerCriterias = deepClone(newCriterias.filter((c) => c.position <= 5));
        let outerCriterias = deepClone(newCriterias.filter((c) => c.position > 5));

        criterias = newCriterias.map((c, ind) => ind % 2 === 0 ? innerCriterias?.shift() : outerCriterias?.shift());

        const firstNotStubCriteriaIndex = criterias.findIndex(x => !!x.id);
        criterias = criterias.slice(firstNotStubCriteriaIndex);

        subHeader = (
            <>
                <div className="CompetenceHeader">
                    {data.result?.criteria?.filter(x => getPosition(x.evaluationTestCriterionId) <= 5).length > 0 && (
                        <label>Внутренняя мотивация</label>)}
                </div>

                <div className="CompetenceHeader">
                    {data.result?.criteria?.filter(x => getPosition(x.evaluationTestCriterionId) > 5).length > 0 && (
                        <label>Внешняя мотивация</label>)}
                </div>
            </>
        );
    }

    const chartParams = getChartParams(data.kind);
    const description = getDescription(data, chartParams, withDescr);
    const isTestActive = data.resultType === EVALUATIONTEST_STATUS.Relevant;

    return (
        <div key={data.result.id} className="Progress-Block">
            {description && <div className="Progress-Block__Descr">{description}</div>}
            {withHeader && <div className="Progress-Block__Header"><a href={`/person/${personId}/evaluate#${data.result.id}`}>{data.title}</a></div>}
            {subHeader}
            {data.id === EVALUATION_TYPE_CAREER 
                ? criterias.map(x => getProgressLineData(data.criteria, x, chartParams && chartParams.groupId, getCompetenceDescription, isTestActive))
                : data.result.criteria.map(x => getProgressLineData(data.criteria, x, chartParams && chartParams.groupId, getCompetenceDescription, isTestActive))
            }
        </div>
    );
};

const getProgressColor = (value) => {
    if (value >= 0 && value < 30) {
        return progressColors.low;
    } else if (value >= 30 && value < 70) {
        return progressColors.mid;
    } else {
        return progressColors.high;
    }
}

const getProgressLineTheme = (value, groupId) => {
    if (groupId === "Abilities") {
        const color = getProgressColor(value);
        return { success: { color: progressColors.high, symbol: value + "%" }, active: { color } };
    }
    
    return { success: { color: "#368ef1", symbol: value + "%" }, active: { color: "#368ef1" } };
}

const getProgressLineData = (criteriaHeaders, criteriaData, groupId, getCompetenceDescription, isTestActive) => {

    const isCriteriaUnknown = criteriaData.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Unknown;

    const isCriteriaActive = criteriaData.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Relevant;
    
    const description = getCompetenceDescription(criteriaData.evaluationTestResultId , criteriaData.evaluationTestCriterionId);
    const value = Math.round(criteriaData.normalizedValue * 100);
    const theme = getProgressLineTheme(value, groupId);
    const title = Object.entries(criteriaHeaders).find(x => x[0] === criteriaData.evaluationTestCriterionId)[1].title;
    return (
        <div 
            className={classnames("Competence", {'Competence--notActive': (!isTestActive || !isCriteriaActive)})} 
            key={criteriaData.evaluationTestCriterionId}
        >
            {(!isCriteriaUnknown &&
            <>
                <label  data-tip data-for={`lineHint${criteriaData.evaluationTestCriterionId}`}>{title}</label>
                <Progress percent={value} theme={theme} />
                {description && renderLineHint(description, `lineHint${criteriaData.evaluationTestCriterionId }`)}
            </>)}
        </div>

    );
};

export const renderRadarChart = (personId, data, withHeader = false, withDescr = false, testProviders) => {

    const color = "rgb(19, 140, 228)";

    let radarData = data && Array.isArray(data) && data.map(item => {
        return {
            id: item.result.id,
            title: item.title,
            name: Object.entries(item.criteria)[0][1].title,
            value: item.result.criteria && item.result.criteria.length > 0
                ? Math.round(item.result.criteria[0].normalizedValue * 100)
                : 0,
            provider: (!!testProviders.find(x => x.id === item.testProviderId) && testProviders.find(x => x.id === item.testProviderId).title) 
                || item.unknownProviderTitle,
            status: item.result.criteria?.length > 0 && item.result?.criteria?.[0]?.status,
            testStatus: item.resultType
        }
    });

    if (!radarData && data) {
        radarData = [
            {
                id: data.result.id,
                title: data.title,
                name: Object.entries(data.criteria)[0][1].title,
                value: data.result.criteria && data.result.criteria.length > 0
                    ? Math.round(data.result.criteria[0].normalizedValue * 100)
                    : 0,
                provider: (!!testProviders.find(x => x.id === data.testProviderId) && testProviders.find(x => x.id === data.testProviderId).title)
                    || '',
                status: data.result.criteria?.length > 0 && data.result?.criteria?.[0].status,
                testStatus: data.resultType
            }
        ]
    }

    if (radarData && radarData.length > 3) {
        radarData = radarData.slice(0, 3);
    }

    if (radarData && radarData.length === 2) {
        radarData.push(
            {
                id: "_",
                title: "",
                name: "_",
                value: 0,
                provider: "",
                status: "Relevant",
                testStatus: "Relevant",
            }
        );
    }

    var printData = radarData && radarData.filter(x => x.id !== "_");

    const chartParams = getChartParams("Abilities");

    return (
        <div key={radarData[0].id} className="Evaluate-Block">
            {withDescr && <div className="Evaluate-Block__Descr">{chartParams.Descr}</div>}
            {withHeader && <div className="Evaluate-Block__Header">{data.title}</div>}
            <div className="Radar-Chart">
                <div className="Radar-Chart__Billet-List">
                    {renderRadarLines(personId, printData, chartParams.groupId)}
                </div>
                <div className="Radar-Chart__Chart">
                    {radarData && radarData.length > 2 ?
                        <RadarChart outerRadius={200} data={radarData} width={400} height={400}>
                            <PolarGrid />
                            <PolarAngleAxis tick={null} dataKey="name" />
                            <PolarRadiusAxis label={false} tick={{ fontSize: 24, fill: "darkblue" }} angle={90} domain={[0, 100]} />
                            <Radar
                                key={data.id}
                                dataKey={"value"}
                                stroke={color}
                                fill={color}
                                fillOpacity={0.6}
                                dot={{ strokeWidth: 5 }}
                            />
                            <Tooltip content={radarChartTooltip} cursor={{ stroke: color, strokeWidth: 3 }} />
                        </RadarChart>
                        : null
                    }
                </div>
            </div>
            {(radarData && radarData.length > 2) && renderRadarLegend(radarData)}
        </div>
    );
};

const renderRadarLegend = () => {
    return (
        <div className="RadarLegend">
            <div className="RadarLegend__Block">
                <div className="RadarLegendBullet RadarLegendBullet--low"></div>
                <div className="RadarLegendBullet__Text">0 - 29 – низкий уровень</div>
            </div>
            <div className="RadarLegend__Block">
                <div className="RadarLegendBullet RadarLegendBullet--medium"></div>
                <div className="RadarLegendBullet__Text">30 - 69 – допустимый уровень</div>
            </div>
            <div className="RadarLegend__Block">
                <div className="RadarLegendBullet RadarLegendBullet--high"></div>
                <div className="RadarLegendBullet__Text">70 - 100 – высокий уровень</div>
            </div>
        </div>
    );
}

const renderRadarLines = (personId, data, groupId) => {
    return (
        <div className="Progress-Block">
            {
                data.map(x => {
                    const theme = getProgressLineTheme(x.value, groupId);
                    const isCriteriaActive = x.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Relevant;
                    const isTestActive = x.testStatus === EVALUATIONTEST_STATUS.Relevant;
                    return (
                        <div
                            className={classnames("Competence Competence--full",
                                { 'Competence--notActive': (!isTestActive || !isCriteriaActive) }
                            )}
                            key={x.id}
                        >
                            <a data-tip data-for={`lineHint${x.id}`}
                               href={`/person/${personId}/evaluate#${x.id}`}>{x.name}</a>
                            <Progress percent={x.value} theme={theme}/>
                            <div className="Chart-Tooltip__UnderLine">{x.provider}</div>
                            {renderLineHint(x.name, `lineHint${x.id}`)}
                        </div>
                    )
                })
            }
            
        </div>
    );
}

const renderLineHint = (title, id) => {
    const hint = getLineHintText(title)
    return (
        hint &&
        <ReactTooltip
            id={id}
            effect={'float'}
            border={true}
            type={'light'}
        >
            <p className="tooltip">
                {hint}
            </p>
        </ReactTooltip>
    );
}

const getLineHintText = (title) => {
    if (!!!title) return title;
    
    switch (title.toLowerCase()) {
        case "вербальная секция" :
            return "Умение анализировать словесную информацию и делать на её основании корректные выводы";
        case "числовая секция":
            return "Умение интерпретировать таблицы и графики, делать корректные выводы на основании числовой информации";
        case "логическая секция":
            return "Умение работать с абстрактной информацией и выявлять закономерности";
        default:
            return title;
    }
}

const radarChartTooltip = ({ active, payload, label }) => {
    if (active && payload[0] && payload[0].value > 0) {
        return (
            <div className="Chart-Tooltip">
                <div className="Chart-Tooltip__Line">{`${label} ${payload[0].value}%`}</div>
                <div className="Chart-Tooltip__UnderLine">{payload[0].payload.provider}</div>
            </div>
        );
    }
    return null;
};

const getChartParams = (testType) => typesDescr.find(x => x.type === testType);
