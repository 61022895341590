import React, { PureComponent } from 'react';
import { formatDate } from 'view';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { DataCard } from "components/common/DataCard";
import EditWorkBlock from './Edit/WorkBlock';
import DataCardProperty, { DataCardTextProperty } from "components/common/DataCard/DataCardProperty";
import DataCardBlock from "components/common/DataCard/DataCardBlock";
import ChangeVertical from 'components/uikit/ChangeVertical';
import Button from "components/uikit/Button";
import { saveBlock } from 'ducks/PersonBlock';
import { showErrorAlert } from 'ducks/Alert';
import { connect } from 'react-redux';
import ButtonsGroup from 'components/uikit/ButtonsGroup';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

class WorkBlock extends PureComponent {

    state = {
        isDragging: false,
        workPlacesArray: [],
        governmentServicesArray: [],
        loading: false,
    };

    componentDidUpdate(prevProps, prevState) {
        
        const { isDragging } = this.state;
        const { editBlock, draggedBlock } = this.props;
       
        if (prevState.isDragging !== isDragging) {
            if (isDragging) {
                this.props.setEditBlock('');
                this.props.setDraggedBlock('WorkBlock');
            } else {
                this.props.setDraggedBlock('');
            }
        }

        if (prevProps.editBlock !== editBlock && editBlock) {
            this.setState({isDragging: false});
        }

        if (prevProps.draggedBlock !== draggedBlock) {
            this.setState({isDragging: draggedBlock ? true : false});
        }
    }

    onDrag = () => {
        this.setState(
            {
                isDragging: !this.state.isDragging,
                workPlacesArray: this.props.workPlaces.toIndexedSeq().toArray()
                                    .map(x => ({...x, personId: this.props.personId})),
                governmentServicesArray: this.props.governmentServices.toIndexedSeq().toArray()
                                    .map(x => ({...x, personId: this.props.personId})),
            }
        )
    };

    onDragUp = (index, arr, stateKey) => {
        const newIndex = index - 1;
        this.moveItem(arr, index, newIndex, stateKey);
    };

    onDragDown = (index, arr, stateKey) => {
        const newIndex = index + 1;
        this.moveItem(arr, index, newIndex, stateKey);
    };

    moveItem = (arr, index, newIndex, stateKey) => {
        const movedField = arr[index];
        arr.splice(index, 1);
        arr.splice(newIndex, 0, movedField);
        this.setState({[stateKey]: this.updateOrderNum(arr)});
    };

    updateOrderNum = (arr) => {
        return arr.map((x, index) => ({
            ...x,
            orderNumber: index,
        }))
    };

    renderWorkPlace = (workPlace, index) => {
        const { isDragging, workPlacesArray, loading } = this.state;
        return (
            <div className="kr_tr" key={index}>
               
                <div className="kr_td kr_date_range">
                    <div className="kr_date_block">
                        {workPlace.hireDate && `${formatDate(workPlace.hireDate)} -`}&nbsp;
                    </div>
                    <div className="kr_date_block">
                        {workPlace.isCurrentWorkPlace && !workPlace.quitDate ? "наст.время" : (formatDate(workPlace.quitDate))}
                    </div>
                </div>
                {isDragging && 
                    <ChangeVertical 
                        index={index} 
                        size={this.props.workPlaces.size} 
                        onUp={() => this.onDragUp(index, workPlacesArray, 'workPlacesArray')}
                        onDown={() => this.onDragDown(index, workPlacesArray, 'workPlacesArray')}
                        disabled={loading}
                    />
                }
                <div className="kr_td">
                    <div className="kr_item_company">{workPlace.company + (workPlace.isMainWorkPlace && workPlace.isCurrentWorkPlace?' (основное)':'')}</div>
                    <div className="kr_item_text">
                        {workPlace.position}
                    </div>
                    {!isDragging && workPlace.industry && <DataCardProperty title="Отрасль">{workPlace.industry.name}</DataCardProperty>}
                    {!isDragging && workPlace.managementLevel && <DataCardProperty title="Уровень менеджмента">{workPlace.managementLevel.name}</DataCardProperty>}
                    {!isDragging && workPlace.employeesNumber && <DataCardProperty title="Подчиненных">{workPlace.employeesNumber.name}</DataCardProperty>}
                    {!isDragging && workPlace.workArea && <DataCardProperty title="Область деятельности">{workPlace.workArea.name}</DataCardProperty>}
                    {!isDragging && workPlace.managementExperience && <DataCardProperty title="Опыт управления">{workPlace.managementExperience.name}</DataCardProperty>}
                    {!isDragging && workPlace.professionalAchievements && <DataCardTextProperty title="Профессиональные достижения">{workPlace.professionalAchievements}</DataCardTextProperty>}
                    {!isDragging && workPlace.externalInfo && <DataCardTextProperty title="Дополнительные сведения">{workPlace.externalInfo}</DataCardTextProperty>}
                </div>
            </div>
        );
    };

    renderGovernmentService = (governmentService, index) => {
        const dateRangeValid = governmentService.isCurrent || governmentService.endDate;
        const { isDragging, governmentServicesArray, loading } = this.state;

        return (
            <div className="kr_tr" key={index}>
                <div className="kr_td kr_date_range">
                    <div className="kr_date_block">
                        {formatDate(governmentService.startDate)}{dateRangeValid && <>&nbsp;-&nbsp;</>}
                    </div>
                    {dateRangeValid && <div className="kr_date_block">
                        {governmentService.isCurrent ? "наст.время" : (formatDate(governmentService.endDate))}
                    </div>}
                </div>
                {isDragging && 
                    <ChangeVertical 
                        index={index} 
                        size={this.props.governmentServices.size} 
                        onUp={() => this.onDragUp(index, governmentServicesArray, 'governmentServicesArray')}
                        onDown={() => this.onDragDown(index, governmentServicesArray, 'governmentServicesArray')}
                        disabled={loading}
                    />
                }
                <div className="kr_td">
                    {governmentService.branch && <div className="kr_item_company">{governmentService.branch}</div>}
                    {governmentService.position && <div className="kr_item_text">{governmentService.position}</div>}
                    {!isDragging && governmentService.governmentServiceKind && <DataCardProperty title="Тип службы">{governmentService.governmentServiceKind.name}</DataCardProperty>}
                    {!isDragging && governmentService.rank && <DataCardProperty title="Звание">{governmentService.rank}</DataCardProperty>}
                    {!isDragging && governmentService.extraInfo && <DataCardTextProperty title="Дополнительные сведения">{governmentService.extraInfo}</DataCardTextProperty>}
                </div>
            </div>
        );
    };

    renderView = () => {
        const { isDragging } = this.state;
        const workPlaces = isDragging ? this.state.workPlacesArray : this.props.workPlaces;
        const governmentServices = isDragging ? this.state.governmentServicesArray : this.props.governmentServices;
        return (
            <>
            <DataCard
                shadow
                title="Опыт работы"
                className="kr_profile-anketa-view"
                id="profile-module3"
                onChange={this.props.canEditPerson ? () => this.props.setEditBlock('WorkBlock') : null}
                canDrag={this.props.canDrag}
                onDrag={this.onDrag}
                isDragging={isDragging}
                isOpen={isDragging}
            >
                <div className="kr_like-table kr_like-table_work">
                    {this.props.workPlaces && this.props.workPlaces.size
                        ? workPlaces.map(this.renderWorkPlace)
                        : this.props.renderEmptyBlock()
                    }
                    <DataCardBlock
                        title="Сведения о службе в вооружённых силах, органах безопасности"
                        isRow={false}
                    >
                        {this.props.governmentServices && this.props.governmentServices.size
                            ? governmentServices.map(this.renderGovernmentService)
                            : this.props.renderEmptyBlock()
                        }
                    </DataCardBlock>
                </div>
                {isDragging && this.renderSaveDragButtons()}
            </DataCard>
            
            </>
        );
    };

    renderEdit = () => {
        return (
            <EditWorkBlock {...this.props} />
        );
    };

    saveOrderedWorkplaces = async () => {
        try {
            this.setState({ loading: true});
            this.props.showPageLoader();
            await this.props.saveBlock ({
                blockType: 'WorkBlock',
                personId: this.props.personId,
                arr: this.state.workPlacesArray,
                isArray: true
            });

            await this.props.saveBlock ({
                blockType: 'GovernmentServiceBlock',
                personId: this.props.personId,
                arr: this.state.governmentServicesArray,
                isArray: true,
            }, true);
        } catch(e) {
            this.props.showErrorAlert('Ошибка сохранения');
        } finally {
            this.setState({ loading: false});
            this.onDrag();
            setTimeout(() => {
                this.props.hidePageLoader();
            });
        }
    };

    renderDragApplyButtons = () => {
        return [
                <Button
                    type="submit"
                    size='sm'
                    onClick={this.saveOrderedWorkplaces}
                    loading={this.state.loading}
                >
                    Сохранить
                </Button>,

                <Button type="button" onClick={this.onDrag} color="default" size='sm'>
                    Отмена
                </Button>,

                <Button type="button" onClick={() => this.onDrag(false)} color="danger" size='sm'>
                    Сбросить
                </Button>
        ]
    };

    renderSaveDragButtons = () => {
        return (
            <div className='DataCardFooter'>
                <ButtonsGroup buttons={this.renderDragApplyButtons()} position='end' />
            </div>
        );
    };

    render = () => {
        return this.props.isEditBlock ? this.renderEdit() : this.renderView();
    }
}

const actions = { saveBlock, showErrorAlert, showPageLoader, hidePageLoader };
export default connect(null, actions)(ToggleOpen(WorkBlock));