import React, { Component } from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { DataCard } from 'components/common/DataCard';
import EditEducationBlock from './Edit/EducationBlock';
import DataCardProperty from 'components/common/DataCard/DataCardProperty';
import { isNullOrWhitespace } from 'utils';

class EducationBlock extends Component {
    renderEducation(education) {
        if (!education.id) {
            return null;
        }
        return (
            <div className="kr_tr" key={education.id}>
                <div className="kr_td kr_date_range">
                    <div className="kr_date_block">
                        {!!education.graduationYear && `${education.graduationYear} г.`}
                    </div>
                </div>
                <div className="kr_td">
                    <div className="kr_item_company">{education.university}</div>
                    <div className="kr_item_text">{education.specialty}</div>
                    {!isNullOrWhitespace(education.faculty) && (
                        <DataCardProperty title="Факультет">{education.faculty}</DataCardProperty>
                    )}
                    {education.educationLevel && (
                        <DataCardProperty title="Уровень образования">
                            {education.educationLevel.name}
                        </DataCardProperty>
                    )}
                    {education.studyForm && (
                        <DataCardProperty title="Форма обучения">
                            {education.studyForm.name}
                        </DataCardProperty>
                    )}
                    {!isNullOrWhitespace(education.extraInfo) && (
                        <DataCardProperty title="Повышение квалификации и другая информация о дополнительном образовании">
                            {education.extraInfo}
                        </DataCardProperty>
                    )}
                </div>
            </div>
        );
    }

    renderView() {
        return (
            <DataCard
                shadow
                title="Образование"
                className="kr_profile-anketa-view"
                onChange={
                    this.props.canEditPerson
                        ? () => this.props.setEditBlock('EducationBlock')
                        : null
                }
            >
                <div className="kr_like-table kr_like-table_work">
                    {this.props.educations && this.props.educations.size
                        ? this.props.educations.map(this.renderEducation)
                        : this.props.renderEmptyBlock()}
                </div>
            </DataCard>
        );
    }

    renderEdit() {
        return <EditEducationBlock {...this.props} setEditBlock={this.props.setEditBlock} />;
    }

    render() {
        return this.props.isEditBlock ? this.renderEdit() : this.renderView();
    }
}

export default ToggleOpen(EducationBlock);
