import React from 'react';
import classnames from 'classnames';
import Signals from './Charts/Signals';
import List from './Charts/List';
import Web from './Charts/Web';
import { EVALUATION_TYPE_CAREER } from 'constants.js';

const TestChart = (props) => {
    const {
        test,
        chartType,
        config,
        testProviders,
        testsResults,
        competenciesModels,
        fixedWidth,
    } = props;

    const getCompetenceDescription = (resultId, criteriaId) => {
        const result = testsResults.find((x) => x.result.id === resultId);
        const criteria = Object.entries(result.criteria).find((x) => x[0] === criteriaId);
        const competencyDescr = competenciesModels
            .map((x) => x.competencies)
            .flat()
            .find(
                (x) =>
                    x.competencyModelId === result.competencyModelId &&
                    x.personGrowthTagId === criteria[1].personGrowthTagId,
            );
        if (competencyDescr) {
            return competencyDescr && competencyDescr.description;
        }
    };

    const getCriteriaDescription = (resultId, criteriaId) => {
        const result = testsResults.find((x) => x.result.id === resultId);
        const criteria = Object.entries(result.criteria).find((x) => x[0] === criteriaId);
        return criteria && Object.entries(criteria).length >= 2 && criteria[1].description;
    };

    const renderChart = () => {
        const { withHeader = false, withDescr = false } = config;
        switch (chartType) {
            case 'Signals':
                return <Signals data={test} withHeader={withHeader} withDescr={withDescr} />;
            case 'List':
                return (
                    <List
                        getCompetenceDescription={
                            test.kind === 'Competency'
                                ? getCompetenceDescription
                                : getCriteriaDescription
                        }
                        data={test}
                        withHeader={withHeader}
                        withDescr={withDescr}
                        fixedWidth={fixedWidth}
                    />
                );
            case 'Web':
                return (
                    <Web
                        testProviders={testProviders}
                        data={test}
                        withHeader={withHeader}
                        withDescr={withDescr}
                        fixedWidth={fixedWidth}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <div
            className={classnames('LKAdaptiveChart', {
                'LKAdaptiveChart--full': chartType === 'Signals' || test.id === EVALUATION_TYPE_CAREER,
            })}
        >
            {renderChart()}
        </div>
    );
};

export default TestChart;
