import React from 'react';
import MenuFilter from 'components/Lk/Uikit/Filter/MenuFilter';
import SorterButton from 'components/Lk/Uikit/Buttons/SorterButton';
import { sortingOptions, hasNoPlanMenuOptions, ALL_MENU_ID } from './constants';

const Filters = props => {
    
    const { selectedSorting, setSelectedSorting, selectedFilter, setSelectedFilter } = props;
    
    const handleFilterChange = value => {
        if (value !== ALL_MENU_ID && selectedSorting.type !== undefined) {
            setSelectedFilter(value);
            setSelectedSorting(sortingOptions[0]);
        } else {
            setSelectedFilter(value);
        }
    };

    const handleSortingChange = value => {
        if (selectedFilter === ALL_MENU_ID) {
            setSelectedSorting(value);
        }
    };

    return (
        <div className="LKProgressFilterFilters">
            <MenuFilter
                options={hasNoPlanMenuOptions}
                value={selectedFilter}
                onChange={handleFilterChange}
            />
            
            <SorterButton
                options={sortingOptions}
                value={selectedSorting}
                onChange={handleSortingChange}
            />
        </div>
    );
};

export default Filters;