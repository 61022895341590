import React from 'react';
import PropTypes from 'prop-types';
import JSONTree from 'react-json-tree';
import { localizeTimezone } from 'utils';
import './UserActionCard.scss';
import UserActionActiveFilters from 'components/Search/UserActionActiveFilters';

function UserActionCard(props) {
    const {
        userName,
        actionName,
        created,
        info,
        error,
        ipAddress,
        userAgent,
        description,
        isSupport,
    } = props;

    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const isJson = isJsonString(info);
    const json = isJson ? JSON.parse(info) : null;
    const criteria = json.Payload?.criteria;

    return (
        <div className="user-action-card">
            <div className="user-action-card-line">
                <div className="user-action-card-line__header">Имя пользователя</div>
                <div className="user-action-card-line__info">{userName}</div>
            </div>

            <div className="user-action-card-line">
                <div className="user-action-card-line__header">Дата</div>
                <div className="user-action-card-line__info">{localizeTimezone(created)}</div>
            </div>

            {!!actionName && (
                <div className="user-action-card-line">
                    <div className="user-action-card-line__header">Действие</div>
                    <div className="user-action-card-line__info">{actionName}</div>
                </div>
            )}

            {!!ipAddress && (
                <div className="user-action-card-line">
                    <div className="user-action-card-line__header">IP Адрес</div>
                    <div className="user-action-card-line__info">{ipAddress}</div>
                </div>
            )}

            {userAgent && (
                <div className="user-action-card-line">
                    <div className="user-action-card-line__header">UserAgent</div>
                    <div className="user-action-card-line__info">{userAgent}</div>
                </div>
            )}

            {!!description && (
                <div className="user-action-card-line">
                    <div className="user-action-card-line__header">Описание</div>
                    <div className="user-action-card-line__info">{description}</div>
                </div>
            )}

            {info && !isSupport && (
                <div className="user-action-card-line">
                    <div className="user-action-card-line__header">Информация</div>
                    <div className="user-action-card-line__info">
                        <pre className="user-action-card-line__json">
                            {isJson ? (
                                <JSONTree
                                    data={json}
                                    hideRoot={true}
                                    shouldExpandNode={(keyName, data, level) => {
                                        return level <= 2;
                                    }}
                                />
                            ) : (
                                info
                            )}
                        </pre>
                    </div>
                </div>
            )}

            {info &&
                isJson &&
                (!!criteria?.Filter?.FieldsRaw ||
                    !!criteria?.SearchTerm ||
                    !!criteria?.Filter?.FilterRaw) && (
                    <div className="user-action-card-line">
                        <div className="user-action-card-line__header">Фильтр</div>
                        <div className="user-action-card-line__info">
                            <UserActionActiveFilters
                                isSearch={!criteria?.Filter?.FilterRaw}
                                searchTerm={criteria?.SearchTerm}
                                activefilters={JSON.parse(
                                    criteria?.Filter?.FieldsRaw || criteria?.Filter?.FilterRaw,
                                )}
                            />
                        </div>
                    </div>
                )}

            {error && (
                <div className="user-action-card-line">
                    <div className="user-action-card-line__header">Ошибка</div>
                    <div className="user-action-card-line__info">{error}</div>
                </div>
            )}

            {!info && !error && (
                <div className="user-action-card-line">
                    <div className="user-action-card__no-data">Нет дополнительной информации</div>
                </div>
            )}
        </div>
    );
}

UserActionCard.propTypes = {
    userName: PropTypes.string.isRequired,
    actionName: PropTypes.string,
    created: PropTypes.string.isRequired,
    info: PropTypes.string,
    error: PropTypes.string,
};

export default UserActionCard;
