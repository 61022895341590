import React from 'react';
import PropTypes from 'prop-types';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import { Link } from 'react-router-dom';

import './CardLink.scss';

const CardLink = props => {
    const { icon, name, description, router, params, dataTest } = props;

    return (
        <Link className="CardLink" to={{ pathname: router.url, state: { ...params } }}>
            <div className="CardLink__Icon">
                <Icons name={icon} />
            </div>
            <div className="CardLink__Body">
                <h5 className="CardLink__Title" data-test={`${dataTest}_title`}>{name}</h5>
                {description && <div className="CardLink__Content" data-test={`${dataTest}_description`}>{description} </div>}
            </div>
        </Link>
    );
};

CardLink.propTypes = {
    onClick: PropTypes.func,
    name: PropTypes.string.isRequired,
};

export default CardLink;
