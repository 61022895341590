import React from 'react';
import PropTypes from 'prop-types';
import RoundButton from "components/uikit/Button/RoundButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserMessageTemplate = (props) => {
    const {handleUpDividerDragEnter, handleUpDividerDragLeave,
            handleDownDividerDragEnter, handleDownDividerDragLeave, 
            index, text, deleteTemplateChild } = props;
    return (
        <div className="TemplateItemContainer">
            <div className="TemplateItemContainer__UpDivider"
                onDragEnter={handleUpDividerDragEnter} 
                onDragLeave={handleUpDividerDragLeave}  
                data-index={index}                              
            />
            <div className="TemplateItem">
                <div className="TemplateItem__Text">
                    <div className="TemplateItem__Text--icon"><FontAwesomeIcon icon="comment" size="2x" /></div>
                    <div className="TemplateItem__Text--text">{text}</div>
                </div>
                <div className="TemplateItem__Buttons"> 
                    <RoundButton type={"close"} onClick={deleteTemplateChild(index)}/>
                </div>
            </div>
            <div className="TemplateItemContainer__DownDivider" 
                onDragEnter={handleDownDividerDragEnter} 
                onDragLeave={handleDownDividerDragLeave}
                data-index={index + 1}
            />
        </div>
    );
};

UserMessageTemplate.propTypes = {
    handleUpDividerDragEnter: PropTypes.func,
    handleUpDividerDragLeave: PropTypes.func,
    handleDownDividerDragEnter: PropTypes.func,
    handleDownDividerDragLeave: PropTypes.func,
    index: PropTypes.number,
    text: PropTypes.string,
    deleteTemplateChild: PropTypes.func,
};

export default UserMessageTemplate;