import React from 'react';
import './ProfileCard.scss';

const NoData = props => {
    const { text } = props;
    return (
        <div className='LKProfileNoData'>{text}</div>
    );
};

export default NoData;