import classnames from 'classnames';
import React from 'react';

import './Badge.scss';

const Badge = (props) => {
    const {
        children,
        color = 'primary',
        className,
        ...tail
    } = props;

    const badgeClassName = classnames(
        'Badge',
        `Badge--color_${color}`,
        className
    );

    return (
        <div
            className={badgeClassName}
            {...tail}
        >
            <div className='Badge__Content'>
                {children}
            </div>
        </div>
    );
}

export default Badge;
