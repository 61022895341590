import { useRef, useEffect } from 'react';

const useMountedEffect = (callback = false, deps = [], dispose = false) => {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;
    ref.current && callback && callback();

    return () => {
      ref.current = false;
      dispose && dispose();
    };
    // eslint-disable-next-line
  }, deps);
  
  return ref.current;
}

export default useMountedEffect;