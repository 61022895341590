import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    getPersonGrowthPlans,
    savePersonGrowthPlan,
    updatePersonGrowthPlan,
    getPersonGrowthPlan,
    getPersonGrowthReportPdfDownloadLink,
    getPersonTeams,
    clearPersonGrowthPlanSession,
    deletePersonGrowthPlan,
} from 'api';
import { isNullOrWhitespace, getMonths, getYears, capitalizeFirstLetter } from 'utils';
import { approveStatuses, PERSONGROWTHPLAN_STATUS, PERSONGROWTHPLAN_TYPE } from 'constants.js';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { evolutionPlan, evolutionEditPlan } from 'routes';
import PersonPlanEdit from './PersonPlanEdit';
import BlockButton from '../Edit/BlockButton';
import PersonCard from 'components/common/PersonCard';
import ModalDialog from 'components/common/ModalDialog';
import ChevronButton from 'components/uikit/ChevronButton';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import InputText from 'components/uikit/InputText';
import Button from 'components/uikit/Button';
import Select from 'components/uikit/Select';
import RoundButton from 'components/uikit/Button/RoundButton';
import { Col, Row } from 'react-flexbox-grid';
import PersonShortInfo from 'components/Person/Teams/PersonShortInfo';
import { serviceResultCode, getError } from 'serviceErrors';
import './PersonProgress.scss';

const optionsMonths = getMonths();
const optionsYear = getYears(new Date().getFullYear(), 5);

const assistanceType = {
    Gemba: 'Гемба',
    Internship: 'Стажировка',
    Project: 'Проект',
    Issue: 'Задача',
    Shadowing: 'Shadowing',
};

const ResourceCard = (props) => {
    return (
        <div className="Person-Progress__ResourceCard">
            <div
                className="Person-Progress__ResourceImage"
                style={{
                    backgroundImage: `url(api/personGrowthResource/${props.itemId}/file/download)`,
                }}
            ></div>
            <div className="Person-Progress__ResourceTitle">
                <a href={props.url} target="_blank" rel="noopener noreferrer">
                    {props.name}
                </a>
            </div>
        </div>
    );
};

class PersonProgress extends Component {
    _isMounted = false;

    state = {
        data: null,
        loaded: false,
        openedGoals: [],
        removeId: null,
        isOpenClearModal: false,
        newGoalText: '',
        newGoalStartDate: new Date(),
        newGoalEndDate: new Date(),
        processing: false,
        editGoal: {
            personId: this.props.id,
            type: PERSONGROWTHPLAN_TYPE.Short,
            title: '',
            minLengthWarning: true,
            start: new Date(),
            end: new Date(),
            status: null,
        },
        fileLoading: false,
        editPlanId: null,
        isDraft: true,
        isShortPlan: false,
        isApproved: false,
        approves: [],
    };

    handleStateChange = (key, value) => this.setState((state) => ({ ...state, [key]: value }));

    acceptModal = () => {
        this.handleStateChange('processing', true);

        const { id, status } = this.state.editGoal;

        if (id && status === PERSONGROWTHPLAN_STATUS.Draft) {
            this.handleStateChange('editGoal', {
                ...this.state.editGoal,
                status: PERSONGROWTHPLAN_STATUS.Process,
            });
        }

        const editGoal = {
            ...this.state.editGoal,
            status:
                id && status === PERSONGROWTHPLAN_STATUS.Draft
                    ? PERSONGROWTHPLAN_STATUS.Process
                    : status,
        };

        this.callEffect(async () => {
            this.state.editGoal.id
                ? await updatePersonGrowthPlan(editGoal)
                : await savePersonGrowthPlan(editGoal);

            this.updateData();
        });
    };

    editCompetence = (planId, isSession) => () =>
        this.props.push(
            evolutionEditPlan.buildUrl({ id: this.props.id, planId: planId, isSession: isSession }),
        );

    selectCompetence = () => this.props.push(evolutionPlan.buildUrl({ id: this.props.id }));

    showAddGoalModal = () => {
        this._isMounted &&
            this.setState({
                isOpenAddGoalModal: true,
                editGoal: {
                    personId: this.props.id,
                    type: PERSONGROWTHPLAN_TYPE.Short,
                    title: '',
                    minLengthWarning: true,
                    start: new Date(),
                    end: moment().endOf('month').toDate(),
                    status: PERSONGROWTHPLAN_STATUS.Process,
                },
            });
    };

    editShortGoal = (goal) => {
        this._isMounted &&
            this.setState({
                isOpenAddGoalModal: true,
                editGoal: {
                    id: goal.id,
                    personId: goal.personId,
                    type: goal.type,
                    title: goal.title,
                    minLengthWarning: goal.title.length < 10,
                    start: goal.start,
                    end: new Date(goal.end),
                    status: goal.status,
                },
            });
    };

    editFullGoal = (planId, isSession) =>
        this.props.push(
            evolutionEditPlan.buildUrl({ id: this.props.id, planId: planId, isSession: isSession }),
        );

    toggleGoalOpen = (goalId) => {
        this._isMounted &&
            this.setState((state) => {
                return {
                    ...state,
                    openedGoals: state.openedGoals.map((x) => {
                        return {
                            ...x,
                            isOpen: x.id === goalId ? !x.isOpen : x.isOpen,
                        };
                    }),
                };
            });
    };

    render() {
        const { loaded, data } = this.state;
        return (
            loaded &&
            data && (
                <div>
                    <PersonCard {...this.props.person} />
                    {this.renderProgressBlock()}
                </div>
            )
        );
    }

    componentDidMount() {
        this._isMounted = true;
        this.updateData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateData = async () => {
        const res = await getPersonGrowthPlans(this.props.id);
        const openedGoals = res.data.map((x) => {
            return {
                id: x.id,
                isOpen: false,
            };
        });
        this._isMounted &&
            this.setState((state) => {
                return {
                    ...state,
                    data: res.data,
                    openedGoals: openedGoals,
                    loaded: true,
                    isOpenAddGoalModal: false,
                    processing: false,
                    editPlanId: null,
                };
            });

        this.callEffect(async () => {
            const teams = (await getPersonTeams(this.props.id)).data;

            let leaders =
                teams &&
                teams.following &&
                Array.isArray(teams.following) &&
                teams.following.map((x) => x.leaderPerson);

            this._isMounted &&
                this.setState((state) => {
                    return {
                        ...state,
                        approvers: leaders,
                    };
                });
        });
    };

    renderProgressBlock = () => {
        return this.state.data && this.state.data.length > 0 ? (
            <>
                {this.renderProgressGoals()}
                {this.renderProgressPlan()}
            </>
        ) : (
            <>
                {this.renderProgressPlan()}
                {this.renderProgressGoals()}
            </>
        );
    };

    renderProgressPlan = () => {
        return (
            <div className="Person-Progress">
                <div className="Header">
                    <div className="Header__Text">Мой план развития</div>
                </div>
                <div className="Body">
                    <div className="Body__Buttons-List">
                        <BlockButton type="add" onClick={this.selectCompetence}>
                            Выбрать компетенцию для развития
                        </BlockButton>
                        <BlockButton type="add" onClick={this.showAddGoalModal}>
                            Добавить свою цель развития
                        </BlockButton>
                        {this.renderAddGoalModal()}
                        {this.renderModalOnRemove()}
                    </div>
                </div>
            </div>
        );
    };

    modalIsValid = () => {
        return (
            !isNullOrWhitespace(this.state.editGoal.title) &&
            this.state.editGoal.title.trim().length >= 10 &&
            this.state.editGoal.end &&
            moment().isSameOrBefore(this.state.editGoal.end) &&
            this.state.editGoal.status != null
        );
    };

    generateManualGoalEndDate = (year, month) => {
        return moment(new Date(year, month)).endOf('month').toDate();
    };

    handleManualGoalTitle = (e) => {
        const newEditGoalBlock = {
            ...this.state.editGoal,
            title: e.target.value,
            minLengthWarning: e.target.value.trim().length < 10,
        };

        this.handleStateChange('editGoal', newEditGoalBlock);
    };

    handleManualGoalEndDate = (e) => {
        const { end } = this.state.editGoal;

        const endDate =
            e.value < 12
                ? this.generateManualGoalEndDate(end.getFullYear(), e.value)
                : this.generateManualGoalEndDate(e.value, end.getMonth());

        const newEditGoalBlock = {
            ...this.state.editGoal,
            end: endDate,
        };

        this.handleStateChange('editGoal', newEditGoalBlock);
    };

    renderModalOnRemove = () => {
        const { isShortPlan } = this.state;
        const modalHeader = isShortPlan
            ? 'Вы уверены, что хотите удалить цель развития без возможности восстановления?'
            : 'Вы уверены, что хотите удалить ИПР без возможности его восстановления?';
        return (
            <ModalDialog
                onClick={this.onRemoveGoal}
                onCloseModal={() => this.setState({ removeId: null, isDraft: true })}
                modalOpen={this.state.removeId !== null}
                modalHeader={modalHeader}
                btnOktext="Да"
                btnCanceltext="Нет"
                size="lg"
            />
        );
    };

    renderAddGoalModal = () => {
        const { id, title, minLengthWarning, end, status } = this.state.editGoal;
        return (
            <ModalDialog
                onClick={this.acceptModal}
                onCloseModal={() => {
                    this.setState({ isOpenAddGoalModal: false });
                }}
                modalOpen={this.state.isOpenAddGoalModal}
                modalHeader="Дополнительная цель развития"
                btnOktext={
                    !id || status === PERSONGROWTHPLAN_STATUS.Draft ? 'Сохранить' : 'Изменить'
                }
                btnCanceltext="Отмена"
                isValidForm={this.modalIsValid()}
                processing={this.state.processing}
                size="lg"
                noHeaderDivider
            >
                <div className="Modal-Children">
                    <div className="Modal-Children__Line">
                        <Field>
                            <InputText value={title} onChange={this.handleManualGoalTitle} />
                            <div className="Modal-Children__ErrorLine">
                                {minLengthWarning &&
                                    'Минимальное кол-во символов 10. Осталось ' +
                                        ((title &&
                                            title.trim.length < 10 &&
                                            10 - title.trim().length) ||
                                            10)}
                            </div>
                        </Field>
                    </div>

                    <div className="Modal-Children__Line">
                        <Field>
                            <Label>Год</Label>
                            <Select
                                value={
                                    end && optionsYear.find((x) => x.value === end.getFullYear())
                                }
                                inputId="endDateYear"
                                placeholder={<div>Выберите год</div>}
                                options={optionsYear}
                                onChange={this.handleManualGoalEndDate}
                            />
                        </Field>
                        <Field>
                            <Label>Месяц</Label>
                            <Select
                                value={end && optionsMonths.find((x) => x.value === end.getMonth())}
                                inputId="endDateMonth"
                                placeholder={<div>Выберите месяц</div>}
                                options={optionsMonths}
                                onChange={this.handleManualGoalEndDate}
                            />
                        </Field>
                    </div>
                    <div className="Modal-Children__ErrorLine">
                        <span>{!moment().isSameOrBefore(end) && 'Укажите актуальную дату'}</span>
                    </div>
                    <div className="Modal-Children__Line"></div>
                </div>
            </ModalDialog>
        );
    };

    renderProgressGoals = () => {
        return (
            <div className="Person-Progress">
                <div className="Header">
                    <div className="Header__Text">Цели развития</div>
                </div>
                <div className="Body">
                    <div className="Body__List">{this.renderGoalsList()}</div>
                </div>
            </div>
        );
    };

    onRemoveGoal = async () => {
        const { removeId, isDraft, isShortPlan } = this.state;
        const { showPageLoader, hidePageLoader, showErrorAlert } = this.props;

        try {
            showPageLoader();
            isDraft
                ? await clearPersonGrowthPlanSession(this.props.id, removeId)
                : await deletePersonGrowthPlan(removeId);
            isDraft
                ? this.props.showSuccessAlert('Черновик ИПР успешно удален')
                : isShortPlan
                ? this.props.showSuccessAlert('Цель развития успешно удалена')
                : this.props.showSuccessAlert('План развития успешно удален');
            this.setState({ removeId: null, isDraft: true });
            this.updateData();
        } catch (e) {
            this.setState({ removeId: null });
            showErrorAlert(e.response.data.payload);
        } finally {
            hidePageLoader();
        }
    };

    renderGoalsList = () => {
        const goals = this.state.data;
        if (!goals || !goals.length) {
            return <div className="Person-Progress__NoData">Цели развития пока отсутствуют</div>;
        }

        return goals.map((goal) => {
            return (
                <div className="Line " key={goal.id}>
                    <div className="Sub-Line GoalTitle">
                        <div className="Sub-Line__Left Sub-Line__Header GoalTitle__Header">
                            <span className="GoalTitle__text">
                                {this.getTitle(goal)}{' '}
                                {goal.status === PERSONGROWTHPLAN_STATUS.Draft && (
                                    <span> (Черновик)</span>
                                )}
                            </span>
                            {goal.status === PERSONGROWTHPLAN_STATUS.Draft && (
                                <button
                                    className="GoalTitle__Button"
                                    onClick={() =>
                                        this.setState({
                                            removeId: goal.id,
                                            isDraft: true,
                                            isShortPlan: false,
                                        })
                                    }
                                >
                                    Удалить
                                </button>
                            )}
                        </div>
                        <div className="Sub-Line__Right">
                            <div className="Sub-Line__Right--chevron">
                                <ChevronButton
                                    isUp={this.isGoalOpen(goal.id)}
                                    onClick={() => this.toggleGoalOpen(goal.id)}
                                />
                            </div>
                        </div>
                    </div>
                    {this.isGoalOpen(goal.id) && (
                        <div className="Sub-Line">{this.renderGoal(goal)}</div>
                    )}
                </div>
            );
        });
    };

    isGoalOpen = (goalId) => {
        const { openedGoals } = this.state;
        return openedGoals.find((x) => x.id === goalId).isOpen;
    };

    renderGoal = (goal) => {
        const { editPlanId } = this.state;
        return editPlanId && editPlanId === goal.id
            ? this.renderPlanEdit(goal.id)
            : goal.type === PERSONGROWTHPLAN_TYPE.Short
            ? this.renderShortGoal(goal)
            : this.renderFullGoal(goal);
    };

    renderShortGoal = (goal) => {
        return (
            <div className="Goal">
                <div className="Goal-Part">
                    <div className="Goal-Part__Header">
                        <div>Цель развития</div>
                        <div>
                            <RoundButton
                                type="close"
                                onClick={() =>
                                    this.setState({
                                        removeId: goal.id,
                                        isDraft: false,
                                        isShortPlan: true,
                                    })
                                }
                            />
                            <RoundButton type="edit" onClick={() => this.editShortGoal(goal)} />
                        </div>
                    </div>
                    <div className="Goal-Part__Line">{goal.title}</div>
                </div>
                <div className="Goal-Part">
                    <div className="Goal-Part__Header">Срок выполнения</div>
                    <div className="Goal-Part__Line">
                        {goal.end && capitalizeFirstLetter(moment(goal.end).format('MMMM YYYY'))}
                    </div>
                </div>
            </div>
        );
    };

    renderApproverItem = (item) => {
        const approver = this.state.approvers.find((x) => x.userId === item.approverId);
        const currStatusObj = approveStatuses.find((x) => x.status === item.status);
        const plan = this.state.data.find((x) => x.id === item.personGrowthPlanId);
        const planStatus = plan && plan.status;
        const currStatus =
            planStatus === PERSONGROWTHPLAN_STATUS.Draft
                ? ''
                : currStatusObj && currStatusObj.label;
        return (
            <div className="EvolutionPlanApproversList__Line" key={item.id}>
                {!!approver && (
                    <Row between="xs" middle="xs">
                        <Col xs={8}>
                            <PersonShortInfo person={approver} />
                        </Col>
                        <Col xs={3}>
                            {!item.approverId ? <div>Не зарегистрирован</div> : null}
                            {currStatus}
                        </Col>
                    </Row>
                )}
            </div>
        );
    };

    renderFullGoal = (goal) => {
        const { fileLoading } = this.state;
        return (
            <div className="Goal">
                <div className="Goal-Part">
                    <div className="Goal-Part__Header">
                        <div>Цель развития</div>
                        {goal.status !== PERSONGROWTHPLAN_STATUS.Draft && (
                            <div>
                                <RoundButton
                                    type="close"
                                    onClick={() =>
                                        this.setState({
                                            removeId: goal.id,
                                            isApproved: goal.isApproved,
                                            approves: goal.approves,
                                            isDraft: goal.status === PERSONGROWTHPLAN_STATUS.Draft,
                                            isShortPlan: false,
                                        })
                                    }
                                />
                                {!goal.isApproved && (
                                    <RoundButton
                                        type="edit"
                                        onClick={() => this.editFullGoal(goal.id, false)}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="Goal-Part__Line">
                        {goal.title || <div className="Goal-Part__Line">Не указано</div>}
                    </div>
                </div>
                {!isNullOrWhitespace(goal.expectedResult) ? (
                    <div className="Goal-Part">
                        <div className="Goal-Part__Header">Желаемый конечный результат</div>
                        <div className="Goal-Part__Line">{goal.expectedResult}</div>
                    </div>
                ) : null}
                <div className="Goal-Part">
                    <div className="Goal-Part__Header">Инструменты для развития</div>
                    {goal.resources && goal.resources.length > 0 ? (
                        this.renderGoalInstruments(goal)
                    ) : (
                        <div className="Goal-Part__Line">Не выбраны</div>
                    )}
                </div>
                <div className="Goal-Part">
                    <div className="Goal-Part__Header">Кто поможет в развитии компетенции</div>
                    {goal.personGrowthAssistants &&
                    goal.personGrowthAssistants.filter((x) => !!x.fullName).length > 0 ? (
                        goal.personGrowthAssistants.map((mentor) => (
                            <div key={mentor.id}>
                                <div className="Goal-Part__FullNameLine">{mentor.fullName}</div>
                                <div className="Goal-Part__Line--gray">{mentor.position}</div>
                                <div className="Goal-Part__DetailsLine">
                                    {mentor.assistanceDetails}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="Goal-Part__Line">Не указано</div>
                    )}
                </div>
                <div className="Goal-Part">
                    <div className="Goal-Part__Header">Что поможет в развитии компетенции</div>
                    {goal.personGrowthEvents && goal.personGrowthEvents.length > 0 ? (
                        goal.personGrowthEvents.map((assistance) => (
                            <div className="Goal-Part__Line" key={assistance.id}>
                                {assistanceType[assistance.type]}{' '}
                                {assistance.assistanceDetails &&
                                    `(${assistance.assistanceDetails})`}
                            </div>
                        ))
                    ) : (
                        <div className="Goal-Part__Line">Не указано</div>
                    )}
                </div>
                <div className="Goal-Part">
                    <div className="Goal-Part__Header">Срок выполнения</div>
                    <div className="Goal-Part__Line">
                        {goal.end ? (
                            capitalizeFirstLetter(moment(goal.end).format('MMMM YYYY'))
                        ) : (
                            <div className="Goal-Part__Line">Не указано</div>
                        )}
                    </div>
                </div>
                <div className="Goal-Part">
                    {goal.approves && Array.isArray(goal.approves) && goal.approves.length > 0 ? (
                        <>
                            <div className="Goal-Part__Header">Согласование плана</div>
                            {goal.approves.map(this.renderApproverItem)}
                        </>
                    ) : null}
                </div>
                <div className="Goal-Part">
                    {goal.status === PERSONGROWTHPLAN_STATUS.Draft ? (
                        <Button
                            className=""
                            type="button"
                            size="sm"
                            onClick={this.editCompetence(goal.id, true)}
                        >
                            Редактировать
                        </Button>
                    ) : (
                        <Button
                            className=""
                            type="button"
                            size="sm"
                            faIcon={faFileDownload}
                            loading={fileLoading}
                            disabled={fileLoading}
                            onClick={() => this.handleDownload(goal.id)}
                        >
                            Сохранить в PDF
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    renderPlanEdit = (planId) => {
        return (
            <PersonPlanEdit
                planId={planId}
                getPersonGrowthPlan={getPersonGrowthPlan}
                personId={this.props.id}
                updateData={this.updateData}
            />
        );
    };

    getTitle = (goal) => {
        if (goal.type === PERSONGROWTHPLAN_TYPE.Short) {
            return goal.title;
        }

        if (goal.competencies && goal.competencies.length > 0) {
            return goal.competencies.map((x) => x.name).join(' / ');
        }

        return 'Цель развития';
    };

    handleDownload = async (goalId) => {
        if (goalId.length === 0) {
            return;
        }

        this.setState({ fileLoading: true });
        try {
            const downloadLink = getPersonGrowthReportPdfDownloadLink(goalId);
            window.location = downloadLink;
        } finally {
            this.setState({ fileLoading: false });
        }
    };

    renderGoalInstruments = (goal) => {
        return goal.resources.map((item) => {
            return <ResourceCard key={item.id} itemId={item.id} url={item.link} name={item.name} />;
        });
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            await callback();
        } catch (error) {
            const reqError = getError(error, this.getPersonProgressError);
            this.props.showErrorAlert(reqError.message);
            if (this.state.removeId !== null) {
                this.setState({ removeId: null, isDraft: true });
            }
        } finally {
            this.props.hidePageLoader();
        }
    };

    getPersonProgressError = (code, payload) => {
        switch (code) {
            case serviceResultCode.Forbidden:
                return `${payload}`;
            case serviceResultCode.PersonGrowthPlanDeleteError:
                return `${payload}`;
            default:
                return !!payload ? `${payload}` : `Произошла непредвиденная ошибка`;
        }
    };
}

const actions = {
    push,
};

export default connect(null, actions)(PersonProgress);
