import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import './HeadersMenu.scss';

const FormattedAnchor = ({ anchorText }) => {
    const html = document.createElement('span');
    html.innerHTML = anchorText;
    return html.innerHTML;
};

const HeadersMenu = (props) => {
    const onClear = (item) => async (e) => {
        e.preventDefault();
        const { location } = props;
        if (location.hash === item.link) {
            await props.history.replace({ ...location, hash: '' });
        }
        props.history.push({ ...location, hash: item.link });
    };

    const [headers, setHeaders] = useState([]);

    const { items = [] } = props;

    useEffect(() => {
        items && setHeaders(items.map((x) => ({ ...x, isOpen: false })));
    }, [items]);

    const onExpand = (link) => {
        const activeHeaders = headers.map((x) => ({
            ...x,
            isOpen: x.link === link ? !x.isOpen : x.isOpen,
        }));
        activeHeaders && setHeaders(activeHeaders);
    };
    
    return (
        <div className="HeadersMenu">
            <ul className={'HeadersMenu__HeadersList'}>
                {headers &&
                    headers?.map((item) => (
                        <div className={'HeadersMenu__HeadersListItem'} key={item.link}>
                            <div
                                className={'HeadersMenu__Icon'}
                                onClick={() => onExpand(item.link)}
                            >
                                {item.childMenus?.length > 0 ? (
                                    item.isOpen ? (
                                        <Icons name="caret_up" />
                                    ) : (
                                        <Icons name="caret_down" />
                                    )
                                ) : (
                                    <Icons name="caret_disabled" />
                                )}
                            </div>
                            <li>
                                <Link
                                    onClick={onClear(item)}
                                    to={(loc) => ({ ...loc, hash: item.link })}
                                >
                                    <FormattedAnchor anchorText={item.title} />
                                </Link>
                                {item.isOpen && (
                                    <ul className={'HeadersMenu__SubHeadersList'}>
                                        {item.childMenus &&
                                            item.childMenus.length > 0 &&
                                            item.childMenus.map((child) => (
                                                <li key={child.link}>
                                                    <Link
                                                        onClick={onClear(child)}
                                                        to={(loc) => ({ ...loc, hash: child.link })}
                                                    >
                                                        <FormattedAnchor anchorText={child.title} />
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </li>
                        </div>
                    ))}
            </ul>
        </div>
    );
};

export default withRouter(HeadersMenu);
