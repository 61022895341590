import React, { Component } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

class Employees extends Component {

    employeesChartTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="Chart-Tooltip">
                    <div className="Chart-Tooltip__Line">{`${label} год.`}</div>
                    <div className="Chart-Tooltip__Line">{`${this.employeesFormatter(payload[0].value)} человек.`}</div>
                </div>
            );
        }
        return null;
    };

    employeesFormatter = (id) => {
        const elem = this.props.employeesNumbersCatalog.data.find(x => x.id === id);
        return elem && elem.name;
    }

    render() {
        return (
            this.renderEmployeesChart()
        );
    }

    renderEmployeesChart = () => {
        const chartData = this.props.employeesNumbersData;
        return ( 
            <div className="Chart">
                <div className="Chart__Header">
                    Количество человек в подчинении
                </div>
                {chartData && chartData.length !== 0 
                ?       
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart
                        data={chartData}
                        margin={{ top: 0, right: 0, left: 100, bottom: 0 }}>
                    >
                        <CartesianGrid strokeDasharray="3 0" />
                        <XAxis dataKey="year" tick={{fontSize: 16}} />
                        <YAxis ticks={[1, 2, 3, 4, 5, 6]} tickFormatter={this.employeesFormatter} tick={{width: 300, fontSize: 16}} />
                        <Tooltip content={this.employeesChartTooltip}/>
                        <Area type="monotone" dataKey="amount" stroke="#323963" fill="#cadffa" />
                    </AreaChart>
                </ResponsiveContainer>
                :
                <div className="No-Data">
                    Данные не найдены
                </div>
                }
            </div>
            
        );
    }
}

export default Employees;