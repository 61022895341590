import React from 'react';
import { connect } from 'react-redux';
import InfoField from 'components/Lk/Uikit/InfoField';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import DatePicker from 'components/uikit/DatePicker';
import AttributeFileUploadButton from 'components/Person/Edit/AttributeFileUploadButton';
import { Select } from 'components/Lk/Uikit/Select/Select';
import InputField from 'components/Lk/Uikit/InfoField/InputField';
import classnames from 'classnames';
import NoData from './NoData';
import { getAttributeAttachmentDownloadLink, } from 'api';

const AttributesInfo = props => {
    const { person, isEdit, onChange, attributeTypes, setFileUploading } = props;

    const {
        attributesInfo: { attributes },
    } = person;

    const onAdd = () => {
        const id = -new Date().getTime();
        const newRecord = { id };
        onChange(
            { ...person, attributesInfo: { attributes: [...attributes, newRecord] } },
            'AttributesBlock',
        );
    };

    const onRemove = id => e => {
        e.stopPropagation();
        onChange(
            { ...person, attributesInfo: { attributes: attributes.filter(a => a.id !== id) } },
            'AttributesBlock',
        );
    };

    const onChangeType = id => attribute => {
        const newRecords = attributes.map(item =>
            item.id === id ? { id, attribute, attachmentId: null, attributeValue: null } : item,
        );
        onChange({ ...person, attributesInfo: { attributes: newRecords } }, 'AttributesBlock');
    };

    const onChangeValue = id => value => {
        const newRecords = attributes.map(item => (item.id === id ? { ...item, ...value } : item));
        onChange({ ...person, attributesInfo: { attributes: newRecords } }, 'AttributesBlock');
    };

    const formatReturnValue = (type, value) => {
        if (!type) {
            return null;
        }
        switch (type.toLowerCase()) {
            case 'date':
                return new Date(value).toLocaleDateString();
            case 'hyperlink':
                if (!value.startsWith('http')) {
                    value = `http://${value}`
                } 
                return value;
            case 'select':
                return value && value.name;
            case 'multiselect':
                return value && value.map(x => x.name).join(', ');
            default:
                return value;
        }
    };

    const getAttributeInputType = (inputType = '') => {
        switch (inputType.toLowerCase()) {
            case 'number':
            case 'long':
            case 'decimal':
                return 'number';
            case 'date':
                return 'date';
            case 'select':
                return 'select';
            case 'multiselect':
                return 'multiselect';
            case 'file':
                return 'file';
            default:
                return 'text';
        }
    };

    const renderAttribute = (item, attributeType) => {
        switch (attributeType) {
            case 'select':
            case 'multiselect':
                return renderSelect(item, attributeType);
            case 'file':
                return renderFile(item);
            case 'date':
                return renderDate(item, attributeType);
            default:
                return renderInput(item, attributeType);
        }
    };

    const renderSelect = (item, attributeType) => {
        const { attribute, id } = item;
        const value =
            attributeType === 'multiselect'
                ? item.attributeValue
                : item.attributeValue && item.attributeValue.id;
        return (
            <Select
                value={value}
                items={attribute.payload.map(x => ({ id: x.id, name: x.value }))}
                catalog
                isMulti={attributeType === 'multiselect'}
                onChange={e => onChangeValue(id)({ attributeValue: e })}
            />
        );
    };

    const renderInput = (item, attributeType) => {
        const { id } = item;
        return (
            <InputField
                className="InfoField__Input"
                type={attributeType}
                double={attributeType === 'number'}
                value={item.attributeValue || ''}
                onChange={e => onChangeValue(id)({ attributeValue: e })}
            />
        );
    };

    const renderDate = (item, attributeType) => {
        const { id } = item;
        item.attributeValue = new Date(item.attributeValue || Date.now());
        return (
            <DatePicker
                type={attributeType}
                selected={item.attributeValue}
                onChange={e => onChangeValue(id)({ attributeValue: e })}
            />
        );
    };

    const renderFile = item => {
        const personId = props.person.personalInfo.id;
        const { id } = item;
        return (
            <AttributeFileUploadButton
                personId={personId}
                fileId={item.attachmentId}
                attributeId={item.id}
                setFileUploading={setFileUploading}
                onFileIdChange={fileInfo => onChangeValue(id)({ attachmentId: fileInfo.id, attributeValue: fileInfo.id,  fileInfo })}
            />
        );
    };

    const renderComponent = item => {
        const attributeType = getAttributeInputType(item.attribute && item.attribute.type);

        return renderAttribute(item, attributeType);
    };
    
    const getLink = (attributeId, fileId) => {
        const personId = props.person.personalInfo.id;
        if (personId && attributeId && fileId) {
            return getAttributeAttachmentDownloadLink(personId, attributeId, fileId);
        }
    };

    return (
        <div className={classnames('PersonalInfo', { 'PersonalInfo--bottom-margin': isEdit })}>
            {(attributes.size && attributes.size !== 0) ||
            (attributes.length && attributes.length !== 0)
                ? attributes.map(item => (
                      <React.Fragment key={item.id}>
                          {isEdit ? (
                              <>
                                  <InfoField
                                      icon={
                                          isEdit && {
                                              icon: 'minus-circle',
                                              size: 'xs',
                                              color: 'red',
                                              onClick: onRemove(item.id),
                                          }
                                      }
                                      noIcon={!isEdit}
                                      value={item.value}
                                      title="Тип"
                                      isEdit={isEdit}
                                      editComponent={
                                          <Select
                                              value={item.attribute && item.attribute.id}
                                              items={attributeTypes}
                                              catalog
                                              onChange={onChangeType(item.id)}
                                          />
                                      }
                                  />
                                  <InfoField
                                      value={item.value}
                                      title="Значение"
                                      isEdit={isEdit}
                                      editComponent={renderComponent(item)}
                                      noIcon
                                  />
                              </>
                          ) : (
                              item.attribute && (
                                  item.attribute.type !== 'file'
                                      ?
                                          item.attribute.type === 'hyperlink'
                                              ?
                                                  <InfoField
                                                      title={item.attribute.name}
                                                      noIcon
                                                  >
                                                      <a
                                                          href={formatReturnValue(
                                                              item.attribute?.type,
                                                              item.attributeValue,
                                                          )}
                                                      >
                                                          {formatReturnValue(
                                                              item.attribute?.type,
                                                              item.attributeValue,
                                                          )}
                                                      </a>
                                                  </InfoField>
                                              :
                                                  <InfoField
                                                      value={formatReturnValue(
                                                          item.attribute?.type,
                                                          item.attributeValue,
                                                      )}
                                                      title={item.attribute.name}
                                                      noIcon
                                                  />
                                      :
                                          <InfoField
                                              title={item.attribute.name}
                                              noIcon
                                          >
                                              <a 
                                                  href={getLink(item.id, item.fileInfo.id)}
                                                  download={item.fileInfo.name}
                                              >
                                                  {item.fileInfo.name}
                                              </a>
                                          </InfoField>
                              )
                          )}
                      </React.Fragment>
                  ))
                : !isEdit && <NoData text="Нет данных" />}
            {isEdit && (
                <InfoField noIcon>
                    <ProfileButton onClick={onAdd}>добавить</ProfileButton>
                </InfoField>
            )}
            
        </div>
    );
};

const mapStateToProps = state => ({
    attributeTypes: state.attributes.data.payload,
});

export default connect(mapStateToProps)(AttributesInfo);
