import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { parse as queryStringParse } from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import PersonList from 'components/PersonList';
import Sorting from 'components/Search/Sorting';
import { DataCard } from 'components/common/DataCard';
import Page, { PageTitle } from 'components/common/Page';
import PersonReportHeader from 'components/common/PersonReportHeader';
import SearchLine from 'components/common/SearchLine';
import { showErrorAlert } from 'ducks/Alert';
import { selectSearchProps } from 'ducks/Search';
import { fetchSelect, updatePersonData, clearSearch } from 'ducks/ExternalSearch';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

import './FullTextSearch.scss';

const updateSelectionState = (props, state, searchTermChanged) => {
    if (props.searchResult === state.searchResult) {
        return state;
    }

    const selection = {
        ...state.selection,
        searchResult: props.searchResult,
        personIds: !searchTermChanged // Если поменялась сортировка, не сбрасываем выделение
            ? state.selection.personIds.filter((selectionId) =>
                  props.searchResult.ids.some((id) => selectionId === id),
              )
            : [],
    };

    return {
        ...state,
        searchResult: props.searchResult,
        selection,
    };
};

class FullTextSearch extends Component {
    static getDerivedStateFromProps(props, state) {
        const { searchTerm } = props;

        const searchTermChanged = searchTerm != null && searchTerm !== state.prevSearchTerm;
        state = updateSelectionState(props, state, searchTermChanged);

        return {
            ...state,
            searchTermChanged,
            prevSearchTerm: searchTerm,
            searchTerm: searchTermChanged ? searchTerm : state.searchTerm,
        };
    }

    state = {
        selection: {
            personIds: [],
        },
        searchResult: null,
        paging: {
            pageNum: 1,
            pageSize: 10,
        },
        sorting: {
            BirthDate: 'desc',
        },
        prevSearchTerm: '',
        searchTerm: '',
    };

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, this.search);
        this.scrollTop();
    };

    componentDidMount() {
        this.props.clearSearch();
    }

    componentDidUpdate() {
        if (this.props.searchResult.loadComplete && this.state.searchTermChanged) {
            this.search();
        }
    }

    handleSelectionChange = (selection) => {
        this.setState({ selection });
    };

    handleSelectAll = () => {
        const { searchResult } = this.props;
        if (!searchResult || !searchResult.ids || searchResult.ids.length === 0) {
            return;
        }

        this.setState({ selection: { personIds: searchResult.ids } });
    };

    handleClearAll = () => {
        this.setState({ selection: { personIds: [] } });
    };

    handlePersonChange = (person) => {
        this.props.updatePersonData(person);
    };

    render() {
        const { searchResult } = this.props;
        const { paging, sorting, searchTerm, selection } = this.state;

        return (
            <div>
                <PersonReportHeader
                    selection={selection}
                    totalCount={searchResult.foundCount}
                    onSelectAll={this.handleSelectAll}
                    onClearAll={this.handleClearAll}
                />
                <Page id="search-page" className="search" w1790>
                    <DataCard className="FullTextSearch__SearchLine">
                        <PageTitle className="FullTextSearch__Title">
                            Полнотекстовый поиск
                        </PageTitle>
                        <SearchLine
                            value={searchTerm}
                            onChangeValue={this.handleChangeSearchTerm}
                            onSubmit={this.handleSearchSubmit}
                            size="lg"
                        />
                    </DataCard>
                    <div className="FullTextSearch__SortLine">
                        <div className="FullTextSearch__SortLine__Sorting">
                            <Sorting
                                sorting={sorting}
                                onSort={this.handleSort}
                                options={[
                                    {
                                        htmlId: 'search-page-order-by-age',
                                        title: 'Возраст',
                                        keys: ['Age'],
                                        value: 'Age',
                                        defaultDirection: 'desc',
                                    },
                                    {
                                        htmlId: 'search-page-order-by-name',
                                        title: 'ФИО',
                                        keys: ['FullName'],
                                        value: 'FullName',
                                        defaultDirection: 'asc',
                                    },
                                ]}
                            />
                        </div>
                        <div className="FullTextSearch__SortLine__Results">
                            Результаты ({searchResult.foundCount})
                        </div>
                    </div>
                    <PersonList
                        persons={searchResult.data}
                        pageNum={paging.pageNum}
                        onPaginate={this.handlePaginate}
                        pageCount={searchResult.pageCount}
                        totalCount={searchResult.foundCount}
                        selection={selection}
                        onChangeSelection={this.handleSelectionChange}
                        onPersonChange={this.handlePersonChange}
                        changePageAmount={this.changePageAmount}
                        pageSize={paging.pageSize}
                    />
                </Page>
            </div>
        );
    }

    handleChangeSearchTerm = (searchTerm) => {
        this.setState({ searchTerm });
    };

    handleSearchSubmit = () => {
        this.handlePaginate(1);
    };

    handlePaginate = (pageNum) => {
        const newPaging = { ...this.state.paging, pageNum };

        this.setState({ paging: newPaging }, this.search);
        window.scrollTo(0, 0);
    };

    handleSort = (newSorting) => {
        const newPaging = { ...this.state.paging, pageNum: 1 };
        this.setState({ sorting: newSorting, paging: newPaging }, this.search);
    };

    search = () => {
        const { searchTerm, paging, sorting } = this.state;

        const outputSorting = [];

        for (let prop in sorting) {
            outputSorting.push({
                category: 'Persons',
                field: prop,
                direction: sorting[prop] === 'asc' ? 'Asc' : 'Desc',
            });
        }

        const criteria = {
            searchTerm,
            sorting: outputSorting,
            paging,
            idList: true,
        };

        if (searchTerm.trim()) {
            this.props.push({ search: `?searchTerm=${searchTerm}` });
            this.props.fetchSelect(criteria);
        }
    };
}

FullTextSearch.propTypes = {
    searchResult: PropTypes.object.isRequired,
    searchTerm: PropTypes.string,
    push: PropTypes.func.isRequired,
    showErrorAlert: PropTypes.func.isRequired,
};

function prepareSearchTerm(searchTerm) {
    return (searchTerm && searchTerm.trim()) || null;
}

function extractSearchTerm(qs) {
    let queryParams = queryStringParse(qs);
    if (queryParams) {
        return prepareSearchTerm(queryParams.searchTerm);
    }
    return null;
}

function mapStateToProps(state) {
    return {
        searchResult: selectSearchProps(state.externalSearch),
        searchTerm: extractSearchTerm(state.router.location.search),
    };
}

const mapDispatchToProps = {
    push,
    showErrorAlert,
    showPageLoader,
    hidePageLoader,
    fetchSelect,
    updatePersonData,
    clearSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(FullTextSearch);
