import React, { Component } from "react";

import { DataCard } from "../DataCard";
import classnames from "classnames";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronButton from 'components/uikit/ChevronButton';

import "./TopMenu.scss";


class MenuItem extends Component {
    state = {
        inheritedMenuIsOpen: false,
    }
    handleToggle = (e) => {
        e.preventDefault();
        this.setState({ inheritedMenuIsOpen: !this.state.inheritedMenuIsOpen });
    };

    render() {
        const hasInheritedMenu = this.props.inheritedMenu && this.props.active;
        const className = classnames({
            "TopMenu_MenuItem": true,
            "TopMenu_MenuItem__Active": this.props.active && !this.state.inheritedMenuIsOpen,
            "TopMenu_MenuItem__Active-SubMenu": this.props.active && hasInheritedMenu && this.state.inheritedMenuIsOpen,
        });
    
        let icon = this.props.textIcon
            ? <span className="TopMenu_Button_IconText">{this.props.textIcon}</span>
            : this.props.icon ? <FontAwesomeIcon className="TopMenu_Button_Icon" icon={this.props.icon} /> : null;   

        const svgIcon = this.props.svgIcon;
        if (svgIcon)
        {
            const iconClassName = classnames(
                "TopMenu_Button_IconSvg",
                `TopMenu_Button_IconSvg_${svgIcon}`,
                { "TopMenu_Button_IconSvg__Active": this.props.active }
            );

            icon =
                <span className={iconClassName}>
                    {this.props.textIcon}
                </span>;
        }

        return (
            <li className={className}>
                <div className="Menu_Button">
                    <button onClick={this.props.onClick}>
                        {icon}
                        <span className="TopMenu_Button_Text">{this.props.title}</span>
                    </button>
                </div>
                {hasInheritedMenu &&
                    <div className="Expand_Button">
                        <ChevronButton isUp={this.state.inheritedMenuIsOpen} onClick={this.handleToggle}/>
                    </div>
                }
                { hasInheritedMenu && this.state.inheritedMenuIsOpen && this.props.inheritedMenu }
                                 
            </li>
        );
    }
}

const Menu = (props) => {
    return (
        <ul className="TopMenu_Menu">
            {props.children}
            {!props.items ? null : props.items.map(item => {
                return <MenuItem
                    key={item.id}
                    title={item.title}
                    active={props.selected === item.id}
                    icon={item.icon}
                    svgIcon={item.svgIcon}
                    textIcon={item.textIcon}
                    onClick={(e) => props.onClick ? props.onClick(e, item) : null}
                    inheritedMenu={item.inheritedMenu}
                />
            })}
        </ul>
    );
}

class TopMenu extends Component {
    onClick = (e, item) => {
        e.preventDefault();
        if (item.id !== this.props.selected && this.props.onChangePage) {
            this.props.onChangePage(item);
        }
    }

    render() {
        return (
            <DataCard className={"TopMenu"}>
                <Menu items={this.props.items} selected={this.props.selected} onClick={this.onClick} size={this.props.size}/>
                {this.props.children}
            </DataCard>
        );
    }
}

TopMenu.propTypes = {
    onChangePage: PropTypes.func,
    selected: PropTypes.string,
}

export default TopMenu;