import React from 'react';
import Field from '../Field';
import Label from '../Label';
import classnames from 'classnames';
import './CheckField.scss';

function CheckField(props) {
    const {
        title,
        checked,
        onChange,
        required,
        filled,
        size,
        className,
        modifier,
        disabled = false,
        partlyChecked = false,
        ...tale
    } = props;

    const finalClass =
        'CheckField' +
        (modifier ? ' CheckField__' + modifier : '') +
        (className ? ' ' + className : '');

    return (
        <Field required={required} filled={filled} size={size}>
            <div
                className={classnames(finalClass, {
                    'CheckField--disabled': disabled,
                    'CheckField--partlyChecked': partlyChecked,
                })}
            >
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => onChange(!checked)}
                    {...tale}
                />
                <Label onClick={() => (!disabled ? onChange(!checked) : undefined)}>{title}</Label>
            </div>
        </Field>
    );
}

export default CheckField;
