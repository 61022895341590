import React from 'react';
import Input from 'components/Lk/Uikit/Input';
import Field from 'components/Lk/Uikit/Field';
import Select from 'components/Lk/Uikit/Select/CustomSelect';
import Label from 'components/Lk/Uikit/Label';
import { useMemo } from 'react';
import './FilterField.scss';
import { FIELDS_TYPE } from '../SearchFilters/constants';

const CustomSelect = (props) => {
    const { options, value, placeholder = 'Выберите...', isClearable = true } = props;
    const val = props.isMulti
        ? options.filter((x) => value && value.indexOf(x.value) >= 0)
        : options.find((x) => x.value === value) || null;
    return (
        <Select
            {...props}
            options={options}
            value={val}
            placeholder={placeholder}
            noOptionsMessage={() => 'Нет данных'}
            isClearable={isClearable}
        />
    );
};

const FilterField = (props) => {
    const {
        type,
        value,
        onChange,
        onInputChange,
        onFocus,
        size,
        invalid,
        error,
        label,
        items,
        placeholder,
        ...extra
    } = props;
    const options = useMemo(() => items?.map((x) => ({ ...x, label: x.name })) || [], [items]);
    const renderField = () => {
        switch (type) {
            case 'input':
                return (
                    <Input
                        placeholder={placeholder}
                        type="text"
                        value={value || ''}
                        onChange={(e) => onChange(e.currentTarget.value)}
                    />
                );
            case 'year':
            case 'number':
                return (
                    <Input
                        placeholder={placeholder}
                        type="number"
                        value={value || ''}
                        onChange={(e) => onChange(e.currentTarget.value)}
                    />
                );
            case FIELDS_TYPE.multiSelect:
            case FIELDS_TYPE.select:
                return (
                    <CustomSelect
                        placeholder={placeholder}
                        options={options}
                        onChange={onChange}
                        onInputChange={onInputChange}
                        onFocus={onFocus}
                        isMulti={type === FIELDS_TYPE.multiSelect}
                        value={
                            (type === FIELDS_TYPE.multiSelect
                                ? (value || []).map((x) => x.value)
                                : value?.value) || null
                        }
                        {...extra}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Field className="LKFilterField" error={error} size={size} invalid={invalid}>
            {label && <Label>{label}</Label>}
            {renderField()}
        </Field>
    );
};

export default FilterField;
