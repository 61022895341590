import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hidePageLoader, showPageLoader } from 'ducks/PageLoader';
import { showErrorAlert, showSuccessAlert, showWarningAlert } from 'ducks/Alert';
import { getError, serviceResultCode } from 'serviceErrors';

import { Col, Grid, Row } from 'react-flexbox-grid';
import { DataCard } from 'components/common/DataCard';
import Page from 'components/common/Page';
import TextEditor from 'components/common/TextEditor';

import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Select from 'components/uikit/Select';
import Button from 'components/uikit/Button';

import { newsEditRoute, newsRoute } from 'routes';
import { push as pushLocation } from 'connected-react-router';
import { createNews, getNews, updateNews, updateNewsStatus } from 'api';

import { NEWS_STATUS, NEWS_STATUS_ENUM } from 'constants.js';

import './NewsEdit.scss';
import NewsTagSearchSelect from './NewsTagSearchSelect.js';
import NewsPicture from './NewsPicture.js';
import { isNullOrWhitespace } from 'utils';

class NewsEdit extends Component {
    state = {
        form: {
            status: NEWS_STATUS_ENUM.Draft,
            title: '',
            groupId: this.props.currentUser.groupId,
            tags: [],
        },
        contentPreview: false,
        summaryPreview: false,
        tagEditMode: false,
    };

    componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            this.fetchNews();
        }
        window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        if (
            this.props.match.params.id !== 'new' &&
            this.props.match.params.id !== this.state.form.id
        ) {
            this.fetchNews();
        }
    }

    fetchNews = () => {
        this.callEffect(async () => {
            const result = await getNews(this.props.match.params.id);

            this.setState({
                form: {
                    ...result.data,
                },
            });
        });
    };

    getValidateErrors = () => {
        const errors = [];

        if (!this.state.form.title) {
            errors.push('Не указано название');
        }

        return errors;
    };

    onChange = item => {
        this.setState(state => ({
            form: {
                ...state.form,
                ...item,
            },
            dirtyForm: true,
        }));
    };

    onSaveNews = () => {
        const errors = this.getValidateErrors();

        if (errors.length > 0) {
            this.props.showWarningAlert('Не все поля корректно заполнены.');
            return;
        }

        const { form } = this.state;

        const formToSend = {
            ...form,
            title: form.title.trim(),
            summary: this.addHttps(form.summary),
            content: this.addHttps(form.content),
        };

        this.callEffect(async () => {
            formToSend.id ? await updateNews(formToSend) : await createNews(formToSend);

            this.props.pushLocation(newsRoute.buildUrl());
        });
    };

    addHttps(value) {
        const checkUrlsReg = /\[(.*?)\]\((?!(http(s?):\/\/))(.*?)\)/g;
        const replaceToString = '[$1](https://$4)';
        return value && value.trim().replace(checkUrlsReg, replaceToString);
    }

    onPublish = () => {
        this.callEffect(async () => {
            const result = await updateNewsStatus(this.state.form.id, NEWS_STATUS_ENUM.Published);

            this.setState({
                form: {
                    ...result.data,
                },
                dirtyForm: false,
            });

            showSuccessAlert('Новость опубликована');
        });
    };

    onEditNewsMode = () => {
        this.props.pushLocation(newsEditRoute.buildUrl({ id: this.props.match.params.id }));
    };

    onCancel = () => {
        this.props.pushLocation(newsRoute.buildUrl());
    };

    onChangePreview = (fieldName, value) => {
        this.setState(state => ({
            form: {
                ...state.form,
                [fieldName]: this.addHttps(state.form[fieldName]),
            },
            [fieldName + 'Preview']: value,
        }));
    };

    callEffect = async callback => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            const reqError = getError(error, this.getNewsError);
            this.props.showErrorAlert(reqError.message);
        } finally {
            this.props.hidePageLoader();
        }
    };

    getNewsError = code => {
        switch (code) {
            case serviceResultCode.ValidationErrors:
                return 'Произошла ошибка при сохранении';
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    render() {
        const { form } = this.state;
        const viewMode = this.props.match.params.mode === 'view';

        const selectedStatus = NEWS_STATUS.find(x => x.value === form.status);

        return (
            <Grid fluid>
                <Page className="NewsEdit">
                    <DataCard className="NewsEdit__Content">
                        <Row>
                            <Col xs={10} sm={12} md={12} lg={12} xl={4} className="NewsImage__Container">
                                <NewsPicture
                                    newsId={form.id}
                                    pictureFileId={form.pictureFileId}
                                    viewMode={viewMode}
                                    onPictureChanged={pictureFileId => this.onChange({ pictureFileId })}
                                />
                            </Col>
                            <Col xs={9} sm={12} md={12} lg={12} xl={8}>
                                <Row>
                                    <Col md={8}>
                                        <Field filled={!isNullOrWhitespace(form.title)} required>
                                            <Label>Заголовок</Label>
                                            <Input
                                                className="NewsEdit__Input"
                                                maxLength="200"
                                                value={form.title}
                                                disabled={viewMode}
                                                onChange={e =>
                                                    this.onChange({ title: e.target.value })
                                                }
                                            />
                                        </Field>
                                    </Col>
                                    <Col md={4}>
                                        <Field>
                                            <Label>Статус</Label>
                                            {viewMode || !form.id ? (
                                                <Input
                                                    disabled={true}
                                                    value={selectedStatus.label}
                                                />
                                            ) : (
                                                <Select
                                                    isDisabled={false}
                                                    value={selectedStatus}
                                                    options={NEWS_STATUS}
                                                    onChange={e =>
                                                        this.onChange({ status: e.value })
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <NewsTagSearchSelect
                                            tags={form.tags}
                                            groupId={form.groupId}
                                            viewMode={viewMode}
                                            onTagsChanged={tags => this.onChange({ tags })}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {(!viewMode || form.summary) &&
                        <Row>
                            <Col md={12}>
                                <Field className="NewsEdit__Summary">
                                    <Label>Краткое описание</Label>
                                    <TextEditor
                                        value={form.summary}
                                        showToolbar={!viewMode}
                                        preview={viewMode || this.state.summaryPreview}
                                        minHeight={viewMode ? 'auto' : 150}
                                        onChangePreview={value => this.onChangePreview('summary', value)}
                                        onChange={e =>
                                            this.onChange({ summary: e.target.value })
                                        }
                                    />
                                </Field>
                            </Col>
                        </Row>
                        }
                        {(!viewMode || form.content) &&
                        <Row>
                            <Col md={12}>
                                <Field className="NewsEdit__Description">
                                    <Label>Описание</Label>
                                    <TextEditor
                                        value={form.content}
                                        showToolbar={!viewMode}
                                        preview={viewMode || this.state.contentPreview}
                                        minHeight={viewMode ? 'auto' : 400}
                                        onChangePreview={value => this.onChangePreview('content', value)}
                                        onChange={e => this.onChange({ content: e.target.value })}
                                    />
                                </Field>
                            </Col>
                        </Row>
                        }
                        {viewMode ? (
                            <Row>
                                <Col md={6} className="NewsEdit__ActionButtons NewsEdit__ActionButtons--left">
                                    <Button
                                        size="sm"
                                        color="light_primary"
                                        onClick={this.onEditNewsMode}
                                    >
                                        Редактировать
                                    </Button>
                                    <Button
                                        size="sm"
                                        disabled={
                                            selectedStatus.value === NEWS_STATUS_ENUM.Published
                                        }
                                        onClick={this.onPublish}
                                    >
                                        Опубликовать
                                    </Button>
                                </Col>
                                <Col md={6} className="NewsEdit__ActionButtons NewsEdit__ActionButtons--right">
                                    <Button size="sm" color="danger" onClick={this.onCancel}>
                                        Назад
                                    </Button>
                                </Col>
                            </Row>
                        ) : (
                            <Row className="NewsEdit__ActionButtons NewsEdit__ActionButtons--right" end="md">
                                <Col md={4}>
                                    <Button size="sm" color="danger" onClick={this.onCancel}>
                                        Отменить
                                    </Button>
                                    <Button
                                        size="sm"
                                        disabled={isNullOrWhitespace(form.title)}
                                        onClick={this.onSaveNews}
                                    >
                                        Сохранить
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </DataCard>
                </Page>
            </Grid>
        );
    }
}

const props = state => {
    return {
        currentUser: state.auth.user,
    };
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    showSuccessAlert,
    showWarningAlert,
    pushLocation,
};

export default connect(props, actions)(NewsEdit);
