import React, {useCallback, useEffect, useState} from 'react';
import './Popover.scss';
import TooltipTrigger from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import classnames from 'classnames'

const Popover = props => {
    const {children, tooltip, childrenClassName, tooltipPlacement, tooltipOffset, onClick = null, autoPlacement = true, className = '' } = props;
    const [isOpen, setOpenState] = useState(false);
    const [isClickOpen, setClickOpenState] = useState(false);

    const onTouchMove = useCallback(() => {
        setOpenPopover(false);
    }, []);

    const setOpenPopover = state => {
        setOpenState(state);
        setClickOpenState(false);
    };

    useEffect(() => {
        window.addEventListener('touchmove', onTouchMove);
        onTouchMove();
        return () => {
            window.removeEventListener('touchmove', onTouchMove);
        };
    }, [onTouchMove]);


    const Trigger = ({getTriggerProps, triggerRef}) => {
        return (
            <div
                {...getTriggerProps({
                    ref: triggerRef,
                    className: "trigger",
                })}
                onClick={onClick}
                className={childrenClassName ? 'LkPopover__' + childrenClassName : 'LkPopover'}
            >
                {children}
            </div>
        );
    };

    const Tooltip = ({
                         getTooltipProps,
                         getArrowProps,
                         tooltipRef,
                         arrowRef,
                         placement
                     }) => {
        return (
            <div
                {...getTooltipProps({
                    ref: tooltipRef,
                    className: classnames("LkPopover LkPopover__Content",className)
                })}
                onClick={e => {
                    e.stopPropagation();
                    setOpenPopover(false)
                }}
            >
                <div
                    {...getArrowProps({
                        ref: arrowRef,
                        "data-placement": placement,
                        className: "tooltip-arrow"
                    })}
                />
                <div className="tooltip-body">
                    {tooltip}
                </div>
            </div>
        );
    };


    return (tooltip
            ?
            <TooltipTrigger 
                onVisibilityChange={shown => setOpenPopover(shown)} 
                tooltipShown={isOpen || isClickOpen}
                placement={autoPlacement ? "auto-end" : tooltipPlacement || "top"} 
                trigger={["click", "hover"]} 
                tooltip={Tooltip}
                modifiers={[{
                    name: 'offset',
                    options: {
                        offset: tooltipOffset || [0, 7],
                    }}
                ]}
            >
                {Trigger}
            </TooltipTrigger>
            :
            <div className={'LkPopover__' + childrenClassName}>
                {children}
            </div>
    );
};

export default Popover;
