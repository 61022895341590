import React, { PureComponent } from 'react';

const getOptionValue = option => (option ? option.id : null);

const getOptionLabel = option => option.name;

const getOptionsByValues = (options, value) =>
    Array.isArray(value)
        ? options.filter(option => value.includes(getOptionValue(option)))
        : options.find(option => getOptionValue(option) === value);

const optionsToValues = options =>
    Array.isArray(options) ? options.map(getOptionValue) : getOptionValue(options);

export default SelectComponent =>
    class DictionarySelect extends PureComponent {
        render() {
            const { value, ...tail } = this.props;
            delete tail.onChange;

            const selectedOptions = getOptionsByValues(tail.options || [], value);

            return (
                <SelectComponent
                    getOptionValue={getOptionValue}
                    getOptionLabel={getOptionLabel}
                    {...tail}
                    value={selectedOptions || null}
                    onChange={this.onChange}
                />
            );
        }

        onChange = newSelectedOptions => {
            this.props.onChange(optionsToValues(newSelectedOptions));
        };
    };
