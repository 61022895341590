import React, { useState, useEffect } from 'react';
import { getPersonTeams } from 'api';
import { connect } from 'react-redux';
import { showErrorAlert } from 'ducks/Alert';
import { DataCard } from 'components/Lk/Uikit/DataCard';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemPerson from 'components/Lk/Common/CardItemPerson';
import CardItemPersonData from 'components/Lk/Common/CardItemPerson/CardItemPersonData';
import Loader from 'components/common/Loader';
import './Teams.scss';
import { getUserShortName } from 'utils';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';
import { CHECKBOX_TYPES } from 'components/Lk/Uikit/StyledCheckBox/constants';
import classnames from 'classnames';
import { USER_ROLES } from 'constants.js';

const Teams = props => {
    const {
        personId,
        setActiveCard,
        activeCard,
        showErrorAlert,
        onPersonSelect,
        selectedIds = [],
        onCheckAll,
        dataTest,
        withCheckboxes,
        items = { leading: [], following: [], isLoaded: false },
        isUser = false,
        fromProfile = false,
    } = props;
    setActiveCard(activeCard);

    useEffect(() => {
        let mounted = true;
        const getTeams = async () => {
            try {
                if (!items.isLoaded) {
                    setLoading(true);
                    const data = await getPersonTeams(personId);
                    mounted && setTeams(data.data);
                }
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                mounted && setLoading(false);
            }
        };
        getTeams();

        return () => {
            mounted = false;
        };
    }, [personId, showErrorAlert, items.isLoaded]);

    const [teams, setTeams] = useState(items);
    const [myTeams, setMyTeams] = useState([]);
    const [otherTeams, setOtherTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [personsIds, setPersonIds] = useState({});

    const getPersonIds = arr =>
        arr.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.id]: curr.followerPersons.filter(x => x.userId).map(x => x.userId),
            }),
            {},
        );

    useEffect(() => {
        let mounted = true;
        mounted && setMyTeams(teams.leading);
        mounted && setOtherTeams(teams.following);
        const leadIds = getPersonIds(teams.leading);
        const follIds = getPersonIds(teams.following);

        mounted && setPersonIds(() => ({ ...leadIds, ...follIds }));

        return () => {
            mounted = false;
        };
    }, [teams]);

    const renderTeamsBlock = (my = false) => {
        return (
            <>
                <div className="LKMainTeamsCategory">
                    <div className="LKMainTeamsCategory__Name">
                        {my
                            ? `Мои команды (${myTeams.length})`
                            : `Я в командах (${otherTeams.length})`}
                    </div>
                </div>
                <div className="LKMainTeams__Team">
                    {my
                        ? myTeams.map(x => renderTeam(x, my))
                        : otherTeams.map(x => renderTeam(x, my))}
                </div>
            </>
        );
    };

    const onCheckTeam = (persons, teamId) => (check, e) => {
        e.stopPropagation();
        onCheckAll(persons, check, teamId);
    };

    const renderTeam = (team, myTeam) => {
        const personsInTeam = myTeam
            ? team.followerPersons.length
            : team.followerPersons.length + 1;

        const { leaderPerson } = team;

        const personIds = myTeam
            ? personsIds[team.id]
            : personsIds[team.id].concat(
                  leaderPerson && leaderPerson.userId ? leaderPerson.userId : [],
              );
        const iconsStartFrom = +(
            personIds.some(x => selectedIds.indexOf(x) >= 0) && personIds.length !== 0
        );
        const checkboxes = CHECKBOX_TYPES.TRIPLE.slice(iconsStartFrom);

        const personsInTeamRegistered = personIds.length;
        const personsInTeamChecked = personIds.filter(x => selectedIds.indexOf(x) >= 0).length;

        const teamPersons = myTeam
            ? team.followerPersons
            : team.followerPersons.concat(leaderPerson ? leaderPerson : []);

        const teamTitleCheckBox = (
            <>
                {onCheckAll && (
                    <StyledCheckBox
                        checkboxes={checkboxes}
                        onChange={onCheckTeam(teamPersons, team.id)}
                        checked={
                            personIds.every(x => selectedIds.indexOf(x) >= 0) &&
                            personIds.length !== 0
                        }
                        disabled={personsInTeamRegistered === 0}
                    />
                )}
            </>
        );

        const teamTitle = (
            <div className="LkTeamCheckBoxField">
                <div className="LKTeamTitle">
                    {team.name ? (
                        onCheckAll ? (
                            <div>
                                {team.name} ({personsInTeam}/
                                <span
                                    className={classnames('LKTeamTitle__PersonsInTeamRegistered', {
                                        'LKTeamTitle__PersonsInTeamRegistered--grey':
                                            personsInTeamRegistered === 0,
                                    })}
                                >
                                    {personsInTeamRegistered}
                                </span>
                                )
                            </div>
                        ) : (
                            <div>
                                {team.name} ({personsInTeam})
                            </div>
                        )
                    ) : (
                        `Команда (${personsInTeam})`
                    )}
                </div>
                {onCheckAll && (
                    <div className="LKTeamTitle__PersonsInTeamChecked">{personsInTeamChecked}</div>
                )}
            </div>
        );

        const teamSubTitle = (
            <div className="LKTeamSubTitle">
                <div className="LKTeamSubTitle__Data">
                    <div className="LKTeamSubTitle__AllAndRegisteredPersonsCol">
                        <div className="LKTeamSubTitle__AllPersonsCol">Всего: {personsInTeam}</div>
                        <div>Подключено: {personsInTeamRegistered}</div>
                    </div>
                    <div>Выбрано: {personsInTeamChecked}</div>
                </div>
            </div>
        );

        return (
            <DataCard
                key={team.id}
                title={teamTitle}
                titleCheckbox={teamTitleCheckBox}
                titleClassName={classnames('LKDataCard__Title', {
                    'LKDataCard__Title--wide': withCheckboxes,
                })}
                chevronClassName={classnames({ LKDataCard__ChevronButton: withCheckboxes })}
                titleModifire="accordionWide"
                defaultIsOpen={false}
                dataTest="Teams"
            >
                {renderTeamPersons(team, myTeam, teamSubTitle)}
            </DataCard>
        );
    };

    const renderTeamSubTitle = teamSubTitle => {
        return <> {withCheckboxes && teamSubTitle} </>;
    };

    const renderTeamPersons = (team, myTeam = false, teamSubTitle) => {
        if (team.followerPersons && team.followerPersons.length !== 0) {
            return (
                <>
                    {renderTeamSubTitle(teamSubTitle)}
                    {!myTeam && renderTeamPerson(team.leaderPerson, -1, teamSubTitle)}
                    {team.followerPersons.map((x, index) =>
                        renderTeamPerson(x, index, teamSubTitle),
                    )}
                </>
            );
        } else {
            return <div className="LKMainTeams__NoTeamData">В этой команде пока никого нет</div>;
        }
    };

    const isPersonSelected = personId => {
        return selectedIds.find(x => +x === +personId);
    };

    const setPersonProps = person => {
        return props.withRegistration
            ? {
                  data: getUserShortName(person),
                  position: person.userId
                      ? person.currentJobTitle
                      : 'Пользователь не зарегистрирован в системе',
              }
            : {
                  data: getUserShortName(person),
                  position: person.currentJobTitle,
              };
    };

    const renderTeamPerson = (person, index) => {
        const personProps = setPersonProps(person);
        const roles =
            (isUser &&
                person.roleIds?.map(roleId => USER_ROLES.find(i => i.id === roleId)?.name)) ||
            [];
        return (
            <React.Fragment key={person.id}>
                {index === -1 && <div className="LKMainTeamPersonPrefix">Руководитель команды</div>}
                {index === 0 && <div className="LKMainTeamPersonPrefix">Состав команды</div>}
                <CardItemBlock>
                    <CardItemPerson
                        person={person}
                        clickable={props.withRegistration ? person.userId : true}
                        profileUrl={
                            !onPersonSelect
                                ? props.location.pathname.replace(/\d/g, '') + person.id
                                : null
                        }
                        onCardClick={onPersonSelect && onPersonSelect(person.id, person)}
                        checkbox={!!(person.userId && onPersonSelect)}
                        checked={isPersonSelected(isUser ? person.userId : person.id)}
                        dataTest={`${dataTest} id_${person.id}`}
                    >
                        <CardItemPersonData {...personProps} roles={roles} />
                    </CardItemPerson>
                </CardItemBlock>
            </React.Fragment>
        );
    };

    const renderTeams = () => {
        return (
            <div
                className={classnames('', {
                    'LKMainTeams__Wrapper--fromProfile LKMainTeams__Wrapper': fromProfile,
                })}
            >
                {loading ? (
                    <div>
                        <Loader />
                    </div>
                ) : (
                    <>
                        {myTeams.length !== 0 && renderTeamsBlock(true)}
                        {otherTeams.length !== 0 && renderTeamsBlock()}
                    </>
                )}
            </div>
        );
    };

    return teams.leading.length === 0 && teams.following.length === 0 && !loading ? (
        <div className="LKMainTeams__NoData">Резервист не состоит в командах</div>
    ) : (
        renderTeams()
    );
};

const actions = { showErrorAlert };

export default connect(
    null,
    actions,
)(Teams);
