import { appName, TEST_GROUPS_CODE } from '../constants';
import { takeLatest, call, all, put } from 'redux-saga/effects';
import { getReportSettings, searchEvaluationTestFullList, saveReportSettings } from 'api';
import { showErrorAlert,showSuccessAlert } from './Alert';
import RequestError from 'RequestError';

const moduleName = 'team-report';
const FETCH_SETTINGS = `${appName}/${moduleName}/FETCH_SETTINGS`;
const REQUEST_SETTINGS = `${appName}/${moduleName}/REQUEST_SETTINGS`;
const SUCCESS_SETTINGS = `${appName}/${moduleName}/SUCCESS_SETTINGS`;
const SAVE_SETTINGS = `${appName}/${moduleName}/SAVE_SETTINGS`;
const LOAD_TESTS = `${appName}/${moduleName}/LOAD_TESTS`;

const initialState = {
    settings: [],
    testList: [],
    isLoaded: false,
    loading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_SETTINGS:
            return { ...state, isLoaded: false, loading: true };
        case SUCCESS_SETTINGS:
            return {
                ...state,
                isLoaded: true,
                loading: false,
                settings: action.payload,
            };
        case LOAD_TESTS:
            return { ...state, testList: action.payload };
        default:
            return state;
    }
}

export const fetchSettings = payload => ({ type: FETCH_SETTINGS, payload });

export const saveSettings = payload => ({ type: SAVE_SETTINGS, payload });

const onLoadSettings = payload => ({
    type: SUCCESS_SETTINGS,
    payload,
});

const onLoadTests = payload => ({
    type: LOAD_TESTS,
    payload,
});

const onRequestSettings = () => ({ type: REQUEST_SETTINGS });

function* fetchSettingsSaga() {
    try {
        yield put(onRequestSettings());

        const response = yield call(getReportSettings);
        const testList = yield call(searchEvaluationTestFullList, {
            filter: {
                kinds: [TEST_GROUPS_CODE.BEHAVIOR, TEST_GROUPS_CODE.MOTIVATION_AND_DESTRUCTION],
            },
        });
        
        yield put(onLoadTests(testList.data.payload));
        yield put(onLoadSettings(response.data));
    } catch (error) {
        if (error && error.response && error.response.status === 404) {
            return;
        }

        const reqError = new RequestError(error, 'При загрузке настроек произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

function* saveSettingsSaga({ payload }) {
    try {
        yield put(onRequestSettings());
        const response = yield call(saveReportSettings, payload);

        yield put(onLoadSettings(response.data));
        showSuccessAlert('Сохранение прошло успешно');
    } catch (error) {
        if (error && error.response && error.response.status === 404) {
            return;
        }

        const reqError = new RequestError(error, 'При сохранении настроек произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

export function* saga() {
    yield all([
        takeLatest(FETCH_SETTINGS, fetchSettingsSaga),
        takeLatest(SAVE_SETTINGS, saveSettingsSaga),
    ]);
}
