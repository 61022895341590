import Input from 'components/uikit/Input';
import React, { useState } from 'react';

const PasswordField = (props) => {
    const { value, disabled, onChange, dataTest } = props;

    const [isVisible, setVisible] = useState(false);

    const type = isVisible ? 'text' : 'password';
    const icon = isVisible ? 'eye-slash' : 'eye'

    const onIconDown = () => {
        setVisible(true);
    };

    const onIconUp = () => {
        setVisible(false);
    };

    return (
        <Input
            name="password"
            id="password"
            type={type}
            required
            autoComplete="off"
            value={value}
            disabled={disabled}
            onChange={onChange}
            postIconButtonMouseDown={onIconDown}
            postIconButtonMouseUp={onIconUp}
            postIconButton={icon}
            data-test={dataTest}
        />
    );
};

export default PasswordField;
