import React, { Component } from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { fieldsToValidateCurrentGovernmentService, fieldsToValidatePastGovernmentService } from '../../../../constants';
import Select from 'components/uikit/Select';
import CheckField from "components/uikit/CheckField";
import DatePicker from 'components/uikit/DatePicker';
import Field from "components/uikit/Field";
import Input from "components/uikit/Input";
import Label from "components/uikit/Label";
import DataList from "../DataList";
import InputText from "components/uikit/InputText";

class GovernmentService extends Component {
    render() {
        const { value, governmentServiceKindCatalog, keySelector } = this.props;
        const {
            isCurrent,
            governmentServiceKind,
            startDate,
            endDate,
            branch,
            position,
            rank,
            extraInfo,
        } = value;

        const index = keySelector(value);

        return (
            <>
                <CheckField
                    id={this.getId('currentGovernmentService', index)}
                    title="Текущее место службы"
                    checked={isCurrent}
                    onChange={checked => this.props.onChangeCurrentGovernmentService(index, checked)}
                    size="100"
                />
                <this.ValidatableField
                    // TODO: Удалить если заказчик не захочет вернуть после импорта новых резервистов
                    //fieldKey="governmentServiceKind"
                    //fieldValue={governmentServiceKind}
                    //validationError="Выберите тип службы"
                    size="50"
                >
                    <Label htmlFor={this.getId('governmentServiceKind', index)}>Тип службы</Label>
                    <Select
                        inputId={this.getId('governmentServiceKind', index)}
                        value={governmentServiceKind}
                        onChange={item => this.props.onChange(index, { governmentServiceKind: item })}
                        options={governmentServiceKindCatalog.data}
                        catalog
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="branch"
                    fieldValue={branch}
                    validationError="Введите вид службы (род войск)"
                    size="50"
                >
                    <Label htmlFor={this.getId('branch', index)}>Вид службы (род войск)</Label>
                    <Input
                        id={this.getId('branch', index)}
                        value={branch || ''}
                        maxLength={250}
                        onChange={e => this.props.onChange(index, { branch: e.target.value })}
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="position"
                    fieldValue={position}
                    validationError="Введите должность"
                    size="50"
                >
                    <Label htmlFor={this.getId('position', index)}>Должность</Label>
                    <Input
                        id={this.getId('position', index)}
                        value={position || ''}
                        maxLength={250}
                        onChange={e => this.props.onChange(index, { position: e.target.value })}
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="rank"
                    fieldValue={rank}
                    validationError="Введите звание"
                    size="50"
                >
                    <Label htmlFor={this.getId('rank', index)}>Звание</Label>
                    <Input
                        id={this.getId('rank', index)}
                        value={rank || ''}
                        maxLength={250}
                        onChange={e => this.props.onChange(index, { rank: e.target.value })}
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    size="25"
                >
                    <Label htmlFor={this.getId('startDate', index)}>Начало службы</Label>
                    <DatePicker
                        id={this.getId('startDate', index)}
                        selected={startDate ? new Date(startDate) : null}
                        onChange={value => this.props.onChange(index, { startDate: value && value.toDateString() })}
                        noDataBtn
                    />
                </this.ValidatableField>
                {!isCurrent &&
                <this.ValidatableField
                    size="25"
                >
                    <Label htmlFor={this.getId('endDate', index)}>Окончание службы</Label>
                    <DatePicker
                        id={this.getId('endDate', index)}
                        selected={!isCurrent ? endDate ? new Date(endDate) : null : null}
                        onChange={value => this.props.onChange(index, { endDate: !isCurrent 
                                ? value && value.toDateString()
                                : null })}
                        noDataBtn
                    />
                </this.ValidatableField>}

                {
                    // TODO: Удалить если заказчик не захочет вернуть после импорта новых резервистов
                    /*<DateRange
                        isNewPerson={!this.props.value.personId}
                        startStateKey='startDate'
                        endStateKey='endDate'
                        subBlockName='governmentServices' 
                        index={index} 
                        isInputInvalid={
                            this.props.value.personId 
                            ? this.props.isInputInvalid
                            : this.props.isInputInvalid.bind(null, 'governmentServiceInfo')
                        }
                        isCurrent={isCurrent}
                        renderDatesRange={this.renderDatesRange}
                    >
                    </DateRange>*/}
                <this.ValidatableField
                    size="100"
                >
                    <Label htmlFor={this.getId('extraInfo', index)}>
                        Дополнительные сведения
                        </Label>
                    <InputText
                        id={this.getId('extraInfo', index)}
                        value={extraInfo}
                        maxLength={450}
                        onChange={e => this.props.onChange(index, { extraInfo: e.target.value })}
                    />
                </this.ValidatableField>
            </>
        );
    }

    getId = (name, index) => {
        return `${name}${index}`;
    }

    ValidatableField = (props) => {
        const {
            fieldKey,
            fieldValue,
            validationError,
            ...tail
        } = props;

        const isCurrent = this.props.value.isCurrent;
        const govtServiceErrorKey = `governmentService_${this.props.index}`;
        const validators = [this.validator(fieldValue, validationError)];
        const fieldState = this.getFieldState(isCurrent, fieldKey, fieldValue, validators, govtServiceErrorKey);

        return (
            <Field
                {...fieldState}
                {...tail}
            />
        );
    }

    validator = (value, errorMessage) => {
        return () => {
            return !!value ? null : errorMessage;
        };
    }

    getFieldState = (isCurrent, fieldKey, fieldValue, validators, governmentServiceErrorKey) => {
        const required = this.isValidatedField(isCurrent, fieldKey);

        return {
            required,
            filled: required ? this.props.isInputFilled(fieldValue, required) : null,
            invalid: required
                ? this.props.value.personId
                    ? this.props.isInputInvalid(fieldKey, validators, governmentServiceErrorKey)
                    : this.props.isInputInvalid('governmentServiceInfo', fieldKey, validators, governmentServiceErrorKey)
                : null
        };
    }

    isValidatedField = (isCurrent, fieldId) => {
        return !!this.getValidatedFields(isCurrent).find(elem => elem === fieldId);
    }

    getValidatedFields = (isCurrent) => {
        return isCurrent ? fieldsToValidateCurrentGovernmentService : fieldsToValidatePastGovernmentService;
    }

    renderDatesRange = (isStartDateValid = false, isEndDateValid = false) => {
        const { value, keySelector } = this.props;
        const { startDate, endDate } = value;
        const index = keySelector(value);
        return (
            <>
                <this.ValidatableField
                    isStart
                    fieldKey="startDate"
                    fieldValue={startDate}
                    validationError="Введите дату начала службы"
                    size="25"
                    invalid={isStartDateValid}
                >
                    <Label htmlFor={this.getId('startDate', index)}>Начало службы</Label>
                    <DatePicker
                        id={this.getId('startDate', index)}
                        selected={startDate ? new Date(startDate) : null}
                        onChange={value => this.props.onChange(index, { startDate: value && value.toDateString() })}
                        noDataBtn
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="endDate"
                    fieldValue={endDate}
                    validationError="Введите дату окончания службы"
                    size="25"
                    invalid={isEndDateValid}
                >
                    <Label htmlFor={this.getId('endDate', index)}>Окончание службы</Label>
                    <DatePicker
                        id={this.getId('endDate', index)}
                        bsSize="sm"
                        selected={endDate ? new Date(endDate) : null}
                        onChange={value => this.props.onChange(index, { endDate: value && value.toDateString() })}
                        noDataBtn
                    />
                </this.ValidatableField>
            </>
        )
    }
}

class GovernmentServiceBlock extends Component {
    render() {
        const {
            governmentServices,
        } = this.props;

        return (
            <DataList
                onAdd={this.addGovernmentService}
                onRemove={this.removeGovernmentService}
                canRemove={this.canRemoveGovernmentServiceBlock}
                entries={governmentServices.map((governmentService, index) => ({ ...governmentService, indexAsKey: index, personId: this.props.personId }))}
                keySelector={this.governmentServiceKeySelector}
                renderEntry={this.renderGovernmentServiceBlock}
            />
        );
    }

    addGovernmentService = () => {
        const governmentServices = this.props.governmentServices.push({ isCurrent: false });
        this.props.handleStateChange('governmentServiceInfo', { governmentServices });
    }

    removeGovernmentService = (govtService) => {
        const governmentServices = this.props.governmentServices.delete(this.governmentServiceKeySelector(govtService));
        this.props.handleStateChange('governmentServiceInfo', { governmentServices });
    }

    canRemoveGovernmentServiceBlock = (governmentService) => {
        return !governmentService.isCurrent;
    }

    changeGovernmentService = (index, changes) => {
        const governmentServices = this.props.governmentServices;
        const newGovernmentServices = governmentServices.set(index, {
            ...governmentServices.get(index),
            ...changes
        });

        this.props.handleStateChange('governmentServiceInfo', { governmentServices: newGovernmentServices });
    }

    changeCurrentGovernmentService = (index, isCurrent) => {
        const governmentServices = this.props.governmentServices.map(item => ({
            ...item,
            isCurrent: false
        }));

        if (isCurrent) {
            governmentServices.get(index).isCurrent = true;
            governmentServices.get(index).endDate = null;
        }

        this.props.handleStateChange('governmentServiceInfo', { governmentServices });
    }

    governmentServiceKeySelector = (governmentService) => {
        return governmentService.indexAsKey;
    }

    renderGovernmentServiceBlock = /* memoize */((governmentService) => {
        return (
            <GovernmentService
                keySelector={this.governmentServiceKeySelector}
                value={governmentService}
                onChange={this.changeGovernmentService}
                onChangeCurrentGovernmentService={this.changeCurrentGovernmentService}
                isInputFilled={this.props.isInputFilled}
                isInputInvalid={this.props.isInputInvalid}
                governmentServiceKindCatalog={this.props.governmentServiceKindCatalog}
            />
        );
    });
}

export default ToggleOpen(GovernmentServiceBlock);
