import React from 'react';
import classnames from 'classnames';
import Image from 'components/common/Image';

import './Avatar.scss';

const Avatar = props => {
    const { person, isUser } = props;

    const imageClassName = classnames({
        'CardItemPerson__Img-ImageMin': props.size === 'xs',
        'CardItemPerson__Img-Image': props.size === 'sm',
        'CardItemPerson__Img-ImageAuto': props.size !== 'sm' && props.size !== 'xs',
    });

    const url = isUser
        ? `/api/user/${person.userId || person.id}/photo?photoSize=small`
        : person.id
        ? `/api/person/${person.id}/photo?photoSize=small`
        : '';

    return <Image className={imageClassName} photoUri={url} size={props.size} />;
};

export default Avatar;
