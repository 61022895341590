import { appName } from '../constants';
import { takeLatest, call, all, put } from 'redux-saga/effects';
import { getEvaluationTestList, getTeamPersonEvaluationTestsCriterion } from 'api';
import { showErrorAlert } from './Alert';
import RequestError from 'RequestError';
import { isModerator } from 'rightsController';
const moduleName = 'evaluation';

const SELECTED = `${appName}/${moduleName}/SELECTED`;
const CLEAR = `${appName}/${moduleName}/CLEAR`;

const FETCH_TESTS = `${appName}/${moduleName}/FETCH_TESTS`;
const REQUEST_TESTS = `${appName}/${moduleName}/REQUEST_TESTS`;
const SET_TESTS = `${appName}/${moduleName}/SET_TESTS`;
const FETCH_TESTS_SUCCESS = `${appName}/${moduleName}/FETCH_TESTS_SUCCESS`;

const FETCH_TESTS_CRITERIAS = `${appName}/${moduleName}/FETCH_TESTS_CRITERIAS`;
const REQUEST_TESTS_CRITERIAS = `${appName}/${moduleName}/REQUEST_TESTS_CRITERIAS`;
const SET_TEST_CRITERIAS =  `${appName}/${moduleName}/SET_TEST_CRITERIAS`;
const FETCH_TESTS_CRITERIAS_SUCCESS = `${appName}/${moduleName}/FETCH_TESTS_CRITERIAS_SUCCESS`;

const initialState = {
    list: [],
    selected: {
        id:null,
        key:0
    },
    personId: null,
    testedPersons: [],
    isLoaded: false,
    loading: false,
    criteriasIsLoaded: false,
    criteriasLoading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_TESTS:
            return { ...state, isLoaded: false, personId: action.payload, loading: true };
        case SET_TESTS:
            return { ...state, list: action.payload };
        case FETCH_TESTS_SUCCESS:
            return { ...state, isLoaded: true, loading: false };
        case SELECTED:
            return { ...state, selected: action.payload };
        case CLEAR:
            return initialState;
        case REQUEST_TESTS_CRITERIAS:
            return { ...state, criteriasIsLoaded: false, personId: action.payload, criteriasLoading: true };
        case SET_TEST_CRITERIAS:
            return { ...state, testedPersons: action.payload };
        case FETCH_TESTS_CRITERIAS_SUCCESS:
            return { ...state, criteriasIsLoaded: true, criteriasLoading: false };
        default:
            return state;
    }
}

export const fetchEvaluation = () => {
    return { type: FETCH_TESTS }
};

const onRequestEvaluation = () => {
    return { type: REQUEST_TESTS }
};

const setEvaluation = payload => {
    return { type: SET_TESTS, payload }
};

const onLoadEvaluation = () => {
    return { type: FETCH_TESTS_SUCCESS }
};


export const setSelectes = payload => {
    return { type: SELECTED, payload }
};


export const setEvaluationTestsCriterion = payload => {
    return { type: FETCH_TESTS_CRITERIAS, payload }
};

const onRequestEvaluationTestCriterias = () => {
    return { type: REQUEST_TESTS_CRITERIAS }
};

const setEvaluationTestsCriterias = payload => {
    return { type: SET_TEST_CRITERIAS, payload }
};


const onLoadEvaluationTestCriterias = () => {
    return { type: FETCH_TESTS_CRITERIAS_SUCCESS }
};


function* fetchEvaluationSaga() {
    try {
        yield put(onRequestEvaluation());
        const response = yield call(getEvaluationTestList);
        yield put(setEvaluation( response.data ));
        yield put(onLoadEvaluation());
    } catch (error) {

        // Возвращается 404 когда выключены команды
        if (error && error.response && error.response.status === 404) {
            return;
        }

        const reqError = new RequestError(error, 'При загрузке команды произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

function* fetchEvaluationTestsCriterionSaga(action) {
    try {
        yield put(onRequestEvaluationTestCriterias());
        const response = yield call(getTeamPersonEvaluationTestsCriterion, action.payload);
        yield put(setEvaluationTestsCriterias( response.data ));
        yield put(onLoadEvaluationTestCriterias());
    
    } catch (error) {

        // Возвращается 404 когда выключены команды
        if (error && error.response && error.response.status === 404) {
            return;
        }

        const reqError = new RequestError(error, 'При загрузке команды произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

export function* saga() {
    yield all([
        takeLatest(FETCH_TESTS, fetchEvaluationSaga),
        takeLatest(FETCH_TESTS_CRITERIAS, fetchEvaluationTestsCriterionSaga),
    ]);
}

// @TODO перенести в сервисы
const getFeatures = config => config && config.data && config.data.features;

export const isAllowedRegion = (features, user) => {
    return (
        features &&
        features.allowedTeamGroups &&
        features.allowedTeamGroups.indexOf(user.groupId) !== -1
    );
};

export const isAccessEnabled = state => {
    const {
        config,
        auth: { user },
    } = state;
    const features = getFeatures(config);
    if (!features) {
        return false;
    }

    const { personAccess } = features;
    if (!personAccess) {
        return false;
    }

    return isAllowedRegion(features, user) && isModerator(user);
};