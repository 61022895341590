import React, { Component } from 'react';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import { isUploadFilesSizeValid } from 'libs/validators';
import { MAX_BYTES_PER_PHOTO_FILE } from 'constants.js';
import { getFileSize } from 'utils';
import { savePhoto } from 'ducks/User';
import { connect } from 'react-redux';
import { showErrorAlert } from 'ducks/Alert';
import './PhotoEditBlock.scss';

class PhotoEditBlock extends Component {
    state = {
        imageLoading: false,
    };

    onChangeFile = e => {
        const files = e.target.files;
        this.upload(files && files[0]);
    };

    upload = async (file) => {
        const { closeModal, showErrorAlert } = this.props;

        if (!file) {
            return;
        }

        if (!file.type.startsWith('image/') || file.type.endsWith('/gif')) {
            showErrorAlert('Некорректный тип фото: ' + file.type + '. Поддерживаются форматы JPEG и PNG');
            return;
        }

        if (!isUploadFilesSizeValid([file], MAX_BYTES_PER_PHOTO_FILE)) {
            showErrorAlert(`Размер файла не может превышать ${getFileSize(MAX_BYTES_PER_PHOTO_FILE)}.`);
            return;
        }

        try {
            this.setState({ imageLoading: true });
            await this.props.savePhoto(file);
        } catch(e) {
            showErrorAlert('Ошибка загрузки фото');
        } finally {
            this.setState({ imageLoading: false });
            closeModal();
        }

        
    };

    render() {
        const { imageLoading } = this.state;
        const { isOpen, closeModal } = this.props;
        return (
            <ModalPopup 
                isModalOpen={isOpen} 
                header={'Загрузить фото профиля'}
                headerInput={undefined}
                confirm={this.onChangeFile}
                onModalClose={closeModal} 
                actionButtons={['Закрыть', 'Выбрать файл']}
                fileUpload
                showCloseIcon={false} 
                processing={imageLoading}
                enabled={!imageLoading}
            />
        );
    }

}

const actions = { savePhoto, showErrorAlert };

export default connect(null, actions)(PhotoEditBlock);