import React from 'react';
import Button from "components/uikit/Button";
import { trimEnd, trimObjectStrings } from 'utils';
import "./ValidBlock.scss";
import { connect } from 'react-redux';

const props = state => {
    return {
        loading: !!state.personBlock.saving
            || !!state.person.loading
    }
}

export default Component => connect(props, null)(class ValidBlock extends React.Component {
    state = {
        loadComplete: false,
        isBlockSubmitted: false,
    }

    isInputInvalid = (inputStateKey, validators, inputErrorKey, customLookup, subBlockName, index) => {
        if (!this.state.isBlockSubmitted) {
            return false;
        }

        const errorKey = inputErrorKey || this.getInputErrorKey(inputStateKey);
        for (let validate of validators) {
            if (typeof validate !== 'function') {
                this[errorKey] = validate;

                if (inputStateKey === 'SocialNetworksBlock') {
                    this.isBlockValid = !validate;
                    return !validate;
                } 
            } else {
                let validateObject = null;
                const inputStateKeyType = typeof inputStateKey;
    
                if (subBlockName) {
                    if (inputStateKeyType === "object") {
                        validateObject = this.getValidatedObject(inputStateKey, this.props[subBlockName].get(index))
                    } else {
                        validateObject = this.props[index][inputStateKey];
                    }
                } else {
                    if (inputStateKeyType === "object") {
                        validateObject = this.getValidatedObject(inputStateKey, this.props)
                    } else {
                        validateObject = this.props[inputStateKey];
                    }
                }

                this[errorKey] = validate(customLookup ? customLookup[inputStateKey] :
                    validateObject);
            }
            if (this[errorKey]) {
                this.isBlockValid = false;
                return true;
            }
        }
    }

    getValidatedObject = (obj, stateBlock) => {
        switch(obj.type) {
            case "dateRange":
                return {
                    start: stateBlock[obj.start],
                    end: stateBlock[obj.end]
                }
            default:
                return obj;
        }
    }

    isInputFilled = (inputValue) => {
        return !!inputValue;
    }

    getInputErrorMessage = (inputStateKey, inputErrorKey) => {
        const errorKey = inputErrorKey || this.getInputErrorKey(inputStateKey);
        return this[errorKey];
    }

    getInputErrorKey(inputStateKey) {
        return `${inputStateKey}_error`;
    }

    getBlockToSave = (blockType) => {
        switch (blockType) {
            case 'PersonalInfoBlock':
                return getPersonGeneralInfo(blockType, this.props);

            case 'EducationBlock':
                const readyBlockObj = {
                    ...this.props,
                    blockId: this.props.blockId,
                    personId: this.props.personId,
                    blockType: blockType,
                    arr: this.props.educations.map(education => ({
                        ...trimObjectStrings(education),
                        educationLevelId: education.educationLevel && education.educationLevel.id,
                        studyFormId: education.studyForm && education.studyForm.id,
                    }))
                };
                return trimObjectStrings(readyBlockObj);

            case 'WorkBlock':
                return [
                    {
                        ...trimObjectStrings(this.props),
                        blockType: blockType,
                        arr: this.props.workPlaces.map(workPlace => ({
                            ...trimObjectStrings(workPlace),
                            personId: this.props.personId,
                            industryId: workPlace.industry && workPlace.industry.id,
                            workAreaId: workPlace.workArea && workPlace.workArea.id,
                            managementLevelId: workPlace.managementLevel && workPlace.managementLevel.id,
                            managementExperienceId: workPlace.managementExperience && workPlace.managementExperience.id,
                            employeesNumberId: workPlace.employeesNumber && workPlace.employeesNumber.id,
                        })),

                    },
                    {
                        ...trimObjectStrings(this.props),
                        blockType: 'GovernmentServiceBlock',
                        arr: this.props.governmentServices.map(govService => ({
                            ...trimObjectStrings(govService)
                        })),
                    }
                ];

            case 'EvaluationBlock':
                return {
                    ...trimObjectStrings(this.props),
                    blockType,
                    arr: this.props.evaluations.map(evaluation => ({
                        ...trimObjectStrings(evaluation)
                    })),
                };

            case 'LanguagesBlock':
                return {
                    ...this.props,
                    blockType: blockType,
                    arr: this.props.knownLanguages.filter(x => x.language).map(x => ({
                        id: x.id,
                        languageId: x.language.id,
                        languageLevelId: x.languageLevel.id,
                        fileAttachmentId: x.fileAttachmentId,
                    })),
                };

            case 'SocialNetworksBlock':
                return {
                    ...this.props,
                    blockType: blockType,
                    arr: this.props.networks.filter(x => x.value.trim()).map(x => ({
                        id: x.id,
                        networkId: x.network.id,
                        value: x.value.trim(),
                    })),
                };

            case 'FilesBlock':
                const {files} = this.props;

                const formData = new FormData();
                files.filter(x=>x.isNew).forEach(x=>formData.append('files', x.file));
                files.filter(x=>x.deleted).map(x=>x.id).forEach(x=>formData.append('removeIds', x))

                return {
                    ...trimObjectStrings(this.props),
                    blockType,
                    arr: formData
                };

            case 'FamilyStatusBlock':
                return getPersonGeneralInfo(blockType, this.props);

            case 'PersonnelInfoBlock': {
                const {
                    selectedDataOrigin,
                    selectedExperienceLevel,
                    awards,
                    curatorName,
                    selectedGeoLevel,
                    selectedManagerLevel,
                    curatorId,
                    ...personnelBlock
                } = this.props;
                
                return [
                    {
                        ...trimObjectStrings(personnelBlock),
                        blockType,
                        dataOriginId: selectedDataOrigin && selectedDataOrigin.id,
                        experienceLevelId: selectedExperienceLevel && selectedExperienceLevel.id,
                        curatorId: curatorId,
                        geoLevel: selectedGeoLevel && selectedGeoLevel.value,
                        managerLevel: selectedManagerLevel && selectedManagerLevel.value,
                        curatorName: curatorName,
                    },
                    {
                        ...trimObjectStrings(personnelBlock),
                        blockType: "AwardsBlock",
                        isArray: true,
                        arr: awards.filter(award => Object.keys(award).length).map(award => ({
                            competitionLevel: award.selectedCompetitionLevel && award.selectedCompetitionLevel.id,
                            name: award.name.trim(),
                        })),
                    },
                ];
            }

            case 'AttributesBlock':
                return {
                    ...trimObjectStrings(this.props),
                    blockType: blockType,
                    arr: this.props.attributes.filter(x => x.attribute).map(attribute => {
                        const trimmedAttribute = trimObjectStrings(attribute);
                        const hasJsonValue =
                            attribute.attribute.type.toLowerCase() === "select"
                            || attribute.attribute.type.toLowerCase() === "multiselect";

                        return {
                            ...trimmedAttribute,
                            attributeValue: hasJsonValue
                                ? JSON.stringify(attribute.attributeValue)
                                : attribute.attributeValue,
                            attribute: {
                                ...trimmedAttribute.attribute,
                                payload: ""
                            },
                        };
                    }),
                };

            case 'SystemInfoBlock':
                const {
                    personnelInfo
                } = this.props;
                
                const {
                    selectedCurator,
                    personId,
                } = this.props;

                return [
                    {
                        ...trimObjectStrings(personnelInfo),
                        blockType: 'CuratorBlock',
                        dataOriginId: personnelInfo.selectedDataOrigin && personnelInfo.selectedDataOrigin.id,
                        experienceLevelId: personnelInfo.selectedExperienceLevel && personnelInfo.selectedExperienceLevel.id,
                        curatorId: selectedCurator && selectedCurator.value.id,
                        geoLevel: personnelInfo.selectedGeoLevel && personnelInfo.selectedGeoLevel.value,
                        managerLevel: personnelInfo.selectedManagerLevel && personnelInfo.selectedManagerLevel.value,
                        curatorName: personnelInfo.curatorName,
                        personId: personId,
                        needPersonId: true
                    }, 
                    getPersonGeneralInfo(blockType, this.props)];
            case 'PersonCommentsBlock':
                const { comment } = this.props;
                return {
                    blockType: blockType,
                    forceUpdate: true,
                    personIds: [comment.personId],
                    personId: comment.personId,
                    comment: comment,
                    needPutRequest: true
                };
            default:
                return null;
        }
    }

    renderEditBlock = (saveOption = true) => {
        return (
            <div className="ValidBlock__EditBlock">
                    <Button
                        type="submit"
                        onClick={this.onSubmit}
                        loading={this.props.loading}
                        disabled={!saveOption}
                    >
                        Сохранить
                    </Button>
                {" "}
                <Button type="button" onClick={this.handleClose} color="default">
                    Отмена
                </Button>
            </div>
        );
    };

    renderCloseBlock = () => {
        return (
            <div className="ValidBlock__EditBlock">
                <Button type="button" onClick={this.handleClose} color="default">
                    Закрыть
                </Button>
            </div>
        );
    };

    scrollToBlock = () => this.props?.blockRef && window.scrollTo(0, this.props.blockRef.current.offsetTop - 100);
    
    validateBlock = (type) => {
        switch (type) {
            case 'SystemInfoBlock':
                return !!this.props.selectedGroup;
            default: 
                return true;
        }
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (this.props.blockType !== 'SocialNetworksBlock') {
            this.isBlockValid = true;
        }
        
        this.setState({ isBlockSubmitted: true }, () => {
            const { blockType } = this.props;
            if (this.isBlockValid && this.validateBlock(blockType)) {
                const blocks = this.getBlockToSave(blockType);
                if (Array.isArray(blocks)) {
                    blocks.forEach((block) => this.props.saveBlock(block, true));
                } else {
                    this.props.saveBlock(blocks, true);
                }
            } else {
                this.props.showWarningAlert('Не все поля корректно заполнены');
                this.scrollToBlock();
            }
        });
    };

    handleClose = () => {
        this.props.setEditBlock('');
        this.scrollToBlock();
    };
    
    render() {
        if (this.props.isEditBlock) {
            return (
                <div className="ValidBlock">
                    {this.props.loading ? <div className="ValidBlock__Overlay"></div> : null}
                    <Component
                        {...this.props}
                        isInputInvalid={this.isInputInvalid}
                        isInputFilled={this.isInputFilled}
                        getInputErrorMessage={this.getInputErrorMessage}
                        onSubmit={this.onSubmit}
                        renderEditBlock={this.renderEditBlock}
                        renderCloseBlock={this.renderCloseBlock}
                        isBlockSubmitted={this.state.isBlockSubmitted}
                        handleClose={this.handleClose}
                    />
                </div>
            );
        } else {
            return (
                <Component  {...this.props} />
            );
        }
    }
});

const getNormalizedMask = (value) => trimEnd(value, '_');

function getPersonGeneralInfo(blockType, props) {
    const readyPersInfoObj = {
        ...props,
        blockType: blockType,
        blockId: props.blockId,
        personId: props.personId,
        birthDate: props.birthDate && props.birthDate.toDateString(),
        federalDistrictId: props.isRussianCitizen ? (props.selectedDistrict && props.selectedDistrict.id) : null,
        regionId: props.isRussianCitizen ? (props.selectedRegion && props.selectedRegion.id) : null,
        localityId: props.isRussianCitizen ? (props.selectedLocality && props.selectedLocality.id) : null,
        foreignLocality: props.isRussianCitizen ? null : props.foreignLocality,
        foreignRegion: props.isRussianCitizen ? null : props.foreignRegion,
        residenceStartYear: props.residenceStartYear,
        nationalityId: props.isRussianCitizen ? null : (props.selectedNationality && props.selectedNationality.id),
        documentId: props.selectedDocument.id,
        documentNumber: getNormalizedMask(props.documentNumber || ''),
        sex: props.selectedSex.id,
        readyMoveToRussia: props.isRussianCitizen ? false : props.readyMoveToRussia,
        filesDirectoryId: props.filesDirectoryId,
        familyStatus: props.selectedFamilyStatus && props.selectedFamilyStatus.id,
        childrenStatusId: props.selectedChildrenStatus && props.selectedChildrenStatus.id,
        extraInfo: props.extraInfo,
        pastResidences: props.pastResidences.map((item) => ({
            ...item,
            federalDistrictId: item.selectedDistrict && item.selectedDistrict.id,
            regionId: item.selectedRegion && item.selectedRegion.id,
            localityId: item.selectedLocality && item.selectedLocality.id,
        })),
        groupId: props.selectedGroup || props.groupId,
        personGroupIds: props.selectedPersonGroupIds || props.personGroupIds || []
    };
    return trimObjectStrings(readyPersInfoObj);
}
