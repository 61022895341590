import React from "react";
import Label from "components/uikit/Label";
//import Select from 'react-select';
import Select from "components/uikit/Select";
import Button from 'components/uikit/Button';
import DatePicker from 'components/uikit/DatePicker';
import Input from 'components/uikit/Input';
import "./ListTemplateForm.scss";

export default function ListTemplateForm({ props }) {

    if (!props.title) {
        return '';
    }
    const { title, line, btnOnClick, btnClearOnClick } = props;

    return (
        <div className="ListTemplateForm">
            <div className="ListTemplateForm__Title">{title}</div>
            <div className="ListTemplateForm__Content">
                {line.map((item , key)  => {
                    const { type, label, ...tail } = item;
                    const getInputControl = (type, tail) => {
                        switch (type) {
                            case 'select':
                                return <div data-test={`${tail.dataTest}--select`}><Select {...tail} /></div>;
                            case 'DatePicker':
                                return <DatePicker  {...tail} />;
                            case 'Input':
                                return <Input {...tail} />;
                            default:
                                return type;
                        }
                    };

                    const tagForFilterForm = getInputControl(type, tail); 
                    
                    return (
                        <div className="ListTemplateForm__Line FieldItem" key={label+key}>
                            <Label>{label}</Label>
                            {tagForFilterForm}
                        </div>
                    )
                })}                
            </div>
            <div className="ListTemplateForm__Footer">
                <div className="ListTemplateForm__Buttons">
                    <Button data-test={`${props.dataTest}Submit--button`} size='sm' onClick={btnOnClick}>Применить</Button>
                    <Button data-test={`${props.dataTest}Clear--button`} closeLink onClick={btnClearOnClick}>Очистить</Button>
                </div>
            </div>
        </div>
    )
}