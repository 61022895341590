import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import catalogReducer from 'ducks/Catalog';
import personReducer from 'ducks/Person';
import searchReducer from 'ducks/Search';
import alertReducer from 'ducks/Alert';
import pageLoaderReducer from 'ducks/PageLoader';
import userReducer from 'ducks/User';
import usersReducer from 'ducks/Users';
import groupReducer from 'ducks/Group';
import groupsReducer from 'ducks/Groups';
import authReducer from 'ducks/Auth';
import personBlockReducer from 'ducks/PersonBlock';
import acceptModalReducer from 'ducks/AcceptModal';
import attributesReducer from 'ducks/Attributes';
import attributeReducer from 'ducks/Attribute';
import configReducer from 'ducks/Config';
import importReducer from 'ducks/Import';
import { reducer as issuesReducer } from 'ducks/Issues';
import analyticsReducer from 'ducks/Analytics';
import planReducer from './ducks/PersonGrowthPlan';
import personCardConfigReducer from 'ducks/PersonCardConfig';
import { reducer as dictionaryReducer } from 'ducks/Dictionary';
import accessReducer from 'ducks/Access';
import externalSearchReducer from 'ducks/ExternalSearch';
import teamsReducer from 'ducks/Teams';
import favoritesReducer from 'ducks/Favorites';
import widgetReducer from 'ducks/Widget';
import eventsReducer from 'ducks/Events';
import progressCartReducer from 'ducks/ProgressCart';
import evaluationReducer from 'ducks/Evaluation';
import { reducer as basketsReducer } from 'ducks/Baskets';
import { reducer as wishlistsReducer } from 'ducks/Wishlists';
import filterSarchReducer from 'ducks/FilterSearch';
import { SIGN_OUT_SUCCESS } from 'ducks/Auth';
import testProvidersReducer from 'ducks/TestProviders';
import personCommentsReducer from 'ducks/PersonComments';
import teamReportReducer from 'ducks/TeamReport'
import dashboardReducer from 'ducks/Dashboard'

const appReducer = (history) => combineReducers({
    router: connectRouter(history),
    catalogs: catalogReducer,
    person: personReducer,
    search: searchReducer,
    alert: alertReducer,
    pageLoader: pageLoaderReducer,
    user: userReducer,
    users: usersReducer,
    group: groupReducer,
    groups: groupsReducer,
    auth: authReducer,
    personBlock: personBlockReducer,
    acceptModal: acceptModalReducer,
    attributes: attributesReducer,
    attribute: attributeReducer,
    dictionary: dictionaryReducer,
    config: configReducer,
    import: importReducer,
    issues: issuesReducer,
    analytics: analyticsReducer,
    plan: planReducer,
    personCardConfig: personCardConfigReducer,
    access: accessReducer,
    externalSearch: externalSearchReducer,
    teams: teamsReducer,
    favorites: favoritesReducer,
    widget: widgetReducer,
    events: eventsReducer,
    progressCart: progressCartReducer,
    evaluation: evaluationReducer,
    baskets: basketsReducer,
    wishlists: wishlistsReducer,
    filterSearch: filterSarchReducer,
    testProviders: testProvidersReducer,
    personComments: personCommentsReducer,
    teamReport:teamReportReducer,
    dashboard:dashboardReducer
});

const rootReducer = (history) => (state, action) => {
    if (action.type === SIGN_OUT_SUCCESS) {
        state = {
            router: { ...state.router },
        };
    }

    return appReducer(history)(state, action);
};

export default rootReducer;
