import React from 'react';
import classnames from "classnames";
import { DataCard } from "components/common/DataCard";

class Menu extends React.PureComponent {
    static defaultProps = {
        viewCheckMark: true,
        getItemState: () => ({
            checked: false,
        }),
    }

    onActiveItemChanged = (key) => () => {
        this.props.onActiveItemChanged(key);
    }

    renderItem = (item) => {
        const { 
            activeItemKey, 
            getItemState, 
        } = this.props;

        const isActive = activeItemKey === item.key;
        const itemState = getItemState(item);

        const className = classnames({
            "kr_active": isActive,
            "kr_checked": !isActive && itemState.checked,
            "kr_hidden": item.visible !== undefined && !item.visible,
        });

        return (
            <li key={item.key} className={className}>
                <button onClick={this.onActiveItemChanged(item)}>
                    {item.text}
                </button>
            </li>
        );
    }

    render = () => {
        const { items, onClear, viewCheckMark } = this.props;

        return (
            <DataCard shadow className={classnames("kr_profile-menu", { "kr_v2": !viewCheckMark })}>
                <div className="kr_menu">
                    <ul>{items.map(this.renderItem)}</ul>
                </div>
                {onClear && 
                    <button className="kr_profile-link-close" onClick={onClear}>
                        Очистить все
                    </button>
                }
            </DataCard>
        );
    }
}

export default Menu;
