import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getWishLists } from 'api';
import {
    createNewWishList,
    addToWishList,
    removeFromWishList,
    setPersonCart,
    setWishlistPersonsUpdated,
} from 'ducks/Wishlists';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { setMenuId } from 'ducks/FilterSearch';
import { FAVORITES_MENU_ID } from 'components/Lk/Shared/Search/constants';
import classnames from 'classnames';
import { lkSearch } from 'routes';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/common/Loader';
import FooterAcceptMenu from 'components/Lk/Common/FooterAcceptMenu';
import NewWishlist from './NewWishlist';
import EditWishListsBlock from './EditWishListsBlock';
import './EditWishLists.scss';

class EditWishLists extends Component {
    state = {
        selectedItem: null,
        loading: false,
        paging: { pageNum: 1, pageSize: 20 },
        isOpenFooterMenu: false,
        wishLists: [],
        pageCount: 1,
        hasMore: true,
        isNewWishList: false,
        wishlistUpdating: false,
    };

    goBack = () => {
        this.props.push({ pathname: lkSearch.url, search: this.props.history.location.search });
    };

    onItemClick = id => () => {
        this.setState({
            selectedItem: id,
            isOpenFooterMenu: true,
        });
    };

    cancelItemSelect = () => {
        this.setState({
            selectedItem: null,
            isOpenFooterMenu: false,
        });
    };

    cancelEditWishlists = () => {
        this.setState({
            selectedItem: null,
            isOpenFooterMenu: false,
        });
        this.props.setMenuId(FAVORITES_MENU_ID);
        this.goBack();
    };

    updateWishList = async cart => {
        const { personIds } = cart;
        const { selectedItem } = this.state;
        const { addToWishList, removeFromWishList, showErrorAlert } = this.props;

        try {
            if (cart.type === 'add') {
                await addToWishList({ id: selectedItem, personIds });
            } else {
                await removeFromWishList({ id: selectedItem, personIds });
            }
        } catch (error) {
            showErrorAlert(
                `При ${
                    cart.type === 'add' ? 'добавлении' : 'удалении'
                } резервиста(ов) произошла ошибка`,
            );
        }
    };

    createWishList = async payload => {
        if (payload.name.trim() === '') {
            this.props.showWarningAlert(`Не указано название подборки`);
            return false;
        }
        this.setState({
            wishLists: [],
            paging: { pageNum: 1, pageSize: 10 },
            wishlistUpdating: true,
        });
        const { personIds } = this.props.cart;
        await this.props.createNewWishList({ ...payload, withPersons: true, personIds });
    };

    loadLists = async () => {
        const { paging, hasMore } = this.state;
        if (hasMore) {
            this.setState({ loading: true, hasMore: false });
            const wishlists = await getWishLists({ paging });
            this.setState({
                wishLists: [...this.state.wishLists, ...wishlists.data.payload],
                hasMore: wishlists.data.meta.pageCount > paging.pageNum,
                paging: { ...paging, pageNum: paging.pageNum + 1 },
                loading: false,
            });
        }
    };

    setNewWishList = (isOpen = false) => () => {
        this.setState({ isNewWishList: isOpen });
    };

    componentDidMount() {
        this.props.cart === undefined && this.props.push(lkSearch.url);
        this.loadLists();
    }

    componentDidUpdate() {
        if (this.props.isWishlistPersonsUpdated) {
            this.setState({ wishlistUpdating: false });
            this.props.setMenuId(FAVORITES_MENU_ID);
            this.props.setWishlistPersonsUpdated({ isWishlistPersonsUpdated: false });
            this.goBack();
        }
    }

    componentWillUnmount() {
        this.props.setPersonCart({ personIds: [], type: null });
    }

    render() {
        const {
            isOpenFooterMenu,
            loading,
            wishLists,
            isNewWishList,
            wishlistUpdating,
            hasMore, 
            selectedItem,
        } = this.state;
        
        const { activeCard, setActiveCard, cart, editLoading } = this.props;

        setActiveCard(ACTIVE_DETAIL_CARD);
        const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;
        return isNewWishList ? (
            <NewWishlist
                setNewWishlist={this.setNewWishList}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
                createWishList={this.createWishList}
            />
        ) : (
            <div
                className={classnames('DetailsCard', {
                    'DetailsCard--isMobileActive': isMobileActive,
                })}
            >
                {!wishlistUpdating && !editLoading ? (
                    <div className="LKEditWishLists">
                        <div className="LKEditWishListsHeader" onClick={this.goBack}>
                            <div className="LKEditWishListsAction">
                                <FontAwesomeIcon icon="chevron-left" />
                            </div>
                            <div className="LKEditWishListsTitle">
                                {cart && cart.type === 'add'
                                    ? 'Добавить резервиста(ов) в подборку'
                                    : 'Удалить резервиста(ов) из подборки'}
                            </div>
                        </div>
                        <EditWishListsBlock
                            wishlists={wishLists}
                            hasMore={hasMore}
                            selectedItem={selectedItem}
                            loadLists={this.loadLists}
                            onItemClick={this.onItemClick}
                            withAddBlock={cart && cart.type === 'add'}
                            setNewWishList={this.setNewWishList}
                        />
                        <Loader show={loading} />
                        <FooterAcceptMenu
                            show={isOpenFooterMenu}
                            onCancel={this.cancelEditWishlists}
                            handleAcceptClick={() => this.updateWishList(cart)}
                            okText={cart && cart.type === 'add' ? 'Добавить' : 'Удалить'}
                        />
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cart: state.wishlists.cart,
        isWishlistPersonsUpdated: state.wishlists.isWishlistPersonsUpdated,
        editLoading: state.wishlists.editLoading,
    };
};

const actions = {
    push,
    createNewWishList,
    addToWishList,
    removeFromWishList,
    setPersonCart,
    showErrorAlert,
    showWarningAlert,
    setMenuId,
    setWishlistPersonsUpdated,
};

export default connect(
    mapStateToProps,
    actions,
)(EditWishLists);
