import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MenuSwitcherItem from './MenuSwitcherItem';
import './MenuSwitcher.scss';

const MenuSwitcher = props => {
    const { onMenuClick, menu, selectedMenuId, addMenuInfo, theme, tabs, activeTab } = props;
    const [activeMenuId, setActiveMenuId] = useState();

    useEffect(() => {
        setActiveMenuId(selectedMenuId);
    }, [selectedMenuId]);

    const onItemClick = e => {
        const newMenuId = e.currentTarget.dataset.itemid;
        onMenuClick(newMenuId);
    };

    const getAddMenuInfo = menuId => {
        if (addMenuInfo) {
            return addMenuInfo.find(x => x.menuId === menuId)?.info || '';
        }
        return '';
    };

    return (
        <div className="MenuSwitcherWrapper">
            <div className="MenuSwitcher">
                {menu.map(x => (
                    <MenuSwitcherItem
                        key={x.id}
                        itemId={x.id}
                        title={`${x.title}${getAddMenuInfo(x.id)}`}
                        isActive={x.id === activeMenuId}
                        onClick={x.id !== activeMenuId ? onItemClick : undefined}
                        theme={theme}
                        isTab={tabs}
                    />
                ))}
            </div>
            {tabs && <div className="MenuSwitcher__ActiveTab">{activeTab}</div>}
        </div>
    );
};

MenuSwitcher.defaultProps = {
    theme: 'light',
    tabs: false,
    activeTab: '',
};

MenuSwitcher.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any, title: PropTypes.string }))
        .isRequired,
    selectedMenuId: PropTypes.any,
    onMenuClick: PropTypes.func.isRequired,
    addMenuInfo: PropTypes.arrayOf(
        PropTypes.shape({ menuId: PropTypes.any, info: PropTypes.string }),
    ),
    theme: PropTypes.oneOf(['light', 'dark']),
    tabs: PropTypes.bool,
    activeTab: PropTypes.any,
};

export default MenuSwitcher;
