import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import { serviceResultCode, getError } from 'serviceErrors';
import { searchPersonGrowthTags, deletePersonGrowthTag } from 'api';
import { personGrowthTagRoute, personGrowthTagsRoute } from 'routes';
import { showErrorAlert, showSuccessAlert, showWarningAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

import Page from 'components/common/Page';
import DataGrid from 'components/common/DataGrid';
import ModalDialog from 'components/common/ModalDialog';

import Button from 'components/uikit/Button';

import PropTypes from 'prop-types';
import { localizeTimezone } from 'utils';

import './PersonGrowthTags.scss';
import uuid from 'uuid/v4';
import ListTemplate from 'components/uikit/ListTemplate/ListTemplate';
import { tagTypes } from 'constants.js';
import { parse as queryStringParse, stringify } from 'query-string';

const getUrlFilterParams = (state) => {
    return {
        page: state.paging.pageNum !== null ? Number(state.paging.pageNum) : 1,
        pageSize: state.paging.pageSize !== null ? Number(state.paging.pageSize) : 10,
        searchByNameQuery: state.filter.searchByNameQuery,
        fromCreatedDate: !!state.filter.fromCreatedDate
            ? state.filter.fromCreatedDate.toISOString()
            : null,
        toCreatedDate: !!state.filter.toCreatedDate
            ? state.filter.toCreatedDate.toISOString()
            : null,
        isInternal: state.filter.isInternal,
    };
};

const buildColumns = ({ editPersonGrowthTag, openDeleteRequestModal }, search) => [
    {
        Header: 'Название тега',
        accessor: 'name',
        resizable: false,
        sortable: true,
        Cell: ({ original }) => (
            <NavLink
                to={personGrowthTagRoute.buildUrl({ id: original.id, regime: 'view' }, search)}
            >
                {original.name}
            </NavLink>
        ),
    },
    {
        Header: 'Дата',
        accessor: 'createdOn',
        resizable: false,
        sortable: false,
        Cell: ({ original }) => localizeTimezone(original.createdOn),
    },
    {
        Header: 'Тип тега',
        accessor: 'IsInternal',
        maxWidth: 250,
        resizable: false,
        sortable: false,
        Cell: ({ original }) => {
            const tagType = tagTypes.find((x) => x.type === original.isInternal);
            return !!tagType && tagType.name;
        },
    },
    {
        Header: 'Действие',
        resizable: false,
        sortable: false,
        Cell: ({ original }) => (
            <Button
                size="sm"
                onClick={() => editPersonGrowthTag(original.id)}
                className="PersonGrowthTagEditBtn"
            >
                Редактировать
            </Button>
        ),
    },
    {
        Header: '',
        resizable: false,
        sortable: false,
        Cell: ({ original }) => (
            <Button
                size="sm"
                color="danger"
                onClick={() => openDeleteRequestModal(original.id)}
                className="PersonGrowthTagDeleteBtn"
            >
                Удалить
            </Button>
        ),
    },
];

const initialState = {
    filter: {
        searchByNameQuery: '',
        fromCreatedDate: '',
        toCreatedDate: '',
        isInternal: null,
    },
    paging: { pageNum: 1, pageSize: 10 },
    sorting: { name: 'asc' },

    tags: [],

    meta: {
        foundCount: 0,
        pageCount: 0,
    },

    loadComplete: false,

    requestId: null,
    isOpenDeleteRequestModal: false,
    processing: false,
};

class PersonGrowthTags extends Component {
    static propTypes = {
        showErrorAlert: PropTypes.func.isRequired,
        showWarningAlert: PropTypes.func.isRequired,
        showSuccessAlert: PropTypes.func.isRequired,
        showPageLoader: PropTypes.func.isRequired,
        hidePageLoader: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    };

    state = initialState;

    componentDidMount() {
        const { paging, filter } = this.getStateFromUrl();

        this.setState({ paging, filter }, () => this.performPersonGrowthTagsFetching());
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            const { paging, filter } = this.getStateFromUrl();
            this.setState({ paging, filter }, () => this.performPersonGrowthTagsFetching());
        }
    }

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } },
            () => this.updateURL(),
        );
    };

    getStateFromUrl = () => {
        const {
            searchByNameQuery = '',
            fromCreatedDate = '',
            toCreatedDate = '',
            isInternal = null,
            page = 1,
            pageSize = 10
        } = queryStringParse(this.props.location.search);

        return {
            filter: {
                searchByNameQuery,
                fromCreatedDate: !!fromCreatedDate ? new Date(fromCreatedDate) : '',
                toCreatedDate: !!toCreatedDate ? new Date(toCreatedDate) : '',
                isInternal: isInternal === null ? null : isInternal === 'true' ? true : false,
            },
            paging: {
                ...this.state.paging,
                pageSize: +pageSize,
                pageNum: +page,
            },
        };
    };

    openDeleteRequestModal = (id) => {
        this.setState({
            isOpenDeleteRequestModal: true,
            requestId: id,
        });
    };

    deleteRequest = async () => {
        this.setState({ processing: true });

        this.callEffect(async () => {
            const response = await deletePersonGrowthTag(this.state.requestId);

            if (response && response.status === 200) {
                this.props.showSuccessAlert('Тег инструмента развития успешно удален');

                this.setState(
                    (state) => {
                        return {
                            ...state,
                            isOpenDeleteRequestModal: false,
                            requestId: null,
                            processing: false,
                        };
                    },
                    () => this.performPersonGrowthTagsFetching(),
                );
            }
        });
    };

    onCancelDeleteRequest = () => {
        this.setState((state) => {
            return {
                ...state,
                isOpenDeleteRequestModal: false,
                requestId: null,
                processing: false,
            };
        });
    };

    updateURL = () => {
        const filter = getUrlFilterParams(this.state);
        const baseFilter = { ...filter };

        this.props.push({
            pathname: personGrowthTagsRoute.url,
            search: stringify(baseFilter),
        });
    };

    newPersonGrowthTag = () => {
        this.props.push(
            personGrowthTagRoute.buildUrl(
                { id: uuid(), regime: 'new' },
                this.props.location.search,
            ),
        );
    };

    editPersonGrowthTag = (id) => {
        this.props.push(
            personGrowthTagRoute.buildUrl({ id, regime: 'edit' }, this.props.location.search),
        );
    };

    filterSubmit = () => {
        const { paging, filter: filterFromState } = this.state;

        const { filter: filterFromUrl } = this.getStateFromUrl();
        if (JSON.stringify(filterFromUrl) === JSON.stringify(filterFromState)) {
            return;
        }

        this.setState(
            {
                paging: { ...paging, pageNum: 1 },
                loadComplete: false,
            },
            () => this.updateURL(),
        );
    };

    handleEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            this.filterSubmit();
        }
    };

    onClearFilters = (e) => {
        e.preventDefault();
        this.clearFilters();
    };

    clearFilters = () => {
        const { filter: filterFromUrl } = this.getStateFromUrl();
        if (JSON.stringify(filterFromUrl) === JSON.stringify(initialState.filter)) {
            return;
        }

        this.setState(initialState, () => this.updateURL());
    };

    onPageChange = (pageIndex) => {
        const { paging } = this.state;
        this.setState(
            {
                paging: { ...paging, pageNum: pageIndex + 1 },
            },
            () => this.updateURL(),
        );
    };

    onSortedChange = (sorted) => {
        this.setState(
            {
                sorted,
                sorting: { [sorted[0].id]: sorted[0].desc ? 'asc' : 'desc' },
            },
            () => this.performPersonGrowthTagsFetching(),
        );
    };

    onSearchByNameChange = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                searchByNameQuery: e.target.value,
            },
        });
    };

    handleFilterTypeOnChange = (item) => {
        this.setState({
            filter: {
                ...this.state.filter,
                isInternal: tagTypes.find((x) => x.id === item.value).type,
            },
        });
    };

    fillSelect = (dictionary) => {
        if (dictionary) {
            return dictionary.map((x) => {
                return { value: x.id, label: x.name };
            });
        } else {
            return [];
        }
    };

    onFromCreatedDateChange = (newDate) => {
        this.setState({
            filter: {
                ...this.state.filter,
                fromCreatedDate: newDate,
            },
        });
    };

    onToCreatedDateChange = (newDate) => {
        this.setState({
            filter: {
                ...this.state.filter,
                toCreatedDate: newDate,
            },
        });
    };

    performPersonGrowthTagsFetching = async () => {
        const { filter, paging, sorting } = this.state;

        this.callEffect(async () => {
            const response = await searchPersonGrowthTags({ filter, paging, sorting });

            if (
                response &&
                response.status === 200 &&
                response.data.payload &&
                response.data.meta
            ) {
                this.setState({
                    tags: response.data.payload,
                    meta: response.data.meta,
                    loadComplete: true,
                });
            }
        });
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();

        try {
            await callback();
        } catch (error) {
            const reqError = getError(error, this.getTagsListError);
            this.props.showErrorAlert(reqError.message);
            if (
                error.response &&
                error.response.data &&
                error.response.data.code &&
                error.response.data.code === 616
            ) {
                this.onCancelDeleteRequest();
            }
        } finally {
            this.props.hidePageLoader();
        }
    };

    getTagsListError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `${payload}`;
            case serviceResultCode.PersonGrowthTagsListError:
                return `${payload}`;
            case serviceResultCode.PersonGrowthTagAttachedToResource:
                return `${payload}`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    render() {
        if (!this.state.loadComplete) {
            return null;
        }

        const columns = buildColumns(
            {
                editPersonGrowthTag: this.editPersonGrowthTag,
                openDeleteRequestModal: this.openDeleteRequestModal,
            },
            this.props.location.search,
        );

        const customSelectStyles = {
            control: (base) => ({
                ...base,
                height: 55,
                backgroundColor: '#f9f9ff',
                borderColor: '#e6dfff',
            }),
            input: (base) => ({
                ...base,
                padding: 0,
                margin: 0,
            }),
            menu: (base) => ({
                ...base,
                color: '#767268',
            }),
            singleValue: (base) => ({
                ...base,
                color: '#767268',
            }),
            valueContainer: (base) => ({
                ...base,
                height: 55,
            }),
        };

        const { filter, paging } = this.state;

        const filterForm = {
            title: 'Фильтр',
            line: [
                {
                    type: 'Input',
                    label: 'Тег',
                    id: 'searchByName',
                    name: 'searchByName',
                    value: filter.searchByNameQuery,
                    onChange: this.onSearchByNameChange,
                    onKeyDown: this.handleEnterKeyPressed,
                },
                {
                    type: 'DatePicker',
                    label: 'Дата создания тега, с',
                    id: 'fromCreatedDate',
                    name: 'fromCreatedDate',
                    autoComplete: 'off',
                    selected: filter.fromCreatedDate,
                    onChange: this.onFromCreatedDateChange,
                },
                {
                    type: 'DatePicker',
                    label: 'Дата создания тега, по',
                    id: 'toCreatedDate',
                    name: 'toCreatedDate',
                    autoComplete: 'off',
                    selected: filter.toCreatedDate,
                    onChange: this.onToCreatedDateChange,
                },
                {
                    type: 'select',
                    label: 'Тип',
                    value: this.fillSelect(tagTypes.filter((x) => x.type === filter.isInternal)),
                    inputId: 'selectedType',
                    placeholder: 'Выберите тип',
                    options: this.fillSelect(tagTypes),
                    onChange: this.handleFilterTypeOnChange,
                    styles: customSelectStyles,
                },
            ],
            btnOnClick: this.filterSubmit,
            btnClearOnClick: this.onClearFilters,
        };

        const headBtn = {
            onClick: this.newPersonGrowthTag,
            value: 'Создать тег',
            addLink: true,
        };

        return (
            <Page>
                <div className="PersonGrowthTags_Wrapper">
                    <ListTemplate title={'Теги'} headBtn={headBtn} form={filterForm}>
                        <DataGrid
                            data={this.state.tags}
                            foundCount={this.state.meta.foundCount}
                            columns={columns}
                            loading={!this.state.loadComplete}
                            showPagination={true}
                            showPageSizeOptions={false}
                            onSortedChange={this.onSortedChange}
                            pages={this.state.meta.pageCount}
                            page={paging.pageNum}
                            onPageChange={this.onPageChange}
                            manual
                            pageSize={paging.pageSize}
                            changePageAmount={this.changePageAmount}
                        />
                        <ModalDialog
                            onClick={this.deleteRequest}
                            onCloseModal={this.onCancelDeleteRequest}
                            modalOpen={this.state.isOpenDeleteRequestModal}
                            modalHeader="Вы уверены, что хотите удалить тег со всеми его связями?"
                            btnOktext="Удалить"
                            btnCanceltext="Отмена"
                            btnOkColor="danger"
                            isDangerHeader="true"
                            processing={this.state.processing}
                        ></ModalDialog>
                    </ListTemplate>
                </div>
            </Page>
        );
    }
}

const actions = {
    showErrorAlert,
    showSuccessAlert,
    showWarningAlert,
    showPageLoader,
    hidePageLoader,
    push,
};

export default connect(null, actions)(PersonGrowthTags);
