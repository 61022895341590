import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/uikit/Input';
import Label from 'components/uikit/Label';
import Button from 'components/uikit/Button';
import { emailValidator } from 'libs/validators';

const ResetPassword = (props) => {
    const { login, onChange, resetPassword, setResetPasswordStage } = props;
    return (
        <>
            <div className="kr_item required">
                <Label className="SignInResetPasswordLabel">
                    Для восстановления пароля укажите адрес эл. почты и мы вышлем вам инструкцию по
                    восстановлению
                </Label>
                <Input
                    name="login"
                    id="login"
                    type="email"
                    required
                    autoFocus
                    value={login}
                    onChange={onChange}
                    data-test="SignInResetPasswordEmail--input"
                />
            </div>
            <div className="kr_item SignInResetPasswordButtonContainer">
                <Button
                    className="SignInResetPasswordButton"
                    disabled={!!emailValidator()(login) || !login}
                    onClick={() => {
                        resetPassword(login);
                    }}
                    data-test="SignInResetPasswordSendEmail--button"
                >
                    Отправить
                </Button>
                <Button
                    className="SignInResetPasswordButton"
                    onClick={() => setResetPasswordStage(false)}
                    data-test="SignInResetPasswordCancel--button"
                >
                    Вернуться
                </Button>
            </div>
        </>
    );
};

ResetPassword.propTypes = {
    login: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    setResetPasswordStage: PropTypes.func.isRequired,
};

export default ResetPassword;
