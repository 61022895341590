import React, { Component } from 'react';
import DataGrid from 'components/common/DataGrid';
import "./Reports.scss";
import Button from "components/uikit/Button";
import ModalDialog from 'components/common/ModalDialog';

class MyReports extends Component {
    state = {
        modalOpen: false,
        modalReportId: null,
    }

    static getDerivedStateFromProps(props, state) {
        if (state.reports !== props.reports) {
            return {
                reports: props.reports
            };
        }
        return null;
    }

    buildColumns = (editReport, openDeleteReport) => {
        return [
            {
                Header: '',
                maxWidth: 50,
                accessor: 'isDefaultIcon',
                sortable: false,
                resizable: false,
            },            
            {
                Header: 'Название',
                accessor: 'name',
                sortable: false,
                resizable: false,
            },
            {
                Header: 'Тип',
                accessor: 'baseRus',
                sortable: false,
                resizable: false,
            },
            {
                Header: "Группа",
                accessor: 'group',
                sortable: false,
                resizable: false,
                Cell: ({original}) => {
                    return original.group
                }
            },
            {
                Header: 'Действия',
                sortable: false,
                resizable: false,
                minWidth: 290,
                maxWidth: 300,
                Cell: ({ original }) => {
                    return (<div className="ActionButtons">
                        {this.renderEditButton(original.id, original.base, editReport)}
                        {this.renderDeleteButton(original.id, openDeleteReport)}
                    </div>)
                },                
            },
        ];
    }

    renderEditButton = (id, reportType, editReport) => {
        return (
            <Button
                className="My-Reports__ActionButton"
                faIcon="pencil-alt"
                onClick={() => editReport(id, reportType)}
            >
                Редактировать
            </Button>
        )
    }
    
    renderDeleteButton = (id) => {
        return (
            <Button
                className="My-Reports__ActionButton"
                color="danger"
                faIcon="trash"
                onClick={() => this.openDeleteDialog(id)}
            >
                Удалить
            </Button>
        )
    }

    editReport = (id, reportType) => {
        this.props.handleStateChange('reportType', reportType, {reloadReport: true, reportType: reportType, id: id});
    }

    openDeleteDialog = (id) => {
        this.onOpenModal(id);
    }

    deleteReport = (id) => {
        this.props.deleteReportTemplate(id);
        this.onCloseModal();
    }

    onOpenModal = (id) => {
        this.setState({ modalOpen: true, modalReportId: id});
    };
     
    onCloseModal = () => {
        this.setState({ modalOpen: false, modalReportId: null});
    };

    render() {
        return (
            <>
                <div className="My-Reports">
                    <DataGrid 
                        data={this.state.reports}
                        columns={this.buildColumns(this.editReport, this.deleteReport)}
                        showPagination={false}
                        pageSize={this.state?.reports?.length || 10}
                    />            
                </div>

                <ModalDialog
                    onClick={() => this.deleteReport(this.state.modalReportId)}
                    onCloseModal={this.onCloseModal}
                    modalOpen={this.state.modalOpen}
                    modalHeader="Вы действительно хотите удалить шаблон отчета?"
                    btnOktext="Да"
                    btnOkColor="danger"
                    btnCanceltext="Нет"
                /> 
            </>
        )
    }
}

export default MyReports;