import React from 'react';
import PropTypes from 'prop-types';
import './SorterButton.scss';

export const SorterButton = props => {
    
    const { options, value, onChange } = props;
    
    const handleOnClick = (e) => {
        e.preventDefault();
        onChange(options.find(x => x.id === (value.id % options.length) + 1));
    };
    
    return (
        <div
            className={`LKSorterButton LKSorterButton__Chevron--${value.indicator}`}
            onClick={handleOnClick}
        >
            {value.label}
        </div>
    )
};

SorterButton.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.shape({
        id: PropTypes.number.isRequired,
        indicator: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
};

export default SorterButton;