import React from 'react';
import PropTypes from 'prop-types';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import './EvaluationType.scss';
import classnames from 'classnames';
import uuid from 'uuid/v4'
import Popover from 'components/Lk/Uikit/Popover';


const EvaluationType = props => {
    const titeText = 'Пока нет подсказки для вашего теста';
    
    const { id = uuid(), title, description, icon, onClick = () => {}, tooltip = null } = props;

    const tooltipText = description !== undefined && description !== null ? description : titeText;

    return (
        <div
            className={classnames('EvaluationType', { 'EvaluationType--noIcon': !icon })}
            onClick={onClick(id, title, tooltipText)}
        >
            {icon && (
                <div className="EvaluationType__Icon">
                    <Icons name={icon} />
                </div>
            )}
            <div id={id} className="EvaluationType__Body">
                {title}
            </div>
            {tooltip && (
                <>
                    <Popover tooltip={tooltipText}>
                        <div className="EvaluationType__Info">
                            <Icons name="help-alt" fill={'#007AFF'} />
                        </div>
                    </Popover>
                </>
            )}
        </div>
    );
};

EvaluationType.propTypes = {
    id: PropTypes.string,
    unknownProviderTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

export default EvaluationType;
