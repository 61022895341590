import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 *
 * @param {String} props buttonCls
 */
const RoundedButton = (props) => {
    const {
        color = 'blue',
        icon,
        disabled,
        loading,
        hideContent,
        inline,
        modifier,
        className = '',
        ...tail
    } = props;

    const finalBlockClass = classnames('LKRoundedButton__Block', {
        [`LKRoundedButton__Block--Inline`]: inline,
        [`LKRoundedButton__Block--${modifier}`]: modifier,
    });
    const finalClass = classnames('LKRoundedButton', className, {
        [`LKRoundedButton--color-${color}`]: true,
        [`LKRoundedButton--icon-${icon}`]: icon,
        [`LKRoundedButton--disabled`]: disabled || loading,
    });
    return (
        <div className={finalBlockClass}>
            <button {...tail} className={finalClass} disabled={disabled || loading}>
                {loading && (
                    <div className="LKRoundedButton__Loader">
                        <FontAwesomeIcon icon="spinner" size="2x" spin />
                    </div>
                )}
                {!hideContent && props.children}
            </button>
        </div>
    );
};

RoundedButton.propTypes = {
    color: PropTypes.string,
    transparent: PropTypes.bool,
    buttonCls: PropTypes.string,
    hideContent: PropTypes.bool,
    loading: PropTypes.bool,
};

export default RoundedButton;
