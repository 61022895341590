import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './TabletButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TabletButton = ({ className, icon, children, theme = 'button', ...tails }) => {
    className = classNames(className, 'TabletButton', `TableButton_theme_${theme}`);
    return theme === 'link' ? (
        <Link {...tails} className={className}>
            <FontAwesomeIcon className="TabletButton__Icon" icon={icon} />
            <div className="TabletButton__Title">{children}</div>
        </Link>
    ) : (
        <button {...tails} className={className}>
            <FontAwesomeIcon className="TabletButton__Icon" icon={icon} />
            <div className="TabletButton__Title">{children}</div>
        </button>
    );
};

export default TabletButton;
