import React, { Component } from "react";
import { connect } from "react-redux";

import { showErrorAlert } from "ducks/Alert";
import { addPersonToDefaultWishlist, removePersonFromDefaultWishlist } from "api";
import { serviceResultCode, getError } from 'serviceErrors';

const getWishlistError = (code, payload) => {
    switch (code) {
        case serviceResultCode.NotFound:
            return payload ? `${payload}` : 'Не найден';
        case serviceResultCode.Forbidden:
            return payload ? `${payload}` : 'Доступ запрещен';
        case serviceResultCode.PersonAlreadyExists:
            return payload ? `${payload}` : 'Пользователь уже добавлен';
        default:
            return 'Произошла непредвиденная ошибка';
    }
};

const withFavorites = Comp => class FavoritesProvider extends Component {

    componentDidMount = async () => {
    };

    handleFavoriteChange = (personId, callback) => async (isFavorite) => {

        try {
            if (isFavorite) {
                await addPersonToDefaultWishlist(personId);
            } else {
                await removePersonFromDefaultWishlist(personId);
            }
            if(callback){
                callback(isFavorite);
            }
        } catch (error) {
            const reqError = getError(error, getWishlistError);
            this.props.showErrorAlert(reqError.message);

            if(callback){
                callback(!isFavorite);
            }
        }
    };

    render = () => (
        <Comp
            {...this.props}
            onChangeFavorite={this.handleFavoriteChange}
        />
    );
};



export default Comp => connect(null, { showErrorAlert })(withFavorites(Comp));
