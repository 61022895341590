import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { competenceModelsRoute } from 'routes';
import uuid from 'uuid/v4';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PageCardWrapper from 'components/common/PageCardWrapper';
import {
    getCompetenceModelById,
    createCompetenceModel,
    updateCompetenceModel,
    getCompetenceIndicatorsTree,
    canCreateModel,
} from 'api';
import { competenceModelCopyRoute } from 'routes';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import Button from 'components/uikit/Button';
import { Card, CardFooter } from 'components/common/Card';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ModalDialog from 'components/common/ModalDialog';
import './Models.scss';
import { getError, serviceResultCode, getValidationErrors } from 'serviceErrors';
import IndicatorsTree from './IndicatorsTree';
import CompetenceCard from './CompetenceCard';

const Model = (props) => {
    const { id, type, showPageLoader, hidePageLoader, showErrorAlert, showWarningAlert, push } =
        props;

    const isEditInit = !!props.isEdit ? props.isEdit : false;

    const [indicatorsTree, setIndicatorsTree] = useState([]);
    const [model, setModel] = useState({ competencies: [] });
    const [modelCopy, setModelCopy] = useState({ competencies: [] });
    const [isEdit, setEditMode] = useState(isEditInit);
    const [isSaving, setSaveState] = useState(false);
    const [selectedCompetenceId, setSelectedCompetenceId] = useState(null);
    const [selectedIndicators, setSelectedIndicators] = useState();
    const [allSelectedIndicators, setAllSelectedIndicators] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [indicatorsModalOpen, setIndicatorsModalOpen] = useState(false);
    const [userCanCreateModel, setUserCanCreateModel] = useState(false);

    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            try {
                showPageLoader();

                const blankModel = {
                    name: '',
                    competencies: [
                        {
                            id: uuid(),
                            name: '',
                            indicatorIds: [],
                        },
                    ],
                };

                const response = await getCompetenceIndicatorsTree();
                const updatedIndicators = response.data.map((x) => ({
                    value: x.id,
                    label: x.name,
                    children: x.competencies.map(({ id: value, name: label, indicators }) => ({
                        value,
                        label,
                        children: indicators.map(({ id: value, name: label }) => ({
                            value,
                            label,
                        })),
                    })),
                }));

                setIndicatorsTree(updatedIndicators);

                if (id) {
                    const model = await getCompetenceModelById(id);
                    const modelData = model.data;
                    modelData.competencies.sort((a, b) =>
                        a.position > b.position ? 1 : b.position > a.position ? -1 : 0,
                    );
                    const competencies = model.data.competencies.map((x) => ({
                        ...x,
                        id: type === 'edit' ? x.id : uuid(),
                    }));
                    const updatedModel = {
                        ...model.data,
                        id: type === 'edit' ? model.data.id : uuid(),
                        competencies,
                    };

                    setModel(updatedModel);
                    setModelCopy(updatedModel);

                    const canCreateModelResponse = await canCreateModel();
                    setUserCanCreateModel(canCreateModelResponse.data);
                } else {
                    const competencies = blankModel.competencies;
                    mounted && setModel({ ...blankModel, competencies });
                }
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };

        fetchData();

        type === 'new' && setEditMode(true);
        return () => {
            mounted = false;
        };
    }, [showPageLoader, hidePageLoader, showErrorAlert, id, type]);

    useEffect(() => {
        const fillIndicators = () => {
            const modelIndicators = [];
            model.competencies.map((x) => {
                x?.indicatorIds?.map((ind) => {
                    modelIndicators.push({ competenceId: x.id, indicator: getIndicatorById(ind) });
                });
            });
            setAllSelectedIndicators(modelIndicators);
        };

        fillIndicators();
        // eslint-disable-next-line
    }, [indicatorsTree, model.id]);

    const getTreeForCompetence = () => {
        const newTree = indicatorsTree
            .map((gr) => {
                return {
                    ...gr,
                    children: gr.children
                        .map((comp) => {
                            return {
                                ...comp,
                                children: comp.children.filter((ind) => {
                                    return !allSelectedIndicators.find((z) =>
                                        isSelectedIndicator(z, ind),
                                    );
                                }),
                            };
                        })
                        .filter((comp) => comp.children.length > 0),
                };
            })
            .filter((gr) => gr.children.length > 0);
        return newTree;
    };

    const isSelectedIndicator = (z, ind) => {
        return z.indicator.value === ind.value && z.competenceId !== selectedCompetenceId;
    };

    const handleModelNameChange = (e) => {
        setModel({ ...model, name: e.target.value });
    };

    const handleCompetenceNameChange = (e) => {
        const newCompetenciesArray = makeNewCompetenciesArray(e, 'name');
        setModel({ ...model, competencies: newCompetenciesArray });
    };

    const handleCompetenceDescriptionChange = (e) => {
        const newCompetenciesArray = makeNewCompetenciesArray(e, 'description');
        setModel({ ...model, competencies: newCompetenciesArray });
    };

    const makeNewCompetenciesArray = (e, key, id = null) => {
        const competenceId = !id ? e.target.getAttribute('competenceid') : id;
        let newCompetenciesArray = [...model.competencies];
        const competenceIndex = newCompetenciesArray.findIndex((x) => x.id === competenceId);
        const competence = newCompetenciesArray.find((x) => x.id === competenceId);
        competenceIndex !== -1 &&
            newCompetenciesArray.splice(competenceIndex, 1, {
                ...competence,
                [key]: !id ? e.target.value : e.value,
            });
        return newCompetenciesArray;
    };

    const onAddCompetence = () => {
        const competenceId = uuid();
        let newCompetenciesArray = [...model.competencies];
        newCompetenciesArray = [
            ...newCompetenciesArray,
            {
                code: null,
                description: '',
                id: competenceId,
                name: '',
                indicatorIds: [],
            },
        ];
        setModel({ ...model, competencies: newCompetenciesArray });
    };

    const onDeleteCompetence = (e) => {
        const competenceId = e.target.getAttribute('competenceid');
        deleteCompetence(competenceId);
    };

    const deleteCompetence = (competenceId) => {
        let newCompetenciesArray = [...model.competencies];
        const competenceIndex = newCompetenciesArray.findIndex((x) => x.id === competenceId);
        competenceIndex !== -1 && newCompetenciesArray.splice(competenceIndex, 1);
        setModel({ ...model, competencies: newCompetenciesArray });
        setAllSelectedIndicators(
            allSelectedIndicators.filter((x) => x.competenceId !== competenceId),
        );
    };

    const getIndicatorById = (id) => {
        let res = null;
        for (var i = 0; i < indicatorsTree.length; i++) {
            indicatorsTree[i].children.map((x) => {
                const indicator = x?.children.find((ind) => ind.value === id);
                if (indicator) {
                    res = { ...indicator, parent: x.value, groupId: indicatorsTree[i].value };
                    return res;
                }
            });
        }
        return res;
    };

    const getExpanded = () => {
        let expanded = [];
        allSelectedIndicators.forEach((ind) => {
            if (selectedCompetenceId === ind.competenceId) {
                expanded.push(ind.indicator.parent);
                expanded.push(ind.indicator.groupId);
            }
        });
        return expanded;
    };

    const setIndicators = () => {
        let newCompetenciesArray = [...model.competencies];
        const competenceIndex = newCompetenciesArray.findIndex(
            (x) => x.id === selectedCompetenceId,
        );
        const competence = newCompetenciesArray.find((x) => x.id === selectedCompetenceId);
        competenceIndex !== -1 &&
            newCompetenciesArray.splice(competenceIndex, 1, {
                ...competence,
                indicatorIds: selectedIndicators,
            });
        setModel({ ...model, competencies: newCompetenciesArray });
        setIndicatorsModalOpen(false);

        const modelIndicators = [];
        newCompetenciesArray.map((x) => {
            x?.indicatorIds?.map((ind) => {
                modelIndicators.push({ competenceId: x.id, indicator: getIndicatorById(ind) });
            });
        });
        setAllSelectedIndicators(modelIndicators);
    };

    const clearIndicatorsByCompetenceId = (id, indicatorId = null) => {
        const newCompetenciesArray = model.competencies.map((x) => {
            return {
                ...x,
                ...(x.indicatorIds && {
                    indicatorIds:
                        x.id === id
                            ? indicatorId
                                ? x.indicatorIds.filter((ind) => ind !== indicatorId)
                                : []
                            : [...x.indicatorIds],
                }),
            };
        });
        setModel({ ...model, competencies: newCompetenciesArray });

        const newAllSelectedIndicators = indicatorId
            ? allSelectedIndicators.filter((x) => x.indicator.value !== indicatorId)
            : allSelectedIndicators.filter((x) => x.competenceId !== id);
        setAllSelectedIndicators(newAllSelectedIndicators);
    };

    const onCancel = () => {
        setModel(modelCopy);
        type === 'new' ? toModelList() : setEditMode(false);
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!source || !destination || source.droppableId !== destination.droppableId) {
            return;
        }
        if (source.index === destination.index) {
            return;
        }

        const selectedFields = [...model.competencies];
        const movedField = selectedFields[source.index];

        selectedFields.splice(source.index, 1);
        selectedFields.splice(destination.index, 0, movedField);

        setModel({ ...model, competencies: selectedFields });
    };

    const onSubmit = () => {
        const isValid = validateModel();
        if (isValid.error) {
            showWarningAlert(isValid.error);
        } else {
            type === 'edit' ? saveModel() : setModalOpen(true);
        }
    };

    const isInValidCompetenciesNames = () => {
        const duplicate = competencies.filter((item, index, array) => {
            return array.map((mapItem) => mapItem['name']).indexOf(item['name']) !== index;
        });
        return duplicate.length > 0 ? true : false;
    };

    const isInvalidCompetencyName = (value) => {
        const duplicates = competencies.filter((x) => x.name === value);
        return duplicates.length > 1 ? true : false;
    };

    const { name, competencies } = model;

    const validateModel = () => {
        if (
            !name ||
            !name.trim() ||
            competencies.length === 0 ||
            competencies.find(
                (x) => !x.id || !x.name.trim() || !x.indicatorIds || x?.indicatorIds?.length === 0,
            )
        ) {
            return { error: 'Не все поля корректно заполнены' };
        }

        if (isInValidCompetenciesNames()) {
            return { error: `Проверьте название введенной компетенции` };
        }

        if (
            props.modelNames &&
            type !== 'edit' &&
            props.modelNames.find((x) => x === model.name.trim())
        ) {
            return { error: `Такое название модели уже существует (${model.name})` };
        }

        return { error: null };
    };

    const makeModelForUpdate = () => {
        return {
            name: name,
            competencies: competencies.map((x, index) => ({ ...x, id: uuid(), position: index })),
        };
    };

    const getModelRequestError = (code) => {
        switch (code) {
            case serviceResultCode.EntityAlreadyExists:
                return `Для текущей группы уже создана модель компетенций`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    const saveModel = async () => {
        try {
            setSaveState(true);
            showPageLoader();
            type === 'edit'
                ? await updateCompetenceModel(id, makeModelForUpdate())
                : await createCompetenceModel({
                      id: id,
                      name: name,
                      competencies: competencies.map((x, index) => ({ ...x, position: index })),
                  });
            toModelList();
        } catch (error) {
            const validationErrors = getValidationErrors(error);
            if (Array.isArray(validationErrors) && validationErrors.length > 0) {
                validationErrors.map((item) => {
                    return showWarningAlert(item.message);
                });
            } else {
                const reqError = getError(error, getModelRequestError);
                showErrorAlert(reqError.message);
            }
        } finally {
            hidePageLoader();
            setSaveState(false);
        }
    };

    const copyModel = (modelId) => {
        push({
            pathname: competenceModelCopyRoute.buildUrl({ id: modelId }, props.location.search),
            state: { modelNames: props.modelNames, isEdit: true },
        });
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const openIndicatorsModal = (competenceId) => {
        setSelectedCompetenceId(competenceId);
        setIndicatorsModalOpen(true);
        const selectedIndicators = model.competencies.find(
            (x) => x.id === competenceId,
        )?.indicatorIds;
        setSelectedIndicators(selectedIndicators);
    };

    const closeIndicatorsModal = () => {
        setIndicatorsModalOpen(false);
        setSelectedCompetenceId(null);
        setSelectedIndicators();
    };

    const toModelList = () => {
        push({
            pathname: competenceModelsRoute.url,
            search: props.location.search,
        });
    };

    const isModelNotEditable = () => {
        if (model.isEditable !== undefined) {
            return !model.isEditable;
        }

        return false;
    };

    const isModelNotCopiable = (modelId) => {
        return modelId ? false : true;
    };

    const editButtons = [
        {
            id: 'btnEditAdd',
            title: 'Добавить',
            function: () => onAddCompetence(),
            properties: { addLink: true },
        },
        { id: 'btnEditSave', title: 'Сохранить', function: () => onSubmit() },
        {
            id: 'btnEditCancel',
            title: 'Отменить',
            function: () => onCancel(),
            properties: { closeLink: true },
        },
    ];

    const viewButtons = [
        { id: 'btnViewBack', title: 'Вернуться', function: () => toModelList() },
        {
            id: 'btnViewEdit',
            title: 'Редактировать',
            function: () => setEditMode(true),
            properties: { disabled: isModelNotEditable() },
        },
        {
            id: 'btnViewCopy',
            title: 'Копировать',
            function: () => copyModel(model.id),
            properties: { disabled: isModelNotCopiable(model.id) },
            hide: !userCanCreateModel,
        },
    ];

    const renderHeaderBlock = () => {
        return (
            <Grid fluid>
                <Row>
                    <Col xs={12}>
                        {isEdit ? (
                            <Field filled={model.name} required>
                                <Label>Название модели</Label>
                                <Input
                                    value={model.name || ''}
                                    onChange={handleModelNameChange}
                                    name="modelName"
                                />
                            </Field>
                        ) : (
                            <Field filled={model.name}>
                                <Label>Название модели</Label>
                                <p>{model.name}</p>
                            </Field>
                        )}
                    </Col>
                </Row>
            </Grid>
        );
    };

    const renderCompetencies = (draggingOverWith) => {
        return model.competencies.map((x, index) => (
            <Draggable isDragDisabled={!isEdit} draggableId={x.id} index={index} key={x.id}>
                {(provided, snapshot) => (
                    <div
                        key={x.id}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        <Grid fluid>
                            <Row>
                                <Col xs={12}>
                                    <Card
                                        isDraggable={isEdit}
                                        fade={snapshot.isDragging && !draggingOverWith}
                                    >
                                        <CompetenceCard
                                            competence={x}
                                            getIndicatorById={getIndicatorById}
                                            handleCompetenceNameChange={handleCompetenceNameChange}
                                            handleCompetenceDescriptionChange={
                                                handleCompetenceDescriptionChange
                                            }
                                            handleIndicators={openIndicatorsModal}
                                            isEditMode={isEdit}
                                            isInvalidCompetencyName={isInvalidCompetencyName}
                                            clearIndicators={clearIndicatorsByCompetenceId}
                                        />
                                        <CardFooter
                                            hasButtons
                                            actionButtons={
                                                competencies.length > 1 &&
                                                isEdit && (
                                                    <Button
                                                        competenceid={x.id}
                                                        closeLink
                                                        onClick={onDeleteCompetence}
                                                        size="sm"
                                                    >
                                                        Удалить
                                                    </Button>
                                                )
                                            }
                                        ></CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Grid>
                    </div>
                )}
            </Draggable>
        ));
    };

    const buttonsRender = (buttonsArray) => {
        return buttonsArray
            .filter((x) => !x.hide)
            .map((item) => {
                return (
                    <div className="ButtonBlock__Item" key={item.id}>
                        <Button size="sm" {...item.properties} onClick={() => item.function.call()}>
                            {item.title}
                        </Button>
                    </div>
                );
            });
    };
    const renderPageCardWRapperFooter = () => {
        if (isEdit) {
            return (
                <>
                    <div className="ButtonBlock">{buttonsRender(editButtons)}</div>
                </>
            );
        } else {
            return (
                <>
                    <div className="ButtonBlock">{buttonsRender(viewButtons)}</div>
                </>
            );
        }
    };

    return (
        <>
            <Grid fluid>
                <Row className="Model__Container">
                    <Col xs={0} xl={2} />
                    <Col xs={12} xl={8}>
                        <PageCardWrapper
                            header={
                                type === 'edit'
                                    ? `Редактирование модели компетенций`
                                    : `Новая модель компетенций`
                            }
                            subHeader={renderHeaderBlock()}
                            footer={renderPageCardWRapperFooter()}
                        >
                            <div>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId={id || uuid()}>
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {renderCompetencies(snapshot.draggingOverWith)}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </PageCardWrapper>
                    </Col>
                    <Col xs={0} xl={2} />
                </Row>
            </Grid>
            <ModalDialog
                size="lg"
                modalHeader="Поведенческие индикаторы"
                modalOpen={indicatorsModalOpen}
                onClick={setIndicators}
                onCloseModal={closeIndicatorsModal}
                processing={isSaving}
                btnOktext="Применить"
                btnCanceltext="Отмена"
            >
                <IndicatorsTree
                    indicatorsTree={getTreeForCompetence()}
                    expanded={getExpanded()}
                    setChecked={setSelectedIndicators}
                    checked={selectedIndicators}
                />
            </ModalDialog>

            <ModalDialog
                modalHeader={'Сохранение модели компетенции'}
                modalOpen={modalOpen}
                onClick={saveModel}
                onCloseModal={closeModal}
                processing={isSaving}
                btnOktext={'Сохранить'}
                btnCanceltext={'Отмена'}
            >
                Внимание! Сохраненная модель компетенций будет доступна для просмотра всем
                пользователям системы.
            </ModalDialog>
        </>
    );
};

const actions = { showErrorAlert, showPageLoader, hidePageLoader, showWarningAlert, push };

export default connect(null, actions)(Model);
