import React from 'react';
import { isNull } from 'utils';
import { DataCard } from '../../common/DataCard';
import Sticker from 'react-stickyfill';
import LeftMenu from '../../common/LeftMenu';
import Button from 'components/uikit/Button';
import PropTypes from 'prop-types';
import './StepWizard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

class StepWizard extends React.PureComponent {
    initWizard = () => {
        const wizard = {
            initialized: false,
            activeStep: null,
            totalSteps: 0,
            menuItems: [],
            steps: [],
        };

        if (!this.props.steps) {
            return wizard;
        }

        this.props.steps.forEach((child, i) => {
            const stepId = child.id || `step${i + 1}`;
            const title = child.title || `Шаг ${i + 1}`;
            const stepTitle = child.stepTitle || child.title;
            const icon = child.icon || `${i + 1}`;
            const titleAddition = child.titleAddition || '';
            const description = child.description || '';

            wizard.menuItems.push({
                id: stepId,
                title: title,
                stepTitle: stepTitle,
                textIcon: icon,
                titleAddition: titleAddition,
                description: description,
            });

            wizard.steps.push(stepId);
        });

        wizard.totalSteps = this.props.steps.length;

        if (isNull(wizard.activeStep) && wizard.steps[0]) {
            wizard.activeStep = wizard.steps[0];
        }
        return wizard;
    };

    componentDidMount() {
        this.performInit();
    }

    componentDidUpdate() {
        this.performInit();
    }

    performInit = () => {
        if (!this.props.wizard || !this.props.wizard.initialized) {
            this.props.initialize(this.initWizard());
        }
    };

    onChangePage = item => {
        if (this.props.onChangePage) {
            this.props.onChangePage(item.id);
        }
    };

    onCancel = e => {
        e.preventDefault();

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    onSave = e => {
        if (this.props.onSave) {
            this.props.onSave();
        } else {
            this.onNextPage(e);
        }
    };

    onNextPage = e => {
        e.preventDefault();

        if (this.props.onNextPage) {
            this.props.onNextPage(
                this.getPageId(this.getIndex() + 1),
                this.getPageId(this.getIndex()),
            );
        }
    };

    onPreviousPage = e => {
        e.preventDefault();

        if (this.props.onPreviousPage) {
            this.props.onPreviousPage(
                this.getPageId(this.getIndex() - 1),
                this.getPageId(this.getIndex()),
            );
        }
    };

    getIndex = () => {
        const { steps, totalSteps } = this.props.wizard;
        const stepId = this.getStepId();

        let currentPageIndex = stepId && steps ? steps.indexOf(stepId) : 0;
        if (currentPageIndex >= totalSteps - 1) {
            currentPageIndex = totalSteps - 1;
        }

        return currentPageIndex;
    };

    getPageId = currentPageIndex => {
        const { steps, totalSteps } = this.props.wizard;

        if (currentPageIndex >= totalSteps - 1) {
            currentPageIndex = totalSteps - 1;
        }

        if (currentPageIndex < 0) {
            currentPageIndex = 0;
        }

        if (!steps || !steps[currentPageIndex]) return null;

        return steps[currentPageIndex];
    };

    getStepId = () => this.props.stepId || this.props.wizard.activeStep;

    renderHeader = stepTitle => {
        const { menuItems } = this.props.wizard;
        const stepId = this.getStepId();

        return this.props.showStepTextInHeader ? (
            <div className="Main-Header">
                <div className="Step-Number">{menuItems.find(x => x.id === stepId).textIcon}</div>
                <div className="Main-Text">{stepTitle}</div>
            </div>
        ) : (
            <div className=".Main-Header">
                <div className="Main-Text">{stepTitle}</div>
            </div>
        );
    };
    onValid = (next, valid) => e => {
        const { onValid } = this.props;

        if (!onValid || !onValid(valid)) {
            return;
        }

        next(e);
    };

    render() {
        if (!this.props.steps || !this.props.wizard) {
            return null;
        }

        const { menuItems, steps } = this.props.wizard;

        const stepId = this.getStepId();
        const selectedStep =
            this.props.steps.find(step => step.id && step.id === stepId) || this.props.steps[0];
        const selectedMenu = menuItems.find(item => item.id && item.id === stepId);

        if (!selectedStep) {
            return null;
        }

        const stepTitle = selectedMenu && selectedMenu.title ? selectedMenu.stepTitle : 'Шаг';
        const previousText = selectedStep.previousText || 'Назад';
        const nextText = selectedStep.nextText || 'Далее';
        const isFirstStep = stepId && steps && steps.indexOf(stepId) === 0;
        const isLastStep = stepId && steps && steps.indexOf(stepId) === steps.length - 1;
        const isValid = selectedStep.isValid ? selectedStep.isValid() : true;
        const titleAddition = selectedStep.titleAddition;
        const description = selectedStep.description;
        const content = selectedStep.content;

        return (
            <>
                <div className="Page__MenuColumn StepWizard__LeftBlock">
                    <Sticker>
                        <LeftMenu
                            items={menuItems}
                            selected={stepId}
                            onChangePage={this.onChangePage}
                        >
                            {this.props.leftMenuPrefix}
                            <button
                                className="StepWizard__CancelImport LinkButton-close-icon"
                                onClick={this.onCancel}
                                disabled={this.props.disableCancel}
                            >
                                <FontAwesomeIcon
                                    className="StepWizard__CancelImportIcon"
                                    size="2x"
                                    icon={faTimesCircle}
                                />
                                <span className="StepWizard__CancelImport--text">
                                    {this.props.disableTitle}
                                </span>
                            </button>
                            {this.props.leftMenuSuffix}
                        </LeftMenu>
                    </Sticker>
                </div>
                <div className="Page__ContentColumn StepWizard__RightBlock">
                    <DataCard className="StepWizard_Page">
                        <div className="StepWizard__Title">
                            {this.renderHeader(stepTitle, titleAddition)}
                            <div>{titleAddition}</div>
                            <div className="StepWizard__Title__Description">{description}</div>
                        </div>
                        <Step>{typeof content === 'function' ? content() : content}</Step>
                    </DataCard>
                    <DataCard className="StepWizard_ActionButtons">
                        {!isFirstStep ? (
                            <Button color="default" size="sm" onClick={this.onPreviousPage}>
                                {previousText}
                            </Button>
                        ) : null}
                        {!isLastStep ? (
                            <Button size="sm" disabled={!isValid} onClick={this.onValid(this.onNextPage, isValid)}>
                                {nextText}
                            </Button>
                        ) : (
                            <Button size="sm" disabled={!isValid} onClick={this.onValid(this.onSave, isValid)}>
                                {nextText}
                            </Button>
                        )}
                    </DataCard>
                </div>
            </>
        );
    }
}

StepWizard.propTypes = {
    stepId: PropTypes.string,
    steps: PropTypes.array.isRequired,
    disableTitle: PropTypes.string,
    disableCancel: PropTypes.bool,
    leftMenuSuffix: PropTypes.node,
    leftMenuPrefix: PropTypes.node,
    onNextPage: PropTypes.func,
    onPreviousPage: PropTypes.func,
    onCancel: PropTypes.func,
    onChangePage: PropTypes.func,
};

StepWizard.defaultProps = {
    disableCancel: false,
    leftMenuSuffix: null,
    leftMenuPrefix: null,
};

const Step = ({ children }) => <div className="StepWizard__Step">{children}</div>;

export default StepWizard;
