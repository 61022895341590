import moment from 'moment';

export const validPlanDate = ({ end_year, end_month }) => {
    if (!end_year || (!end_month && end_month !== 0)) {
        return false;
    }

    const endOfMonth = moment(new Date(end_year, end_month))
        .endOf('month');

    return moment().isSameOrBefore(endOfMonth);
};

export const buildEndDate = ({ end_year, end_month }) => {
    if (!end_year || (!end_month && end_month !== 0)) {
        return null;
    }

    return moment(new Date(end_year, end_month))
        .endOf('month')
        .toISOString();
};
