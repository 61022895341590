import React, { useState, useEffect } from 'react';
import { fullTextSearchSelect, searchAssignUsers } from 'api';
import { useCallback } from 'react';
import { usePrevious } from 'components/hooks';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/common/Loader';
import RenderPerson from '../Evaluate/RenderPerson';
import Switcher from 'components/Lk/Uikit/Switcher';
import SearchLine from 'components/Lk/Common/SearchLine';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import classnames from 'classnames';
import WithKeyboardHandler from 'components/Lk/Hooks/WithKeyboardHandler';

const SEARCH_USER = 'user';
const SEARCH_PERSON = 'person';

const Search = props => {
    const {
        tempBasket,
        personClick,
        dataTest,
        isFocused,
        onSubmit,
        onCancel,
        isPersonsRequestsLoading,
        setSerchFocused,
    } = props;

    const [searchTerm, setSearchTerm] = useState('');
    const [paging, setPaging] = useState({ pageNum: 1, pageSize: 10 });
    const [searchLoading, setSearchLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [searchResult, setSearchResult] = useState([]);
    const [searchType, setSearchType] = useState(SEARCH_PERSON);

    const prevSearchTerm = usePrevious(searchTerm);

    useEffect(() => {
        if (searchType === SEARCH_USER) {
            onSearch({});
        }
        // тут не нужен onSearch в зависимостях
        // eslint-disable-next-line
    }, [searchType]);

    useEffect(() => {
        setSerchFocused(isFocused);
    }, [setSerchFocused, isFocused]);

    const onSearch = async ({ pageNum = 1, ...tail }, isClearable) => {
        if (
            (searchType === SEARCH_PERSON && searchTerm.trim() === '') ||
            searchLoading ||
            (pageCount !== 0 && pageCount < pageNum)
        ) {
            return;
        }

        const newTerm = prevSearchTerm !== searchTerm || tail.key === 'Enter' || isClearable;
        if (newTerm) setSearchResult([]);

        setPaging({ pageNum: pageNum, pageSize: 10 });

        const result = await searchData(pageNum, searchTerm);
        const resultPageCount = result.data.meta.pageCount;

        const previousResult = newTerm ? [] : searchResult;
        setSearchResult([...previousResult, ...result.data.payload]);

        if (pageCount !== resultPageCount) setPageCount(resultPageCount);
        setHasMore(pageCount > pageNum);
        setSearchLoading(false);
    };

    const onChangeSearchTerm = e => {
        setSearchTerm(e.target.value);
    };

    const onSearchFieldKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setSearchResult([]);
            onSearch(e, true);
        }
    };

    const searchData = useCallback(
        (pageNum, searchTerm) => {
            setSearchLoading(true);
            return searchType === SEARCH_USER
                ? searchAssignUsers({
                      searchTerm,
                      paging: { pageNum: pageNum, pageSize: 10 },
                  })
                : fullTextSearchSelect({
                      filter: {
                          fields: [
                              {
                                  category: 'Persons',
                                  field: 'FullName',
                                  operation: 'contains',
                                  value: searchTerm,
                              },
                          ],
                      },
                      paging: { pageNum: pageNum, pageSize: 10 },
                  });
        },
        [searchType],
    );

    const handleLoadMore = () => {
        setHasMore(false);
        onSearch({ pageNum: paging.pageNum + 1 });
    };

    const onChangeSearchType = async () => {
        setSearchResult([]);
        await setSearchType(searchType === SEARCH_PERSON ? SEARCH_USER : SEARCH_PERSON);
    };

    return (
        <div className={classnames("SearchIssue", {"SearchIssue--scroll": isFocused})} id="SearchIssue">
            <SearchLine
                searchTerm={searchTerm}
                onChange={onChangeSearchTerm}
                onKeyPress={onSearchFieldKeyPress}
                onSearch={onSearch}
                inputDataTest={`${dataTest}_EditSearchInput--input`}
                searchButtonDatatTest={`${dataTest}_MakeSearch--button`}
            />
            <div className="LKSearchTypeSwitch" onClick={onChangeSearchType}>
                <Switcher checked={searchType === SEARCH_USER} disabled={searchLoading} />
                <div className="LKSearchTypeSwitch__Title">
                    Эксперты по&nbsp;управлению талантами в&nbsp;моем регионе
                </div>
            </div>

            <div className={classnames("SearchIssue__List", {"SearchIssue__List--no-scroll": isFocused})}>
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={hasMore}
                    initialLoad={false}
                    useWindow={false}
                >
                    <RenderPerson
                        data={searchResult}
                        basket={tempBasket}
                        {...personClick}
                        userId
                        isUser={true}
                        dataTest={`${dataTest}_Search`}
                    />
                    {searchLoading && <Loader />}
                    <StickyFooter unstick={isFocused}>
                        <div className="SelectImplementer__Footer">
                            <RoundedButton
                                modifier="withoutPaddingBottom"
                                onClick={onSubmit}
                                disabled={
                                    tempBasket?.userId?.length === 0 || isPersonsRequestsLoading()
                                }
                                data-test="SelectImplementer_Select--button"
                            >
                                Применить
                            </RoundedButton>
                            <RoundedButton
                                color="transparent"
                                onClick={onCancel}
                                data-test="SelectImplementer_Cancel--button"
                            >
                                Отменить
                            </RoundedButton>
                        </div>
                    </StickyFooter>
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default WithKeyboardHandler(Search, 'SearchIssue');
