import React, { useState, useMemo, useEffect } from 'react';
import photoStub from 'assets/images/photo-stub.png';
import './Team.scss';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchTeams } from 'ducks/Teams';
import { getPersonPhotoUri, photoSizes } from 'api';
import Image from 'components/common/Image';
import { person } from 'routes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from 'components/common/Loader';

const STEP_PERSON = 6;
const STEP_TEAM = 4;

export const Team = props => {
    const {
        leading,
        fetchTeams,
        settings,
        personId,
        isEdit,
        onEditData,
        loading,
        setTitle,
        setEditState,
        size,
    } = props;
    const { teamId } = settings;
    const [page, setPage] = useState(0);
    const [step, setStep] = useState(STEP_PERSON);

    useEffect(() => {
        fetchTeams(personId);
    }, [personId, fetchTeams]);

    useEffect(() => {
        setPage(0);
        setStep(isEdit ? STEP_TEAM : STEP_PERSON);
    }, [isEdit]);

    const onSetTeam = newTeamId => () => {
        onEditData({ ...settings, teamId: newTeamId });
        setEditState(false);
    };

    const renderPhotoBlock = id => {
        const photoUri = getPersonPhotoUri(photoSizes.small, id);
        return (
            <Link to={person.buildUrl({ id })}>
                <Image photoUri={photoUri} photoStub={photoStub} showLoader />
            </Link>
        );
    };

    const currentTeam = useMemo(() => {
        if (!teamId || !leading) {
            return null;
        }
        const [team] = leading.filter(t => t.id === teamId);
        return team || null;
    }, [leading, teamId]);

    useEffect(() => {
        setTitle(currentTeam && currentTeam.name);
    }, [currentTeam, setTitle]);

    const slicedPerson = useMemo(
        () =>
            (currentTeam && currentTeam.followerPersons.slice(page * step, page * step + step)) ||
            [],
        [page, step, currentTeam],
    );

    const slicedTeam = useMemo(() => leading.slice(page * step, page * step + step), [
        page,
        step,
        leading,
    ]);

    const hasNext =
        (isEdit ? leading : (currentTeam && currentTeam.followerPersons) || []).length >
        page * step + step;
    const hasPrev = page > 0;

    const onPageClick = inc => () => {
        setPage(page + inc);
    };

    const renderList = (
        <div className={classnames('Avatar', `Avatar--${size}`)}>
            {slicedPerson.map(t => (
                <div key={t.id} className="Avatar__Item">
                    <div className="Avatar__Image">{renderPhotoBlock(t.id)}</div>
                </div>
            ))}
        </div>
    );

    const renderEdit = (
        <div className="FollowingList">
            <span>Выберите команду для отображения в виджете:</span>
            {slicedTeam.map(team => (
                <div
                    key={team.id}
                    className={classnames('FollowingList__Item', {
                        'FollowingList__Item--active': team.id === teamId,
                    })}
                    onClick={onSetTeam(team.id)}
                >
                    {team.name || 'Команда'}
                </div>
            ))}
        </div>
    );

    if (loading) {
        return (
            <div className="TeamLoader">
                <Loader className="TeamLoader__Image" />
            </div>
        );
    }

    return (
        <div className="TeamList">
            {hasPrev && (
                <div className="TeamList__Chevron TeamList__Chevron--left">
                    <FontAwesomeIcon icon="chevron-left" onClick={onPageClick(-1)} />
                </div>
            )}
            {isEdit || !currentTeam ? renderEdit : renderList}
            {hasNext && (
                <div className="TeamList__Chevron">
                    <FontAwesomeIcon icon="chevron-right" onClick={onPageClick(1)} />
                </div>
            )}
        </div>
    );
};

Team.propTypes = {
    leading: PropTypes.array,
    loading: PropTypes.bool,
    fetchTeam: PropTypes.func,
    settings: PropTypes.object,
    personId: PropTypes.number,
    size: PropTypes.string,
    isEdit: PropTypes.bool,
    onEditData: PropTypes.func,
};

const PureTeam = React.memo(Team);

const mapStateToProps = ({ teams, auth }) => ({
    personId: auth.user.personId,
    leading: teams.leading,
    loading: teams.loading,
});

export default connect(mapStateToProps, { fetchTeams })(PureTeam);
