import './DataGrid.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import {} from 'react-table';
import NoDataComponent from './NoDataComponent';
import LoadingComponent from './LoadingComponent';
import Pagination from './Pagination';
import classnames from 'classnames';

function getNoDataProps({ loading }) {
    return { loading };
}

const DataGrid = props => {
    
    const { className='', showHeader = true, pageSize = 10, changePageAmount, ...rest } = props;
    const resultClass = classnames(className, 'data-grid -highlight', {withPagination:props.showPagination})

    return (
        <ReactTable
            PaginationComponent={props.showPagination ? Pagination : () => {}}
            previousText=""
            nextText=""
            loadingText="Загрузка..."
            noDataText="Нет данных"
            pageText="Страница"
            ofText="из"
            rowsText="строк"
            pageJumpText="перейти к странице"
            rowsSelectorText="строк на странице"
            minRows={1}
            className={resultClass}
            getNoDataProps={getNoDataProps}
            NoDataComponent={NoDataComponent}
            LoadingComponent={LoadingComponent}
            TheadComponent={showHeader === false ? () => null : undefined}
            pageSize={pageSize}
            changePageAmount={changePageAmount}
            {...rest}
        />
    );
};

DataGrid.propTypes = {
    className: PropTypes.string,
    showHeader: PropTypes.bool,
    changePageAmount: PropTypes.func,
};

DataGrid.defaultProps = {
    className: '',
    showHeader: true,
    changePageAmount: () => null,
};

export default DataGrid;
