import React from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import { endOf, getMonths } from 'utils';

const DateStep = props => {
    const { changeHandler, currentPlan } = props;

    const onChangeDate = date => {
        if (endOf(date) && endOf(date)?.isSame(endOf(currentPlan.end)) === false) {
            changeHandler('end', date && endOf(date));
        }
    }

    const monthFormat = value =>
        value && typeof value.getMonth == 'function' && getMonths()[value.getMonth()].label;

    const datePickerProps = {
        dateFormat: 'LLLL yyyy',
        mobileFormat: 'MMMM YYYY',
        type: 'date',
        minDate: new Date().setMonth(new Date().getMonth() - 1),
        minDateMobile: new Date(),
        onChange: onChangeDate,
        dateConfig: {
            month: {
                format: monthFormat,
                step: 1,
            },
            year: {
                format: 'YYYY',
                step: 1,
            },
        },
        showMonthYearPicker: true,
        locale: 'ru',
    };

    return (
        <>
            <div className="LKProgressPlanHeader">
                <span className="LKProgressPlanHeader__Title">6. Дата завершения</span>
                <span className="LKProgressPlanHeader__Description">
                    Рекомендуемый срок реализации цели – 6 месяцев или 1 год.
                </span>
            </div>
            <InfoField
                noIcon
                inputProps={datePickerProps}
                required
                isEdit
                value={(currentPlan.end && endOf(currentPlan.end)) || null}
            />
        </>
    );
};

export default DateStep;
