import React from "react";
import DropzoneDecorator from "components/common/DropzoneDecorator";
import { getFileExtension, getFileSize } from 'utils';

function Container(props) {
    const { getRootProps, children, className } = props;

    return (
        <div {...getRootProps()} className={className}>
            {children}
        </div>
    );
}


function FileList(props) {
    const { children } = props;

    if (!children || !Array.isArray(children) || !children.length) {
        return null;
    }

    return (
        <div className="kr_like-table kr_like-table_files">
            {children}
        </div>
    );
}


function FileItem(props) {
    const {
        file,
        remove,
        getFileUrl
    } = props;

    const {
        uploadComplete = true,
        error,
    } = file;

    const uploadSuccess = uploadComplete && !error;

    let href = "#";
    if (uploadSuccess) {
        href = `${getFileUrl(file.id)}`;
    }

    return (
        <div className="kr_tr">
            <div className="kr_td">
                <a href={href}>
                    <span className="kr_files_doc">
                        {getFileExtension(file.name)}
                    </span>
                    {file.name}
                </a>
            </div>
            <div className="kr_td">
                {getFileSize(file.size)}
            </div>
            <div className="kr_td">
                <div className="kr_btn_remove" onClick={e => remove(e, file)} />
            </div>
        </div>
    );
}

export default DropzoneDecorator(Container, FileList, FileItem);
