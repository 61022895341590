import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    getAllWishlists,
    setWishlistPersonsUpdated,
    getWishlistsByIds,
    setWishlistsMode,
    setWishlistCommentsUpdated,
} from 'ducks/Wishlists';
import ManageWishlistsMenu from './ManageWishlistsMenu';
import MassEditMenu from './Wishlists/MassEditMenu';
import ManageWishlistsFilters from './ManageWishlistsFilters';
import Wishlists from './Wishlists/Wishlists';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import queryString from 'query-string';
import { lkSearch } from 'routes';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import CloseButton from 'components/Lk/Uikit/Buttons/CloseButton';
import './ManageWishlists.scss';

class ManageWishlists extends Component {
    state = {
        loading: false,
        paging: { pageNum: 1, pageSize: 15 },
        wishLists: [],
        editWishlistsIds: [],
        hasMore: true,
        filter: {},
        sorting: {},
        isModalOpen: false,
        renameWishlists: false,
        isMassEditDisabled: false,
        clearList: true,
    };

    timer = null;

    handleStateChange = state => {
        this.setState(state);
    };

    handleModeChange = mode => {
        const { selectedWishlists, setWishlistsMode } = this.props;
        setWishlistsMode(mode);
        this.setState({ editWishlistsIds: selectedWishlists });
    };

    handleLoadMore = () => {
        this.loadLists(false);
        this.setState({ hasMore: false });
    };

    loadLists = (clearList = true) => {
        const { mode, getAllWishlists, getWishlistsByIds, dynamicSearchTerm } = this.props;
        const { paging, filter, sorting, editWishlistsIds } = this.state;
        this.setState({
            loading: true,
            clearList,
            ...(clearList ? { paging: { pageNum: 1, pageSize: 15 } } : { paging }),
        });
        mode === 'view'
            ? getAllWishlists({
                  ...(clearList ? { paging: { pageNum: 1, pageSize: 15 } } : { paging }),
                  filter,
                  sorting,
                  idList: true,
                  searchTerm: dynamicSearchTerm,
              })
            : getWishlistsByIds({ paging, filter, sorting, ids: editWishlistsIds });
    };

    exitEditMode = () => {
        const { push, zipSearch } = this.props;
        push({ pathname: lkSearch.url, search: queryString.stringify({ q: zipSearch }) });
        this.handleStateChange({ wishLists: [], loading: true });
        this.handleModeChange('view');
        this.loadLists();
    };

    componentDidMount() {
        this.loadLists();
    }

    componentDidUpdate(prevProps, prevState) {
        const { list, mode, dynamicSearchTerm } = this.props;
        const { wishLists, paging, clearList } = this.state;

        if (list && prevProps.list !== list) {
            let newWishlists =
                mode === 'view' && !clearList
                    ? [...wishLists, ...list.wishLists]
                    : [...list.wishLists];
            this.setState({
                loading: false,
                wishLists: newWishlists,
                paging: {
                    ...paging,
                    pageNum:
                        list.meta.pageCount > paging.pageNum ? paging.pageNum + 1 : paging.pageNum,
                },
                hasMore: list.meta.pageCount > paging.pageNum,
            });
        }

        if (this.props.isWishlistPersonsUpdated) {
            this.setState(
                { paging: { pageNum: 1, pageSize: 15 }, wishLists: [], loading: false },
                () => this.loadLists(),
            );
            this.props.setWishlistPersonsUpdated({ isWishlistPersonsUpdated: false });
        }

        if (this.props.isWishlistCommentsUpdated) {
            this.setState(
                { paging: { pageNum: 1, pageSize: 15 }, wishLists: [], loading: false },
                () => this.loadLists(),
            );
            this.props.setWishlistCommentsUpdated({ isWishlistCommentsUpdated: false });
        }

        if (
            prevState.filter !== this.state.filter ||
            prevState.sorting !== this.state.sorting ||
            prevProps.mode !== this.props.mode
        ) {
            this.loadLists();
        }

        if (prevProps.dynamicSearchTerm !== dynamicSearchTerm) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.loadLists();
            }, 200);
        }
    }

    componentWillUnmount() {
        this.props.setWishlistsMode('view');
    }

    getHeader = (closeAction = null) => {
        const { renameWishlists } = this.state;
        const { mode } = this.props;

        if (mode === 'edit') {
            return (
                <div className="LKManageWishlistsHeader">
                    <div className="LKManageWishlistsHeader__Text">
                        {renameWishlists ? 'Переименование подборок' : 'Редактирование подборок'}
                    </div>
                    {closeAction && (
                        <div className="LKManageWishlistsHeader__Close">
                            <CloseButton onClick={closeAction} />
                        </div>
                    )}
                </div>
            );
        }
        return '';
    };

    render() {
        const { loading, wishLists, renameWishlists, isMassEditDisabled } = this.state;
        const {
            onSearch,
            setMainFilter,
            zipSearch,
            selectedWishlists,
            setSelectedWishlists,
            mode,
        } = this.props;

        return (
            <>
                {this.getHeader()}
                {mode === 'view' && (
                    <ManageWishlistsMenu
                        selectedWishlists={selectedWishlists}
                        setSelectedWishlists={setSelectedWishlists}
                        onSearch={onSearch}
                        setMainFilter={setMainFilter}
                        wishLists={wishLists}
                        readOnlyIds={wishLists?.filter(x=>x.isDefault).map(x=>x.id)}
                        setMode={this.handleModeChange}
                    />
                )}

                {mode === 'edit' && !renameWishlists && wishLists?.length !== 0 && (
                    <MassEditMenu
                        selectedWishlists={selectedWishlists}
                        setSelectedWishlists={setSelectedWishlists}
                        wishLists={wishLists}
                        setMode={this.handleModeChange}
                        handleStateChange={this.handleStateChange}
                        isMassEditDisabled={isMassEditDisabled}
                        
                        loadLists={this.loadLists}
                    />
                )}

                <ManageWishlistsFilters
                    handleStateChange={this.handleStateChange}
                    showAll={!renameWishlists}
                    showSorters={!renameWishlists && wishLists?.length !== 0}
                />

                <Wishlists
                    selectedWishlists={selectedWishlists}
                    setSelectedWishlists={setSelectedWishlists}
                    loading={loading}
                    wishLists={wishLists}
                    handleLoadMore={this.handleLoadMore}
                    hasMore={this.state.hasMore}
                    mode={mode}
                    renameWishlists={renameWishlists}
                    handleStateChange={this.handleStateChange}
                    loadLists={this.loadLists}
                    zipSearch={zipSearch}
                    updated={this.props.updated}
                />
                {mode === 'edit' && !renameWishlists && (
                    <StickyFooter>
                        <RoundedButton onClick={this.exitEditMode}>
                            Закрыть редактирование
                        </RoundedButton>
                    </StickyFooter>
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        list: state.wishlists.allWishLists,
        isWishlistPersonsUpdated: state.wishlists.isWishlistPersonsUpdated,
        isWishlistCommentsUpdated: state.wishlists.isWishlistCommentsUpdated,
        mode: state.wishlists.mode,
    };
};

const actions = {
    push,
    getAllWishlists,
    setWishlistPersonsUpdated,
    getWishlistsByIds,
    setWishlistsMode,
    setWishlistCommentsUpdated,
};

export default connect(
    mapStateToProps,
    actions,
)(ManageWishlists);
