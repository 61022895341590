import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { serviceResultCode, getError } from 'serviceErrors';
import { getAdvicesForTag, getAdviceFileLink } from 'api';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Loader from 'components/common/Loader';

import './Advices.scss';

const Advices = (props) => {
    const tagId = props.match.params.id;
    const { activeCard, setActiveCard, showPageLoader, hidePageLoader, showErrorAlert } = props;

    setActiveCard(ACTIVE_DETAIL_CARD);
    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;

    const [advices, setAdvices] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            try {
                setLoaded(false);
                showPageLoader();

                const advicesData = await getAdvicesForTag(tagId);

                if (mounted && advicesData && advicesData.data) {
                    setAdvices(advicesData.data);
                    setLoaded(true);
                }
            } catch (error) {
                const reqError = getError(error, getAdvicesError);
                showErrorAlert(reqError.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchData();

        return () => {
            mounted = false;
        };
    }, [showPageLoader, hidePageLoader, showErrorAlert, tagId]);

    const getAdvicesError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `${payload}`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    return (
        <div
            className={classnames('DetailsCard', { 'DetailsCard--isMobileActive': isMobileActive })}
        >
            <MainCardMenu />
            <div className="AdvicesContainer">
                {loaded ? (
                    advices.length !== 0 ? (
                        <ul>
                            {advices.map((advice) => (
                                <li key={advice.id}>
                                    <a href={getAdviceFileLink(advice.id, advice.fileId)}>
                                        {advice.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="AdviceTags__NoData">Советы не загружены</div>
                    )
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};

Advices.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
    showPageLoader: PropTypes.func.isRequired,
    hidePageLoader: PropTypes.func.isRequired,
    showErrorAlert: PropTypes.func.isRequired,
};

const actions = { showPageLoader, hidePageLoader, showErrorAlert };
export default connect(null, actions)(Advices);
