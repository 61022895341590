import React from 'react';
import classnames from 'classnames';

const StickyBottomAcceptMenu = props => {
    const { background, separator = '', btns } = props;

    const finalMenuClass = classnames('LKStickyBottomAcceptMenu', 
        {
            [`LKStickyBottomAcceptMenu--BgColor`]: background,
        }
    );

    return (
        <div className={finalMenuClass}>
            {btns.map((item, key) => {
                if (item.hide) {
                    return null;
                }
                const itemSeparator =  (btns.length - 1) !== key ? separator : '';
                const type = item.type ? item.type : 'Neutral';
                return (
                    <React.Fragment key={item.title}>
                        <div  
                            className={classnames(`LKStickyBottomAcceptMenu__Button LKStickyBottomAcceptMenu__Button--${type}`, {[`LKStickyBottomAcceptMenu__Button--Disable`] : item.disable})} 
                            onClick={!item.disable ? item.onClick : null}
                            data-test={`${item.dataTest}`}
                        >
                            {item.title}
                        </div>
                        {itemSeparator}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

export default StickyBottomAcceptMenu;