import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    getEvaluationRequests,
    reviewEvaluationRequest,
    getQuotaBalance,
    getAllowedGroups as getGroupsListApi,
} from 'api';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import Button from 'components/uikit/Button';
import DataGrid from 'components/common/DataGrid';
import ChevronButton from 'components/uikit/ChevronButton';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Select from 'components/uikit/Select';
import InputText from 'components/uikit/InputText';
import { capitalizeFirstLetter } from 'utils';
import './Evaluations.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalDialog from 'components/common/ModalDialog';
import { getError, serviceResultCode } from 'serviceErrors';

const buildColumns = ({ showReviewModal, renderGroup }) => [
    {
        Header: 'Специалист по развитию',
        sortable: false,
        resizable: false,
        className: 'Evaluations__td',
        headerClassName: 'Evaluations__th',
        Cell: (props) => {
            const { lastName, firstName, middleName } = props.original.requester;
            return `${lastName} ${firstName.charAt(0)}. ${middleName.charAt(0)}.`;
        },
    },
    {
        Header: 'Резервист',
        sortable: false,
        resizable: false,
        Cell: (props) => {
            const { lastName, firstName, middleName, id } = props.original.subjectPerson;
            return (
                <div className="New-Tab-User-Link">
                    <Link to={`/person/${id}`} target="_blank">
                        {`${lastName} ${firstName.charAt(0)}. ${middleName.charAt(0)}.`}
                        <FontAwesomeIcon size="xs" icon="external-link-alt" />
                    </Link>
                </div>
            );
        },
    },
    {
        Header: 'Группа',
        sortable: false,
        resizable: false,
        className: 'Evaluations__td',
        headerClassName: 'Evaluations__th',
        Cell: (props) => {
            const { groupId } = props.original.requester;
            return <div>{renderGroup(groupId)}</div>;
        },
    },
    {
        Header: 'Региональный координатор',
        sortable: false,
        resizable: false,
        className: 'Evaluations__td',
        headerClassName: 'Evaluations__th',
        Cell: (props) => {
            if (props.original.reviewer) {
                const { lastName, firstName, middleName } = props.original.reviewer;
                return `${lastName} ${firstName.charAt(0)}. ${middleName.charAt(0)}.`;
            } else {
                return ``;
            }
        },
    },
    {
        Header: 'Название теста',
        accessor: 'evaluationTest.title',
        sortable: false,
        resizable: false,
    },
    {
        Header: 'Статус',
        sortable: false,
        resizable: false,
        className: 'Evaluations__td--status',
        headerClassName: 'Evaluations__th--status',
        Cell: (props) => {
            const { id, status } = props.original;
            switch (status) {
                case 'New':
                    return (
                        <div className="Evaluations-Permissions-Table__Button-Group">
                            <Button size="sm" onClick={() => showReviewModal('Approve', id)}>
                                Принять
                            </Button>
                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => showReviewModal('Reject', id)}
                            >
                                Отклонить
                            </Button>
                        </div>
                    );
                case 'Approved':
                case 'Confirmed':
                    return (
                        <div className="Review-Status Review-Status--approved">
                            <FontAwesomeIcon size="2x" icon="check-circle" title="Принято" />
                            <span>Принято</span>
                        </div>
                    );
                case 'Rejected':
                    return (
                        <div className="Review-Status Review-Status--rejected">
                            <FontAwesomeIcon size="2x" icon="times-circle" title="Отклонено" />
                            <span>Отклонено</span>
                        </div>
                    );
                case 'Expired':
                    return (
                        <div className="Review-Status">
                            <FontAwesomeIcon
                                size="2x"
                                icon="hourglass-end"
                                title="Период действия истек"
                            />
                            <span>Период действия истек</span>
                        </div>
                    );
                case 'Finished':
                    return <>Пройден</>;
                default:
                    return;
            }
        },
    },
    {
        expander: true,
        sortable: false,
        resizable: false,
        Expander: ({ isExpanded }) => {
            return <ChevronButton isUp={isExpanded} />;
        },
    },
];

class Evaluations extends Component {
    state = {
        data: {},
        meta: {},
        paging: { pageNum: 1, pageSize: 10 },
        sorting: { created: 'desc' },
        loaded: false,
        periods: [],
        selectedPeriod: null,
        expanded: {},
        requestId: null,
        isReviewModalOpened: false,
        reviewDecision: '',
        reviewRejectText: '',
        quotaPoints: null,
        processing: false,
    };

    handleRowExpanded = (newExpanded, index) => {
        this.setState({
            expanded: { [index]: newExpanded[index] },
        });
    };

    getGroupsData = async () => {
        const user = this.props.currentUser;
        const result = await getGroupsListApi(user.groupId);
        this.setState({ groups: result.data, user: user });
    };

    renderGroup = (groupId) => {
        const { groups, user } = this.state;

        if (groups) {
            const group = groups.find((x) => x.id === groupId);

            if (user && group && group.id !== user.groupId) {
                return (
                    <div className="Evaluations-Permissions-Table__Group--child-group">
                        {group.title}
                    </div>
                );
            }
            return <div className="Evaluations-Permissions-Table__Group">{group.title}</div>;
        }
    };

    addTestDetailCard = (row) => {
        const {
            subjectPerson,
            reviewedOn,
            reviewRejectionComment,
            periodStartsOn,
            periodEndsOn,
            status,
        } = row.original;
        const testStart = moment(periodStartsOn).format('DD.MM.YYYY');
        const testEnd = moment(periodEndsOn).format('DD.MM.YYYY');
        return (
            <div className="Details">
                <div className="Row">
                    <div className="Row__Label">Должность резервиста</div>
                    <div className="Row__Info">{subjectPerson.currentJobTitle}</div>
                    <div className="Row__Label">Место работы резервиста</div>
                    <div className="Row__Info">{subjectPerson.currentJobCompanyName}</div>
                    <div className="Row__Divider"></div>

                    {status && status === 'New' && (
                        <>
                            <div className="Row__Label">Период</div>
                            <div className="Row__Info">{`с ${testStart} по ${testEnd}`}</div>
                        </>
                    )}

                    {status &&
                        (status === 'Approved' ||
                            status === 'Confirmed' ||
                            status === 'Finished') && (
                            <>
                                <div className="Row__Label">Дата разрешения</div>
                                <div className="Row__Info">
                                    {moment(reviewedOn).format('DD.MM.YYYY')}
                                </div>
                                <div className="Row__Label">Период</div>
                                <div className="Row__Info">{`с ${testStart} по ${testEnd}`}</div>
                            </>
                        )}

                    {status && status === 'Rejected' && (
                        <>
                            <div className="Row__Label">Дата отклонения</div>
                            <div className="Row__Info">
                                {moment(reviewedOn).format('DD.MM.YYYY')}
                            </div>
                            <div className="Row__Label">Комментарий Регионального координатора</div>
                            <div className="Row__Info">{reviewRejectionComment}</div>
                        </>
                    )}

                    {status && status === 'Expired' && (
                        <>
                            <div className="Row__Label">Период</div>
                            <div className="Row__Info">{`с ${testStart} по ${testEnd}`}</div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    handleStateChange = (key, value) => {
        this.setState((state) => {
            return {
                ...state,
                [key]: value,
            };
        });
    };

    handlePeriodChange = async (item) => {
        const yearMonth = moment(item.id).format('YYYY-MM-DD');
        await this.loadRequests(yearMonth, 1);

        this.setState((state) => {
            return {
                ...state,
                selectedPeriod: item,
                paging: { ...state.paging, pageNum: 1 },
            };
        });
    };

    renderPeriodsList = () => {
        let startDate = moment().subtract(9, 'months');
        const endDate = moment();
        let periods = [];

        while (startDate <= endDate && moment(endDate).isAfter('2019-08-01', 'month')) {
            periods.push({
                id: endDate.format('YYYY-MM-01'),
                name: capitalizeFirstLetter(moment(endDate).format('MMMM YYYY')),
            });
            endDate.subtract(1, 'months');
        }
        return periods;
    };

    onPageChange = (pageIndex) => {
        const { selectedPeriod } = this.state;
        const yearMonth = moment(selectedPeriod.id).format('YYYY-MM-DD');

        this.setState(
            {
                paging: { ...this.state.paging, pageNum: pageIndex + 1 },
                expanded: {},
            },
            () => this.loadRequests(yearMonth, pageIndex + 1),
        );
    };

    changePageAmount = (pageSize) => {
        const { selectedPeriod } = this.state;
        const yearMonth = moment(selectedPeriod.id).format('YYYY-MM-DD');

        this.setState({ paging: { pageNum: 1, pageSize } }, () => this.loadRequests(yearMonth, 1));
    };

    showReviewModal = (reviewDecision, id) => {
        this.setState((state) => {
            return {
                ...state,
                isReviewModalOpened: true,
                reviewDecision: reviewDecision,
                requestId: id,
            };
        });
    };

    reviewRequest = async () => {
        const { reviewDecision, reviewRejectText, requestId, selectedPeriod, paging } = this.state;
        const yearMonth = moment(selectedPeriod).format('YYYY-MM-DD');

        if (reviewDecision === 'Reject' && reviewRejectText.trim() === '') {
            return this.props.showErrorAlert('Необходимо указать причину отказа');
        }

        if (!requestId) return;

        this.setState({ processing: true });

        await this.callEffect(() =>
            reviewEvaluationRequest(requestId, reviewDecision, reviewRejectText),
        );
        this.setState(
            (state) => {
                return {
                    ...state,
                    isReviewModalOpened: false,
                    reviewRejectText: '',
                    requestId: null,
                    processing: false,
                };
            },
            () => this.loadRequests(yearMonth, paging.pageNum),
        );
    };

    onCancelReviewRequest = () => {
        this.setState((state) => {
            return {
                ...state,
                isReviewModalOpened: false,
                reviewRejectText: '',
                requestId: null,
            };
        });
    };

    isInvalidField = (stateKey) => {
        return this.state[stateKey] === '';
    };

    getTrProps = (state, rowInfo) => {
        if (rowInfo) {
            const { status } = rowInfo.original;
            switch (status) {
                case 'Approved':
                    return {
                        style: {
                            background: '#eff6fe',
                        },
                    };
                case 'Rejected':
                    return {
                        style: {
                            background: '#f9ebeb',
                        },
                    };
                case 'Confirmed':
                    return {
                        style: {
                            background: '#eff6fe',
                        },
                    };
                case 'Expired':
                    return {
                        style: {
                            background: '#fffbe5',
                            color: '#adadad',
                        },
                    };
                default:
                    return {};
            }
        }
        return {};
    };
    render() {
        const columns = buildColumns({
            showReviewModal: this.showReviewModal,
            renderGroup: this.renderGroup,
        });
        const {
            loaded,
            selectedPeriod,
            data,
            meta,
            paging,
            reviewDecision,
            quotaPoints,
        } = this.state;
        return (
            loaded &&
            data && (
                <div className="Evaluations-Permissions">
                    <div className="Evaluations-Permissions__Header">
                        <div className="Evaluations-Permissions__Header--text Col-80">
                            Разрешение на оценку
                        </div>
                        <div className="Evaluations-Permissions__Header--select Col-20">
                            <Field>
                                <Label>Период</Label>
                                <Select
                                    value={selectedPeriod}
                                    onChange={this.handlePeriodChange}
                                    options={this.state.periods}
                                    catalog
                                    placeholder="Выберите период"
                                />
                            </Field>
                        </div>
                    </div>
                    <div className="Evaluations-Permissions__Container-Content">
                        <div className="Evaluations-Permissions-Table Col-80">
                            <DataGrid
                                data={data}
                                foundCount={meta.foundCount}
                                columns={columns}
                                showPageSizeOptions={false}
                                manual
                                showPagination
                                pages={meta.pageCount}
                                page={paging.pageNum}
                                onPageChange={this.onPageChange}
                                expanded={this.state.expanded}
                                onExpandedChange={this.handleRowExpanded}
                                SubComponent={(row) => this.addTestDetailCard(row)}
                                getTrProps={this.getTrProps}
                                pageSize={paging.pageSize}
                                changePageAmount={this.changePageAmount}
                            />
                        </div>
                        <div className="Evaluations-Permissions-Quotas-Info Col-20">
                            <div className="Info-Box">
                                {moment(selectedPeriod.id).isSameOrAfter(
                                    moment(new Date()).startOf('month'),
                                ) ? (
                                    <>
                                        <div className="Info-Box__Text">Остаток квот</div>
                                        <div className="Info-Box__Main">{quotaPoints}</div>
                                    </>
                                ) : (
                                    <div className="Info-Box__Text">
                                        Период действия квоты истек
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <ModalDialog
                        onClick={this.reviewRequest}
                        onCloseModal={this.onCancelReviewRequest}
                        modalOpen={this.state.isReviewModalOpened}
                        modalHeader={
                            reviewDecision === 'Approve'
                                ? 'Вы уверены, что хотите разрешить?'
                                : 'Вы уверены, что хотите отклонить?'
                        }
                        btnOktext={reviewDecision === 'Approve' ? 'Да' : 'Отклонить'}
                        btnOkColor={reviewDecision === 'Approve' ? 'primary' : 'danger'}
                        isDangerHeader={reviewDecision !== 'Approve'}
                        btnCanceltext="Отмена"
                        processing={this.state.processing}
                    >
                        {reviewDecision === 'Reject' && (
                            <Field invalid={this.isInvalidField('reviewRejectText')}>
                                <Label>Причина</Label>
                                <InputText
                                    value={this.state.reviewRejectText}
                                    maxLength={80}
                                    onChange={(e) =>
                                        this.handleStateChange('reviewRejectText', e.target.value)
                                    }
                                ></InputText>
                            </Field>
                        )}
                    </ModalDialog>
                </div>
            )
        );
    }

    componentDidMount() {
        const currentDate = moment(new Date());
        const currentPeriodId = currentDate.format('YYYY-MM-01');
        const currentPeriodName = capitalizeFirstLetter(currentDate.format('MMMM YYYY'));
        this.setState((state) => {
            return {
                ...state,
                periods: this.renderPeriodsList(),
                selectedPeriod: {
                    id: currentPeriodId,
                    name: currentPeriodName,
                },
            };
        });
        this.loadRequests(currentDate.format('YYYY-MM-01'));

        if (!this.state.groups !== undefined) {
            this.getGroupsData();
        }
    }

    loadRequests = async (yearMonth, pageNum = 1) => {
        const { pageSize } = this.state.paging;
        const data = await this.callEffect(() =>
            getEvaluationRequests(yearMonth, pageNum, pageSize),
        );
        const quotaPointsData = await getQuotaBalance({
            startYearMonth: yearMonth,
            endYearMonth: yearMonth,
        });

        const { quotaPoints } =
            quotaPointsData && Array.isArray(quotaPointsData.data) && quotaPointsData.data[0];
        data &&
            this.setState((state) => {
                return {
                    ...state,
                    loaded: true,
                    data: data.data.payload,
                    meta: data.data.meta,
                    quotaPoints: quotaPoints,
                };
            });
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            const reqError = getError(error, this.getDropSessionError);
            this.props.showErrorAlert(reqError.message);
        } finally {
            this.props.hidePageLoader();
        }
    };

    getDropSessionError = (code, payload) => {
        switch (code) {
            case serviceResultCode.EvaluationQuotaPointsInsufficientBalance:
                return payload;
            case serviceResultCode.EvaluationRequestExpired:
                return `Истекло время действия запроса`;
            case serviceResultCode.EvaluationRequestCannotReview:
                return `Невозможно рассмотреть запрос со статусом 'Новый'`;
            case serviceResultCode.EvaluationRequestCannotReviewDoesNotExist:
                return `Невозможно рассмотреть запрос, так как такого запроса не существует`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };
}

const props = (state) => {
    return {
        currentUser: state.auth.user,
    };
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(props, actions)(Evaluations);
