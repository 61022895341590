import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { resetPassword } from 'ducks/Users';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import { userEdit } from 'routes';
import { sendActivationLink } from 'api';
import { API_STATUS_OK, API_STATUS_NOT_FOUND } from 'constants.js';
import { isSecurityAdmin, isSupportLine1, isSupportLine2 } from 'rightsController';
import InfoCard from './InfoCard';
import { userProps, personProps, expertProps } from './constants';
import Button from 'components/uikit/Button';
import ModalDialog from 'components/common/ModalDialog';
import { getUserFullName } from 'utils';

const ActionItem = ({ label, onClick, color = 'primary', disabled = false }) => {
    return (
        <div className="AdminUserInfoAction">
            <Button size="xs" onClick={onClick} color={color} disabled={disabled}>
                {label}
            </Button>
        </div>
    );
};

class UserInfo extends Component {
    state = { isModalOpen: false };

    setModalState = isModalOpen => this.setState({ isModalOpen });

    canUnblock = () => {
        const { user, currentUser } = this.props;
        const isLocked = user.isDisabled || user.isLocked;
        return isLocked && isSecurityAdmin(currentUser);
    };

    isSupport = () => {
        const { currentUser } = this.props;
        return isSupportLine1(currentUser) || isSupportLine2(currentUser);
    };

    editUser = () => {
        const {
            user: { id },
            push,
        } = this.props;
        push(userEdit.buildUrl({ id }));
    };

    resetPassword = () => {
        const { user } = this.props;
        this.props.resetPassword({...user, fullName: getUserFullName(user)});
    };

    sendActivationLink = async () => {
        const {
            user,
            showSuccessAlert,
            showErrorAlert,
            showPageLoader,
            hidePageLoader,
        } = this.props;

        try {
            showPageLoader();
            const result = await sendActivationLink(user.id);
            if (result.status === API_STATUS_OK) {
                showSuccessAlert(`Ссылка была успешно отправлена на ${user.email}`);
            } else if (result.status === API_STATUS_NOT_FOUND) {
                showErrorAlert('Пользователь не существует.');
            }
            this.setModalState(false);
        } catch {
            showErrorAlert('При отправке ссылки на активацию произошла неизвестная ошибка.');
        } finally {
            hidePageLoader();
        }
    };

    render() {
        const { isModalOpen } = this.state;
        const { user, personCard, personExpertCard, header, currentUser, getPropInfo } = this.props;

        const isLocked = user.isDisabled;
        const isNotInvited = !(user.phoneNumberConfirmed && user.passwordGenerated);

        if (!user) {
            return null;
        }

        return (
            <div className="AdminUserInfo">
                {header}
                <div className="AdminUserInfo__Actions">
                    {(
                        <ActionItem label="Редактировать" onClick={this.editUser} />
                    )}
                    <ActionItem
                        label={
                            isNotInvited
                                ? 'Отправить приглашение'
                                : 'Отправить приглашение (повторно)'
                        }
                        onClick={() => this.setModalState(true)}
                        disabled={isLocked}
                    />
                    <ActionItem
                        label="Сбросить пароль"
                        onClick={this.resetPassword}
                        disabled={isLocked}
                    />
                    {(!isSecurityAdmin(currentUser) && isLocked) || this.isSupport() ? null : (
                        <ActionItem
                            label={isLocked ? 'Разблокировать' : 'Блокировать'}
                            onClick={this.props.blockUser}
                            color={isLocked ? 'primary' : 'danger'}
                        />
                    )}
                </div>
                <InfoCard
                    header="Информация о пользователе"
                    data={user}
                    propsArray={userProps}
                    getPropInfo={getPropInfo}
                />
                <InfoCard
                    header="Прикрепленная анкета"
                    data={personCard}
                    propsArray={personProps}
                    getPropInfo={getPropInfo}
                />
                <InfoCard
                    header="Эксперт по управлению талантами"
                    data={personExpertCard}
                    propsArray={expertProps}
                    getPropInfo={getPropInfo}
                />
                <ModalDialog
                    modalHeader={`Отправить приглашение на ${user.email}?`}
                    onClick={this.sendActivationLink}
                    onCloseModal={() => this.setModalState(false)}
                    modalOpen={isModalOpen}
                />
            </div>
        );
    }
}

UserInfo.defaultProps = {
    header: { header: '', subHeader: '' },
};

UserInfo.propTypes = {
    user: PropTypes.object.isRequired,
    personCard: PropTypes.object,
    personExpertCard: PropTypes.object,
    header: PropTypes.object,
    currentUser: PropTypes.object,
    blockUser: PropTypes.func,
    getPropInfo: PropTypes.func,
};

const actions = {
    push,
    resetPassword,
    showPageLoader,
    hidePageLoader,
    showSuccessAlert,
    showErrorAlert,
};

export default connect(
    null,
    actions,
)(UserInfo);
