import { push } from 'connected-react-router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip, ResponsiveContainer, PieChart, Pie, Sector, Cell } from 'recharts';
import { search } from 'routes'

import './Industries.scss';

class Industries extends Component {
    state = {
        activeIndex: null,
    };

    onPieEnter = (data) => {
        this.setState( state => {
                return { 
                    ...state,
                    activeIndex: data.activeIndex,
                }
            }
        );  
    };

    onPieOut = () => {
        this.setState( state => {
                return { 
                    ...state,
                    activeIndex: null,
                }
            }
        );
    };

    handlePieClick = () => {
        if (this.state.activeIndex === null) { return; }

        const entry = this.props.industriesData[this.state.activeIndex];

        this.props.push(search.url, {analyticsPersonIds: entry.personIds, analycitsIndustryId: entry.industryId});
    }

    industriesChartTooltip = ({ active, payload }) => {
        if (active) {
            return (
                <div className="Chart-Tooltip">
                    <div className="Chart-Tooltip__Line">{`${this.industriesFormatter(payload[0].payload.industryId)} ${payload[0].payload.percent}%`}</div>
                </div>
            );
        }
        
        return null;
    };

    industriesFormatter = (id) => {
        const elem = this.props.industriesCatalog.data.find(x => x.id === id);
        return elem && elem.name;
    }

    renderIndustryChart = (chartData) => {
        const lessColors = chartData.length <= 10;   
        const maxAmount = Math.max(...chartData.map(x => x.percent));     
        return ( 
            <div className="Chart-Info">              
                <div className="Chart-Info__Graph" style={{ width: '40%', height: 500 }}>
                    <ResponsiveContainer>
                        <PieChart>
                            <Tooltip content={this.industriesChartTooltip}/>
                            <Pie
                                className="IndustryPieChart"
                                activeShape={this.renderActiveShape}
                                activeIndex={this.state.activeIndex}
                                dataKey="percent" 
                                data={chartData} 
                                innerRadius={100}
                                onMouseEnter={this.onPieEnter}
                                onMouseOut={this.onPieOut}
                                onClick={this.handlePieClick}
                            >
                                {chartData.map((entry) => {
                                    const color = this.props.getColor(entry.percent, maxAmount, lessColors);
                                    const strokeColor = this.props.lightenDarkenColor(color, 50);
                                        return (
                                            <Cell
                                                stroke={strokeColor} 
                                                key={`cell-${entry.industryId}`} 
                                                fill={color}
                                            />
                                        )
                                    }
                                )} 
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <div className="Chart-Info__Legend">
                    {this.renderIndustriesChartLegend(chartData, lessColors)}
                </div>
            </div>
        )        
    }

    onLegendEnter = (props) => {
        this.setState(
            {
                activeIndex: +props.target.getAttribute("id"),
            }
        );
    }

    onLegendOut = () => {
        this.setState(
            {
                activeIndex: null,
            }
        );
    }

    renderIndustriesChartLegend = (data, lessColors) => {
        const maxAmount = Math.max(...data.map(x => x.percent));
        return (
            <div className="List-Col">
                {data.map((x) => {
                    const percent = Math.round(x.percent * 100) / 100;
                    return (
                        <div 
                            className="List-Col__Line-50" 
                            key={x.industryId} 
                            id={x.activeIndex} 
                            color={this.props.getColor(x.percent, maxAmount, lessColors)}
                            onMouseEnter={this.onLegendEnter} 
                            onMouseLeave={this.onLegendOut}
                            onClick={this.handlePieClick}
                        >
                            <div className="Line-Flex_Container">
                                <div className="List-Col__Line-50--bullet" style={{backgroundColor: this.props.getColor(x.percent, maxAmount, lessColors) }}></div>
                                <div className="List-Col__Line-50--text" id={x.activeIndex} >
                                    {this.industriesFormatter(x.industryId)}
                                </div>
                                <div className="List-Col__Line-50--percent">{percent}%</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }

    renderActiveShape = (props) => {
        const {
            cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill
        } = props;

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius - 20}
                    outerRadius={outerRadius + 20}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        );
    }
    
    render() {
        const chartData = this.props.industriesData 
                    && this.props.industriesData.map((x, index) => ({...x, percent: Math.round(x.percent * 100) / 100,  activeIndex: index}));
        return (
            <div className="Chart Chart--last">
                <div className="Chart__Header">
                    Отрасли резервистов
                </div>
                {chartData && chartData.length !== 0 
                ? this.renderIndustryChart(chartData)
                :
                <div className="No-Data">
                    Данные не найдены
                </div>
                }
            </div>
        );    
    }
}

const mapDispatchToProps = {
    push
};

export default connect(null, mapDispatchToProps)(Industries);