export const ADD_MENU_ID = 'addTeam';
export const DELETE_MENU_ID = 'deleteTeam';
export const RENAME_MENU_ID = 'renameTeam';

export const ADD_PERSON_MENU_ID = 'addPerson';
export const DELETE_PERSON_MENU_ID = 'deletePerson';
export const MOVE_PERSON_MENU_ID = 'movePerson';
export const EMAIL_PERSON_ID = 'emailPerson';
export const CALL_PERSON_MENU_ID = 'callPerson';

export const teamsEditMenuItems = [
    {id: ADD_MENU_ID, name: 'Добавить команду', icon: 'circle-plus', action: 'add'},
    {id: DELETE_MENU_ID, name: 'Удалить команду', icon: 'circle-cross', action: 'delete'},
    {id: RENAME_MENU_ID, name: 'Переименовать команду', icon: 'edit', action: 'edit'},
];

export const teamEditMenuItems = [
    {id: ADD_PERSON_MENU_ID, icon: 'circle-plus', action: 'add'},
    {id: DELETE_PERSON_MENU_ID,  icon: 'circle-cross', action: 'delete'},
    {id: MOVE_PERSON_MENU_ID, icon: 'move', action: 'move'},
];

export const TEAM_NAME_LIMIT = 250;