import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { signIn as signInRoute, interfaceSelectorRoute } from 'routes';
import { fetchUser } from 'ducks/Auth';
import queryString from 'query-string';
import { canSelectInterface } from 'rightsController';

class PrivateRoute extends Component {
    componentDidMount() {
        this.ensureUserLoaded();
    }

    componentDidUpdate() {
        this.ensureUserLoaded();
    }

    ensureUserLoaded = () => {
        if (!this.isUserLoaded()) {
            this.props.fetchUser();
        }
    };

    isUserLoaded = () => {
        const { auth } = this.props;
        return auth.user != null || +auth.loadTime !== +new Date(0);
    };

    render() {
        const { auth, redirectUrl, ...rest } = this.props;

        if (auth.user != null) {
            if (canSelectInterface(auth.user) 
                && !auth.user.selectedInterface)
            {
                const search = queryString.stringify({ from: window.location.pathname });
                return <Redirect to={{ pathname: interfaceSelectorRoute.url, search }} />
            }
            
            if (redirectUrl) {
                return <Redirect to={redirectUrl} />;
            }
            return <Route {...rest} />;
        }

        if (!this.isUserLoaded()) {
            return null;
        }

        const search = queryString.stringify({ from: window.location.pathname });

        return <Redirect to={{ pathname: signInRoute.url, search }} />;
    }
}

export default connect(
    state => {
        return {
            auth: state.auth,
        };
    },
    { fetchUser },
)(PrivateRoute);
