import { uploadLanguageAttachment, uploadTempFile, getLanguageAttachmentInfo } from "api";
import FileUploadButton from "components/uikit/FileUploadButton";
import contramapProps from "components/hoc/contramapProps";

const getUploader = (personId) => async (file) => {
    try {
        const response = personId > 0
            ? await uploadLanguageAttachment(personId, file)
            : await uploadTempFile(file);
        return response.data;
    } catch (e) {
        return null;
    }
};

const getFileInfoRequester = (personId) => async (fileId) => {
    try {
        const response = personId > 0
            ? await getLanguageAttachmentInfo(personId, fileId)
            : Promise.resolve(null);
        return response.data;
    } catch (e) {
        return null;
    }
};

const injectFileApis = ({ personId = null, fileId, onFileIdChange, maxBytesPerFile }) =>
    ({
        fileId,
        onFileIdChange,
        getFileInfo: getFileInfoRequester(personId),
        onUpload: getUploader(personId),
        maxBytesPerFile
    });

export const LangFileUploadButton = contramapProps(injectFileApis, FileUploadButton);
