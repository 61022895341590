import { push } from "connected-react-router";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ComposableMap, ZoomableGroup, Geographies, Geography } from "react-simple-maps";
import ReactTooltip from 'react-tooltip'
import { MapRegions } from './constants';
import { search } from 'routes';

const wrapperStyles = {
    width: "100%",
    margin: "0 auto",
}

const defaultZoom = .8, maxZoom = 10;
const minSliderValue = 0, maxSliderValue = 100, sliderStep = 5;
const zoomStep = (maxZoom - defaultZoom) / (maxSliderValue / sliderStep);
const k = maxSliderValue / (maxZoom - defaultZoom);

class Choropleth extends Component {
    state = {
        zoom: defaultZoom,
        center: [0, 0],
        moveStartCenter: null,
        moved: false
    }

    getRegionData = (regionName) => {
        const mappedRegion = MapRegions.filter(x => x.regionName === regionName);
        const dataRegion  = mappedRegion && this.props.choroplethData.find(x => x.code === mappedRegion[0].regionCode);
        return dataRegion && { amount: dataRegion.amount, name2show: dataRegion.name, personIds: dataRegion.personIds, id: dataRegion.id };
    };

    handleZoomIn = () => {
        if (this.state.zoom < maxZoom) {
            this.setState({
                zoom: this.state.zoom + zoomStep,
            })
        }
        
    }

    handleZoomOut = () => {
        if (this.state.zoom > defaultZoom) {
            this.setState({
                zoom: this.state.zoom - zoomStep,
            })
        }
    }

    onSliderChange = (val) => {
        this.setState({
            zoom: 1 + (val / k),
        })
    }

    handleGroupMoveStart = (center) => {
        this.setState({ moveStartCenter: center });
    }

    handleGroupMoveEnd = (center) => {
        const oldCenter = this.state.moveStartCenter;

        if (oldCenter[0] !== center[0] || oldCenter[1] !== center[1]) {
            this.setState({ moved: true });
        } else {
            this.setState({ moved: false });
        }
    }

    handleGeographyClick = (isPositiveAmount, regionData) => () => {
        if (this.state.moved) { return; }

        if (isPositiveAmount) {
            this.props.push(search.url, {mapPersonIds: regionData.personIds, mapRegionId: regionData.id });
        }
    };

    renderMap = (mapData) => {
        const maxAmount = Math.max(...mapData.map(x => x.amount));
        const lessColors = mapData.length <= 10;

        return (
                <div className="Map" style={wrapperStyles}>
                        <ComposableMap
                            width={1400}
                            projectionConfig={{
                                scale: 650,
                                xOffset: -380,
                                yOffset: 330,
                                rotation: [0,0,0],
                                precision: 0.1,
                            }}
                        >
                            <ZoomableGroup 
                                center={ this.state.center } 
                                zoom={ this.state.zoom }
                                onMoveStart={this.handleGroupMoveStart}
                                onMoveEnd={this.handleGroupMoveEnd}
                            >
                                <Geographies geography='/russia.json'>
                                    {
                                        (geographies, projection) => geographies.map((geography, i) => {
                                            return this.renderGeography(geography, maxAmount, lessColors, projection, i);
                                        })
                                    }
                                </ Geographies>
                            </ ZoomableGroup>
                        </ ComposableMap>   
                        <ReactTooltip type={'light'} border={true} />
                    <div className="Map-Controls">
                        <div className="Map-Controls__Col">
                            <button size="sm" onClick={ this.handleZoomIn }>{ "+" }</button>
                        </div>
                        <div className="Map-Controls__Col--slider">
                            <Slider 
                                vertical
                                step={sliderStep} 
                                min={minSliderValue} 
                                max={maxSliderValue} 
                                defaultValue={minSliderValue} 
                                onChange={this.onSliderChange}
                                value={(this.state.zoom - defaultZoom) * k}
                            />
                        </div>
                        <div className="Map-Controls__Col">
                            <button size="sm" onClick={ this.handleZoomOut }>{ "-" }</button>
                        </div>
                    </div>                 
                </div>
        );
    }

    renderGeography = (geography, maxAmount, lessColors, projection, i) => {
        const regionData = (this.getRegionData(geography.properties.NAME_1));
        let newGeography = {};
        let newColor = "";
        if (regionData) {
            newColor = this.props.getColor(regionData.amount, maxAmount, lessColors);
            const newProperties = {
                ...geography.properties,
                AMOUNT: regionData.amount
            }
            newGeography = {
                ...geography,
                properties: {...newProperties}
            }
        }

        const isPositiveAmount = regionData && regionData.amount > 0;
        const dataTip = regionData
            && (isPositiveAmount ? `${regionData.name2show}: ${regionData.amount}` : regionData.name2show);

        return (
            <Geography
                key={i}
                data-tip={dataTip}
                data-multiline
                geography={regionData ? newGeography : geography} 
                projection={projection}
                onClick={this.handleGeographyClick(isPositiveAmount, regionData)}
                style={{
                    default: {
                        fill: newColor ? newColor : "#fff",
                        stroke: "#607D8B",
                        strokeWidth: 0.5,
                        outline: "none",
                    },
                    hover: {
                        fill:  newColor ? newColor : "#e5f0fd",
                        stroke: "#607D8B",
                        strokeWidth: 0.5,
                        outline: "none",
                        cursor: "grab",
                    },
                    pressed: {
                        fill: "#323963",
                        stroke: "#323963",
                        strokeWidth: 0.5,
                        outline: "none",
                        cursor: "grabbing",
                    }
                }}
            />                                            
        );
    }

    render() {
        const mapData = this.props.choroplethData;
        return ( 
            <div className="Chart">
                <div className="Chart__Header">
                    Места проживания резервистов в Российской Федерации
                </div>                
                {mapData && mapData.length !== 0 
                    ? this.renderMap(mapData)
                    :
                    <div className="No-Data">
                        Данные не найдены
                    </div>
                }
            </div>
        );
    }

    componentDidMount() {
        setTimeout(() => {
            ReactTooltip.rebuild()
          }, 500)
    }
}

const mapDispatchToProps = { push };

export default connect(null, mapDispatchToProps)(Choropleth);
