import React from 'react';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';

const Input = React.forwardRef((props, ref) => {
    const {
        placeholder = '',
        type = 'text',
        preIcon = '',
        preIconColor = "#007AFF",
        postIcon = '',
        postIconClick = undefined,
        postIconColor = "#A6B2C4",
        ...tail
    } = props;
    return (
        <div className="LKInputWrapper">
            {preIcon && (
                <div className="LKInputWrapper__PreIcon">
                    <Icons name={preIcon} fill={preIconColor} />
                </div>
            )}
            {postIcon && (
                <div className="LKInputWrapper__PostIcon">
                    <IconButton
                        type={postIcon}
                        fill={postIconColor}
                        onClick={postIconClick}
                        autoSize
                        inline
                    />
                </div>
            )}
            <input ref={ref} className="LKInput" placeholder={placeholder} type={type} {...tail} />
        </div>
    );
});

export default Input;
