import React from "react";
import classnames from "classnames";
import "./PageTitle.scss";

function PageTitle({ children, className, theme = 'default' }) {
    className = classnames(className, "PageTitle", `PageTitle_theme_${theme}`);
    
    return (
        <div className={className}>
            {children}
        </div>
    );
}

export default PageTitle;
