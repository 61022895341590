import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TechSupport from './TechSupport';
import Button from 'components/uikit/Button';

const SubmitSection = (props) => {
    const { onSubmit, disabled, auth, withLegalNotice } = props;

    return (
        <>
            <div className="kr_btn_wrap">
                <Button
                    onClick={onSubmit}
                    disabled={auth.signInInProgress || disabled}
                    loading={auth.signInInProgress}
                    data-test="SignInEnter--button"
                >
                    Войти
                </Button>
            </div>

            {withLegalNotice && (
                <div className="SignIn__LegalNotice">
                    Нажимая кнопку "Войти", вы соглашаетесь получить СМС с авторизационным кодом
                </div>
            )}
            <TechSupport />
        </>
    );
};

const props = (state) => {
    return {
        auth: state.auth,
    };
};

SubmitSection.defaultProps = { disabled: false, withLegalNotice: true };

SubmitSection.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    withLegalNotice: PropTypes.bool,
};

export default connect(props)(SubmitSection);
