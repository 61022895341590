import React, { Component } from 'react';
import classnames from 'classnames';
import ToggleOpen from 'components/hoc/ToggleOpen';
import './Dropdown.scss';

function DefaultToggleButtonComponent(props) {
    const { children, onToggle } = props;

    return (
        <div className="Dropdown__ToggleButton" onClick={onToggle}>
            <header>{children}</header>
            <i />
        </div>
    );
}

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.menuRef = React.createRef();
    }

    static defaultProps = {
        ToggleButtonComponent: DefaultToggleButtonComponent,
    };

    render() {
        const {
            title,
            children,
            isOpen = false,
            toggleOpen,
            className,
            ToggleButtonComponent,
            userPic,
            issuesAmount,
            externalProps,
            tooltip,
            tooltipHeader,
            tooltipPlacement,
        } = this.props;
        const finalClassName = classnames('Dropdown', className, {
            Dropdown_IsOpen: isOpen,
        });

        return (
            <div className={finalClassName} ref={this.menuRef}>
                <ToggleButtonComponent 
                    onToggle={toggleOpen} 
                    userPic={userPic} 
                    issuesAmount={issuesAmount} 
                    externalProps={externalProps} 
                    tooltip={tooltip} 
                    tooltipHeader={tooltipHeader}
                    tooltipPlacement={tooltipPlacement}
                >
                    <div className="UserMenu__UserName--title" data-test="UserMenu_UserName--title">{title} </div>
                    {isOpen && <div className="Dropdown__SubMenu">{children}</div>}
                </ToggleButtonComponent>
            </div>
        );
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = e => {
        if (!this.menuRef.current || this.menuRef.current.contains(e.target)) {
            return;
        }
        if (this.props.isOpen) {
            this.props.toggleOpen();
        }
    };
}

export default ToggleOpen(Dropdown);
