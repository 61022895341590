import React from "react";
import classnames from "classnames";

const ModalPopupContent = props => {
    const { children, infiniteWrapper, fitScreenSize, contentMarginTop, contentClass } = props;
    
    return (
        infiniteWrapper
            ?   <div
                className='LKModalPopup__Content--fitScreenSize'
                style={{
                    ...(fitScreenSize && {
                        marginTop: contentMarginTop,
                        height: "100%"})
                }}
            >
                {children}
            </div>
            :
            <div
                className={classnames('', { 'LKModalPopup__FixWrapper': fitScreenSize })}
                style={{ ...(fitScreenSize && { marginTop: contentMarginTop }) }}
            >
                <div
                    className={classnames(contentClass, {
                        'LKModalPopup__Content--fitScreenSize': fitScreenSize,
                    })}
                >
                    {children}
                </div>
            </div>
    );
};

export default ModalPopupContent;