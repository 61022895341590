import React from 'react';
import './ToolTypeSelector.scss';
import CheckCard from '../../CheckCard';
import Label from 'components/uikit/Label';
import Button from 'components/uikit/Button';
import classnames from 'classnames';
import { extendedSearchPersonGrowthResources, getResourceCounter, makeRequestCreator } from 'api';
import LazyLoad from 'react-lazy-load';
import Popover from 'components/Lk/Uikit/Popover';
import axios from 'axios';
import Pagination from 'components/common/Pagination';
import { default as LocalLoader } from 'components/common/Loader';
import Icons from 'components/Lk/Uikit/Icons/Icons';

const getPersonGrowthResourcesOnce = makeRequestCreator(extendedSearchPersonGrowthResources);
const getPersonResourceCounterOnce = makeRequestCreator(getResourceCounter);

const resourceTypes = [
    { name: 'Видео', type: 'Video' },
    { name: 'Книги', type: 'Book' },
    { name: 'Статьи', type: 'File' },
    { name: 'Курсы', type: 'Microcourse' },
    { name: 'Аудиокниги', type: 'AudioBook' },
    { name: 'Ссылки', type: 'Link' },
];

export const RESOURCE_RECOMEND = 'recomend';
export const RESOURCE_ALL = 'all';
export const RESOURCE_SELECTED = 'selected';

const tooltip = `Инструменты развития - это библиотеки партнеров, Альпины и Виртуальной школы
                    Сбербанка. Переход по материалам Альпины происходит автоматически. Для
                    доступа в ВШС введите логин и пароль из письма-приглашения, которое было
                    направлено резервисту на почту с адреса info@iskra-system.ru.`;

export const ToolTypeSelectorHelpTitle = () => {
    return (
        <div>
            <div className="targetCreation__container__title">
                <Popover tooltip={tooltip}>
                    <div
                        className="tooltip-link"
                        data-tip
                        data-for="socialActivityHint"
                        style={{ fontSize: 24 + 'px' }}
                    >
                        ?
                    </div>
                </Popover>
            </div>
        </div>
    );
};

export class ToolTypeSelectorRecommendation extends React.Component {
    state = {
        loading: false,
        checkedActive: false,
        recommendedResources: true,
        resourceType: RESOURCE_RECOMEND,
        selectedType: '',
        searchCriteria: '',
        search: false,
        paging: {
            pageNum: 1,
            pageSize: 10,
        },
        resources: [],
        pageCount: 1,
        totalCount: 0,
        resourceCount: {},
    };

    searchTools = (reset = false) => {
        if (reset) this.setState({searchCriteria: ''})

        this.setState(
            state => {
                return {
                    ...state,
                    paging: {
                        pageNum: 1,
                        pageSize: state.paging.pageSize,
                    },
                };
            },
            () => {
                this.getResources();
                this.getResourceCounter();
            },
        );
    };

    handleSearchTools = () => {
        this.searchTools()
    }

    handleResetSearchTools = () => {
        this.searchTools(true)
    }

    showSelected = () => {
        this.setState(state => {
            return {
                ...state,
                resourceType: RESOURCE_SELECTED,
            };
        });
    };

    showAll = () => {
        this.setState(
            state => {
                return {
                    ...state,
                    resourceType: RESOURCE_ALL,
                };
            },
            () => {
                this.getResources();
                this.getResourceCounter();
            },
        );
    };

    getResources = async () => {
        const { selectedType, resourceType, paging, searchCriteria } = this.state;

        if (resourceType === RESOURCE_SELECTED) return;

        this.handleStateChange('loading', true);

        const { competencyIds } = this.props;
        const requestCriteria = {
            filter: {
                name: searchCriteria,
                types: !!selectedType ? [selectedType] : [],
                competencyIds: resourceType === RESOURCE_ALL ? [] : competencyIds,
            },
            paging: paging,
        };
        try {
            const response = await getPersonGrowthResourcesOnce(requestCriteria);
            this.setState(state => {
                return {
                    ...state,
                    resources: response.data.payload,
                    totalCount: response.data.meta.foundCount,
                    pageCount: response.data.meta.pageCount,
                };
            });
        } catch (e) {
            if (axios.isCancel(e)) {
                return;
            }
        }
        this.handleStateChange('loading', false);
    };

    getResourceCounter = async () => {
        const { resourceType, searchCriteria } = this.state;

        if (resourceType === RESOURCE_SELECTED) return;

        const { competencyIds } = this.props;
        const requestCriteria = {
            filter: {
                name: searchCriteria,
                competencyIds: resourceType === RESOURCE_ALL ? [] : competencyIds,
            },
        };

        const response = await getPersonResourceCounterOnce(requestCriteria);
        if (response) {
            this.handleStateChange('resourceCount', response.data);
        }
    };

    showRecommended = () => {
        this.setState(
            state => {
                return {
                    ...state,
                    resourceType: RESOURCE_RECOMEND,
                };
            },
            () => {
                this.getResources();
                this.getResourceCounter();
            },
        );
    };

    filterByType = type => {
        this.setState(state => {
            return {
                ...state,
                selectedType: type,
                paging: {
                    pageNum: 1,
                    pageSize: state.paging.pageSize,
                },
            };
        }, this.getResources);
    };

    handleStateChange = (key, value) => {
        this.setState(state => {
            return {
                ...state,
                [key]: value,
            };
        });
    };

    handleSearchInputKeyDown = e => {
        if (e.key === 'Enter') {
            this.searchTools();
        }
    };

    handlePaginate = pageNum => {
        const newPaging = { ...this.state.paging, pageNum };
        this.setState({ paging: newPaging }, this.getResources);
    };

    handleToolSelect = (item, selected) => {
        const changedResources = selected
            ? [...this.props.resources.map(x => x), item]
            : this.props.resources.filter(x => x.id !== item.id);

        this.props.onChange('resources', changedResources);
    };

    getResourcesByType = () => {
        const { resources: selectedResources } = this.props;
        const { resourceType, resources, searchCriteria, selectedType } = this.state;

        switch (resourceType) {
            case RESOURCE_ALL:
            case RESOURCE_RECOMEND:
                return resources;
            case RESOURCE_SELECTED:
                return selectedResources.filter(
                    x =>
                        x.name.includes(searchCriteria) &&
                        (!selectedType || x.type === selectedType),
                );
            default:
                return resources;
        }
    };

    getCountersByType = () => {
        const { resources } = this.props;
        const { resourceCount, resourceType, searchCriteria } = this.state;

        let counters = {
            Video: 0,
            Book: 0,
            File: 0,
            Microcourse: 0,
            Link: 0,
            AudioBook: 0,
        };

        switch (resourceType) {
            case RESOURCE_ALL:
            case RESOURCE_RECOMEND:
                return resourceCount;
            case RESOURCE_SELECTED:
                if (resources.length === 0) {
                    return counters;
                }

                for (const resource of resources.filter(x => x.name.includes(searchCriteria))) {
                    counters[resource.type] = counters[resource.type] + 1;
                }

                return counters;
            default:
                return counters;
        }
    };

    componentDidMount() {
        this.getResources();
        this.getResourceCounter();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.resourceIds &&
            prevProps.resourceIds.length !== this.props.resourceIds.length &&
            this.state.checkedActive
        ) {
            this.filterByChecked(false);
        }
    }

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, this.getResources);
    };

    renderSearchPanel = () => {
        return (
            <div className="toolTypeSelector__container__row__item__recommend-search-block">
                <input
                    onChange={e => this.handleStateChange('searchCriteria', e.target.value)}
                    onKeyDown={this.handleSearchInputKeyDown}
                    value={this.state.searchCriteria || ''}
                />
                <div className="toolTypeSelector__clearButton" onClick={this.handleResetSearchTools}>
                    <Icons fill="#368ef1" name="iconClose" />
                </div>
                <Button size="xs" onClick={this.handleSearchTools}>
                    Поиск
                </Button>
            </div>
        );
    };

    renderCategorySection = () => {
        return (
            <div className="toolTypeSelector__container__row__item__recommend-category-block">
                <div
                    className={classnames('toolTypeSelector__container__row__item__recommend', {
                        'toolTypeSelector__container__row__item__recommend-active':
                            this.state.resourceType === RESOURCE_RECOMEND,
                    })}
                >
                    <button
                        className="toolTypeSelector__input"
                        name="recommendedResources"
                        id="recommendedResources"
                    ></button>
                    <label
                        htmlFor="recommendedResources"
                        className="toolTypeSelector__container__row__item__input"
                        onClick={() => {
                            this.showRecommended();
                        }}
                    >
                        Рекомендованные
                    </label>
                </div>
                <div
                    className={classnames('toolTypeSelector__container__row__item__recommend', {
                        'toolTypeSelector__container__row__item__recommend-active':
                            this.state.resourceType === RESOURCE_ALL,
                    })}
                >
                    <button
                        className="toolTypeSelector__input"
                        name="allResources"
                        id="allResources"
                    />
                    <label
                        htmlFor="allResources"
                        className="toolTypeSelector__container__row__item__input"
                        onClick={() => {
                            this.showAll();
                        }}
                    >
                        Все
                    </label>
                </div>
                <div
                    className={classnames('toolTypeSelector__container__row__item__recommend', {
                        'toolTypeSelector__container__row__item__recommend-active':
                            this.state.resourceType === RESOURCE_SELECTED,
                    })}
                >
                    <button
                        className="toolTypeSelector__input"
                        name="checkedResources"
                        id="checkedResources"
                    ></button>
                    <label
                        htmlFor="checkedResources"
                        className="toolTypeSelector__container__row__item__input"
                        onClick={() => {
                            this.showSelected();
                        }}
                    >
                        Выбранные
                    </label>
                </div>
            </div>
        );
    };

    renderTypesSection = () => {
        let checked = false;
        const resourceCounter = this.getCountersByType();
        return (
            <div className="toolTypeSelector__container__selector">
                {resourceTypes
                    .filter(x => !x.hidden)
                    .map(resource => {
                        return (
                            <div
                                key={resource.type}
                                className={classnames(
                                    'toolTypeSelector__container__selector__row',
                                    {
                                        'toolTypeSelector__container__selector__row-active':
                                            this.state.selectedType === resource.type,
                                    },
                                )}
                                onClick={() => {
                                    checked = !checked;
                                    this.filterByType(resource.type);
                                }}
                            >
                                <div className="toolTypeSelector__container__row__item__type">
                                    <input
                                        className="toolTypeSelector__input"
                                        name="type"
                                        type="radio"
                                        id={resource.id}
                                    />
                                    <label
                                        htmlFor={resource.id}
                                        className="toolTypeSelector__container__row__item__input"
                                        
                                    >
                                        {resource.name} <br />{' '}
                                        <div className="badge">
                                            {resourceCounter[resource.type]}
                                        </div>
                                    </label>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    renderResultsSection = () => {
        const {
            search,
            paging: { pageNum, pageSize },
            pageCount,
            totalCount,
            resourceType,
        } = this.state;

        const resources = this.getResourcesByType();

        return (
            <div>
                {resources && resources.length ? (
                    <div>
                        <div className="toolTypeSelector__container-horizontal">
                            {resources.map(item => (
                                <div
                                    className="toolTypeSelector__container-horizontal__row"
                                    key={item.id}
                                >
                                    <div className="toolTypeSelector__container__row__item__cards">
                                        <LazyLoad offsetVertical={300}>
                                            <CheckCard
                                                onChange={e => {
                                                    this.handleToolSelect(item, e);
                                                }}
                                                className="toolTypeSelector__checkCard"
                                                checked={
                                                    this.props.resources.find(
                                                        x => x.id === item.id,
                                                    ) || false
                                                }
                                                imgUrl={`api/personGrowthResource/${item.id}/file/download`}
                                            />
                                        </LazyLoad>
                                        <div className="toolTypeSelector__checkCard__title">
                                            <Label className="toolTypeSelector__label">
                                                <a
                                                    className="toolTypeSelector__label__a"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={item.link}
                                                    title={item.name}
                                                >
                                                    {item.name}
                                                </a>
                                            </Label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {resourceType !== RESOURCE_SELECTED && (
                            <div className="toolTypeSelector__pagination">
                                <Pagination
                                    currentPageNum={pageNum}
                                    pageCount={pageCount}
                                    totalCount={totalCount}
                                    pageSize={pageSize}
                                    onPaginate={this.handlePaginate}
                                    changePageAmount={this.changePageAmount}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="toolTypeSelector-No-Data">
                        {!search
                            ? `В выбранной категории отсутствуют инструменты развития`
                            : `Совпадений не найдено`}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { search, loading } = this.state;
        return (
            <div>
                <div className="toolTypeSelector__container__selector__recommend">
                    <div className="toolTypeSelector__container__row__recommend">
                        {this.renderSearchPanel()}
                        {this.renderCategorySection()}
                    </div>
                </div>
                <div className="toolTypeSelector">
                    {!search && this.renderTypesSection()}
                    {loading ? <LocalLoader /> : this.renderResultsSection()}
                </div>
            </div>
        );
    }
}

export default ToolTypeSelectorRecommendation;
