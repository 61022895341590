import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showErrorAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { isNullOrWhitespace } from 'utils';
import { Row, Col } from 'react-flexbox-grid';
import { DataCard } from 'components/common/DataCard';
import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import TextEditor from 'components/common/TextEditor';
import Button from 'components/uikit/Button';

class Body extends Component {
    render() {
        const { subject, body, setSubject, setBody, saveEmail, canSend } = this.props;

        const hasHtmlTags = (body) => {
            const checkHtmlTags = /<[^>]*>/ig;
            return body && typeof(body) === 'string' && checkHtmlTags.test(body.trim())
        };

        const hasInvalidUriSchemes = (body) => {
            const checkUriSchemes = /\[(.*?)\]\((javascript|data|skype|sms|tel):(.*)\)/ig;
            return body && typeof(body) === 'string' && checkUriSchemes.test(body.trim());
        };

        return (
            <>
                <DataCard className="Email__Content">
                    <Row>
                        <Col xs={12}>
                            <Field filled={!isNullOrWhitespace(subject)} required>
                                <Label>Тема</Label>
                                <Input value={subject} onChange={setSubject} />
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <TextEditor value={body} showTabs={false} onChange={setBody} notEmptyText />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Button
                                size="sm"
                                disabled={!canSend || hasHtmlTags(body) || hasInvalidUriSchemes(body)}
                                onClick={this.props.sendLetter}
                            >
                                Отправить
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Row end="xs">
                                <div className="Email__Buttons">
                                    <Button
                                        size="sm"
                                        disabled={!body.trim()}
                                        onClick={this.props.showPreview}
                                    >
                                        Предварительный просмотр
                                    </Button>
                                    <Button
                                        size="sm"
                                        disabled={!subject.trim() || !body.trim()}
                                        onClick={saveEmail}
                                    >
                                        Сохранить
                                    </Button>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </DataCard>
            </>
        );
    }
}

Body.propTypes = {
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    setSubject: PropTypes.func.isRequired,
    setBody: PropTypes.func.isRequired,
    saveEmail: PropTypes.func,
    showPreview: PropTypes.func,
    canSend: PropTypes.bool,
    sendLetter: PropTypes.func,
};

const actions = { showErrorAlert, showPageLoader, hidePageLoader };

export default connect(
    null,
    actions,
)(Body);
