import React from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import Dropzone from 'react-dropzone';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import classnames from 'classnames';
import { getPersonFileUrl } from 'api';
import { isUploadFilesCountValid, isUploadFilesSizeValid } from 'libs/validators';
import { MAX_UPLOAD_FILES } from 'constants.js';
import { MAX_BYTES_PER_FILE } from 'constants.js';
import { showWarningAlert } from 'ducks/Alert';
import { getFileSize } from 'utils';
import NoData from './NoData';

const FilesInfo = props => {
    const { person, isEdit, onChange } = props;

    const {
        filesInfo: { files },
    } = person;

    const { id: personId } = person.personalInfo;

    const onAdd = async uploadFiles => {
        if (
            !isUploadFilesCountValid(
                uploadFiles.length,
                files.filter(x => x.isNew).size,
                MAX_UPLOAD_FILES,
            )
        ) {
            return showWarningAlert(
                `Максимальное количество файлов для загрузки: ${MAX_UPLOAD_FILES}`,
            );
        }

        if (!isUploadFilesSizeValid(uploadFiles, MAX_BYTES_PER_FILE)) {
            return showWarningAlert(
                `Размер одного файла не должен превышать ${getFileSize(MAX_BYTES_PER_FILE)}`,
            );
        }

        const newRecords = uploadFiles.map((file,ind) => ({
            id: -new Date().getTime()-ind,
            name: file.name,
            mimeType: file.mimeType,
            size: file.size,
            isNew: true,
            url: window.URL.createObjectURL(file),
            file,
        }));


        onChange({ ...person, filesInfo: { files: files.concat(newRecords) } }, 'FilesBlock');
    };

    const getLink = fileId => getPersonFileUrl(personId, fileId);

    const onRemove = id => () => {
        const newFiles = files
            .map(x => (x.id === id ? { ...x, deleted: true } : x))
            .filter(x => !(x.deleted && x.isNew));

        onChange({ ...person, filesInfo: { files: newFiles } }, 'FilesBlock');
    };

    const renderDropZone = ({ getRootProps, getInputProps, isDragActive }) => (
        <div
            {...getRootProps()}
            className={classnames('dropzone', {
                'dropzone--isActive': isDragActive,
            })}
        >
            <input {...getInputProps()} />
            <InfoField noIcon>
                <ProfileButton>добавить</ProfileButton>
            </InfoField>
        </div>
    );
    return (
        <div
            className={classnames('PersonalInfo', { 'PersonalInfo--bottom-margin': isEdit })}
        >
            {(files?.filter(x => !x.deleted)?.size > 0)
                ? files
                    .filter(x => !x.deleted)
                    .map(item => (
                        <InfoField
                            key={item.id}
                            icon={
                                isEdit && {
                                    icon: 'minus-circle',
                                    size: 'xs',
                                    color: 'red',
                                    onClick: onRemove(item.id),
                                }
                            }
                            noIcon={!isEdit}
                        >
                            <a href={item.isNew ? item.url : getLink(item.id)} download={item.name}>{item.name}</a>
                        </InfoField>
                    ))
                : (!isEdit && <NoData text='Нет данных' />)
            }
            {isEdit && <Dropzone onDrop={onAdd}>{renderDropZone}</Dropzone>}
        </div>
    );
};

export default FilesInfo;
