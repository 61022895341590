import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './EvaluationCardItemBlock.scss';

const EvaluationCardItemBlock = (props) => {
    const { grey, green, done, shadow, littleMargin, selected, children } = props;

    const blockClassName = classnames(
        "EvaluationCardItemBlock"
        ,{ "EvaluationCardItemBlock--Gray": grey }
        ,{ "EvaluationCardItemBlock--Green": green || done }
        ,{ "EvaluationCardItemBlock--Shadow": shadow }
        ,{ "EvaluationCardItemBlock--LittleMargin": littleMargin}
        ,{ "EvaluationCardItemBlock--Selected": selected }
    );

    return (
        <div className={blockClassName}>
            {children}
        </div>
    );
};

EvaluationCardItemBlock.propTypes = {
    grey: PropTypes.bool,
    green: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,        
      ]).isRequired,
};

export default EvaluationCardItemBlock;