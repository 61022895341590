import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './PersonCard.scss';
import PhotoBlock from "components/common/PhotoBlock";
import { getPersonPhotoUri, photoSizes } from 'api';
import { fetchPersonCardConfig } from 'ducks/PersonCardConfig';
import { declineAge } from 'utils';
import { differenceInYears } from "date-fns";
import { trimStart } from 'utils';

class PersonCard extends Component {

    

    getCurrentWorkPlace = (workPlaces) => {
        const currentWP = workPlaces.filter(x => x.isCurrentWorkPlace);
        return currentWP;
    }

    getAge = (birthDate) => {
        return differenceInYears(new Date(), birthDate);
    };

    renderPhotoBlock = (personId) => {
        const photoUri = getPersonPhotoUri(photoSizes.small, personId);
        const photoLink = getPersonPhotoUri(photoSizes.fullSize, personId);
        return (
            <PhotoBlock
                id="photo-block"
                photoUri={photoUri}
                photoLink={photoLink}
                readOnly={true}
            />
        );
    };

    renderRussianLivingPlace = (props) => {
        return (
            <>
                <div>
                    <label>Федеральный округ</label>
                    {props.federalDistrict && props.federalDistrict.name}
                </div>
                <div>
                    <label>Регион</label>
                    {props.region && props.region.name}
                </div>
                <div>
                    <label>Населенный пункт</label>
                    {props.locality && props.locality.name}
                </div>                    
            </>
        )
    }

    renderForeignLivingPlace = (props) => {
        return (
            <>
                <div>
                    <label>Гражданство</label>
                    {props.nationality?.name}
                </div>
                <div>
                    <label>Регион</label>
                    {props.foreignRegion}
                </div>
                <div>
                    <label>Населенный пункт</label>
                    {props.foreignLocality}
                </div>                    
            </>
        )
    } 

    componentDidMount() {
        this.props.fetchPersonCardConfig(this.props.userId);
    }

    renderSocialNetwork = (networkInfo) => {
        const { network, value } = networkInfo;        
        const url = this.getSocialNetworkUrl(value);

        return (
            <a
                key={network.id} 
                href={url} 
                className={"kr_" + network.code}
            >
                {" "}
            </a>
        );
    }

    getSocialNetworkUrl = (value) => {
        value = trimStart(value, 'http://');
        value = trimStart(value, 'https://');
        return `https://${value}`;
    }
    
    render() {
        const { personalInfo, workInfo, socialNetworksInfo} = this.props;
        const { lastName, firstName, middleName, isRussianCitizen, id, birthPlace, birthDate, sex, email, phone } = personalInfo;
        const { showPhotoBlock } = this.props.personCardConfig;
        let currentWP = this.getCurrentWorkPlace(workInfo.workPlaces);
        currentWP = currentWP.length !== 0 && currentWP;

        return (
            <div className="PersonCard">
                {showPhotoBlock && 
                    <div className="PersonCard__PhotoBlock">
                        {this.renderPhotoBlock(id)}
                    </div>
                }
                
                <div className="PersonCard__Col1">
                    <div className="Name">{`${lastName} ${firstName} ${middleName}`}</div>
                    {isRussianCitizen && <div className="Check">Гражданин РФ</div>}  
                    <div><label>Место рождения</label>{birthPlace}</div>
                    {!Number.isNaN(this.getAge(birthDate)) && <div><label>Возраст</label>{declineAge(this.getAge(birthDate))}</div>}
                    <div><label>Пол</label>{sex.name}</div>                        
                    {socialNetworksInfo.networks.length > 0 && 
                        <div className="SocialNetworks kr_item_socials">
                            {socialNetworksInfo.networks.map(this.renderSocialNetwork)}
                        </div>
                    }
                </div>
                
                <div className="PersonCard__Col2">
                    {isRussianCitizen ? this.renderRussianLivingPlace({...personalInfo}) : this.renderForeignLivingPlace({...personalInfo})}
                    <div className="PersonCard__Section">
                        <div><label>Место работы</label>{currentWP && currentWP[0].company}</div>
                        <div><label>Должность</label>{currentWP && currentWP[0].position}</div>
                    </div>
                    <div className="PersonCard__Section">
                        <div><label>Телефон</label>{phone}</div>
                        <div><label>E-mail</label>{email}</div>
                    </div>
                </div>
            </div>
        );
    }
}

PersonCard.propTypes = {
    showPhotoBlock: PropTypes.bool,
};

const mapStateToProps = (state) =>  {
    return {
        personCardConfig : state.personCardConfig.data,
        userId: state.auth.user.id,
    };
};
const actions = {
    fetchPersonCardConfig,
};

export default connect(mapStateToProps, actions)(PersonCard);