import { push } from 'connected-react-router';
import queryString from 'query-string'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { initiatePhoneConfirmation, confirmPhoneNumber, resetPassword } from 'api';
import contramapProps from 'components/hoc/contramapProps';
import { showErrorAlert } from 'ducks/Alert';
import { home as homeRoute } from 'routes';
import Auth from '../Auth';
import ConfirmPhoneStep from './ConfirmPhoneStep';
import ResetPasswordStep from './ResetPasswordStep';

const wizardSteps = {
    password: "password",
    phone: "phone",
};

class AccountWizard extends Component {
    state = { currentStep: 0 }

    componentDidMount = () => {
        const { steps } = this.props;

        if (!steps || steps.length === 0) {
            this.props.onFinished();
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleStepSubmit = () => {
        const { steps } = this.props;
        const { currentStep } = this.state;
        
        const nextStep = currentStep + 1;
        if (nextStep === steps.length) {
            this.props.onFinished();
        }

        this.setState({ currentStep: nextStep });
    }

    renderStep = (step) => {
        const { login, onError, onResetPassword, onRequestCode, onConfirmPhone } = this.props;

        if (step === wizardSteps.password) {
            return (
                <ResetPasswordStep
                    login={login}
                    onResetPassword={onResetPassword}
                    onSubmitSuccess={this.handleStepSubmit}
                    onError={onError}
                />
            );
        }

        return (
            <ConfirmPhoneStep
                login={login}
                onRequestCode={onRequestCode}
                onConfirmPhone={onConfirmPhone}
                onSubmitSuccess={this.handleStepSubmit}
                onError={onError}
            />
        );
    }
 
    render = () => {
        const { steps } = this.props;
        const { currentStep } = this.state;

        if (!steps || steps.length === 0) { return null; }

        return (
            <div className="signin-page">
                {this.renderStep(steps[currentStep])}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ search: state.router.location.search });

const mapDispatchToProps = (dispatch) => ({
    onError: bindActionCreators(showErrorAlert, dispatch),
    onFinished: () => dispatch(push(homeRoute.url))
});

const makeSteps = (phoneToken, passwordToken) => {
    let steps = [];
    if (phoneToken) {
        steps.push(wizardSteps.phone);
    }
    if (passwordToken) {
        steps.push(wizardSteps.password);
    }
    return steps;
}

const makeOnResetPassword = (login, passwordToken) =>
    (password) => resetPassword(login, password, passwordToken);

const makeOnRequestCode = (login, phoneToken) =>
    () => initiatePhoneConfirmation(login, phoneToken);

const makeOnConfirmPhone = (login, phoneToken) =>
    (code) => confirmPhoneNumber(login, phoneToken, code);

const toAccountWizardProps = ({ search, onError, onFinished }) => {
    const { login, passwordToken, phoneToken } = queryString.parse(search);

    const steps = makeSteps(phoneToken, passwordToken);
    const onResetPassword = makeOnResetPassword(login, passwordToken);
    const onRequestCode = makeOnRequestCode(login, phoneToken);
    const onConfirmPhone = makeOnConfirmPhone(login, phoneToken);

    return { login, steps, onResetPassword, onRequestCode, onConfirmPhone, onError, onFinished };
}

const Comp = Auth(contramapProps(toAccountWizardProps, AccountWizard));

export default connect(mapStateToProps, mapDispatchToProps)(Comp);
