import React from 'react';
import ChevronButton from 'components/uikit/ChevronButton';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RejectedRequests = (props) => {
    const renderRejectedTestsSection = () => {
        const {isRejectedTestsSectionOpen, rejectedRequests, loaded, rejectedTestsRef} = props;
        return ( loaded &&
            <div className="Tests-Block" ref={rejectedTestsRef}>
                <div className="Header">
                    <div className="Header__Text">
                        Отклоненные ({rejectedRequests.length})
                    </div>
                    <div className="Header__Right">
                        <ChevronButton className="kr_right" isUp={isRejectedTestsSectionOpen} onClick={() => props.handleToggle('isRejectedTestsSectionOpen')}/>
                    </div>
                </div>
                {isRejectedTestsSectionOpen && (
                    <div className="Tests-Block__List">
                        {rejectedRequests.map(x => renderRejectedTest(x))}
                    </div>                    
                )}
            </div>
        );
    }

    const renderRejectedTest = (test) => {
        const { status, evaluationTest } = test;
        return (
            <div className={classnames("Line", {"Line--expired": (status === "Expired"), "Line--rejected": (status === "Rejected")})} key={test.id}>
                <div className="Sub-Line">
                    <div>{evaluationTest.title}</div>
                </div>
                <div className="Sub-Line">
                    {status === "Rejected" && (
                        <div className="Sub-Line__Left--rejected">
                            <FontAwesomeIcon icon="times-circle" size="2x"></FontAwesomeIcon>
                            <div className="Sub-Line__Left">Отклонено:<span className="Sub-Line__Left--text">{` ${test.reviewRejectionComment}`}</span></div>
                        </div>   
                    )}
                    
                    {status === "Expired" && (
                        <div className="Sub-Line__Left--expired">
                            <FontAwesomeIcon icon="clock" size="2x"></FontAwesomeIcon>
                            <div>Период действия истек</div>
                        </div> 
                    )}                
                </div>
            </div>
        )        
    }

    return (
        props.rejectedRequests && !!props.rejectedRequests.length
        ? <div className="Person-Tests">
            {renderRejectedTestsSection()}
        </div>
        : null
    );
}

export default RejectedRequests;