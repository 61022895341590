import { all } from 'redux-saga/effects';
import { saga as catalogSaga } from 'ducks/Catalog';
import { saga as personSaga } from 'ducks/Person';
import { saga as searchSaga } from 'ducks/Search';
import { saga as alertSaga } from 'ducks/Alert';
import { saga as userSaga } from 'ducks/User';
import { saga as usersSaga } from 'ducks/Users';
import { saga as groupSaga } from 'ducks/Group';
import { saga as groupsSaga } from 'ducks/Groups';
import { saga as authSaga } from 'ducks/Auth';
import { saga as personBlockSaga } from 'ducks/PersonBlock';
import { saga as acceptModalSaga } from 'ducks/AcceptModal';
import { saga as attributesSaga } from 'ducks/Attributes';
import { saga as attributeSaga } from 'ducks/Attribute';
import { saga as dictionarySaga } from 'ducks/Dictionary';
import { saga as configSaga } from 'ducks/Config';
import { saga as importSaga } from 'ducks/Import';
import { saga as issuesSaga } from 'ducks/Issues';
import { saga as analyticsSaga } from 'ducks/Analytics';
import { saga as personCardConfigSaga } from 'ducks/PersonCardConfig';
import { saga as accessSaga } from 'ducks/Access';
import { saga as externalSearchSaga } from 'ducks/ExternalSearch';
import { saga as teamsSaga } from 'ducks/Teams';
import { saga as favoritesSaga } from 'ducks/Favorites';
import { saga as widgetSaga } from 'ducks/Widget';
import { saga as eventsSaga } from 'ducks/Events';
import { saga as ProgressCartSaga } from 'ducks/ProgressCart';
import { saga as evaluationSaga } from 'ducks/Evaluation';
import { saga as basketsSaga } from 'ducks/Baskets';
import { saga as wishlistsSaga } from 'ducks/Wishlists';
import { saga as filterSearchSaga } from 'ducks/FilterSearch';
import { saga as testProvidersSaga } from 'ducks/TestProviders';
import { saga as personCommentsSaga } from 'ducks/PersonComments';
import { saga as TeamReportSaga } from 'ducks/TeamReport';
import { saga as DashboardSaga } from 'ducks/Dashboard';
import { saga as LoggerSaga } from 'ducks/Logger';

export default function* rootSaga() {
    yield all([
        catalogSaga(),
        personSaga(),
        searchSaga(),
        alertSaga(),
        userSaga(),
        usersSaga(),
        groupSaga(),
        groupsSaga(),
        authSaga(),
        personBlockSaga(),
        acceptModalSaga(),
        attributesSaga(),
        attributeSaga(),
        dictionarySaga(),
        configSaga(),
        importSaga(),
        issuesSaga(),
        analyticsSaga(),
        personCardConfigSaga(),
        accessSaga(),
        externalSearchSaga(),
        teamsSaga(),
        favoritesSaga(),
        widgetSaga(),
        eventsSaga(),
        ProgressCartSaga(),
        evaluationSaga(),
        basketsSaga(),
        wishlistsSaga(),
        filterSearchSaga(),
        testProvidersSaga(),
        personCommentsSaga(),
        TeamReportSaga(),
        DashboardSaga(),
        LoggerSaga()
    ]);
}
