import React from 'react';
import Item from './Item';

export const List = props => {
    const { items, onClick, labelField } = props;
    return (
        <ul>
            {items.map((item, index) => (
                <Item labelField={labelField} key={index} item={item} onClick={onClick} />
            ))}
        </ul>
    );
};

export default List;
