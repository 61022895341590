import React from 'react';
import { connect } from 'react-redux';
import { adminSignIn as signInAdminRoute } from 'routes';
import Logo from 'components/common/Logo';
import LoginBox from './LoginBox';
import './SignIn.scss';
const SignIn = (props) => {
    return (
        <div className="SignInPageBackground">
            <div className="SignInPage">
                <div className="SignInPageLeft">
                    <div className="SignInPageLeft__Title">
                        <h1 className="SignInPage__LogoWrapper">
                            <Logo svgLogo size="lg" transparent />
                        </h1>
                        <h2 className="SignInTitle__Welcome">
                            {props.isAdmin
                                ? 'Страница Администратора'
                                : 'Добро пожаловать в «Интеллектуальную Систему Кадрового Резерва»!'}
                        </h2>
                    </div>
                </div>
                <div className="SignInForm">
                    <div className="SignInForm__LoginBox">
                        <LoginBox />
                    </div>
                </div>
            </div>
        </div>
    );
};

const props = (state) => {
    return {
        isAdmin:
            state.router &&
            state.router.location &&
            state.router.location.pathname === signInAdminRoute.buildUrl(),
    };
};

export default connect(props)(SignIn);
