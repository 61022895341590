import React from 'react';
import { connect } from 'react-redux';
import InfoField from 'components/Lk/Uikit/InfoField';
import classnames from 'classnames';
import NoData from './NoData';

const FamilyInfo = props => {
    const { person, childrenStatuses, isEdit, onChange,familyStatuses } = props;

    const {
        familyInfo: { childrenStatus, familyStatus },
        personalInfo,
    } = person;

    const onChangeState = name => value => {
        onChange(
            { ...person, familyInfo: { ...person.familyInfo, [name]: value } },
            'PersonalInfoBlock',
        );
    };

    return (
        <div className={classnames('PersonalInfo', { 'PersonalInfo--bottom-margin': isEdit })}>
            {!isEdit && !person.familyInfo.childrenStatus && !person.familyInfo.familyStatus ? (
                <NoData text="Нет данных" />
            ) : (
                <>
                    <InfoField
                        value={familyStatus && familyStatus.id}
                        title="Статус"
                        isEdit={isEdit}
                        inputProps={{
                            type: 'select',
                            items: familyStatuses[personalInfo?.sex?.id || 'All'],
                            onChange: onChangeState('familyStatus'),
                            isClearable: true,
                        }}
                        noIcon
                    >
                        {familyStatus && familyStatus.name}
                    </InfoField>
                    <InfoField
                        title="Количество детей"
                        isEdit={isEdit}
                        value={childrenStatus && childrenStatus.id}
                        inputProps={{
                            type: 'select',
                            items: childrenStatuses,
                            onChange: onChangeState('childrenStatus'),
                            isClearable: true,
                        }}
                        noIcon
                    >
                        {childrenStatus && childrenStatus.name}
                    </InfoField>
                </>
            )}
        </div>
    );
};
const mapStateToProps = state => ({
    familyStatuses: state.catalogs.familyStatuses.sexData,
    childrenStatuses: state.catalogs.childrenStatus.data,
});

const actions = {};

export default connect(
    mapStateToProps,
    actions,
)(FamilyInfo);
