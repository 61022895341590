import React, { Component } from 'react';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import InfoField from 'components/Lk/Uikit/InfoField';
import Wishlists from 'components/Lk/Shared/Search/ManageWishlists/Wishlists/Wishlists';
import { connect } from 'react-redux';
import {
    getAllWishlists,
    createNewWishList,
    setLastWishlistId,
    updatePersonWishlists,
} from 'ducks/Wishlists';
import Icons from 'components/Lk/Uikit/Icons';
import NewWishlist from './NewWishlist';
import UnloadBlocker from 'components/Lk/Common/UnloadBlocker/UnloadBlocker';
import './ManageFavorites.scss';

class ManageFavorites extends Component {
    state = {
        loading: false,
        paging: { pageNum: 1, pageSize: 15 },
        wishLists: [],
        clearList: true,
        hasMore: true,
        allChecked: false,
        myChecked: true,
        searchTerm: '',
        newWishlist: false,
        addTo: [],
        deleteFrom: [],
        isSaveWarning: false,
        allIds: [],
        defaultSelected: [],
        currentSelected: [],
        onConfirmWarningModal: () => {},
        tryCreateNewWishlist: false,
        newWishlistUpdated: false,
    };

    toggleFilter = filter => () => {
        const { defaultSelected, allIds } = this.state;
        this.setState({ [filter]: !this.state[filter] });
        if (filter === 'allChecked') {
            if (!this.state[filter]) {
                this.setState({
                    currentSelected: [...allIds],
                    addTo: [...allIds.filter(x => !defaultSelected.includes(x))],
                    deleteFrom: [],
                });
            } else {
                this.setState({
                    currentSelected: [],
                    addTo: [],
                    deleteFrom: [...allIds.filter(x => defaultSelected.includes(x))],
                });
            }
        } else if (filter === 'myChecked') {
            this.setState({
                myChecked: !this.state[filter],
            });
        }
    };

    toggleNewWishlist = state => {
        this.setState({ newWishlist: state });
    };

    handleChanges = (newWishlists, wishlistId, add = true) => {
        const { defaultSelected, addTo, deleteFrom } = this.state;
        this.setState({ currentSelected: newWishlists });

        if (add) {
            !defaultSelected.find(x => x === wishlistId)
                ? this.setState({ addTo: [...addTo, wishlistId] })
                : this.setState({ deleteFrom: deleteFrom.filter(x => x !== wishlistId) });
        } else {
            defaultSelected.find(x => x === wishlistId)
                ? this.setState({ deleteFrom: [...deleteFrom, wishlistId] })
                : this.setState({ addTo: addTo.filter(x => x !== wishlistId) });
        }
    };

    header = () => {
        const { allChecked, myChecked, searchTerm, newWishlist } = this.state;
        const { createNewWishList } = this.props;
        return !newWishlist ? (
            <div className="LKManageFavorites__Header">
                <div>Выберите подборки</div>
                <div className="LKManageFavorites__Search">
                    <InfoField
                        isEdit
                        title="Поиск подборки"
                        onChange={this.handleSearchtermChange}
                        value={searchTerm}
                        placeholder="Введите наименование подборки..."
                        noIcon
                    />
                </div>
                <div className="LKManageFavorites__Filters">
                    <div
                        className="LKManageFavorites__Filters--check-all"
                        onClick={this.toggleFilter('allChecked')}
                    >
                        <Icons name={!allChecked ? 'checkbox' : 'checkboxChecked'} />
                        <span className="LKManageFavorites__Filters--check-box">Выделить все</span>
                    </div>
                    <div
                        className="LKManageFavorites__Filters--check-all"
                        onClick={this.toggleFilter('myChecked')}
                    >
                        <Icons name={myChecked ? 'checkboxChecked' : 'checkbox'} />
                        <span className="LKManageFavorites__Filters--check-box">Мои подборки</span>
                    </div>
                </div>
            </div>
        ) : (
            <NewWishlist
                createWishList={createNewWishList}
                tryCreateNewWishlist={this.state.tryCreateNewWishlist}
                handleStateChange={this.handleStateChange}
            />
        );
    };

    additionalActions = [
        {
            id: 'addWishlist',
            name: '+ Создать подборку',
            action: () => this.toggleNewWishlist(true),
        },
    ];

    loadLists = (clearList = true) => {
        const { paging, searchTerm, myChecked, wishLists } = this.state;
        const { personId } = this.props;
        this.setState({
            loading: true,
            clearList,
            wishLists: clearList ? [] : wishLists,
            ...(clearList ? { paging: { pageNum: 1, pageSize: 15 } } : { paging }),
        });
        this.props.getAllWishlists({
            ...(clearList ? { paging: { pageNum: 1, pageSize: 15 } } : { paging }),
            idList: true,
            searchTerm,
            filter: { withIncludedPerson: myChecked, personId: personId },
        });
    };

    handleLoadMore = () => {
        this.loadLists(false);
        this.setState({ hasMore: false });
    };

    handleStateChange = state => {
        this.setState(state);
    };

    handleSearchtermChange = e => {
        this.setState({ searchTerm: e });
    };

    closeModal = (forceClose = false) => fromConfirm => {
        const { newWishlist, newWishlistUpdated, tryCreateNewWishlist } = this.state;

        if (!newWishlist) {
            const { addTo, deleteFrom } = this.state;
            if ((addTo.length !== 0 || deleteFrom.length !== 0) && !forceClose && !fromConfirm) {
                this.setState({ isSaveWarning: true, onConfirmWarningModal: this.confirmSaveWarning });
                return;
            }
            this.setState({ searchTerm: '', addTo: [], deleteFrom: [], currentSelected: [] });
            this.props.onClose();
        } else {
            if (!tryCreateNewWishlist && newWishlistUpdated && !forceClose) {
                this.setState({ isSaveWarning: true, onConfirmWarningModal: this.confirmSaveWarning });
                return;
            }
            this.setState({tryCreateNewWishlist: false, newWishlistUpdated: false });
            this.toggleNewWishlist(false);
        }
    };

    onConfirmModal = () => {
        const { newWishlist } = this.state;

        if (!newWishlist) {
            const { addTo, deleteFrom } = this.state;
            this.props.updatePersonWishlists({ personId: this.props.personId, addTo, deleteFrom });
        } else {
            this.setState({tryCreateNewWishlist: true});
        }
    };

    closeSaveWarning = () => {
        this.setState({ isSaveWarning: false, onConfirmWarningModal: () => {} });
    };

    confirmSaveWarning = () => {
        this.closeModal(true)();
        this.closeSaveWarning();
    };

    handleCreatedWishlist = id => {
        const { addTo, currentSelected } = this.state;
        this.setState({ addTo: [...addTo, id], currentSelected: [...currentSelected, id] });
    };

    componentDidMount() {
        this.props.isOpen && this.loadLists(true);
    }

    componentDidUpdate(prevProps, prevState) {
        const { list, isOpen, personWishlists, lastWishlistId } = this.props;
        const { wishLists, paging, clearList, searchTerm, myChecked, currentSelected } = this.state;

        if (prevProps.isOpen !== isOpen && isOpen) {
            const selectedWishlists = personWishlists ? personWishlists.map(x => x.id) : [];
            this.setState({
                allChecked: false,
                myChecked: true,
                searchTerm: '',
                newWishlist: false,
                defaultSelected: selectedWishlists,
                currentSelected: selectedWishlists,
            });
            this.loadLists();
        }

        if (list && prevProps.list !== list && isOpen) {
            let newWishlists = !clearList ? [...wishLists, ...list.wishLists] : [...list.wishLists];
            this.setState({
                loading: false,
                wishLists: newWishlists,
                allIds: [...list.meta.ids],
                paging: {
                    ...paging,
                    pageNum:
                        list.meta.pageCount > paging.pageNum ? paging.pageNum + 1 : paging.pageNum,
                },
                hasMore: list.meta.pageCount > paging.pageNum,
            });
        }

        if (prevProps.personWishlists !== personWishlists) {
            const selectedWishlists = personWishlists ? personWishlists.map(x => x.id) : [];
            this.setState({
                defaultSelected: selectedWishlists,
                currentSelected: currentSelected.concat(selectedWishlists),
            });
        }

        if (prevState.myChecked !== myChecked) {
            this.loadLists(true);
        }

        if (prevState.searchTerm !== searchTerm) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.loadLists(true);
            }, 200);
        }

        if (lastWishlistId) {
            this.setState(
                { paging: { pageNum: 1, pageSize: 15 }, wishLists: [], loading: false },
                () => this.loadLists(),
            );
            this.handleCreatedWishlist(lastWishlistId);
            this.props.setLastWishlistId({ lastWishlistId: null });
        }
    }

    render() {
        const {
            loading,
            wishLists,
            hasMore,
            newWishlist,
            currentSelected,
            isSaveWarning,
            addTo,
            deleteFrom,
            onConfirmWarningModal
        } = this.state;
        const { isOpen } = this.props;

        const isSaving = addTo.length !== 0 || deleteFrom.length !== 0;

        return (
            <UnloadBlocker isSaving={isSaving}>
                <div className="LKManageFavorites">
                    <ModalPopup
                        isModalOpen={isOpen}
                        header={this.header()}
                        onModalClose={this.closeModal()}
                        confirm={this.onConfirmModal}
                        actionButtons={['Отменить', 'Сохранить']}
                        showCloseIcon
                        withFooter
                        fitScreenSize
                        infiniteWrapper
                        additionalActions={this.additionalActions}
                        enabled={isSaving}
                        fixedButtons
                    >
                        {!newWishlist && (
                            <Wishlists
                                selectedWishlists={currentSelected}
                                setSelectedWishlists={this.handleChanges}
                                handleChanges={this.handleChanges}
                                loading={loading}
                                wishLists={wishLists}
                                handleLoadMore={this.handleLoadMore}
                                hasMore={hasMore}
                                mode={'view'}
                                handleStateChange={this.handleStateChange}
                                loadLists={this.loadLists}
                                withoutComments={true}
                            />
                        )}
                    </ModalPopup>
                </div>
                <ModalPopup
                    isModalOpen={isSaveWarning}
                    header={'Изменения не будут сохранены'}
                    headerInput={undefined}
                    confirm={onConfirmWarningModal}
                    onModalClose={this.closeSaveWarning}
                    actionButtons={['Отменить', 'Продолжить']}
                    showCloseIcon={false}
                />
                {newWishlist && 
                <ModalPopup
                    isModalOpen={isOpen}
                    header={this.header()}
                    onModalClose={this.closeModal()}
                    confirm={this.onConfirmModal}
                    actionButtons={['Отменить', 'Подтвердить']}
                    showCloseIcon
                    withFooter
                    fitScreenSize
                    hideOverlay
                    fixedButtons
                    enabled={this.state.newWishlistUpdated}
                />}
            </UnloadBlocker>
        );
    }
}

const mapStateToProps = state => {
    return {
        list: state.wishlists.allWishLists,
        lastWishlistId: state.wishlists.lastWishlistId
    };
};

const actions = {
    getAllWishlists,
    createNewWishList,
    setLastWishlistId,
    updatePersonWishlists,
};

export default connect(
    mapStateToProps,
    actions,
)(ManageFavorites);
