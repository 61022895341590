import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import {
    getContentCategories,
    getContentByIdFromEditor,
    createNewCategory,
    updateCategory,
    updateContent,
} from 'api';
import { withRouter } from 'react-router';
import Loader from 'components/common/Loader';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Select from 'components/uikit/Select';
import ButtonsGroup from 'components/uikit/ButtonsGroup';
import Button from 'components/uikit/Button';
import './InstructionsEdit.scss';
import { CATEGORY_TITLE_LIMIT } from './constants';
import { isNullOrWhitespace } from 'utils';
import { logUIError } from 'api';

class CategoryEditor extends Component {
    state = {
        selectedItem: {
            id: null,
            title: '',
            parentId: null,
            position: 0,
        },
        isBusy: false,
        selectedGroups: [],
        selectedRoles: [],
        affectedItems: [],
        modifiedAffectedItems: [],
        groups: [],
    };

    changeTitle = (e) => {
        this.setState({
            selectedItem: {
                ...this.state.selectedItem,
                title: e.target.value.slice(0, CATEGORY_TITLE_LIMIT),
            },
        });
    };

    changeGroups = (e) => {
        this.setState({ selectedGroups: e || [] });
        this.modifyAffectedItems();
    };

    changeRoles = (e) => {
        const selectedRoles = e ? [...e] : [];
        this.setState({ selectedRoles });
        this.modifyAffectedItems();
    };

    checkGroup = (groups) => {
        const { affectedItems } = this.state;
        const modifyGroups = affectedItems.map((x) => {
            return groups && groups.length !== 0
                ? x.groupIds.filter((g) => groups.map((z) => z.id).includes(g))
                : [];
        });

        const emptyGroups = modifyGroups.filter((x) => x.length === 0);
        return emptyGroups;
    };

    modifyAffectedItems = () => {
        const { affectedItems, selectedGroups, selectedRoles } = this.state;
        const modifiedAffectedItems = affectedItems.map((x) => {
            return {
                ...x,
                roleIds: selectedRoles.length !== 0 ? [...selectedRoles.map((z) => z.id)] : [],
                groupIds: selectedGroups.length !== 0 ? [...selectedGroups.map((z) => z.id)] : [],
            };
        });

        this.setState({ modifiedAffectedItems });
    };

    checkModifiedGroups = () => {
        const emptyGroups = this.state.modifiedAffectedItems.filter((x) => x.groupIds.length === 0);
        return emptyGroups;
    };

    getUpdatedItems = () => {
        const { affectedItems, modifiedAffectedItems } = this.state;

        if (modifiedAffectedItems.length === 0) {
            return [];
        }

        for (const item of affectedItems) {
            const modifiedItem = modifiedAffectedItems.find((x) => x.id === item.id);
            if (
                modifiedItem.roleIds.length === item.roleIds.length &&
                modifiedItem.groupIds.length === item.groupIds.length
            ) {
                modifiedAffectedItems.splice(modifiedAffectedItems.indexOf(modifiedItem), 1);
            }
        }
        return modifiedAffectedItems;
    };

    saveCategory = async () => {
        const { selectedItem, selectedRoles, selectedGroups } = this.state;
        const { onItemClick, saveCallback, showSuccessAlert, treeData } = this.props;

        const itemsToUpdate = this.getUpdatedItems();
        const roleIds = selectedRoles ? selectedRoles.map((x) => x.id) : [];
        const groupIds = selectedGroups ? selectedGroups.map((x) => x.id) : [];
        let res = {};
        try {
            this.setState({ isBusy: true });
            await this.updateChildren(itemsToUpdate);
            res = selectedItem.id
                ? await updateCategory({ ...selectedItem, roleIds, groupIds })
                : await createNewCategory({ ...selectedItem, roleIds, groupIds });
        } catch (e) {
            this.props.showErrorAlert('Ошибка сохранения категории');
        } finally {
            saveCallback && (await saveCallback());
            this.setState({ isBusy: false });
            onItemClick({ ...res.data, itemsInCategory: treeData.length + 1 });
            showSuccessAlert('Сохранение прошло успешно');
        }
    };

    updateChildren = async (itemsToUpdate) => {
        try {
            if (itemsToUpdate.length !== 0) {
                for (const item of itemsToUpdate) {
                    if (item.isArticle) {
                        const article = await getContentByIdFromEditor(item.id);
                        await updateContent({
                            ...article.data,
                            groups: item.groupIds,
                            roles: item.roleIds,
                        });
                    } else {
                        await updateCategory({ item });
                    }
                }
            }
        } catch (e) {
            return e;
        }
    };

    setAffectedItems = async (parentId) => {
        if (this.state.selectedItem?.id) {
            const childrenData = await getContentCategories(parentId);
            const children = childrenData.data;
            if (children && children.length !== 0) {
                children.map((x) => {
                    this.setState({ affectedItems: [...this.state.affectedItems, x] });

                    if (x.hasChildren) {
                        this.setAffectedItems(x.id);
                    }

                    return true;
                });
            } else {
                return [];
            }
        }
        return [];
    };

    fixedSelectStyles = {
        multiValueLabel: (base, state) => {
            return state.data.isFixed ? { ...base, paddingRight: '5px' } : base;
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
    };

    selectAllGroups = () => {
        const { groups } = this.state;
        this.changeGroups(groups);
    };

    setItemAccess = () => {
        const { selectedItem } = this.state;
        const {
            currentUser: { groupId },
            centerGroupId,
        } = this.props;
        const canEdit =
            selectedItem?.groupIds?.filter((x) => x !== groupId).length === 0 ||
            !selectedItem?.id ||
            groupId === centerGroupId;
        this.props.setItemFullAccess(canEdit);
    };

    fetchCategory = async () => {
        const { id } = this.props.match.params;
        const { setLoadedItem, treeData } = this.props;

        if (id === 'new') {
            this.setState({
                selectedItem: {
                    id: null,
                    title: '',
                    isArticle: false,
                    parentId: null,
                },
                isBusy: false,
            });
        } else {
            const category = treeData.find((x) => x?.id === id);
            this.setState({ selectedItem: category, isBusy: false });
            setLoadedItem(category);

            if (!category) {
                try {
                    const { location } = window;
                    const errorScope = {
                        pathName: location.pathname + location.search,
                        errorTitle: 'Категория не найдена',
                        errorInfo: `can't find category with id '${id}'`,
                    };
                    logUIError(errorScope);
                } catch (e) {
                    showErrorAlert('Ошибка логирования');
                }
                showErrorAlert('Категория не найдена');
            }
        }
    };

    mappedGroups = () => {
        const { selectedItem } = this.state;
        const { groups } = this.props;
        const selectedGroups =
            selectedItem && selectedItem.groupIds
                ? groups.filter((x) => selectedItem.groupIds.includes(x.id))
                : [];
        return selectedGroups;
    };

    mappedRoles = () => {
        const { selectedItem } = this.state;
        const { roles } = this.props;
        const selectedRoles =
            selectedItem && selectedItem.roleIds
                ? roles.filter((x) => selectedItem.roleIds.includes(x.id))
                : [];

        return selectedRoles;
    };

    componentDidMount = async () => {
        const {
            groups,
            treeLoading,
            centerGroupId,
            currentUser: { groupId },
        } = this.props;
        const { id } = this.props.match.params;
        this.setState({ groups: groups.filter((x) => !x.isScope) });

        if (!treeLoading) {
            await this.fetchCategory();
            const selectedGroups = this.mappedGroups();
            const selectedRoles = this.mappedRoles();
            this.setState({ selectedGroups, selectedRoles, affectedItems: [] });
            this.setAffectedItems(id);
            if (groupId !== centerGroupId && !id) {
                this.selectAllGroups();
            }
            this.setItemAccess();
        }
    };

    async componentDidUpdate(prevProps, prevState) {
        const { selectedGroups, selectedRoles } = this.state;
        const {
            groups,
            treeLoading,
            centerGroupId,
            currentUser: { groupId },
        } = this.props;
        const { id } = this.props.match.params;
        if (
            (prevProps?.match?.params?.id !== id || prevProps?.treeLoading !== treeLoading) &&
            !treeLoading
        ) {
            await this.fetchCategory();
            const selectedGroups = this.mappedGroups();
            const selectedRoles = this.mappedRoles();

            this.setState({ groups: groups.filter((x) => !x.isScope) });
            this.setState({ selectedGroups, selectedRoles, affectedItems: [] });
            this.setAffectedItems(id);
            if (groupId !== centerGroupId && !id) {
                this.selectAllGroups();
            }
            this.setItemAccess();
        }

        if (
            JSON.stringify(prevState.selectedGroups) !== JSON.stringify(selectedGroups) ||
            JSON.stringify(prevState.selectedRoles) !== JSON.stringify(selectedRoles)
        ) {
            this.modifyAffectedItems();
        }
    }

    render() {
        const { title } = this.state.selectedItem || '';
        const { isBusy, selectedGroups, selectedRoles, groups } = this.state;
        const { roles, itemFullAccess, treeLoading } = this.props;

        const buttons = [
            <Button size="sm" color="primary" onClick={this.saveCategory} disabled={!title?.trim()}>
                Сохранить
            </Button>,
        ];

        return (
            <>
                {this.state.selectedItem ? (
                    <Grid fluid>
                        <div className="CategoryEditor">
                            <Row>
                                <Col md={12}>
                                    <Field>
                                        <div>
                                            <h1>Редактор категорий</h1>
                                        </div>
                                    </Field>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Field filled={!isNullOrWhitespace(title)} required>
                                        <Label>Название категории</Label>
                                        <Input
                                            className=""
                                            value={title}
                                            onChange={this.changeTitle}
                                            placeholder="Введите название категории"
                                            disabled={!itemFullAccess}
                                        />
                                        <div className="footer-message">
                                            {`Осталось символов:  ${
                                                CATEGORY_TITLE_LIMIT - (title ? title.length : 0)
                                            }`}
                                        </div>
                                    </Field>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Field filled={selectedGroups?.length !== 0}>
                                        <Label>
                                            <div>Группа</div>
                                        </Label>
                                        <Select
                                            id="groupsSelect"
                                            value={selectedGroups}
                                            options={groups}
                                            onChange={this.changeGroups}
                                            catalog
                                            isMulti
                                            isDisabled={!itemFullAccess}
                                            placeholder={
                                                selectedGroups?.length === 0
                                                    ? 'Категория доступна всем группам'
                                                    : ''
                                            }
                                        />
                                    </Field>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Field filled={selectedRoles?.length !== 0}>
                                        <Label>Роль</Label>
                                        <Select
                                            styles={this.fixedSelectStyles}
                                            value={selectedRoles}
                                            options={roles}
                                            onChange={this.changeRoles}
                                            catalog
                                            isMulti
                                            isDisabled={!itemFullAccess}
                                            placeholder={
                                                selectedRoles?.length === 0
                                                    ? 'Категория доступна всем ролям'
                                                    : ''
                                            }
                                        />
                                    </Field>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {itemFullAccess && (
                                        <ButtonsGroup position="end" buttons={buttons} />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Grid>
                ) : (
                    <div className="ErrorBox">
                        <h1>Запрашиваемая категория не найдена</h1>
                    </div>
                )}
                <Loader absolute show={isBusy || treeLoading} />
            </>
        );
    }
}

const mapStateToProps = (state) => ({ currentUser: state.auth.user });
const actions = { showErrorAlert, showSuccessAlert };
export default withRouter(connect(mapStateToProps, actions)(CategoryEditor));
