import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { search, fetch as fetchUserAction } from 'ducks/UserActions';
import { getAdminUserActions } from 'api';
import DataGrid from 'components/common/DataGrid';
import { formatDate } from 'utils';
import { userActionStatusName, userActionTypeName } from 'constants.js';
import ChevronButton from 'components/uikit/ChevronButton';
import UserActionCard from 'components/Admin/UserActions/UserActionCard';
import Select from 'components/uikit/Select';
import Button from 'components/uikit/Button';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import DatePicker from 'components/uikit/DatePicker';
import CheckField from 'components/uikit/CheckField';
import { isSecurityAdmin, isITAdmin } from 'rightsController';
import moment from 'moment';

const statusNameResolver = (statusValue) => {
    const result = userActionStatusName.find((x) => x.value === statusValue);
    return <div className={result && result.class}>{result && result.name}</div>;
};

const typeNameResolver = (typeValue) => {
    const result = userActionTypeName.find((x) => x.value === typeValue);
    return <div>{result && result.name}</div>;
};

const filterFromDate = moment().startOf('day').subtract(7, 'd').toDate();
const filterToDate = moment().endOf('day').toDate();

const defaultFilter = {
    fromCreatedDate: filterFromDate,
    toCreatedDate: filterToDate,
    status: [],
    type: [],
    excludeHighFrequency: true
};

class UserActions extends Component {
    state = {
        expanded: {},
        selectedUserAction: null,
        userActions: [],
        pageCount: 0,
        foundCount: 0,
        paging: { pageNum: 1, pageSize: 50 },
        filter: defaultFilter,
        sorting: {
            created: 'desc',
        },
    };

    columns = [
        {
            Header: 'Дата',
            accessor: 'created',
            maxWidth: 200,
            Cell: ({ original }) => formatDate(original.created, 'DD-MM-YYYY kk:mm:ss'),
        },
        { Header: 'Действие', accessor: 'actionName', sortable: false },
        {
            Header: 'Статус',
            accessor: 'status',
            maxWidth: 150,
            Cell: ({ original }) => statusNameResolver(original.status),
        },
        {
            Header: 'Тип',
            accessor: 'type',
            maxWidth: 150,
            Cell: ({ original }) => typeNameResolver(original.type),
        },
        {
            expander: true,
            Expander: ({ isExpanded }) => {
                return <ChevronButton isUp={isExpanded} />;
            },
            headerStyle: { textAlign: 'left' },
        },
    ];

    isAdmin = isITAdmin(this.props.currentUser) || isSecurityAdmin(this.props.currentUser);

    handleRowExpanded = (newExpanded, [index]) => {
        const { showPageLoader, hidePageLoader, showErrorAlert, isSupport } = this.props;

        this.setState(
            {
                expanded: { [index]: newExpanded[index] },
            },
            async () => {
                if (newExpanded[index]) {
                    try {
                        showPageLoader();
                        const { userActions } = this.state;
                        const selectedUserAction = await fetchUserAction(
                            userActions[index].id,
                            isSupport,
                        );
                        this.setState({ selectedUserAction });
                    } catch (e) {
                        showErrorAlert('Ошибка загрузки действия пользователя.');
                    } finally {
                        hidePageLoader();
                    }
                }
            },
        );
    };

    addDetailCard = (row, selectedUserAction, isSupport) => {
        if (selectedUserAction && row.original.id === selectedUserAction.id) {
            return <UserActionCard {...selectedUserAction} isSupport={isSupport} />;
        }
        return null;
    };

    clearFilter = () => {
        const { paging } = this.state;
        this.setState(
            { paging: { ...paging, pageNum: 1 }, filter: { ...defaultFilter }, expanded: {} },
            () => {
                this.searchActions();
            },
        );
    };

    searchActions = async (resetPaging = false) => {
        const { paging, filter, sorting } = this.state;
        const { showPageLoader, hidePageLoader, showErrorAlert, userId } = this.props;

        const newFilter = {
            ...filter,
            status: filter.status ? filter.status.map((x) => x.value) : [],
            type: filter.type ? filter.type.map((x) => x.value) : [],
        };
        const criteria = {
            paging: resetPaging ? { ...paging, pageNum: 1 } : paging,
            filter: { ...newFilter, userIds: [userId] },
            sorting,
        };

        resetPaging && this.setState({ paging: { ...paging, pageNum: 1 } });

        try {
            showPageLoader();
            const data = this.isAdmin
                ? await search(criteria)
                : await getAdminUserActions(userId, criteria);

            if (this.isAdmin) {
                const { userActions, pageCount, foundCount } = data;
                this.setState({ userActions, pageCount, foundCount, expanded: {} });
            } else {
                this.setState({
                    userActions: data.data.payload,
                    pageCount: data.data.meta.pageCount,
                    foundCount: data.data.meta.foundCount,
                    expanded: {},
                });
            }
        } catch (e) {
            showErrorAlert('Ошибка загрузки действий пользователя.');
        } finally {
            hidePageLoader();
        }
    };

    onPageChange = (pageIndex) => {
        this.setState({ expanded: {}, paging: { ...this.state.paging, pageNum: pageIndex + 1 } });
    };

    onSortedChange = (sorted) => {
        this.setState({
            expanded: {},
            sorting: { [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        });
    };

    onFilterChange = (key, value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [key]: value,
            },
        });
    };

    componentDidMount() {
        this.searchActions();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            paging: { pageNum },
            sorting,
        } = this.state;

        if (
            prevState.paging.pageNum !== pageNum ||
            JSON.stringify(prevState.sorting) !== JSON.stringify(sorting)
        ) {
            this.searchActions();
        }
    }

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, () =>
            this.searchActions(),
        );
    };

    render() {
        const {
            userActions,
            paging,
            pageCount,
            foundCount,
            expanded,
            selectedUserAction,
            filter,
        } = this.state;

        const { header, isSupport } = this.props;

        const subComponent = {
            SubComponent: (row) => this.addDetailCard(row, selectedUserAction, isSupport),
        };

        return (
            <div className="UserActions">
                {header}
                <div className="UserActions__Filter">
                    <Field className="UserActions__Filter--full-width">
                        <Label>Статус</Label>
                        <Select
                            options={userActionStatusName.map((x) => ({
                                value: x.value,
                                label: x.name,
                            }))}
                            value={filter.status}
                            onChange={(e) => this.onFilterChange('status', e)}
                            isMulti
                        />
                    </Field>
                    <Field className="UserActions__Filter--full-width">
                        <Label>Тип</Label>
                        <Select
                            options={userActionTypeName.map((x) => ({
                                value: x.value,
                                label: x.name,
                            }))}
                            value={filter.type}
                            onChange={(e) => this.onFilterChange('type', e)}
                            isMulti
                        />
                    </Field>
                    <Field>
                        <Label>Дата с</Label>
                        <DatePicker
                            selected={filter.fromCreatedDate}
                            onChange={(e) => this.onFilterChange('fromCreatedDate', e)}
                        />
                    </Field>
                    <Field>
                        <Label>Дата по</Label>
                        <DatePicker
                            selected={filter.toCreatedDate}
                            onChange={(e) => this.onFilterChange('toCreatedDate', e)}
                        />
                    </Field>
                    <Field className="UserActions__Filter--checkbox">
                        <CheckField
                                    id="excludeHighFrequency"
                                    title="Убрать высокочастотные запросы"
                                    checked={filter.excludeHighFrequency}
                                    onChange={(e) => this.onFilterChange('excludeHighFrequency', e)}
                                    size="100"
                                />
                    </Field>
                    <Field>
                        <Label>&nbsp;</Label>
                        <Button size="xs" onClick={() => this.searchActions(true)}>
                            Применить
                        </Button>
                    </Field>
                    <Field>
                        <Label>&nbsp;</Label>
                        <Button size="xs" onClick={this.clearFilter}>
                            Очистить фильтр
                        </Button>
                    </Field>
                </div>
                <div className="UserActions__Table">
                    <DataGrid
                        data={userActions}
                        foundCount={foundCount}
                        columns={this.columns}
                        showPagination
                        showPageSizeOptions={false}
                        onSortedChange={this.onSortedChange}
                        pages={pageCount}
                        page={paging.pageNum}
                        onPageChange={this.onPageChange}
                        onExpandedChange={this.handleRowExpanded}
                        manual
                        expanded={expanded}
                        {...subComponent}
                        pageSize={paging.pageSize}
                        changePageAmount={this.changePageAmount}
                    />
                </div>
            </div>
        );
    }
}

UserActions.propTypes = {
    userId: PropTypes.number.isRequired,
    header: PropTypes.object,
    currentUser: PropTypes.object,
    isSupport: PropTypes.bool,
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(null, actions)(UserActions);
