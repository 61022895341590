import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DictionarySelect } from 'components/uikit/Select';
import { fetchCatalog } from 'ducks/Catalog';

const dictionaryStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    multiValue: base => ({ ...base, maxWidth: '95%' }),
};

export const DictionaryField = props => {
    const { dict, dictionary, fetchCatalog, ...tail } = props;
    const [options, setOptions] = useState();

    useEffect(() => {
        if (dictionary.loadComplete) {
            setOptions(dictionary.data.map(({ name }) => ({ id: name, name })));
            return;
        }
        fetchCatalog(dict);
    }, [dictionary, dict, fetchCatalog]);

    return (
        <DictionarySelect
            styles={dictionaryStyles}
            menuPortalTarget={document.body}
            isMulti={true}
            {...tail}
            options={options}
        />
    );
};

const mapStateToProps = (state, props) => ({
    dictionary: state.catalogs[props.dict],
});

export default connect(mapStateToProps, { fetchCatalog })(DictionaryField);
