import React from 'react';
import ModalDialog from 'components/common/ModalDialog';
import ChevronButton from 'components/uikit/ChevronButton';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "components/uikit/Button";
import Field from "components/uikit/Field";
import InputText from 'components/uikit/InputText';
import moment from 'moment';
import Label from 'components/uikit/Label';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


const NewTests = (props) => {
    const { isNewTestsSectionOpen, newRequests, loaded, requestId, sendTestText, handleToggle, canRequest, openSendToTestModal, newTestsRef,
        sendToTest, onCancelSendToTest, modalOpen, processing, handleStateChange } = props;

    const openNewTabPreviewLetter = () => {
        let url = `${window.location.origin}/api/evaluation/requests/getEmailPreview?EvaluationRequestId=${requestId}&MessageToPerson=${encodeURIComponent(sendTestText)}`;
        let win = window.open(url, '_blank');
        win.focus();
    };

    const renderNewTestsSection = () => {
        return (loaded &&
            <div className="Tests-Block">
                <div className="Header">
                    <div className="Header__Text">
                        Назначено на оценку ({newRequests.length})
                            </div>
                    <div className="Header__Right">
                        <ChevronButton className="kr_right" isUp={isNewTestsSectionOpen} onClick={() => handleToggle('isNewTestsSectionOpen')} />
                    </div>
                </div>
                {isNewTestsSectionOpen && (
                    <div className="Tests-Block__List">
                        {newRequests.map(x => renderNewTest(x))}
                    </div>
                )}
            </div>
        );
    }

    const renderNewTest = (test) => {

        const { status, evaluationTest, id } = test;

        return (
            <div className={classnames({ "Line": true, "Line--approved": (status === "Approved") })} key={id}>
                <div className="Sub-Line">
                    <div>{evaluationTest.title}</div>
                    {status === "Approved" &&
                        <div className="Sub-Line__Right">Ожидает отправки пользователю</div>
                    }

                    {status === "Confirmed" &&
                        <div className="Sub-Line__Right">Не пройден</div>
                    }
                </div>
                <div className="Sub-Line">
                    {status === "New" && (
                        <div className="Sub-Line__Left--new">
                            <FontAwesomeIcon icon="clock" size="2x"></FontAwesomeIcon>
                            <div>Ожидает согласования</div>
                        </div>
                    )}

                    {status === "Approved" && (
                        <>
                            <div className="Check">Одобрено региональным координатором</div>
                            {canRequest && renderSendButton(id)}
                        </>
                    )}

                    {status === "Confirmed" && (
                        <>
                            <div className="Sub-Line__Left--yes">
                                <FontAwesomeIcon icon="arrow-circle-right" size="2x"></FontAwesomeIcon>
                                <div>Отправлено резервисту {moment(test.reviewedOn).format('DD.MM.YYYY')}</div>
                            </div>
                            {canRequest && renderSendButton(id, true)}
                        </>
                    )}
                </div>
            </div>
        )
    }

    const renderSendButton = (requestId, Confirmed = false) => {
        return (
            <div>
                <Button size="xs"
                    onClick={() => openSendToTestModal(requestId, Confirmed)}
                    color={Confirmed && "light_primary"}
                >
                    {Confirmed ? "Отправить повторно" : "Отправить"}
                </Button>
            </div>
        );
    }

    const handleRequestTextChange = (e) => {
        handleStateChange('sendTestText', e.target.value);
    }

    const renderModalDialog = () => {
        if (!!props.Confirmed) {
            return <ModalDialog
                onClick={sendToTest}
                onCloseModal={onCancelSendToTest}
                modalOpen={modalOpen}
                modalHeader="Направление на оценку"
                btnOktext="Отправить"
                btnCanceltext="Отмена"
                size={'lg'}
                processing={processing}
            >
                <div className="NewTestsModalWarningText">
                    Уверены, что хотите отправить сообщение повторно?
               </div>
            </ModalDialog>
        } else {
            return <ModalDialog
                onClick={sendToTest}
                onCloseModal={onCancelSendToTest}
                modalOpen={modalOpen}
                modalHeader="Направление на оценку"
                btnOktext="Отправить"
                btnCanceltext="Отмена"
                size={'lg'}
                processing={processing}
                btnExtra={[{
                    text: "Предпросмотр",
                    icon: faSearch,
                    onClick: openNewTabPreviewLetter
                }]}
            >
                <Field>
                    <Label>Дополнительный комментарий для резервиста</Label>
                    <InputText
                        value={sendTestText}
                        onChange={handleRequestTextChange}
                    >
                    </InputText>
                </Field>
            </ModalDialog>
        }
    }

    return newRequests && !!newRequests.length ?
        <div className="Person-Tests" ref={newTestsRef}>
            {renderNewTestsSection()}
            {renderModalDialog()}
        </div> : null;
}


export default NewTests;