import React from "react";

function Label(props) {
    const { children, className, ...tail } = props;
    const finalClass = (className ? " " + className : "");

    return (
        <label 
            className={finalClass}
            {...tail}
        >
            {children}
        </label>
    );
}

export default Label;
