import React from "react";
import ToggleOpen from 'components/hoc/ToggleOpen';

import SearchMenuJSON from './SearchMenuJSON';
import RenderDataCard from './renderDataCard';

const PersonnelInfoBlock = React.memo(props => {

    return (
        <RenderDataCard obj={SearchMenuJSON.PersonnelInfoBlock} {...props} />
    );
});

export default ToggleOpen(PersonnelInfoBlock);
