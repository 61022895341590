import React from 'react';
import './InfoField.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Input from './InputField';
import Icons from 'components/Lk/Uikit/Icons';
import Popover from 'components/Lk/Uikit/Popover';

const InfoField = React.forwardRef((props, ref) => {
    const {
        icon,
        iconClick = false,
        title,
        footer,
        value,
        tooltip,
        children,
        isWrap,
        onWrap,
        isWrapIcon,
        isEdit,
        onChange,
        type,
        placeholder='',
        autoFocus,
        inputProps,
        required,
        editComponent: Component,
        noIcon = false,
        size,
        id,
        inputId,
        rightActionIcon,
        invalid,
        iconToTop,
        noBorder,
        dataTest,
        dataTestFooter,
        maxLength,
        svgIcon,
        shrink,
        wrapperBorder,
        topBorder,
        loweredIcon,
        titlePadding,
        fullWidth,
        maxRows,
        ...tail
    } = props;

    const { previewValue = children || value || '–' } = props;

    delete tail.show;
    const  inpProps = {onChange, type, placeholder, autoFocus, maxLength, id: inputId, maxRows, ...tail, ...inputProps };

    const renderEdit = Component || (
        <Input className="InfoField__Input" value={value} {...inpProps} ref={ref} />
    );

    const titleClass = classnames('InfoField__Title', {
        'InfoField__Title--large': isEdit && size === 'lg',
        'InfoField__Title--green': isEdit && required && value && !invalid,
        'InfoField__Title--red': isEdit && ((required && !value) || invalid),
        'InfoField__Title--padding': isEdit && titlePadding,
        'InfoField__Title--view': !isEdit,
    });

    const iconClass = classnames('InfoField__Icon', {
        'InfoField__Icon--red': isEdit && ((required && !value) || invalid),
        'InfoField__Icon--lowered': loweredIcon,
    });
    
    const renderIcon = () => {
        return !noIcon && (icon || svgIcon) && (
            <div className={iconClass}>
                {icon &&
                (typeof icon === 'string'
                        ?
                        (
                            <FontAwesomeIcon icon={icon} onClick={iconClick || undefined} />
                        )
                        :
                        (
                            <FontAwesomeIcon
                                {...icon}
                                className={classnames(
                                    { 'InfoField__Icon--event': !!icon.onClick },
                                    icon.className,
                                )}
                            />
                        )
                )
                }
                {svgIcon && (
                    <Icons {...svgIcon} />
                )}
            </div>
        )
    };
    
    return (
        <div className={classnames("InfoField",  {"InfoField--no-border" : noBorder || wrapperBorder })}
            onClick={onWrap} 
            {...tail} 
            data-test={dataTest}
        >
            {renderIcon()}
            <div className={classnames("InfoField__Wrapper", 
                                        {"InfoField__Wrapper--icon-to-top" : iconToTop },
                                        {"InfoField__Wrapper--shrink" : shrink },
                                        {"InfoField__Wrapper--bottom-border" : wrapperBorder },
                                        {"InfoField__Wrapper--top-border" : topBorder },
                                        {"InfoField__Wrapper--full":fullWidth}
                                        )}>
                <div className={classnames("InfoField__Container",{
                    "InfoField__Container--full":fullWidth
                })}>
                    {title && <span className={titleClass}>{title}</span>}
                    <span className="InfoField__Value">
                        {isEdit ? renderEdit : previewValue}
                    </span>
                    {footer && <span className="InfoField__Footer" data-test={dataTestFooter}>{footer}</span>}
                </div>

                {tooltip && (
                    <>
                        <Popover tooltip={tooltip}>
                            <div className="InfoField__Tooltip">
                            <Icons name="help-alt" fill={'#007AFF'} />
                            </div>
                        </Popover>
                    </>
                )}
                {onWrap && (
                    <div className="InfoField__Tooltip">
                        <FontAwesomeIcon icon={isWrapIcon 
                            ? isWrap 
                                ? 'chevron-up' 
                                : 'chevron-down'
                            : "chevron-right"}/>
                    </div>
                )}
                {rightActionIcon && (
                    <div className="InfoField__Tooltip"
                    onClick={rightActionIcon.iconClick(id)}
                    >
                        {rightActionIcon.icon}
                    </div>
                )}
            </div>
        </div>
    );
});

export default InfoField;
