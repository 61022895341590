import React from 'react';
import { renderLineHint, getChartParams } from '../chartsHelper';
import LineChart from './LineChart';
import Popover from 'components/Lk/Uikit/Popover';
import ScaleTooltip from './ScaleTooltip';
import classnames from 'classnames';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import { isDevice } from 'utils';
import { EVALUATIONTEST_CRITERIONRESULT_STATUS, EVALUATIONTEST_STATUS } from 'constants.js';

const Web = (props) => {
    const { data, withHeader = false, withDescr = false, testProviders, fixedWidth } = props;

    const renderRadarLines = (data) => {
        return (
            <div
                className={classnames('LKEvaluate-Block', {
                    'LKEvaluate-Block--fixed-width': fixedWidth,
                })}
            >
                {data.map((x) => {
                    const lineHint = renderLineHint(x.description, `lineHint${x.id}`);
                    
                    const isTestActive = x.testStatus === EVALUATIONTEST_STATUS.Relevant;
                    const isCriteriaActive = x.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Relevant;
                    
                    return (
                        <div 
                            className={classnames("LKCompetence LKCompetence--full", 
                                {'LKCompetence--notActive': !isTestActive || !isCriteriaActive})} 
                            key={x.id}
                        >
                            <div className="LKCompetence__Line">
                                <Popover
                                    key={`popover${x.id}`}
                                    tooltip={lineHint}
                                    autoPlacement={false}
                                >
                                    <LineChart
                                        title={x.name}
                                        progress={x.value}
                                        alreadyHavePopover={!!lineHint}
                                    />
                                </Popover>
                            </div>
                            <div className="LKChart-Tooltip__UnderLine">{x.provider}</div>
                        </div>
                    );
                })}
            </div>
        );
    };

    let radarData =
        data &&
        Array.isArray(data) &&
        data.map((item) => {
            return {
                id: item.result.id,
                title: item.title,
                name: Object.entries(item.criteria)[0][1].title,
                value:
                    item.result.criteria && item.result.criteria.length > 0
                        ? Math.round(item.result.criteria[0].normalizedValue * 100)
                        : 0,
                provider:
                    (!!testProviders.find((x) => x.id === item.testProviderId) &&
                        testProviders.find((x) => x.id === item.testProviderId).title) ||
                    item.unknownProviderTitle,
                description: Object.entries(item.criteria)[0][1].description,
                testStatus: item.resultType,
                status: item.result?.criteria?.[0]?.status,
            };
        });

    if (!radarData && data) {
        radarData = [
            {
                id: data.result.id,
                title: data.title,
                name: Object.entries(data.criteria)[0][1].title,
                value:
                    data.result.criteria && data.result.criteria.length > 0
                        ? Math.round(data.result.criteria[0].normalizedValue * 100)
                        : 0,
                provider:
                    (!!testProviders.find((x) => x.id === data.testProviderId) &&
                        testProviders.find((x) => x.id === data.testProviderId).title) ||
                    data.unknownProviderTitle,
                description: Object.entries(data.criteria)[0][1].description,
                testStatus: data?.resultType,
                status: data.result?.criteria?.[0]?.status,
            },
        ];
    }

    if (radarData && radarData.length > 3) {
        radarData = radarData.slice(0, 3);
    }

    if (radarData && radarData.length === 2) {
        radarData.push({
            id: '_',
            title: '',
            name: '_',
            value: 0,
            provider: '',
            description: '',
            status: 'Relevant',
            testStatus: 'Relevant',
        });
    }

    var printData = radarData && radarData.filter((x) => x.id !== '_');

    const chartParams = getChartParams('Abilities');

    return (
        <div key={radarData[0].id} className="LKEvaluate-Block">
            {withHeader && !isDevice.SM() && (
                <div className="LKEvaluate-Block__Header">
                    Тесты способностей
                    {withDescr && (
                        <Popover tooltip={chartParams.Descr} autoPlacement={false}>
                            <Icons name="help-alt" fill={'#007AFF'} />
                        </Popover>
                    )}
                </div>
            )}
            {isDevice.SM() && withDescr && <div className="LKEvaluate-Block__Descr">{chartParams.Descr}</div>}
            <div className="LKRadar-Chart">
                <div className="LKRadar-Chart__Billet-List">{renderRadarLines(printData)}</div>
            </div>
            <ScaleTooltip withDivider />
        </div>
    );
};

export default Web;
