import React from 'react';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';

const ConfirmModal = props => {
    const { isOpen, onConfrim, onClose, isUrlBlocker } = props;
    return (
        <ModalPopup
            isModalOpen={isOpen}
            confirm={onConfrim}
            onModalClose={onClose}
            showCloseIcon
            actionButtons={isUrlBlocker ? ['Отменить', 'Продолжить'] : ['Вернуться', 'Подтвердить']}
        >
            <div className="ConfrimModal">
                {isUrlBlocker ? (
                    <> Изменения не&nbsp;будут сохранены</>
                ) : (
                    <>
                        Вы&nbsp;выбрали настройку видимости, при которой
                        <br />
                        <b>заметка будет доступна для всех пользователей</b>
                        <br />
                        системы, имеющих доступ к&nbsp;данной анкете резервиста
                    </>
                )}
            </div>
        </ModalPopup>
    );
};

export default ConfirmModal;
