import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RoundButton = (props) => {
    const { type, children, position, icon, disabled, color, ...tail } = props;
    const finalClass = classnames("RoundButton", {
        "RoundButton__Edit": type === "edit", 
        "RoundButton__Copy": type === "copy", 
        "RoundButton__Close": type === "close",
        "RoundButton__Print": type === "print",
        "RoundButton__Edit--absolute" :  position === "absolute",
        "RoundButton__Drag": type === "drag",
        "RoundButton--disabled": disabled,
        "RoundButton__LightBlue": color === 'LightBlue',
    });

    const content = 
        <>
            {icon && <span><FontAwesomeIcon icon={icon} /></span>}
            {children}
        </>;

    return (
        <button className={finalClass} {...tail}>
            {content}
        </button>
    );
};

export default RoundButton;