import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { getFullWishlistById, getWishlistById, removePersonsFromWishlist } from 'api';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { getAllWishlists, fetchAllPersonIds } from 'ducks/Wishlists';
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchResultCard from 'components/Lk/Shared/Search/SearchResultCard';
import Loader from 'components/common/Loader';
import InfiniteScroll from 'react-infinite-scroller';
import EventMenu from 'components/Lk/Common/EventMenu';
import PersonMassActionMenu from 'components/Lk/Common/EventMenu/PersonMassActionMenu';
import AcceptPage from 'components/Lk/Common/AcceptPage';
import { isMobileWidth as isMobileWindowWidth } from 'utils.js';

import './WishlistPeople.scss';

const defaultAcceptPageParams = {
    title: '',
    acceptAction: () => {},
};

const initialState = {
    persons: [],
    loading: false,
    hasMore: false,
    pageCount: 1,
    paging: { pageNum: 1, pageSize: 10 },
    wishlist: null,
    menu: null,
    activePersons: [],
    filter: { visibilities: ['Everyone', 'Group', 'User'] },
    isMobile: false,
    acceptPage: defaultAcceptPageParams,
    isAcceptPage: false,
};

class WishlistPeople extends Component {
    _isMounted = false;

    state = initialState;

    onResize = () => {
        const isMobileWidth = isMobileWindowWidth();

        if (!this.state.isMobile && isMobileWidth) {
            this.setState({ isMobile: true });
        }
    };

    goBack = () => {
        this.props.goBack();
    };

    loadLists = async () => {
        const {
            match: {
                params: { id },
            },
        } = this.props;
        const { paging } = this.state;
        this.setState({ loading: true });
        const persons = await getFullWishlistById(id, { paging });
        const wishlist = await getWishlistById(id);
        this._isMounted &&
            this.setState({
                persons: [...this.state.persons, ...persons.data.payload],
                wishlist: wishlist.data,
                loading: false,
                paging: { ...paging, pageNum: paging.pageNum + 1 },
                hasMore: persons.data.meta.pageCount > paging.pageNum,
            });
    };

    handleLoadMore = () => {
        this.setState({ hasMore: false });
        this.loadLists();
    };

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('resize', this.onResize);
        this.onResize();

        this.loadLists();
        this.props.fetchAllPersonIds();
    }

    componentDidUpdate(prevProps) {
        this.onResize();

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ ...initialState }, () => this.loadLists());
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.onResize);
    }

    onSetMenu = menu => this.setState({ menu });

    setActivePersons = activePersons => this.setState({ activePersons });

    onCheck = (personId, isCheck) => {
        const { activePersons } = this.state;
        const newList = isCheck
            ? [...activePersons, personId]
            : activePersons.filter(x => x !== personId);
        this.setActivePersons(newList);
    };

    removeFromWishList = (wishlistId, personIds) => async () => {
        const { showSuccessAlert, showErrorAlert, getAllWishlists, allWishLists } = this.props;
        const criteria = (allWishLists && allWishLists.criteria) || {
            paging: this.state.paging,
            filter: this.state.filter,
        };
        this.setIsAcceptPage(false);

        try {
            const response = await removePersonsFromWishlist(wishlistId, personIds);
            if (response.status === 200 && this._isMounted) {
                this.setState({ ...initialState }, () => this.loadLists());
                showSuccessAlert('Резервист(ы) успешно удален(ы)');
            }
            getAllWishlists(criteria);
        } catch (error) {
            showErrorAlert('При удалении резервиста(ов) произошла ошибка');
        }
    };

    setIsAcceptPage = value => {
        this.setState({ isAcceptPage: value });
    };

    setAcceptPage = value => {
        this.setState({ acceptPage: value });
    };

    showAcceptPage = (wishlistId, personIds) => {
        this.setAcceptPage({
            title: 'Удалить резервистов ?',
            info: '',
            acceptAction: this.removeFromWishList(wishlistId, personIds),
        });
        this.setIsAcceptPage(true);
    };

    render() {
        const {
            loading,
            persons,
            wishlist,
            hasMore,
            activePersons,
            menu,
            isAcceptPage,
            acceptPage,
        } = this.state;
        const { activeCard, setActiveCard, allPersonIds } = this.props;
        const wishlistId = this.props.match.params.id;

        const wishlistIdWithPersonIds = allPersonIds.find(x => x.id === +wishlistId);
        const totalPersonIds = wishlistIdWithPersonIds && wishlistIdWithPersonIds.personIds;

        setActiveCard(ACTIVE_LIST_CARD);
        const isMobileActive = activeCard === ACTIVE_LIST_CARD;
        
        return (
            <div className={classnames('ListCard', { 'ListCard--isMobileActive': isMobileActive })}>
                <div className="LKWishlistPeopleHeader" onClick={this.goBack}>
                    <div className="LKWishlistPeopleAction">
                        <FontAwesomeIcon icon="chevron-left" />
                    </div>
                    <div className="LKWishlistPeopleTitle">{wishlist && wishlist.name}</div>
                </div>
                <AcceptPage
                    portal
                    show={isAcceptPage}
                    title={acceptPage.title}
                    info={acceptPage.info}
                    acceptAction={acceptPage.acceptAction}
                    setIsAcceptPage={this.setIsAcceptPage}
                />
                <EventMenu
                    menu={menu}
                    onSetMenu={this.onSetMenu}
                    data={persons}
                    onCheck={this.setActivePersons}
                    activePerson={activePersons}
                    totalPersonIds={totalPersonIds}
                />
                <PersonMassActionMenu
                    persons={activePersons}
                    data={persons}
                    type={menu}
                    setMenu={this.onSetMenu}
                    skipEditWishList={true}
                    wishlistId={wishlistId}
                    removeFromWishList={this.showAcceptPage}
                />
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.handleLoadMore}
                    hasMore={hasMore}
                    initialLoad={false}
                    useWindow={false}
                >
                    <SearchResultCard
                        searchResult={persons}
                        withMenu={false}
                        withCheckbox={true}
                        activePerson={activePersons}
                        onCheckClick={this.onCheck}
                    />
                    {loading && <Loader />}
                </InfiniteScroll>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        allWishLists: state.wishlists.allWishLists,
        allPersonIds: state.wishlists.allPersonIds,
    };
};

const actions = { push, showSuccessAlert, showErrorAlert, getAllWishlists, fetchAllPersonIds, goBack };

export default connect(
    mapStateToProps,
    actions,
)(WishlistPeople);
