import React from 'react';
import Constructor from './Constructor/Constructor';
import Profile from './Profile';
import ScrollTabs from 'components/uikit/Tabs/ScrollTabs';

export const ExternalSearch = () => {
    return (
        <div className="MasterSearchContainer">
            <ScrollTabs defaultTab={0}>
                <div label="Профили">
                    <div className="MasterSearchContainer__TabContainer">
                        <Profile />
                    </div>
                </div>
                <div label="Конструктор">
                    <div className="MasterSearchContainer__TabContainer">
                        <Constructor />
                    </div>
                </div>
            </ScrollTabs>
        </div>
    );
};

export default ExternalSearch;
