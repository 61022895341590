import React from 'react';
import Label from 'components/uikit/Label';
import classnames from 'classnames';
import './CheckCard.scss';

const CheckCard = (props) => {
    const {
        title,
        checked,
        onChange,
        className,
        imgUrl,
        disabled,
        ...tail
    } = props;

    const finalClass = classnames(
        'kr_checkbox_checkCard_wrap ',
        ` ${disabled ? ' disabled ' : ''} `,
        ` ${className ? className : ''} `,
    );
    return (
        <div className="checkCard">
            <div className={finalClass}>
                {imgUrl && (
                    <div className="imageCard" style={{ backgroundImage: `url(${imgUrl})` }}></div>
                )}
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => !disabled && onChange(!checked)}
                    {...tail}
                />
                <Label onClick={() => !disabled && onChange(!checked)}>{title}</Label>
            </div>
        </div>
    );
};

export default CheckCard;
