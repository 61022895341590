import React from 'react';
import PropTypes from 'prop-types';
import RoundButton from "components/uikit/Button/RoundButton";
import Button from "components/uikit/Button/Button";

const ButtonTemplate = (props) => {
    const {handleUpDividerDragEnter, handleUpDividerDragLeave,
            handleDownDividerDragEnter, handleDownDividerDragLeave, 
            index, text, deleteTemplateChild, handleModalOpen, color} = props;
            const style = {background: `${color}`};
    return (
        <div className="TemplateItemContainer">
            <div className="TemplateItemContainer__UpDivider"
                onDragEnter={handleUpDividerDragEnter} 
                onDragLeave={handleUpDividerDragLeave}  
                data-index={index}                              
            />
            <div className="TemplateItem">
                <div className="TemplateItem__Text">
                    <Button size="sm" style={style}>{text}</Button>
                </div>
                <div className="TemplateItem__Buttons">
                    <RoundButton type={"edit"} onClick={handleModalOpen(index)}/>    
                    <RoundButton type={"close"} onClick={deleteTemplateChild(index)}/>
                </div>
            </div>
            <div className="TemplateItemContainer__DownDivider" 
                onDragEnter={handleDownDividerDragEnter} 
                onDragLeave={handleDownDividerDragLeave}
                data-index={index + 1}
            />
        </div>
    );
};

ButtonTemplate.propTypes = {
    handleUpDividerDragEnter: PropTypes.func,
    handleUpDividerDragLeave: PropTypes.func,
    handleDownDividerDragEnter: PropTypes.func,
    handleDownDividerDragLeave: PropTypes.func,
    index: PropTypes.number,
    text: PropTypes.string,
    deleteTemplateChild: PropTypes.func,
    handleModalOpen: PropTypes.func,
    color: PropTypes.string,
};

export default ButtonTemplate;