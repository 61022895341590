import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import InfoField from 'components/Lk/Uikit/InfoField';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import queryString from 'query-string';
import { lkIssue, lkNewIssue } from 'routes';
import {
    photoSizes,
    createIssueMultiple,
    updateIssue as updateIssueApi,
    createIssueDraftMultiple,
    updateIssueMultiple,
    getIssueAssigned,
} from 'api';
import { fetchPerson } from 'ducks/Person';
import { changeBasket, clearBasket, basketActive } from 'ducks/Baskets';
import { fetchTeams } from 'ducks/Teams';
import { updatedIssues, updateIssue, setUpdateTracking, deleteIssueById } from 'ducks/Issues';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import Loader from 'components/common/Loader';
import { getUserShortName, endOf, startOf, isSameOrAfter, isMobileWidth, isNullOrWhitespace, isDevice } from 'utils';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import { API_STATUS_OK, ISSUE_STATUS_ENUM, ISSUE_TYPE_ENUM } from 'constants.js';
import { getValidationErrors, getError } from 'serviceErrors';
import AcceptPage from 'components/Lk/Common/AcceptPage';
import './NewIssue.scss';
import shallowEqual from 'shallowequal';
import { TITLE_LIMIT, DESCRIPTION_LIMIT, TEAM_TYPE } from './constants';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import SelectImplementer from './SelectImplementer';
import WithKeyboardHandler from 'components/Lk/Hooks/WithKeyboardHandler';
import IssueFile from './IssueFile';
import LoadingUrlBlocker from 'components/Lk/Common/LoadingUrlBlocker';
import UnloadBlocker from 'components/Lk/Common/UnloadBlocker/UnloadBlocker';

const cartName = 'newIssue';

class NewIssue extends Component {
    signTextareaRef = React.createRef();
    inputRefs = {
        issueTitle: React.createRef(),
        issueDescription: React.createRef(),
    };

    _isMounted = false;

    initialIssue = {
        title: '',
        description: '',
        dueDate: null,
        type: ISSUE_TYPE_ENUM.Default,
        status: ISSUE_STATUS_ENUM.New,
        assigneeId: null,
        signature: `С уважением, ${getUserShortName(this.props.authUser)}! \nВерю в Вас!`,
        issues: [],
        files: [],
    };

    state = {
        person: {},
        basket: {},
        teams: {},
        issue: this.initialIssue,
        isFormValid: false,
        isAcceptShown: false,
        acceptTitle: '',
        onIssueAction: () => {},
        onIssueCancel: () => {
            this.setState({ forceClose: true }, () => {
                this.onBack('/');
            });
        },
        loading: false,
        timerId: null,
        returnId: null,
        issueCopy: this.initialIssue,
        isSaving: false,
        isDeleting: false,
        forceClose: false,
        implementerType: TEAM_TYPE,
    };

    handlerPersonIcon = id => () => {
        const { basket } = this.state;
        const resultPerson = {
            ...basket,
            persons: basket.persons.filter(item => item.person.userId !== id),
            userId: basket.userId.filter(x => x !== id),
        };

        this.props.changeBasket(cartName, resultPerson);
    };

    rightActionPerson = {
        icon: <Icons name="deleteIcon" />,
        iconClick: this.handlerPersonIcon,
    };

    onWrapSelect = () => {
        this.onShow();
    };

    onBack = (defaultUrl = '/', id = null) => {
        const { issue } = this.state;
        const { returnUrl, push } = this.props;

        const newId = id || issue.id;

        push((newId && returnUrl && returnUrl.buildUrl({ id: newId })) || defaultUrl);
    };

    renderPerson = data => {
        const renderRersonList = data.persons.map((each, key) => {
            const title = key === 0 ? 'Кому' : '';
            return (
                <InfoField
                    key={each.person.userId}
                    title={title}
                    id={each.person.userId}
                    value={getUserShortName(each.person)}
                    rightActionIcon={this.rightActionPerson}
                    noIcon
                    dataTest="EditIssue_AddAssignee--button"
                />
            );
        });

        return (
            <>
                {data.persons.length === 0 && (
                    <InfoField
                        title="Кому"
                        value="Добавить"
                        isEdit={false}
                        id={this.state.person.userId}
                        onWrap={this.onWrapSelect}
                        noIcon
                        dataTest="EditIssue_AddAssignee--button"
                    />
                )}
                {renderRersonList}
            </>
        );
    };

    onAdd = () => {
        const { basketActive } = this.props;
        basketActive(cartName, false);
        this.onWrapSelect();
    };

    changeRecordDate = e => {
        const newDueDate = e ? endOf(e, 'day').format() : null;
        if (newDueDate && isSameOrAfter(newDueDate, new Date(), 'sec')) {
            this.changeRecord('dueDate')(newDueDate);
        }
    };

    changeFiles = files => {
        this.changeRecord('issueFiles')(files);
    };

    changeDescription = () => value => {
        const { issue } = this.state;
        this._isMounted &&
            this.setState({
                issue: {
                    ...issue,
                    description: value.slice(0, DESCRIPTION_LIMIT),
                    title:
                        issue.title === issue.description || (!issue.title && !issue.description)
                            ? value.slice(0, TITLE_LIMIT)
                            : issue.title,
                },
            });
    };

    datePickerProps = {
        dateFormat: 'dd.MM.yyyy',
        mobileFormat: 'DD.MM.YYYY',
        minDate: new Date(startOf(new Date(), 'day').format()),
        type: 'date',
        dataTest: 'EditIssue_DueDate--datepicker',
        dateConfig: {
            date: {
                format: 'D',
                caption: 'Day',
                step: 1,
            },
            month: {
                format: 'M',
                caption: 'Mon',
                step: 1,
            },
            year: {
                format: 'YYYY',
                caption: 'Year',
                step: 1,
            },
        },
    };

    getIssuetError = (error, defaultError = 'Произошла ошибка') => {
        if (error.response && error.response.data && error.response.data.payload) {
            const errorKey = Object.keys(error.response.data.payload)[0];
            return error.response.data.payload[errorKey][0].message;
        }
        return defaultError;
    };

    setRequest = criteria => {
        const formData = new FormData();
        const { issueFiles: files } = criteria;
        formData.append('data', JSON.stringify(criteria));
        if (files?.length > 0) {
            files.filter(x => x.isNew).forEach(x => formData.append('files', x.file));
            files
                .filter(x => x.deleted)
                .map(x => x.id)
                .forEach(x => formData.append('removeIds', x));
        }
        return formData;
    };

    setIssue = async () => {
        
        const { basket, issue } = this.state;
        const { clearBasket } = this.props;
        const criteria = {
            assigneeIds: basket.userId,
            ...issue,
            title: !issue.title || (issue.title && issue.title.trim() === '') ? null : issue.title,
        };

        const IssueMultiple =
            issue.status === ISSUE_STATUS_ENUM.New
                ? await createIssueMultiple(this.setRequest(criteria))
                : await createIssueDraftMultiple(this.setRequest(criteria));

        if (IssueMultiple.status === API_STATUS_OK) {
            showSuccessAlert(
                `${
                    issue.status === ISSUE_STATUS_ENUM.New
                        ? 'Задача успешно поставлена'
                        : 'Задача сохранена в черновиках'
                } `,
            );
            this.props.updatedIssues();
            this.setState({ isSaving: false });
            clearBasket(cartName);
        }

        return true;
    };

    onSave = async (status, url, showAlert = true) => {
        this.setState({ loading: true, isSaving: true, forceClose: true });
        await this.callEffect(async () => {
            const result = this.props.isEdit ? await this.onUpdate(status, showAlert) : await this.setIssue();
            this.setState({ loading: false, isSaving: false, });

            if(!result){
                this.setState({ forceClose: false, });
                return false;
            }

            if (url) {
                return this.props.push(url);
            }

            this.onBack('/', result?.id);
        });
    };

    onDraftSave = (url, showAlert = true) => {
        this.changeAcceptVisibility(false);
        this.setState(
            state => ({
                ...state,
                issue: {
                    ...state.issue,
                    status: ISSUE_STATUS_ENUM.Draft,
                },
            }),
            () => this.onSave(ISSUE_STATUS_ENUM.Draft, url, showAlert),
        );
    };

    onUpdate = async (status, showAlert = true) => {
        const { issue, basket } = this.state;
        const criteria = {
            ...issue,
            assigneeIds: basket.userId,
            status: status ? status : issue.status,
        };
        if (status !== ISSUE_STATUS_ENUM.Draft && (!basket.userId || basket.userId.length === 0)) {
             showErrorAlert(`Не заполнено поле "Кому"`);
             return false;
        }

        const response =
            status === ISSUE_STATUS_ENUM.Draft || (basket.userId && basket.userId.length !== 0)
                ? await updateIssueMultiple(this.setRequest(criteria))
                : await updateIssueApi(this.setRequest(criteria));

        if (response.data.id !== issue.id) {
            this.props.setUpdateTracking(true);
        }

        showAlert && showSuccessAlert('Сохранение прошло успешно');
        this.setState({ isSaving: false, isDeleting: false });

        this.props.updateIssue(response.data);
        this.props.updatedIssues();
        return response.data;
    };
    
    isEmptyIssue = (issue) => {
        if (!issue) { return true; }
        
        return !!isNullOrWhitespace(issue.title) 
            && !!isNullOrWhitespace(issue.description) 
            && !!!issue.dueDate
            && ((issue.issueFiles && issue.issueFiles.length === 0) 
                ||  (issue.issueFiles && issue.issueFiles.every(x => x.deleted)));
    };
    
    isEmptyAssigneesBasket = basket => {
        return basket && basket.userId && Array.isArray(basket.userId) && basket.userId.length === 0;
    };

    onDeleteIssue = issue => {
        this.changeAcceptVisibility(true);
        this._isMounted && this.setState({
            acceptTitle: 'Удалить пустой черновик?',
            onIssueAction: () => {
                this.setState({ forceClose: true, isDeleting: true }, async () => {
                    await this.onSave(ISSUE_STATUS_ENUM.Draft, null, false);
                    this.props.deleteIssueById(issue.id);
                });
            },
        });
    };

    onCancel = () => {
        const {
            isEdit,
            match: {
                params: { id },
            },
        } = this.props;
        const { issue, issueCopy, basket } = this.state;
        if (!isEdit || this.state.issue.status === ISSUE_STATUS_ENUM.Draft) {
            if (
                !shallowEqual(issueCopy, issue) ||
                JSON.stringify(issue.issues.map(x => x.assigneeId).sort()) !==
                    JSON.stringify(basket.userId.sort())
            ) {
                if (this.isEmptyIssue(issue) && this.isEmptyAssigneesBasket(basket)) {
                    this.onDeleteIssue(issue)
                } else {
                    this.changeAcceptVisibility(true);
                    this._isMounted &&
                    this.setState({
                        acceptTitle: !isEdit
                            ? `Сохранить задачу в черновиках?`
                            : `Сохранить изменения в черновике?`,
                        onIssueAction: () => {
                            this.setState({ forceClose: true }, () => {
                                this.onDraftSave();
                            });
                        },
                    });
                }
            } else {
                this.onBack('/');
            }
        } else {
            if (
                !shallowEqual(issueCopy, issue) ||
                JSON.stringify(issue.issues.map(x => x.assigneeId).sort()) !==
                    JSON.stringify(basket.userId.sort())
            ) {
                this.changeAcceptVisibility(true);

                this._isMounted &&
                    this.setState({
                        acceptTitle: `Отменить внесенные изменения?`,
                        onIssueAction: () => {
                            showSuccessAlert(`Изменения успешно отменены`);
                            this.setState({ forceClose: true }, () => {
                                this.onBack(lkIssue.buildUrl({ id }));
                            });
                        },
                        onIssueCancel: () => {
                            this.changeAcceptVisibility(false);
                        },
                    });
            } else {
                this.onBack(lkIssue.buildUrl({ id }));
            }
        }
    };

    getIssueError = () => code => {
        switch (code) {
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };

    getAcceptWindow = url => {
        const { isEdit } = this.props;
        const { issue, basket } = this.state;

        if (this.isEmptyIssue(issue) && this.isEmptyAssigneesBasket(basket)) {
            this.onDeleteIssue(issue)
        } else {
            this.changeAcceptVisibility(true);

            const acceptTitle = isEdit
                ? this.state.issue.status === ISSUE_STATUS_ENUM.Draft
                    ? `Сохранить изменения в черновике?`
                    : `Отменить внесенные изменения?`
                : `Сохранить задачу в черновиках?`;
            this._isMounted &&
            this.setState({
                acceptTitle,
                onIssueAction: () => {
                    this.setState({ forceClose: true }, () => {
                        if (!isEdit || this.state.issue.status === ISSUE_STATUS_ENUM.Draft) {
                            this.onDraftSave(url);
                        } else {
                            showSuccessAlert(`Изменения успешно отменены`);
                            this.props.push(url);
                        }
                    });
                },
                onIssueCancel: () => {
                    if (!isEdit || this.state.issue.status === ISSUE_STATUS_ENUM.Draft) {
                        this.setState({ forceClose: true }, () => {
                            this.props.push(url);
                        });
                    }
                    this.changeAcceptVisibility(false);
                },
            });
        }
    };

    callEffect = async callback => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            const validationErrors = getValidationErrors(error);
            if (Array.isArray(validationErrors) && validationErrors.length > 0) {
                validationErrors.map(item => {
                    return showErrorAlert(item.message);
                });
            } else {
                const reqError = getError(error, this.getIssueError());
                showErrorAlert(reqError.message);
            }
        } finally {
            this.props.hidePageLoader();
            this._isMounted && this.setState({ loading: false, isSaving: false });
        }
    };

    setEmptyIssue = () => {
        return this.initialIssue;
    };

    loadRecord = async () => {
        const {
            match: {
                params: { id },
            },
            push,
            clearBasket,
        } = this.props;
        let record = {};
        if (!id) {
            record = this.setEmptyIssue();
            clearBasket(cartName);
            this.setState({ issue: { ...record }, issueCopy: { ...record }, loading: false });
            push(lkNewIssue.url);
            return;
        }

        try {
            this.setState({ loading: true });
            const result = await getIssueAssigned(id);
            record = result.data;
            record.issues = record.issues.filter(
                issue => issue.status !== ISSUE_STATUS_ENUM.Deleted,
            );
            this.setState({ issue: { ...record }, issueCopy: { ...record }, loading: false });

            this.updateBasket(record);
        } catch (e) {
            showErrorAlert('При загрузке задачи возникла ошибка');
            push('/');
        }
    };

    updateBasket = currIssue => {
        let newBasket = {};
        if (currIssue === undefined || !currIssue.issues) {
            this.props.changeBasket(cartName, { persons: [] });
            return;
        }

        for (const issue of currIssue.issues) {
            const { assignee } = issue;
            newBasket[assignee.personId || assignee.id] = assignee.id;
        }

        newBasket = currIssue && {
            ...newBasket,
            persons: currIssue.issues.map(({ assignee }) => ({
                person: { ...assignee, userId: assignee.id, id: assignee.personId || assignee.id },
                type: 'Team',
            })),
            userId: currIssue.issues.map(({ assignee }) => assignee.id),
        };

        this.props.basketActive(cartName, newBasket.persons.length !== 0);
        this.props.changeBasket(cartName, newBasket);
    };

    isFormValid = () => {
        const { storeBasket } = this.props;
        const { issue } = this.state;
        return (
            storeBasket.basket.persons &&
            storeBasket.basket.persons.length !== 0 &&
            issue.title &&
            storeBasket.active &&
            ((issue.dueDate && issue.dueDate._isAMomentObject && issue.dueDate._isValid) ||
                !isNaN(Date.parse(issue.dueDate))) &&
            issue.title.trim()
        );
    };

    changeAcceptVisibility = (state = false) => {
        this._isMounted && this.setState({ isAcceptShown: state });
    };

    unregisterHistoryListener = () => {};

    componentDidMount = async () => {
        const hash = 'fixed';
        this._isMounted = true;
        const {
            storePerson,
            personId,
            storeTeams,
            fetchPerson,
            fetchTeams,
            storeBasket,
            isEdit,
            match: {
                params: { id },
            },
            history,
        } = this.props;

        this.setState({ pathname: history.location.pathname, hash });

        history.replace({
            pathname: history.location.pathname,
            hash,
        });

        this.setState({
            basket: storeBasket.basket,
            person: storePerson.data,
            teams: storeTeams,
        });

        if (!storePerson.loadComplete) {
            fetchPerson(personId, photoSizes.small, true);
        }
        if (storeTeams.leading.length === 0 && !storeTeams.loading) {
            fetchTeams(personId);
        }

        if (isEdit) {
            !id && this.props.push('/');
            await this.loadRecord();
        }
        !isDevice.SM() && this.focusDescription();
    };

    focusDescription = () => {
        setTimeout(() => {
            this.inputRefs.issueDescription.current &&
                this.inputRefs.issueDescription.current.focus();
        });
    };

    async componentDidUpdate(pervProps, prevState) {
        const {
            storeBasket,
            storePerson,
            storeTeams,
            isEdit,
            issues,
            match: {
                params: { id },
            },
        } = this.props;
        const { issue } = this.state;

        if (this.signTextareaRef && this.signTextareaRef.current) {
            this.signTextareaRef.current.style.height = '0px';
            const scrollHeight = this.signTextareaRef.current.scrollHeight;
            this.signTextareaRef.current.style.height = scrollHeight + 'px';
        }

        if (storeBasket.basket.persons !== pervProps.storeBasket.basket.persons) {
            this.setState({ basket: storeBasket.basket });
        }

        if (storePerson.loadComplete !== pervProps.storePerson.loadComplete) {
            this.setState({ person: storePerson.data });
        }

        if (storeTeams !== pervProps.storeTeams) {
            this.setState({ teams: storeTeams });
        }

        if (
            storeBasket.basket.persons !== pervProps.storeBasket.basket.persons ||
            issue.title !== prevState.issue.title ||
            issue.dueDate !== prevState.issue.dueDate
        ) {
            this.setState({ isFormValid: this.isFormValid() });
        }

        if (pervProps.isEdit !== isEdit && !isEdit) {
            const record = this.setEmptyIssue();
            this.props.clearBasket(cartName);
            this.setState({ issue: { ...record } });
            this.focusDescription();
        }

        if (issues && isEdit && pervProps.match.params.id !== id) {
            await this.loadRecord();
            this.focusDescription();
        }

        if (pervProps.storeBasket.active !== storeBasket.active) {
            this.setState({ isFormValid: this.isFormValid() });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.props.clearBasket(cartName);
        this.unregisterHistoryListener();
    }

    onTitleFocus = () => {
        const { issue } = this.state;
        if (!issue.title && issue.description) {
            this.setState({
                issue: { ...this.state.issue },
            });
        }
    };

    changeRecord = name => value =>
        this.setState({ issue: { ...this.state.issue, [name]: value } });

    changeRecordText = (name, limit) => value => this.changeRecord(name)(value.slice(0, limit));
    onShow = () => {
        const hash = 'fixed';
        const {
            match: { url },
            history,
        } = this.props;
        history.push({
            pathname: url,
            search: queryString.stringify({ SelectImplementer: true }),
            state: { ignorePrompt: true },
            hash,
        });
    };

    onHide = () => {
        const hash = 'fixed';
        const {
            match: { url },
            history,
        } = this.props;
        history.push({
            pathname: url,
            hash,
            state: { ignorePrompt: true },
        });
    };

    issueCard = () => {
        const { basket, teams, issue } = this.state;
        const { storeBasket, isEdit, status, isFocused } = this.props;
        const headerTitle = isEdit
            ? `Редактировать ${status === ISSUE_STATUS_ENUM.Draft ? 'черновик' : 'задачу'} `
            : 'Новая задача';

        return (
            <div className="NewIssue" id="NewIssue">
                <div
                    className={classnames('NewIssue__Scroll', {
                        'NewIssue__Scroll--isFocused': isFocused,
                    })}
                >
                    <div className="NewIssue__Container">
                        <h3 className="LKLayout__Title">{headerTitle}</h3>
                        {
                            <>
                                {(!basket || !teams) && <Loader />}

                                {storeBasket.basket &&
                                    storeBasket.basket.persons &&
                                    this.renderPerson(storeBasket.basket)}

                                {storeBasket.basket.persons &&
                                    storeBasket.basket.persons.length !== 0 &&
                                    storeBasket.active && (
                                        <div className="NewIssue__links">
                                            <span
                                                className="NewIssue__links--item"
                                                onClick={this.onAdd}
                                                data-test="EditIssue_AddAssignee--button"
                                            >
                                                Добавить
                                            </span>
                                        </div>
                                    )}
                            </>
                        }

                        <InfoField
                            title="Заголовок"
                            value={issue.title || ''}
                            dataTest="EditIssue_Title"
                            dataTestFooter="EditIssue_Title--text-length"
                            inputProps={{
                                maxLength: TITLE_LIMIT,
                                rows: 2,
                                onFocus: this.onTitleFocus,
                                dataTest: 'EditIssue_Title--input',
                            }}
                            type="textarea"
                            onChange={this.changeRecordText('title', TITLE_LIMIT)}
                            isEdit
                            ref={this.inputRefs.issueTitle}
                            noIcon
                            footer={
                                `Осталось символов:  ${TITLE_LIMIT -
                                    (issue.title ? issue.title.length : 0)}` || ''
                            }
                        />
                        <InfoField
                            type="textarea"
                            title="Текст задачи"
                            dataTest="EditIssue_Description"
                            dataTestFooter="EditIssue_Description--text-length"
                            inputProps={{
                                maxLength: DESCRIPTION_LIMIT,
                                rows: 3,
                                dataTest: 'EditIssue_Description--input',
                            }}
                            value={issue.description || ''}
                            onChange={this.changeDescription()}
                            isEdit
                            ref={this.inputRefs.issueDescription}
                            noIcon
                            footer={
                                `Осталось символов:  ${DESCRIPTION_LIMIT -
                                    issue.description.length}` || ''
                            }
                        />
                        <InfoField
                            title="Срок выполнения задачи"
                            inputProps={this.datePickerProps}
                            value={
                                typeof issue.dueDate === 'string' ||
                                (issue.dueDate &&
                                    issue.dueDate._isAMomentObject &&
                                    issue.dueDate._isValid)
                                    ? issue.dueDate
                                    : null
                            }
                            onChange={this.changeRecordDate}
                            placeholder="Добавить дату"
                            isEdit
                            noIcon
                        />

                        <InfoField title="Файлы" noIcon>
                            <IssueFile
                                isEdit
                                files={issue.issueFiles}
                                onChange={this.changeFiles}
                            />
                        </InfoField>
                    </div>
                    {this.footer()}
                </div>
                
            </div>
        );
    };

    footer = () => {
        const { isEdit, status, isFocused, location } = this.props;
        const { issue, isFormValid } = this.state;
        const searchParams = queryString.parse(location.search);
        const footer = !searchParams.SelectImplementer && (
            <div>
                {(!isEdit || status === ISSUE_STATUS_ENUM.Draft) && (
                    <RoundedButton
                        modifier="withoutPaddingBottom"
                        disabled={!isFormValid}
                        onClick={() => this.onSave('New')}
                        data-test="EditIssue_CreateIssue--button"
                    >
                        Поставить задачу
                    </RoundedButton>
                )}
                {isEdit && status !== ISSUE_STATUS_ENUM.Draft && (
                    <RoundedButton
                        modifier="withoutPaddingBottom"
                        onClick={() => this.onSave()}
                        disabled={
                            !issue.title ||
                            !issue.title.trim() ||
                            !issue.dueDate ||
                            (!!issue.dueDate &&
                                !!issue.dueDate._isAMomentObject &&
                                !issue.dueDate._isValid) ||
                            (issue.dueDate && !isSameOrAfter(issue.dueDate, new Date(), 'sec'))
                        }
                        data-test="EditIssue_SaveIssue--button"
                    >
                        Сохранить
                    </RoundedButton>
                )}

                <RoundedButton
                    color="transparent"
                    onClick={this.onCancel}
                    data-test="EditIssue_CreateIssueCancel--button"
                >
                    Отменить
                </RoundedButton>
            </div>
        );

        return <StickyFooter unstick={isFocused}>{footer}</StickyFooter>;
    };

    setImplementerType = type => {
        this.setState({implementerType: type})
    };

    render() {
        const {
            isAcceptShown,
            acceptTitle,
            loading,
            isSaving,
            issueCopy,
            issue,
            basket,
            forceClose,
            isDeleting,
            implementerType,
        } = this.state;
        const { setActiveCard, location } = this.props;
        const isMobileActive = this.props.activeCard === ACTIVE_DETAIL_CARD;
        const searchParams = queryString.parse(location.search);
        const activeCard = ACTIVE_DETAIL_CARD;

        setActiveCard(ACTIVE_DETAIL_CARD);
        const baseClass = 'DetailsCard';
        const isChanges =
            !shallowEqual(issueCopy, issue) ||
            JSON.stringify(issue.issues.map(x => x.assigneeId).sort()) !==
                JSON.stringify((basket.userId || []).sort());
        return (
            <UnloadBlocker isSaving={isChanges}>
                <div
                    className={classnames(baseClass, {
                        [`${baseClass}--isMobileActive`]: isMobileActive,
                    })}
                >
                    {loading &&
                        (isMobileWidth() ? <Loader absolute /> : <Loader overlay />)}

                    <>
                        <LoadingUrlBlocker
                            isLoading={isChanges && !forceClose}
                            onLoad={this.getAcceptWindow}
                            message={false}
                        />
                        {!isDeleting && <LoadingUrlBlocker
                            isLoading={isSaving}
                            message={"Выполняется сохранение. Пожалуйста, подождите."}
                        />}
                        {!searchParams.SelectImplementer && this.issueCard()}
                        {!searchParams.SelectImplementer && (
                            <AcceptPage
                                show={isAcceptShown}
                                title={acceptTitle}
                                acceptAction={this.state.onIssueAction}
                                cancelAction={this.state.onIssueCancel}
                                setIsAcceptPage={() => {}}
                            />
                        )}
                        {searchParams.SelectImplementer && (
                            <SelectImplementer
                                onSubmit={this.onHide}
                                onCancel={this.onHide}
                                activeCard={activeCard}
                                setActiveCard={setActiveCard}
                                implementerType={implementerType}
                                setImplementerType={this.setImplementerType}
                            />
                        )}
                    </>
                </div>
            </UnloadBlocker>
        );
    }
}

NewIssue.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const mapStateToProps = store => {
    return {
        personId: store.auth.user.personId,
        authUser: store.auth.user,
        storePerson: store.person,
        storeBasket: store.baskets.newIssue,
        allBasket: store.baskets,
        storeTeams: store.teams,
        issues: store.issues,
    };
};

const actions = {
    push,
    fetchPerson,
    changeBasket,
    clearBasket,
    basketActive,
    fetchTeams,
    updateIssue,
    updatedIssues,
    showPageLoader,
    hidePageLoader,
    setUpdateTracking,
    deleteIssueById,
};
export default connect(
    mapStateToProps,
    actions,
)(WithKeyboardHandler(NewIssue, 'NewIssue'));
