import React, { Component } from 'react';
import Page from "components/common/Page";
import { connect }  from 'react-redux';
import { fetchCatalog, EMPLOYEES_NUMBERS, INDUSTRIES } from 'ducks/Catalog';
// eslint-disable-next-line
import Employees from './Employees';
import Industries from './Industries';
import Choropleth from './Choropleth';
import './Analytics.scss';
import { colors } from './constants'
import { search, favorites } from '../../routes'
import { Link } from "react-router-dom";

class Analytics extends Component {
    getColor = (amount, maxAmount, lessColors = false) => {
        const k = lessColors ? 10 : 1;
        if (amount === maxAmount) {
            return colors[colors.length -k]
        } else {
            const colorId = Math.round(+amount * (colors.length -k) / +maxAmount);
            return colors[colorId];
        }
    };

    lightenDarkenColor = (col, amt)  => {
  
        let usePound = false;
      
        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }
     
        const num = parseInt(col,16);
        
        let r = (num >> 16) + amt;
        if (r > 255) {
            r = 255
        } else if (r < 0) {
            r = 0
        }
     
        let b = ((num >> 8) & 0x00FF) + amt;
        if (b > 255) {
            b = 255
        } else if (b < 0) {
            b = 0
        }
     
        let g = (num & 0x0000FF) + amt;
        if (g > 255) {
            g = 255
        } else if (g < 0) {
            g = 0
        }
     
        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    }

    render() {
        return (
            <Page>
                <div className="Chart-Container">
                    {this.props.ids.length !== 0 ? (
                        <>
                            {/* TODO Согласовать состав графика
                            <div className="Flex-Wrapper-50"></div>
                                <Employees 
                                    employeesNumbersCatalog={this.props.employeesNumbersCatalog} 
                                    employeesNumbersData={this.props.employeesNumbersData}
                                /> */}

                            <Choropleth
                                choroplethData={this.props.choroplethData}
                                getColor={this.getColor}
                            />

                            <Industries
                                industriesCatalog={this.props.industriesCatalog}
                                industriesData={this.props.industriesData}
                                getColor={this.getColor}
                                lightenDarkenColor={this.lightenDarkenColor}
                            />
                        </>
                    ) : (
                        <div className="No-Data">
                            Выберите группу резервистов в разделе{' '}
                            <Link to={search.url}>«Поиск»</Link> или{' '}
                            <Link to={favorites.url}>«Избранное»</Link> для того, чтобы построить по
                            ним аналитику
                        </div>
                    )}

                </div>
            </Page>
        ); 
    }

    componentDidMount() {
        this.props.fetchCatalog(EMPLOYEES_NUMBERS);
        this.props.fetchCatalog(INDUSTRIES);
    }

}

const mapStateToProps = (state) => {
    const analytics = state.analytics.data;
    return {
        employeesNumbersCatalog: state.catalogs.employeesNumbers,
        employeesNumbersData: analytics.employees || [],
        industriesCatalog: state.catalogs.industries,
        industriesData: analytics.industries || [],
        choroplethData: analytics.regions || [],
        ids: state.analytics.ids || []
    }
}

const actions = { fetchCatalog }

export default connect(mapStateToProps, actions)(Analytics);