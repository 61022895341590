import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Option from '../Option';
import './StyleDecorator.scss';

export default (SelectComponent) =>
    class StyledSelect extends PureComponent {
        static propTypes = { className: PropTypes.string };
        static defaultProps = { className: '' };
        static defaultComponents = { Option };
        static styles = {
            noOptionsMessage: (styles) => ({
                ...styles,
                lineHeight: 3,
                padding: 0,
                boxSizing: 'border-box',
            }),
        };

        render() {
            const { className, ...tail } = this.props;
            const finalClass = classnames('react-select', className);

            return (
                <SelectComponent
                    placeholder=''
                    className={finalClass}
                    classNamePrefix='react-select'
                    noOptionsMessage={this.noOptionsMessage}
                    loadingMessage={this.loadingMessage}
                    styles={StyledSelect.styles}
                    components={StyledSelect.defaultComponents}
                    {...tail}
                />
            );
        }

        noOptionsMessage = () => 'Ничего не найдено';
        loadingMessage = () => 'Загрузка...';
    };
