import React, { Component } from 'react';
import ContextMenu from './ContextMenu';
import { WISHLISTS_ACTION_ITEMS, WISHLIST_MERGE, WISHLIST_REMOVE } from './constants';

class WishlistsActionMenu extends Component {

    state = {
        menuItems: [],
    };

    onClick = item => {
        this.props.onAction(item.id);
    }; 

    updateMenu = () => {
        const { selectedWishlists = [], defaultWishListId } = this.props;
        
        const readyItems = WISHLISTS_ACTION_ITEMS
            .map(x => (x.id === WISHLIST_MERGE 
                ? {...x, disabled: selectedWishlists.length < 2} 
                : {...x}))
            .map(x => (x.id === WISHLIST_REMOVE 
                ? {...x, disabled:  selectedWishlists.length === 1 && selectedWishlists.includes(defaultWishListId) } 
                : {...x}));
        
        this.setState({ menuItems: readyItems });
    };

    componentDidMount() {
        this.updateMenu();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedWishlists !== this.props.selectedWishlists) {
            this.updateMenu();
        }
    }

    render() {
        const { isOpen, onClose } = this.props;
        const { menuItems } = this.state;
        return (
            <ContextMenu items={menuItems} isOpen={isOpen} onClose={onClose} onClick={this.onClick} />
        );
    }
}

export default WishlistsActionMenu;