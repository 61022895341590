import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactDropzone from 'react-dropzone';
import Image from 'components/common/Image';
import { DataCard } from 'components/common/DataCard';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import ValidBlock from 'components/hoc/ValidBlock';
import { MAX_BYTES_PER_PHOTO_FILE } from 'constants.js';
import { isUploadFilesSizeValid } from 'libs/validators';
import { getFileSize } from 'utils';
import RoundButton from 'components/uikit/Button/RoundButton';
import './PhotoBlock.scss';
import classnames from 'classnames';

class PhotoBlock extends Component {
    fileInput = React.createRef();

    state = {
        imageLoaded: false,
        noImg: false,
    };

    onInvalidPhotoUpload = message => {
        this.props.showWarningAlert(message);
        this.handleErrorImage();
    };

    onDropFile = files => this.upload(files && files[0]);

    onChangeFile = e => {
        const files = e.target.files;
        this.upload(files && files[0]);
    };

    handleLoadImage = () => {
        this.setState({ imageLoaded: true, noImg: false });
    };
    handleErrorImage = () => {
        this.setState({ imageLoaded: true, noImg: true });
    };

    openFileDialog = () => {
        const inputElement = this.fileInput.current;

        if (!inputElement) {
            return;
        }
        this.fileInput.current.click();
    };

    upload = file => {
        if (!file) {
            return;
        }

        this.setState({ imageLoaded: false });

        if (!file.type.startsWith('image/') || file.type.endsWith('/gif')) {
            this.onInvalidPhotoUpload(
                'Некорректный тип фото: ' + file.type + '. Поддерживаются форматы JPEG и PNG',
            );
            return;
        }

        if (!isUploadFilesSizeValid([file], MAX_BYTES_PER_PHOTO_FILE)) {
            this.onInvalidPhotoUpload(
                `Размер файла не может превышать ${getFileSize(MAX_BYTES_PER_PHOTO_FILE)}.`,
            );
            return;
        }

        if (this.props.onPhotoOpened) {
            this.props.onPhotoOpened(file);
        }

        if (this.props.onToggleMode) {
            this.props.onToggleMode(false);
        }
    };

    onImageClick = e => {
        const { imageLoaded, noImg } = this.state;

        const isNew = !imageLoaded || noImg;

        if (isNew) {
            e.preventDefault();
            this.openFileDialog();
        }
    };

    renderImage = () => {
        const { imageLoaded } = this.state;
        const { alt, photoUri, photoLink, loading } = this.props;

        return (
            <Link to={photoLink || ''} target="_blank" onClick={this.onImageClick}>
                <Image
                    alt={alt}
                    photoUri={photoUri}
                    loading={!imageLoaded || loading}
                    onLoad={this.handleLoadImage}
                    onError={this.handleErrorImage}
                />
            </Link>
        );
    };

    renderEditContent = props => {
        const { id, onToggleMode, isImport, canEdit, readOnly, className = '', isDisabled = false } = this.props;
        const { getRootProps, getInputProps } = props;

        return (
            <div
                {...getRootProps()}
                className={classnames('PhotoBlock', className, {
                    PhotoBlock_Edit: !readOnly,
                    [`${className}--edit`]:className&& !readOnly,
                })}
            >
                <DataCard
                    className={classnames('kr_profile-avatar', 'kr_text-center', {
                        [`${className}__Card`]: className,
                        [`${className}__Card--edit`]: className&& !readOnly,
                    })}
                    shadow
                >
                    {canEdit && onToggleMode && !isDisabled && (
                        <RoundButton type="edit" position="absolute" onClick={onToggleMode} />
                    )}
                    {!readOnly && !isImport && !isDisabled && (
                        <div className="kr_profile-avatar-text1">Перетащите фото сюда</div>
                    )}
                    <div className={classnames('kr_profile-avatar-img', {
                        [`${className}__Img`]: className,
                        [`${className}__Img--edit`]: className && !readOnly,
                    })}>{this.renderImage()}</div>
                    {!readOnly && !isDisabled && (
                        <>
                            {!isImport && <div className="kr_profile-avatar-text2">или</div>}
                            <input
                                {...getInputProps()}
                                id={id}
                                type="file"
                                className="kr_profile-avatar-inputfile"
                                onChange={this.onChangeFile}
                                ref={this.fileInput}
                            />
                            <label
                                htmlFor={id}
                                className={
                                    !isImport
                                        ? 'kr_cadr_btn kr_v2 kr_profile-avatar-label'
                                        : 'kr_cadr_btn kr_v2-import kr_profile-avatar-label'
                                }
                            >
                                Выберите файл*
                            </label>
                            <div>
                                <small className="PhotoHint">
                                    * Размер файла не должен превышать 10 Мб
                                </small>
                            </div>
                        </>
                    )}
                </DataCard>
            </div>
        );
    };

    componentDidUpdate(prevProps) {
        if (prevProps.photoUri !== this.props.photoUri) {
            this.setState({ imageLoaded: false });
        }
    }

    render() {
        const { readOnly } = this.props;
        return (
            <ReactDropzone
                disabled={readOnly || this.props.isDisabled}
                onDrop={this.onDropFile}
                children={this.renderEditContent}
            />
        );
    }
}

PhotoBlock.propTypes = {
    id: PropTypes.string.isRequired,
    photoUri: PropTypes.string,
    photoLink: PropTypes.string,
    loading: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    canEdit: PropTypes.bool,
    onToggleMode: PropTypes.func,
    onPhotoOpened: PropTypes.func,
    alt: PropTypes.string,
};

const actions = {
    showWarningAlert,
    showErrorAlert,
};

export default connect(
    null,
    actions,
)(ValidBlock(PhotoBlock));
