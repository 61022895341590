import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { personGrowthTagRoute } from 'routes';
import PersonGrowthTag from './PersonGrowthTag';
import NotFound from '../../NotFound';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_PERSONGROWTHTAG } from '../../../rightsController';

class PersonGrowthTagRoutes extends Component {
    render() {
        return (
            <Switch>
                <ProtectedRoute path={personGrowthTagRoute.url} component={PersonGrowthTag} allowedRoles={ALLOWED_MODERATE_PERSONGROWTHTAG} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}

export default PersonGrowthTagRoutes;