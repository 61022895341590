import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './EvaluationPersonData.scss';

const EvaluationPersonData = props => {
    const { data, position, roles = [] } = props;

    const blockClassName = classnames('EvaluationPersonData', {'EvaluationPersonData--withRoles': roles.length > 0});

    const renderRoles = () => {
        return roles.map((x, idx) => (
            <div key={idx} className="Roles__Item">{x}</div>
        ));
    };

    return (
        <div className="EvaluationPersonData__Wrapper">
            <div className={blockClassName}>
                <div className="EvaluationPersonData__Name">{data}</div>
                <div className="EvaluationPersonData__Position">{position}</div>
            </div>
            {roles.length > 0 && <div className="Roles">{renderRoles()}</div>}
        </div>
    );
};

EvaluationPersonData.propTypes = {
    data: PropTypes.string.isRequired,
    position: PropTypes.string,
};

export default EvaluationPersonData;
