import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import './Tabs.scss';

const ScrollTabs = props => {
    const { children, defaultTab = 0, minHeight = 700 } = props;

    const [height] = useState(minHeight);
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [needScroll, setScrollState] = useState(false);

    const tabsRef = [];
    const listRef = useRef();

    const scrollToTab = () => {
        const activeChild = tabsRef[activeTab];
        listRef.current.scrollTop = activeChild.offsetTop - listRef.current.offsetTop;
        setScrollState(false);
    };

    useEffect(() => {
        setScrollState(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (needScroll) {
            scrollToTab();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needScroll]);

    const onClickTabItem = tab => {
        setActiveTab(tab);
        setScrollState(true);
    };

    const onScrollTab = () => {
        if (needScroll) {
            return false;
        }
        const tab = tabsRef
            .map((el, index) => ({ offset: el.offsetTop, index, height: el.clientHeight }))
            .filter(el => el.offset <= listRef.current.scrollTop + listRef.current.clientHeight / 2)
            .pop();
        if (tab.index !== activeTab) {
            setActiveTab(tab.index);
        }
    };

    const setRef = index => ref => {
        tabsRef[index] = ref;
    };

    return (
        <div className="Tabs">
            <ol className="TabList">
                {children.map((child, index) => {
                    const { label } = child.props;
                    return (
                        <Tab
                            activeTab={activeTab}
                            key={label}
                            label={label}
                            onClick={onClickTabItem}
                            index={index}
                        />
                    );
                })}
            </ol>
            <div
                onScroll={onScrollTab}
                ref={listRef}
                style={{ height: `${height}px` }}
                className="Tab__Content Tab__Content--scroll"
            >
                {children.map((child, index) => {
                    return (
                        <div className="Tab__Child" key={index} ref={setRef(index)}>
                            {child.props.children}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

ScrollTabs.propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    defaultTab: PropTypes.number.isRequired,
};

export default ScrollTabs;
