import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import uuid from "uuid/v4";

import { updatePersonGrowthResourceSchedule, createPersonGrowthResourceSchedule } from 'api';
import { showErrorAlert, showSuccessAlert } from "ducks/Alert";

import ModalDialog from "components/common/ModalDialog";
import DatePicker from 'components/uikit/DatePicker';
import Field from "components/uikit/Field";
import Label from "components/uikit/Label";
import Input from "components/uikit/Input";

import { INT_32_MAX_VALUE } from 'constants.js'

import "./EditScheduleRecord.scss";

const blankRecord = {
    id: null,
    personGrowthResourceId: null,
    subscriptionStarts: new Date(),
    subscriptionEnds: new Date(),
    price: null,
};


const EditScheduleRecord = (props) => {
    
    const { recordToEdit, isOpenEditRecordModal, modalType, 
        performResourceScheduleFetching, resourceId, setStateOnCancelEditRecord} = props;
    
    const [record, setRecord] = useState(recordToEdit || blankRecord);

    const AreDatesInOrder = (startDay, endDay) => startDay && endDay && new Date(startDay) <= new Date(endDay);

    const renderStartDatePicker = () => {
        return (
            <Field
                required
                filled={record.subscriptionStarts}
                invalid={!AreDatesInOrder(record.subscriptionStarts, record.subscriptionEnds) }
            >
                <Label>Начало обучения</Label>
                <DatePicker
                    selected={record.subscriptionStarts && new Date(record.subscriptionStarts)}
                    onChange={subscriptionStarts => setRecord({...record, subscriptionStarts})}
                />
            </Field>
        )
    };

    const renderEndDatePicker = () => {
        return (
            <Field
                required
                filled={record.subscriptionEnds}
                invalid={!AreDatesInOrder(record.subscriptionStarts, record.subscriptionEnds)}
            >
                <Label>Окончание обучения</Label>
                <DatePicker
                    selected={new Date(record.subscriptionEnds || new Date())}
                    onChange={subscriptionEnds => setRecord({...record, subscriptionEnds})}
                />
            </Field>
        )
    };

    const renderPrice = () => {
        return (
            <Field
                filled={record.price}
                invalid={(record.price < 0 || (record.price > INT_32_MAX_VALUE)) }
            >
                <Label>Стоимость, {'\u20BD'}</Label>
                <Input
                    type="number"
                    value={record.price || ""}
                    min={0}
                    onChange={e => setRecord({...record, price: e.target.value})}
                />
            </Field>
        )
    };
    
    const isValidForm = () => {
        if (!AreDatesInOrder(record.subscriptionStarts, record.subscriptionEnds)              
            || (record.price < 0 || (record.price > INT_32_MAX_VALUE)) 
        ){
            return false;
        }
        return true;
    };

    const saveRecord = () => {

        const newRecordId = uuid();

        callEffect(async () => {

            let response;
            if (modalType === "new") {
                
                const newRecord = {...record, 
                    id: newRecordId, 
                    personGrowthResourceId: resourceId, 
                };
                
                response = await createPersonGrowthResourceSchedule(newRecord);
            
            } else if (modalType === "edit") {
                response = await updatePersonGrowthResourceSchedule({...record});
            }
        
            if (response && response.status === 200) {
                props.showSuccessAlert('Запись успешно сохранена');
            }
            
            performResourceScheduleFetching();
            onCancelEditRecord();
        })
    };

    const onCancelEditRecord = () => {
        setRecord(blankRecord);
        setStateOnCancelEditRecord();
    };
    
    const callEffect = async callback => {
        try {
            await callback();
        } catch (error) {
            props.showErrorAlert(error.message);
        }
    };

    return (
        <ModalDialog
            onClick={saveRecord}
            onCloseModal={onCancelEditRecord}
            modalOpen={isOpenEditRecordModal}
            modalHeader={`${modalType === "new" ? "Добавление " : "Редактирование "}`}
            btnOktext={`${modalType === "new" ? "Добавить" : "Сохранить"}`}
            btnCanceltext="Отмена"
            size="xl"
            isValidForm={isValidForm()}
        >
            <Col>
                <Row>
                    <Col xs={4.5}>{renderStartDatePicker()}</Col>
                    <Col xs={4.5}>{renderEndDatePicker()}</Col>
                    <Col xs={3}>{renderPrice()}</Col>
                </Row>
            </Col>
        </ModalDialog>
    );
};

const actions = { showErrorAlert, showSuccessAlert };
export default connect(null, actions)(EditScheduleRecord);