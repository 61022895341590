import { getIssueActive, getIssueDrafts, deleteIssue, getIssuesAssigned } from 'api';
import { appName } from '../constants';
import { all, takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import RequestError from '../RequestError';
import { showErrorAlert, showSuccessAlert } from './Alert';

const moduleName = 'issues';
const SET_ACTIVE_ISSUES_AMOUNT = `${appName}/${moduleName}/SET_ACTIVE_ISSUES_AMOUNT`;
const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
const FETCH_ASSIGNED_REQUEST = `${appName}/${moduleName}/FETCH_ASSIGNED_REQUEST`;
const FETCH_DRAFTS = `${appName}/${moduleName}/FETCH_DRAFTS`;
const CHANGE_REQUEST = `${appName}/${moduleName}/CHANGE_REQUEST`;
const UPDATE_ISSUE = `${appName}/${moduleName}/UPDATE_ISSUE`;
const SELECT_ID = `${appName}/${moduleName}/SELECT_ID`;
const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
const FETCH_END = `${appName}/${moduleName}/FETCH_END`;
const FETCH_ASSIGNED_END = `${appName}/${moduleName}/FETCH_ASSIGNED_END`;
const FETCH_DRAFTS_END = `${appName}/${moduleName}/FETCH_DRAFTS_END`;
const DELETE_ISSUE = `${appName}/${moduleName}/DELETE_ISSUE`;
const SET_UPDATE_TRACKING = `${appName}/${moduleName}/SET_UPDATE_TRACKING`;
const FETCH_TRACKING_START = `${appName}/${moduleName}/FETCH_TRACKING_START`;
const FETCH_TRACKING_END = `${appName}/${moduleName}/FETCH_TRACKING_END`;
const FETCH_TRACKING_MORE_START = `${appName}/${moduleName}/FETCH_TRACKING_MORE_START`;
const FETCH_TRACKING_MORE_END = `${appName}/${moduleName}/FETCH_TRACKING_MORE_END`;

export function getTracking(criteria) {
    return {
        type: FETCH_TRACKING_START,
        payload: criteria
    };
}

export function fetchEndTracking(issues, criteria) {
    return {
        type: FETCH_TRACKING_END,
        payload: { issues, criteria }
    };
}

export function getTrackingMore(criteria) {
    return {
        type: FETCH_TRACKING_MORE_START,
        payload: criteria
    };
}

export function fetchEndTrackingMore(issues, criteria) {
    return {
        type: FETCH_TRACKING_MORE_END,
        payload: { issues, criteria }
    };
}

export function getIssues() {
    return {
        type: FETCH_REQUEST,
    };
}

export function geIssuesDraft(action) {
    return {
        type: FETCH_DRAFTS,
        payload: action,
    };
}

export function selectIssues(id) {
    return {
        type: SELECT_ID,
        payload: { id },
    };
}

export function updateIssue(issue) {
    return {
        type: UPDATE_ISSUE,
        payload: issue
    };
}

export function updatedIssues() {
    return {
        type: CHANGE_REQUEST,
    };
}

export function getAssigned() {
    return {
        type: FETCH_ASSIGNED_REQUEST,
    };
}

function fetchAssignedSuccess(assignedIssues) {
    return {
        type: FETCH_ASSIGNED_END,
        payload: { assignedIssues },
    };
}

export const deleteIssueById = id => {
    return {
        payload: id,
        type: DELETE_ISSUE,
    };
};

export const setActiveIssuesAmount = payload => ({ type: SET_ACTIVE_ISSUES_AMOUNT, payload });

function fetchStart(criteria) {
    return {
        type: FETCH_START,
        payload: criteria,
    };
}

function fetchSuccess(issues) {
    return {
        type: FETCH_END,
        payload: { issues },
    };
}

function fetchFailed(error) {
    return {
        type: FETCH_END,
        payload: { error },
        error: true,
    };
}

function fetchDraftSuccess(drafts) {
    return {
        type: FETCH_DRAFTS_END,
        payload: { drafts },
    };
}

export function setUpdateTracking(payload) {
    return {
        type: SET_UPDATE_TRACKING,
        payload,
    };
}

const getIssuesError = `При получении заданий произошла ошибка`;
const deleteIssueError = `При удалении задания произошла ошибка`;

const initialState = {
    loading: false,
    loadComplete: false,
    data: [],
    selectId: '',
    activeIssuesAmount: null,
    assignedIssues: [],
    updateTracking: false,
    tracking: {
        loading: false,
        hasMore: false,
        criteria: { pageSize: 10, pageNum: 1 },
        issues: []
    }
};

export function reducer(state, action) {
    switch (action.type) {
        case FETCH_START:
            return {
                ...state,
                loading: true,
            };

        case SELECT_ID:
            const { id } = action.payload;
            return {
                ...state,
                selectId: id,
            };

        case FETCH_ASSIGNED_END:
            if (!action.error) {
                const { assignedIssues } = action.payload;

                return {
                    ...state,
                    loading: false,
                    loadComplete: true,
                    assignedIssues,
                };
            } else {
                return initialState;
            }
        case FETCH_DRAFTS_END:
            if (!action.error) {
                const { drafts } = action.payload;

                return {
                    ...state,
                    loading: false,
                    loadComplete: true,
                    drafts: drafts,
                };
            } else {
                return initialState;
            }
        case FETCH_END:
            if (!action.error) {
                const { issues } = action.payload;

                return {
                    ...state,
                    loading: false,
                    loadComplete: true,
                    data: issues,
                };
            } else {
                return initialState;
            }

        case CHANGE_REQUEST:
            return {
                ...state,
                loading: false,
                loadComplete: false,
            };
        case SET_ACTIVE_ISSUES_AMOUNT:
            const activeIssuesAmount = action.payload;
            return { ...state, activeIssuesAmount: activeIssuesAmount };
        case SET_UPDATE_TRACKING:
            return { ...state, updateTracking: action.payload };
        case FETCH_TRACKING_START:
                return {
                    ...state,
                    tracking: {
                        loading: true,
                        hasMore: false,
                        criteria: { ...action.payload },
                        issues: []
                    }
                }
        case FETCH_TRACKING_END:
            return {
                ...state,
                tracking: {
                    loading: false,
                    hasMore: action.payload.issues && action.payload.issues.length > 0,
                    criteria: { ...action.payload.criteria },
                    issues: [ ...action.payload.issues ]
                }
            }
        case FETCH_TRACKING_MORE_START:
                return {
                    ...state,
                    tracking: {
                        ...state.tracking,
                        loading: true,
                        criteria: { ...action.payload }
                    }
                }
        case FETCH_TRACKING_MORE_END:
            return {
                ...state,
                tracking: {
                    loading: false,
                    hasMore: action.payload.issues && action.payload.issues.length > 0,
                    criteria: { ...action.payload.criteria },
                    issues: [ ...state.tracking.issues, ...action.payload.issues ]
                }
            }
        case UPDATE_ISSUE:
            {
                const issues = [...state.tracking.issues];
                const itemIndex = issues.findIndex(x => x.id === action.payload.id);
                if (itemIndex >= 0) {
                    issues[itemIndex] = { ...action.payload };
                }

                return {
                    ...state,
                    tracking: {
                        ...state.tracking,
                        issues: [...issues]
                    }
                }
            }

        default:
            return state || initialState;
    }
}

function* fetchAssignedIssuesSaga() {
    yield put(fetchStart());

    try {
        const response = yield call(getIssuesAssigned);
        const assignedIssues = response.data;

        yield put(fetchAssignedSuccess(assignedIssues));
    } catch (error) {
        const requestError = new RequestError(error, getIssuesError);

        yield put(fetchFailed(requestError));
        yield put(showErrorAlert(requestError.message));

        return {
            error: {
                message: getIssuesError,
                payload: error,
            },
        };
    }
}

function* fetchIssuesSaga() {
    yield put(fetchStart());
    try {
        const response = yield call(getIssueActive);
        const issues = response.data;
        yield put(fetchSuccess(issues));
    } catch (error) {
        const requestError = new RequestError(error, getIssuesError);
        yield put(showErrorAlert(requestError.message));
    }
}

function* fetchIssuesDraftSaga({ payload = {} }) {
    yield put(fetchStart(payload));

    try {
        const { pageNum = 1, pageSize = 3 } = payload;
        const drafts = yield call(getIssueDrafts, pageNum, pageSize);
        yield put(fetchDraftSuccess(drafts.data));
    } catch (error) {
        const requestError = new RequestError(error, getIssuesError);
        yield put(showErrorAlert(requestError.message));
    }
}

function* deleteIssueSaga({ payload }) {
    try {
        yield call(deleteIssue, payload);
        yield put(push('/'));
        yield put(showSuccessAlert('Черновик успешно удален'));
    } catch (error) {
        const requestError = new RequestError(error, deleteIssueError);
        yield put(showErrorAlert(requestError.message));
    }
}

function* fetchTrackingSaga({ payload }) {
    try {
        const response = yield call(getIssuesAssigned, payload);
        yield put(fetchEndTracking(response.data, payload));
    } catch (error) {
        const requestError = new RequestError(error, getIssuesError);
        yield put(showErrorAlert(requestError.message));
    }
}

function* fetchTrackingMoreSaga({ payload }) {
    try {
        const response = yield call(getIssuesAssigned, payload);
        yield put(fetchEndTrackingMore(response.data, payload));
    } catch (error) {
        const requestError = new RequestError(error, getIssuesError);
        yield put(showErrorAlert(requestError.message));
    }
}

export function* saga() {
    yield all([
        takeLatest(FETCH_REQUEST, fetchIssuesSaga),
        takeLatest(FETCH_ASSIGNED_REQUEST, fetchAssignedIssuesSaga),
        takeLatest(FETCH_DRAFTS, fetchIssuesDraftSaga),
        takeLatest(DELETE_ISSUE, deleteIssueSaga),
        takeEvery(CHANGE_REQUEST, fetchIssuesSaga),
        takeEvery(CHANGE_REQUEST, fetchIssuesDraftSaga),
        takeLatest(FETCH_TRACKING_START, fetchTrackingSaga),
        takeLatest(FETCH_TRACKING_MORE_START, fetchTrackingMoreSaga),
    ]);
}
