import { appName } from '../constants';
import { takeEvery, select, put } from 'redux-saga/effects';


const moduleName = 'acceptModal';
const SHOW = `${appName}/${moduleName}/SHOW`;
const HIDE = `${appName}/${moduleName}/HIDE`;
const ACCEPT = `${appName}/${moduleName}/ACCEPT`;


export default function reducer(state, action) {
    switch (action.type) {
        case SHOW:
            return {
                item: action.payload,
            };

        case HIDE:
            return {
                item: null,
            };

        default:
            return state || {
                item: null,
            };
    }
}


export function show(title, question, actions, actionType) {
    return {
        type: SHOW,
        payload: {
            title,
            question,
            actions,
            actionType,
        },
    };
}

export function accept(value) {
    return {
        type: ACCEPT,
        payload: { value },
    };
}

export function hide(action) {
    return {    
        type: HIDE,
        payload: action.payload,
    };
}


function* invokeSaga(action) {
    const item = yield select(stateItem);
    
    yield put({
        type: item.actionType,
        payload: action.payload,
    });

    yield put({ type: HIDE });
}

export function* saga() {
    yield takeEvery(ACCEPT, invokeSaga);
}


function stateItem(state) {
    return state.acceptModal.item;
}
