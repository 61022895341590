import { useEffect } from 'react';

export const useUnloadBlocker = preventDefault => {
    useEffect(() => {
        const handleBeforeUnload = event => {
            if (!preventDefault) {
                return event.preventDefault();
            }
            event.returnValue = preventDefault;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [preventDefault]);
};
