import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { showErrorAlert } from 'ducks/Alert';
import ModalDialog from 'components/common/ModalDialog';
import Select from 'components/uikit/Select';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import Button from 'components/uikit/Button';
import DatePicker from 'components/uikit/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { getEvaluationTestResultFileLink, addEvaluationTestResults } from 'api';
import { CRITERIA_TYPE_OTHER_STRING, CRITERIA_TYPE_GRADE } from 'constants.js';
import uuid from 'uuid/v4';
import { Row, Col } from 'react-flexbox-grid';
import { getValidationErrors } from 'serviceErrors';

const ManualResults = (props) => {
    const { handleStateChange, testList, addResult, isOpenEditTestResultsModal, canRequest } =
        props;
    const { testProviders } = props;

    const handleAddResultProvider = (value) => {
        handleStateChange('provider', value, 'addResult');
        handleStateChange('test', null, 'addResult');
    };

    const handleAddResultTest = (value) => {
        const criteria = testList && testList.find((x) => x.id === value.code);
        setNewTestCriteria(criteria);
        setEvaluationTestId(value.code);
        handleStateChange('test', value, 'addResult');
    };

    const setEvaluationTestId = (id) => {
        handleStateChange('evaluationTestId', id, 'addResult');
    };

    const setNewTestCriteria = (value) => {
        if (!value) {
            return null;
        }
        const { criteria, criteriaOrder } = value;
        const orderedCriteria = criteriaOrder.map((x) => {
            return {
                ...criteria[x],
                id: uuid(),
                evaluationTestCriterionId: criteria[x].id,
                decimalValue: '',
                integerValue: '',
                stringValue: '',
            };
        });
        handleStateChange('criteria', orderedCriteria, 'addResult');
    };

    const handleAddResultCriteria = (e) => {
        const criteriaId = e.target.getAttribute('criteriaid');
        const index = e.target.getAttribute('index');
        const value = e.target.value;
        const type = e.target.getAttribute('type');
        const valueObj = type === 'number' ? { decimalValue: value } : { stringValue: value };
        const newCriteria = [...addResult.criteria];
        let foundCriterion = newCriteria.find((x) => x.id === criteriaId);
        if (!foundCriterion) {
            const test = testList && testList.find((x) => x.id === addResult?.evaluationTestId);
            const evaluationTestCriterias = test && test.criteria && Object.values(test.criteria);
            const title = addResult.criteria?.[index]?.title;
            const evaluationTestCriterionId =
                evaluationTestCriterias &&
                title &&
                evaluationTestCriterias.find((x) => x.title === title)?.id;
            if (!evaluationTestCriterionId) {
                newCriteria.splice(index, 1);
                handleStateChange('criteria', newCriteria, 'addResult');
                return;
            }
            foundCriterion = {
                ...newCriteria[index],
                id: uuid(),
                evaluationTestCriterionId: evaluationTestCriterionId,
                evaluationTestResultId: addResult.resultId,
                status: 'Unknown',
            };
        }
        const newLine = { ...foundCriterion, ...valueObj };
        newCriteria.splice(index, 1, newLine);
        handleStateChange('criteria', newCriteria, 'addResult');
    };

    const handleAddResultCriteriaFromSelect = (e, code, index) => {
        const newCriteria = [...addResult.criteria];
        const newLine = { ...newCriteria.find((x) => x.id === code), stringValue: e?.name };
        newCriteria.splice(index, 1, newLine);
        handleStateChange('criteria', newCriteria, 'addResult');
    };

    const handleAddResultDate = (value) => {
        handleStateChange('date', value || new Date(), 'addResult');
    };

    const [processing, setProcessing] = useState(false);
    const renderAddTestResultsModal = () => {
        return (
            <ModalDialog
                onClick={saveTestResults}
                onCloseModal={onCancelAddTestResults}
                modalOpen={isOpenEditTestResultsModal}
                modalHeader={`${
                    addResult.type === 'new'
                        ? 'Добавление результатов оценки'
                        : 'Редактирование результатов оценки'
                }`}
                btnOktext={`${addResult.type === 'new' ? 'Добавить' : 'Сохранить'}`}
                btnCanceltext="Отмена"
                size="xl"
                isValidForm={isFormValid()}
                processing={processing}
            >
                <Row>
                    <Col xs={12}>{renderAddResultProviderSelector()}</Col>
                    <Col xs={12}>{renderAddResultTestSelector()}</Col>
                    <Col xs={12}>{renderAddResultFileDate()}</Col>
                    <Col xs={12}>{renderAddResultCriteria()}</Col>
                </Row>
            </ModalDialog>
        );
    };

    const getResultToSave = () => {
        const { addResult, personId } = props;
        return {
            id: addResult.resultId,
            personId: personId,
            completedOn: addResult.date,
            evaluationTestId: addResult.evaluationTestId,
            linkToResult: addResult.linkToResult || '',
            resultFileId: addResult.resultFileId || '',
            criteria: [...addResult.criteria],
        };
    };

    const saveTestResults = () => {
        callEffect(async () => {
            const data = getResultToSave();
            const files = fileUploadEl.current.files;
            data.id
                ? await addEvaluationTestResults(
                      data,
                      files.length !== 0 && fileUploadEl.current.files[0],
                      'update',
                  )
                : await addEvaluationTestResults(
                      { ...data, id: uuid() },
                      files.length !== 0 && fileUploadEl.current.files[0],
                      'create',
                  );
            props.updateResults(props.personId);
            onCancelAddTestResults();
        });
    };

    const renderAddResultProviderSelector = () => {
        return (
            <div className="Modal-Children__Line">
                <Field required filled={addResult.provider} invalid={false}>
                    <Label>Поставщик</Label>
                    <Select
                        value={addResult.provider}
                        onChange={handleAddResultProvider}
                        options={testProviders.map((x) => ({ ...x, value: x.id, label: x.title }))}
                        isDisabled={addResult.type === 'edit'}
                        catalog
                    />
                </Field>
            </div>
        );
    };

    const renderAddResultTestSelector = () => {
        const { provider } = addResult;
        return (
            <div className="Modal-Children__Line">
                <Field required filled={addResult.test} invalid={false}>
                    <Label>Список оценок</Label>
                    <Select
                        options={
                            provider
                                ? testList
                                      .filter((x) => x.testProviderId === provider.code)
                                      .map((x) => ({
                                          code: x.id,
                                          name: x.title,
                                          value: x.id,
                                          label: x.title,
                                      }))
                                : []
                        }
                        value={addResult.test}
                        onChange={handleAddResultTest}
                        isDisabled={addResult.type === 'edit'}
                        catalog
                    />
                </Field>
            </div>
        );
    };

    const renderAddResultCriteria = () => {
        const { criteria } = addResult;
        return (
            <>
                {criteria && criteria.length !== 0 && (
                    <div>
                        <div className="Add-Result-Table">
                            <div className="Add-Result-Table__Header">
                                <div className="Col-50">Название</div>
                                <div className="Col-50 Col--centered">Результат</div>
                            </div>
                            <div className="Add-Result-Table__Body Add-Result-Table__Body--no-footer">
                                {criteria.map((x, index) => (
                                    <div key={index} className="Add-Result-Table__Line">
                                        {renderCriteriaLine(x, index)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const renderCriteriaLine = (data, index) => {
        const {
            id,
            title,
            gradesInAscendingOrder,
            scaleLowerBound,
            scaleUpperBound,
            decimalValue,
            stringValue,
        } = data;
        const value = decimalValue != null ? decimalValue : stringValue;
        return (
            <>
                <div className="Col-50 Col-50--text-line">
                    {title}
                    <div className="Add-Result-Table__Line--hint">
                        {gradesInAscendingOrder && gradesInAscendingOrder[0]
                            ? `Возможные значения: ${gradesInAscendingOrder}`
                            : `Значение от ${scaleLowerBound} до ${scaleUpperBound}`}
                    </div>
                </div>
                <div className="Col-50">
                    <Field
                        noLabel
                        filled={id && value != null}
                        invalid={value != null && !isResultValid(data)}
                    >
                        {renderResultInput(data, index)}
                    </Field>
                </div>
            </>
        );
    };

    const renderResultInput = (data, index) => {
        const { gradesInAscendingOrder, id, scaleUpperBound } = data;
        const value =
            data.decimalValue != null &&
            data.valueKind !== CRITERIA_TYPE_GRADE &&
            data.valueKind !== CRITERIA_TYPE_OTHER_STRING
                ? data.decimalValue
                : data.stringValue;
        let resultsArray;
        if (addResult.type === 'new') {
            resultsArray = gradesInAscendingOrder && gradesInAscendingOrder.split(',');
        } else {
            resultsArray = gradesInAscendingOrder;
        }
        const maxLength = scaleUpperBound?.toString()?.length;

        return gradesInAscendingOrder && gradesInAscendingOrder[0] ? (
            <>
                <Select
                    isClearable
                    options={resultsArray.map((x) => ({
                        code: id,
                        name: x,
                        index: index,
                        value: x.id,
                        label: x.title,
                    }))}
                    value={{ code: id, name: value } || resultsArray[0]}
                    onChange={(e) => handleAddResultCriteriaFromSelect(e, id, index)}
                    catalog
                />
            </>
        ) : (
            <>
                <Input
                    type="number"
                    criteriaid={id}
                    value={value != null ? value : ''}
                    index={index}
                    onChange={(e) => {
                        handleAddResultCriteria(e, maxLength);
                    }}
                />
            </>
        );
    };

    const renderAddResultFileDate = () => {
        return (
            <div className="Modal-Children__Line">
                <Row>
                    <Col xs={6}>{renderAddResultDate()}</Col>
                    <Col xs={6}>{renderAddResultFile()}</Col>
                </Row>
            </div>
        );
    };

    const renderAddResultDate = () => {
        return (
            <>
                <Field required filled={addResult.date} invalid={addResult.date && !isDateValid()}>
                    <Label>Оценка получена</Label>
                    <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="dd.MM.yyyy"
                        selected={addResult.date}
                        onChange={handleAddResultDate}
                        maxDate={new Date()}
                    />
                </Field>
            </>
        );
    };

    const fileUploadEl = useRef(null);

    const handleDeleteFile = () => handleStateChange('resultFileId', null, 'addResult');

    const renderAddResultFile = () => {
        const link = addResult.resultFileId && (
            <a
                className="FileDownloadLink"
                href={getEvaluationTestResultFileLink(addResult.resultId)}
            >
                <FontAwesomeIcon icon={faFileDownload} />
            </a>
        );

        const removeLink = addResult.resultFileId && (
            <FontAwesomeIcon
                className="FileDeleteLink"
                icon="times"
                color="#f34343"
                onClick={handleDeleteFile}
            />
        );

        const fileName = addResult.file && addResult.file.name;

        return (
            <Field filled={fileName}>
                <Label>
                    {link ? (
                        <>
                            <span>Заменить текущий файл</span>
                            {link} {removeLink}
                        </>
                    ) : (
                        'Добавить файл'
                    )}
                </Label>
                <div className="FileUploadButton">
                    <label htmlFor="file-upload" className="FileUploadButton__Label">
                        {fileName ? (
                            fileName
                        ) : (
                            <>
                                <FontAwesomeIcon icon="file-upload" />
                                Выберите файл
                            </>
                        )}
                        <input
                            id="file-upload"
                            type="file"
                            ref={fileUploadEl}
                            onChange={() => handleFileUploadChange(fileUploadEl)}
                        />
                    </label>
                    {fileName && (
                        <div className="FileUploadButton--action-btn">
                            <Button closeLink size="xs" onClick={onClearFile} />
                        </div>
                    )}
                </div>
                <small className="FileUploadHint">*Размер файла не должен превышать 100 Мб</small>
            </Field>
        );
    };

    const handleFileUploadChange = (fileUploadEl) => {
        const file = fileUploadEl.current.files[0];
        handleStateChange('file', file || null, 'addResult');
    };

    const onClearFile = () => {
        fileUploadEl.current.value = '';
        handleStateChange('file', null, 'addResult');
    };

    const isResultValid = ({
        scaleLowerBound,
        scaleUpperBound,
        gradesInAscendingOrder,
        decimalValue,
        stringValue,
        valueKind,
        id,
    }) => {
        const value =
            decimalValue != null &&
            valueKind !== CRITERIA_TYPE_GRADE &&
            valueKind !== CRITERIA_TYPE_OTHER_STRING
                ? decimalValue
                : stringValue || '';

        return gradesInAscendingOrder && gradesInAscendingOrder[0]
            ? true
            : id
            ? value != null && value >= scaleLowerBound && value <= scaleUpperBound
            : true;
    };

    const isDateValid = () => {
        const date = new Date(addResult.date);
        return moment().isSameOrAfter(moment(date), 'minute');
    };

    const isFormValid = () => {
        return (
            addResult.criteria.length !== 0 &&
            !addResult.criteria.filter((x) => !isResultValid(x)).length > 0 &&
            addResult.criteria.filter((x) => !!x.stringValue || (x.decimalValue != null && x.decimalValue !== '')).length >= 1 &&
            addResult.date !== '' &&
            isDateValid()
        );
    };

    const onCancelAddTestResults = () => {
        clearAddTestResults();
        handleStateChange('isOpenEditTestResultsModal', false);
    };

    const clearAddTestResults = () => {
        const emptyAddResult = {
            resultId: null,
            resultFileId: null,
            provider: null,
            test: null,
            criteria: [],
            submitted: false,
            type: 'new',
            date: '',
            file: null,
        };
        handleStateChange('addResult', emptyAddResult);
    };

    const openAddTestResultsModal = () => {
        handleStateChange('isOpenEditTestResultsModal', true);
    };

    const callEffect = async (callback) => {
        try {
            setProcessing(true);
            await callback();
        } catch (error) {
            const [err] = getValidationErrors(error);
            props.showErrorAlert((err || error).message);
        } finally {
            setProcessing(false);
        }
    };

    return (
        <>
            {canRequest && renderAddTestResultsModal()}
            {canRequest && (
                <Button size="sm" onClick={openAddTestResultsModal}>
                    Добавить результаты оценки
                </Button>
            )}
        </>
    );
};
const actions = { showErrorAlert };

export default connect(null, actions)(ManualResults);
