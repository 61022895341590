import React, { Component } from 'react';

import RoundButton from 'components/uikit/Button/RoundButton';
import ToggleOpen from 'components/hoc/ToggleOpen';
import EditPersonCommentBlock from './Edit/PersonCommentBlock';
import { deleteComment } from 'api.js';
import { getUserShortName, localizeTimezone } from 'utils.js';
import Pagination from 'components/common/Pagination';
import { default as LocalLoader } from 'components/common/Loader';
import ModalDialog from 'components/common/ModalDialog';
import { PERSON_COMMENT_TYPE_ENUM, PERSON_COMMENT_VISIBILITY_ENUM } from 'constants.js';

import './PersonCommentsBlock.scss';
import { fetchComments, setPaging } from 'ducks/PersonComments';
import { connect } from 'react-redux';
import DataCardWrapper from 'components/common/DataCard/components/DataCardWrapper';
import { showSuccessAlert } from 'ducks/Alert';

class PersonCommentsBlock extends Component {
    state = {
        deleteModalOpen: false,
        commentId: null,
        loading: false,
    };

    _mounted = true;

    componentWillUnmount() {
        this._mounted = false;
    }

    componentDidUpdate(prevProps) {
        const { blockRef } = this.props;
        if (this.props.isOpen && !prevProps.isOpen) {
            const paging = { pageSize: 10, pageNum: 1 };
            this.props.fetchComments(this.props.personId, paging);
        }

        if (prevProps.paging.pageSize !== this.props.paging.pageSize) {
            const { paging } = this.props;
            this.props.fetchComments(this.props.personId, { pageSize: paging.pageSize, pageNum: 1 });
        }

        if (prevProps.comments !== this.props.comments) {
            window.scrollTo(0, blockRef.current.offsetTop - 100);
        }
    }

    getVisibility = (visibility) => {
        switch (visibility) {
            case PERSON_COMMENT_VISIBILITY_ENUM.Everyone:
                return 'Все, у кого есть доступ к данной анкете резервиста';
            case PERSON_COMMENT_VISIBILITY_ENUM.Group:
                return 'Все, у кого такая же группа, как у автора';
            default:
                return 'Только я';
        }
    };

    getAttributeVisibilityInfo = (comment, isCommentCurrentUser) => {
        return isCommentCurrentUser ? (
            <div className="PersonCommentsBlock__AttributeVisibility">
                <div>Кто видит мою заметку:</div>
                <div className="PersonCommentsBlock__AttributeVisibility--attr">
                    {this.getVisibility(comment.visibility)}
                </div>
            </div>
        ) : (
            ''
        );
    };

    getTypeComment = (comment) => {
        switch (comment.type) {
            case PERSON_COMMENT_TYPE_ENUM.Person:
                return <span>(из&nbsp;анкеты)</span>;
            case PERSON_COMMENT_TYPE_ENUM.Wishlist:
                return <span>(из&nbsp;подборки)</span>;
            default:
                return '';
        }
    };

    getCommentInfo = (comment, isCommentCurrentUser) => {
        const author = isCommentCurrentUser ? 'Моя заметка' : getUserShortName(comment.author);

        return `${author}, ${localizeTimezone(
            comment.modified || comment.created,
            'DD.MM.YYYY HH:mm',
        )} ${this.getTypeComment(comment)?.props?.children || ''}:`;
    };

    handlePaginate = (pageNum) => {
        const newPaging = { ...this.props.paging, pageNum };

        this.props.fetchComments(this.props.personId, newPaging);
    };

    onCloseModal = () => {
        this.setState({ deleteModalOpen: false, commentId: null });
    };

    deleteComment = async (id) => {
        await deleteComment(id);

        this.onCloseModal();
        this.props.showSuccessAlert('Заметка успешно удалена');
        const newPaging = { ...this.props.paging, pageNum: 1 };
        this.props.fetchComments(this.props.personId, newPaging);
    };

    openModal = (id) => {
        this.setState({ deleteModalOpen: true, commentId: id });
    };

    renderComment(comment, currentUserId) {
        const isCommentCurrentUser = currentUserId === comment.author.id;
        const attributeVisibility = this.getAttributeVisibilityInfo(comment, isCommentCurrentUser);
        return (
            <div className="kr_tr PersonCommentsBlock__Comment" key={comment.id}>
                <div className="PersonCommentsBlock__CommentContainer">
                    {this.getCommentInfo(comment, isCommentCurrentUser)}
                    <div className="PersonCommentsBlock__Text">{comment.text}</div>
                    {attributeVisibility}
                </div>

                {isCommentCurrentUser && comment.type === PERSON_COMMENT_TYPE_ENUM.Person && (
                    <RoundButton type="close" onClick={() => this.openModal(comment.id)} />
                )}
            </div>
        );
    }

    changePageAmount = (amount) => {
        this.props.setPaging({ pageNum: 1, pageSize: amount });
    };

    renderListComments = () => {
        const { pageCount, foundCount, paging, comments, loading } = this.props;
        return (
            <>
                {loading ? (
                    <LocalLoader />
                ) : (
                    comments.map((c) => this.renderComment(c, this.props.currentUser.id))
                )}
                <div className="PersonCommentsBlock__Pagination">
                    <Pagination
                        currentPageNum={paging.pageNum}
                        pageCount={pageCount}
                        totalCount={foundCount}
                        //shownTotal={comments.length}
                        pageSize={paging.pageSize}
                        onPaginate={this.handlePaginate}
                        changePageAmount={this.changePageAmount}
                    />
                </div>
            </>
        );
    };

    renderView() {
        return (
            <DataCardWrapper
                shadow
                title="Заметки"
                className="kr_profile-anketa-view"
                onChange={
                    this.props.canEditPerson
                        ? () => this.props.setEditBlock('PersonCommentsBlock')
                        : null
                }
                onToggle={this.props.toggleOpen}
                isOpen={this.props.isOpen}
            >
                <div className="kr_like-table">
                    {this.props.comments && this.props.comments.length > 0
                        ? this.renderListComments()
                        : this.props.renderEmptyBlock()}
                </div>

                <ModalDialog
                    onClick={() => this.deleteComment(this.state.commentId)}
                    onCloseModal={this.onCloseModal}
                    modalOpen={this.state.deleteModalOpen}
                    modalHeader="Вы действительно хотите удалить заметку?"
                    btnOktext="Да"
                    btnOkColor="danger"
                    btnCanceltext="Нет"
                />
            </DataCardWrapper>
        );
    }

    renderEdit() {
        return <EditPersonCommentBlock {...this.props} setEditBlock={this.props.setEditBlock} />;
    }

    render() {
        return this.props.isEditBlock ? this.renderEdit() : this.renderView();
    }
}

const mapStateToProps = ({ personComments }) => {
    return {
        comments: personComments.data,
        pageCount: personComments.meta.pageCount,
        foundCount: personComments.meta.foundCount,
        paging: personComments.criteria,
        loading: personComments.loading,
    };
};

export default connect(mapStateToProps, { fetchComments, showSuccessAlert, setPaging })(
    ToggleOpen(PersonCommentsBlock),
);
