import memoize from 'fast-memoize';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showWarningAlert } from 'ducks/Alert';
import { loadAttributes } from 'ducks/Attributes';
import { fetchCatalog, GOVT_SERVICE_KINDS, REGION_LOCALITIES } from 'ducks/Catalog';
import {
    allPersonCatalogs, educationBlockCatalogs, familyStatusBlockCatalogs, fetchPerson, languagesBlockCatalogs,
    newPerson, newPersonSelector, personalInfoBlockCatalogs, personFullSelector, savePerson, savePhoto,
    socialNetworksBlockCatalogs, workInfoBlockCatalogs, personnelInfoBlockCatalogs, setSnilsIsOk, clearPerson, setShowSnilsError
} from 'ducks/Person';
import { fileToBase64, trimEnd, isNull, isNullOrWhitespace, trimObjectStrings } from 'utils';
import Button from "components/uikit/Button";
import { DataCard } from 'components/common/DataCard';
import Page from "components/common/Page";
import EditAttributesBlock from './AttributesBlock';
import EducationBlock from './EducationBlock';
import FamilyStatusBlock from './FamilyStatusBlock';
import FilesBlock from './FilesBlock';
import FloatMenu from './FloatMenu';
import LanguagesBlock from './LanguagesBlock';
import PersonalInfoBlock from './PersonalInfoBlock';
import PersonnelInfoBlock from './PersonnelInfoBlock';
import PhotoBlock from 'components/common/PhotoBlock';
import SocialNetworksBlock from './SocialNetworksBlock';
import WorkBlock from './WorkBlock';
import SystemInfoBlock from './SystemInfoBlock';
import uuid from "uuid/v4";

import "./EditPerson.scss";
import { BIRTH_DATE_DISPLAY_MODES, shortUrlRegex } from 'constants.js';

const menuItems = [
    { anchor: 'personalInfo', text: 'Личные данные' },
    { anchor: 'educationInfo', text: 'Образование' },
    { anchor: 'workInfo', text: 'Опыт работы' },
    { anchor: 'languagesInfo', text: 'Иностранные языки' },
    { anchor: 'familyInfo', text: 'Семейное положение' },
    { anchor: 'socialNetworksInfo', text: 'Социальные сети' },
    { anchor: 'filesInfo', text: 'Файлы' },
    { anchor: 'personnelInfo', text: 'Дополнительная информация' },
    { anchor: 'attributesInfo', text: 'Дополнительные поля' },
    { anchor: 'systemInfoInfo', text: 'Системная информация' },
];



const blockFillRules = {
    personalInfo: (values) => {
        let result = !isNullOrWhitespace(values.firstName)
            && !isNullOrWhitespace(values.lastName)
            && !isNullOrWhitespace(values.middleName)
            && !isNull(values.birthDate)
            && !isNullOrWhitespace(values.birthPlace)
            && !isNull(values.selectedSex)
            && !isNull(values.selectedDocument)
            && !isNullOrWhitespace(values.documentNumber)
            && !isNullOrWhitespace(values.email)
            && !isNullOrWhitespace(values.phone)
            && !isNull(values.selectedDistrict)
            && !isNull(values.selectedRegion)
            && !isNull(values.snils)
            && !isNull(values.selectedLocality);

        if (result && !isNull(values.pastResidences)
            && values.pastResidences.length > 0) {

            for (const resident of values.pastResidences) {
                if (isNull(resident.selectedDistrict)
                    || isNull(resident.selectedRegion)
                    || isNullOrWhitespace(resident.yearFrom)
                    || isNullOrWhitespace(resident.yearTo)) {
                    return false;
                }
            }

            return true;
        }

        return result;
    },
    educationInfo: (values) => {
        if (isNull(values.educations) || values.educations.size === 0)
            return false;

        for (const education of values.educations) {
            if (isNull(education.educationLevel)) {
                return false;
            }
        }

        return true;
    },
    workInfo: (values) => {
        if (isNull(values.workPlaces) || values.workPlaces.size === 0)
            return false;

        for (const workPlace of values.workPlaces) {
            if (isNullOrWhitespace(workPlace.company)
                || isNullOrWhitespace(workPlace.position)
                || isNullOrWhitespace(workPlace.hireDate)) {
                return false;
            }
        }

        return true;
    },
    languagesInfo: (values) => {
        if (isNull(values.knownLanguages) || values.knownLanguages.size === 0) {
            return false;
        }

        for (const language of values.knownLanguages) {
            if (isNull(language.language)
                || isNull(language.languageLevel)) {
                return false;
            }
        }

        return true;
    },
    familyInfo: (values) => {
        return !isNull(values.selectedFamilyStatus);
    },
    socialNetworksInfo: (values) => {
        if (isNull(values.networks) || values.networks.size === 0) {
            return false;
        }

        for (const network of values.networks) {
            if (isNullOrWhitespace(network.value) || !network.value.match(shortUrlRegex)) {
                return false;
            }
        }

        return true;
    },
    filesInfo: (values) => {
        return (!isNull(values.files)
            && values.files.size > 0);
    },
    personnelInfo: (values) => {
        return !isNull(values.selectedDataOrigin);
    },
    attributesInfo: (values) => {
        if (isNull(values.attributes) || values.attributes.size === 0) {
            return false;
        }

        for (const attribute of values.attributes) {
            if (isNull(attribute.attribute)) { return false; }

            if (attribute.attributeValue && attribute.attributeValue.length !== 0) {
                return true;
            }

            if (isNullOrWhitespace(attribute.attributeValue)) {
                return false;
            }
        }

        return true;
    }
}

class EditPerson extends Component {
    constructor(props) {
        super(props);

        this.cardRefs = {
            personalInfo: React.createRef(),
            educationInfo: React.createRef(),
            workInfo: React.createRef(),
            languagesInfo: React.createRef(),
            familyInfo: React.createRef(),
            socialNetworksInfo: React.createRef(),
            filesInfo: React.createRef(),
            personnelInfo: React.createRef(),
            attributesInfo: React.createRef(),
            systemInfoInfo: React.createRef(),
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.loadComplete) {
            return null;
        }

        const { person } = props;

        let loadComplete = person.loadComplete;
        if (state.loadComplete == null) {
            loadComplete = loadComplete && person.isActual;
        }

        if (!loadComplete) {
            return { loadComplete };
        }

        const {
            personalInfo = {}, educationInfo = {}, workInfo = {}, governmentServiceInfo = {},
            evaluationInfo = {},
            languagesInfo = {}, socialNetworksInfo = {}, filesInfo = {}, filesDirectoryId, attributesInfo = {},
            personnelInfo = {}, systemInfo = {},
        } = person;
        
        return {
            loadComplete: true,
            person: {
                personalInfo: {
                    id: personalInfo.id,
                    snils: personalInfo.snils|| '',
                    lastName: personalInfo.lastName || '',
                    firstName: personalInfo.firstName || '',
                    middleName: personalInfo.middleName || '',
                    selectedSex: personalInfo.sex || null,
                    birthDate: personalInfo.birthDate,
                    birthDateDisplayMode : personalInfo.birthDateDisplayMode || BIRTH_DATE_DISPLAY_MODES.FullDate,
                    birthPlace: personalInfo.birthPlace || '',
                    email: personalInfo.email || '',
                    phone: personalInfo.phone || '',
                    selectedDistrict: personalInfo.federalDistrict || null,
                    selectedRegion: personalInfo.region || null,
                    selectedLocality: personalInfo.locality || null,
                    selectedDocument: personalInfo.document || null,
                    documentNumber: personalInfo.documentNumber || '',
                    documentName: personalInfo.documentName || '',
                    isRussianCitizen: personalInfo.isRussianCitizen,
                    foreignRegion: personalInfo.foreignRegion || '',
                    foreignLocality: personalInfo.foreignLocality || '',
                    residenceStartYear: personalInfo.residenceStartYear || '',
                    selectedNationality: personalInfo.nationality || null,
                    readyMoveToRussia: personalInfo.readyMoveToRussia || false,
                    photoUri: personalInfo.photoUri,
                    extraInfo: personalInfo.extraInfo || '',
                    pastResidences: personalInfo.pastResidences || [],
                },
                educationInfo: {
                    educations: List(educationInfo.educations)
                },
                workInfo: {
                    workPlaces: List(workInfo.workPlaces),
                },
                governmentServiceInfo: {
                    governmentServices: List(governmentServiceInfo.governmentServices),
                },
                evaluationInfo: {
                    evaluations: List(evaluationInfo.evaluations),
                },
                languagesInfo: {
                    knownLanguages: List(languagesInfo.knownLanguages),
                },
                socialNetworksInfo: {
                    networks: List(socialNetworksInfo.networks),
                },
                foreignerInfo: {
                    selectedNationality: personalInfo.nationality || null,
                    readyMoveToRussia: personalInfo.readyMoveToRussia || false,
                },
                familyInfo: {
                    selectedFamilyStatus: personalInfo.familyStatus || null,
                    selectedChildrenStatus: personalInfo.childrenStatus || null,
                },
                filesInfo: {
                    files: List(filesInfo.files),
                },
                personnelInfo: {
                    id: personnelInfo.id,
                    awards: personnelInfo.awards,
                    selectedDataOrigin: personnelInfo.dataOrigin || null,
                    recommenderName: personnelInfo.recommenderName || '',
                    curatorId: personnelInfo.selectedCurator && personnelInfo.selectedCurator.value.id,
                    curatorName: personnelInfo.curatorName,
                    selectedCuratorName: personnelInfo.selectedCuratorName,
                    selectedGeoLevel: personnelInfo.selectedGeoLevel,
                    selectedManagerLevel: personnelInfo.selectedManagerLevel, 
                    selectedExperienceLevel: personnelInfo.experienceLevel || null,
                    socialActivity: personalInfo.socialActivity || '',
                    memberOfSocialOrganizations: personalInfo.memberOfSocialOrganizations || '',
                    socialAchivements: personalInfo.socialAchivements || '',
                    extraInfo: personnelInfo.extraInfo,
                    reserveLevel: personnelInfo.reserveLevel || '',
                    inclusionReason: personnelInfo.inclusionReason || '',
                },
                attributesInfo: {
                    attributes: List(attributesInfo.attributes).map(attribute => ({
                        ...attribute,
                        attributeValue: (attribute.attribute.type.toLowerCase() === "select"
                            || attribute.attribute.type.toLowerCase() === "multiselect")
                            ? JSON.parse(attribute.attributeValue)
                            : attribute.attributeValue,
                        attribute: {
                            ...attribute.attribute, local_id: uuid(),
                            payload: attribute.attribute.payload && JSON.parse(attribute.attribute.payload),
                        },

                    }))
                },
                filesDirectoryId,
                systemInfo: {
                    groups: systemInfo.groups,
                    selectedGroup: systemInfo.groupId || props.currentUserGroupId,
                },
            },
            isBlockFilled: {}
        }
    }

    state = {
        person: {},
        loadComplete: null,
        isFormSubmitted: false,
        isBlockFilled: {
            personalInfo: false,
            educationInfo: false,
            workInfo: false,
            languagesInfo: false,
            familyInfo: false,
            socialNetworksInfo: false,
            filesInfo: false,
            personnelInfo: false,
            attributesInfo: false,
            systemInfo: false,
        }
    }

    componentDidMount() {
        this.props.setSnilsIsOk(false);

        if (this.isNewPerson()) {
            this.props.clearPerson();
        }

        if (this.state.loadComplete) {
            return;
        }

        const criteria = { paging: { pageNum: 1, pageSize: 50 } };
        this.props.loadAttributes(criteria);

        if (this.isNewPerson()) {
            this.props.newPerson();
        } else {
            this.props.fetchPerson(this.props.id);
        }
    }

    isNewPerson() {
        return !this.props.id;
    }

    handleStateChange = (blockStateKey, blockState) => {
        this.setState(state => {
            const person = {
                ...state.person,
                [blockStateKey]: { ...state.person[blockStateKey], ...blockState }
            }

            const blockFilled = {
                ...state.isBlockFilled,
                [blockStateKey]: !isNull(blockFillRules[blockStateKey])
                    ? blockFillRules[blockStateKey](person[blockStateKey])
                    : false
            }
            return {
                ...state,
                person: { ...person },
                isBlockFilled: { ...blockFilled }

            }
        });
    }

    handlePhotoOpened = (personId) => (photoFile) => {
        if (!photoFile) { return; }

        fileToBase64(photoFile).then((photoUri) => {
            this.handleStateChange('personalInfo', { photoUri });
        });

        if (personId) {
            this.props.savePhoto(personId, photoFile);
        }

    }

    isInputInvalid = (blockStateKey, inputStateKey, validators, inputErrorKey, subBlockName, index) => {
        if (!this.state.isFormSubmitted) {
            return false;
        }

        const errorKey = inputErrorKey || this.getInputErrorKey(inputStateKey);
        for (let validate of validators) {

            if (typeof validate !== 'function') {
                this[errorKey] = validate;
            } else {
                let validateObject = null;
                const inputStateKeyType = typeof inputStateKey;

                if (subBlockName) {
                    if (inputStateKeyType === 'object') {
                        validateObject = this.getValidatedObject(
                            inputStateKey,
                            this.state.person[blockStateKey][subBlockName].get(index),
                        );
                    } else {
                        validateObject =
                            this.state.person[blockStateKey][subBlockName][index][inputStateKey];
                    }
                } else {
                    if (inputStateKeyType === 'object') {
                        validateObject = this.getValidatedObject(
                            inputStateKey,
                            this.state.person[blockStateKey],
                        );
                    } else {
                        validateObject = this.state.person[blockStateKey][inputStateKey];
                    }
                }

                this[errorKey] = validate(validateObject);
            }

            if (this[errorKey]) {
                this.handleFirstError(blockStateKey === 'governmentServiceInfo' ? 'workInfo' : blockStateKey);
                this.isFormValid = false;
                return true;
            }
        }
    }

    getValidatedObject = (obj, stateBlock) => {
        switch (obj.type) {
            case "dateRange":
                return {
                    start: stateBlock[obj.start],
                    end: stateBlock[obj.end]
                }
            default:
                return obj;
        }
    }

    handleFirstError = (firstErrorBlock) => {
        if (!this.firstErrorBlock) {
            this.firstErrorBlock = firstErrorBlock;
        }
    }

    scrollToErrorBlockRef = (errorBlock) => {
        window.scrollTo({
            top: this.cardRefs[errorBlock].current.offsetTop,
            behavior: "smooth"
        });
    };

    isInputFilled = (blockStateKey, inputValue) => {
        return !!inputValue;
    }

    getInputErrorMessage = (inputStateKey, inputErrorKey) => {
        const errorKey = inputErrorKey || this.getInputErrorKey(inputStateKey);
        return this[errorKey];
    }

    getInputErrorKey(inputStateKey) {
        return `${inputStateKey}_error`;
    }

    getMenuItemState = (item) => {
        return {
            checked: this.state.isBlockFilled[item.anchor],
        };
    }

    getCatalogs = (names) => (catalogs) => {
        return names.reduce((obj, name) => {
            obj[name] = catalogs[name];
            return obj;
        }, {});
    }

    loadLocalities = regionId => {
        this.props.fetchCatalog(REGION_LOCALITIES, [regionId]);
    }

    onSubmit = e => {
        e.preventDefault();
        this.isFormValid = true;
        this.firstErrorBlock = null;
        this.setState({ isFormSubmitted: true, saveTime: new Date() }, () => {
            if (this.isFormValid) {
                this.props.savePerson(this.getPersonToSave());
            } else {
                this.props.showWarningAlert('Не все поля корректно заполнены');
                this.firstErrorBlock && this.scrollToErrorBlockRef(this.firstErrorBlock);
            }
        });
    }

    onClear = () => {
        const newState = EditPerson.getDerivedStateFromProps(this.props, {
            loadComplete: false,
            isBlockFilled: {
                personalInfo: false,
                educationInfo: false,
                workInfo: false,
                languagesInfo: false,
                familyInfo: false,
                socialNetworksInfo: false,
                filesInfo: false,
                personnelInfo: false,
                attributesInfo: false,
                systemInfo: false,
            }
        });
        this.setState(newState, () => {
            window.scrollTo(0, 0);
        });
        this.props.setShowSnilsError(false);
    }

    getNormalizedMask = (value) => {
        return trimEnd(value, '_');
    }

    getPersonToSave = () => {
        const {
            personalInfo, educationInfo, workInfo, governmentServiceInfo, evaluationInfo, languagesInfo,
            socialNetworksInfo, familyInfo, filesInfo, filesDirectoryId, personnelInfo, attributesInfo,
            systemInfo
        } = this.state.person;

        const {
            selectedDistrict, selectedDocument, selectedLocality, selectedRegion, selectedSex, pastResidences, curatorName
        } = personalInfo;

        const { selectedDataOrigin, selectedExperienceLevel, awards, selectedGeoLevel, selectedManagerLevel } = personnelInfo;

        return {
            ...trimObjectStrings(personalInfo),
            federalDistrictId: personalInfo.isRussianCitizen ? (selectedDistrict && selectedDistrict.id) : null,
            regionId: personalInfo.isRussianCitizen ? (selectedRegion && selectedRegion.id) : null,
            localityId: personalInfo.isRussianCitizen ? (selectedLocality && selectedLocality.id) : null,
            foreignLocality: personalInfo.isRussianCitizen ? null : personalInfo.foreignLocality.trim(),
            foreignRegion: personalInfo.isRussianCitizen ? null : personalInfo.foreignRegion.trim(),
            nationalityId: personalInfo.isRussianCitizen ? null : (personalInfo.selectedNationality && personalInfo.selectedNationality.id),
            documentId: selectedDocument.id,
            documentNumber: this.getNormalizedMask(personalInfo.documentNumber || '').trim(),
            sex: selectedSex && selectedSex.id,
            familyStatus: familyInfo.selectedFamilyStatus && familyInfo.selectedFamilyStatus.id,
            childrenStatusId: familyInfo.selectedChildrenStatus && familyInfo.selectedChildrenStatus.id,
            readyMoveToRussia: personalInfo.isRussianCitizen ? false : personalInfo.readyMoveToRussia,
            filesDirectoryId,
            awards: awards.map(award => ({
                competitionLevel: award.selectedCompetitionLevel && award.selectedCompetitionLevel.id,
                name: award.name.trim(),
            })),
            pastResidences: pastResidences.map(item => ({
                ...trimObjectStrings(item),
                federalDistrictId: item.selectedDistrict && item.selectedDistrict.id,
                regionId: item.selectedRegion && item.selectedRegion.id,
                localityId: item.selectedLocality && item.selectedLocality.id,
            })),
            educationInfo: educationInfo.educations.map(education => ({
                ...trimObjectStrings(education),
                educationLevelId: education.educationLevel && education.educationLevel.id,
                studyFormId: education.studyForm && education.studyForm.id
            })),
            workPlaces: workInfo.workPlaces.map(workPlace => ({
                ...trimObjectStrings(workPlace),
                industryId: workPlace.industry && workPlace.industry.id,
                workAreaId: workPlace.workArea && workPlace.workArea.id,
                managementLevelId: workPlace.managementLevel && workPlace.managementLevel.id,
                managementExperienceId: workPlace.managementExperience && workPlace.managementExperience.id,
                employeesNumberId: workPlace.employeesNumber && workPlace.employeesNumber.id,
            })),
            personnelInfo: {
                ...trimObjectStrings(personnelInfo),
                dataOriginId: selectedDataOrigin && selectedDataOrigin.id,
                experienceLevelId: selectedExperienceLevel && selectedExperienceLevel.id,
                curatorId: systemInfo.selectedCurator && systemInfo.selectedCurator.value.id,
                selectedGeoLevel,
                selectedManagerLevel,
                curatorName: curatorName,
            },
            governmentServices: governmentServiceInfo.governmentServices.map(trimObjectStrings),
            evaluations: evaluationInfo.evaluations.map(trimObjectStrings),
            languages: languagesInfo.knownLanguages.filter(x => x.language).map(x => ({
                languageId: x.language.id,
                languageLevelId: x.languageLevel.id,
                fileAttachmentId: x.fileAttachmentId,
            })),
            socialNetworks: socialNetworksInfo.networks.filter(x => x.value.trim()).map(x => ({
                networkId: x.network.id,
                value: x.value.trim(),
            })),
            files: filesInfo.files.filter(f => !f.error),
            customAttributes: attributesInfo.attributes
                .filter(x => x.attribute)
                .map(attribute => {
                    const trimmedAttribute = trimObjectStrings(attribute);
                    const hasJsonValue =
                        attribute.attribute.type.toLowerCase() === "select"
                        || attribute.attribute.type.toLowerCase() === "multiselect";
                    return {
                        ...trimmedAttribute,
                        attributeValue: hasJsonValue
                            ? JSON.stringify(attribute.attributeValue)
                            : attribute.attributeValue,
                        attribute: {
                            ...trimmedAttribute.attribute,
                            payload: ""
                        },
                    };
                }),
            groupId: systemInfo.selectedGroup,
            personGroupIds: systemInfo.selectedPersonGroupIds
        };
    };

    handleStateChangePersonal = this.handleStateChange.bind(null, 'personalInfo');
    isInputInvalidPersonal = this.isInputInvalid.bind(null, 'personalInfo')
    isInputFilledPersonal = this.isInputFilled.bind(null, 'personalInfo')
    getPersonalBlockCatalogs = memoize(this.getCatalogs(personalInfoBlockCatalogs));

    handleStateChangeEducation = this.handleStateChange.bind(null, 'educationInfo');
    isInputInvalidEducation = this.isInputInvalid.bind(null, 'educationInfo');
    isInputFilledEducation = this.isInputFilled.bind(null, 'educationInfo');
    getEducationBlockCatalogs = memoize(this.getCatalogs(educationBlockCatalogs));

    isInputFilledWorkInfo = this.isInputFilled.bind(null, 'workInfo');
    getWorkBlockCatalogs = memoize(this.getCatalogs(workInfoBlockCatalogs));

    handleStateChangeLanguages = this.handleStateChange.bind(null, 'languagesInfo');
    isInputInvalidLanguages = this.isInputInvalid.bind(null, 'languagesInfo');
    isInputFilledLanguages = this.isInputFilled.bind(null, 'languagesInfo');
    getLanguagesCatalogs = memoize(this.getCatalogs(languagesBlockCatalogs));

    handleStateChangeFamilyStatus = this.handleStateChange.bind(null, 'familyInfo');
    isInputFilledFamilyStatus = this.isInputFilled.bind(null, 'familyInfo');
    getFamilyStatusCatalogs = memoize(this.getCatalogs(familyStatusBlockCatalogs));

    handleStateChangeSocialNetworks = this.handleStateChange.bind(null, 'socialNetworksInfo');
    isInputInvalidSocialNetworks = this.isInputInvalid.bind(null, 'socialNetworksInfo');
    isInputFilledSocialNetworks = this.isInputFilled.bind(null, 'socialNetworksInfo');
    getSocialNetworksCatalogs = memoize(this.getCatalogs(socialNetworksBlockCatalogs));

    handleStateChangeFiles = this.handleStateChange.bind(null, 'filesInfo');
    isInputFilledFiles = this.isInputFilled.bind(null, 'filesInfo');

    isInputInvalidPersonnel = this.isInputInvalid.bind(null, 'personnelInfo');
    isInputFilledPersonnel = this.isInputFilled.bind(null, 'personnelInfo');
    handleStateChangePersonnel = this.handleStateChange.bind(null, 'personnelInfo');
    getPersonnelCatalogs = memoize(this.getCatalogs(personnelInfoBlockCatalogs));

    isInputInvalidAttributes = this.isInputInvalid.bind(null, 'attributesInfo');
    isInputFilledAttributes = this.isInputFilled.bind(null, 'attributesInfo');
    handleStateChangeAttributes = this.handleStateChange.bind(null, 'attributesInfo');

    handleStateChangeSystemInfo = this.handleStateChange.bind(null, 'systemInfo');
    isInputInvalidSystemInfo = this.isInputInvalid.bind(null, 'systemInfo');
    isInputFilledSystemInfo = this.isInputFilled.bind(null, 'systemInfo');

    handlePersonPhotoOpened = memoize((personId) => this.handlePhotoOpened(personId || null));

    render() {
        if (!this.state.loadComplete) {
            return null;
        }

        const attributes = this.props?.catalogs?.attributes?.data;
        const filteredMenu = !this.props.snilsIsOk
            ? menuItems.filter((x) => x.anchor === 'personalInfo')
            : attributes?.length === 0
            ? menuItems.filter((x) => x.anchor !== 'attributesInfo')
            : menuItems;
        
        return (
            <Page id="profile-page" w1790>
                <div className="Page__MenuColumn">
                    <PhotoBlock
                        id="photo-block"
                        photoUri={this.state.person.personalInfo.photoUri}
                        onPhotoOpened={this.handlePersonPhotoOpened(
                            this.state.person.personalInfo.id,
                        )}
                        readOnly={false}
                        isDisabled={!this.props.snilsIsOk}
                    />
                    <FloatMenu
                        items={filteredMenu}
                        getItemState={this.getMenuItemState}
                        refs={this.cardRefs}
                        onClear={this.onClear}
                        person={this.state.person}
                    />
                </div>
                <div className="Page__ContentColumn">
                    <div id="personalInfo" ref={this.cardRefs.personalInfo}>
                        <PersonalInfoBlock
                            {...this.state.person.personalInfo}
                            {...this.getPersonalBlockCatalogs(this.props.catalogs)}
                            handleStateChange={this.handleStateChangePersonal}
                            isInputInvalid={this.isInputInvalidPersonal}
                            isInputFilled={this.isInputFilledPersonal}
                            loadLocalities={this.loadLocalities}
                            getInputErrorMessage={this.getInputErrorMessage}
                            filesDirectoryId={this.props.person.filesDirectoryId}
                            hasValidationErrors={this.firstErrorBlock === 'personalInfo'}
                            saveTime={this.state.saveTime}
                        />
                    </div>
                    {this.props.snilsIsOk && (
                        <>
                            <div id="educationInfo" ref={this.cardRefs.educationInfo}>
                                <EducationBlock
                                    {...this.state.person.educationInfo}
                                    {...this.getEducationBlockCatalogs(this.props.catalogs)}
                                    handleStateChange={this.handleStateChangeEducation}
                                    isInputInvalid={this.isInputInvalidEducation}
                                    isInputFilled={this.isInputFilledEducation}
                                    getInputErrorMessage={this.getInputErrorMessage}
                                    hasValidationErrors={this.firstErrorBlock === 'educationInfo'}
                                    saveTime={this.state.saveTime}
                                />
                            </div>
                            <div id="workInfo" ref={this.cardRefs.workInfo}>
                                <WorkBlock
                                    {...this.state.person.workInfo}
                                    {...this.state.person.governmentServiceInfo}
                                    catalogs={this.getWorkBlockCatalogs(this.props.catalogs)}
                                    governmentServiceKindCatalog={
                                        this.props.catalogs[GOVT_SERVICE_KINDS]
                                    }
                                    handleStateChange={this.handleStateChange}
                                    isArray={true}
                                    personId={this.state.person.personalInfo.id}
                                    isInputInvalid={this.isInputInvalid}
                                    isInputFilled={this.isInputFilledWorkInfo}
                                    getInputErrorMessage={this.getInputErrorMessage}
                                    hasValidationErrors={this.firstErrorBlock === 'workInfo'}
                                    saveTime={this.state.saveTime}
                                />
                            </div>
                            <div id="languagesInfo" ref={this.cardRefs.languagesInfo}>
                                <LanguagesBlock
                                    personId={this.state.person.personalInfo.id}
                                    {...this.state.person.languagesInfo}
                                    {...this.getLanguagesCatalogs(this.props.catalogs)}
                                    handleStateChange={this.handleStateChangeLanguages}
                                    isInputInvalid={this.isInputInvalidLanguages}
                                    isInputFilled={this.isInputFilledLanguages}
                                    getInputErrorMessage={this.getInputErrorMessage}
                                    hasValidationErrors={this.firstErrorBlock === 'languagesInfo'}
                                    saveTime={this.state.saveTime}
                                />
                            </div>
                            <div id="familyInfo" ref={this.cardRefs.familyInfo}>
                                <FamilyStatusBlock
                                    {...this.state.person.familyInfo}
                                    {...this.getFamilyStatusCatalogs(this.props.catalogs)}
                                    handleStateChange={this.handleStateChangeFamilyStatus}
                                    isInputFilled={this.isInputFilledFamilyStatus}
                                    hasValidationErrors={this.firstErrorBlock === 'familyInfo'}
                                    saveTime={this.state.saveTime}
                                />
                            </div>
                            <div id="socialNetworksInfo" ref={this.cardRefs.socialNetworksInfo}>
                                <SocialNetworksBlock
                                    {...this.state.person.socialNetworksInfo}
                                    {...this.getSocialNetworksCatalogs(this.props.catalogs)}
                                    handleStateChange={this.handleStateChangeSocialNetworks}
                                    isInputInvalid={this.isInputInvalidSocialNetworks}
                                    isInputFilled={this.isInputFilledSocialNetworks}
                                    getInputErrorMessage={this.getInputErrorMessage}
                                    hasValidationErrors={
                                        this.firstErrorBlock === 'socialNetworksInfo'
                                    }
                                    saveTime={this.state.saveTime}
                                />
                            </div>
                            <div id="filesInfo" ref={this.cardRefs.filesInfo}>
                                <FilesBlock
                                    {...this.state.person.filesInfo}
                                    personId={this.state.person.personalInfo.id}
                                    handleStateChange={this.handleStateChangeFiles}
                                    isInputFilled={this.isInputFilledFiles}
                                    directoryId={this.props.person.filesDirectoryId}
                                    hasValidationErrors={this.firstErrorBlock === 'filesInfo'}
                                    saveTime={this.state.saveTime}
                                />
                            </div>
                            <div id="personnelInfo" ref={this.cardRefs.personnelInfo}>
                                <PersonnelInfoBlock
                                    {...this.state.person.personnelInfo}
                                    {...this.getPersonnelCatalogs(this.props.catalogs)}
                                    isInputInvalid={this.isInputInvalidPersonnel}
                                    isInputFilled={this.isInputFilledPersonnel}
                                    handleStateChange={this.handleStateChangePersonnel}
                                    hasValidationErrors={this.firstErrorBlock === 'personnelInfo'}
                                    saveTime={this.state.saveTime}
                                />
                            </div>
                            {attributes?.length !== 0 && (
                                <div id="attributesInfo" ref={this.cardRefs.attributesInfo}>
                                    <EditAttributesBlock
                                        personId={this.state.person.personalInfo.id}
                                        {...this.state.person.attributesInfo}
                                        {...this.props}
                                        handleStateChange={this.handleStateChangeAttributes}
                                        isInputInvalid={this.isInputInvalidAttributes}
                                        isInputFilled={this.isInputFilledAttributes}
                                        getInputErrorMessage={this.getInputErrorMessage}
                                        hasValidationErrors={
                                            this.firstErrorBlock === 'attributesInfo'
                                        }
                                        saveTime={this.state.saveTime}
                                    />
                                </div>
                            )}
                            <div id="systemInfoInfo" ref={this.cardRefs.systemInfoInfo}>
                                <SystemInfoBlock
                                    {...this.props}
                                    {...this.state.person.systemInfo}
                                    handleStateChange={this.handleStateChangeSystemInfo}
                                    isInputInvalid={this.isInputInvalidSystemInfo}
                                    isInputFilled={this.isInputFilledSystemInfo}
                                    getInputErrorMessage={this.getInputErrorMessage}
                                    defaultOpen={false}
                                    hasValidationErrors={this.firstErrorBlock === 'systemInfo'}
                                    saveTime={this.state.saveTime}
                                />
                            </div>
                            <DataCard shadow className="kr_profile-anketa">
                                <div className="kr_profile-anketa-btn kr_text-center">
                                    <Button type="submit" onClick={this.onSubmit}>
                                        Сохранить
                                    </Button>
                                </div>
                            </DataCard>
                        </>
                    )}
                </div>
            </Page>
        );
    }
}

EditPerson.propTypes = {
    id: PropTypes.number,
    person: PropTypes.object.isRequired,
}

function mapStateToProps(state, ownProps) {
    const person = ownProps.id ? personFullSelector(state, ownProps.id) : newPersonSelector(state);
    let catalogs = allPersonCatalogs.reduce((obj, name) => {
        obj[name] = state.catalogs[name];
        return obj;
    }, {});
    const attributesData = state.attributes.data.payload.map(x => {
        return { id: x.id, code: x.type, type: x.type, name: x.name, payload: x.payload }
    });
    const attributes = { data: attributesData };

    catalogs = { ...catalogs, attributes }
    const error = state.person.error;
    const currentUserGroupId = state.auth.user.groupId;

    return {
        person,
        catalogs,
        error,
        currentUserGroupId,
        snilsIsOk: state.person.snilsIsOk,
    };
}

const actions = {
    fetchPerson,
    savePerson,
    newPerson,
    setSnilsIsOk,
    fetchCatalog,
    showWarningAlert,
    savePhoto,
    loadAttributes,
    clearPerson,
    setShowSnilsError,
};

export default connect(mapStateToProps, actions)(EditPerson);
