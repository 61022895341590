import React from 'react';

import SearchMenuJSON from './SearchMenuJSON';
import RenderDataCard from './renderDataCard';

const PopularBlockFields = React.memo(props => {
    const methods = {
        handleRegionChange: selectedRegions => {
            props.handleStateChange('personalInfo', selectedRegions);
        },

        handleRegionCheckChange: e => {
            const val = !e.currentTarget.checked;
            props.handleStateChange('personalInfo', [
                'selectedRegions',
                props.selectedRegions.value ? [...props.selectedRegions.value]: [],
                { ...props.selectedRegions?.extraFields, value: val },
            ]);
        },

        checkedRegions: () => {
            return props.selectedRegions?.extraFields?.value === false;
        },
    };

    return <RenderDataCard obj={SearchMenuJSON.PopularBlockFields} {...props} {...methods} />;
});

export default PopularBlockFields;
