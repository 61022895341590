import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getIssues,
    selectIssues,
    geIssuesDraft,
    deleteIssueById,
    setUpdateTracking,
} from 'ducks/Issues';
import { lkIssue, lkIssueDraft } from 'routes';
import EvaluationCardItemBlock from 'components/Lk/Shared/Evaluate/EvaluationCardItemBlock';
import IssueItem from './IssueItem';
import Loader from 'components/common/Loader';
import AcceptPage from 'components/Lk/Common/AcceptPage';
import { isNullOrWhitespace } from 'utils';
import './IssuesCard.scss';

class IssuesCard extends Component {
    _isMounted = false;
    state = {
        issues: [],
        issuesDraft: null,
        paging: { pageNum: 1, pageSize: 3 },
        isAcceptShown: false,
        acceptTitle: '',
        onIssueAction: () => {},
    };

    handlerOnClick = item => () => {
        this.props.selectIssues(item.id);
    };

    getItemLink = (item, isEdit) => () => {
        return {
            pathname: isEdit
                ? lkIssueDraft.buildUrl({ id: item.id })
                : lkIssue.buildUrl({ id: item.id }),
        };
    };

    sortByDueDateAsc = (a, b) => {
        return (Date.parse(a.dueDate) || 0) === (Date.parse(b.dueDate) || 0)
            ? 0
            : (Date.parse(a.dueDate) || 0) > (Date.parse(b.dueDate) || 0)
            ? 1
            : -1;
    };

    renderIssues = data => {
        const dataSorted = data.sort((a, b) => this.sortByDueDateAsc(a, b));
        return dataSorted.map(item => (
            <EvaluationCardItemBlock grey key={item.id}>
                <Link to={this.getItemLink(item)} onClick={this.handlerOnClick(item)}>
                    <IssueItem {...item} />
                </Link>
            </EvaluationCardItemBlock>
        ));
    };

    onLoadMoreDrafts = foundCount => () => {
        this._isMounted && this.setState({ paging: { pageNum: 1, pageSize: foundCount } });
    };

    onDeleteIssue = issue => e => {
        e.preventDefault();
        e.stopPropagation();
        this.changeAcceptVisibility(true);
        this._isMounted &&
            this.setState({
                acceptTitle: `Вы действительно хотите удалить черновик "${
                    !isNullOrWhitespace(issue.title) ? issue.title : `(Без названия)`
                }"?`,
                onIssueAction: () => {
                    this.props.deleteIssueById(issue.id);
                },
            });
    };

    sortByCreationDateAsc = (a, b) => {
        return (Date.parse(a.created) || 0) === (Date.parse(b.created) || 0)
            ? 0
            : (Date.parse(a.created) || 0) < (Date.parse(b.created) || 0)
            ? 1
            : -1;
    };

    renderIssuesDraft = data => {
        const dataSorted = data.payload.sort((a, b) => this.sortByCreationDateAsc(a, b));
        return (
            <>
                {dataSorted.map(item => (
                    <EvaluationCardItemBlock grey key={item.id}>
                        <Link to={this.getItemLink(item, true)}>
                            <IssueItem
                                {...item}
                                onClick={this.handlerOnClick(item, true)}
                                withActions={[
                                    {
                                        id: item.id,
                                        color: 'red',
                                        text: 'Удалить',
                                        onClick: this.onDeleteIssue(item),
                                        dataTest: 'IssueDraftDelete--button',
                                    },
                                ]}
                            />
                        </Link>
                    </EvaluationCardItemBlock>
                ))}
                {data.meta.foundCount > data.payload.length && (
                    <div
                        className="IssuesCard__Item--load-more"
                        onClick={this.onLoadMoreDrafts(data.meta.foundCount)}
                    >
                        {`Показать все черновики (еще ${data.meta.foundCount -
                            data.payload.length})`}
                    </div>
                )}
            </>
        );
    };

    getIssues = () => {
        const { getIssues, geIssuesDraft, issuesProps } = this.props;
        const { paging } = this.state;

        getIssues();
        geIssuesDraft(paging);
        this._isMounted &&
            this.setState({
                issues: issuesProps.data,
                issuesDraft: issuesProps.drafts,
            });
    };

    componentDidMount() {
        this._isMounted = true;
        this.getIssues();
    }

    componentDidUpdate(prevProps, prevState) {
        const { issuesProps, updateTracking } = this.props;

        if (
            JSON.stringify(prevProps.issuesProps.data) !==
            JSON.stringify(this.props.issuesProps.data)
        ) {
            this._isMounted && this.setState({ issues: issuesProps.data });
        }

        if (
            JSON.stringify(prevProps.issuesProps.drafts) !==
            JSON.stringify(this.props.issuesProps.drafts)
        ) {
            this._isMounted && this.setState({ issuesDraft: issuesProps.drafts });
        }

        if (prevState.paging !== this.state.paging) {
            this.props.geIssuesDraft(this.state.paging);
        }

        if (prevProps.updateTracking !== updateTracking && updateTracking) {
            this.getIssues();
        }

        if (!!prevProps.issuesProps.activeIssuesAmount &&
            this.props.issuesProps.activeIssuesAmount !== prevProps.issuesProps.activeIssuesAmount) {
            this.getIssues();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    changeAcceptVisibility = (state = false) => {
        this._isMounted && this.setState({ isAcceptShown: state });
    };

    render() {
        const { issuesDraft, issues, isAcceptShown, acceptTitle, onIssueAction } = this.state;
        return (
            <div className="IssuesCard__Item">
                {!this.props.loading ? (
                    <div>
                        <h3 className="LKLayout__Title">Сегодня</h3>
                        {issues.length === 0 ? (
                            <div className="IssuesCard__Item-empty" data-test="NoIssuesTitle--text">
                                Заданий нет
                            </div>
                        ) : null}
                        {issues && this.renderIssues(issues)}
                        {issuesDraft && issuesDraft.payload.length !== 0 && (
                            <>
                                <h3 className="LKLayout__Title" data-test="IssuesDraftsTitle--text">
                                    Черновики ({issuesDraft && issuesDraft.meta.foundCount})
                                </h3>
                                {this.renderIssuesDraft(issuesDraft)}
                            </>
                        )}
                    </div>
                ) : (
                    <Loader />
                )}
                <AcceptPage
                    show={isAcceptShown}
                    title={acceptTitle}
                    acceptAction={onIssueAction}
                    setIsAcceptPage={() => this.changeAcceptVisibility(false)}
                    dataTestHeader="IssueDraftDeleteWarning--text"
                />
            </div>
        );
    }
}

const mapStateToProps = store => ({
    issuesProps: store.issues,
    loading: store.issues.loading,
    updateTracking: store.issues.updateTracking,
});
const actions = {
    push,
    getIssues,
    selectIssues,
    geIssuesDraft,
    deleteIssueById,
    setUpdateTracking,
};
export default connect(
    mapStateToProps,
    actions,
)(IssuesCard);
