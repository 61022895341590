import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from "classnames";
import { lkIssue } from 'routes';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import { createPersonGrowthPlanApproveComment } from 'api';
import { push } from 'connected-react-router';
import InfoField from 'components/Lk/Uikit/InfoField';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import StickyBottomAcceptMenu from 'components/Lk/Uikit/Buttons/StickyBottomAcceptMenu';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import { updatedIssues } from 'ducks/Issues';
import { serviceResultCode, getError } from 'serviceErrors';
import { lkPersonPlanComment } from "routes.js";
import './PersonPlanCommentPage.scss';

export const DESCRIPTION_LIMIT = 1000;

const PersonPlanCommentPage = props => {

    const { activeCard, setActiveCard, pathname, issuesProps,
        showSuccessAlert, showErrorAlert, push, updatedIssues,
        match: { params: {id, planId, approveId }} } = props;

    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;
    setActiveCard(ACTIVE_DETAIL_CARD);

    const [comment, setComment] = useState('');
    const [issue, setIssue] = useState({});
    const [loading, setLoading] = useState(false);

    const refMount = useRef();

    const choicePage = pathname === lkPersonPlanComment.buildUrl({id, planId, approveId});
    
    useEffect(() => {
        refMount.current = true;
        if (issuesProps.data.length !== 0) {
            const result = issuesProps.data.find(x => x.id === id);
            if (!id || !result) {
                push('/')
            }
            setIssue(result);
        }

        return () => refMount.current = false;
    }, [issuesProps, id, push]);

    const handlerOnAccept = async() => {
        setLoading(true);
        const IssueUpdateError = (code, payload) => {
            switch (code) {
                case serviceResultCode.NotFound:
                    return `${payload}`;
                case serviceResultCode.ValidationErrors:
                    return `${payload}`;
                default:
                    return `Произошла непредвиденная ошибка`;
            }
        };

        try {
            if (choicePage && planId && approveId && id) {
                const createCommentResult = await createPersonGrowthPlanApproveComment(planId, approveId, comment, id);
                createCommentResult && createCommentResult.status && createCommentResult.status === 200 && 
                showSuccessAlert('Комментарий отравлен');
            }
            updatedIssues();
            push('/');
        } catch(error) {
            const reqError = getError(error, IssueUpdateError);
            showErrorAlert(reqError.message);
        } finally {
            refMount.current && setLoading(false);
        }
    };

    const handlerOnCancel = () => {
        push(lkIssue.buildUrl({id: issue.id}));
    };
    
    const MenuSetup = {
        background: false,
        btns: [
            {
                title: 'Отменить',
                onClick: handlerOnCancel,
                type: 'Cancel',
            },
            {
                title: 'Отправить',
                onClick: handlerOnAccept,
                type: 'Accept',
                disable: !comment || loading,
            },
        ]
    };

    return (
        <div className={classnames('DetailsCard', { 'DetailsCard--isMobileActive': isMobileActive })}>
            <div className="PersonPlanCommentPage">
                <h3 className="LKLayout__Title LKLayout__Title--JustifyStart">
                    {'Комментировать ИПР'}
                </h3>
                <div className="PersonPlanCommentPage__Content">
                    <InfoField
                        title="Комментарий"
                        type="textarea"
                        inputProps={{ maxLength: DESCRIPTION_LIMIT, rows: 2 }}
                        isEdit={true}
                        value={comment}
                        onChange={setComment}
                        placeholder="добавить"
                        noIcon
                        autoFocus
                        footer={`Осталось символов:  ${DESCRIPTION_LIMIT - comment.length}` || ''}
                    />
                </div>
            </div>
            <StickyFooter>
                <StickyBottomAcceptMenu {...MenuSetup} />
            </StickyFooter>
        </div>
    );
};

const mapStateToProps = store => (
    {
        issuesProps: store.issues,
        pathname: store.router.location.pathname,
    }
);

const actions = { showSuccessAlert, showErrorAlert, push, updatedIssues };
export default connect(mapStateToProps, actions)(PersonPlanCommentPage);
