import React from 'react';
import './AssignedSatus.scss';
import { ISSUE_STATUS_LEADER } from 'constants.js';
import classnames from 'classnames';

const AssignedSatus = props => {
    const { status = 'New', label, dataTest } = props;

    const statusRecord = ISSUE_STATUS_LEADER.find(x => x.value === status);

    const bulletClass = classnames('AssignedSatus__Bullet', [`AssignedSatus__Bullet--${status.toLowerCase()}`]);

    return (
        <div className="AssignedSatus__Block">
            <div className={bulletClass}></div>
            <div className="AssignedSatus__Text" data-test={dataTest} >{label || statusRecord?.label}</div>
        </div>
    );
};

export default AssignedSatus;
