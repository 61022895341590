import React, { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { getPersonProfilesInfo as fetchData } from 'api.js';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { wordForm } from '../../../utils';
import moment from 'moment';

import './PersonChart.scss';

import {
    BarChart,
    Cell,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import { goToSearch } from '../../Search/Search';

class PersonChart extends PureComponent {
    state = {
        selectedItem: -1,
        items: [],
    };

    componentDidMount() {
        this.props.showPageLoader();
        fetchData(moment(), 6)
            .then(this.handleData, this.handleError)
            .then(this.props.hidePageLoader);
    }

    drawTooltipContent = ({ active, payload }) => {
        if (active && payload && payload[0]) {
            return (
                <div className="kr-persons-chart__tooltip">
                    <div>{`${payload[0].value} ${wordForm(payload[0].value, [
                        'резервист',
                        'резервиста',
                        'резервистов',
                    ])}`}</div>
                </div>
            );
        }
    };

    onMouseEnter = payload => {
        if (this.state.selectedItem !== payload.id) {
            this.setState({
                selectedItem: payload.id,
            });
        }
    };

    onMouseLeave = () => {
        this.setState({
            selectedItem: -1,
        });
    };

    onBarClick = (data) => {
        const periodStart = moment([data.year, data.monthIndex, 1]);
        const periodEnd = periodStart.clone().endOf('month');
        const filter = {
            systemFilters: {
                createdStart: {
                    operation: { id: 4, code: '>=', name: 'Больше или равно' },
                    value: periodStart,
                },
                createdEnd: {
                    operation: { id: 4, code: '<=', name: 'Меньше или равно' },
                    value: periodEnd,
                },
            },
        };

        goToSearch(this.props.push, filter);
    };

    drawChart = () => {
        const { viewOnly } = this.props;
        return (
            <ResponsiveContainer>
                <BarChart
                    data={this.state.items}
                    margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid stroke="white" vertical={false} />
                    <XAxis dataKey="month" tick={{ fill: 'white' }} stroke={null} />
                    <YAxis
                        tick={{ fill: 'white' }}
                        width={105}
                        allowDecimals={false}
                        stroke={null}
                    />
                    <Tooltip
                        content={this.drawTooltipContent}
                        cursor={{ fill: 'rgba(0, 0, 0, 0)' }}
                    />
                    <Bar
                        dataKey="amount"
                        radius={[10, 10, 0, 0]}
                        onClick={!viewOnly ? this.onBarClick : undefined}
                        onMouseEnter={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}
                    >
                        {this.state.items.map((entry) => (
                            <Cell
                                key={entry.id}
                                fill={entry.id === this.state.selectedItem ? '#368ef0' : 'white'}
                                cursor="pointer"
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    };

    render() {
        return (
            <div className="kr_home-stats_graph kr_right">
                <div className="kr_text">Анкеты резервистов</div>
                <div className="kr_chart_wrap">{this.props.loading ? null : this.drawChart()}</div>
            </div>
        );
    }

    handleData = response => {
        const updatedData = response.data.items.map((item, index) => ({
            id: index,
            month: moment()
                .month(item.month - 1)
                .format('MMMM'),
            monthIndex: item.month - 1,
            year: item.year,
            amount: item.amount,
        }));

        this.setState({ items: updatedData });

        return Promise.resolve();
    };

    handleError = () => {
        this.props.user?.roles.length !== 0 && this.props.showErrorAlert('При загрузке данных произошла ошибка');

        return Promise.resolve();
    };
}

function mapStateToProps(state) {
    return {
        loading: state.pageLoader.visible,
        user: state.auth.user,
    };
}

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    push,
};

export default connect(
    mapStateToProps,
    actions,
)(PersonChart);
