import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from 'components/common/Page';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import { DictionarySelect, Select } from 'components/uikit/Select';
import Button from 'components/uikit/Button';
import DatePicker from 'components/uikit/DatePicker';
import { userActionStatusName } from 'constants.js';
import './UserActions.scss';
import moment from 'moment';
import ListTemplate from 'components/uikit/ListTemplate';
import UserActionTable from 'components/Admin/UserActions/UserActionTable';
import CheckField from 'components/uikit/CheckField';

const filterFromDate = moment().startOf('day').subtract(7, 'd').toDate();
const filterToDate = moment().endOf('day').toDate();

const initialState = {
    selectedUserAction: null,
    userActions: [],
    foundCount: 0,
    pageCount: 0,
    filter: {
        userIds: null,
        fromCreatedDate: filterFromDate,
        toCreatedDate: filterToDate,
        selectedStatus: null,
        status: null,
        excludeHighFrequency: true
    },
    sorting: { created: 'desc' },
    paging: { pageNum: 1, pageSize: 50 },
    expanded: {},
    showFilter: true,
    showPagination: true,
};

class UserActionSearchPage extends Component {
    static propTypes = {
        users: PropTypes.array,
        showErrorAlert: PropTypes.func.isRequired,
        showPageLoader: PropTypes.func.isRequired,
        hidePageLoader: PropTypes.func.isRequired,
        loadDictionaries: PropTypes.func.isRequired,
        search: PropTypes.func.isRequired,
    };

    state = initialState;

    componentDidMount() {
        this.callEffect(async () => {
            await this.props.loadDictionaries();
            await this.search();
        });
    }

    statusForSelect = () => {
        return userActionStatusName.map((item) => {
            return {
                value: item.value,
                label: item.name,
            };
        });
    };

    handleRowExpanded = (newExpanded, [index]) => {
        this.setState({
            expanded: { [index]: newExpanded[index] },
        });
        if (newExpanded[index]) {
            const { userActions } = this.state;
            this.fetchUserAction(userActions[index].id);
        }
    };

    onClearFilters = (e) => {
        e.preventDefault();
        this.clearFilters();
    };

    clearFilters = () => {
        this.setState(initialState, async () => {
            await this.callEffect(this.search);
        });
    };

    handleStateChange = (key, value) => {
        this.setState({
            ...this.state,
            [key]: value,
        });
    };

    handleExtendChange = (index, value) => {
        this.setState({
            expanded: { [index]: value[index] },
        });
    };

    getSorted = () => {
        const sorted = [];
        const { sorting } = this.props;

        for (let key in sorting) {
            sorted.push({ id: key, desc: sorting[key] === 'Desc' });
        }

        return sorted;
    };

    onSortedChange = (sorted) => {
        this.setState(
            {
                sorting: {
                    [sorted[0].id]: sorted[0].desc ? 'Desc' : 'Asc',
                },
            },
            () => this.callEffect(this.search),
        );
    };

    handleFilterChange = (name, data) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: data,
            },
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.callEffect(this.search);
    };

    onPageChange = (pageIndex) => {
        const { paging } = this.state;
        this.setState(
            {
                paging: { ...paging, pageNum: pageIndex + 1 },
                expanded: {},
            },
            () => this.callEffect(this.search),
        );
    };

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, () => this.callEffect(this.search));
    };

    search = async () => {
        try {
            this.setState({ loading: true });
            const { sorting, paging } = this.state;
            let filter = { ...this.state.filter };

            if (filter.selectedStatus) {
                filter.status = filter.selectedStatus.map((status) => {
                    return status.value;
                });
            }

            const criteria = { filter, sorting, paging };
            const { userActions, pageCount, foundCount } = await this.props.search(criteria);
            this.setState({
                userActions,
                pageCount,
                foundCount,
                selectedUserAction: null,
                expanded: {},
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();

        try {
            await callback();
        } catch (error) {
            this.props.showErrorAlert(error.message);
        } finally {
            this.props.hidePageLoader();
        }
    };

    filters = () => {
        const { filter } = this.state;

        const customSelectStyles = {
            control: (base) => ({
                ...base,
                padding: 0,
                borderColor: '#d2d5ea',
            }),
            input: (base) => ({
                ...base,
                height: 31,
                padding: 0,
                margin: 0,
            }),
            indicatorsContainer: (base) => ({
                ...base,
                padding: 0,
                height: 31,
            }),
        };

        return (
            <div className="UserActionsPage__Filter">
                <Field className="UserActionsPage__Filter--full-width">
                    <Label htmlFor="userId">Пользователь</Label>
                    <DictionarySelect
                        inputId="userId"
                        options={this.props.users || []}
                        value={filter.userIds}
                        onChange={(e) => this.handleFilterChange('userIds', e)}
                        isMulti
                        styles={customSelectStyles}
                    />
                </Field>
                <Field className="UserActionsPage__Filter--full-width">
                    <Label htmlFor="status">Статус</Label>
                    <Select
                        inputId="status"
                        options={this.statusForSelect()}
                        value={filter.selectedStatus}
                        onChange={(e) => this.handleFilterChange('selectedStatus', e)}
                        isMulti
                        styles={customSelectStyles}
                    />
                </Field>
                <Field>
                    <Label htmlFor="fromCreatedDate">Дата с</Label>
                    <DatePicker
                        id="fromCreatedDate"
                        selected={filter.fromCreatedDate}
                        onChange={(e) => this.handleFilterChange('fromCreatedDate', e)}
                    />
                </Field>
                <Field>
                    <Label htmlFor="toCreatedDate">Дата по</Label>
                    <DatePicker
                        id="toCreatedDate"
                        selected={filter.toCreatedDate}
                        onChange={(e) => this.handleFilterChange('toCreatedDate', e)}
                    />
                </Field>
                <Field className="UserActions__Filter--checkbox">
                    <CheckField
                        id="excludeHighFrequency"
                        title="Убрать высокочастотные запросы"
                        checked={filter.excludeHighFrequency}
                        onChange={(e) => this.handleFilterChange('excludeHighFrequency', e)}
                        size="100"
                    />
                </Field>
                <Field>
                    <Label>&nbsp;</Label>
                    <Button size="xs" onClick={this.onSubmit}>
                        Применить
                    </Button>
                </Field>
                <Field>
                    <Label>&nbsp;</Label>
                    <Button size="xs" onClick={this.onClearFilters}>
                        Очистить фильтр
                    </Button>
                </Field>
            </div>
        );
    };

    render() {
        const {
            selectedUserAction,
            userActions,
            pageCount,
            paging,
            filter,
            loading,
            expanded,
        } = this.state;

        if (loading && !!!userActions) {
            return null;
        }

        return (
            <Page>
                <ListTemplate title={this.filters()}>
                    <div className="UserActionsPage__Table">
                        <UserActionTable
                            filter={filter}
                            onPageChange={this.onPageChange}
                            getSorted={this.getSorted()}
                            onSortedChange={this.onSortedChange}
                            showPagination={true}
                            handleExtendChange={this.handleExtendChange}
                            handleStateChange={this.handleStateChange}
                            expanded={expanded}
                            fetchUserAction={this.props.fetchUserAction}
                            sorting={this.state.sorting}
                            userActions={userActions}
                            foundCount={this.state.foundCount}
                            page={paging.pageNum}
                            pageCount={pageCount}
                            selectedUserAction={selectedUserAction}
                            pageSize={paging.pageSize}
                            changePageAmount={this.changePageAmount}
                        />
                    </div>
                </ListTemplate>
            </Page>
        );
    }
}

export default UserActionSearchPage;
