import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCampaignById, getCampaignHtml } from 'api';
import { connect } from 'react-redux';
import { showErrorAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { CAMPAIGN_STATUS } from 'constants.js';
import { formatDate, getUserFullName } from 'utils';
import { NavLink } from 'react-router-dom';
import { editMassEmail } from 'routes';

const InfoBlock = ({ label, text }) => {
    return (
        <div className="CampaignInfoLine">
            <div className="CampaignInfoLine__Label">{label}</div>
            <div className="CampaignInfoLine__Text">{text}</div>
        </div>
    );
};

class CampaignInfo extends Component {
    state = {
        campaign: null,
        htmlCode: '',
    };

    fetchCampaign = async () => {
        const { campaignId, showPageLoader, hidePageLoader, showErrorAlert } = this.props;
        try {
            showPageLoader();
            const campaign = await getCampaignById(campaignId);
            const campaignHtml =
                CAMPAIGN_STATUS.Sent.id === campaign.data.status
                    ? await getCampaignHtml(campaignId)
                    : null;
            this.setState({ campaign: campaign.data, ...campaignHtml && {htmlCode: campaignHtml.data} });
        } catch (e) {
            showErrorAlert('Ошибка загрузки рассылки.');
        } finally {
            hidePageLoader();
        }
    };

    componentDidMount() {
        this.fetchCampaign();
    }

    render() {
        const { campaign, htmlCode } = this.state;
        if (!campaign) {
            return null;
        }
        
        return (
            <div className="CampaignInfo">
                <InfoBlock
                    label="Тема"
                    text={
                        <NavLink to={editMassEmail.buildUrl({ id: campaign.letter.id })}>
                            {campaign.letter.subject}
                        </NavLink>
                    }
                />
                <InfoBlock label="Статус" text={CAMPAIGN_STATUS[campaign.status].name} />
                <InfoBlock
                    label="Дата рассылки"
                    text={
                        campaign.sendOn
                            ? formatDate(campaign.sendOn, 'YYYY-MM-DD HH:mm')
                            : 'Не отправлена'
                    }
                />
                <InfoBlock label="Автор" text={campaign?.author && getUserFullName(campaign.author)} />
                <InfoBlock label="Отправитель" text={campaign?.sender && getUserFullName(campaign.sender)} />
                <div className="CampaignInfoLine__Html">
                    <div dangerouslySetInnerHTML={{ __html: htmlCode }} />
                </div>
            </div>
        );
    }
}

CampaignInfo.propTypes = {
    campaignId: PropTypes.string.isRequired,
};

const actions = { showPageLoader, hidePageLoader, showErrorAlert };

export default connect(null, actions)(CampaignInfo);
