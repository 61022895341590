import React from 'react';
import PropTypes from 'prop-types';
import { formatDate, diffDate, getUserShortName, isNullOrWhitespace } from 'utils';
import './IssueItem.scss';
import { lkIssueTrackingSelect } from 'routes';
import { Link } from 'react-router-dom';
import uuid from "uuid/v4";

const IssueTrackItem = props => {
    const { onClick, title, assignees, dueDate, withActions = false, id } = props;

    const renderAction = action => {
        return (
            <div
                key={action.id}
                className={`IssueItem__Actions--${action.color}`}
                onClick={action.onClick}
            >
                {action.text}
            </div>
        );
    };

    const renderAssignees = () => {
        const assigneeList = assignees
            .map(x => (
                <span key={uuid()} className="IssueItem__Assignee">
                    {getUserShortName(x)}
                </span>
            ));

        return assigneeList.length > 0 && assigneeList.reduce((prev, curr) => [prev, ', ', curr]);
    };

    return (
        <Link to={lkIssueTrackingSelect.buildUrl({ id })} className="IssueItem IssueItem--link" onClick={onClick}>
            <div className="IssueItem__Head">
                {dueDate && diffDate(new Date(), dueDate) >= 0 ? (
                    <>
                        <div className="IssueItem__Track">Истекает срок по задаче</div>
                        <div className="IssueItem__Date">{`${formatDate(dueDate, 'DD.MM')}`}</div>
                    </>
                ) : (
                    <>
                        <div className="IssueItem__Date">{`${formatDate(dueDate, 'DD.MM')}`}</div>
                    </>
                )}
            </div>
            <div className="IssueItem__Body">
                {!isNullOrWhitespace(title) ? title : <i>(Без названия)</i>}
            </div>
            <div className="IssueItem__Footer">
                Ответственные: {assignees && assignees.length > 0 && renderAssignees()}
            </div>

            {withActions && Array.isArray(withActions) && withActions.length !== 0 && (
                <div className="IssueItem__Actions">{withActions.map(x => renderAction(x))}</div>
            )}
        </Link>
    );
};

IssueTrackItem.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    dueDate: PropTypes.string,
};

export default IssueTrackItem;
