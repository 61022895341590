import React from 'react';
import { connect } from 'react-redux';
import ToggleOpen from 'components/hoc/ToggleOpen';
import ValidBlock from 'components/hoc/ValidBlock';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { CHILDREN_STATUS, FAMILY_STATUS } from 'ducks/Catalog';
import { DataCard } from "components/common/DataCard";
import Select from 'components/uikit/Select';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Row from 'components/uikit/Row';
import Form from "components/common/Form";

const FamilyStatusBlock = props => {
    const {
        handleStateChange,
        isInputFilled,
        selectedFamilyStatus,
        selectedChildrenStatus
    } = props;

    isInputFilled(props.selectedFamilyStatus);
    isInputFilled(props.selectedChildrenStatus);

    return (
        <DataCard
            shadow
            title="Семейное положение"
            className="kr_profile-anketa"
            isOpen={props.hasValidationErrors}
        >
            <Form autoComplete={false} className="EditPerson__Form">
                <Row>
                    <Field
                        filled={isInputFilled(selectedFamilyStatus)}
                        size="33">
                        <Label htmlFor="selectedFamilyStatus">Статус</Label>
                        <Select
                            inputId="selectedFamilyStatus"
                            value={selectedFamilyStatus}
                            onChange={item => handleStateChange({ selectedFamilyStatus: item })}
                            options={props[FAMILY_STATUS].sexData[props?.sex?.id || 'All']}
                            isClearable
                            catalog
                        />
                    </Field>
                    <Field
                        filled={isInputFilled(selectedChildrenStatus)}
                        size="33"
                    >
                        <Label htmlFor="selectedChildrenStatus">Количество детей</Label>
                        <Select
                            inputId="selectedChildrenStatus"
                            value={selectedChildrenStatus}
                            onChange={item => handleStateChange({ selectedChildrenStatus: item })}
                            options={props[CHILDREN_STATUS].data}
                            isClearable
                            catalog
                        />
                    </Field>
                </Row>
                <Row>
                    {props.isEditBlock ? props.renderEditBlock() : null}
                </Row>
            </Form>
        </DataCard>
    );
}

export default connect(null, { showWarningAlert, showErrorAlert })(ToggleOpen(ValidBlock(FamilyStatusBlock)));