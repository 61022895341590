import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPerson, personFullSelector } from 'ducks/Person';
import { fetchAccess } from 'ducks/Access';
import "./Team.scss";
import { PageLoader } from 'components/common/Loader';
import PersonTeams from 'components/Person/Teams';
import { DataCard } from 'components/common/DataCard';
import { Row, Col } from 'react-flexbox-grid';
import { getUserFullName } from 'utils';
import ContactIcons from '../../../components/uikit/ContactIcons';

const initState = {
    hintText: "В данном разделе отражаются созданные Вами команды",
    showHint: true,
    showPhone: true,
    showMail: true
}

class Team extends Component {
    state = initState;

    async componentDidMount() {
        await this.props.fetchPerson(this.props.personId);
    }

    render() {
        if (!this.props.person.loadComplete) {
            return <></>;
        }

        const { access } = this.props;

        const requester = access && access.lastAccessRequest && access.lastAccessRequest.requester;

        return (<>
            <div className="Team__InfoCard">
                <DataCard>
                    <Row>
                        <Col md={12}>
                            <span className="Team__ExpertTitle">{requester ? "Эксперт по управлению талантами:" : "Эксперт не назначен"}</span>
                            <span className="Team__ExpertFullName">{requester && getUserFullName(requester)}</span>
                            <div className="Team__ExpertIcons">
                                <ContactIcons
                                    showPhone={this.state.showPhone}
                                    showMail={this.state.showMail}
                                    email={requester && requester.email}
                                    number={requester && requester.phone} />
                            </div>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                </DataCard>
            </div>
            <div className="Team__Card">
                <PersonTeams
                    isLeaderPage={true}
                    person={this.props.person}
                    user={this.props.user}
                    title="Моя команда"
                    followingTitle="Команда руководителя"
                    showHint={this.state.showHint}
                    hintText={this.state.hintText}
                    hidePerson />
                <PageLoader />
            </div>
        </>
        );
    }
}

const props = (state, ownProps) => {
    const person = personFullSelector(state, ownProps.id);

    return {
        personId: state.auth.user.personId,
        user: state.auth.user,
        access: state.access && state.access.data,
        person
    }
}

const actions = {
    fetchPerson,
    fetchAccess
}

export default withRouter(connect(props, actions)(Team));