import React from 'react';
import classnames from 'classnames';
import { Progress } from 'react-sweet-progress';
import { isNullOrWhitespace } from 'utils';
import Popover from 'components/Lk/Uikit/Popover';
import { EVALUATIONTEST_CRITERIONRESULT_STATUS } from 'constants.js';

export const progressColors = {
    low: 'rgb(236, 137, 24)',
    mid: 'rgb(247, 211, 54)',
    high: 'rgb(4, 158, 81)',
};

export const types = [
    { id: 1, left: 'Скептичность', right: 'Оптимистичность' },
    { id: 2, left: 'Спонтанность', right: 'Педантизм' },
    { id: 3, left: 'Чувствительность', right: 'Сдержанность' },
    { id: 4, left: 'Осторожность', right: 'Авантюризм' },
    { id: 5, left: 'Скромность', right: 'Публичность' },
    { id: 6, left: 'Прагматизм', right: 'Альтруизм' },
];

export const groups = [
    { id: 'Abilities', name: 'Способности', groupResult: true, visualType: 'Web' },
    { id: 'Competences', name: 'Компетенции', groupResult: false },
    { id: 'MotivationAndDestructors', name: 'Мотивация и деструкторы', groupResult: false },
    { id: 'Characteristics', name: 'Личностные характеристики', groupResult: false },
];

export const typesDescr = [
    {
        type: 'Competency',
        name: 'Компетенции',
        Descr: 'За счет чего добивается результатов, уровень развития управленческих компетенций.',
        groupId: 'Competences',
    },
    {
        type: 'Behavior',
        name: 'Личностные характеристики',
        Descr: 'Для решения каких задач и в каких ситуациях наиболее эффективен.',
        groupId: 'Characteristics',
    },
    {
        type: 'MotivationAndDestructors',
        name: 'Мотивация и вовлеченность',
        Descr: 'Что дает энергию и позволяет добиваться результатов.',
        groupId: 'MotivationAndDestructors',
    },
    {
        type: 'Abilities',
        name: 'Способности',
        Descr:
            'Тесты способностей показывают умение анализировать словесную информацию и делать на её основании корректные выводы, работать с абстрактной информацией и выявлять закономерности.',
        groupId: 'Abilities',
    },
    {
        type: 'Potential',
        name: 'Потенциал',
        Descr:
            'Эффективность в новой деятельности - в ее освоении и использовании необходимых навыков и знаний.',
        groupId: 'Competences',
    },

    // "Результативность деятельности": появится на 3 этапе
    {
        type: '',
        name: 'Результативность деятельности',
        Descr: 'Каковы реальные результаты работы.',
        chartType: '',
        groupId: '',
        groupName: '',
    },

    // "Профессиональные знания": ВОЗМОЖНО появится на 3 этапе. Вид графика - "паутина"
    {
        type: '',
        name: 'Профессиональные знания',
        Descr: '',
        chartType: '',
        groupId: '',
        groupName: '',
    },
];

export const getDescription = (data, chartParams, withDescr) => {
    return isNullOrWhitespace(data.description)
        ? withDescr && chartParams && chartParams.Descr
        : data.description;
};

export const getProgressColor = value => {
    if (value >= 0 && value < 30) {
        return progressColors.low;
    } else if (value >= 30 && value < 70) {
        return progressColors.mid;
    } else {
        return progressColors.high;
    }
};

export const getProgressLineTheme = (value, groupId) => {
    if (groupId === 'Abilities') {
        const color = getProgressColor(value);
        return { success: { color: progressColors.high }, active: { color } };
    }

    return { success: { color: '#368ef1', symbol: value + '%' }, active: { color: '#368ef1' } };
};

export const getProgressLineThemeMobile = (value) => {
    let color = '#63C9B6';
    if (value >= 0 && value < 30) {
        color = '#F36B7C';
    } else if (value >= 30 && value < 70) {
        color = '#FFCC00';
    } else {
        color = '#63C9B6';
    }

    return { success: { color: '#63C9B6', symbol: value + '%' }, active: { color } };
};

export const getProgressLineData = (
    criteriaHeaders,
    criteriaData,
    groupId,
    getTitleDescription,
    showCriteriaDescription,
    isMobile, 
) => {
    const description = getTitleDescription(
        criteriaData.evaluationTestResultId,
        criteriaData.evaluationTestCriterionId,
    );
    const value = Math.round(criteriaData.normalizedValue * 100);
    const theme = isMobile
        ? getProgressLineThemeMobile(value, groupId)
        : getProgressLineTheme(value, groupId);
    const title = Object.entries(criteriaHeaders).find(
        x => x[0] === criteriaData.evaluationTestCriterionId,
    )[1].title;
    
    const lineHintId = `lineHint${criteriaData.evaluationTestCriterionId}`;
    
    const isCriteriaActive = criteriaData.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Relevant;
    
    return (
        <div
            className={classnames('LKCompetence', {
                'LKCompetence--mobile': isMobile,
                'LKCompetence--notActive': !isCriteriaActive,
            })}
            key={criteriaData.evaluationTestCriterionId}
        >
            <Popover tooltip={renderLineHint(description, lineHintId, "LKCompetence__LabelHint")} autoPlacement={false}>
                <div className="LKCompetence__Line">
                    <div className="LKCompetence__Label">
                        {title}
                    </div>
                    <Progress isMobile={isMobile} title={title} percent={value} theme={theme} />
                </div>
            </Popover>
        </div>
    );
};

export const renderLineHint = (title, id, className = '') => {
    const hint = getLineHintText(title);
    return (
        hint && (<span key={id} className={classnames("LKtooltip", className)}>{hint}</span>)
    );
};

export const getLineHintText = title => {
    if (!!!title) return title;
    
    switch (title.toLowerCase()) {
        case 'вербальная секция':
            return 'Умение анализировать словесную информацию и делать на её основании корректные выводы';
        case 'числовая секция':
            return 'Умение интерпретировать таблицы и графики, делать корректные выводы на основании числовой информации';
        case 'логическая секция':
            return 'Умение работать с абстрактной информацией и выявлять закономерности';
        default:
            return title;
    }
};

export const getChartParams = testType => typesDescr.find(x => x.type === testType);
