import React from 'react';
import PropTypes from 'prop-types';
import { nameProps } from './constants';
import classnames from 'classnames';

const InfoItem = ({ label, info }) => {
    return (
        <div className="AdminUserInfoItem">
            <div className="AdminUserInfoItem__Label">{label}</div>
            <div className="AdminUserInfoItem__Text">{info}</div>
        </div>
    );
};

const InfoCard = props => {
    const { header, data, propsArray, withName, withDivider, getPropInfo, wrapped } = props;

    if (!data) {
        return null;
    }

    let fullName = '';
    if (withName) {
        fullName = nameProps.reduce((acc, curr) => {
            return acc + data[curr] + ' ';
        }, '');
    }

    return (
        <div
            className={classnames('AdminUserInfo__Summary', {
                'AdminUserInfo__Summary--wrapped': wrapped,
            })}
        >
            {header && <div className="AdminUserInfoItem__Header">{header}</div>}
            {withDivider && <div className="AdminUserInfoItem__Divider" />}
            {withName && <InfoItem label="Ф.И.О." info={fullName} />}
            {propsArray.map(x => {
                let concatField = '';
                if (x.concat) {
                    concatField = x.id.reduce((acc, curr) => {
                        return acc + (x.inside ? data?.[x.inside]?.[curr] + ' ' : data?.[curr] + ' ');
                    }, '');
                    
                }
                return (
                    <InfoItem
                        key={x.id}
                        label={x.label}
                        info={
                            x.concat
                                ? getPropInfo(concatField, x, data)
                                : getPropInfo(x.id  && (x.inside ? data?.[x.inside]?.[x.id] : data?.[x.id]), x, data)
                        }
                    />
                );
            })}
        </div>
    );
};

InfoCard.defaultProps = {
    withName: false,
    withDivider: false,
    wrapped: false,
};

InfoCard.propTypes = {
    header: PropTypes.string,
    data: PropTypes.object,
    propsArray: PropTypes.array,
    withName: PropTypes.bool,
    withDivider: PropTypes.bool,
    getPropInfo: PropTypes.func,
    wrapped: PropTypes.bool,
};

export default InfoCard;
