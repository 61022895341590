import React from 'react';
import './EventMenu.scss';
import Icons from 'components/Lk/Uikit/Icons';
import classnames from 'classnames';
import EventMenuItems from './EventMenuItems';

const EventMenu = props => {
    const { data, onCheck, activeSelection = [], onSetMenu, allIdsList = [], menuType, menuFuncs, forceDisabled = false, withCounter = false, buttonsToDisable } = props;

    const isCheck = data && data.length > 0 && activeSelection && allIdsList 
        && activeSelection.length === allIdsList.length;

    const onCheckAll = () => {
        if (allIdsList) {
            onCheck(isCheck ? [] : allIdsList);
        }
    };

    const setMenu = menu => () =>  onSetMenu(menu);

    const disabledButtons = (activeSelection && activeSelection.length === 0) || forceDisabled;
    const disabledCheckAllBtn = data && data.length === 0;

    return (
        <div className="EventMenu">
            <div className={classnames('EventMenuItem', { 'EventMenuItem--disabled' : disabledCheckAllBtn })} onClick={onCheckAll} >
                <Icons name={!isCheck ? 'checkbox' : 'checkboxChecked'} />
                <span className="EventMenuItem__Text">
                    Выделить всех
                    {withCounter &&
                        <>
                            {` [ ${activeSelection.length.toLocaleString()} из ${allIdsList.length.toLocaleString()} ]`}
                        </>
                    }
                </span>
            </div>
            <EventMenuItems 
                menuType={menuType} 
                disabledButtons={disabledButtons} 
                setMenu={setMenu} 
                activeSelection={activeSelection} 
                menuFuncs={menuFuncs} 
                buttonsToDisable={buttonsToDisable} 
            />
        </div>
    );
};

export default EventMenu;
