import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import photoStub from 'assets/images/photo-stub.png';
import Dropdown from 'components/common/Dropdown';
import { canModerate, canSelectInterface, isModerator, isOperator } from 'rightsController.js';
import { getPersonPhotoUri, getUserPhotoUri, photoSizes } from 'api';
import { stringify } from 'query-string';
import { ISSUE_STATUS_ENUM } from 'constants.js';
import { instructionsOldRoute, profileRoute, interfaceSelectorRoute } from 'routes';
import InterfaceSelectorIcon from 'components/InterfaceSelector/Icon';
import './UserMenu.scss';

function ToggleButton(props) {
    const { children, onToggle, userPic, issuesAmount, externalProps } = props;
    const url = stringify({ status: [ISSUE_STATUS_ENUM.New, ISSUE_STATUS_ENUM.InProgress] });

    return (
        <React.Fragment>
            {externalProps.showIssues ? (
                <div>
                    <Link
                        className="UserMenu__IconButton"
                        to={{ pathname: '/moderation/issues', search: url }}
                        data-test="UserMenu_Issues--link"
                    >
                        <FontAwesomeIcon icon="clipboard-check"></FontAwesomeIcon>
                        {issuesAmount > 0 && (
                            <span className="UserMenu__Badge">{issuesAmount}</span>
                        )}
                    </Link>
                </div>
            ) : null}
            <div className="UserMenu__Ava">
                <button type="button" onClick={onToggle} data-test="UserMenu_UserPic--button">
                    {userPic ? (
                        <img src={userPic} onError={addDefaultSrc} alt="Фото" />
                    ) : (
                        <img src={photoStub} alt="Фото" />
                    )}
                </button>
            </div>
            <div
                className="UserMenu__UserName"
                onClick={onToggle}
                data-test="UserMenu_UserName--button"
            >
                {children}
            </div>
        </React.Fragment>
    );
}

function addDefaultSrc(ev) {
    ev.target.src = photoStub;
}

function UserMenu({ userName, className, onSignOut, defaultOpen, user, issuesAmount }) {
    const finalClassNames = classnames('UserMenu', className);
    const personPhotoUri = user.personId && getPersonPhotoUri(photoSizes.small, user.personId);
    const userPhotoUri = user.id && getUserPhotoUri(photoSizes.small, user.id);
    const appVersion = JSON.parse(document.getElementById('root').dataset?.version || null);

    return (
        <Dropdown
            title={userName}
            className={finalClassNames}
            ToggleButtonComponent={ToggleButton}
            issuesAmount={issuesAmount}
            defaultOpen={defaultOpen}
            userPic={personPhotoUri || userPhotoUri}
            externalProps={{ showIssues: user && (isModerator(user) || isOperator(user)) }}
        >
            <ul className="UserMenu__Options">
                <li className="UserMenu__Instructions">
                    <Link to="/instructions" data-test="UserMenu_Instructions--button">
                        <FontAwesomeIcon icon="question-circle" /> Инструкции
                    </Link>
                </li>
                <li className="UserMenu__Instructions">
                    <Link to={instructionsOldRoute.url} data-test="UserMenu_Instructions--button">
                        <FontAwesomeIcon icon="question-circle" /> Инструкции (старые)
                    </Link>
                </li>
                <li className="UserMenu__Profile">
                    <Link to={profileRoute.url} data-test="UserMenu_Profile--button">
                        <FontAwesomeIcon icon="user" /> Профиль
                    </Link>
                </li>
                {canModerate(user) && (
                    <li className="UserMenu__Moderation">
                        <Link to="/moderation" data-test="UserMenu_Moderation--button">
                            <FontAwesomeIcon icon="cogs" /> Модерация
                        </Link>
                    </li>
                )}
                {canSelectInterface(user) && (
                    <li className="UserMenu__SelectInterface">
                        <Link
                            to={interfaceSelectorRoute.url}
                            data-test="UserMenu_SelectInterface--button"
                        >
                            <InterfaceSelectorIcon /> Сменить интерфейс
                        </Link>
                    </li>
                )}
                {appVersion && (
                    <li className="UserMenu__Version">
                        <div className="UserMenu__Version--block">
                            <FontAwesomeIcon icon="code-branch" />
                            {`Версия ${appVersion.Name || appVersion.Version}`}
                        </div>
                    </li>
                )}
                <li
                    className="UserMenu__SignOut"
                    onClick={onSignOut}
                    data-test="UserMenu_SignOut--button"
                >
                    <i /> Выйти
                </li>
            </ul>
        </Dropdown>
    );
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    issuesAmount: state.issues.activeIssuesAmount,
});

export default connect(mapStateToProps)(UserMenu);
