import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import ModalDialog from 'components/common/ModalDialog';
import Widget from 'components/uikit/Widget';
import {
    mediaScreenSizes,
    availableWidgets,
    WIDGET_SIZE_LARGE,
    WIDGET_SIZE_MEDIUM,
    WIDGET_SIZE_SMALL,
    WIDGET_SIZE,
} from 'constants.js';
import Button from 'components/uikit/Button';
import { fetchSettings, setUserSettingsData } from 'ducks/Widget';

const Home = props => {
    const { settings, fetchSettings, setWidgetsData } = props;

    const { columns = [] } = settings;

    useEffect(() => {
        fetchSettings();
    }, [fetchSettings]);

    const isSmallScreen = () =>
        screenSize && ['xxsmall', 'xsmall', 'small'].some(x => x === screenSize[0]);

    const [editWidgets, setEditWidgets] = useState(false);
    const toggleEdit = () => {
        setEditWidgets(!editWidgets);
    };

    const renderWorkspace = () => {
        return (
            <div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid fluid>
                        <Row>{isSmallScreen() ? renderOneColumn() : renderAllColumns()}</Row>
                    </Grid>
                </DragDropContext>
            </div>
        );
    };

    const renderAllColumns = () => {
        return columns.map((x, index) => (
            <Col key={index} xs={x.width} className={classnames({ EditCol: editWidgets })}>
                {editWidgets && renderAddWidgetButton(x.id)}
                <Droppable droppableId={x.id}>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="DroppableArea"
                        >
                            {renderWidgets(x.widgets, x.id, x.width)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </Col>
        ));
    };

    const renderOneColumn = () => {
        const oneColWidgets = columns.flatMap(x => [...x.widgets]);
        return (
            <Col xs={12} className={classnames({ EditCol: editWidgets })}>
                {editWidgets && renderAddWidgetButton()}
                <Droppable droppableId={uuid()}>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="DroppableArea"
                        >
                            {renderWidgets(oneColWidgets)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </Col>
        );
    };

    const renderAddWidgetButton = colId => {
        return (
            <div className="AddWidgetHeader">
                <div className="AddWidgetButton" id={colId} onClick={onAddWidget}>
                    <FontAwesomeIcon icon="cog" />
                </div>
            </div>
        );
    };

    const onAddWidget = e => {
        setAddWidgetModalOpen({ colId: e.currentTarget.id, isOpen: true });
    };

    const renderWidgets = (widgets, colId = null, width = WIDGET_SIZE_LARGE) => {
        return (
            <div className="Home__Widgets">
                {widgets.map((x, index) => withDrag(x, index, colId, width))}
            </div>
        );
    };

    const withDrag = (x, index, colId, width) => {
        return (
            <Draggable isDragDisabled={!editWidgets} draggableId={x.id} index={index} key={x.id}>
                {(provided) => (
                    <div
                        key={x.id}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className="DraggableWidget"
                    >
                        {renderWidget(x, colId, width)}
                    </div>
                )}
            </Draggable>
        );
    };

    const renderWidget = (widget, colId, width) => {
        return (
            <Widget
                title={widget.name}
                type={widget.type}
                settings={widget.settings}
                key={widget.id}
                raised={editWidgets}
                closable={editWidgets}
                size={WIDGET_SIZE[width]}
                onClose={() => deleteWidget(widget.id, colId)}
                onEditData={updateData(widget.id, colId)}
            />
        );
    };

    const updateData = (widgetId, colId) => settings => {
        const newData = copyWidgetsData();
        const widgetsIndex = newData.findIndex(x => x.id === colId);
        const widgets = newData[widgetsIndex].widgets;
        const widgetIndex = widgets.findIndex(x => x.id === widgetId);
        newData[widgetsIndex].widgets[widgetIndex].settings = settings;
        setWidgetsData(newData);
    };

    const deleteWidget = (widgetId, colId) => {
        const newData = copyWidgetsData();
        const widgetsIndex = newData.findIndex(x => x.id === colId);
        const widgets = newData[widgetsIndex].widgets;
        const widgetIndex = widgets.findIndex(x => x.id === widgetId);
        widgets.splice(widgetIndex, 1);
        setWidgetsData(newData);
    };

    const addWidget = widgetId => {
        const widgetToAdd = availableWidgets.find(x => x.id === widgetId);
        const newData = copyWidgetsData();
        newData
            .find(x => x.id === isAddWidgetModalOpen.colId)
            .widgets.push({ type: widgetToAdd.type, id: uuid(), settings: {} });
        setWidgetsData(newData);
    };

    const [isAddWidgetModalOpen, setAddWidgetModalOpen] = useState({ colId: null, isOpen: false });
    const renderAddWidgetModal = () => {
        return (
            availableWidgets.length !== 0 && (
                <ModalDialog
                    modalHeader={'Добавить виджет'}
                    onClick={() => {}}
                    onCloseModal={closeAddWidgetModal}
                    modalOpen={isAddWidgetModalOpen.isOpen}
                    btnOktext="Сохранить"
                    btnCanceltext="Отменить"
                    withoutButtons
                >
                    {availableWidgets.map(x => (
                        <div className="NewWidgetList" key={x.id}>
                            <div className="NewWidgetList__Name">{x.name}</div>
                            <div>
                                <Button size="xs" onClick={() => addWidget(x.id)}>
                                    +
                                </Button>
                            </div>
                        </div>
                    ))}
                </ModalDialog>
            )
        );
    };

    const closeAddWidgetModal = () => {
        setAddWidgetModalOpen({ colId: null, isOpen: false });
    };

    const copyWidgetsData = () => {
        return columns.map(x => {
            return {
                ...x,
                widgets: x.widgets.map((x) => {
                    return { ...x };
                }),
            };
        });
    };

    const onDragEnd = result => {
        const { source, destination } = result;
        if (!source || !destination) {
            return;
        }
        if (source.index === destination.index && source.droppableId === destination.droppableId) {
            return;
        }

        const newData = copyWidgetsData();

        const sourceIndex = newData.findIndex(x => x.id === source.droppableId);
        const destinationIndex = newData.findIndex(x => x.id === destination.droppableId);
        const sourceWidgets = newData[sourceIndex].widgets;
        const destinationWidgets = newData[destinationIndex].widgets;
        const movedWidget = sourceWidgets.splice(source.index, 1);
        destinationWidgets.splice(destination.index, 0, movedWidget[0]);

        setWidgetsData(newData);
    };

    const [isEditLayoutModalOpen, setEditLayouModalOpen] = useState(false);
    const [selectedLayout, setSelectedLayout] = useState(columns.length - 1);
    const closeEditLayoutModal = () => setEditLayouModalOpen(false);
    const layoutsPreview = [
        { id: 0, cols: [WIDGET_SIZE_LARGE] },
        { id: 1, cols: [WIDGET_SIZE_MEDIUM, WIDGET_SIZE_MEDIUM] },
        { id: 2, cols: [WIDGET_SIZE_SMALL, WIDGET_SIZE_MEDIUM, WIDGET_SIZE_SMALL] },
    ];
    const renderLayoutPreview = () => {
        return (
            <div className="FlexContainer">
                {layoutsPreview.map(x => (
                    <div
                        key={x.id}
                        className={classnames('LayoutPreview', {
                            'LayoutPreview--selected': x.id === selectedLayout,
                        })}
                        onClick={() => setSelectedLayout(x.id)}
                    >
                        {x.cols.map(x => (
                            <div
                                key={x || 0}
                                className={`LayoutPreview__Col LayoutPreview__Col--w${x}`}
                            ></div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    const changeLayoutData = () => {
        const data = copyWidgetsData();
        const widgets = data.reduce((c, arr) => c.concat(arr.widgets), []);
        const newData = layoutsPreview[selectedLayout].cols.map((w, index) => ({
            id: uuid(),
            width: w,
            widgets: index === 0 ? widgets : [],
        }));
        setWidgetsData(newData);
        setEditLayouModalOpen(false);
    };

    const renderEditLayoutModal = () => {
        return (
            <ModalDialog
                modalHeader={'Вид рабочего стола'}
                onClick={changeLayoutData}
                onCloseModal={closeEditLayoutModal}
                modalOpen={isEditLayoutModalOpen}
                btnOktext="Сохранить"
                btnCanceltext="Отменить"
            >
                {renderLayoutPreview()}
            </ModalDialog>
        );
    };

    const [screenMatch, setScreenMatch] = useState({});
    const [screenSize, setScreenSize] = useState('unknown');

    useEffect(() => {
        const size = Object.entries(screenMatch).filter(x => x[1])[0];
        screenMatch && setScreenSize(size);
    }, [screenMatch]);

    return (
        <Media queries={mediaScreenSizes}>
            {matches => (
                <div>
                    {renderEditLayoutModal()}
                    {renderAddWidgetModal()}
                    <Grid fluid>
                        <Row center="xs">
                            <Col xs={12} xl={8}>
                                <div className="WidgetSettings">
                                    {editWidgets && (
                                        <div
                                            className="WidgetSettings__Link"
                                            onClick={() => setEditLayouModalOpen(true)}
                                        >
                                            Вид рабочего стола
                                        </div>
                                    )}
                                    {!isSmallScreen() && (
                                        <div className="WidgetSettings__Icon">
                                            <FontAwesomeIcon
                                                icon={editWidgets ? 'times' : 'th'}
                                                onClick={toggleEdit}
                                            ></FontAwesomeIcon>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {setScreenMatch(matches)}
                                    {renderWorkspace(editWidgets)}
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            )}
        </Media>
    );
};

const mapStateToProps = ({ widget }) => ({ settings: widget.settings });

export default connect(mapStateToProps, { fetchSettings, setWidgetsData: setUserSettingsData })(
    Home,
);
