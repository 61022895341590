import React, { useState, useEffect } from 'react';
import './WrappedCard.scss';
import classnames from 'classnames';
import Loader from 'components/common/Loader';
import Icons from 'components/Lk/Uikit/Icons/Icons';

const WrappedCard = props => {
    const {
        title,
        cardOpen = false,
        onTitleClick = undefined,
        toggleCallback = undefined,
        loading,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(cardOpen);
    }, [cardOpen]);

    const toggleCard = e => {
        e.stopPropagation();
        toggleCallback ? toggleCallback() : setIsOpen(!isOpen);
    };

    return (
        <div className="WrappedCard">
            <div className="WrappedCard__TitleWrapper" onClick={onTitleClick}>
                <div
                    className={classnames('WrappedCard__Title', {
                        'WrappedCard__Title--clickable': onTitleClick,
                    })}
                >
                    {title}
                </div>
                <div className="WrappedCard__Toggler" onClick={toggleCard}>
                    {isOpen ? <Icons name='chevron-up' /> : <Icons name='chevron-down' />}
                </div>
            </div>
            {isOpen && props.children}
            <Loader absolute show={loading} />
        </div>
    );
};

export default WrappedCard;
