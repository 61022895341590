import React from 'react';
import { connect } from 'react-redux';
import { setActiveFilter } from 'ducks/FilterSearch';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import Button from 'components/Lk/Uikit/Buttons/Button';

const SuccessButton = props => {
    const { visibleSection, onSubmit, buttonEnabled, activeFilter, setActiveFilter, isFocused, defaultFilters } = props;
    
    const submit = (closeFilters) => async () => {
        await onSubmit(closeFilters);
        setActiveFilter(null);
    };


    return (
        <>
            {(visibleSection || defaultFilters) && (
                <div className="LKSearchCardVisibleFIlterLine--right">
                    <Button icon="plus" onClick={submit(false)} disabled={!buttonEnabled}>
                        {activeFilter ? 'Изменить' : 'Добавить'}
                    </Button>
                </div>
            )}
            <StickyFooter unstick={isFocused}>
                {isFocused}
                <RoundedButton onClick={submit(true)}>Применить</RoundedButton>
            </StickyFooter>
        </>
    );
};

const actions = {setActiveFilter};

export default connect(null, actions)(SuccessButton);
