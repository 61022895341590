import { push } from 'connected-react-router';
import delay from '@redux-saga/delay-p'
import queryString,{ parse as queryStringParse} from 'query-string';
import { takeLeading, takeLatest, put, call, all, select } from 'redux-saga/effects';
import { appName, ROLE_LEADER_ID } from '../constants';
import { home as homeRoute, signIn as signInRoute, adminSignIn as adminSignInRoute, accountWizard, resourceViewRoute, interfaceSelectorRoute } from 'routes';
import { showErrorAlert } from './Alert';
import RequestError from '../RequestError';
import { signIn as signInApi, signInAdmin as signInAdminApi, signOut as signOutApi, getCurrentUser, changeInterface, heartbeat } from '../api';
import { serviceResultCode, getError, getCode } from "serviceErrors";
import { isAdmin } from 'rightsController';
import { openHub, closeHub } from 'store/middlewares/socketMiddleware';
import { canSelectInterface } from 'rightsController';

const moduleName = 'auth';
export const SIGN_IN_CLEAR = `${appName}/${moduleName}/SIGN_IN_CLEAR`;
export const SIGN_IN_REQUEST = `${appName}/${moduleName}/SIGN_IN_REQUEST`;
export const SIGN_IN_ADMIN_REQUEST = `${appName}/${moduleName}/SIGN_IN_ADMIN_REQUEST`;
export const SIGN_IN_START = `${appName}/${moduleName}/SIGN_IN_START`;
export const SIGN_IN_2FA_REQUIRED = `${appName}/${moduleName}/SIGN_IN_2FA_REQUIRED`;
export const SIGN_IN_SUCCESS = `${appName}/${moduleName}/SIGN_IN_SUCCESS`;
export const SIGN_IN_ENTER = `${appName}/${moduleName}/SIGN_IN_ENTER`;
export const SIGN_IN_FAILED = `${appName}/${moduleName}/SIGN_IN_FAILED`;
export const SIGN_OUT_REQUEST = `${appName}/${moduleName}/SIGN_OUT_REQUEST`;
export const SIGN_OUT_START = `${appName}/${moduleName}/SIGN_OUT_START`;
export const SIGN_OUT_SUCCESS = `${appName}/${moduleName}/SIGN_OUT_SUCCESS`;
export const SIGN_OUT_UNAUTHORIZED = `${appName}/${moduleName}/SIGN_OUT_UNAUTHORIZED`;
export const SIGN_OUT_FAILED = `${appName}/${moduleName}/SIGN_OUT_FAILED`;
export const RESET_PASSWORD_REQUEST = `${appName}/${moduleName}/RESET_PASSWORD_REQUEST`;
export const FETCH_USER_REQUEST = `${appName}/${moduleName}/FETCH_USER_REQUEST`;
export const FETCH_USER_SUCCESS = `${appName}/${moduleName}/FETCH_USER_SUCCESS`;
export const FETCH_USER_FAILED = `${appName}/${moduleName}/FETCH_USER_FAILED`;
export const SELECT_INTERFACE_REQUEST = `${appName}/${moduleName}/SELECT_INTERFACE_REQUEST`;
export const SELECT_INTERFACE_START = `${appName}/${moduleName}/SELECT_INTERFACE_START`;
export const SELECT_INTERFACE_SUCCESS = `${appName}/${moduleName}/SELECT_INTERFACE_SUCCESS`;
export const SELECT_INTERFACE_FAILED = `${appName}/${moduleName}/SELECT_INTERFACE_FAILED`;
export const INIT_HEARTBEAT = `${appName}/${moduleName}/INIT_HEARTBEAT`;
export const SET_IDLE = `${appName}/${moduleName}/SET_IDLE`;
const invalidCardStatus = {
    logout: 'logout',
    chooseInterface: 'chooseInterface',
};

const initialState = {
    user: null,
    isIdle: false,
    signInInProgress: false,
    twoFactorRequired: false,
    resendCooldown: null,
    maskedPhoneNumber: null,
    signOutInProgress: false,
    selectInterfaceInProgress: false,
    error: null,
    loadTime: new Date(0),
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case SIGN_IN_CLEAR:
            return { ...initialState }

        case SIGN_IN_START:
            return {
                ...state,
                signInInProgress: true,
            }

        case SIGN_IN_2FA_REQUIRED:
            return {
                ...state,
                error: null,
                loadTime: payload.loadTime,
                signInInProgress: false,
                twoFactorRequired: true,
                resendCooldown: payload.resendCooldown,
                maskedPhoneNumber: payload.maskedPhoneNumber,
            }

        case SIGN_IN_SUCCESS:
            return {
                ...initialState,
                error: null,
                user: payload.user,
                loadTime: payload.loadTime,
            }

        case SIGN_IN_FAILED:
            return {
                ...state,
                signInInProgress: false,
                user: null,
                loadTime: payload.loadTime,
                error: error.message,
            }

        case SIGN_OUT_START:
            return {
                ...state,
                signOutInProgress: true,
            }

        case SIGN_OUT_SUCCESS:
            return { ...initialState }

        case SIGN_OUT_FAILED:
            return {
                ...state,
                signOutInProgress: false,
                error: error.message,
            }

        case FETCH_USER_SUCCESS:
            return {
                ...state,
                user: payload.user,
                loadTime: payload.loadTime,
            }

        case FETCH_USER_FAILED:
            return {
                ...state,
                user: null,
                loadTime: payload.loadTime,
                error: error.message,
            }
        case SELECT_INTERFACE_START:
            return {
                ...state,
                selectInterfaceInProgress: true,
            }
        case SELECT_INTERFACE_SUCCESS:
            return {
                ...initialState,
                error: null,
                user: payload.user,
                loadTime: payload.loadTime,
            }
        case SELECT_INTERFACE_FAILED:
            return {
                ...state,
                selectInterfaceInProgress: false,
                user: null,
                loadTime: payload.loadTime,
                error: error.message,
            }
        case SET_IDLE:
            return {
                ...state,
                isIdle: payload
            }
        default:
            return state;
    }
}

export const initHeartBeat = () => {
    return {
        type: INIT_HEARTBEAT
    }
}

export const setIdle = (isIdle) => {
    return {
        type: SET_IDLE,
        payload: isIdle
    }
}

export const signIn = (login, password, rememberMe, code) => {
    return {
        type: SIGN_IN_REQUEST,
        payload: { login, password, rememberMe, code },
    }
}

export const signInAdmin = (login, password, rememberMe, code) => {
    return {
        type: SIGN_IN_ADMIN_REQUEST,
        payload: {login, password, rememberMe, code}
    }
}

export const selectInterface = (userInterface, isRemember) => {
    return {
        type: SELECT_INTERFACE_REQUEST,
        payload: { userInterface, isRemember }
    }
}

export const selectInterfaceStart = () => {
    return {
        type: SELECT_INTERFACE_START
    }
}

export const selectInterfaceSuccess = (user, loadTime) => {
    return {
        type: SELECT_INTERFACE_SUCCESS,
        payload: { user, loadTime },
    }
}

export const selectInterfaceFailed = (error, loadTime) => {
    return {
        type: SELECT_INTERFACE_FAILED,
        payload: { loadTime },
        error,
    }
}

export const signInEnter = () => {
    return {
        type: SIGN_IN_ENTER
    }
}

export const signInClear = () => {
    return {
        type: SIGN_IN_CLEAR
    }
}

export const signInStart = () => {
    return {
        type: SIGN_IN_START
    }
}

export const signIn2FARequired = (resendCooldown, maskedPhoneNumber, loadTime) => {
    return {
        type: SIGN_IN_2FA_REQUIRED,
        payload: { resendCooldown, maskedPhoneNumber, loadTime },
    }
}

export const signInSuccess = (user, loadTime) => {
    return {
        type: SIGN_IN_SUCCESS,
        payload: { user, loadTime },
    }
}

export const signInFailed = (error, loadTime) => {
    return {
        type: SIGN_IN_FAILED,
        payload: { loadTime },
        error,
    }
}

export const signOut = () => {
    return {
        type: SIGN_OUT_REQUEST
    }
}

export const signOutStart = () => {
    return {
        type: SIGN_OUT_START
    }
}

export const signOutSuccess = (isAdmin = false) => {
    return {
        type: SIGN_OUT_SUCCESS,
        payload: isAdmin
    }
}

export const signOutUnauthorized = () => {
    return {
        type: SIGN_OUT_UNAUTHORIZED
    }
}

export const signOutFailed = error => {
    return {
        type: SIGN_OUT_FAILED,
        error,
    }
}

export const fetchUser = (skipCache = false) => {
    return {
        type: FETCH_USER_REQUEST,
        payload: { skipCache }
    }
}

export const fetchUserSuccess = (user, loadTime) => {
    return {
        type: FETCH_USER_SUCCESS,
        payload: { user, loadTime },
    }
}

export const fetchUserFailed = (error, loadTime) => {
    return {
        type: FETCH_USER_FAILED,
        payload: { loadTime },
        error,
    }
}

export const resetPassword = (login, password, token) => {
    return {
        type: RESET_PASSWORD_REQUEST,
        payload: { login, password, token },
    }
}

export const signInSaga = function* (action) {
    yield trySignInSaga(action, signInApi);
}

export const signInAdminSaga = function* (action) {
    yield trySignInSaga(action, signInAdminApi)
}

export const signOutSaga = function* () {
    yield put(signOutStart());

    try {
        const user = yield select(x => x.auth.user);

        yield call(signOutApi);        
        yield put(signOutSuccess(isAdmin(user)));        
    } catch (error) {
        const reqError = new RequestError(error);
        yield all([
            put(signOutFailed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
}

const signOutUnauthorizedSaga = function* () {
    yield put(signOutStart());

    try {
        const user = yield select(x => x.auth.user);
        yield put(signOutSuccess(isAdmin(user)));
    } catch (error) {
        const reqError = new RequestError(error);
        yield all([
            put(signOutFailed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
}

const signOutSuccessSaga = function* (action) {
    try {
        yield put(closeHub());
        yield put(action.payload ? push(adminSignInRoute.url) : push(signInRoute.url));
    } catch (error) {
        const reqError = new RequestError(error);
        yield all([
            put(signOutFailed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
}

const getAuthError = (code) => {
    switch (code) {
        case serviceResultCode.SmsWrongNumber:
        case serviceResultCode.SmsSendFail:
            return `Ошибка отправки сообщения`;
        case serviceResultCode.SignInCredentialError:
        case serviceResultCode.SignInWrongUserNameOrPassword:
            return `Неправильный логин или пароль`;
        case serviceResultCode.SignInPhoneNotConfirmed:
            return `Номер телефона не подтвержден`;
        case serviceResultCode.SignInInvalidCode:
            return `Неправильный код подтверждения`;
        case serviceResultCode.SignInUserIsLockedOut:
            return `Учетная запись пользователя заблокирована`;
        case serviceResultCode.SignInUserIsNotAllowed:
            return `Доступ запрещен`;
        case serviceResultCode.SignInUserNameIsEmpty:
            return `Логин не может быть пустым`;
        case serviceResultCode.SignInPasswordIsEmpty:
            return `Пароль не может быть пустым`;
        case serviceResultCode.UserIdentityErrors:
            return `Пользователь не найден`;
        case serviceResultCode.UserInvalidInterface:
            return `У текущего пользователя, не подключена роль для текущего интерфейса`;
        case serviceResultCode.SignIn2FaUserIsLockedOut:
            return `Учетная запись пользователя временно заблокирована`;
        case serviceResultCode.UserPhoneIsInvalid:
            return `Неправильный телефон пользователя`;
        default:
            return "Произошла непредвиденная ошибка";
    }
}

const checkValidPersonCard = (user) => {
    if (user && !user.personId && user.roles.find((x) => x.id === ROLE_LEADER_ID)) {
        return canSelectInterface(user)
            ? invalidCardStatus.chooseInterface
            : invalidCardStatus.logout;
    }
    return null;
};

export const trySignInSaga = function* (action, apiUrl) {
    yield put(signInStart());

    try {
        const response = yield call(apiUrl, action.payload);
        const result = response.data;

        if (result.isTwoFactorInitiated) {
            yield put(signIn2FARequired(result.codeResendCooldownSeconds, result.maskedPhoneNumber, new Date()))
        } else {
            yield put(signInSuccess(result.user, new Date()));
            yield put(openHub());
            yield put(signInEnter());
        }
        
        const cardStatus = checkValidPersonCard(result.user);
        if (cardStatus === invalidCardStatus.logout) {
            yield put(closeHub());
            yield put(signOut());
            yield put(showErrorAlert('Для входа в систему необходима прикрепленная анкета. Обратитесь в службу поддержки.'));
        }

        if (cardStatus === invalidCardStatus.chooseInterface) {
            yield put(push({ pathname: interfaceSelectorRoute.url }));
        }
    } catch (error) {
        const reqError = getError(error, getAuthError);

        if (getCode(error) === serviceResultCode.SignInPhoneNotConfirmed) {
            yield put(signInClear());

            const params = { login: action.payload.login, phoneToken: error.response.data.payload };
            yield put(push({
                pathname: accountWizard.url,
                search: queryString.stringify(params),
            }));

            return;
        }

        if (getCode(error) === serviceResultCode.SignIn2FaUserIsLockedOut) {
            yield put(showErrorAlert(reqError.message));
            yield put(signInClear());

            const params = { login: action.payload.login, phoneToken: error.response.data.payload };
            yield put(push({
                pathname: homeRoute.url,
                search: queryString.stringify(params),
            }));

            return;
        }

        yield put(signInFailed(error, new Date()));
        yield put(showErrorAlert(reqError.message));
    }
}

export const signInEnterSaga = function* () {
    const search = queryStringParse(window.location.search);

    if (search.from && search.from?.startsWith(resourceViewRoute.buildUrl({ id: null }))) {
        window.location = search.from;
        return;
    }

    yield put(push(search.from || homeRoute.url));
}

export const selectInterfaceSaga = function* (action) {
    yield put(selectInterfaceStart());

    try {
        const response = yield call(changeInterface, action.payload);
        const result = response.data;

        yield put(selectInterfaceSuccess(result, new Date()));

        const search = queryStringParse(window.location.search);

        if (search.from && search.from?.startsWith(resourceViewRoute.buildUrl({ id: null }))) {
            window.location = search.from;
            return;
        }

        yield put(push(search.from || homeRoute.url));
    } catch (error) {
        const reqError = getError(error, getAuthError);

        yield put(selectInterfaceFailed(error, new Date()));
        yield put(showErrorAlert(reqError.message));
    }
}

export const fetchUserSaga = function* (action) {
    const { skipCache } = action.payload;
    const state = yield select();
    const { signInInProgress, signOutInProgress, user } = state.auth;
    if (signInInProgress || signOutInProgress) {
        return;
    }

    try {
        const response = yield call(getCurrentUser, skipCache);
        yield put(fetchUserSuccess(response.data ? response.data : null, new Date()));
        if (!user) {
            yield put(openHub());
        }
    } catch (error) {
        const reqError = new RequestError(error);
        yield put(fetchUserFailed(reqError, new Date()));
    }
};

const heartBeatSaga = function* () {
    const HEARTBEAT_INTERVAL = 60 * 1000;

    while (true) {
        const state = yield select(state => state.auth);

        if (state.user) {
            try {
                const userSession = yield call(heartbeat, { isIdle: state.isIdle });

                if (!!state.user.isDisabled || userSession.status === 204) {
                    yield put(signOut);
                }
            }
            catch (e) {
                if (e?.response?.status !== 401) {
                    console.error(e);
                }
            }
        }

        yield delay(HEARTBEAT_INTERVAL);
      }
}

export const saga = function* () {
    yield all([
        takeLatest(SIGN_IN_REQUEST, signInSaga),
        takeLatest(SIGN_IN_ENTER, signInEnterSaga),
        takeLatest(SIGN_IN_ADMIN_REQUEST, signInAdminSaga),
        takeLatest(SELECT_INTERFACE_REQUEST, selectInterfaceSaga),
        takeLeading(SIGN_OUT_REQUEST, signOutSaga),
        takeLatest(SIGN_OUT_SUCCESS, signOutSuccessSaga),
        takeLatest(SIGN_OUT_UNAUTHORIZED, signOutUnauthorizedSaga),
        takeLeading(FETCH_USER_REQUEST, fetchUserSaga),
        takeLatest(INIT_HEARTBEAT, heartBeatSaga),
    ]);
}