import React from 'react';
import './NotFound.scss';

const NotFound = () => {
    return (
        <h3 className="not-found">
            Ошибка 404. Запрашиваемая страница не найдена.
        </h3>
    );
};
export default NotFound
