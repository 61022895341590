import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import './Header.scss';

const Logo = (props) => {
    const { push } = props;
    const renderHome = () =>  push('/services');

    return (
        <div className="LayoutHeaderLogo" onClick={renderHome} />
    );
};

const actions = { push };

export default connect(null, actions)(Logo);