import React from 'react';
import InputText from 'components/uikit/InputText';
import Input from 'components/uikit/Input';
import Label from 'components/uikit/Label';
import Field from 'components/uikit/Field';

import './TargetCreation.scss';

const TargetCreation = props => {
    return (
        <div className="TargetCreation">
            <div className="TargetCreationTarget__Selector">
                <Field required noLabel invalid={props.isSubmited && !props.isValid} filled={props.isValid}>
                    <Input
                        id="targetInfo"
                        maxLength={4000}
                        value={props.target}
                        onChange={e => {
                            props.onChange('target', e.target.value);
                        }}
                        rows={1}
                    />
                </Field>
            </div>
            <div>
                <Label className="TargetCreationTarget__DescriptionTitle">Цель должна быть:</Label>
                <div className="TargetCreationTarget__DescriptionText">
                    Конкретная, достижимая, измеримая, реалистичная, значимая, определенная по
                    времени
                </div>
            </div>
            <div className="TargetCreationExpectedResult__Title">
                <div>Описание конечного результата:</div>
            </div>
            <Field size="full">
                <div className="TargetCreationExpectedResult__TextArea">
                    <InputText
                        maxLength={4000}
                        value={props.expectedResult}
                        onChange={e => props.onChange('expectedResult', e.target.value)}
                        rows={6}
                    />
                </div>
            </Field>
        </div>
    );
};

export default TargetCreation;
