import React, { useState } from 'react';
import MenuFilter from 'components/Lk/Uikit/Filter/MenuFilter';
import Sorter from 'components/Lk/Uikit/Filter/Sorter';
import { visibilityFilterOptions } from './constants';
import classnames from 'classnames';
import { Col } from 'react-flexbox-grid';
import './ManageWishlists.scss';

const ManageWishlistsFilters = props => {

    const { handleStateChange, showAll = true,  showSorters = true } = props;

    const [selectedVisibilityFilterType, setSelectedVisibilityFilterType] = useState('');
    const [sorting, setSorting] = useState({});

    const setMainState = () => {
        return {
            selectedVisibilityFilterType,
            selectedWishlists: [],
            paging: { pageNum: 1, pageSize: 15 },
            wishLists: [],
            hasMore: true,
        };
    };

    const handleVisibilityChange = filterId => {
        const selectedVisibilityFilterType = visibilityFilterOptions.find(x => +x.id === +filterId).type;
        setSelectedVisibilityFilterType(selectedVisibilityFilterType);
        handleStateChange(
            {
                ...setMainState(),
                filter: {
                    visibilities: selectedVisibilityFilterType
                        ? [selectedVisibilityFilterType]
                        : ['Everyone', 'Group', 'User'],
                },
            }
        );
    };

    const handleSorter = field => state => {
        const copySorting = {...sorting};
        !state && delete copySorting[field];
        const updatedSorting = state ? {...copySorting, [field]: state} : {...copySorting};
        handleStateChange({
            ...setMainState(),
            sorting: updatedSorting,
        });
        setSorting(updatedSorting);
    };

    return (
        <div className={classnames('LKManageWishlistsFilters', {'LKManageWishlistsFilters--hidden': !showAll})}>
            <Col xs={4} className='LKManageWishlistsFilters__Block'>
                <MenuFilter
                    options={visibilityFilterOptions}
                    value={selectedVisibilityFilterType}
                    onChange={handleVisibilityChange}
                    size="small"
                />
            </Col>
            <Col xs={8}  className={classnames('LKManageWishlistsFilters__Block', {'LKManageWishlistsFilters__Block--hidden': !showSorters})}>
                <Sorter value='Размер' size='small' onClick={handleSorter('entriesCount')} />
                <Sorter value='Дата' size='small' onClick={handleSorter('modified')} />
                <Sorter value='А-Я' size='small'  onClick={handleSorter('name')} />
            </Col>
        </div> 
    );
    
};

export default ManageWishlistsFilters;