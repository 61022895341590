import React from "react";
import InfoField from 'components/Lk/Uikit/InfoField';
import Icons from 'components/Lk/Uikit/Icons';
import classnames from "classnames";
import './WishlistVisibilityAttribute.scss';

const WishlistVisibilityAttribute = props => {

    const { attribute, selectItem, selected, value, footer, disabled } = props;
    
    const itemClass = classnames('LKWishlistVisibilityAttribute', {
        'LKWishlistVisibilityAttribute--disabled': disabled
    });
    return (
        <div className={itemClass} onClick={selectItem(attribute)}>
            <div className='LKWishlistVisibilityAttribute__Icon'>
                <Icons name={selected === attribute ? 'radioSelected' : 'radio'}/>
            </div>
            <InfoField noIcon value={value} footer={footer} />
        </div>
    );
};
export default WishlistVisibilityAttribute;