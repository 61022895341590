import React from 'react';
import classnames from 'classnames';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import './StyledCheckBox.scss';
import { CHECKBOX_TYPES } from './constants';

const StyledCheckBox = props => {
    const { title, checked, onChange, children, checkbox = CHECKBOX_TYPES.DEFAULT, checkboxes, dataTest, disabled, ...tail } = props;
    const onCheck = e => {
        if (!onChange || disabled) {
            return;
        }
        onChange(!checked, e);
    };
    const Prefix = checked ? 'Checked' : '';

    const renderCheckBox = () => {
        
        if (checkboxes && Array.isArray(checkboxes) && checkboxes.length >= 2) {
            return <Icons name={checkboxes[+checked]} fill={disabled ? "#a6b2c4" : "#ffffff"} />;
        } else {
            switch (checkbox) {
                case CHECKBOX_TYPES.DEFAULT:
                default:
                    return <Icons name={`checkbox${Prefix}`} fill={disabled ? "#a6b2c4" : "#ffffff"} />;

                case CHECKBOX_TYPES.BORDERED:
                    return <Icons name={`checkboxBorder${Prefix}`} size={20} fill={disabled ? "#a6b2c4" : "#ffffff"} />;
            }
        }
    };

    return (
        <div
            {...tail}
            onClick={onCheck}
            className={classnames('LKStyledCheckBox', { 'LKStyledCheckBox--editable': onChange, 'LKStyledCheckBox--disabled': disabled })}
        >
            <input type="checkbox" checked={checked} readOnly />
            <span className="LKStyledCheckBox__Box" data-test={dataTest}>
                {renderCheckBox()}
            </span>
            {title || children}
        </div>
    );
};

export default StyledCheckBox;
