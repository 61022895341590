import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { signIn as signInRoute, adminSignIn as signInAdminRoute } from 'routes';
import { fetchUser, signIn, signInAdmin, signInEnter } from 'ducks/Auth';
import { resetUserPasswordByEmail, resetAdminPasswordByEmail } from 'api';
import Auth from '../Auth';
import { getError, serviceResultCode } from 'serviceErrors';
import { showInfoAlert, showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import FirstStage from './FirstStage';
import TwoFactorStage from './TwoFactorStage';
import ResetPassword from './ResetPassword';
import SubmitSection from './SubmitSection';

import './SignIn.scss';

const LOGIN_PASSWORD_STAGE = 1;
const TWO_FACTOR_STAGE = 2;

class SignIn extends Component {
    state = {
        login: '',
        password: '',
        rememberMe: true,
        resetPasswordForm: false,
        isPasswordReset: false,
        code: null,
        signInInProgress: false,
        stage: LOGIN_PASSWORD_STAGE,
        codeTimerId: null,
        codeTimerValue: null,
    };

    static getDerivedStateFromProps = (props, state) => {
        const newStage = props.auth.twoFactorRequired ? TWO_FACTOR_STAGE : LOGIN_PASSWORD_STAGE;

        let codeTimerValue = state.codeTimerValue;
        if (
            state.stage !== newStage ||
            (state.signInInProgress && !props.auth.signInInProgress && !props.auth.error)
        ) {
            codeTimerValue = newStage === TWO_FACTOR_STAGE ? props.auth.resendCooldown || 0 : null;
        }

        return {
            ...state,
            stage: newStage,
            codeTimerValue,
            signInInProgress: props.auth.signInInProgress,
        };
    };

    componentDidMount() {
        const { auth, showInfoAlert } = this.props;

        if (auth && auth.user) {
            this.props.signInEnter();
        }

        const parameters = qs.parseUrl(window.location.href);

        if (parameters.query && parameters.query.expired) {
            showInfoAlert(
                'Ваша сессия работы в системе истекла. ' +
                    'Пожалуйста, войдите в систему повторно.',
            );
            this.props.push(signInRoute.buildUrl({}));
        }
        document.addEventListener('keypress', this.handleKeyPress);
    }

    handleKeyPress = (e) => {
        const { resetPasswordForm } = this.state;
        e.key === 'Enter' && !resetPasswordForm && this.onSubmit(e);
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { stage, codeTimerId } = this.state;
        const { location } = this.props;
        
        if (prevProps.location.search !== location.search) {
            const parameters = qs.parse(location.search)
            this.setState({
                resetPasswordForm: parameters.resetPassword ? true : false,
            });
        }

        const prevStage = prevState.stage;
        if (stage === prevStage) {
            return;
        }

        if (codeTimerId) {
            clearInterval(codeTimerId);
            this.setState({ codeTimerId: null });
        }

        if (stage === TWO_FACTOR_STAGE) {
            const codeTimerId = setInterval(() => {
                this.setState((state) => {
                    let codeTimerValue = state.codeTimerValue;
                    if (codeTimerValue === null || codeTimerValue === 0) {
                        return {};
                    }
                    return { codeTimerValue: codeTimerValue - 1 };
                });
            }, 1000);
            this.setState({ codeTimerId });
        }
    };

    componentWillUnmount = () => {
        document.removeEventListener('keypress', this.handleKeyPress);
        if (this.state.codeTimerId) {
            clearInterval(this.state.codeTimerId);
        }
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onRememberMeChanged = (e) => {
        this.setState({ rememberMe: !e.target.checked });
    };

    onResendCode = (e) => {
        e.preventDefault();

        const { login, password, rememberMe } = this.state;
        this.signIn(login, password, rememberMe, null);
    };

    onSubmit = (e) => {
        e.preventDefault();

        const { login, password, rememberMe } = this.state;
        const code = this.state.stage === TWO_FACTOR_STAGE ? this.state.code : null;

        this.signIn(login, password, rememberMe, code);
    };

    resetPassword = async (login) => {
        if (!login || (login && !login.trim())) {
            this.props.showErrorAlert('Необходимо указать адрес электронной почты');
            return;
        }

        const isAdminPath = this.props.isAdmin;

        try {
            if (isAdminPath) {
                await resetAdminPasswordByEmail(login);
            } else {
                await resetUserPasswordByEmail(login);
            }
            this.setState({ isPasswordReset: true });
        } catch (error) {
            const reqError = getError(error, this.getResetPasswordError);
            this.props.showErrorAlert(reqError.message);
            this.setState({ disableResetPasswordButton: false });
            return;
        } finally {
            clearInterval(this.state.resetPasswordTimerId);
        }
    };

    getResetPasswordError = (code) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `Пользователь с таким Email не найден`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    signIn = (login, password, rememberMe, code) => {
        return this.props.isAdmin
            ? this.props.signInAdmin(login, password, rememberMe, code)
            : this.props.signIn(login, password, rememberMe, code);
    };

    setResetPasswordStage = (val = true) => {
        const { location, push } = this.props;

        push(
            val
                ? { pathname: location.pathname, search: '?resetPassword=true' }
                : { pathname: location.pathname, search: '' },
        );
    };

    isSubmitDisabled = () => {
        const { stage, login, password, code } = this.state;

        return (
            (stage !== LOGIN_PASSWORD_STAGE && !code) ||
            (stage === LOGIN_PASSWORD_STAGE && (!password || !login))
        );
    };

    render() {
        const {
            stage,
            resetPasswordForm,
            login,
            signInInProgress,
            password,
            rememberMe,
            code,
            codeTimerValue,
        } = this.state;

        return (
            <div className="kr_form_wrap">
                {!this.state.isPasswordReset && (
                    <>
                        <div className="kr_form_title" data-test="MainPageSignInFormTitle--text">
                            {resetPasswordForm ? 'Восстановление пароля' : 'Вход'}
                        </div>
                        <div>
                            <div className="SignIn__StageFormContainer">
                                {resetPasswordForm ? (
                                    <ResetPassword
                                        login={login}
                                        onChange={this.onChange}
                                        resetPassword={this.resetPassword}
                                        setResetPasswordStage={this.setResetPasswordStage}
                                    />
                                ) : stage === LOGIN_PASSWORD_STAGE ? (
                                    <FirstStage
                                        login={login}
                                        password={password}
                                        rememberMe={rememberMe}
                                        onChange={this.onChange}
                                        setResetPasswordStage={this.setResetPasswordStage}
                                        onRememberMeChanged={this.onRememberMeChanged}
                                        signInInProgress={signInInProgress}
                                    />
                                ) : (
                                    <TwoFactorStage
                                        code={code}
                                        codeTimerValue={codeTimerValue}
                                        signInInProgress={signInInProgress}
                                        onChange={this.onChange}
                                        onResendCode={this.onResendCode}
                                    />
                                )}
                            </div>
                            {!resetPasswordForm && (
                                <SubmitSection
                                    onSubmit={this.onSubmit}
                                    disabled={this.isSubmitDisabled()}
                                    withLegalNotice={stage === LOGIN_PASSWORD_STAGE}
                                />
                            )}
                        </div>
                    </>
                )}
                {this.state.isPasswordReset && (
                    <div className="kr_form_title">
                        Письмо отправлено
                        <div className="SignIn__PasswordResetNotice">
                            На почту{' '}
                            <div className="SignIn__PasswordResetNotice--highlight">{login}</div>{' '}
                            отправлено письмо с инструкцией по восстановлению пароля. Обязательно
                            проверьте папку "Спам".
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const props = (state) => {
    return {
        auth: state.auth,
        isAdmin:
            state.router &&
            state.router.location &&
            state.router.location.pathname === signInAdminRoute.buildUrl(),
    };
};

const actions = {
    signIn,
    signInAdmin,
    signInEnter,
    fetchUser,
    push,
    showInfoAlert,
    showErrorAlert,
    showSuccessAlert,
};

export default withRouter(connect(props, actions)(Auth(SignIn)));
