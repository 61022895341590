
export const sortingOptions = [
    { id: 1, label: 'Без сортировки', type: undefined, indicator: '' },
    { id: 2, label: 'Мин', type: 'asc', indicator: 'up' },
    { id: 3, label: 'Макс', type: 'desc', indicator: 'down' },
];

export const ALL_MENU_ID = 'full';
export const HAS_NO_PLAN_MENU_ID = 'hasNoPlan';

export const hasNoPlanMenuOptions = [
    {id: ALL_MENU_ID, name: 'Все', type: 'full'},
    {id: HAS_NO_PLAN_MENU_ID, name: 'Без ИПР', type: 'hasNoPlan'},
];