import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { getError } from 'serviceErrors';

import { newsRoute, newsEditRoute } from 'routes';
import { push as pushLocation } from 'connected-react-router';
import { parse as queryStringParse, stringify } from 'query-string';
import { Link } from 'react-router-dom';

import Button from 'components/uikit/Button';
import DataGrid from 'components/common/DataGrid';
import ListTemplate from 'components/uikit/ListTemplate/ListTemplate';
import Page from 'components/common/Page';
import ModalDialog from 'components/common/ModalDialog';

import { getTags, updateNewsStatus, searchNews } from 'api';
import { NEWS_STATUS, NEWS_STATUS_ENUM } from 'constants.js';

const initalState = {
    criteria: {
        filter: {
            statuses: [],
            tags: null,
        },
        paging: {
            pageNum: 1,
            pageSize: 10,
        },
    },
    payload: {
        data: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
};

class News extends Component {
    state = {
        ...initalState,
    };

    componentDidMount() {
        const { filter, paging } = this.state.criteria;

        this.fetchTags();
        this.fetchData();

        if (
            this.props.queryParams.status !== filter.statuses ||
            this.props.queryParams.tag !== filter.tags
        ) {
            this.setState(state => ({
                criteria: {
                    ...state.criteria,
                    filter: {
                        statuses: this.props.queryParams.status,
                        tags: this.props.queryParams.tag,
                    },
                    paging: {
                        ...state.criteria.paging,
                        pageNum: paging.pageNum,
                    },
                },
            }));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.criteria.paging.pageNum !== this.state.criteria.paging.pageNum ||
            JSON.stringify(prevProps.queryParams.status) !==
                JSON.stringify(this.props.queryParams.status) ||
            JSON.stringify(prevProps.queryParams.tag) !== JSON.stringify(this.props.queryParams.tag)
        ) {
            this.fetchData();
        }
    }

    getAsArray = items => {
        return items && !Array.isArray(items) ? [items] : items;
    };

    fetchData = () => {
        window.scrollTo(0, 0);

        const criteria = {
            filter: {
                statuses: this.getAsArray(this.props.queryParams.status),
                tags: this.getAsArray(this.props.queryParams.tag),
            },
            paging: {
                ...this.state.criteria.paging,
            },
        };

        if (!criteria.filter.statuses || criteria.filter.statuses.length === 0) {
            criteria.filter.statuses = [NEWS_STATUS_ENUM.Published, NEWS_STATUS_ENUM.Draft];
        }

        this.callEffect(async () => {
            const result = await searchNews(criteria);

            this.setState({
                payload: {
                    data: [...result.data.payload],
                    meta: { ...result.data.meta },
                },
            });
        });
    };

    fetchTags = () => {
        this.callEffect(async () => {
            const result = await getTags();

            const tags = result.data.map(x => {
                return { value: x.id, label: x.title };
            });

            this.setState({
                tags: tags,
            });
        });
    };

    callEffect = async callback => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            const reqError = getError(error, `Произошла непредвиденная ошибка`);
            this.props.showErrorAlert(reqError.message);
        } finally {
            this.props.hidePageLoader();
        }
    };

    onPageChange = pageIndex => {
        this.setState(state => ({
            criteria: {
                ...state.criteria,
                paging: { ...state.criteria.paging, pageNum: pageIndex + 1 },
            },
        }));
    };

    onFilterSubmit = () => {
        const { criteria } = this.state;

        const urlParams = {
            page: 1,
            tag: criteria.filter.tags,
            status: criteria.filter.statuses,
        };

        this.props.pushLocation({ pathname: newsRoute.url, search: stringify(urlParams) });
    };

    onClearFilters = () => {
        this.setState(initalState);
        this.props.pushLocation({ pathname: newsRoute.url, search: stringify({ page: 1 }) });
    };

    onEditNews = id => {
        this.props.pushLocation(newsEditRoute.buildUrl({ id }));
    };

    onUpdateNewsStatus = (news, dialogAction) => () => {
        this.setState({ current: { ...news }, dialogAction });
    };

    onHide = () => this.setState({ current: null, dialogAction: null });

    updateNewsStatus = async () => {
        const { current, dialogAction } = this.state;
        this.setState({ dialogProcessing: true });

        this.callEffect(async () => {
            await updateNewsStatus(current.id, dialogAction);
            this.fetchData();
            this.setState({ dialogProcessing: false, current: null, dialogAction: null });
        });
    };

    addNews = () => {
        this.props.pushLocation(newsEditRoute.buildUrl({ id: 'new' }));
    };

    onSelectChange = (name, values) => {
        this.setState(state => ({
            criteria: {
                ...state.criteria,
                filter: {
                    ...state.criteria.filter,
                    [name]: values && values.map(x => x.value),
                },
            },
        }));
    };

    render() {
        const buildColumns = [
            {
                Header: 'Заголовок',
                resizable: false,
                Cell: ({ original }) =>
                    original.title && (
                        <Link to={newsEditRoute.buildUrl({ id: original.id, mode: 'view' })}>
                            {original.title}
                        </Link>
                    ),
            },
            {
                Header: '',
                maxWidth: 500,
                resizable: false,
                Cell: ({ original }) => (
                    <div className="ButtonsWrapper">
                        <Button
                            disabled={original.status === NEWS_STATUS_ENUM.Published}
                            size="sm"
                            onClick={this.onUpdateNewsStatus(original, NEWS_STATUS_ENUM.Published)}
                            className="ButtonsWrapper__Button"
                            type="button"
                        >
                            {original.status === NEWS_STATUS_ENUM.Published
                                ? 'Опубликовано'
                                : 'Опубликовать'}
                        </Button>
                        <Button
                            size="sm"
                            onClick={() => this.onEditNews(original.id)}
                            className="ButtonsWrapper__Button"
                        >
                            Редактировать
                        </Button>
                        <Button
                            size="sm"
                            onClick={this.onUpdateNewsStatus(original, NEWS_STATUS_ENUM.Deleted)}
                            className="ButtonsWrapper__Button"
                            color="danger"
                            type="button"
                        >
                            Удалить
                        </Button>
                    </div>
                ),
            },
        ];

        const customSelectStyles = {
            control: base => ({
                ...base,
                backgroundColor: '#f9f9ff',
                borderColor: '#e6dfff',
            }),
            input: base => ({
                ...base,
                padding: 0,
                margin: 0,
            }),
            menu: base => ({
                ...base,
                color: '#767268',
            }),
            singleValue: base => ({
                ...base,
                color: '#767268',
            }),
            valueContainer: base => ({
                ...base,
            }),
        };

        const { filter } = this.state.criteria;
        const { tags, dialogAction, dialogProcessing } = this.state;

        const selectedStatuses =
            filter &&
            filter.statuses &&
            NEWS_STATUS.filter(x => filter.statuses.indexOf(x.value) >= 0);
        const selectedTags =
            tags && filter && filter.tags && tags.filter(x => filter.tags.indexOf(x.value) >= 0);

        let dialogMessage;
        let dialogButtonLabel;
        switch (dialogAction) {
            case NEWS_STATUS_ENUM.Deleted:
                dialogMessage = `Вы уверены, что хотите удалить новость?`;
                dialogButtonLabel = `Удалить`
                break;
            case NEWS_STATUS_ENUM.Published:
                dialogMessage = `Вы уверены, что хотите опубликовать новость?`;
                dialogButtonLabel = `Опубликовать`
                break;
            default:
        }

        const filterForm = {
            title: 'Фильтры',
            line: [
                {
                    type: 'select',
                    label: 'Статусы',
                    value: selectedStatuses,
                    inputId: 'statuses',
                    placeholder: 'Выберите статусы',
                    options: NEWS_STATUS.filter(x => x.value !== NEWS_STATUS_ENUM.Deleted),
                    onChange: values => this.onSelectChange('statuses', values),
                    isMulti: true,
                    styles: customSelectStyles,
                    noOptionsMessage: () => 'Пусто',
                },
                {
                    type: 'select',
                    label: 'Теги',
                    value: selectedTags,
                    inputId: 'tags',
                    placeholder: 'Выберите теги',
                    options: tags,
                    onChange: values => this.onSelectChange('tags', values),
                    isMulti: true,
                    styles: customSelectStyles,
                    noOptionsMessage: () => 'Пусто',
                },
            ],
            btnOnClick: this.onFilterSubmit,
            btnClearOnClick: this.onClearFilters,
        };

        const headBtn = {
            onClick: this.addNews,
            value: 'Добавить',
            addLink: true,
        };

        const { payload, criteria } = this.state;

        return (
            <Page>
                <ListTemplate title={'Список новостей'} headBtn={headBtn} form={filterForm}>
                    <DataGrid
                        data={payload.data}
                        foundCount={payload.meta.foundCount}
                        columns={buildColumns}
                        showPagination={true}
                        showPageSizeOptions={false}
                        pages={payload.meta.pageCount}
                        page={+criteria.paging.pageNum || 1}
                        onPageChange={this.onPageChange}
                        manual
                    />
                    <ModalDialog
                        onClick={this.updateNewsStatus}
                        onCloseModal={this.onHide}
                        modalOpen={!!dialogAction}
                        modalHeader={dialogMessage || ""}
                        btnOktext={dialogButtonLabel}
                        btnCanceltext="Отмена"
                        btnOkColor={
                            dialogAction === NEWS_STATUS_ENUM.Deleted ? "danger" : "primary"
                        }
                        isDangerHeader={
                            dialogAction === NEWS_STATUS_ENUM.Deleted ? true : false
                        }
                        processing={dialogProcessing}
                    ></ModalDialog>
                </ListTemplate>
            </Page>
        );
    }
}

const props = state => {
    return {
        queryParams: queryStringParse(state.router.location.search),
    };
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    pushLocation,
};

export default connect(props, actions)(News);
