import React from 'react';
import classnames from 'classnames';
import { isNullOrWhitespace } from 'utils';
import { getChartParams, getDescription } from '../chartsHelper';
import './Charts.scss';
import LineChart from './LineChart';
import Popover from 'components/Lk/Uikit/Popover';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import { isDevice } from 'utils';
import { EVALUATIONTEST_CRITERIONRESULT_STATUS, EVALUATIONTEST_STATUS } from 'constants.js';

const VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const SCALES = ['Сильная', 'Умеренная', 'Неясная', 'Умеренная', 'Сильная'];

const Signals = (props) => {
    const { data, withHeader, withDescr } = props;
    const chartParams = getChartParams(data.kind);
    const description = getDescription(data, chartParams, true);
    
    const isTestActive = data.resultType === EVALUATIONTEST_STATUS.Relevant;

    const { result, criteria, title } = data;

    const getValue = (v) => (Math.abs((v || 1) - 5.5) + 0.5) * 20;

    const renderValueLine = (value, left, right, description, extraDescription) => {
        return (
            <>
                <div className="LKSignalsValueLineChart">
                    <div className="LKSignalsValueLineChart__Item">
                        <Popover tooltip={description} autoPlacement={false}>
                            <LineChart
                                title={left}
                                progress={6 - value > 0 && getValue(value)}
                                reverse
                                color="#63C9B6"
                                withPercent={false}
                            />
                        </Popover>
                    </div>
                    <div className="LKSignalsSeparator">
                        <div className="LKSignalsSeparator__Item" />
                    </div>
                    <div className="LKSignalsValueLineChart__Item">
                        <Popover tooltip={extraDescription} autoPlacement={false}>
                            <LineChart
                                title={right}
                                progress={value - 5 > 0 && getValue(value)}
                                withPercent={false}
                                color="#63C9B6"
                            />
                        </Popover>
                    </div>
                </div>
            </>
        );
    };

    const renderSeparators = () => {
        return (
            <>
                <div className="LKSignalsSeparatorLineVal">
                    <span className="LKSignalsSeparatorLineVal__Span LKSignalsSeparatorLineVal__Span--left">
                        1
                    </span>
                    <span className="LKSignalsSeparatorLineVal__Span">5</span>
                    <span className="LKSignalsSeparatorLineVal__Span LKSignalsSeparatorLineVal__Span--right">
                        10
                    </span>
                </div>
                <div className="LKSignalsSeparatorLine">
                    {SCALES.map((v, i) => (
                        <div
                            key={`col-${v}_${i}`}
                            className={classnames('LKSignalsSeparatorLine__Item')}
                        />
                    ))}
                </div>
                <div className="LKSignalsSeparatorLine LKSignalsSeparatorLine--border">
                    {VALUES.map((v) => (
                        <div
                            key={`col-${v}`}
                            className={classnames('LKSignalsSeparatorLine__Item')}
                        />
                    ))}
                </div>
            </>
        );
    };

    const renderCriteriaLine = (item) => {
        const { id, evaluationTestCriterionId, normalizedValue } = item;

        const { title, description, extraDescription } = criteria[evaluationTestCriterionId];
        const [leftTitle, rightTitle] = title.split(/[-––]/);
        
        const isCriteriaActive = item.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Relevant;
        
        return (
            <div key={id} className={classnames({'LKSignalsValueLineChartWrapper--notActive': !isTestActive || !isCriteriaActive})}>
                {renderValueLine(
                    Math.ceil(normalizedValue * 10),
                    leftTitle,
                    rightTitle,
                    description,
                    extraDescription,
                )}
                <div className="LKSignalsValueLineChart">
                    <div className="LKSignalsValueLineChart__Item" />
                    <div className="LKSignalsSeparator">
                        <div className="LKSignalsSeparator__Item" />
                    </div>
                    <div className="LKSignalsValueLineChart__Item" />
                </div>
            </div>
        );
    };
    const renderHeader = () => {
        return (
            <div className="LKSigalsHeader">
                {SCALES.map((title, idx) => (
                    <div className="LKSigalsHeader__Item" key={idx}>
                        {title}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="LKEvaluate-Block--full">
            {withHeader && !isDevice.SM() && (
                <div className="LKEvaluate-Block__Header">
                    {title}
                    {!isNullOrWhitespace(description) && (
                        <Popover tooltip={description} autoPlacement={false}>
                            <Icons name="help-alt" fill={'#007AFF'} />
                        </Popover>
                    )}
                </div>
            )}
            {isDevice.SM() && withDescr && <div className="LKEvaluate-Block__Descr">{description}</div>}
            {renderSeparators()}
            {renderHeader()}
            {result.criteria.map(renderCriteriaLine)}
        </div>
    );
};

export default Signals;
