import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AdaptiveProviderContext } from 'components/common/AdaptiveProvider';
import { PageLoader } from 'components/common/Loader';
import NavMenu from '../NavMenu';
import ErrorBoundary from 'components/common/ErrorBoundary';
import './Layout.scss'

class Layout extends Component {
    static contextType = AdaptiveProviderContext;

    state = {
        mobileMenuIsOpen: false,
    };

    render() {
        const { children } = this.props;
        const { mobileMenuIsOpen } = this.state;

        const finalClass = 'Layout' + (mobileMenuIsOpen ? ' Layout_CutDown' : '');

        return (
            <div className={finalClass}>
                <div className="Layout__Wrapper">
                    <NavMenu
                        mobileMenuIsOpen={mobileMenuIsOpen}
                        onToggleMobileMenu={this.toggleMobileMenu}
                    />
                    <div className="Layout__Content">
                        <ErrorBoundary>
                            {children}
                        </ErrorBoundary>
                    </div>
                    <PageLoader />
                </div>
            </div>
        );
    }

    toggleMobileMenu = () => {
        const { isMobile } = this.context;

        if (isMobile) {
            this.setState({
                mobileMenuIsOpen: !this.state.mobileMenuIsOpen,
            });
        }
    };
}

function mapStateToProps(state) {
    return {
        path: state.router.location.pathname,
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(Layout);
