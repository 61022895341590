import React from 'react';
import PropTypes from 'prop-types' ;
import classnames from 'classnames';

import './PageCardWrapper.scss';

const PageCardWrapper = (props) => {
    const { header, subHeader, footer, rightFooter, noFooterClass, size = "lg" } = props;
    return (
        <div className={classnames("Wrapper", `Wrapper--${size}`)}>
            {header && <div className={classnames("Wrapper__Header", `Wrapper__Header--${size}`)}>{header}</div>}
            {subHeader && <div className="Wrapper__SubHeader">{subHeader}</div>}
            <div className={classnames({"Wrapper__Body": !noFooterClass}, {"Wrapper__Body--no-footer": !footer ||noFooterClass})}>{props.children}</div>
            {footer && 
                <div className={classnames("Wrapper__Footer", `Wrapper__Footer--${size}`, {"Wrapper__Footer--right": rightFooter})}>
                    {footer}
                </div>
            }
            
        </div>
    );
};

PageCardWrapper.propTypes = {
    header: PropTypes.any,
    subHeader: PropTypes.any,
    footer: PropTypes.any,
    rightFooter: PropTypes.bool,
    size: PropTypes.string,
};

export default PageCardWrapper;