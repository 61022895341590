import React, { useState } from 'react';
import { connect } from 'react-redux';
import InfoField from 'components/Lk/Uikit/InfoField';
import { formatDate } from 'utils.js';
import { Select } from 'components/Lk/Uikit/Select/Select';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import { WORK_DATE_FORMATE, DATE_CONFIG } from './constants';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox/StyledCheckBox';
import classnames from 'classnames';
import NoData from './NoData';
import { MENU_PROFILE_WORK_EXPERIENCE } from './constants';

const WorkExperienceInfo = props => {
    const {
        person,
        isWrap,
        setWrap,
        onChange,
        isEdit,
        industries,
        workAreas,
        managementLevels,
        managementExperiences,
        employeesNumbers,
        setVisibleWrapSection,
    } = props;

    const {
        workInfo: { workPlaces },
    } = person;

    const [currentId, setCurrentId] = useState(false);

    const [currentWork = {}] = workPlaces.filter(item => item.id === currentId);

    const selectWork = id => () => {
        setCurrentId(id);
        setWrap(true);
        setVisibleWrapSection(MENU_PROFILE_WORK_EXPERIENCE);
    };

    const onAdd = () => {
        const id = -new Date().getTime();
        const newRecord = { id };
        onChange({ ...person, workInfo: { workPlaces: [...workPlaces, newRecord] } }, 'WorkBlock');
    };

    const onChangeState = name => value => {
        const newRecord = {
            ...currentWork,
            quitDate: name === 'isCurrentWorkPlace' && value ? null : currentWork.quitDate,
            [name]: value,
        };

        let newRecords = workPlaces.map(item => (item.id === newRecord.id ? newRecord : item));
        newRecords = name === 'isMainWorkPlace' && value ? workPlaces.map(item => (item.id !== newRecord.id ? {...item, isMainWorkPlace: false} : newRecord)) : newRecords;
        onChange({ ...person, workInfo: { workPlaces: newRecords } }, 'WorkBlock');
    };

    const onRemove = id => e => {
        e.stopPropagation();
        onChange(
            { ...person, workInfo: { workPlaces: workPlaces.filter(a => a.id !== id) } },
            'WorkBlock',
        );
    };

    const getPeriod = (hireDate, quitDate, isCurrentWorkPlace) =>
        `${(hireDate && formatDate(hireDate, WORK_DATE_FORMATE)) || ''} - ${
            isCurrentWorkPlace
                ? 'наст. вр'
                : (quitDate && formatDate(quitDate, WORK_DATE_FORMATE)) || ''
        }`;

    return (
        <div className={classnames('PersonalInfo', { 'PersonalInfo--bottom-margin': isEdit })}>
            {isWrap && currentId !== false ? (
                <>
                    <InfoField
                        title="Место работы"
                        isEdit={isEdit}
                        required
                        value={currentWork.company}
                        onChange={onChangeState('company')}
                        maxLength={250}
                        noIcon
                    ></InfoField>
                    <InfoField
                        title="Должность"
                        required
                        isEdit={isEdit}
                        value={currentWork.position}
                        onChange={onChangeState('position')}
                        maxLength={250}
                        noIcon
                    ></InfoField>
                    <InfoField
                        title="Отрасль"
                        value={currentWork.industry && currentWork.industry.name}
                        isEdit={isEdit}
                        editComponent={
                            <Select
                                items={industries}
                                value={currentWork.industry && currentWork.industry.id}
                                onChange={onChangeState('industry')}
                            />
                        }
                        noIcon 
                    />
                    <InfoField
                        title="Область деятельности"
                        value={currentWork.workArea && currentWork.workArea.name}
                        isEdit={isEdit}
                        editComponent={
                            <Select
                                items={workAreas}
                                value={currentWork.workArea && currentWork.workArea.id}
                                onChange={onChangeState('workArea')}
                            />
                        }
                        noIcon
                    />
                    <InfoField
                        title="Уровень менеджмента"
                        value={currentWork.managementLevel && currentWork.managementLevel.name}
                        isEdit={isEdit}
                        editComponent={
                            <Select
                                items={managementLevels}
                                value={
                                    currentWork.managementLevel && currentWork.managementLevel.id
                                }
                                onChange={onChangeState('managementLevel')}
                            />
                        }
                        noIcon
                    />
                    <InfoField
                        title="Опыт управления"
                        value={
                            currentWork.managementExperience &&
                            currentWork.managementExperience.name
                        }
                        isEdit={isEdit}
                        editComponent={
                            <Select
                                items={managementExperiences}
                                value={
                                    currentWork.managementExperience &&
                                    currentWork.managementExperience.id
                                }
                                onChange={onChangeState('managementExperience')}
                            />
                        }
                        noIcon
                    />
                    <InfoField
                        title="Кол-во человек в подчинении"
                        isEdit={isEdit}
                        value={currentWork.employeesNumber && currentWork.employeesNumber.name}
                        editComponent={
                            <Select
                                items={employeesNumbers}
                                value={
                                    currentWork.employeesNumber && currentWork.employeesNumber.id
                                }
                                onChange={onChangeState('employeesNumber')}
                            />
                        }
                        noIcon
                    />
                    <InfoField
                        title="Начало работы"
                        isEdit={isEdit}
                        value={currentWork.hireDate}
                        inputProps={{ ...DATE_CONFIG }}
                        type="date"
                        onChange={onChangeState('hireDate')}
                        footer={
                            <>
                                <div className='PersonalInfo__MultiCheck'>
                                    <StyledCheckBox
                                        title="По настоящее время"
                                        checked={currentWork.isCurrentWorkPlace || false}
                                        onChange={isEdit ? onChangeState('isCurrentWorkPlace') : undefined}
                                    />
                                </div>
                                {currentWork.isCurrentWorkPlace && 
                                    <div className='PersonalInfo__MultiCheck'>
                                        <StyledCheckBox
                                            title="Основное место работы"
                                            checked={currentWork.isMainWorkPlace}
                                            onChange={isEdit ? onChangeState('isMainWorkPlace') : undefined}
                                        />
                                    </div>
                                }
                            </>
                        }
                        noIcon
                    >
                        {currentWork.hireDate &&
                            formatDate(currentWork.hireDate, WORK_DATE_FORMATE)}
                    </InfoField>
                    {!currentWork.isCurrentWorkPlace && (
                        <InfoField
                            title="Окончания работы"
                            isEdit={isEdit}
                            inputProps={{ ...DATE_CONFIG }}
                            type="date"
                            value={currentWork.quitDate}
                            onChange={onChangeState('quitDate')}
                            noIcon
                        >
                            {currentWork.quitDate &&
                                formatDate(currentWork.quitDate, WORK_DATE_FORMATE)}
                        </InfoField>
                    )}
                    <InfoField
                        type="textarea"
                        title="Профессиональные достижения"
                        isEdit={isEdit}
                        maxLength={450}
                        value={currentWork.professionalAchievements || ''}
                        onChange={onChangeState('professionalAchievements')}
                        noIcon
                    />
                    <InfoField
                        type="textarea"
                        title="Дополнительные сведения"
                        isEdit={isEdit}
                        value={currentWork.externalInfo || ''}
                        onChange={onChangeState('externalInfo')}
                        maxLength={4000}
                        noIcon
                    />
                </>
            ) : (
                <>
                    {
                        (workPlaces.size && workPlaces.size !== 0) || (workPlaces.length && workPlaces.length !== 0)
                        ? workPlaces.map(
                            ({ company, position, isCurrentWorkPlace, hireDate, quitDate, id }) => (
                                <InfoField
                                    icon={
                                        isEdit && {
                                            icon: 'minus-circle',
                                            size: 'xs',
                                            color: 'red',
                                            onClick: onRemove(id),
                                        }
                                    }
                                    key={id}
                                    title={company}
                                    onWrap={selectWork(id)}
                                    footer={getPeriod(hireDate, quitDate, isCurrentWorkPlace)}
                                    noIcon={!isEdit}
                                >
                                    {position}
                                </InfoField>
                            ),
                        )
                        : (!isEdit && <NoData text='Нет данных' />)
                    }
                    {isEdit && (
                        <InfoField noIcon>
                            <ProfileButton onClick={onAdd} dataTest="WorkExperienceInfo_AddWork--button">добавить</ProfileButton>
                        </InfoField>
                    )}
                </>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    industries: state.catalogs.industries.data,
    workAreas: state.catalogs.workAreas.data,
    managementLevels: state.catalogs.managementLevels.data,
    managementExperiences: state.catalogs.managementExperiences.data,
    employeesNumbers: state.catalogs.employeesNumbers.data,
});

export default connect(mapStateToProps)(WorkExperienceInfo);
