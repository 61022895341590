import { appName } from '../constants';
import { takeLatest, call, all, put, select } from 'redux-saga/effects';
import { getFullWishlist } from 'api';
import { showErrorAlert } from './Alert';
import RequestError from 'RequestError';

const moduleName = 'favorites';
const FETCH = `${appName}/${moduleName}/FETCH`;
const SET = `${appName}/${moduleName}/SET`;
const CLEAR = `${appName}/${moduleName}/CLEAR`;
const REQUEST = `${appName}/${moduleName}/REQUEST`;
const SUCCESS = `${appName}/${moduleName}/SUCCESS`;

const initialState = {
    personIds: [],
    persons:{},
    isLoaded: false,
    loading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET:
            const { personIds, persons } = action.payload;
            return { ...state, personIds, persons };
        case CLEAR:
            return initialState;
        case REQUEST:
            return { ...state, isLoaded: false, loading: true };
        case SUCCESS:
            return { ...state, isLoaded: true, loading: false };
        default:
            return state;
    }
}

export const fetchFavorites = payload => ({ type: FETCH, payload });
export const setFavorites = payload => ({ type: SET, payload });

const onLoadFavorites = () => ({ type: SUCCESS });
const onRequestFavorites = () => ({ type: REQUEST });

function* fetchFavoritesSaga({ payload }) {
    try {
        const { isLoaded } = yield select(s => s.favorites);
        if ( isLoaded ) { return; }
        yield put(onRequestFavorites());
        const response = yield call(getFullWishlist, payload);
        yield put(setFavorites(response.data));
        yield put(onLoadFavorites());
    } catch (error) {
        
        if (error && error.response && error.response.status === 404) {
            return;
        }

        const reqError = new RequestError(error, 'При загрузке фаворитов произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

export function* saga() {
    yield all([takeLatest(FETCH, fetchFavoritesSaga)]);
}
