import { appName, ROLES_DICTIONARY_NAME, GROUPS_DICTIONARY_NAME } from '../constants';
import { takeEvery, takeLatest, put, call, select, all, takeLeading } from 'redux-saga/effects';
import { createUser, editUser, getUser, uploadUserPhoto } from '../api';
import { push } from 'connected-react-router';
import { user as userRoute, users as usersRoute, notFound } from 'routes';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';
import { fetchDictionaries, isDictionariesLoaded } from './Dictionary';
import { withPageLoader } from './PageLoader';
import { serviceResultCode, getError } from 'serviceErrors';
import { isLockedOut } from './Users';

const moduleName = 'user';
const NEW_USER = `${appName}/${moduleName}/NEW_USER`;
const SAVE_REQUEST = `${appName}/${moduleName}/SAVE_REQUEST`;
const CANCEL = `${appName}/${moduleName}/CANCEL`;
const SAVE_START = `${appName}/${moduleName}/SAVE_START`;
const SAVE_SUCCESS = `${appName}/${moduleName}/SAVE_SUCCESS`;
const SAVE_FAILED = `${appName}/${moduleName}/SAVE_FAILED`;
const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;
const SET_IS_DISABLED = `${appName}/${moduleName}/SET_IS_DISABLED`;
const SET_IS_LOCKED = `${appName}/${moduleName}/SET_IS_LOCKED`;
const SAVE_PHOTO = `${appName}/${moduleName}/SAVE_PHOTO`;
const SAVE_PHOTO_SUCCESS = `${appName}/${moduleName}/SAVE_PHOTO_SUCCESS`;
const SAVE_PHOTO_FAILED = `${appName}/${moduleName}/SAVE_PHOTO_FAILED`;

export const allUserDictionaries = [ROLES_DICTIONARY_NAME, GROUPS_DICTIONARY_NAME];

const cacheTimeoutInSeconds = 60;

const initialState = {
    loading: false,
    loadComplete: false,
    loadTime: new Date(0),
    saving: false,
    id: null,
    data: {
        isLocked: false,
    },
    updatingPhoto: false,
    refetchKey: new Date().getTime(),
    error: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;

    switch (type) {
        case FETCH_START:
            return {
                ...initialState,
                loading: true,
                id: payload.id,
            };
        case FETCH_FAILED:
            return {
                ...initialState,
                loadComplete: true,
                loadTime: payload.loadTime,
                id: payload.id,
                error: error.message,
            };
        case SAVE_START:
            return {
                ...state,
                saving: true,
                id: payload.id,
            };
        case FETCH_SUCCESS:
        case SAVE_SUCCESS:
            return {
                ...initialState,
                loadComplete: true,
                loadTime: payload.loadTime,
                id: payload.data.id,
                data: {
                    ...payload.data,
                },
            };
        case SAVE_FAILED:
            return {
                ...state,
                saving: false,
                loadTime: payload.loadTime,
                id: payload.id,
                error: error.message,
            };
        case SET_IS_DISABLED:
            return {
                ...state,
                data: {
                    ...state.data,
                    isDisabled: action.isDisabled,
                },
            };
        case SET_IS_LOCKED:
            return {
                ...state,
                data: {
                    ...state.data,
                    isLocked: payload.isLocked,
                    lockoutEnd: null,
                },
            };

        case SAVE_PHOTO: {
            const { id } = action.payload;

            if (state.data.id === id) {
                return {
                    ...state,
                    updatingPhoto: true,
                };
            }

            return state;
        }

        case SAVE_PHOTO_SUCCESS: {
            return {
                ...state,
                updatingPhoto: false,
                refetchKey: new Date().getTime(),
            };
        }

        case SAVE_PHOTO_FAILED: {
            return {
                ...state,
                updatingPhoto: false,
                refetchKey: new Date().getTime(),
            };
        }

        default:
            return state;
    }
}

export const newUser = () => {
    return {
        type: NEW_USER,
    };
};

export const saveStart = id => {
    return {
        type: SAVE_START,
        payload: { id },
    };
};

export const saveSuccess = (data, loadTime) => {
    return {
        type: SAVE_SUCCESS,
        payload: { data, loadTime },
    };
};

export const saveFailed = (id, error, loadTime) => {
    return {
        type: SAVE_FAILED,
        payload: { id, loadTime },
        error,
    };
};

export const saveUser = user => {
    return {
        type: SAVE_REQUEST,
        payload: { user },
    };
};

export function cancel(userId) {
    return {
        type: CANCEL,
        payload: { userId },
    };
}

export const fetchUser = id => {
    return {
        type: FETCH_REQUEST,
        payload: { id },
    };
};

const fetchStart = (id = 0) => {
    return {
        type: FETCH_START,
        payload: { id },
    };
};

const fetchSuccess = (data, loadTime) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data, loadTime },
    };
};

const fetchFailed = (id, error, loadTime) => {
    return {
        type: FETCH_FAILED,
        payload: { id, loadTime },
        error,
    };
};

export const setIsDisabled = isDisabled => {
    return {
        type: SET_IS_DISABLED,
        isDisabled: isDisabled,
    };
};

export const setIsLocked = isLocked => {
    return {
        type: SET_IS_LOCKED,
        payload: { isLocked },
    };
};

export const savePhoto = (file) => {
    return {
        type: SAVE_PHOTO,
        payload: {  file },
    };
};

export const savePhotoSuccess = () => {
    return {
        type: SAVE_PHOTO_SUCCESS,
    };
};

export const savePhotoFailed = () => {
    return {
        type: SAVE_PHOTO_FAILED,
    };
};

function* fetchUserDictionariesSaga() {
    yield put(fetchDictionaries(allUserDictionaries));
}

export const saveUserSaga = function*(action) {
    const { user } = action.payload;
    const id = user.id;
    const state = yield select();
    const currentLocation = state.router.location;

    yield put(saveStart(id));

    const isLocationChanged = state => currentLocation !== state.router.location;
    const isUserChanged = state => state.user.id !== id;
    try {
        const response = yield call(
            withPageLoader,
            () => (!id ? createUser(user) : editUser(user)),
            true,
        );

        const savedUser = response.data;
        const state = yield select();

        if (!isUserChanged(state)) {
            yield put(saveSuccess(savedUser, new Date()));
        }

        if (!isLocationChanged(state)) {
            yield put(push(userRoute.buildUrl({ id: savedUser.id }, '?section=personInfo')));
        }
    } catch (error) {
        const reqError = getError(error, getSaveUserError);
        if (!isUserChanged(yield select())) {
            yield put(saveFailed(id, reqError, new Date()));
        }
        yield put(showErrorAlert(reqError.message));
    }
};

const getSaveUserError = (code, payload) => {
    switch (code) {
        case serviceResultCode.UserRightsInvalidReduce:
            return 'Суперадмин не может отобрать у себя права';
        case serviceResultCode.UserIdentityErrors:
            return 'При сохранении пользователя произошла ошибка';
        case serviceResultCode.UserEmailIsInvalid:
            return 'Для создания пользователя необходимо указать Email';
        case serviceResultCode.UserNameIsInvalid:
            return 'Для создания пользователя необходимо указать имя';
        case serviceResultCode.UserEmailAlreadyExists:
            return 'Указанный Email уже используется';
        case serviceResultCode.UserPhoneIsInvalid:
            return 'Для создания пользователя необходимо указать телефон';
        case serviceResultCode.UserOnlyOneRoleForUserAllowed:
            return 'Пользователю можно назначить только одну роль';
        case serviceResultCode.UserOnlyOneAdminRoleForUserAllowed:
            return 'Пользователь может иметь только одну роль "Администратор"';
        case serviceResultCode.UserSetPasswordErrors:
            return payload;
        case serviceResultCode.UserMergeInvalidRoleError:
            return payload;
        case serviceResultCode.UserAsLeaderHasNoPersonProfile:
            return payload;
        default:
            return 'При сохранении пользователя произошла ошибка';
    }
};

function* newUserSaga() {
    yield call(fetchUserDictionariesSaga);
}

export const fetchUserSaga = function*(action) {
    yield call(fetchUserDictionariesSaga);

    const { id } = action.payload;
    yield put(fetchStart(id));
    try {
        const response = yield call(withPageLoader, () => getUser(id));

        const userData = response.data;
        userData.isLocked = isLockedOut(userData.lockoutEnd, userData.isDisabled);
        yield put(fetchSuccess(userData, new Date()));
    } catch (error) {
        if (error.response && error.response.status === 404) {
            yield put(push(notFound.buildUrl()));
        } else {
            const reqError = new RequestError(error, 'При загрузке пользователя произошла ошибка');
            yield all([
                put(fetchFailed(id, reqError, new Date())),
                put(showErrorAlert(reqError.message)),
            ]);
        }
    }
};

function* cancelSaga(action) {
    const { userId } = action.payload;
    if (userId) {
        yield put(push(userRoute.buildUrl({ id: userId }, '?section=personInfo')));
    } else {
        yield put(push(usersRoute.buildUrl()));
    }
}

const savePhotoSaga = function*(action) {
    const { file } = action.payload;
    try {
        yield call(uploadUserPhoto, file);
        yield put(savePhotoSuccess());
    } catch (error) {
        if (error.response.status === 422) {
            const fileErrorCodes = error.response.data;
            if (fileErrorCodes && fileErrorCodes.formatUnsupported) {
                yield put(
                    showErrorAlert(
                        'Неподдерживаемый формат изображения. Поддерживаются форматы JPEG и PNG',
                    ),
                );
            } else if (fileErrorCodes && fileErrorCodes.tooLarge) {
                let maxSize = (fileErrorCodes.tooLarge.maxSize / (1024 * 1024)).toFixed(2);
                yield put(showErrorAlert(`Фото превышает лимит в ${maxSize} Мб`));
            } else {
                yield put(showErrorAlert('При загрузке фото произошла ошибка'));
            }
        } else {
            yield put(showErrorAlert('При загрузке фото произошла ошибка'));
        }

        yield put(savePhotoFailed());
    }
};

export const saga = function*() {
    yield all([
        takeLatest(FETCH_REQUEST, fetchUserSaga),
        takeLeading(NEW_USER, newUserSaga),
        takeEvery(SAVE_REQUEST, saveUserSaga),
        takeEvery(SAVE_PHOTO, savePhotoSaga),
        takeEvery(CANCEL, cancelSaga),
    ]);
};

const isUserActual = user => {
    return (
        user.loadComplete &&
        !user.error &&
        (Date.now() - user.loadTime) / 1000 < cacheTimeoutInSeconds
    );
};

export const newUserSelector = state => {
    if (!isDictionariesLoaded(state.dictionary, allUserDictionaries)) {
        return { loadComplete: false };
    }

    return {
        loadComplete: true,
        isActual: true,
        id: 0,
        lastName: '',
        firstName: '',
        middleName: '',
        roles: [],
        groupId: null,
        email: '',
        phone: '',
        supportStatus: 'Default',
        error: state.user.error,
    };
};

export const userFullSelector = (state, id) => {
    if (!isDictionariesLoaded(state.dictionary, allUserDictionaries)) {
        return { loadComplete: false };
    }

    const user = state.user;
    if (!user.loadComplete) {
        return { loadComplete: false };
    }

    const data = user.data;
    return {
        loadComplete: true,
        isActual: id === data.id && isUserActual(data),
        id: user.id,
        saveInProgress: data.saving,
        lastName: data.lastName,
        firstName: data.firstName,
        middleName: data.middleName,
        roles: data.roles,
        groupId: data.groupId,
        email: data.email,
        phone: data.phone,
        isDisabled: data.isDisabled,
        phoneNumberConfirmed: data.phoneNumberConfirmed,
        passwordGenerated: data.passwordGenerated,
        lockoutEnd: data.lockoutEnd,
        isLocked: data.isLocked,
        supportStatus: data.supportStatus,
        error: user.error,
        personId: data.personId,
    };
};
