import uuid from 'uuid/v4';
import { FIELDS_TYPE } from './SearchFilters/constants';

export const setSearchFilter = (
    category,
    field,
    operation,
    value,
    label,
    joinOperation,
    filterId,
    section,
    excludeFromSearch = false,
    parent = null,
    group = null,
    type = null,
    shortLabel = null,
    isDefault = false,
) => {
    const id = uuid();

    const result = {
        category: category,
        field: field,
        operation: operation,
        label: label,
        joinOperation: joinOperation,
        filterId: filterId,
        id,
        section,
        parent,
        group: group || id,
        excludeFromSearch: excludeFromSearch,
        type: type || FIELDS_TYPE.input,
        shortLabel: shortLabel || label,
        isDefault,
    };



    return Object.assign(
        result,
        type === FIELDS_TYPE.multiSelect
            ? { values: value, value: '' }
            : { value: type === FIELDS_TYPE.input ? value?.trim() : value },
    );
};
