import React from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { DataCard } from "components/common/DataCard";
import EditFilesBlock from './Edit/FilesBlock';
import { getFileSize, getFileExtension } from 'utils';

const FilesBlock = props => {
    function renderView() {
        return (
            <DataCard
                shadow
                title="Файлы"
                className="kr_profile-anketa-view"
                onChange={props.canEditPerson ? () => props.setEditBlock('FilesBlock') : null}
            >
                <div className="kr_like-table kr_like-table_files">
                    {props.files.filter((file) => file.deleted !== true).map((file, index) =>
                        <div className="kr_tr" key={index}>
                            <div className="kr_td">
                                <a href={props.getFileUrl(file.id)}>
                                    <span className="kr_files_pdf">{getFileExtension(file.name)}</span>
                                    {file.name}
                                </a>
                            </div>
                            <div className="kr_td">{getFileSize(file.size)}</div>
                        </div>
                    )}
                    {(!props.files || !props.files.size) ? props.renderEmptyBlock() : null}
                </div>
            </DataCard>
        );
    }

    function renderEdit() {
        return (
            <EditFilesBlock {...props} setEditBlock={props.setEditBlock} />
        );
    }

    return props.isEditBlock ? renderEdit() : renderView();
}

export default ToggleOpen(FilesBlock);