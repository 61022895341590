import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import { user as userRoute, userNew } from 'routes';
import { fetchUsers, changeCriteria, usersSelector } from 'ducks/Users';
import { showWarningAlert, showErrorAlert } from 'ducks/Alert';
import { selectDictionaries } from 'ducks/Dictionary';
import { allUserDictionaries } from 'ducks/User';
import { GROUPS_DICTIONARY_NAME, ROLES_DICTIONARY_NAME } from 'constants.js';
import ListTemplate from 'components/uikit/ListTemplate';
import DataGrid from 'components/common/DataGrid';
import Button from 'components/uikit/Button';
import Field from 'components/uikit/Field';
import Page from 'components/common/Page';
import Input from 'components/uikit/Input';
import Label from 'components/uikit/Label';
import Select from 'react-select';
import './Users.scss';
import { faBan, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isLockedOut } from '../../../ducks/Users';
import { isITAdmin, isSupportLine1, isSupportLine2 } from 'rightsController';
import UserSessionCard from './UserSessionCard';
import ChevronButton from 'components/uikit/ChevronButton';
import { getUserSession, dropUserSession, dropDeviceSession } from 'api';
import { getError, serviceResultCode } from 'serviceErrors';
import { mainMenuIds } from '../User/constants';

const visibilityOptions = {
    allUsers: { value: 'allUsers', label: 'Все пользователи' },
    onlyBlocked: { value: 'onlyBlocked', label: 'Только заблокированные пользователи' },
    onlyActive: { value: 'onlyActive', label: 'Только активные пользователи' },
    onlyOnline: { value: 'onlyOnline', label: 'Только онлайн пользователи' },
};

const visibilityOptionValues = Object.values(visibilityOptions);

const renderStatus = ({ isDisabled, isActive, lockoutEnd }) => {
    if (isDisabled) {
        return <FontAwesomeIcon icon={faBan} color="red" />;
    }

    if (isActive) {
        return <FontAwesomeIcon className="OnlineIndicator" icon={faCircle} />;
    }

    if (isLockedOut(lockoutEnd, isDisabled)) {
        return <FontAwesomeIcon icon={faBan} color="red" />;
    }

    return null;
};

const writeTime = ({ lockoutEnd, isDisabled }) => {
    if (isLockedOut(lockoutEnd, isDisabled)) {
        const date = new Date(lockoutEnd);
        return date.getFullYear() < 3000 ? (
            <div className="BanTimeText">
                Блок до {date.toLocaleDateString() + ' ' + date.toLocaleTimeString()}
            </div>
        ) : null;
    }

    return null;
};

const initialState = {
    filter: {
        lastName: '',
        firstName: '',
        patronymic: '',
        searchTerm: '',
        roles: [],
        groups: [],
        visibility: visibilityOptions.allUsers,
    },
    sorting: { fullName: 'asc' },
    paging: { pageNum: 1, pageSize: 20 },
    selectedUserId: null,
    selectedUserSession: null,
    expanded: {},
};

export class Users extends Component {
    state = initialState;

    isSupport = isSupportLine1(this.props.currentUser) || isSupportLine2(this.props.currentUser);

    buildColumns = () => [
        {
            Header: '',
            width: 20,
        },
        {
            Header: '',
            accessor: 'status',
            maxWidth: 50,
            Cell: ({ original }) => renderStatus({ ...original }),
            sortable: false,
            resizable: false,
        },
        {
            Header: 'ФИО',
            accessor: 'fullName',
            resizable: false,
            Cell: ({ original }) => (
                <div>
                    <NavLink
                        to={userRoute.buildUrl(
                            { id: original.id },
                            `?section=${mainMenuIds.personInfo}`,
                        )}
                    >
                        {original.fullName}
                    </NavLink>
                    <div>{writeTime({ ...original })}</div>
                </div>
            ),
        },
        {
            Header: 'Роли',
            accessor: 'roles',
            sortable: false,
            resizable: false,
        },
        {
            Header: 'Группа',
            accessor: 'group.title',
            resizable: false,
        },
        {
            Header: 'E-mail',
            accessor: 'email',
            resizable: false,
        },
        {
            Header: 'Телефон',
            accessor: 'phoneNumber',
            resizable: false,
        },
        {
            expander: true,
            width: 50,
            Expander: ({ isExpanded, original }) => {
                return original.isSessionActive && <ChevronButton isUp={isExpanded} />;
            },
        },
    ];

    handleStateChange = (blockStateKey, blockState) => {
        this.setState(state => {
            return {
                [blockStateKey]: {
                    ...state[blockStateKey],
                    ...blockState,
                },
            };
        });
    };

    componentDidMount() {
        this.performUsersFetching();
    }

    componentDidUpdate(prevProps) {
        const { paging } = this.state;
        const { data } = this.props;

        if (data !== prevProps.data) {
            if (data && data.meta.pageCount < paging.pageNum && data.meta.pageCount !== 0) {
                this.setState({ paging: { ...paging, pageNum: data.meta.pageCount } }, () => {
                    this.performUsersFetching();
                });
            }
        }
    }

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, () =>
            this.performUsersFetching(),
        );
    };

    newUser = () => {
        this.props.push(userNew.buildUrl({}));
    };

    onSortedChange = sorted => {
        this.props.changeCriteria('sorted', sorted);

        const criteria = {
            ...this.state,
            sorting: {
                [sorted[0].id]: sorted[0].desc ? 'Desc' : 'Asc',
            },
        };

        this.setState(criteria, () => this.performUsersFetching());
    };

    handleRowExpanded = (newExpanded, index) => {
        const user = this.props.data && this.props.data.payload[index];

        this.setState(
            {
                expanded: { [index]: newExpanded[index] },
            },
            () => this.fetchSessions(user.id),
        );
    };

    onDropDevice = (userId, deviceId) => {
        this.callEffect(async () => {
            await dropDeviceSession(userId, deviceId);
            await this.fetchSessions(userId);
        });
    };

    onDropSession = userId => {
        this.callEffect(async () => {
            await dropUserSession(userId);
            await this.fetchSessions(userId);
        });
    };

    fetchSessions = userId => {
        this.callEffect(async () => {
            const data = await getUserSession(userId);
            this.setState({
                selectedUserSession: data.data,
                selectedUserId: userId,
            });
            if (!data.data) {
                this.performUsersFetching();
            }
        });
    };

    addDetailCard = () => {
        if (this.state.selectedUserSession) {
            return (
                <UserSessionCard
                    userSession={this.state.selectedUserSession}
                    onDropDevice={this.onDropDevice}
                    onDropSession={this.onDropSession}
                    isSupport={this.isSupport}
                />
            );
        }
    };

    onClearFilters = e => {
        e.preventDefault();
        this.clearFilters();
    };

    clearFilters = () => {
        this.setState(initialState, () => this.performUsersFetching());
    };

    fillSelect = dictionary => {
        if (dictionary) {
            return dictionary.map(x => {
                return { value: x.id, label: x.name };
            });
        } else {
            return [];
        }
    };

    onPageChange = pageIndex => {
        const { paging } = this.state;
        this.setState(
            {
                paging: { ...paging, pageNum: pageIndex + 1 },
                expanded: {},
            },
            () => this.performUsersFetching(),
        );
    };

    onSubmit = e => {
        e.preventDefault();
        this.performUsersFetching();
    };

    filterGrid = e => {
        if (e.key === 'Enter') {
            this.performUsersFetching();
        }
    };

    callEffect = async callback => {
        try {
            await callback();
        } catch (error) {
            const reqError = getError(error, this.getDropSessionError);
            this.props.showErrorAlert(reqError.message);
        }
    };

    getDropSessionError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `${payload}`;
            case serviceResultCode.UserSessionDropError:
                return `${payload}`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    performUsersFetching = () => {
        const { filter, paging, sorting } = this.state;
        const groups = filter.groups ? filter.groups.map(x => x.value) : null;
        const roles = filter.roles ? filter.roles.map(x => x.value) : null;
        const visibility = filter.visibility.value;
        const criteria = { filter: { ...filter, groups, roles, visibility }, paging, sorting };
        this.props.fetchUsers(criteria);
    };

    headBtn = () => {
        return isITAdmin(this.props.currentUser)
            ? { onClick: this.newUser, value: 'Создать пользователя', addLink: true, size: 'xs' }
            : null;
    };

    filters = () => {
        const groups = this.props.dictionaries[GROUPS_DICTIONARY_NAME];
        const roles = this.props.dictionaries[ROLES_DICTIONARY_NAME];
        const customSelectStyles = {
            control: base => ({
                ...base,
                padding: 0,
                backgroundColor: '#f9f9ff',
                borderColor: '#d2d5ea',
            }),
            input: base => ({
                ...base,
                height: 33,
                padding: 0,
                margin: 0,
            }),
        };

        return (
            <div className="Users__Filter">
                <Field>
                    <Label>Поиск</Label>
                    <Input
                        placeholder="Введите имя/фамилию или Email"
                        value={this.state.filter.searchTerm}
                        maxLength="100"
                        onChange={e =>
                            this.handleStateChange('filter', {
                                searchTerm: e.target.value,
                            })
                        }
                        onKeyDown={this.filterGrid}
                    />
                </Field>

                <Field>
                    <Label>Роли</Label>
                    <Select
                        value={this.state.filter.roles}
                        inputId="selectedRole"
                        placeholder={<div>Выберите роли</div>}
                        options={this.fillSelect(roles)}
                        onChange={item => this.handleStateChange('filter', { roles: item })}
                        isMulti
                        styles={customSelectStyles}
                    />
                </Field>

                <Field>
                    <Label>Группы</Label>
                    <Select
                        value={this.state.filter.groups}
                        inputId="selectedGroup"
                        placeholder={<div>Выберите группы</div>}
                        options={this.fillSelect(groups)}
                        onChange={item => this.handleStateChange('filter', { groups: item })}
                        isMulti
                        styles={customSelectStyles}
                    />
                </Field>

                <Field>
                    <Label>Видимость</Label>
                    <Select
                        value={this.state.filter.visibility}
                        inputId="selectedVisibility"
                        options={visibilityOptionValues}
                        onChange={item => this.handleStateChange('filter', { visibility: item })}
                        styles={customSelectStyles}
                    />
                </Field>
                <div className="Users__FilterActions">
                    <Button size="xs" onClick={this.onSubmit}>Применить</Button>
                    <Button size="xs" onClick={this.onClearFilters} closeLink>
                        Очистить фильтр
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        if (!this.props.loadComplete) {
            return null;
        }

        const columns = this.buildColumns();
        const { pageCount } = this.props.data.meta;
        const { paging, expanded } = this.state;

        return (
            <Page>
                <div className="Users">
                    <ListTemplate title={' '} headBtn={this.headBtn()}>
                        <div className="Users__Grid">
                            {this.filters()}
                            <DataGrid
                                className="Users__Table"
                                data={this.props.data.payload}
                                foundCount={this.props.data.meta.foundCount}
                                columns={ columns }
                                loading={!this.props.loadComplete}
                                showPagination={true}
                                showPageSizeOptions={false}
                                sorted={this.props.criteria.sorted}
                                onSortedChange={this.onSortedChange}
                                pages={pageCount}
                                page={paging.pageNum}
                                onPageChange={this.onPageChange}
                                manual
                                selectedRow={this.state.selectedUserSession}
                                expanded={expanded}
                                onExpandedChange={this.handleRowExpanded}
                                SubComponent={row => this.addDetailCard(row)}
                                pageSize={paging.pageSize}
                                changePageAmount={this.changePageAmount}
                            />
                        </div>
                    </ListTemplate>
                </div>
            </Page>
        );
    }
}

export default connect(
    state => {
        const users = usersSelector(state);
        const dictionaries = selectDictionaries(state.dictionary, allUserDictionaries);
        return {
            ...users,
            dictionaries,
            currentUser: state.auth.user,
        };
    },
    { showWarningAlert, showErrorAlert, fetchUsers, changeCriteria, push },
)(Users);
