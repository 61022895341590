import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Label from 'components/uikit/Label';
import Avatar from 'components/Lk/Common/Avatar';
import classnames from 'classnames';
import ModalSummaryCard from 'components/Lk/Common/ModalPersonSummaryCard';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import './CardItemPerson.scss';

const CardItemPerson = props => {
    const {
        id,
        children,
        checkbox,
        checked = false,
        onCheckClick,
        clickable,
        onCardClick,
        actionIcon,
        onActionIconClick = () => {},
        person = {},
        profileRoute,
        profileUrl,
        buttons,
        dataTest,
    } = props;

    const [isOpenModalSummary, setIsOpenModalSummary] = useState(false);
    const closeSummaryCard = () => setIsOpenModalSummary(false);

    const isOnClick = () => {};
    const onCheck = e => {
        if (onCheckClick) {
            e.stopPropagation();
            onCheckClick(e, !checked);
        }
    };

    const handleCardClick = e => {
        onCardClick ? onCardClick(e) : handleSummaryCardOpen();
    };

    const handleSummaryCardOpen = () => {
        setIsOpenModalSummary(true);
    };

    const Content = children ? (
        children
    ) : (
        <span className="CardItemPerson__Content-NoData" data-test="CardItemPerson-NoData">Нет данных</span>
    );

    const ImgClassName = classnames('CardItemPerson__Img', {
        'CardItemPerson__Img--isCheckbox': checkbox,
    });

    return (
        <>
            <div
                className={classnames('CardItemPerson', { 'CardItemPerson--clickable': clickable })}
                data-person-id={person.id}
                onClick={clickable ? handleCardClick : null}
            >
                <div className="CardItemPerson__Info">
                    {actionIcon && (
                        <div
                            className={`CardItemPersonActionIcon CardItemPersonActionIcon__${actionIcon}`}
                            onClick={onActionIconClick}
                        ></div>
                    )}
                    <div className={ImgClassName}>
                        {checkbox && (
                            <>
                                <input
                                    id={`Name__Id${id}`}
                                    type="checkbox"
                                    readOnly
                                    checked={checked}
                                    hidden
                                    data-person-id={person.id}
                                />
                                <Label
                                    onClick={onCheck}
                                    className="CardItemPerson__Img-Label"
                                    data-test={dataTest}
                                ></Label>
                            </>
                        )}
                        <Avatar person={person} size="sm" isUser={!!person.userId} />
                    </div>
                    <div className="CardItemPerson__Content" onClick={isOnClick}>
                        {Content}
                    </div>
                </div>
                {buttons && (
                    <div className="CardItemPerson__Buttons">
                        {buttons.map(item => (
                            <IconButton key={item.id} {...item} />
                        ))}
                    </div>
                )}
            </div>
            <ModalSummaryCard
                isOpen={isOpenModalSummary}
                onClose={closeSummaryCard}
                person={person}
                profileRoute={profileRoute}
                profileUrl={profileUrl}
                buttons={buttons}
            />
        </>
    );
};

CardItemPerson.propTypes = {
    checkbox: PropTypes.bool,
    children: PropTypes.any,
    onClick: PropTypes.func,
};

export default CardItemPerson;
