import React from 'react';

const InstructionNotFound = () => {
    return (
        <div className="InstructionView__Instruction">
            Инструкция не найдена.
        </div>
    );
};

export default InstructionNotFound;
