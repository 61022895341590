import { appName, PASSWORD_LENGTH_MINIMUM } from '../constants';
import { getConfig } from '../api';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { showErrorAlert } from './Alert';
import RequestError from '../RequestError';
import { isModerator } from 'rightsController';

const moduleName = 'config';
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;

const initialState = {
    loading: false,
    loadComplete: false,
    data: {
        features: {},
    },
    error: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_START:
            return {
                ...state,
                loading: true,
            };

        case FETCH_SUCCESS:
            return {
                ...state,
                loadComplete: true,
                data: payload.data,
            };

        case FETCH_FAILED:
            return {
                ...state,
                loadComplete: true,
                error: error.message,
            };

        default:
            return state;
    }
}

export const fetchConfig = () => {
    return {
        type: FETCH_REQUEST,
    };
};

export const fetchStart = () => {
    return {
        type: FETCH_START,
    };
};

export const fetchSuccess = (data) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data },
    };
};

export const fetchFailed = (error) => {
    return {
        type: FETCH_FAILED,
        error,
    };
};

export const allActions = {
    fetchConfig,
    fetchStart,
    fetchSuccess,
    fetchFailed,
};

const fetchConfigSaga = function* () {
    const { config } = yield select();

    if (config.loading || (config.loadComplete && !config.error)) {
        return;
    }

    yield put(fetchStart());

    try {
        const response = yield call(getConfig);
        yield put(fetchSuccess(response.data));
    } catch (error) {
        const reqError = new RequestError(error, `При загрузке настроек произошла ошибка`);
        yield all([put(fetchFailed(reqError)), put(showErrorAlert(reqError.message))]);
    }
};

export const saga = function* () {
    yield takeLatest(FETCH_REQUEST, fetchConfigSaga);
};

export const isAllowedResourceProviderRegion = (features, user) => {
    if (features && !features.groupsAllowedToResourceProviders) {
        return true;
    }

    return (
        features &&
        features.groupsAllowedToResourceProviders &&
        Array.isArray(features.groupsAllowedToResourceProviders) &&
        features.groupsAllowedToResourceProviders.indexOf(user.groupId) !== -1
    );
};

export const isResourceProvidersEnabled = (state) => {
    const {
        config,
        auth: { user },
    } = state;

    const features = config && config.data && config.data.features;

    if (!features) {
        return false;
    }

    const { resourceProviders: isEnabledResourceProviders } = features;
    if (!isEnabledResourceProviders) {
        return false;
    }

    return (
        isEnabledResourceProviders &&
        isAllowedResourceProviderRegion(features, user) &&
        isModerator(user)
    );
};

export const getMinimalPasswordLength = (state) => {
    const { config } = state;

    const minimalPasswordLength =
        config && config.data && config.data.features && config.data.features.minimalPasswordLength;

    if (!!!minimalPasswordLength) {
        return PASSWORD_LENGTH_MINIMUM;
    }

    return minimalPasswordLength;
};
