import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import classnames from 'classnames';
import { mainCardMenuItems } from './constants';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import { Link } from 'react-router-dom';

import './MainCard.scss';

const MainCardMenu = props => {
    const { push, menuItems, linkParams, main, onClick, activeId, linkMenu = true } = props;
    const handleMenuLink = e => {
        const menuItemData = e.currentTarget.dataset;
        push(menuItemData.url, { ...linkParams });
    };

    const handleMenuClick = x => () => {
        onClick && onClick(x);
    };

    const cardMenuItems = menuItems ? menuItems : mainCardMenuItems;

    return (
        <div className={classnames('LKMainCardMenu', { 'LKMainCardMenu--ismain': main })}>
            {cardMenuItems.map(x => {
                const active = onClick ? activeId === x.id : props.location.startsWith(x.link);
                const pref = active ? 'Active' : '';

                return linkMenu ? (
                    <Link
                        key={x.id}
                        className={classnames('LKMainCardMenuItem', {
                            'LKMainCardMenuItem--selected': active,
                        })}
                        data-menuid={x.id}
                        data-url={x.link}
                        onClick={handleMenuClick(x)}
                        to={{ pathname: x.link, state: { ...linkParams } }}
                    >
                        <Icons name={x.icon + pref} />
                    </Link>
                ) : (
                    <div
                        key={x.id}
                        className={classnames('LKMainCardMenuItem', {
                            'LKMainCardMenuItem--selected': active,
                        })}
                        data-menuid={x.id}
                        data-url={x.link}
                        onClick={onClick ? handleMenuClick(x) : handleMenuLink}
                    >
                        <Icons name={x.icon + pref} />
                    </div>
                );
            })}
        </div>
    );
};

const mapStateToProps = state => ({
    location: state.router.location.pathname,
});
const actions = { push };

export default connect(
    mapStateToProps,
    actions,
)(MainCardMenu);
