import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { instructionsRoute } from 'routes';
import Tree from 'components/common/Tree';
import Loader from 'components/common/Loader';

class CategoryList extends Component {
    render() {
        return (
            <div className="CategoryList">
                <div
                    className="CategoryList__Header"
                    onClick={() => this.props.push(instructionsRoute.url)}
                >
                    Помощь
                </div>
                {this.props.loading ? (
                    <Loader absolute />
                ) : (
                    <Tree
                        data={this.props.treeData}
                        onItemClick={this.props.onTreeClick}
                        selectedId={this.props.selectedId}
                    />
                )}
            </div>
        );
    }
}

export default connect(
    null,
    { push },
)(CategoryList);
