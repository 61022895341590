import React from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import SearchMenuJSON from './SearchMenuJSON';
import RenderDataCard from './renderDataCard';

const EducationBlock = React.memo(props => {
    return (
        <RenderDataCard obj={SearchMenuJSON.EducationBlock} {...props} />
    );
});

export default ToggleOpen(EducationBlock);