import React from 'react';
import classnames from 'classnames';
import { isNullOrWhitespace } from 'utils';
import ReactTooltip from "react-tooltip";
import { EVALUATIONTEST_CRITERIONRESULT_STATUS } from 'constants.js';

const SidesChart = (props) => {
    const { withHeader, personId, withValues, description, result, title, criteria, isTestActive } = props;
    
    const renderChart = () => {
        return (
            <div className="Sides-Chart">
                {!isNullOrWhitespace(description) && <div className="Sides-Chart__Chart-Descr">{description}</div>}
                {withHeader && <div className="Sides-Chart__Chart-Name"><a href={`/person/${personId}/evaluate#${result.id}`}>{title}</a></div>}
                {renderTestColumnsTitles()}
                {
                    result.criteria.map(x => {

                        const isCriteriaActive = x.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Relevant;

                        const title = Object.entries(criteria).find(line => line[0] === x.evaluationTestCriterionId)[1].title;
                        const description = Object.entries(criteria).find(line => line[0] === x.evaluationTestCriterionId)[1].description;
                        const extraDescription = Object.entries(criteria).find(line => line[0] === x.evaluationTestCriterionId)[1].extraDescription;
                        
                        if (title.match("[-––]")) {
                            const splitedValue = title.split(/[-––]/);
                            const left = splitedValue[0].trim();
                            const right = splitedValue[1].trim();
    
                            return (
                                <div className={classnames("Chart-Line", {"Chart-Line--notActive": !isTestActive || !isCriteriaActive})} key={x.id}>
                                    {renderLine(Math.ceil(x.normalizedValue * 10), "", left, right, x.id, description, extraDescription)}
                                </div>
                            )
                        } else {return null}
                    })}
            </div>
        );
    }
    
    const renderTestColumnsTitles = () => {
        return(
            <div className="Chart-Line" key={"testTitles"}>
    
                <div className="Chart-Line__Data-Block">
                    <div className="Test-Colunmn-Title-Wrapper">
                        <div className="Test-Colunmn-Title Test-Colunmn-Title__Border-Right Test-Colunmn-Title__Bold">Сильная</div>
                        <div className="Test-Colunmn-Title Test-Colunmn-Title__Border-Left Test-Colunmn-Title__Normal">Умеренная</div>
                        <div className="Test-Colunmn-Title Test-Colunmn-Title__Light">Неясная</div>
                        <div className="Test-Colunmn-Title Test-Colunmn-Title__Border-Right Test-Colunmn-Title__Right Test-Colunmn-Title__Normal">Умеренная</div>
                        <div className="Test-Colunmn-Title Test-Colunmn-Title__Border-Left Test-Colunmn-Title__Right Test-Colunmn-Title__Bold">Сильная</div>
                    </div> 
                </div>
    
            </div>
        );
    }
    
    const renderLine = (amount = 5, name, leftName, rightName, id, description, extraDescription) => {
        let line = [];

        line.push(renderSideText(leftName, "left", description));
        line.push(
            <div key={id} className="Chart-Line__Data-Block">
                <div className="Flex-Wrapper">{renderSide(amount, id)}</div>
            </div>
        );
        line.push(renderSideText(rightName, "right", extraDescription));
        return line;
    }
    
    const renderSideText = (text, side, tooltipText) => {
        return (
            <div
                key={`${text}_${side}`}
                className={classnames("Chart-Line__Side-Text", {
                    "Chart-Line__Side-Text--left": side === "left",
                    "Chart-Line__Side-Text--right": side === "right"
                })}
                data-tip
                data-for={`${text}_${side}_${tooltipText}`}
            >
                {text}
                <ReactTooltip
                    id={`${text}_${side}_${tooltipText}`}
                    place={'top'}
                    type={'light'}
                    effect={'solid'}
                    border={true}
                    data-offset="{'top': 0, 'left': 0}"
                >
                    <p className="SideChart__Tooltip">{tooltipText}</p>
                </ReactTooltip>
            </div>
        );
    }
    
    const renderSide = (amount, id) => {
        let line = [];
        if (amount < 6) {
            const emptyCubes = amount - 1;
            line.push(renderLeft(emptyCubes, amount, id));
            line.push(renderRight(5, id));
        } else {
            line.push(renderLeft(5, id));
            const emptyCubes = 10 - amount;
            line.push(renderRight(emptyCubes, amount, id));
        }
        return line;
    }
    
    
    
    
    const renderLeft = (emptyCubes, amount, id) => {
        let side = [];
        let sideId = -1;
        for (let i = 0; i < 5; i++) {
            if (sideId === -1 && i >= emptyCubes) {
                sideId = i;
                side.push(renderCube(i >= emptyCubes ? true : false, i + 1, amount, "left", id));
            } else {
                side.push(renderCube(i >= emptyCubes ? true : false, i + 1, amount,false, id));
            } 
        }
    
        return side;
    }
    
    const renderRight = (emptyCubes, amount, id) => {
        let side = [];
        let sideId = -1;
        let j = 5
        for (let i = 5; i > 0; i--) {
            if (sideId === -1 && i === emptyCubes + 1) {
                sideId = i;
                side.push(renderCube(i > emptyCubes ? true : false, j + 1, amount, "right", id));
            } else {
                side.push(renderCube(i > emptyCubes ? true : false, j + 1, amount, false, id));
            }
            
            j++;
        }
        return side;
    }
    
    const renderCube = (filled = false, wrapperId, amount, showAmount = false, id) => {
        return (
            <div key={`${id}_${wrapperId}`} className={classnames("Chart-Cube-Wrapper", [`Chart-Cube-Wrapper-${wrapperId}`] )}>
                {filled && <div className={classnames("Chart-Cube", { [`Chart-Cube--round-${showAmount}`]: showAmount })}>
                    {withValues && showAmount && <div className="Side-Amount">{amount}</div>}
                </div>}
            </div>
        )   
    }

    return renderChart();
}

export default SidesChart;