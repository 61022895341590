import React, { Component } from 'react';

class Teams extends Component {


    render() {

        return (
            <div className="PersonSelectorSection__Item">
                Teams
            </div>
        );
    }
}

export default Teams;