import { push, replace } from 'connected-react-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { photoSizes, getPersonWishlists } from 'api.js';
import { showErrorAlert } from 'ducks/Alert';
import { loadAttributes } from 'ducks/Attributes';
import {
    fetchPerson,
    personFullSelector,
    savePhoto,
    togglePersonInWishlist,
    changeData,
} from 'ducks/Person';
import Loader from 'components/common/Loader';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import classnames from 'classnames';
import './ProfileCard.scss';
import ProfileInfo from './ProfileInfo';
import Progress from './Progress';
import { MENU_PROFILE, MENU_PROGRESS, MENU_EVALUATION, MENU_TEAM, MENU_NOTES } from './constants';
import ProfileEvaluate from './ProfileEvaluate';
import Teams from 'components/Lk/Shared/Teams/Teams';
import LeaderTeams from 'components/Lk/Shared/Teams/LeaderTeams';
import { Element, scroller } from 'react-scroll';
import Logo from './Logo';
import Menu from './Menu';
import LoadingUrlBlocker from 'components/Lk/Common/LoadingUrlBlocker';
import { ROLE_LEADER } from 'constants.js';
import ExtraInfo from './ExtraInfo';
import Sticky from 'react-sticky-state';
import './Sticky.scss';
import { useLastLocation } from 'react-router-last-location';
import Favorite from 'components/common/Favorite';
import PhotoEditBlock from './PhotoEditBlock';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import { setWishlistPersonsUpdated } from 'ducks/Wishlists';
import ProfileComments from 'components/Lk/Containers/PersonComments/ProfileComments';

const ProfileCard = props => {
    const scrollClass = {
        down: 'sticky-scroll-down',
        up: 'sticky-scroll-up',
    };

    const {
        activeCard,
        setActiveCard,
        match: {
            url,
            params: { id },
        },
        person,
        fetchPerson,
        loadAttributes,
        loading,
        location,
        user,
        push,
        replace,
        saving,
        setWishlistPersonsUpdated,
        changeData,
        pid
    } = props;

    const scrollParentRef = useRef(null);

    const [menuSelect, setMenu] = useState();
    const [startLink, setStartLink] = useState(location.pathname);
    const [isWrap, setWrap] = useState(false);
    const [visibleWrapSection, setVisibleWrapSection] = useState(null);
    const [activeMenu, setProfileMenu] = useState(null);
    const [isEdit, setEditState] = useState(false);
    const [isLogoWrapped, setLogoWrapped] = useState(false);
    const [cancelSave, setCancelSave] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [isSecondStep, setIsSecondStep] = useState(false);
    const [heightLogo, setHeightLogo] = useState(0);
    const [hiddenExtraInfo, setHiddenExtraInfo] = useState(false);
    const [showEditPhoto, setShowEditPhoto] = useState(false);
    const [isFavoriteChange, setFavoriteChange] = useState(false);
    const [isManageFavoritesOpen, setManageFavoritesOpen] = useState(false);
    const [personWishlists, setPersonWishlists] = useState(null);

    const container = document.getElementById('ProfileCardContainer');

    const setActiveMenu = menu => {
        const searchParams = queryString.parse(props.location.search);
        replace({ pathname: url, search: queryString.stringify({ ...searchParams, menu }) });
    };

    const searchParams = queryString.parse(props.location.search);

    setActiveCard(ACTIVE_DETAIL_CARD);
    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;

    const { personalInfo } = person;

    const scrollToContainer = (scrollToBlock, duration = 500, wrap = false) => {
        const menu = document.getElementById('TopMenu');
        const logo = document.getElementById('Logo');

        const magicBorder = -1;

        isWrap && setWrap(wrap);
        scroller.scrollTo(scrollToBlock, {
            duration,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'ProfileCardContainer',
            offset: !isEdit ? -1 * (logo?.offsetHeight + menu?.offsetHeight + magicBorder) : -100,
        });
    };

    const lastUrl = useLastLocation();

    const logoChange = useCallback(node => {
        if (node !== null) {
            setHeightLogo(node.getBoundingClientRect().height);
        }
    }, []);

    useEffect(() => {
        const tracker = document.getElementById('header-scroll-tracker');
        const options = {
            root: document.getElementById('ProfileCardContainer'),
            rootMargin: '5px',
            threshold: [0, 0.5, 0.9, 1],
        };

        const callback = function(entries) {
            entries.forEach(entry => {
                if (menuSelect === MENU_PROFILE) {
                    if (entry.intersectionRatio < 1) {
                        const visibleLogo = document.getElementById('Logo');
                        visibleLogo && setHeaderHeight(visibleLogo.offsetHeight);
                        setIsSecondStep(true);
                    } else {
                        setHeaderHeight(heightLogo);
                        setIsSecondStep(false);
                    }
                }
            });
        };

        if (Object.keys(searchParams).length === 0) {
            push({
                pathname: location.pathname,
                search: queryString.stringify({ q: '', profileMenuId: MENU_PROFILE }),
            });
        }

        const observer = new IntersectionObserver(callback, options);
        tracker && observer.observe(tracker);

        return () => {
            observer.disconnect();
        };
    }, [menuSelect, searchParams, location.pathname, push, heightLogo]);

    useEffect(() => {
        if (menuSelect !== MENU_PROFILE) {
            const hiddenLogoElement = document.getElementById('HiddenShortLogo');
            hiddenLogoElement && setHeaderHeight(hiddenLogoElement.offsetHeight);
        } else {
            setHiddenExtraInfo(false);
        }
    }, [menuSelect]);

    const loadWishlists = useCallback(async () => {
        const wishlists = await getPersonWishlists(id);
        if (wishlists.data) {
            setPersonWishlists(
                wishlists.data.map(x => {
                    return {
                        id: x.id,
                        title: x.isDefault ? 'Моё избранное' : x.name,
                        isDefault: x.isDefault,
                    };
                }),
            );
            changeData('isFavorite', wishlists.data.some(x => x.isDefault));
        } else {
            setPersonWishlists([]);
        }
        setWishlistPersonsUpdated(false);
    }, [id, setWishlistPersonsUpdated, changeData]);

    useEffect(() => {
        if (!id) {
            return;
        }

        loadWishlists();
    }, [id, loadWishlists]);

    useEffect(() => {
        if (id && props.isWishlistPersonsUpdated) {
            loadWishlists();
        }
    }, [id, loadWishlists, props.isWishlistPersonsUpdated]);

    useEffect(() => {
        if (!id) {
            return;
        }

        const loadPerson = async () => {
            await fetchPerson(id, photoSizes.small, true, false);
            setStartLink(location.pathname);
            const criteria = { paging: { pageNum: 1, pageSize: 50 } };
            await loadAttributes(criteria);
        };

        loadPerson();

        !lastLocation && setLastLocation(lastUrl);

        const searchString = searchParams?.q || '';
        replace({
            pathname: location.pathname,
            search: queryString.stringify({ q: searchString, profileMenuId: MENU_PROFILE }),
        });
        // иначе startLink и lastLocation будут обновляться в цикле
        // eslint-disable-next-line
    }, [id, fetchPerson, loadAttributes, push, location.pathname]);

    useEffect(() => {
        setMenu(searchParams.profileMenuId);
        setLogoWrapped(!!searchParams.profileMenuId);
        setEditState(false);
        setWrap(false);
        return container?.scrollTo(0, 0);
    }, [searchParams.profileMenuId, searchParams.type, container]);

    useEffect(() => {
        setProfileMenu(searchParams.menu || null);
    }, [searchParams.menu]);

    if (!personalInfo) {
        return (
            <div
                className={classnames('DetailsCard', {
                    'DetailsCard--isMobileActive': isMobileActive,
                })}
            >
                <div>
                    <Loader />
                </div>
            </div>
        );
    }

    const setWrapAndExtraInfo = value => {
        setWrap(value);
        setTimeout(() => {
            setHiddenExtraInfo(value);
        }, 100);
    };

    const { id: personId } = personalInfo;

    const onSelectMenu = link => () => {
        const searchParams = queryString.parse(props.location.search);
        const searchString = (searchParams && searchParams.q) || '';
        setLogoWrapped(true);
        setVisibleWrapSection(null);
        props.push({
            pathname: `${startLink}`,
            search: queryString.stringify({ q: searchString, profileMenuId: link }),
        });
    };

    const renderContent = () => {
        const searchParams = queryString.parse(props.location.search);
        switch (searchParams.profileMenuId) {
            case MENU_PROFILE:
                return (
                    <Element id="ProfileInfoContainer">
                        <ProfileInfo
                            person={personalInfo}
                            scrollToContainer={scrollToContainer}
                            activeMenu={activeMenu}
                            setActiveMenu={setActiveMenu}
                            isWrap={isWrap}
                            setWrap={setWrapAndExtraInfo}
                            setHiddenExtraInfo={setHiddenExtraInfo}
                            isEdit={isEdit}
                            setEditState={setEditState}
                            cancelSave={cancelSave}
                            setCancelSave={setCancelSave}
                            setVisibleWrapSection={setVisibleWrapSection}
                            visibleWrapSection={visibleWrapSection}
                            canLeaderEdit={canLeaderEdit}
                        />
                    </Element>
                );
            case MENU_PROGRESS:
                return <Progress personId={personalInfo.id} scrollParentRef={scrollParentRef} />;
            case MENU_EVALUATION:
                return <ProfileEvaluate />;
            case MENU_TEAM:
                return user.personId === personId ? (
                    <LeaderTeams
                        {...props}
                        setActiveCard={setActiveCard}
                        activeCard={activeCard}
                        personId={personId}
                        fromProfile={true}
                    />
                ) : (
                    <Teams
                        {...props}
                        personId={personalInfo.id}
                        setActiveCard={setActiveCard}
                        activeCard={activeCard}
                        fromProfile={true}
                    />
                );
            case MENU_NOTES:
                return <ProfileComments personId={personalInfo.id} />;
            default:
                return <div className="TechnicalWorks">Раздел находится в разработке</div>;
        }
    };

    const canLeaderEdit = () => {
        if (!props.user) {
            return true;
        }
        return (
            props.user.roles.find(role => role.name === ROLE_LEADER) &&
            props.user.personId === person.personalInfo.id
        );
    };

    const logoProps = () => ({
        personalInfo,
        setLogoWrapped,
        setMenu,
        isEdit,
        setEditState,
        setCancelSave,
        container,
        setWrap,
        setVisibleWrapSection,
        canLeaderEdit,
        menuSelect,
        lastLocation,
        editPhoto,
    });

    const header = document.getElementById('TopMenu');
    if (header) {
        header.setAttribute(
            'style',
            `top: ${
                props.saving ? document.getElementById('Logo')?.offsetHeight : headerHeight
            }px !important;`,
        );
    }

    const isFavorite = personWishlists ? personWishlists.some(x => x.isDefault) : false;

    const toggleFavoriteFlag = () => {
        if (person?.personalInfo?.id) {
            const isMulti = personWishlists
                ? personWishlists.filter(x => !x.isDefault).length > 0
                : false;
            isMulti
                ? setFavoriteChange(true)
                : props.togglePersonInWishlist(person?.personalInfo?.id);
        }
    };

    const confirmFavoriteChange = () => {
        closeFavoriteChange();
        setManageFavoritesOpen(true);
    };

    const closeFavoriteChange = () => {
        setFavoriteChange(false);
    };

    const isLongLogo = !isEdit && menuSelect === MENU_PROFILE && !isWrap;
    const isShortLogo = !isLongLogo || props.saving;

    const favoriteBlock = (
        <Favorite
            isFavorite={isFavorite}
            onChangeFavorite={toggleFavoriteFlag}
            tooltip={personWishlists ? personWishlists.slice(0, 3) : []}
        />
    );

    const editPhoto = () => {
        setShowEditPhoto(true);
    };

    if(!pid){
        return null;
    }

    return (
        <>
            <div
                id="ProfileCardContainer"
                className={classnames('DetailsCard', 'LKProfile', {
                    'DetailsCard--isMobileActive': isMobileActive,
                    'LKProfile--overflowscroll': menuSelect === MENU_PROFILE,
                    'DetailsCard--no-overflow':
                        menuSelect === MENU_TEAM && user.personId === personId,
                })}
            >
                <PhotoEditBlock isOpen={showEditPhoto} closeModal={() => setShowEditPhoto(false)} />
                <div
                    className={classnames('', {
                        LKWrappedContainer: menuSelect && menuSelect !== MENU_PROFILE,
                        LKProfileEditWrapper: isEdit,
                        'LKWrappedContainer--overflowhidden':
                            menuSelect && (menuSelect !== MENU_PROFILE && menuSelect !== MENU_PROGRESS),
                    })}
                    ref={scrollParentRef}
                >
                    <div className={classnames({ScrollContainer:menuSelect === MENU_NOTES})}>
                        {!isShortLogo && <div id="header-scroll-tracker"></div>}
                        <div id="HiddenShortLogo" className="LKProfileContainer__HiddenLogo" />
                        <Sticky scrollClass={scrollClass}>
                            <div className="top sticky with-borders" id="Logo" ref={logoChange}>
                                <Logo
                                    {...logoProps()}
                                    isManageFavoritesOpen={isManageFavoritesOpen}
                                    setManageFavoritesOpen={setManageFavoritesOpen}
                                    isSecondStep={isShortLogo || isSecondStep}
                                    personWishlists={personWishlists || []}
                                    favoriteBlock={favoriteBlock}
                                />
                            </div>
                        </Sticky>
                        {isLongLogo && !props.saving && !hiddenExtraInfo && (
                            <div id="ExtraInfo">
                                <ExtraInfo person={person} />
                            </div>
                        )}
                        {!isEdit && (
                            <Sticky scrollClass={scrollClass}>
                                <div className="top sticky" id="TopMenu">
                                    <Menu
                                        isOnTop={isLogoWrapped}
                                        menuSelect={menuSelect}
                                        onSelectMenu={onSelectMenu}
                                    />
                                </div>
                            </Sticky>
                        )}
                        {menuSelect && renderContent()}
                    </div>
                </div>
            </div>
            <LoadingUrlBlocker
                isLoading={saving}
                message="Выполняется сохранение. Пожалуйста, подождите."
            />
            {loading && <Loader absolute />}
            <ModalPopup
                isModalOpen={isFavoriteChange}
                header={`Вы хотите отредактировать подборки, в которых состоит резервист?`}
                headerInput={undefined}
                confirm={confirmFavoriteChange}
                onModalClose={closeFavoriteChange}
                actionButtons={['Нет', 'Да']}
                showCloseIcon={false}
            />
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const person = personFullSelector(state, ownProps.id);

    return {
        person,
        pid: state.person.id,
        loading: !!state.personBlock.saving || !!state.person.loading,
        saving: !!state.personBlock.saving,
        user: state.auth.user,
        isWishlistPersonsUpdated: state.wishlists.isWishlistPersonsUpdated,
    };
};

const actions = {
    fetchPerson,
    loadAttributes,
    showErrorAlert,
    push,
    replace,
    savePhoto,
    togglePersonInWishlist,
    setWishlistPersonsUpdated,
    changeData,
};

export default connect(
    mapStateToProps,
    actions,
)(ProfileCard);
