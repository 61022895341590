import React from 'react';
import classnames from 'classnames';
import ElepsisText from './ElepsisText';
import { getShortText } from 'utils';

const LineChart = props => {
    const {
        progress,
        title,
        color,
        withPercent = true,
        reverse,
        alreadyHavePopover = false,
    } = props;

    const getLineStatus = value => (value < 30 ? 'low' : value >= 70 ? 'high' : 'medium');

    const lineStyles = { width: `${progress}%`, backgroundColor: color };

    const lineClass = classnames('LKLineChart__Line', {
        [`LKLineChart__Line--${getLineStatus(progress)}`]: !color,
        [`LKLineChart__Line--reverse`]: reverse,
    });

    const baseClass = classnames('LKLineChart', { [`LKLineChart--reverse`]: reverse });

    return (
            <div className={baseClass}>
                <div className={lineClass} style={lineStyles} />
                <div className="LKLineChart__Info">
                    <ElepsisText className="LKLineChart__Title" alreadyHavePopover={alreadyHavePopover}>{getShortText(title)}</ElepsisText>
                    {withPercent && <span>{progress}%</span>}
                </div>
            </div>
    );
};

export default LineChart;
