import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { showErrorAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import MenuSwitcher from 'components/common/MenuSwitcher';
import PersonSelector from 'components/common/PersonSelector';
import Body from './Body';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/uikit/Button';
import './Email.scss';

const bodyId = 'body';
const recipientsId = 'recipients';

class Email extends Component {
    state = {
        menuItems: [
            { id: bodyId, title: 'Письмо' },
            { id: recipientsId, title: 'Адресаты' },
        ],
        addMenuInfo: [],
        section: bodyId,
        recipientsUpdated: Date.parse(new Date()),
        preview: false,
    };

    setVisibleSection = (section) => {
        this.setState({ section });
    };

    setRecipients = async (recipientsIds, savePage, filter) => {
        await this.props.setRecipients(recipientsIds, savePage, filter);
        this.setState({ recipientsUpdated: Date.parse(new Date()) });
    };

    showPreview = async () => {
        const { getHtmlCode, showErrorAlert, showPageLoader, hidePageLoader } = this.props;
        try {
            showPageLoader();
            const htmlCode = await getHtmlCode();
            this.setState({ preview: htmlCode });
        } catch (e) {
            showErrorAlert('Ошибка загрузки письма.');
        } finally {
            hidePageLoader();
        }
    };

    hidePreview = () => {
        this.setState({ preview: false });
    };

    defaultAddMenuInfo = () => {
        const { recipientsMeta } = this.props;
        return [
            {
                menuId: recipientsId,
                info: !!recipientsMeta.foundCount ? ` (${recipientsMeta.foundCount})` : '',
            },
        ];
    };

    setAddMenuInfo = (menuId = '', info = '') => {
        this.setState({
            addMenuInfo: !menuId ? this.defaultAddMenuInfo() : [{ menuId, info }],
        });
    };

    getActiveTab = () => {
        const { section, recipientsUpdated } = this.state;
        const {
            subject,
            body,
            recipients,
            recipientsMeta,
            setSubject,
            setBody,
            saveEmail,
            sendLetter,
            getRecipients,
            recipientsPaging,
            massEditRecipients,
        } = this.props;

        return section === bodyId ? (
            <Body
                subject={subject}
                body={body}
                setSubject={setSubject}
                setBody={setBody}
                saveEmail={saveEmail}
                showPreview={this.showPreview}
                canSend={recipients?.length !== 0}
                sendLetter={sendLetter}
            />
        ) : (
            <PersonSelector
                setRecipients={this.setRecipients}
                recipients={recipients}
                recipientsUpdated={recipientsUpdated}
                recipientsMeta={recipientsMeta}
                getRecipients={getRecipients}
                recipientsPaging={recipientsPaging}
                massEditRecipients={massEditRecipients}
                setAddMenuInfo={this.setAddMenuInfo}
                setRecipientsPaging={this.props.setRecipientsPaging}
            />
        );
    };

    componentDidMount() {
        const { hideRecipients } = this.props;
        hideRecipients &&
            this.setState({
                menuItems: [{ id: bodyId, title: 'Письмо' }],
            });
    }

    componentDidUpdate(prevProps) {
        const { addMenuInfo } = this.state;
        const { hideRecipients, recipientsMeta } = this.props;
        
        if (prevProps.hideRecipients !== hideRecipients && !hideRecipients) {
            this.setState({
                menuItems: [
                    { id: bodyId, title: 'Письмо' },
                    { id: recipientsId, title: 'Адресаты' },
                ],
            });
        }

        if (prevProps.recipientsMeta.foundCount !== recipientsMeta.foundCount) {
            addMenuInfo.length === 0 && this.setAddMenuInfo();
        }
    }

    render() {
        const { section, preview, menuItems, addMenuInfo } = this.state;
        const { goBack } = this.props;

        return (
            <div className="Email">
                <div className="Email__TopActions">
                    <div className="EmailGoBackButton" onClick={goBack}>
                        {!this.state.preview && (
                            <>
                                <FontAwesomeIcon size="lg" icon="arrow-left" /> К списку писем
                            </>
                        )}
                    </div>
                    {this.state.preview && (
                        <Button closeLink onClick={this.hidePreview} size="sm">
                            Закрыть
                        </Button>
                    )}
                </div>
                {this.state.preview ? (
                    <div className="EmailPreview">
                        <div dangerouslySetInnerHTML={{ __html: preview }} />
                    </div>
                ) : (
                    <MenuSwitcher
                        menu={menuItems}
                        addMenuInfo={addMenuInfo}
                        selectedMenuId={section}
                        onMenuClick={this.setVisibleSection}
                        tabs
                        activeTab={this.getActiveTab()}
                    />
                )}
            </div>
        );
    }
}

Email.defaultProps = {
    recipients: [],
    hideRecipients: false,
    massEditRecipients: true,
};

Email.propTypes = {
    subject: PropTypes.string,
    body: PropTypes.string,
    setSubject: PropTypes.func,
    setBody: PropTypes.func,
    goBack: PropTypes.func,
    recipients: PropTypes.array,
    recipientsMeta: PropTypes.object,
    setRecipients: PropTypes.func,
    saveEmail: PropTypes.func,
    hideRecipients: PropTypes.bool,
    sendLetter: PropTypes.func,
    getHtmlCode: PropTypes.func,
    getRecipients: PropTypes.func,
    recipientsPaging: PropTypes.object,
    massEditRecipients: PropTypes.bool,
};

const actions = { showErrorAlert, push, showPageLoader, hidePageLoader };

export default connect(null, actions)(Email);
