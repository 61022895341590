import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Checkbox.scss';

const Checkbox = (props) => {
    const { color, checked, halfChecked, onClick } = props;

    return (
        <div
            className={classnames('Checkbox', {
                [`Checkbox--${color}`]: true,
                'Checkbox--checked': checked,
                'Checkbox--half-checked': halfChecked,
                'Checkbox--unchecked': !checked && !halfChecked,
            })}
            onClick={onClick}
        >
        </div>
    );
};

Checkbox.defaultProps = {
    color: 'blue',
    checked: false,
    halfChecked: false,
    onClick: () => null,
};

Checkbox.propTypes = {
    color: PropTypes.string,
    checked: PropTypes.bool,
    halfChecked: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Checkbox;
