import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { search } from 'ducks/ExternalSearch';
import Input from 'components/uikit/Input';
import Button from 'components/uikit/Button';
import { externalSearchRoute } from 'routes';
import { DataCard } from 'components/common/DataCard';
import Field from 'components/uikit/Field';
import PersonList from 'components/PersonList';
import Sorting from 'components/Search/Sorting';

const BaseSearch = props => {
    const { search, searchResult, searchMeta } = props;
    const [searchTerm, setSearchTerm] = useState('');
    const [sorting, setSort] = useState({
        Rank: 'desc',
    });

    const [paging, setPageState] = useState({
        pageNum: 1,
        pageSize: 10,
    });

    const onSearch = () => {
        search({ searchTerm, paging, sorting });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        onSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting, paging]);

    const onPaginate = pageNum => {
        setPageState({ ...paging, pageNum });
    };

    const onSearchFieldKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();

            onSearch();
        }
    };

    const onChangeSearchTerm = e => setSearchTerm(e.target.value);

    return (
        <>
            <DataCard className="BaseSearch__Control BaseSearchControl">
                <Field className="BaseSearchControl__Field" textAlign="left">
                    <Input
                        className="BaseSearchControl__Input"
                        value={searchTerm}
                        onChange={onChangeSearchTerm}
                        onSubmit={search}
                        onKeyPress={onSearchFieldKeyPress}
                    />

                    <Button size="md" className="BaseSearchControl__Button" onClick={onSearch}>
                        Найти
                    </Button>
                </Field>
                <div className="BaseSearchControl__Description">* здесь могут быть подсказки</div>
            </DataCard>
            <Link className="BaseSearch__ExtLink" to={externalSearchRoute.url}>
                Расширеный поиск
            </Link>
            <div className="BaseSearch__Content">
                <div className="BaseSearch__SortLine">
                    <div className="BaseSearch__SortLine__Sorting">
                        <Sorting
                            sorting={sorting}
                            onSort={setSort}
                            options={[
                                {
                                    htmlId: 'search-page-order-by-relevance',
                                    title: 'Релевантность',
                                    keys: ['Rank'],
                                    value: 'Rank',
                                    defaultDirection: 'desc',
                                },
                                {
                                    htmlId: 'search-page-order-by-age',
                                    title: 'Возраст',
                                    keys: ['Age'],
                                    value: 'Age',
                                    defaultDirection: 'asc',
                                },
                                {
                                    htmlId: 'search-page-order-by-name',
                                    title: 'ФИО',
                                    keys: ['Name'],
                                    value: 'Name',
                                    defaultDirection: 'asc',
                                },
                            ]}
                        />
                    </div>
                    <div className="FullTextSearch__SortLine__Results">
                        Результаты ({searchMeta.foundCount})
                    </div>
                </div>

                <PersonList
                    persons={searchResult}
                    pageNum={paging.pageNum}
                    onPaginate={onPaginate}
                    pageCount={searchMeta.pageCount}
                    totalCount={searchMeta.foundCount}
                />
            </div>
        </>
    );
};

const mapStateToProps = ({ externalSearch }) => {
    return { searchResult: externalSearch.data.payload, searchMeta: externalSearch.data.meta };
};

const actions = {
    search,
};

export default connect(mapStateToProps, actions)(BaseSearch);
