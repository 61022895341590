import RequestError from './RequestError';

export const serviceResultStatus = {
    Forbidden: 403,
}

export const serviceResultCode =
{
    ConcurrencyError: 475,

    // GroupService
    GroupIsInUse: 2,
    GroupTitleAlreadyExists: 3,
    GroupIdEqualsToParentId: 10,
    GroupQuotasIsAllocated: 13,
    EmailAdministrationTitleIsEmpty: 14,
    GroupCannotChangeType: 1451,

    // CustomAttributeService
    AttributeChangeType: 4,
    AttributeInvalidNameLength: 18,

    // SmsService
    SmsWrongNumber: 5,
    SmsSendFail: 6,

    // UserMaintenance
    UserMaintenanceAlreadyConfirmed: 7,
    UserMaintenanceInvalidCode: 8,
    UserMaintenanceInvalidToken: 9,

    // User
    UserRightsInvalidReduce: 11,
    UserIdentityErrors: 12,
    UserEmailIsInvalid: 15,
    UserNameIsInvalid: 16,
    UserEmailAlreadyExists: 17,
    UserPhoneIsInvalid: 19,
    CuratorNotFound: 20,
    UserOnlyOneRoleForUserAllowed: 13,
    UserSetPasswordErrors: 21,
    UserOnlyOneAdminRoleForUserAllowed: 22,
    UserMergeInvalidRoleError: 23,
    UserUpdateError: 24,
    UserInvalidInterface: 25,
    UserCannotBeAssignedAGroupWithScopeType: 26,
    UserAsLeaderHasNoPersonProfile: 27,

    // ProfileImport
    ProfileImportErrors: 51,
    ProfileImportTooLarge: 52,
    ProfileImportWrongType: 53,
    ProfileImportErrorSave: 54,
    ProfileImportEmptyFile: 55,
    ProfileImportCancelNotFound: 56,
    ProfileImportCancelDeleteError: 57,
    ProfileImportUnsupportedVersion: 58,

    // SignIn
    SignInCredentialError: 100,
    SignInWrongUserNameOrPassword: 101,
    SignInPhoneNotConfirmed: 102,
    SignInInvalidCode: 103,
    SignInUserIsLockedOut: 104,
    SignInUserIsNotAllowed: 105,
    SignInUserNameIsEmpty: 106,
    SignInPasswordIsEmpty: 107,
    SignIn2FaUserIsLockedOut: 109,
    
    SystemEmailTemplateMakeDefaultError: 210,
    NoDefaultEmailTemplateError: 211,

    PersonEmailAlreadyExists: 300,
    PersonAlreadyExists: 301,
    PersonPhoneIsInvalid: 302,
    PersonBirthDateIsNotRange: 303,
    PersonUpdateFileMaxSize: 310,
    PersonUploadFilesCountIsInValid: 311,
    SocialNetworkLinkIsNotValid: 312,
    AttributeLinkIsNotValid: 313,
    PersonPersonnelIsNull: 858,

    UserSessionDropError: 355,
    
    BadRequest: 400,
    Forbidden: 403,
    NotFound: 404,
    UnprocessableEntity: 422,
    
    PersonGrowthResourceListError: 429,
    PersonGrowthResourceError: 430,
    EvaluationQuotaYearRangeTooLarge: 452,

    EvaluationQuotaPointsInsufficientBalance: 550,

    //EvaluationRequest
    EvaluationRequestPersonDoesNotExist: 500,
    EvaluationRequestAlreadyExists: 501,
    EvaluationRequestInvalid: 502,
    EvaluationRequestCannotReview: 503,
    EvaluationRequestCannotConfirm: 504,
    EvaluationRequestExpired: 505,
    EvaluationRequestTestNotFound: 512,
    EvaluationRequestInvalidPeriodEndsOn: 513,
    EvaluationRequestInvalidPeriodStartsOn: 514,
    EvaluationRequestPeriodEndsEarlierPeriodStarts: 515,
    EvaluationRequestCannotReviewDoesNotExist: 516,
    EvaluationRequestCannotSend: 517,

    EvaluationTestIsUsedUnableUpdate: 557,
    EvaluationTestIsUsedUnableDelete: 558,

    PersonGrowthPlanTypeRequired: 600,
    PersonGrowthPlanStartRequired: 601,
    PersonGrowthPlanEndRequired: 602,
    PersonGrowthPlanEndMoreOrEqualStart: 603,
    PersonGrowthEventTypeRequired: 604,
    PersonGrowthResourceTypeRequired: 605,
    PersonGrowthResourceNameRequired: 610,
    PersonGrowthResourceLinkRequired: 611,
    PersonGrowthResourceIsInUse: 612,
    PersonGrowthTagNameRequired: 615,
    PersonGrowthTagAttachedToResource: 616,
    PersonGrowthTagIdAlreadyExists: 617,
    PersonGrowthTagIsInternalPropRequired: 618,
    
    PersonGrowthTagsListError: 620,
    PersonGrowthTagError: 621,
    PersonGrowthResourceProviderAlreadyExists: 625,
    PersonGrowthResourceProviderIsInUse: 626,
    PersonGrowthResourceScheduleIsInUse: 627,
    PersonAlreadyHavePersonGrowthPlan: 630,
    PersonGrowthResourceAlreadyExists: 631,
    PersonAlreadySentToEvaluation: 632,
    PersonGrowthPlanError: 633,
    PersonGrowthPlanDeleteError: 634,
    PersonGrowthPlanApprovedUpdateError: 635,

    InvalidTeam: 800,
    InvalidTeamName: 801,
    PersonAccessInvalidRequest: 851,
    RoleNotFound: 880,
    
    EntityAlreadyExists: 900,
    FilterIsInvalid: 901,
    UnableDeleteEntityIsInUse:903,
    PersonAccessRequestInvalidPhoneNumber:852,
    PersonAccessRequestEmptyEmail:859,

    DefaultWishlistVisibilityChangeForbidden: 870,
    DefaultWishlistDeleteForbidden: 871,

    QueryGeneratorCategoryNotFound: 950,
    QueryGeneratorFieldNotFound: 951,
    QueryGeneratorOperationNotFound: 952,
    QueryGeneratorUnacceptableOperation: 953,
    QueryGeneratorJoinOperationNotFound: 954,
    QueryGeneratorEmptyValue: 954,
    QueryGeneratorWrongValue: 955,
    UnableToOpenOrModifyDeletedEntity: 960,
    ConvertToHtmlError: 961,
    LetterMessageIsEmpty: 962,
    CampaignNotActiveError: 963,
    CopyCampaignError: 964,
    UnableToCreateOrModifyLetterCampaign: 965,
    UnableToModifyCampaignSent: 966,

    ValidationErrors: 1000,
    UnableToOpenCanceledIssue: 1201,
    
    PersonStatusFileMaxSize: 1700,
    PersonStatusFileExtension: 1701,
    PersonStatusCheckFailed: 1702
};

export const validationErrorsCodes = [
    "dueDateError",
    "notFound",
    "outOfRange",
    "equalsCreated",
    "empty"
];

export const personValidationCodes = {
    snilsNotValid: 'snils not valid',
}

const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }

export function getError(error, getTitle, withStatus, validate) {
    const [validateErr] = (validate && getValidationErrors(error) )|| [];
    if(validateErr){
        return new RequestError(
            error,
            validateErr.message|| 'Произошла непредвиденная ошибка',
        );
    }
    if (error.response && error.response.data && error.response.data.code && getTitle) {
        
        return new RequestError(error, getTitle(error.response.data.code, error.response.data.payload));
    }
    if (withStatus && getTitle) {
        const key = getKeyByValue(serviceResultStatus, error?.response?.data?.status);
        if (key && serviceResultCode[key]) {
            
            return new RequestError(
                error,
                getTitle(serviceResultCode[key], 'Произошла непредвиденная ошибка'),
            );
        }
    }

    return  new RequestError(error, "Произошла непредвиденная ошибка");
}

export const getValidationErrors = (error) => {
    if (error.response && error.response.data && error.response.data.code && error.response.data.payload) {
        const payload = error.response.data.payload;
        if (typeof payload === 'object') {
            return parseValidationPayload(payload)
        } else {
            return [{message: payload}];
        }
    }

    return [];
};

export const parseValidationPayload = payload => {
    let errors = [];
    const payloadArr = Object.values(payload);
    payloadArr && Array.isArray(payloadArr) && payloadArr.map((item) => {
        return item && item.map((error) => {
            return errors.push(error) 
        })
    });
    return errors;
}

export function getCode(error) {
    if (error.response && error.response.data && error.response.data.code) {
        return error.response.data.code;
    }

    return null;
}