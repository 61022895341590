import React from 'react';
import StickyFooter from 'components/Lk/Common/StickyFooter';

import classnames from 'classnames';

const ConfirmButton = ({ onClick, disabled, cancel, title }) => (
    <div
        className={classnames('LkEditPersonCommentFooter__Action', {
            'LkEditPersonCommentFooter__Action--disabled': disabled,
            'LkEditPersonCommentFooter__Action--red': cancel,
        })}
        onClick={onClick}
    >
        {title}
    </div>
);

const ConfirmButtons = props => {
    const { isFocused, withStickyFooter, onCancel, onSubmitButton, disabled } = props;

    const buttons = (
        <div
            className={classnames('LkEditPersonCommentFooter', {
                'LkEditPersonCommentFooter--isFocus': isFocused && !withStickyFooter,
                'LkEditPersonCommentFooter--insideModal': !withStickyFooter,
            })}
        >
            <ConfirmButton onClick={onCancel} title="Отменить" cancel />
            |
            <ConfirmButton onClick={onSubmitButton} disabled={disabled} title="Сохранить" />
        </div>
    );

    return withStickyFooter ? <StickyFooter unstick={isFocused}>{buttons} </StickyFooter> : buttons;
};

export default ConfirmButtons;
