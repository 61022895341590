import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import Field from 'components/uikit/Field';
import Input from 'components/uikit/Input'
import Button from 'components/uikit/Button';
import { fetchGroup, newGroup, saveGroup, selectGroup } from 'ducks/Group';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { isNullOrWhitespace } from "utils";
import Page from "components/common/Page";
import { DictionarySelect } from "components/uikit/Select";
import { isSecurityAdmin } from "rightsController";
import './EditGroup.scss';
import { groups } from 'routes';
import Toggle from 'components/common/Toggle';

import { getGroupsList } from 'api.js';
import { groupType } from 'constants.js';

export class GroupEdit extends Component {
    static propTypes = {
        id: PropTypes.number,
        group: PropTypes.object.isRequired,
        dictionaries: PropTypes.object.isRequired,
    }

    static getDerivedStateFromProps(props, state) {
        const group = state.group || props.group || GroupEdit.getDefaultGroup();

        return {
            ...state,
            groupScopes: !!props.dictionaries?.groups ? props.dictionaries.groups.filter(x => x.isScope) : [],
            group,
        };
    }

    static getDefaultGroup() {
        return {
            title: "",
            parentId: null,
            emailAdministrationTitle: "",
            type: groupType.Default.id,
            groupScopes: []
        };
    }

    state = {
        isFormSubmitted: false,
        group: null,
        groupTypes: Object.entries(groupType)
            .map((key) => ({
                htmlId: 'admin-group-edit-' + key[0],
                title: key[1].value,
                value: key[0],
            }))
    };

    componentDidMount() {
        if (!this.props.id) {
            this.props.newGroup();
        } else {
            this.props.fetchGroup(this.props.id);
        }

        getGroupsList(this.props.id || "").then(
            result => {
                this.setState({
                    availableParentIds: result.data.filter(x => x.type === groupType.Default.id).map(x => {
                        return {
                            id: x.id,
                            name: x.title,
                        }
                    }),
                });
            }
        ).catch(() => {
            this.props.showErrorAlert(
                'Во время обработки запроса произошла ошибка.');
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.group !== prevProps.group) {
            this.setState({
                group: this.props.group
            })
        }
    }

    changeGroup = (key, value) => {
        this.setState({
            group: {
                ...this.state.group,
                [key]: value
            },
        });
    };

    changeGroupType = (value) => {
        this.setState({
            group: {
                ...this.state.group,
                type: value,
                parentId: null
            },
        });
    };

    save = (e) => {
        e.preventDefault();
        this.setState({ isFormSubmitted: true }, () => {
            if (this.isFormValid()) {
                this.props.saveGroup(this.getGroupToSave());
            } else {
                this.props.showWarningAlert('Не все поля корректно заполнены');
            }
        });
    }

    onCancel = e => {
        e.preventDefault();
        this.props.push(groups.url);
    };

    getGroupToSave() {
        const { id, ...group } = this.state.group;

        if (id) {
            group.id = id;
        }

        return group;
    }

    getSelectOptions = () => {
        const { group, availableParentIds } = this.state;
        let selectOptions = [];

        if (availableParentIds) {
            selectOptions = availableParentIds
                .filter(x => x.id !== group.id);
        }

        return selectOptions;
    }

    isFormValid = () => {
        const { group } = this.state;
        const selectOptions = this.getSelectOptions();

        return group.type === groupType.Default.id
            ? !isNullOrWhitespace(group.emailAdministrationTitle) &&
                !isNullOrWhitespace(group.title) &&
                !this.isParentInvalid(selectOptions, group.parentId)
            : !isNullOrWhitespace(group.title);
    }

    isParentInvalid = (selectOptions, parentId) => {
        const result = selectOptions.length > 0 ? parentId !== null : true;
        return !result;
    }

    getInputErrorMessage = (inputStateKey, inputErrorKey) => {
        const errorKey = inputErrorKey || this.getInputErrorKey(inputStateKey);
        return this[errorKey];
    }

    getInputErrorKey(inputStateKey) {
        return `${inputStateKey}_error`;
    }

    render() {
        if (this.props.loading) {
            return null;
        }

        const { group, groupScopes, groupTypes } = this.state;
        const selectOptions = this.getSelectOptions();
        const areParentIdsPresented = selectOptions && selectOptions.length > 0;
        const groupScopesExist = groupScopes && groupScopes.length > 0;

        return (
            <Page>
                <div className="admin-group-edit-wrapper">
                    <div className="admin-group-edit-wrapper__content">
                        <div className="admin-group-edit">
                            <div className="admin-group-edit__label">
                                Название
                            </div>
                            <div className="admin-group-edit__value">
                                <Field
                                    invalid={isNullOrWhitespace(group.title) && this.state.isFormSubmitted}
                                >
                                    <Input
                                        id="title"
                                        type="text"
                                        value={group.title || ''}
                                        onChange={e => this.changeGroup('title', e.target.value)}
                                        maxLength={200}
                                        disabled={isSecurityAdmin(this.props.currentUser)}
                                    />
                                </Field>
                            </div>
                        </div>
                        {group.type === groupType.Default.id && <div className="admin-group-edit">
                            <div className="admin-group-edit__label">
                                Родительская группа
                            </div>
                            <div className="admin-group-edit__value">
                                <Field
                                invalid={this.isParentInvalid(selectOptions, group.parentId) && this.state.isFormSubmitted}
                                filled={group.parentId}>
                                <DictionarySelect
                                    id='parentId'
                                    value={group.parentId}
                                    onChange={newParentId => this.changeGroup('parentId', newParentId)}
                                    options={selectOptions}
                                    isClearable
                                    isDisabled={!areParentIdsPresented || isSecurityAdmin(this.props.currentUser)}
                                    placeholder={selectOptions.length > 0 ? "Выберите группу" : "Нет доступных групп для выбора"}
                                />
                                </Field>
                            </div>
                        </div>}
                        {group.type === groupType.Default.id && <div className="admin-group-edit">
                            <div className="admin-group-edit__label">
                                Название для электронной рассылки
                            </div>
                            <div className="admin-group-edit__value">
                                <Field
                                    invalid={isNullOrWhitespace(group.emailAdministrationTitle) && this.state.isFormSubmitted}
                                >
                                    <Input
                                        id="emailAdministrationTitle"
                                        type="text"
                                        value={group.emailAdministrationTitle || ''}
                                        onChange={e => this.changeGroup('emailAdministrationTitle', e.target.value)}
                                        maxLength={250}
                                        disabled={isSecurityAdmin(this.props.currentUser)}
                                    />
                                </Field>
                            </div>
                        </div> }
                        <div className="admin-group-edit">
                            <div className="admin-group-edit__label">
                                Тип
                            </div>
                            <div className="admin-group-edit__value">
                                <Field>
                                    <Toggle
                                        value={group.type}
                                        options={groupTypes}
                                        onChange={e => this.changeGroupType(e)}
                                    />
                                </Field>
                            </div>
                        </div>
                        {group.type === groupType.Default.id && <div className="admin-group-edit">
                            <div className="admin-group-edit__label">
                                Область видимости
                            </div>
                            <div className="admin-group-edit__value">
                                <Field>
                                    <DictionarySelect
                                        id='groupScopes'
                                        value={group.groupScopes}
                                        onChange={e => this.changeGroup('groupScopes', e)}
                                        options={groupScopes}
                                        isClearable
                                        isDisabled={!(groupScopesExist) || isSecurityAdmin(this.props.currentUser)}
                                        placeholder={ groupScopesExist ? "Выберите группу для области видимости" : "Нет доступных групп для выбора"}
                                        isMulti
                                    />
                                </Field>
                            </div>
                        </div>}
                    </div>
                    {
                        isSecurityAdmin(this.props.currentUser) ? null :
                            <div className="admin-group-edit-wrapper__btn">
                            <Button onClick={this.onCancel} closeLink>
                                Отменить
                            </Button>
                                <Button
                                    type="submit"
                                    onClick={this.save}
                                    color="primary"
                                    icon="save"
                                >
                                    Сохранить
						        </Button>
                            </div>
                    }
                </div>
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return selectGroup(state);
}

const actions = {
    newGroup,
    fetchGroup,
    saveGroup,
    showErrorAlert,
    showWarningAlert,
    push,
};

export default connect(mapStateToProps, actions)(GroupEdit);