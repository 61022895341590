import { appName } from '../constants';
import { takeLatest, all, put, select } from 'redux-saga/effects';

const moduleName = 'leader-search';

const SET_ACTIVE_FILTER = `${appName}/${moduleName}/SET_ACTIVE_FILTER`;
const SET_FILTERS = `${appName}/${moduleName}/SET_FILTERS`;
const SET_MAIN_FILTER = `${appName}/${moduleName}/SET_MAIN_FILTER`;
const SET_FILTER_ID = `${appName}/${moduleName}/SET_FILTER_ID`;
const SET_MENU_ID = `${appName}/${moduleName}/SET_MENU_ID`;
const SET_RESTORE_MENU_ID = `${appName}/${moduleName}/SET_RESTORE_MENU_ID`;
const SET_EXTRA_FIELDS = `${appName}/${moduleName}/SET_EXTRA_FIELDS`;
const SET_SEARCH_TERM = `${appName}/${moduleName}/SET_SEARCH_TERM`;
const SET_DYNAMIC_SEARCH_TERM = `${appName}/${moduleName}/SET_DYNAMIC_SEARCH_TERM`;

const initialState = {
    activeFilter: null,
    mainFilter: [],
    filterId: null,
    menuId: 'filter',
    restoreMenuId: null,
    extraFields: false,
    searchTerm: '',
    dynamicSearchTerm: '',
};

export default function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_SEARCH_TERM:
            return { ...state, searchTerm: payload };
        case SET_DYNAMIC_SEARCH_TERM:
            return { ...state, dynamicSearchTerm: payload };            
        case SET_ACTIVE_FILTER:
            return { ...state, activeFilter: payload };
        case SET_EXTRA_FIELDS:
            return { ...state, extraFields: payload };
        case SET_FILTERS:
            return { ...state, mainFilter: payload, activeFilter: null };
        case SET_FILTER_ID:
            return {
                ...state,
                filterId: payload,
                activeFilter: payload ? state.activeFilter : null,
            };
        case SET_MENU_ID:
            return { ...state, menuId: payload };
        case SET_RESTORE_MENU_ID:
            return { ...state, restoreMenuId: payload };
        default:
            return state;
    }
}

export const setActiveFilter = payload => ({ type: SET_ACTIVE_FILTER, payload });
export const setExtraFields = payload => ({ type: SET_EXTRA_FIELDS, payload });
export const setMainFilter = (
    filters = [],
    filterId = null,
    replace = false,
    othersFields = {},
) => {
    return {
        type: SET_MAIN_FILTER,
        payload: { filters, filterId, replace, othersFields },
    };
};

export const setFilterId = id => ({ type: SET_FILTER_ID, payload: id });
export const setMenuId = id => ({ type: SET_MENU_ID, payload: id });
export const setRestoreMenuId = id => {
    if (!id) {
        return { type: SET_RESTORE_MENU_ID, payload: 'none' };
    } else {
        return { type: SET_RESTORE_MENU_ID, payload: id === 'none' ? null : id };
    }
};
export const setSearchTerm = searchTerm => {
    return { type: SET_SEARCH_TERM, payload: searchTerm }
};
export const setDynamicSearchTerm = searchTerm => {
    return { type: SET_DYNAMIC_SEARCH_TERM, payload: searchTerm }
};

const setFilters = payload => ({ type: SET_FILTERS, payload });

function* setMainFilterSaga({ payload: { filters, filterId, replace, othersFields } }) {
    const { mainFilter, activeFilter } = yield select(s => s.filterSearch);
    const validFilters = (filters||[]).filter(x => x);
    if (filterId === null) {
        yield put(setFilters(validFilters));
        return;
    }

    const wrapper = mainFilter.find(x => x.filterId === filterId);
    let newFilters = [];
    if (wrapper) {
        newFilters = mainFilter.map(x => {
            return x.filterId === filterId
                ? {
                      ...othersFields,
                      ...x,

                      childs: replace
                          ? [...validFilters]
                          : [
                                ...x.childs.filter(
                                    x => !activeFilter || activeFilter.group !== x.group,
                                ),
                                ...validFilters,
                            ],
                  }
                : { ...x };
        });
    } else {
        newFilters = [
            ...mainFilter,
            { joinOperation: 'AND', filterId, childs: validFilters, ...othersFields },
        ];
    }

    yield put(setFilters([...newFilters].filter(x => x.childs?.length >= 1)));
}

export function* saga() {
    yield all([takeLatest(SET_MAIN_FILTER, setMainFilterSaga)]);
}
