import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Tab = props => {
    const { label, onClick, activeTab, index } = props;

    return (
        <li
            className={classnames('TabList__Item', {
                'TabList__Item--active': activeTab === index,
            })}
            onClick={() => onClick(index)}
        >
            {label}
        </li>
    );
};

Tab.propTypes = {
    activeTab: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Tab;
