import React from 'react';
import PropTypes from 'prop-types';

const Roles = props => {
    const { roles } = props;
    return (
        <div className="LKPersonCardInfoRoles">
            {roles.map((role, idx)=><div key={idx} className="LKPersonCardInfoRoles__Item">{role}</div>)}
        </div>
    );
};

Roles.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Roles;