import emailValidatorExternal from 'email-validator';
import moment from 'moment';
import { PASSWORD_LENGTH_MINIMUM } from 'constants.js';

export const requireValidator = (message = 'Введите значение', options = {}) => {
    const { trimSpaceString = true } = options;

    return value => {
        let normalizedValue = value || '';
        normalizedValue = normalizedValue.toString();
        if (trimSpaceString) {
            normalizedValue = normalizedValue.trim();
        }
        return !normalizedValue ? message : null;
    };
};

export const validateName = (message = 'Введите значение', required = false) => {
    return value => checkName(value, required) ? null : message;
};

export const checkName = (value, required) => {
    if (!value) {
        return !required;
    }
    return new RegExp('^([a-zA-Zа-яёА-ЯЁ]{1,}-?[a-zA-Zа-яёА-ЯЁ]{1,})$').test(value);
};

export const dateValidator = (message = 'Введите дату', options = {}) => {
    const { allowFutureDates = false } = options;

    return value => {
        var today = new Date();

        return !(moment(value) < today || allowFutureDates) ? message : null;
    };
};

export const birthDateValidator = (message = 'Некорректная дата рождения', options = {}) => {
    const { minAge = 13, maxAge = 125 } = options;

    return value => {
        var age = moment().diff(value, 'years', false);
        return !(age >= minAge && age <= maxAge) ? message : null;
    };
};

export const emailValidator = (message = 'Некорректный email', options = {}) => {
    const { validIfEmpty = true } = options;

    return value => {
        if (validIfEmpty && isEmpty(value)) {
            return null;
        }
        return !emailValidatorExternal.validate(value) ? message : null;
    };
};

export const regexValidator = (regex, message = 'Некорректное значение', options = {}) => {
    const { validIfEmpty = true } = options;

    return value => {
        if (!regex || (validIfEmpty && isEmpty(value))) {
            return null;
        }

        return !regex.test(value) ? message : null;
    };
};

export const yearValidator = (message = 'Некорректный год') => {
    return value => {
        if (!value) {
            return null;
        }

        return !(
            value.toString().length === 4 &&
            +value >= 1900 &&
            +value <= moment().format('YYYY')
        )
            ? message
            : null;
    };
};

export const yearRangeValidator = (
    message = 'Некорректный диапазон',
    startYear,
    endYear,
    options = {},
) => {
    return () => {
        const { maxPastYears = 125 } = options;
        const currentYear = moment().format('YYYY');
        const isValidStartYear =
            startYear &&
            startYear.toString().length === 4 &&
            +startYear >= currentYear - maxPastYears;
        const isValidEndYear =
            endYear && endYear.toString().length === 4 && +endYear <= currentYear;

        return !(isValidStartYear && isValidEndYear && +startYear <= +endYear) ? message : null;
    };
};

const dateRangeValidator = (dateFrom, dateTo = null, options = {}) => {
    const message = 'Некорректный диапазон дат';
    const {
        allowFutureDates = false,
        maxPastYears = 125,
        checkStartDate = true,
        checkEndDate = true,
    } = options;
    const datesAreInRightOrder = dateTo === null || moment(dateFrom).isSameOrBefore(dateTo);
    const dateFromIsInRange = checkStartDate
        ? moment().diff(dateFrom, 'years', false) <= maxPastYears
        : true;

    let dateIsBeforeCurrent = true;
    if (!allowFutureDates) {
        dateIsBeforeCurrent = checkStartDate
            ? moment(dateFrom).isSameOrBefore(moment())
            : ((dateTo === null || moment(dateTo).isSameOrAfter(moment())) && !checkEndDate) ||
              moment(dateTo).isSameOrBefore(moment());
    }

    return !datesAreInRightOrder || !dateFromIsInRange || !dateIsBeforeCurrent ? message : null;
};

export const workBlockStartDateRangeValidator = () => {
    return value => {
        return dateRangeValidator(value.start, value.end);
    };
};

export const workBlockEndDateRangeValidator = (options = {}) => {
    const { isCurrent } = options;
    return value => {
        return dateRangeValidator(value.start, value.end, {
            checkStartDate: false,
            checkEndDate: !isCurrent,
        });
    };
};

export const phoneValidator = (message = 'Телефон не соответствует маске', options = {}) => {
    return regexValidator(/\+7 ?\(?\d{3}\)? ?\d{3}-?\d{2}-?\d{2}/, message, options);
};

export const passwordValidator = (minimalPasswordLength = PASSWORD_LENGTH_MINIMUM) => {
    return value => {
        const errors = [];

        if (!/[А-ЯA-Z]/.test(value)) {
            errors.push('Пароль должен содержать хотя бы одну букву в верхнем регистре');
        }

        if (!/[а-яa-z]/.test(value)) {
            errors.push('Пароль должен содержать хотя бы одну букву в нижнем регистре');
        }

        if (!/[0-9]/.test(value)) {
            errors.push('Пароль должен содержать хотя бы одну цифру');
        }

        if (!/[!@#$%^&*()_+=-]/.test(value)) {
            errors.push('Пароль должен содержать хотя бы один специальный символ');
        }

        if (value.length < minimalPasswordLength) {
            errors.push(`Пароль должен содержать не менее ${minimalPasswordLength} символов`);
        }

        return errors.length > 0 ? errors : null;
    };
};

export const passwordConfirmValidator = (message = 'Пароли не совпадают', options = {}) => {
    return value => {
        const { password } = options;
        return value !== password ? message : null;
    };
};

const isEmpty = value => {
    return !value || !value.toString().trim();
};

export const isUploadFilesCountValid = (uploadFiles = 0, existFiles = 0, maxUploadFiles = 0) => {
    return uploadFiles <= maxUploadFiles - existFiles;
};

export const isUploadFilesSizeValid = (uploadFiles = [], maxBytesPerFile = 0) => {
    return !uploadFiles.some(f => f.size > maxBytesPerFile);
};
