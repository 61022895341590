import React from 'react';
import ListTemplate from 'components/uikit/ListTemplate';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Label from 'components/uikit/Label';
import Button from 'components/uikit/Button';
import classnames from 'classnames';

const ResultBlock = (props) => {
    const { resultResponse, cleanResult } = props;

    return (
        <ListTemplate>
            <Grid className="AdminPersons">
                {resultResponse?.succeed && (
                    <div className="AdminPersonsResultBlock__RowFlex">
                        <Label className="AdminPersonsResultBlock__Title">
                            {`Успешно обработано анкет: ${resultResponse.succeed.length}`}
                        </Label>
                        <Button size="sm" closeLink onClick={cleanResult}>
                            Закрыть
                        </Button>
                    </div>
                )}
                {resultResponse?.failed?.length > 0 && (
                    <>
                        <Row>
                            <Label className="AdminPersonsResultBlock__SubTitle">
                                {`Следующие анкеты (${resultResponse.failed.length}) не были обработаны:`}
                            </Label>
                        </Row>
                        {resultResponse.failed.map((x) => (
                            <Row
                                key={x.id}
                                className={classnames(
                                    'AdminPersonsTable__Row',
                                    'AdminPersonsTable__Row--error--light',
                                )}
                            >
                                <Col xs={2}>{x.id}</Col>
                                <Col xs={6}>{x.lastName}</Col>
                            </Row>
                        ))}
                    </>
                )}
            </Grid>
        </ListTemplate>
    );
};

export default ResultBlock;
