import React from 'react';
import { connect } from 'react-redux';
import { fetchCatalog, clearCatalog, FEDERAL_DISTRICTS, LOCALITIES, REGIONS } from 'ducks/Catalog';
import { REGIONS_FILTER_ID } from '../constants';
import Field from 'components/Lk/Uikit/Field';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';
import uuid from 'uuid/v4';
import { queryOperation } from 'components/Search/QueryOperation';
import FilterTemplate from './FilterTemplate';
import { FIELDS_TYPE } from '../SearchFilters/constants';
import { getCatalog } from 'api';

const Fields = [
    {
        label: 'Связь с регионами',
        table: 'PersonResidence',
        value: 'personRegions',
        section: 'personRegions',
        isDefault: true,
        child: [
            {
                type: FIELDS_TYPE.select,
                label: 'Федеральный округ',
                query: queryOperation.Equal,
                value: 'FederalDistrictId',
                size: 100,
                column: 'FederalDistrictId',
                catalog: 'federalDistricts',
                table: 'PersonResidence',
                updateDependence: {
                    stateField: 'FederalDistrictId',
                    clearable: ['RegionId', 'LocalityId'],
                },
            },
            {
                type: FIELDS_TYPE.select,
                label: 'Регион',
                query: queryOperation.Equal,
                value: 'RegionId',
                size: 100,
                column: 'RegionId',
                catalog: 'regions',
                table: 'PersonResidence',
                updateDependence: {
                    stateField: 'FederalDistrictId',
                    catalog: FEDERAL_DISTRICTS,
                    catalogKey: 'federalDistrictId',
                    clearable: ['LocalityId'],
                },
            },
            {
                type: FIELDS_TYPE.select,
                label: 'Город',
                query: queryOperation.Equal,
                value: 'LocalityId',
                size: 100,
                column: 'LocalityId',
                catalog: 'localities',
                table: 'PersonResidence',
                updateDependence: {
                    stateField: 'RegionId',
                    catalog: REGIONS,
                    catalogKey: 'regionCode',
                    catalogProp: 'code',
                },
            },
            {
                type: FIELDS_TYPE.input,
                label: 'Место рождения',
                query: queryOperation.Contain,
                value: 'BirthPlace',
                size: 100,
                column: 'BirthPlace',
                table: 'Persons',
            },

            {
                type: FIELDS_TYPE.number,
                label: 'Год проживания с',
                query: queryOperation.GreaterOrEqual,
                value: 'ResidenceStartYearStart',
                size: 50,
                column: 'ResidenceStartYear',
                catalog: 'regions',
                table: 'Persons',
            },
            {
                type: FIELDS_TYPE.number,
                label: 'Год проживания по',
                query: queryOperation.LessOrEqual,
                value: 'ResidenceStartYearStartEnd',
                size: 50,
                column: 'ResidenceStartYear',
                catalog: 'regions',
                table: 'Persons',
            },
        ],
    },
];
class Region extends React.Component {
    state = {
        groupCondition: null,
        withCurrentSection: true,
        selectedRegionId: null,
        selectedFederalDistrictId: null,
        loclitesRegion: {},
        searchLocalities: [],
        loadCatalogs: false,
    };

    setStateCurrentSection = withCurrentSection => {
        this.setState({ withCurrentSection });
    };

    setActiveFilter = async () => {
        const { activeFilter } = this.props;

        if (!activeFilter) {
            return;
        }
        const { childs } = activeFilter;

        const [regionFilter] = childs[0].childs;
        if (!regionFilter) {
            return;
        }

        this.setState({
            withCurrentSection: childs.length === 2,
        });
    };

    componentDidMount() {
        this.setActiveFilter();
        this.setState({
            groupCondition: this.props.getGroupCondition(REGIONS_FILTER_ID),
        });
        this.props.clearCatalog(LOCALITIES);
    }

    componentDidUpdate(prevProps) {
        const { activeFilter } = this.props;

        if (prevProps.activeFilter !== activeFilter) {
            this.setActiveFilter();
        }
    }

    setLocalities = async (searchTerm, params) => {
        const { selectedRegionId: regionId } = this.state;
        const { loclitesRegion } = this.state;

        const selectedRegionId = params?.selectedRegionId || regionId;

        if (selectedRegionId && loclitesRegion[selectedRegionId] && !searchTerm) {
            return this.setState({
                searchLocalities: loclitesRegion[selectedRegionId],
                loadCatalogs: true,
            });
        }
        if (selectedRegionId && !searchTerm && !loclitesRegion[selectedRegionId]) {
            const params = { Region: selectedRegionId };
            const res = await getCatalog(LOCALITIES, params);
            const data = res.data.map(x => ({
                ...x,
                value: x.id,
                label: x.name,
            }));
            return this.setState({
                searchLocalities: data,
                loclitesRegion: { ...loclitesRegion, [selectedRegionId]: data },
                loadCatalogs: true,
            });
        }

        if (searchTerm) {
            const params = { searchTerm, Region: regionId };
            const res = await getCatalog(LOCALITIES, params);

            return this.setState({
                searchLocalities: res.data.map(x => ({
                    ...x,
                    value: x.id,
                    label: x.name,
                })),
                loadCatalogs: true,
            });
        }
    };

    setFiltersState = currentFilter => {
        const { setFiltersState } = this.props;
        const { withCurrentSection } = this.state;
        const [current] = currentFilter;
        let childs = [
            {
                joinOperation: 'OR',
                filterId: REGIONS_FILTER_ID,
                childs: currentFilter.filter(x => x).map(x => ({ ...x,joinOperation: 'AND', withCurrentSection })),
                parent: true,
            },
        ];

        const isOnlyPersonFields  = currentFilter.every(x => x.category === 'Persons');
        if (withCurrentSection && !isOnlyPersonFields) {
            childs = childs.concat({
                joinOperation: 'AND',
                filterId: REGIONS_FILTER_ID,
                parent: true,
                childs: currentFilter
                    .filter(x => x.category !== 'Persons')
                    .map(x => ({ ...x, category: 'Persons',joinOperation: 'AND', parent: true, withCurrentSection })),
            });
        }

        let federalDistrict = currentFilter.find(x => x.field === 'FederalDistrictId');
        const region = currentFilter.find(x => x.field === 'RegionId');
        const locality = currentFilter.find(x => x.field === 'LocalityId');
        const birthPlace = currentFilter.find(x => x.field === 'BirthPlace');
        const ResidenceStartYearStart = currentFilter.find(
            x => x.field === 'ResidenceStartYear' && x.operation === queryOperation.GreaterOrEqual,
        );
        const ResidenceStartYearEnd = currentFilter.find(
            x => x.field === 'ResidenceStartYear' && x.operation === queryOperation.LessOrEqual,
        );
        const excludeFromSearch = currentFilter.some(x => x.excludeFromSearch);
        const label = ''
            .concat(federalDistrict ? `${federalDistrict.label}` : '')
            .concat(!!region ? `${federalDistrict ? ', ' : ''} ${region.label}` : '')
            .concat(locality ? `${!!region ? ', ' : ''} ${locality.label}` : '')
            .concat(birthPlace ? `${!!region || !!locality ? ', ' : ''} ${birthPlace.label}` : '')
            .concat(
                ResidenceStartYearStart
                    ? `${!!region || !!locality || !!birthPlace ? ', ' : ''} ${
                          ResidenceStartYearStart.label
                      }`
                    : '',
            )
            .concat(
                ResidenceStartYearEnd
                    ? `${
                          !!region || !!locality || !!birthPlace || !!ResidenceStartYearStart
                              ? ', '
                              : ''
                      } ${ResidenceStartYearEnd.label}`
                    : '',
            )
            .concat(!isOnlyPersonFields && withCurrentSection ? ' (вкл. текущее)' : '');
        const filterId = uuid();

        const newFilters = current
            ? [
                  {
                      id: filterId,
                      joinOperation: this.state.groupCondition,
                      filterId: REGIONS_FILTER_ID,
                      label,
                      section: 'personRegions',
                      group: current.group,
                      parent: true,
                      childs,
                      excludeFromSearch: excludeFromSearch,
                  },
              ]
            : [];
        return setFiltersState(newFilters, REGIONS_FILTER_ID);
    };

    onChange = async (state) => {
        if (state.FederalDistrictId === null) {
            this.props.clearCatalog(LOCALITIES);
        }
        if (state.RegionId) {
            const region = state.RegionId.id
                ? { Region: state.RegionId.id }
                : { Region: state.RegionId };

            this.setState({ selectedRegionId: region.Region });
        }
        if (state.RegionId === null) {
            this.setState({ selectedRegionId: null, searchLocalities: [] });
        }

        if (state.LocalityId && !state.LocalityId.id) {
            if (state.RegionId) {
                const region = state.RegionId.id
                    ? { Region: state.RegionId.id }
                    : { Region: state.RegionId };
                this.setLocalities(null, { selectedRegionId: region.Region });
            }
        }

        if (state.LocalityId === null) {
            this.props.clearCatalog(LOCALITIES);
        }
    };

    onInputChange = async state => {
        if (state.LocalityId) {
            this.setLocalities(state.LocalityId);
        }
    };

    onFocusField = async (field) => {
        if (field === 'LocalityId') {
            this.setLocalities(null);
        }
    };

    onAddFilter = () => {
        this.setState({ selectedRegionId: null });
    };

    setGroupCondition = groupCondition => this.setState({ groupCondition });

    render() {
        const catalogs = {
            ...this.props.catalogs,
            localities: { data: this.state.searchLocalities },
        };
        
        return (
            <FilterTemplate
                {...this.props}
                setFiltersState={this.setFiltersState}
                fields={Fields}
                onChange={this.onChange}
                onInputChange={this.onInputChange}
                onFocusField={this.onFocusField}
                onAddFilter={this.onAddFilter}
                setCondition={this.setGroupCondition}
                condition={this.state.groupCondition}
                catalogs={catalogs}
                extraFields={
                    <div className="LKSearchCardVisibleFIlterLine">
                        <Field>
                            <StyledCheckBox
                                disabled={false}
                                onChange={this.setStateCurrentSection}
                                checked={this.state.withCurrentSection}
                                title="Текущее место жительства"
                            />
                        </Field>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        catalogs: state.catalogs,
        activeFilter: state.filterSearch.activeFilter,
    };
};

const actions = { fetchCatalog, clearCatalog };

export default connect(
    mapStateToProps,
    actions,
)(Region);
