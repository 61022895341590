import React from 'react';
import classnames from 'classnames';
import './WishlistItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import moment from 'moment';

const WishlistItem = (props) => {
    const {
        wishlistId,
        name = '',
        amount,
        visibility,
        modified,
        isSelected,
        setSelectedWishlists,
        selectedWishlists,
        withAction,
        action = () => {},
        editMode,
        updateEditList,
        showComments,
        commentCount,
        withoutComments,
        isDefault,
    } = props;

    const onCheck = (id) => () => {
        !isSelected
            ? setSelectedWishlists([...selectedWishlists, id], id, true)
            : setSelectedWishlists(
                  selectedWishlists.filter((x) => x !== id),
                  id,
                  false,
              );
    };

    const withComments = commentCount && commentCount !== 0;

    return (
        ((editMode && isSelected) || !editMode) && (
            <div className="LKWishlistItem">
                <div className="LKWishlistItemWrapper">
                    {!editMode && (
                        <>
                            <input type="checkbox" readOnly hidden checked={isSelected} />
                            <label
                                onClick={onCheck(wishlistId)}
                                className="LKWishlistItem__Label"
                            ></label>
                        </>
                    )}
                    <div
                        onClick={action}
                        className={classnames('LKWishlistItemCard', {
                            'LKWishlistItemCard--action': withAction && !editMode,
                            'LKWishlistItemCard--nomargin': editMode,
                            'LKWishlistItemCard--disabled': editMode && isDefault,
                        })}
                    >
                        <div className="LKWishlistItem__Header">
                            {editMode ? (
                                <input
                                    value={name}
                                    onChange={updateEditList(wishlistId)}
                                    style={{ width: '100%' }}
                                    disabled={isDefault}
                                />
                            ) : (
                                `${name} ${amount && !withoutComments ? `(${amount})` : ''}`
                            )}
                            {withAction && !editMode && (
                                <div className="LKWishlistItemAction">
                                    <FontAwesomeIcon icon="chevron-right" />
                                </div>
                            )}
                            {!editMode && !withAction && !withoutComments && (
                                <IconButton
                                    type={withComments ? 'notes' : 'blank_notes'}
                                    autoSize
                                    onClick={showComments(wishlistId)}
                                />
                            )}
                        </div>
                        <div className="LKWishlistItem__Footer">
                            <div className="LKWishlistItemInfo">{visibility}</div>
                            <div className="LKWishlistItemInfo">
                                {modified &&
                                    `Дата изменения ${moment(modified).format('L')}`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default WishlistItem;