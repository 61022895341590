import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { showErrorAlert } from 'ducks/Alert';
import Loader from 'components/common/Loader';
import classnames from 'classnames';

const AcceptPage = (props) => {
    const {
        show,
        title,
        info,
        acceptAction,
        cancelAction,
        showErrorAlert,
        setIsAcceptPage,
        setPageType = () => {},
        dataTestHeader = '',
        closeOnly = false,
        forceClose = false,
    } = props;

    const [isActive, setIsActive] = useState(show);
    const [loading, setLoading] = useState(false);

    const modalRoot = document.getElementById('modal-root');

    const _isMounted = useRef();

    useEffect(() => {
        _isMounted.current = true;
        setIsActive(show);
        return () => {
            _isMounted.current = false;
        };
    }, [show]);

    const handleAcceptAction = async () => {
        try {
            _isMounted.current && setLoading(true);
            await acceptAction();
            _isMounted.current && setIsActive(false);
            _isMounted.current && setIsAcceptPage(false);
            _isMounted.current && setPageType('');
        } catch (error) {
            const errorPayload = error?.response?.data?.payload;
            showErrorAlert(errorPayload || error.message);
            _isMounted.current && forceClose && setIsActive(false);
            _isMounted.current && forceClose && setIsAcceptPage(false);
            _isMounted.current && forceClose && setPageType('');
        } finally {
            _isMounted.current && setLoading(false);
        }
    };

    const handleCancelAction = async () => {
        if (cancelAction) {
            await cancelAction();
        }
        _isMounted.current && setIsActive(false);
        _isMounted.current && setIsAcceptPage(false);
        _isMounted.current && setPageType('');
    };

    const content = (
        <div className="LKAcceptPageWrapper">
            <div className="LKAcceptPage">
                {loading && <Loader />}
                {!loading && (
                    <>
                        <div className="LKAcceptPageHeader" data-test={dataTestHeader}>
                            {title}
                        </div>
                        <div className="LKAcceptPageButtons">
                            <div
                                className={classnames('LKAcceptPageButtons--no', {
                                    'LKAcceptPageButtons--close-only': closeOnly,
                                })}
                                onClick={handleCancelAction}
                                data-test="LKAcceptPage_No--button"
                            >
                                {closeOnly ? 'Закрыть' : 'Нет'}
                            </div>
                            {!closeOnly && (
                                <div
                                    className="LKAcceptPageButtons--yes"
                                    onClick={handleAcceptAction}
                                    data-test="LKAcceptPage_Yes--button"
                                >
                                    Да
                                </div>
                            )}
                        </div>
                    </>
                )}
                {info && <div className="LKAcceptPageFooter">{info}</div>}
            </div>
        </div>
    );

    if (isActive) {
        return ReactDOM.createPortal(content, modalRoot);
    }

    return isActive && content;
};

const actions = {
    showErrorAlert,
};

export default connect(null, actions)(AcceptPage);
