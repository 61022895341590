import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';

const CardLine = (props) => {
    const { lineBlock, children, isFlexBlock = false } = props;

    return (
        <div className="Card__Body">
            <Grid fluid>
            <Row>
                <Col xs={12}>
                    {isFlexBlock 
                        ?<div className="FlexBlock">
                            {lineBlock}
                            {children}
                        </div>
                        : <>{lineBlock}{children}</>
                    }
                </Col>
            </Row>
            </Grid>
        </div>
    );
};

CardLine.propTypes = {
    lineBlock: PropTypes.any,
    isFlexBlock: PropTypes.any,
};

export default CardLine;