import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PersonCard from './PersonCard';
import Pagination from 'components/common/Pagination';
import Field from 'components/uikit/Field';
import Input from 'components/uikit/Input';
import Loader from 'components/common/Loader';
import classnames from 'classnames';
import './PersonSelector.scss';

class Selected extends Component {
    state = {
        searchTerm: '',
        loading: false,
    };

    timer = null;

    componentDidUpdate(prevProps) {
        const { searchTerm } = this.props;

        if (prevProps.searchTerm !== searchTerm) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.props.getRecipients({
                    paging: { ...this.props.recipientsPaging, pageNum: 1 },
                    filter: { fullName: searchTerm },
                });
            }, 300);
        }
    }

    componentWillUnmount() {
        const { searchTerm } = this.props;
        if (searchTerm) {
            this.props.setSearchTerm('');
            this.props.getRecipients({
                paging: { ...this.props.recipientsPaging, pageNum: 1 },
            });
        }
    }

    changePage = (pageNum) => {
        this.props.getRecipients({
            paging: { ...this.props.recipientsPaging, pageNum },
            filter: { fullName: this.props.searchTerm },
        });
    };

    changeSearchterm = (e) => {
        const searchTerm = e.target.value;
        this.props.setSearchTerm(searchTerm);
    };

    changePageAmount = (amount) => {
        this.props.setRecipientsPaging( {pageNum: 1, pageSize: amount });
    };

    render() {
        const { loading } = this.state;

        const {
            setSelected,
            recipients,
            recipientsMeta,
            recipientsPaging,
            searchTerm,
            viewMode,
        } = this.props;

        return (
            <>
                <div
                    className={classnames('PersonSelectorSection', {
                        PersonSelectorSection__NoData: recipients?.length === 0,
                    })}
                >
                    <div className="PersonSelectorSelected">
                        {(recipients.length !== 0 || searchTerm) && !viewMode && (
                            <div className="PersonSelectorSelected__TopActions">
                                <div>
                                    <Field>
                                        <Input
                                            value={searchTerm}
                                            onChange={this.changeSearchterm}
                                            postIconButton={searchTerm && 'times-circle'}
                                            postIconButtonClick={() => this.props.setSearchTerm('')}
                                        />
                                    </Field>
                                </div>
                            </div>
                        )}
                        {!loading && recipients.length === 0 ? (
                            <div className="PersonSelectorSelected__NoData">{`${
                                searchTerm ? 'Адресаты не найдены' : 'Адресаты не выбраны'
                            }`}</div>
                        ) : (
                            <>
                                <div>
                                    {recipients.map((x) => {
                                        return (
                                            <PersonCard
                                                key={x.id}
                                                person={x}
                                                checkable={!viewMode}
                                                onCheck={() => setSelected([x.id], false, true)}
                                                checked={true}
                                                viewMode={viewMode}
                                            />
                                        );
                                    })}
                                </div>
                                {recipientsMeta?.pageCount > 1 && (
                                    <div className="PersonSelectorSection__Pagination">
                                        <Pagination
                                            currentPageNum={recipientsPaging.pageNum}
                                            pageCount={recipientsMeta.pageCount}
                                            totalCount={recipientsMeta.foundCount}
                                            pageSize={recipientsMeta.pageSize}
                                            onPaginate={this.changePage}
                                            changePageAmount={this.changePageAmount}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <Loader overlay show={loading} />
            </>
        );
    }
}

Selected.defaultPropTypes = {
    recipients: [],
    viewMode: false,
};

Selected.propTypes = {
    setSelected: PropTypes.func,
    isSelectedPerson: PropTypes.func,
    clearSelection: PropTypes.func,
    recipients: PropTypes.array,
    recipientsMeta: PropTypes.object,
    recipientsUpdated: PropTypes.any,
    getRecipients: PropTypes.func,
    recipientsPaging: PropTypes.object,
    searchTerm: PropTypes.string,
    setSearchTerm: PropTypes.func,
    viewMode: PropTypes.bool,
    setRecipientsPaging: PropTypes.func,
};

export default Selected;
