import React from 'react';
import classnames from 'classnames';
import Image from 'components/common/Image';
import Label from 'components/uikit/Label';
import PropTypes from 'prop-types';

const Avatar = props => {
    const { id, isUser, isCheckable, isChecked, dataTest, onClick } = props;

    const imageClassName = classnames('LKPersonCardAvatar__Image', {
        'LKPersonCardAvatar__Image--small': props.size === 'sm',
    });

    const url = isUser
        ? `/api/user/${id}/photo?photoSize=small`
        : `/api/person/${id}/photo?photoSize=small`;

    const handleClick = e => {
        e.stopPropagation();
        e.preventDefault();
        if (onClick) {
            onClick(e, !isChecked);
        }
    };        

    return (
        <div className="LKPersonCardAvatar" onClick={handleClick}>
            <Image className={imageClassName} photoUri={url} />
            {isCheckable && (
                <>
                    <input id={id} type="checkbox" hidden checked={isChecked} onChange={handleClick} />
                    <Label
                        htmlFor={id}
                        className="LKPersonCardAvatar__Label"
                        data-test={dataTest}
                    ></Label>
                </>
            )}
        </div>
    );
};

Avatar.defaultProps = {
    isUser: false,
    isCheckable: false,
    isChecked: false,
    dataTest: '',
};

Avatar.propTypes = {
    id: PropTypes.number.isRequired,
    isUser: PropTypes.bool,
    size: PropTypes.string,
    isCheckable: PropTypes.bool,
    isChecked: PropTypes.bool,
    dataTest: PropTypes.string,
    onClick: PropTypes.func,
};

export default Avatar;
