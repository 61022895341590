import React from 'react';
import Sorter from 'components/Lk/Uikit/Filter/Sorter';
import { Col } from 'react-flexbox-grid';

const SearchTemplateSorter = props => {
    const { onChange, data } = props;
    const handleSorter = field => state => {
        const copySorting = { ...data };
        !state && delete copySorting[field];
        const updatedSorting = state ? { ...copySorting, [field]: state } : { ...copySorting };
        onChange(updatedSorting);
    };
    return (
        <div className="LKSearchSorter">
            <Col xs={12} className="LKSearchSorter__Block">
                <Sorter value="Размер" size="small" onClick={handleSorter('count')} />
                <Sorter value="Дата" size="small" onClick={handleSorter('updatedOn')} />
                <Sorter value="А-Я" size="small" onClick={handleSorter('title')} />
            </Col>
        </div>
    );
};

export default SearchTemplateSorter;
