import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DirectionType = {
    horizontal: ['chevron-right', 'chevron-left'],
    vertical: ['chevron-down', 'chevron-up'],
};

const ExpandedBlock = props => {
    const { header = '', subHeader = '', direction = 'horizontal' } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const iconType = DirectionType[direction];
    const onExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='PersonCartExpanderWrapper'>
            <div className='PersonCartExpander' onClick={onExpand}>
                <div className='PersonCartExpander__Header'>
                    <div>{header} ({subHeader})</div>
                </div>
                
                <div className='PersonCartExpander__Expander'>
                    <FontAwesomeIcon icon={iconType[+isExpanded]} />
                </div>
            </div>
            {isExpanded && 
            <div className='PersonCartExpanderData'>
                {props.children}
            </div>}
        </div>
    );
};

export default ExpandedBlock;
