import React, { useState, useEffect, useCallback } from 'react';
import Swiper from "./swiper";
import 'swiper/swiper.scss'
import './pagination.scss'

const Slider = props => {

    const { slideChange, initialSlide = null, resultIds } = props;

    const [swiper, updateSwiper] = useState(null);
    const [swiperIds, setSwiperIds] = useState([]);

    const updateIndex = useCallback(() => {
        slideChange(swiper.realIndex, swiperIds)
    }, [swiper, swiperIds, slideChange]);

    useEffect(() => {
        if (swiper !== null) {
            swiper.on("slideChange", updateIndex);
        }

        return () => {
            if (swiper !== null) {
                swiper.off("slideChange", updateIndex);
            }
        };
    }, [swiper, updateIndex]);

    useEffect(() => {
        if (resultIds.length > 0) {
            setSwiperIds(resultIds)
        }
    }, [resultIds])

    let params = {
        shouldSwiperUpdate: true,
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            type: "bullets",
            clickable: true
        },
        activeSlideKey: `${initialSlide}`,
        spaceBetween: 10,
        getSwiper: updateSwiper,
        speed: 0,
    };

    return (
        <Swiper params={params}>
            {props.data.map((item, idx) => {
                return (<div key={idx}>{item}</div>)
            })}
        </Swiper>
    );
};

export default Slider;