import React from 'react';
import { menuItems } from './constants';
import Icons from "components/Lk/Uikit/Icons/Icons";
import Exit from './Exit';
import Image from 'components/Lk/Uikit/Image';
import { Link } from 'react-router-dom';
import { lkPersonProfile } from 'routes';
import './Header.scss';
import { photoSizes, getPersonPhotoUri } from 'api';
import { MENU_PROFILE  } from 'components/Lk/Shared/Profile/constants';

const Menu = props => {
    const { user, refetchKey } = props;
    const photoUri = getPersonPhotoUri(photoSizes.small, user.personId, refetchKey);

    return (
        <div className="LayoutHeaderMenu">
            {
                menuItems.map(x => (
                    <Link key={x.id} to={x.link} className='LayoutHeaderMenuItem'
                          data-test="LayoutHeaderMenu_DynamicLink--link">
                        <Icons name={x.id} />
                    </Link >
                ))
            }
            <div className="LayoutHeaderMenuItem">
                <Exit user={user} data-test="LayoutHeaderMenu__Exit--button"/>
            </div>
            <div className="LayoutHeaderMenuItem__UserPic" 
                >
                {user.personId 
                ?   
                    <Link
                        to={{
                            pathname: lkPersonProfile.buildUrl({ id: user.personId }),
                            search: `?profileMenuId=${MENU_PROFILE}`,
                        }}
                        data-test="LayoutHeaderMenu_UserPic--button"
                    >
                        <Image photoUri={photoUri} />
                    </Link>
                : 
                    <Image photoUri={photoUri}/>}
            </div>
        </div>
    );
};

export default Menu;
