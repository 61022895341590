import React from 'react';
import Button from 'components/uikit/Button';
import { isITAdmin } from 'rightsController';
import { GROUPS_DICTIONARY_NAME, groupType } from 'constants.js';

const GroupInfo = props => {
    const { group, dictionaries, currentUser,editGroup } = props;

    const getParentName = () => {
        const groupDict = dictionaries[GROUPS_DICTIONARY_NAME];
        const parentGroup = groupDict.find(g => g.id === group.parentId);

        return parentGroup ? parentGroup.name : '';
    };

    const getGroupScopeNames = () => {
        const groupDict = dictionaries[GROUPS_DICTIONARY_NAME];
        const groupScopes = groupDict.filter(g =>
            group?.groupScopes ? group.groupScopes.includes(g.id) : false,
        );

        return groupScopes ? groupScopes.map(x => x.name).join(', ') : '';
    };

    const groupScopeNames = getGroupScopeNames();

    return (
        <div className="admin-group-wrapper">
            <div className="admin-group-wrapper__content">
                <div className="admin-group">
                    <div className="admin-group__label">Группа</div>
                    <div className="admin-group__value">{group.title}</div>
                </div>
                {group.type === groupType.Default.id && (
                    <div className="admin-group">
                        <div className="admin-group__label">Родительская группа</div>
                        <div className="admin-group__value">{getParentName()}</div>
                    </div>
                )}
                {group.type === groupType.Default.id && (
                    <div className="admin-group">
                        <div className="admin-group__label">Название для электронной рассылки</div>
                        <div className="admin-group__value">{group.emailAdministrationTitle}</div>
                    </div>
                )}
                <div className="admin-group">
                    <div className="admin-group__label">Тип</div>
                    <div className="admin-group__value">{groupType[group.type]?.value}</div>
                </div>
                {group.type === groupType.Default.id && (
                    <div className="admin-group">
                        <div className="admin-group__label">Область видимости</div>
                        <div className="admin-group__value">
                            {groupScopeNames ? groupScopeNames : 'Нет данных'}
                        </div>
                    </div>
                )}
            </div>
            {isITAdmin(currentUser) && (
                <div className="admin-group-wrapper__btn">
                    <Button color="primary" icon="save" type="submit" onClick={editGroup}>
                        Изменить
                    </Button>
                </div>
            )}
        </div>
    );
};

export default GroupInfo;
