import React from 'react';
import { connect } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { deleteContent } from 'api';
import { getUserShortName, formatDate } from 'utils.js';
import DataGrid from 'components/common/DataGrid';
import Button from 'components/uikit/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RecentPosts = props => {
    const { loading, data, setModal, onItemClick, refreshTree, showErrorAlert, showSuccessAlert, setModalProcessing} = props;

    const buildColumns = [
        {
            Header: 'Название',
            accessor: 'title',
            resizable: false,
            minWidth: 300,
        },
        {
            Header: 'Редактировался',
            accessor: 'modified',
            resizable: false,
            minWidth: 150,
            maxWidth: 200,
            Cell: ({ original }) => (
                formatDate(original.created)
            ),
        },
        {
            Header: 'Пользователь',
            accessor: 'modifier',
            resizable: false,
            minWidth: 150,
            maxWidth: 200,
            Cell: ({ original }) => (
                getUserShortName(original.author)
            ),
        },
        {
            Header: 'Действие',
            resizable: false,
            width: 100,
            headerStyle: { textAlign: 'right' },
            Cell: ({ original }) => ( 
                <div>
                    <Button onClick={() => editItem(original)} size='xs'>
                        <FontAwesomeIcon icon='edit' />
                    </Button>
                    &nbsp;
                    <Button onClick={() => onDeleteItem(original)} size='xs'>
                        <FontAwesomeIcon icon='trash' />
                    </Button>
                </div>
            )
        },

    ];

    const onDeleteItem = item => {
        const modalHeader = `Удалить инструкцию "${item.title}"?`;
        setModal(modalHeader, () => deleteItem(item));
    };

    const deleteItem = async item => {
        try {
            setModalProcessing(true);
            await deleteContent(item.id);
            setModal('', undefined, false);
            refreshTree();
            showSuccessAlert('Инструкция успешно удалена');
        } catch(e) {
            showErrorAlert('Ошибка удаления инструкции');
        } finally {
            setModalProcessing(false);
        }
    };

    const editItem = item => {
        onItemClick({...item, isArticle: true, parentId: item.categoryId});
    };

    return (
        <div className='RecentPosts'>
            <div className='RecentPosts__Header'>
                Недавно созданные / отредактированные инструкции
            </div>
            <DataGrid 
                data={data}
                columns={buildColumns}
                loading={loading}
                manual
                showPagination={false}
                showPageSizeOptions={false}
                className='RecentPost'
            />
        </div>     
    );
};

const mapStateToProps = state => ({currentUser: state.auth.user});
const actions = { showErrorAlert, showSuccessAlert };
export default connect(mapStateToProps, actions)(RecentPosts);
