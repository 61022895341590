import React from 'react';
import classnames from 'classnames';
import './Loader.scss';

const Loader = ({
    size = '',
    overlay = false,
    show = true,
    absolute,
    style,
    noBackground = false,
}) => {
    if (!show) {
        return null;
    }

    return (
        <div
            style={style}
            className={classnames('Loader', {
                'Loader--overlay': overlay,
                'Loader--absolute': absolute,
                'Loader--no-background': noBackground,
            })}
        >
            <div
                className={classnames('sk-fading-circle', { [`sk-fading-circle--${size}`]: size })}
            >
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
            </div>
        </div>
    );
};

export default Loader;
