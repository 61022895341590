import React from 'react';
import ListTemplateForm from './ListTemplateForm';
import Button from 'components/uikit/Button';
import { Col, Row } from 'react-flexbox-grid';
import './ListTemplate.scss';

function ListTemplate({ title, headBtn, form, children }) {
    let button = null;
    if (headBtn) {
        const { value, ...tail } = headBtn;
        button = <Button {...tail}>{value}</Button>;
    }

    return (
        <Row className="ListTemplate">
            <Col xs={12} className="ListTemplate__Head">
                {title && <h1 className="ListTemplate__title">{title}</h1>}
                <div className="ListTemplate__Head-btn">{button}</div>
            </Col>
            {form && (
                <Col xl={3} lg={12} md={12}>
                    <ListTemplateForm props={form} />
                </Col>
            )}
            <Col lg md={12} className="ListTemplate__child">
                {children}
            </Col>
        </Row>
    );
}

export default ListTemplate;
