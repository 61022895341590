import React, { useState } from 'react';

import Pagination from 'components/common/Pagination';
import ResultCard from 'components/common/ResultCard';
import withFavorites from 'components/hoc/withFavorites';

export const PersonListView = (props) => {
    const [waitingFavorite, setWaitingFavorite] = useState(false);

    const {
        className,
        persons,
        selection,
        onChangeSelection,
        pageNum,
        onPaginate,
        pageCount,
        totalCount,
        onChangeFavorite,
        onPersonChange,
    } = props;

    const isPersonChecked = (personId) => {
        if (!selection) {
            return null;
        }

        return selection.personIds.some((id) => personId === id);
    };

    const handlePersonCheckedChange = (personId) => (isChecked) => {
        if (!onChangeSelection) {
            return;
        }

        const personIds = isChecked
            ? [...selection.personIds, personId]
            : selection.personIds.filter((id) => id !== personId);

        onChangeSelection({ ...selection, personIds });
    };

    const handleFavoriteChange = (person) => async (isFavorite) => {
        setWaitingFavorite(true);

        onChangeFavorite(person.id, (isFavoriteLocal) => {
            setWaitingFavorite(false);
            onPersonChange && onPersonChange({ ...person, isFavorite: isFavoriteLocal });
        })(isFavorite);
    };

    return (
        <div className={className} id="SearchResults">
            <div>
                {persons.map((person) => (
                    <ResultCard
                        key={person.id}
                        person={person}
                        isFavorite={props.editMode ? null : person.isFavorite}
                        favoriteDisabled={waitingFavorite}
                        onChangeFavorite={handleFavoriteChange(person)}
                        isChecked={isPersonChecked(person.id)}
                        onChangeChecked={handlePersonCheckedChange(person.id)}
                        editMode={props.editMode}
                        isImport={props.isImport}
                    />
                ))}
            </div>
            <Pagination
                currentPageNum={pageNum}
                pageCount={pageCount}
                totalCount={totalCount}
                onPaginate={onPaginate}
                changePageAmount={props.changePageAmount}
                pageSize={props.pageSize}
            />
        </div>
    );
};

export const PersonList = withFavorites(PersonListView);
