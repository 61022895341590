import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { restricted as restrictedRoute } from 'routes';
import { connect } from 'react-redux';
import CheckAccess from 'components/hoc/CheckAccess'

function ProtectedRoute({ isAllowed, ...rest }) {
    return isAllowed 
        ? <Route {...rest} /> 
        : <Redirect to={restrictedRoute.url} />;
}

function mapStateToProps(state) {
    return { 
        userRoles: state.auth.user.roles,
    };
}

export default connect(mapStateToProps)(CheckAccess(ProtectedRoute));
