import React, { Component } from "react";
import { connect } from 'react-redux';
import { getError, serviceResultCode } from 'serviceErrors';
import uuid from "uuid/v4";
import { Row, Col } from 'react-flexbox-grid';
import {
    getEvaluationTestList,
    getEvaluationRequestsPermissions,
    createEvaluationRequest,
    getQuotaBalance,
    getEvaluationRequestsByPersonId,
    confirmEvaluationRequest,
    getCompetencies,
    resendConfirmEvaluationRequest,
} from "api";
import moment from 'moment';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { fetchTestProviders } from 'ducks/TestProviders';
import { capitalizeFirstLetter } from 'utils';
import { groups, typesDescr, renderSideChart, renderProgressChart, renderRadarChart } from './chartsHelper'
import ManualResults from './ManualResults';
import NewTests from "./NewTests";
import RejectedRequests from './RejectedRequests';
import Tests from "./Tests";
import Button from "components/uikit/Button";
import PersonCard from "components/common/PersonCard";
import ModalDialog from "components/common/ModalDialog";
import DatePicker from "components/uikit/DatePicker";
import Select from "components/uikit/Select";
import Field from "components/uikit/Field";
import Label from "components/uikit/Label";
import "react-sweet-progress/lib/style.css";
import { isModerator, isOperator } from 'rightsController';
import { EVALUATIONTEST_STATUS, EVALUATIONTEST_CRITERIONRESULT_STATUS } from 'constants.js';
import { deepClone } from 'utils';
import "./PersonEvaluate.scss";


const testListStyles = {
    control: base => ({ ...base, fontSize: "24px" }),
    menu: base => ({ ...base, fontSize: "24px" }),
};

class PersonEvaluate extends Component {
    state = {
        data: null,
        testsResults: null,
        competenciesModels: [],
        loaded: false,
        canRequest: false,
        selectedGroup: null,
        isOpenRequestModal: false,
        addResult: {
            resultId: null,
            linkToResult: null,
            resultFileId: null,
            provider: null,
            test: null,
            criteria: [],
            submitted: false,
            type: "new",
            date: "",
            file: null,
        },
        isOpenEditTestResultsModal: false,
        newTestId: null,
        newTestDate: null,
        isOpenSendToTestModal: false,
        requestId: null,
        sendTestId: null,
        sendTestText: "",
        isNewTestsSectionOpen: true,
        isActiveTestsSectionOpen: true,
        isArchiveTestsSectionOpen: true,
        isRejectedTestsSectionOpen: false,
        submitted: false,
        processing: false,
    };

    handleStateChange = (stateKey, value, block = "") => {
        this.setState(state => {
            const resState = block
                ? { ...state, [block]: { ...state[block], [stateKey]: value } }
                : { ...state, [stateKey]: value };
            return resState;
        });
    };

    isValidField = (key, block = "") => {
        return block
            ? this.state.submitted ? !!this.state[block][key] : true
            : this.state.submitted ? !!this.state[key] : true;
    };

    isValidDate = key => this.state.submitted ? moment(this.state[key]).isAfter(moment(new Date()).format('YYYY-MM-DD')) : true;

    isValidSendToEvaluateDate = () => {
        const { newTestDate } = this.state;
        if (newTestDate) {
            return moment().isBefore(newTestDate, 'day')
        }
        return false;
    };

    handleToggle = sectionKey => {
        this.setState({
            [sectionKey]: !this.state[sectionKey]
        });
    };

    handleTestToggle = testId => {
        this.setState(state => {
            return {
                ...state,
                testsResults: state.testsResults.map(x => {
                    const openStatus = x.result.id === testId ? !x.isOpen : x.isOpen;
                    return {
                        ...x,
                        isOpen: openStatus
                    };
                })
            };
        });
    };

    handleTestsTypeChange = group => {
        this.setState(state => {
            return {
                ...state,
                selectedGroup: group
            };
        });
    };

    handleTestEdit = e => {
        const resultId = e.target.getAttribute('resultid');
        const test = this.state.testsResults.find(x => x.result.id === resultId);
        const provider = this.props.testProviders.find(x => x.id === test.testProviderId);
        this.setState(state => {
            return {
                ...state,
                isOpenEditTestResultsModal: true,
                addResult: {
                    evaluationTestId: test.result.evaluationTestId,
                    resultId: test.result.id,
                    linkToResult: test.result.linkToResult,
                    resultFileId: test.result.resultFileId,
                    provider: provider,
                    test: { code: resultId, name: test.title },
                    criteria: this.setEditTestCriteria(test),
                    submitted: false,
                    type: "edit",
                    date: new Date(test.result.completedOn),
                    file: null,
                }
            }
        })
    };

    setEditTestCriteria = (test) => {
        const criteriaResult = test.result.criteria;
        const criteriaOrder = test.criteriaOrder;
        const criteriaInfo = test.criteria;
        return criteriaOrder.map(x => {

            var result = criteriaResult.find(cr => cr.evaluationTestCriterionId === x)

            return {
                ...result,
                id: result?.id || uuid(),
                evaluationTestResultId: test.result.id,
                evaluationTestCriterionId: x,                
                gradesInAscendingOrder: criteriaInfo[x].gradesInAscendingOrder,
                scaleLowerBound: criteriaInfo[x].scaleLowerBound,
                scaleUpperBound: criteriaInfo[x].scaleUpperBound,
                title: criteriaInfo[x].title,
                valueKind: criteriaInfo[x].valueKind,
            }
        });
    };

    openRequestModal = () => {
        const { emailIsValid } = this.props;

        if (emailIsValid) {
            this.setState({ isOpenRequestModal: true });
        }
        else {
            this.props.showErrorAlert("Проверьте правильность введенной электронной почты");
        }
    };

    openSendToTestModal = (requestId, confirmed) => {
        this.setState(state => {
            return {
                ...state,
                isOpenSendToTestModal: true,
                requestId: requestId,
                Confirmed: confirmed
            }
        })
    };

    sendToTest = () => {
        const { requestId, sendTestText, Confirmed } = this.state;
        this.callEffect(async () => {
            this.setState({ processing: true });
            !Confirmed
            ? await confirmEvaluationRequest(requestId, sendTestText) 
            : await resendConfirmEvaluationRequest(requestId, sendTestText);
            const requestsData = await getEvaluationRequestsByPersonId(this.props.id);
            this.setState({
                isOpenSendToTestModal: false,
                sendTestId: "",
                sendTestText: "",
                requestId: null,
                requests: requestsData.data,
                processing: false,
                Confirmed: true
            }, () => {this.props.showSuccessAlert("Сообщение успешно отправлено")});
        })
    };

    onCancelSendToTest = () => {
        this.setState({
            isOpenSendToTestModal: false,
            sendTestId: "",
            sendTestText: "",
            requestId: null,
        });
    };

    render() {
        if (!this.props.testProvidersIsLoaded) {
            return null;
        }
        const requests = this.props.requestsData && this.props.requestsData.data;
        const requestLists = requests && requests.payload
            && this.state.requests && this.state.requests.payload.filter(x => x.status !== "Rejected" && x.status !== "Expired" && x.status !== "Finished");
        const rejectedRequests = this.state?.requests?.payload?.filter(x => x.status === "Rejected" || x.status === "Expired");
        
        return (
            <>
                <PersonCard {...this.props.person} />
                {this.renderEvaluateBlock()}
                {requestLists && requestLists.length > 0 ?
                    <NewTests
                        Confirmed={this.state.Confirmed}
                        isNewTestsSectionOpen={this.state.isNewTestsSectionOpen}
                        newRequests={requestLists}
                        loaded={this.state.loaded}
                        handleToggle={this.handleToggle}
                        openSendToTestModal={this.openSendToTestModal}
                        sendToTest={this.sendToTest}
                        onCancelSendToTest={this.onCancelSendToTest}
                        modalOpen={this.state.isOpenSendToTestModal}
                        handleStateChange={this.handleStateChange}
                        sendTestText={this.state.sendTestText}
                        requestId={this.state.requestId}
                        canRequest={this.state.canRequest}
                        confirmEvaluationRequest={confirmEvaluationRequest}
                        resendConfirmEvaluationRequest={resendConfirmEvaluationRequest}
                        processing={this.state.processing}
                        newTestsRef={this.props.evaluationRefs.newTests}
                    />
                    : null
                }
                
                { rejectedRequests?.length > 0 ? (
                    <RejectedRequests
                        isRejectedTestsSectionOpen={this.state.isRejectedTestsSectionOpen}
                        rejectedRequests={rejectedRequests}
                        loaded={this.state.loaded}
                        handleToggle={this.handleToggle}
                        handleStateChange={this.handleStateChange}
                        rejectedTestsRef={this.props.evaluationRefs.rejectedTests}
                    />
                ): null }
               
                <Tests
                    testsResults={this.state.testsResults}
                    typesDescr={typesDescr}
                    loaded={this.state.loaded}
                    isActiveTestsSectionOpen={this.state.isActiveTestsSectionOpen}
                    isArchiveTestsSectionOpen={this.state.isArchiveTestsSectionOpen}
                    handleToggle={this.handleToggle}
                    handleTestToggle={this.handleTestToggle}
                    handleArchiveTestToggle={this.handleArchiveTestToggle}
                    renderChart={this.renderChart}
                    evaluationRefs={this.props.evaluationRefs}
                    personId={this.props.id}
                    handleTestEdit={this.handleTestEdit}
                    showErrorAlert={this.props.showErrorAlert}
                    showPageLoader={this.props.showPageLoader}
                    hidePageLoader={this.props.hidePageLoader}
                />
            </>
        );
    }

    componentDidMount() {
        if (!this.props.testProvidersIsLoaded) {
            this.callEffect( async() => {
                await this.props.fetchTestProviders();
            });
        }

        this.updateData();
    }

    updateData = () => {
        let testList = [];
        this.callEffect(async () => {
            testList = await getEvaluationTestList();
            const compenciesModels = await getCompetencies(this.props.id);
            const quotaPermissions = await getEvaluationRequestsPermissions();
            const { canRequest } = quotaPermissions.data;
            const quotaPointsData = canRequest && await getQuotaBalance();
            const { quotaPoints } = !!quotaPointsData && Array.isArray(quotaPointsData.data) && quotaPointsData.data[0];
            const testsResults = this.props.testsResults;

            const newData = testsResults.data && testsResults.data
                .flatMap(x => ({
                    ...x.evaluationTest,
                    resultType: x.status === "Relevant" ? EVALUATIONTEST_STATUS.Relevant : EVALUATIONTEST_STATUS.Archived,
                    isOpen: x.status === "Relevant" ? true : false,
                    result: {
                        id: x.id,
                        completedOn: x.completedOn,
                        criteria: x.criteria,
                        evaluationTestId: x.evaluationTestId,
                        linkToResult: x.linkToResult,
                        personId: x.personId,
                        resultFileId: x.resultFileId
                    }
                }));

            this.setState(state => {
                return {
                    ...state,
                    competenciesModels: compenciesModels.data,
                    canRequest: canRequest,
                    quotaPoints: quotaPoints,
                    testList: testList.data,
                    testsResults: newData,
                    requests: this.props.requestsData ? this.props.requestsData.data : [],
                    loaded: true,
                    selectedGroup: groups[0],
                };
            });
        });
    };

    componentDidUpdate(prevProps) {
        
        if (this.state.loaded && 
            this.props.testsResults.data !== prevProps.testsResults.data) {
            this.updateData();
        }
    }


    getCompetenceDescription = (resultId, criteriaId) => {
        const { testsResults, competenciesModels } = this.state;
        const result = testsResults.find(x => x.result.id === resultId);
        const criteria = Object.entries(result.criteria).find(x => x[0] === criteriaId);
        const competencyDescr = competenciesModels
            .map(x => x.competencies)
            .flat()
            .find(x => x.competencyModelId === result.competencyModelId &&
                x.personGrowthTagId === criteria[1].personGrowthTagId);
        if (competencyDescr) {
            return competencyDescr && competencyDescr.description;
        }
    };

    getCriteriaDescription = (resultId, criteriaId) => {
        const { testsResults } = this.state;
        const result = testsResults.find(x => x.result.id === resultId);
        const criteria = Object.entries(result.criteria).find(x => x[0] === criteriaId);
        return criteria && Object.entries(criteria).length >= 2 && criteria[1].description
    };

    renderEvaluateBlock = () => {
        const { loaded, testsResults, selectedGroup } = this.state;
        const { user } = this.props;
        return (
            loaded &&
            testsResults && (
                <div className="Person-Evaluate" ref={this.props.evaluationRefs.evaluation}>
                    <div className="Header">
                        <div className="Header__Text">Оценка</div>
                        {testsResults && testsResults.length !== 0 &&
                            <div className="Header__Select">
                                <Select
                                    placeholder="Выберите тип оценки"
                                    onChange={this.handleTestsTypeChange}
                                    options={groups}
                                    value={selectedGroup}
                                    catalog
                                    styles={testListStyles}
                                />
                            </div>
                        }
                    </div>
                    {selectedGroup && this.renderCharts(this.props.id, selectedGroup)}
                    {(isModerator(user) || isOperator(user)) && <div className="Footer">{this.renderAddTestSection()}</div>}
                </div>
            )
        );
    };

    renderCharts = (personId, selectedGroup) => {
        const { testsResults, loaded } = this.state;
        const testsList = loaded && this.getTests(testsResults, selectedGroup.id);

        if (testsList.length === 0) {
            return (<div className="No-Data">Нет данных</div>);
        }

        if (selectedGroup.groupResult) {
            return this.renderChart(personId, testsList, selectedGroup.visualType, { withHeader: true, withDescr: true }, this.props.testProviders);
        }

        return testsList.map(test => this.renderChart(
            personId,
            test,
            test.visualizationKind,
            {
                withHeader: true,
                withDescr: true,
            }),
            this.props.testProviders,
        );
    };

    renderChart = (personId, test, chartType, { withHeader = false, withDescr = false }, testProviders ) => {
        switch (chartType) {
            case "Signals":
                return renderSideChart(personId, test, withHeader, withDescr);
            case "List":
                return renderProgressChart(
                    personId, 
                    test, 
                    test.kind === 'Competency' 
                        ? this.getCompetenceDescription
                        : this.getCriteriaDescription, 
                    withHeader, 
                    withDescr);
            case "Web":
                return renderRadarChart(personId, test, withHeader, withDescr, testProviders);
            default:
                return null;
        }
    };

    getTests = (data, groupId) => {
        const testTypes = typesDescr.filter(x => x.groupId === groupId);
        let tests = [];
        testTypes.map(x => data
            .filter(res => res.kind === x.type && res.resultType === EVALUATIONTEST_STATUS.Relevant)
            .map(test => {
                const criterias = test.result.criteria.filter(x => x.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Relevant);
                tests.some(t => t.result.evaluationTestId === test.result.evaluationTestId)
                    ? tests.find(t => t.result.evaluationTestId === test.result.evaluationTestId)?.result?.criteria?.push(...criterias)
                    : tests.push(deepClone(test))
            }));
        return tests;
    };

    renderAddTestSection = () => {
        return (this.state.canRequest &&
            <div className="Footer__Buttons">
                <ManualResults
                    personId={this.props.id}
                    testProviders={this.props.testProviders}
                    handleStateChange={this.handleStateChange}
                    testList={this.state.testList}
                    addResult={this.state.addResult}
                    isOpenEditTestResultsModal={this.state.isOpenEditTestResultsModal}
                    canRequest={this.state.canRequest}
                    updateResults={this.props.updateResults}
                    type={this.state.addResult.type}
                />
                {this.renderNewRequest()}
            </div>
        );
    };

    getTestsList = (active = false) => {
        const testsArray = active ? this.state.testList.filter(x => x.status === 'External') : this.state.testList;
        return testsArray.map(x => ({
            id: x.id,
            name: x.title,
            provider: x.testProviderId,
        }));
    };

    renderNewRequest = () => {
        const testList = this.getTestsList(true);
        const isValidTestDate = this.isValidSendToEvaluateDate();
        return (
            <>
                <ModalDialog
                    onClick={this.makeEvaluationRequest}
                    onCloseModal={this.onCancelSendEvaluationRerquest}
                    modalOpen={this.state.isOpenRequestModal}
                    modalHeader="Направить на оценку"
                    btnOktext="Направить"
                    btnCanceltext="Отмена"
                    size="xl"
                    isValidForm={isValidTestDate}
                >
                    <div className="Modal-Children">
                        <div className="Modal-Children__Line">
                            <Row between="xs">
                                <Col xs={5}>
                                    <Field
                                        required
                                        filled={this.state.newTestDate}
                                        invalid={!this.isValidField("newTestDate") || !this.isValidDate("newTestDate") || !isValidTestDate}
                                    >
                                        <Label htmlFor="newTestDate">Завершить оценку до</Label>
                                        <DatePicker
                                            id="newTestDate"
                                            name="newTestDate"
                                            selected={this.state.newTestDate}
                                            minDate={moment().toDate()}
                                            onChange={value => this.handleStateChange("newTestDate", value)}
                                            fixedHeight
                                        />
                                    </Field>
                                </Col>
                                <Col xs={6}>
                                    <Field
                                        required
                                        filled={this.state.newTestId}
                                        invalid={!this.isValidField("newTestId")}
                                    >
                                        <Label htmlFor="testList">Список тестов</Label>
                                        <Select
                                            inputId="testList"
                                            name="testList"
                                            options={testList}
                                            value={this.state.newTestId}
                                            onChange={value => this.handleStateChange("newTestId", value)}
                                            catalog
                                        />
                                    </Field>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </ModalDialog>
                {this.state.canRequest &&
                    <Button size="sm" onClick={this.openRequestModal}>
                            Направить на оценку
                    </Button>
                }
            </>
        )
    }

    makeEvaluationRequest = () => {
        const { newTestId, newTestDate } = this.state;
        if (newTestId && newTestDate && this.isValidDate('newTestDate')) {
            const request = {
                id: uuid(),
                subjectPersonId: this.props.id,
                evaluationTestId: newTestId.id,
                periodStartsOn: moment(new Date()).format('YYYY-MM-DD'),
                periodEndsOn: moment(newTestDate).format('YYYY-MM-DD'),
            };
            this.sendEvaluationRequest(request);
            this.setState(
                state => {
                    return {
                        ...state,
                        isOpenRequestModal: false,
                        newTestId: "",
                        newTestDate: null,
                        submitted: false,
                    };
                }
            )
        } else {
            this.setState(state => {
                return {
                    ...state,
                    submitted: true,
                };
            });
        }
    };

    sendEvaluationRequest = (request) => {
        this.callEffect(async () => {
            if (this.state.quotaPoints > 0) {
                const quotas = await getQuotaBalance({
                    startYearMonth: moment(new Date()).format('YYYY-MM-DD'),
                    endYearMonth: request.periodEndsOn
                });

                for (let i = 0; i < quotas.data.length; i++) {
                    if (quotas.data[i].quotaPoints <= 0) {
                        this.props.showErrorAlert(`Квота на ${capitalizeFirstLetter(moment(quotas.data[i].year).format('YYYY'))} исчерпана, либо не распределена`);
                        return false;
                    }
                }

                await createEvaluationRequest(request);
                const requestsData = await getEvaluationRequestsByPersonId(this.props.id);
                this.setState({
                    requests: requestsData.data,
                });
            } else {
                return this.props.showErrorAlert(`Квота на текущий год исчерпана`);
            }
            this.props.showSuccessAlert('Направление на оценку успешно отправлено')
        });
    };

    onCancelSendEvaluationRerquest = () => {
        this.setState(state => {
            return {
                ...state,
                isOpenRequestModal: false,
                newTestId: "",
                newTestDate: null,
                submitted: false,
            };
        });
    };

    callEffect = async callback => {
        this.props.showPageLoader();
        try {
            await callback();
        } catch (error) {
            const reqError = getError(error, this.getEvaluationRequestError);
            this.props.showErrorAlert(reqError.message);
        } finally {
            this.setState({processing: false});
            this.props.hidePageLoader();
        }
    };

    getEvaluationRequestError = (code) => {
        this.setState({
            isOpenSendToTestModal: false,
        })
        switch (code) {
            case serviceResultCode.EvaluationRequestCannotConfirm:
                return `Невозможно подтвердить запрос со статусом 'Подтверждено'`;
            case serviceResultCode.EvaluationRequestExpired:
                return `Истекло время действия запроса`
            case serviceResultCode.EvaluationRequestPersonDoesNotExist:
                return `Невозможно создать запрос для пользователя, так как его не существует`
            case serviceResultCode.EvaluationRequestInvalid:
                return `Данный запрос не является валидным`
            case serviceResultCode.EvaluationRequestAlreadyExists:
                return `Данный запрос уже существует`
            case serviceResultCode.EvaluationRequestInvalidPeriodEndsOn:
                return `Неверная дата окончания`
            case serviceResultCode.EvaluationRequestInvalidPeriodStartsOn:
                return `Неверная дата начала`
            case serviceResultCode.EvaluationRequestPeriodEndsEarlierPeriodStarts:
                return `Дата окончания указана раньше, чем дата начала`
            case serviceResultCode.EvaluationRequestTestNotFound:
                return `Не найден тест`
            case serviceResultCode.EvaluationRequestCannotSend:
                return "Невозможно отправить запрос повторно, так как он не имеет статуса 'Подтверждено'"
            default:
                return `Произошла непредвиденная ошибка`;
        }
    }
}

const mapStateToProps = (state) => {
    const testProviders = state.testProviders.data.payload;
    const testProvidersProcessed = (testProviders && testProviders.map((x => ({...x, code: x.id, name: x.title, id: x.id})))) || [];
    return {
        testProviders: testProvidersProcessed,
        testProvidersIsLoaded: state.testProviders.loadComplete,
        user: state.auth.user,
    }  
};

const actions = { showPageLoader, hidePageLoader, fetchTestProviders };

export default connect(mapStateToProps, actions)(PersonEvaluate);
