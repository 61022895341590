import React from 'react';
import TopMenu from 'components/common/TopMenu';
import { ROLE_LEADER, ROLE_OPERATOR, ROLE_USER, ROLE_INSTRUCTION_EDITOR } from 'constants.js';

const ListManagersMenu = props => {

    const renderMenu = () => {
        let mainMenuItems = [
            {
                id: "Leaders",
                title: "Закрепленные руководители",
                status: 'Approved',
                roles: [ROLE_LEADER]
            },
            {
                id: "Approved",
                title: "Заявки согласованы",
                status: 'Approved',
                roles: [ROLE_LEADER, ROLE_OPERATOR, ROLE_USER, ROLE_INSTRUCTION_EDITOR]
            },
            {
                id: "Review",
                title: "Заявки на рассмотрении",
                status: 'Review',
                roles: [ROLE_LEADER, ROLE_OPERATOR, ROLE_USER, ROLE_INSTRUCTION_EDITOR]
            },
            {
                id: "Rejected",
                title: "Заявки отклонены",
                status: 'Rejected',
                roles: [ROLE_LEADER, ROLE_OPERATOR, ROLE_USER, ROLE_INSTRUCTION_EDITOR]
            }
        ];

        return (
            <div className="ProfileMenu__Container">
                <TopMenu
                    items={mainMenuItems}
                    selected={props.selected || "Leaders"}
                    onChangePage={(item) => props.onChangeMenu(item)}
                />
            </div>
        )
    };

    const SubTitle = (selected) => {
        switch (selected) {
            case "Approved":
                return "Поданные Вами заявки на подключение/отключение роли, которые согласованы:";
            case "Review":
                return "Поданные Вами заявки на подключение/отключение роли, которые пока находятся в работе:";
            case "Rejected":
                return "Поданные Вами заявки на подключение/отключение роли, которые были отклонены:";
            case "Leaders":
                return "Список руководителей, закрепленных за Вами как за экспертом по управлению талантами:";
            default:
                return "";
        }
    };
    
    return (
        <>
            {renderMenu()}
            <h4>{SubTitle(props.selected)}</h4>
        </>
    )
};

export default ListManagersMenu;