import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { quotasRoute } from 'routes';
import moment from 'moment';
import uuid from 'uuid/v4';
import { addCorrections, getQuotasInfo, getQuotaById, saveQuota } from 'api';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { Link } from 'react-router-dom';
import Field from 'components/uikit/Field';
import Input from 'components/uikit/Input';
import Label from 'components/uikit/Label';
import Select from 'components/uikit/Select';
import Button from 'components/uikit/Button';
import { capitalizeFirstLetter, isSameOrAfter } from 'utils';
import CheckField from 'components/uikit/CheckField';
import ModalDialog from 'components/common/ModalDialog';
import { serviceResultCode, getError } from 'serviceErrors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { quotaStatuses }  from '../constants';

const emptyCorrectionEdit = {
    balances: {},
    corrections: {},
    showMinusSign: {},
};

const initalState = {
    quotas: [],
    selectedQuotaId: null,
    status: quotaStatuses.edit,
    isOpenFixQuotasModal: false,
    isOpenCorrectionModal: false,
    allAmount: 12000,
    correctionEdit: emptyCorrectionEdit,
    processing:false
};

class EditQuotas extends Component {
    state = initalState;

    goBack = () => this.props.push(quotasRoute.url);

    changeAllAmount = e => this.setState({ allAmount: e.target.value });

    isInputFilled = value => {
        return !!value;
    };

    handleAmountChange = (quotaId, groupId, quotasValue, isNewQuota = true) => {
        const { quotas } = this.state;
        const groups = isNewQuota
            ? this.getSelectedQuota(quotas, quotaId).newQuotaInfo.toGroups
            : quotas.allocations;

        const newGroups = groups.map(x => {
            return {
                ...x,
                amount: x.id === groupId ? quotasValue : x.amount,
                toGroup: isNewQuota ? null : { ...x.toGroup },
            };
        });

        const totalAmount = this.calculateSelectedAmount(newGroups);

        const newQuotas = isNewQuota
            ? quotas.map(x => {
                  return {
                      ...x,
                      currentUser: { ...x.currentUser },
                      fromGroup: { ...x.fromGroup },
                      newQuotaInfo: {
                          ...x.newQuotaInfo,
                          toGroups:
                              x.quotaId === quotaId
                                  ? newGroups
                                  : x.newQuotaInfo.toGroups.map(g => ({ ...g })),
                      },
                      totalAmount: x.quotaId === quotaId ? totalAmount : x.totalAmount,
                  };
              })
            : {
                  ...quotas,
                  allocations: newGroups,
                  fromGroup: { ...quotas.fromGroup },
                  totalAmount: totalAmount,
              };

        this.setState(state => {
            return {
                ...state,
                quotas: newQuotas,
            };
        });
    };

    getTestError = () => code => {
        switch (code) {
            case serviceResultCode.EvaluationQuotaYearRangeTooLarge:
                return `Нельзя редактировать прошедшие квоты`;
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };

    handleCorrectionChange = (groupId, correction) => {
        const currentCorrection = this.state.correctionEdit.corrections[groupId];

        let showMinusSign = false;
        if (correction === '-' || correction === '-0' || correction === '0-') {
            showMinusSign = true;
        }

        this.setState(state => ({
            ...state,
            correctionEdit: {
                ...state.correctionEdit,
                showMinusSign: {
                    ...state.correctionEdit.showMinusSign,
                    [groupId]: showMinusSign,
                },
            },
        }));

        if (correction === '' || correction === '+' || correction === '-') {
            this.setCorrection(groupId, 0);
            return;
        }

        if (correction.startsWith('+-') || correction.startsWith('-+')) {
            this.setCorrection(groupId, -currentCorrection);
            return;
        }

        let correctionNum = Number(correction);
        if (Number.isNaN(correctionNum)) {
            return;
        }

        correctionNum = Number.parseInt(correctionNum, 10);
        if (Math.abs(correctionNum) > 1000000) {
            return;
        }

        this.setCorrection(groupId, correctionNum);
    };

    setCorrection = (groupId, correction) => {
        const fromGroupId = this.state.quotas.fromGroup.id;
        if (groupId === fromGroupId) {
            return;
        }

        this.setState(state => {
            const toGroupOldBalance = state.correctionEdit.balances[groupId];
            const toGroupOldCorrection = state.correctionEdit.corrections[groupId];
            const toGroupCorrectionDelta = correction - toGroupOldCorrection;

            const toGroupNewBalance = toGroupOldBalance + toGroupCorrectionDelta;

            const fromGroupNewBalance =
                state.correctionEdit.balances[fromGroupId] - toGroupCorrectionDelta;

            const fromGroupNewCorrection =
                state.correctionEdit.corrections[fromGroupId] - toGroupCorrectionDelta;

            return {
                ...state,
                correctionEdit: {
                    ...state.correctionEdit,
                    balances: {
                        ...state.correctionEdit.balances,
                        [fromGroupId]: fromGroupNewBalance,
                        [groupId]: toGroupNewBalance,
                    },
                    corrections: {
                        ...state.correctionEdit.corrections,
                        [fromGroupId]: fromGroupNewCorrection,
                        [groupId]: correction,
                    },
                },
            };
        });
    };

    handleBalanceChange = (groupId, balance) => {
        let balanceNum = Number(balance);
        if (Number.isNaN(balanceNum)) {
            return;
        }

        balanceNum = Number.parseInt(balanceNum, 10);
        if (Math.abs(balanceNum) > 1000000) {
            return;
        }

        this.setBalance(groupId, balanceNum);
    };

    setBalance = (groupId, balance) => {
        const prevBalance = this.state.correctionEdit.balances[groupId];
        const prevCorrection = this.state.correctionEdit.corrections[groupId];
        const newCorrection = balance - (prevBalance - prevCorrection);
        this.setCorrection(groupId, newCorrection);
    };

    calculateSelectedAmount = quotas => {
        return quotas.reduce((acc, x) => {
            return acc + +x.amount;
        }, 0);
    };

    handleSaveClick = () => {
        this.state.status === quotaStatuses.fix
            ? this.setState({ isOpenFixQuotasModal: true })
            : this.saveQuota();
    };

    handleApplyCorrectionsClick = () => {
        this.setState({ isOpenCorrectionModal: true });
    };

    saveQuota = async () => {
        try {
            const quotaToSave = this.getQuotaToSave(this.props.isNew);
            this.setState({processing:true})
            await this.callEffect(() => saveQuota(quotaToSave));
            this.props.push(quotasRoute.url);
        } catch (error) {
            this.props.showErrorAlert(error.message);
        }
        this.setState({processing:false})
    };

    saveCorrections = async () => {
        try {
            this.setState({ isOpenCorrectionModal: false, processing:true });
            const corrections = this.getCorrectionsToSave();
            await this.callEffect(() => addCorrections(this.props.id, corrections));
            this.loadQuotas(false);
        } catch (error) {
            this.props.showErrorAlert(error.message);
        }
        this.setState({processing:false})
    };

    getSelectedQuota = (quotas, quotaId) => {
        if (Array.isArray(quotas)) {
            return quotas.find(x => x.quotaId === quotaId);
        }
        return quotas[quotaId];
    };

    getQuotaToSave = isNewQuota => {
        const { quotas, selectedQuotaId } = this.state;
        const quota = isNewQuota ? this.getSelectedQuota(quotas, selectedQuotaId) : quotas;
        return isNewQuota
            ? {
                  id: uuid(),
                  parentQuotaId: quota.newQuotaInfo.parentQuotaId,
                  fromGroupId: quota.fromGroup.id,
                  totalAmount: quota.totalAmount,
                  year: quota.year,
                  allocations: quota.newQuotaInfo.toGroups.map(x => {
                      return {
                          id: uuid(),
                          toGroupId: x.id,
                          amount: x.amount,
                      };
                  }),
                  finalize: this.state.status === quotaStatuses.fix,
              }
            : {
                  id: quota.id,
                  parentQuotaId: quota.parentQuotaId,
                  fromGroupId: quota.fromGroup.id,
                  totalAmount: quota.totalAmount,
                  year: quota.year,
                  allocations: quota.allocations.map(x => {
                      return {
                          id: x.id,
                          toGroupId: x.toGroup.id,
                          amount: x.amount,
                      };
                  }),
                  finalize: this.state.status === quotaStatuses.fix,
              };
    };

    getCorrectionsToSave = () => {
        const newCorrections = this.state.correctionEdit.corrections;
        const oldCorrections = this.state.quotas.groupTotalCorrections;

        const correctionDiff = Object.keys(newCorrections).reduce(
            (obj, groupId) => ({
                ...obj,
                [groupId]: newCorrections[groupId] - oldCorrections[groupId],
            }),
            {},
        );

        return {
            id: uuid(),
            previousCorrectionOrderingKey: this.state.quotas.lastCorrectionOrderingKey,
            groupCorrections: correctionDiff,
        };
    };

    toggleFixQuotas = () => {
        this.setState(state => {
            const newStatus = state.status === quotaStatuses.edit ? quotaStatuses.fix : quotaStatuses.edit;
            return {
                ...state,
                status: newStatus,
            };
        });
    };

    enterCorrectionMode = () => {
        if (this.state.status === quotaStatuses.edit) {
            return;
        }

        this.setState(state => ({
            ...state,
            status: quotaStatuses.correcting,
            correctionEdit: {
                balances: state.quotas.groupSelfQuotaPointBalances,
                corrections: state.quotas.groupTotalCorrections,
                showMinusSign: {},
            },
        }));
    };

    cancelCorrection = () => {
        if (this.state.status === quotaStatuses.edit) {
            return;
        }

        this.setState(state => ({
            ...state,
            status: quotaStatuses.fix,
            correctionEdit: emptyCorrectionEdit,
        }));
    };

    handleModalCancelClick = () => {
        this.setState({
            isOpenFixQuotasModal: false,
            isOpenCorrectionModal: false,
        });
    };

    renderHeader = () => {
        const { quotas, selectedQuotaId } = this.state;
        const quota = this.getSelectedQuota(quotas, selectedQuotaId);
        const showAllAmount = selectedQuotaId !== null || quotas.id;
        const allAmount = this.state.allAmount;
        const isLockedAmount = this.props.isNew
            ? quota && quota.newQuotaInfo.parentQuotaId
            : quotas && quotas.parentQuotaId;

        return (
            <div className="Quotas-Container__Header">
                <div>
                    <Link to={quotasRoute.url}>
                        <FontAwesomeIcon icon="arrow-left" /> Назад
                    </Link>
                </div>
                {this.renderPeriodsList(this.props.isNew)}
                {!this.props.fixed && (
                    <>
                        {showAllAmount ? (
                            <div className="Col-25">
                                <Field filled={this.isInputFilled(allAmount)}>
                                    <Label>Общее количество</Label>
                                    <Input
                                        value={allAmount}
                                        onChange={this.changeAllAmount}
                                        type="number"
                                        min={0}
                                        readOnly={isLockedAmount}
                                    />
                                </Field>
                            </div>
                        ) : (
                            <div className="Col-25"></div>
                        )}
                    </>
                )}
            </div>
        );
    };

    renderPeriodsList = isNew => {
        const { quotas } = this.state;
        return (
            <div className="Col-50">
                <Field>
                    <Label>Период</Label>
                    {isNew ? (
                        <Select
                            options={quotas
                                .filter(x => !!x.newQuotaInfo)
                                .sort((a, b) =>
                                    moment(a.year).isAfter(moment(b.year), 'year')
                                        ? 1
                                        : -1,
                                )
                                .filter(
                                    x =>
                                        x.status === 'QuotaNotCreated' &&
                                        moment(x.year).isAfter(
                                            moment().subtract(1, 'year'),
                                            'year',
                                        ),
                                )
                                .map(x => ({
                                    id: x.quotaId,
                                    name: capitalizeFirstLetter(
                                        moment(x.year).format('YYYY'),
                                    ),
                                }))
                                .slice(0, 5)}
                            catalog
                            onChange={this.handlePeriodChange}
                            placeholder="Выберите период"
                        />
                    ) : (
                        <Select
                            isDisabled
                            value={{
                                id: 0,
                                name: capitalizeFirstLetter(
                                    moment(quotas.year).format('YYYY'),
                                ),
                            }}
                            catalog
                        />
                    )}
                </Field>
            </div>
        );
    };

    handlePeriodChange = item => {
        const newQuotas = this.copyNewQuotasArray(this.state.quotas);
        const selectedQuota = newQuotas.find(x => x.quotaId === item.id);
        const allAmount = selectedQuota.newQuotaInfo.parentQuotaId
            ? selectedQuota.newQuotaInfo.totalAmount
            : this.state.allAmount;

        this.setState(state => {
            return {
                ...state,
                selectedQuotaId: item.id,
                quotas: newQuotas,
                allAmount: allAmount,
            };
        });
    };

    copyNewQuotasArray = quotasArray => {
        return quotasArray.map(x => {
            return {
                ...x,
                currentUser: { ...x.currentUser },
                fromGroup: { ...x.fromGroup },
                newQuotaInfo: {
                    ...x.newQuotaInfo,
                    toGroups: x.newQuotaInfo.toGroups.map(g => {
                        return {
                            ...g,
                            amount: g.amount ? g.amount : 0,
                        };
                    }),
                },
                totalAmount: x.totalAmount ? x.totalAmount : 0,
            };
        });
    };

    renderFooter = () => {
        const { isNew } = this.props;

        const { allAmount, selectedQuotaId, status, quotas } = this.state;
        const totalAmount = isNew
            ? this.getSelectedQuota(quotas, selectedQuotaId).totalAmount
            : quotas.totalAmount;
        const isDisabled = +allAmount !== +totalAmount || +totalAmount === 0;

        let responsibleUserName = quotas.responsibleUser
            ? `${quotas.responsibleUser.lastName} ${quotas.responsibleUser.firstName} ${quotas.responsibleUser.middleName}`
            : `${this.props.user.lastName} ${this.props.user.firstName} ${this.props.user.middleName}`;

        const renderFooterControlsUnfixed = () => {
            return (
                <>
                    <div className="Quotas-Container__Checkbox-Group">
                        <CheckField
                            id="fixQuotas"
                            title="Зафиксировать квоты"
                            checked={this.state.status === quotaStatuses.fix}
                            onChange={this.toggleFixQuotas}
                            modifier="QuotasContainer"
                        />
                        <ModalDialog
                            onClick={this.saveQuota}
                            processing={this.state.processing}
                            onCloseModal={this.handleModalCancelClick}
                            modalOpen={this.state.isOpenFixQuotasModal}
                            modalHeader="Зафиксировать квоты?"
                            btnOktext="Да"
                            btnCanceltext="Нет"
                        />
                    </div>
                    <div className="Quotas-Container__Buttons-Group">
                        <Button size="sm" onClick={this.handleSaveClick} disabled={isDisabled || this.state.status !== quotaStatuses.fix}>
                            Сохранить
                        </Button>
                        <Button size="sm" color="secondary" onClick={this.goBack}>
                            Отмена
                        </Button>
                    </div>
                </>
            );
        };

        const renderFooterControlsFixed = () => {
            return (
                <div className="Quotas-Container__Buttons-Group">
                    <Button size="sm" onClick={this.enterCorrectionMode}>
                        Корректировать
                    </Button>
                </div>
            );
        };

        const renderFooterControlsCorrecting = () => {
            const isDisabled = Object.values(this.state.correctionEdit.balances).some(v => v < 0);

            const oldBalances = quotas.groupQuotaPointBalances;
            const oldCorrections = quotas.groupTotalCorrections;
            const newBalances = this.state.correctionEdit.balances;
            const newCorrections = this.state.correctionEdit.corrections;

            const correctionTable = (
                <table className="CorrectionDiffTable">
                    <tbody>
                        {quotas.allocations.map(a => {
                            const oldBalance = oldBalances[a.toGroup.id];
                            const oldCorrection = oldCorrections[a.toGroup.id];
                            const newBalance = newBalances[a.toGroup.id];
                            const newCorrection = newCorrections[a.toGroup.id];

                            return (
                                <tr key={a.id} className="CorrectionDiffRow">
                                    <td className="CorrectionDiffCol">{a.toGroup.title}</td>
                                    <td className="CorrectionDiffCol CorrectionDiffCol_Correction">
                                        {oldBalance} {this.renderCorrectionNumber(oldCorrection)}
                                    </td>
                                    <td className="CorrectionDiffCol">→</td>
                                    <td className="CorrectionDiffCol CorrectionDiffCol_Correction">
                                        {newBalance} {this.renderCorrectionNumber(newCorrection)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );

            return (
                <>
                    <div className="Quotas-Container__Buttons-Group">
                        <Button
                            size="sm"
                            onClick={this.handleApplyCorrectionsClick}
                            disabled={isDisabled}
                        >
                            Применить
                        </Button>
                        <Button size="sm" color="secondary" onClick={this.cancelCorrection}>
                            Отмена
                        </Button>
                    </div>
                    <ModalDialog
                        onClick={this.saveCorrections}
                        onCloseModal={this.handleModalCancelClick}
                        modalOpen={this.state.isOpenCorrectionModal}
                        modalHeader="Применить корректировки?"
                        processing={this.state.processing}
                        btnOktext="Да"
                        btnCanceltext="Нет"
                    >
                        {correctionTable}
                    </ModalDialog>
                </>
            );
        };

        return (
            <div className="Quotas-Container__Footer">
                <div className="Col">
                    <Field>
                        <Label>Ответственный</Label>
                        {responsibleUserName}
                    </Field>
                </div>
                {isSameOrAfter(quotas.year, new Date(), 'year') &&
                    (!this.props.fixed
                        ? renderFooterControlsUnfixed()
                        : status === quotaStatuses.correcting
                        ? renderFooterControlsCorrecting()
                        : renderFooterControlsFixed())}
            </div>
        );
    };

    renderBody = () => {
        const { allAmount, selectedQuotaId, quotas } = this.state;
        const { isNew } = this.props;
        const totalAllocationAmount = isNew
            ? this.getSelectedQuota(quotas, selectedQuotaId).totalAmount
            : quotas.totalAmount;
        const errorAbove = allAmount < totalAllocationAmount ? 'Вы превысили квоту!' : null;
        const errorBelow = allAmount > totalAllocationAmount ? 'Не вся квота распределена' : null;

        const renderHeaderUnfixed = () => {
            return (
                <div className="Quotas-List__Header">
                    <div className="Col-50">Название</div>
                    <div className="Col-25 Col-25--center" />
                    <div className="Col-25 Col-25--center">Количество</div>
                </div>
            );
        };

        const renderHeaderFixed = () => {
            return (
                <div className="Quotas-List__Header">
                    <div className="Col-40">Название</div>
                    <div className="Col-25 Col-25--center">Распределено</div>
                    <div className="Col-35 Col-35--center">Остаток</div>
                </div>
            );
        };

        const renderFooterUnfixed = () => {
            return (
                <div className="Quotas-List__Footer">
                    <div className="Col-50">Общее количество</div>
                    {this.renderErrorText(errorAbove, errorBelow)}
                    <div
                        className={`Col-25 Col-25--center ${
                            errorAbove ? 'Error-Text' : 'Blue-Color'
                        }`}
                    >
                        {totalAllocationAmount}
                    </div>
                </div>
            );
        };

        const renderFooterFixed = () => {
            const totalBalanceAmount = Object.values(quotas.groupQuotaPointBalances).reduce(
                (acc, v) => acc + v,
            );
            
            const totalAllocationAmountCounted = quotas.allocations.map(x => +x.amount).reduce((acc, v) => acc + v, 0);

            return (
                <div className="Quotas-List__Footer">
                    <div className="Col-40">Общее количество</div>
                    <div className="Col-25 Col-25--center">{totalAllocationAmountCounted}</div>
                    <div className="Col-35 Col-35--center">{totalBalanceAmount}</div>
                </div>
            );
        };

        return (
            <div className="Quotas-Container__Body">
                <div className="Quotas-List">
                    {this.props.fixed ? renderHeaderFixed() : renderHeaderUnfixed()}
                    <div className="Quotas-List__Body">{this.renderQuotas()}</div>
                    {this.props.fixed ? renderFooterFixed() : renderFooterUnfixed()}
                </div>
            </div>
        );
    };

    renderErrorText = (errorAbove, errorBelow) => {
        if (errorAbove || errorBelow) {
            return errorAbove ? (
                <div className="Col-25 Col-25--center Error-Text">{errorAbove}</div>
            ) : (
                <div className="Col-25 Col-25--center Blue-Color">{errorBelow}</div>
            );
        }
    };

    renderCorrectionNumber = correction => {
        if (correction === 0) {
            return null;
        }
        if (correction > 0) {
            return <span className="Correction-Plus">(+{correction})</span>;
        }
        return <span className="Correction-Minus">({correction})</span>;
    };

    renderQuotas = () => {
        const { quotas, selectedQuotaId } = this.state;
        const { isNew } = this.props;
        const groups = isNew
            ? this.getSelectedQuota(quotas, selectedQuotaId).newQuotaInfo.toGroups
            : quotas.allocations;

        const renderAllocationUnfixed = allocation => {
            return (
                <div className="Line" key={allocation.id}>
                    <div className="Col-50">
                        {isNew ? allocation.title : allocation.toGroup.title}
                    </div>
                    <div className="Col-25 Col-25--center" />
                    <div className="Col-25 Col-25--center">
                        <Field filled={this.isInputFilled(allocation.amount)}>
                            <Input
                                value={allocation.amount}
                                type="number"
                                min={0}
                                onChange={e =>
                                    this.handleAmountChange(
                                        selectedQuotaId,
                                        allocation.id,
                                        e.target.value,
                                        !!isNew,
                                    )
                                }
                            />
                        </Field>
                    </div>
                </div>
            );
        };

        const renderAllocationFixed = allocation => {
            const renderBalanceView = () => {
                const correction = quotas.groupTotalCorrections[allocation.toGroup.id];

                return (
                    <div className="Col-35 Col-35--center">
                        {quotas.groupQuotaPointBalances[allocation.toGroup.id]}{' '}
                        {this.renderCorrectionNumber(correction)}
                    </div>
                );
            };

            const renderBalanceEdit = () => {
                const balanceInputId = `${allocation.id}_balance`;
                const correctionInputId = `${allocation.id}_correction`;

                let correctionInputClass = '';
                let correction = this.state.correctionEdit.corrections[allocation.toGroup.id];
                const showMinusSign =
                    this.state.correctionEdit.showMinusSign[allocation.toGroup.id] || false;

                if (correction > 0) {
                    correctionInputClass = 'Correction-Plus';
                    correction = `+${correction}`;
                } else if (correction < 0) {
                    correctionInputClass = 'Correction-Minus';
                } else if (showMinusSign) {
                    correctionInputClass = 'Correction-Minus';
                    correction = '-';
                }

                const balance = this.state.correctionEdit.balances[allocation.toGroup.id];
                const balanceInputClass = balance >= 0 ? '' : 'Correction-Minus';

                return (
                    <div className="Col-35 Col-35--center CorrectionEdit">
                        <Field
                            className="CorrectionEdit-BalanceField"
                            filled={this.isInputFilled(balance)}
                        >
                            <Label htmlFor={balanceInputId}>Баланс</Label>
                            <Input
                                id={balanceInputId}
                                className={balanceInputClass}
                                value={parseInt(balance) * 1}
                                disabled={allocation.toGroup.id === this.state.quotas.fromGroup.id}
                                onChange={e =>
                                    this.handleBalanceChange(allocation.toGroup.id, e.target.value)
                                }
                            />
                        </Field>
                        <Field
                            className="CorrectionEdit-CorrectionField"
                            filled={this.isInputFilled(correction)}
                        >
                            <Label htmlFor={balanceInputId}>Корректировка</Label>
                            <Input
                                id={correctionInputId}
                                className={correctionInputClass}
                                value={correction}
                                type="text"
                                pattern="[-+]?\d+"
                                disabled={allocation.toGroup.id === this.state.quotas.fromGroup.id}
                                onChange={e =>
                                    this.handleCorrectionChange(
                                        allocation.toGroup.id,
                                        e.target.value,
                                    )
                                }
                            />
                        </Field>
                    </div>
                );
            };

            return (
                <div className="Line" key={allocation.id}>
                    <div className="Col-40">
                        {isNew ? allocation.title : allocation.toGroup.title}
                    </div>
                    <div className="Col-25 Col-25--center">{allocation.amount}</div>
                    {this.state.status === quotaStatuses.correcting ? renderBalanceEdit() : renderBalanceView()}
                </div>
            );
        };

        const renderAllocation = allocation =>
            this.props.fixed
                ? renderAllocationFixed(allocation)
                : renderAllocationUnfixed(allocation);

        return groups.map(renderAllocation);
    };

    render() {
        const { isNew } = this.props;
        const { selectedQuotaId, quotas } = this.state;
        const isSelected = isNew ? selectedQuotaId !== null : quotas.length !== 0;
        return (
            <div className="Quotas-Container">
                {this.renderHeader()}
                {isSelected && this.renderBody()}
                {isSelected && this.renderFooter()}
            </div>
        );
    }

    componentDidMount() {
        this.loadQuotas(this.props.isNew);
    }

    loadQuotas = async isNew => {
        try {
            const quotas = isNew
                ? await this.callEffect(() => getQuotasInfo(false))
                : await this.callEffect(() => getQuotaById(this.props.id));

            if (Array.isArray(quotas.data)) {
                quotas.data = quotas.data.map((x, index) => ({ ...x, quotaId: index }));
            } else {
                quotas.data.quotaId = 0;
            }
            
            quotas &&
                this.setState(state => {
                    return {
                        ...initalState,
                        ...!isNew && {status: quotas.data.status},
                        quotas: isNew ? this.getEditableQuotas(quotas.data) : quotas.data,
                        allAmount: isNew ? state.allAmount : quotas.data.totalAmount,
                    };
                });
        } catch (error) {
            this.props.showErrorAlert('При загрузке данных произошла ошибка');
        }
    };

    getEditableQuotas = data => {
        return this.copyNewQuotasArray(
            data.filter(x => x.status !== 'QuotaNotAvailable' && x.status !== 'QuotaExists'),
        );
    };

    callEffect = async callback => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            const err = getError(error, this.getTestError());
            this.props.showErrorAlert(err.message);
        } finally {
            this.props.hidePageLoader();
        }
    };
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    };
};

const actions = {
    push,
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(
    mapStateToProps,
    actions,
)(EditQuotas);
