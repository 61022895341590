import React from 'react';
import { attributes } from "./constants";
import WishlistVisibilityAttribute from './WishlistVisibilityAttribute';
import classnames from "classnames";
import './WishlistAttributeSelector.scss';


const WishlistAttributeList = props => {
    const { attributes, ...tail} = props;
    
    return attributes.map(attr => 
        <WishlistVisibilityAttribute 
            key={attr.value} 
            value={attr.value} 
            attribute={attr.attribute} 
            footer={attr.footer} 
            {...tail} 
        />);
};

const WishlistAttributeSelector = props => {
    
    const visibilityOptions = [
        { value: 'Общая', attribute: attributes.everyone,  footer: 'Видна всем' },
        { value: 'Групповая', attribute: attributes.group,  footer: 'Видна только участникам группы' },
        { value: 'Персональная', attribute: attributes.user,  footer: 'Видна только этому пользователю' },
    ];
    
    const { disabled } = props; 
    
    return (
        <div className={classnames('LKWishlistAttributeSelector', {'LKWishlistAttributeSelector--disabled' : disabled})}>
            <div className='LKWishlistAttributeSelector__Item LKWishlistAttributeSelector__Item--header'>
                Атрибуты видимости
            </div>
            <WishlistAttributeList attributes={visibilityOptions} {...props} />
        </div>
    );
};

export default WishlistAttributeSelector;