import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import EvaluationPerson from 'components/Lk/Shared/Evaluate/EvaluationPerson';
import EvaluationPersonData from 'components/Lk/Shared/Evaluate/EvaluationPerson/EvaluationPersonData';
import EvaluationCardItemBlock from 'components/Lk/Shared/Evaluate/EvaluationCardItemBlock';
import { fetchTeams } from 'ducks/Teams';
import { changeBasket, basketActive } from 'ducks/Baskets';
import { fetchEvaluation, setSelectes } from 'ducks/Evaluation';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { fetchPerson } from 'ducks/Person';
import { getWishListsWithPersonUsers, getPersonsForWishlist } from 'api';
import { getUserShortName, getUserFullName } from 'utils';
import Loader from 'components/common/Loader';
import Teams from 'components/Lk/Shared/Teams/Teams';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import Search from './Search';
import WishList from './WishList';
import { mainCardMenuItems, TEAM_TYPE, WISHLIST_TYPE, SEARCH_TYPE } from './constants';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import { USER_ROLES } from 'constants.js';
import classnames from 'classnames';
import './SelectImplementer.scss';

const SelectImplementer = props => {
    const cartName = 'newIssue';
    const {
        personId,
        fetchTeams,
        storeBasket,
        storeTeams,
        authUser,
        basketActive,
        implementerType,
        setImplementerType,
    } = props;
    const [teams, setTeams] = useState({});
    const [wishlists, setWishlists] = useState(null);
    const [person, setPerson] = useState({});
    const [tempBasket, setTempBasket] = useState({});
    const [isPersonsLoading, setPersonsLoading] = useState({});
    const [isWishlistOpenLoading, setWishlistOpenLoading] = useState(false);
    const [isSearchFocused, setSerchFocused] = useState(false);

    const onMenuClick = menu => {
        setImplementerType(menu.id);
    };

    const loadPersons = (wishListId, isWishlistOpen) => async () => {
        setPersonsLoading({ ...isPersonsLoading, [wishListId]: true });
        isWishlistOpen && setWishlistOpenLoading(true);

        const response = await getPersonsForWishlist(wishListId);
        const persons = response.data;
        updateWishLists(wishListId, persons, wishlists);

        setPersonsLoading({ ...isPersonsLoading, [wishListId]: false });
        isWishlistOpen && setWishlistOpenLoading(false);
    };

    const updateWishLists = (wishListId, persons, wishlists) => {
        const newWishLists = wishlists?.map(x => {
            return {
                ...x,
                persons: x.id === wishListId ? persons : x.persons || [],
                type: x.type,
            };
        });

        setWishlists(newWishLists);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                showPageLoader();
                if (
                    storeTeams.leading.length === 0 &&
                    !storeTeams.loading &&
                    !storeTeams.isLoaded
                ) {
                    fetchTeams(personId);
                }
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchData();
    }, [fetchTeams, personId, storeTeams]);

    useEffect(() => {
        const load = async () => {
            const res = await getWishListsWithPersonUsers();
            setWishlists(res.data);
        };

        if (implementerType === WISHLIST_TYPE && (!wishlists || wishlists.length === 0)) {
            load();
        }
    }, [implementerType, wishlists]);

    useEffect(() => {
        setTeams(storeTeams);
    }, [storeTeams]);

    useEffect(() => {
        const curator = authUser.curator && {
            ...authUser.curator,
            userId: authUser.curator.id,
        };

        const result = {
            ...authUser,
            id: authUser.personId,
            userId: authUser.id,
            curator,
        };
        setPerson(result);
    }, [authUser]);

    useEffect(() => {
        setTempBasket(storeBasket);
        basketActive(cartName, false);
        return () => {
            basketActive(cartName, true);
        };
    }, [storeBasket, basketActive]);

    const onSelectPerson = (id, item) => e => {
        e.stopPropagation();
        e.preventDefault();

        const isOwn = (tempBasket?.userId || []).indexOf(item.userId) >= 0;
        let result = {};
        if (!isOwn) {
            result = {
                ...tempBasket,
                persons: [
                    ...tempBasket.persons,
                    {
                        person: item,
                        type: implementerType,
                    },
                ],
                userId: [...tempBasket.userId, item.userId],
            };
        } else {
            result = {
                ...tempBasket,
                persons: tempBasket.persons.filter(p => p.person.userId !== item.userId),
                userId: tempBasket.userId.filter(x => x !== item.userId),
            };
        }
        setTempBasket(result);
    };

    const onCheckAll = (persons, isCheck, itemId) => {
        const currentUserIds = persons.map(x => x.userId);

        const wishlist = wishlists && wishlists.find(x => x.id === itemId);
        if (wishlist) {
            (!wishlist.persons || (wishlist.persons && wishlist.persons.length === 0)) &&
                loadPersons(itemId)();
        }

        const result = {
            ...tempBasket,
            persons: isCheck
                ? tempBasket.persons
                      .filter(x => currentUserIds.indexOf(x.person.userId) === -1)
                      .concat(
                          persons
                              .filter(x => x.userId)
                              .map(x => ({ person: x, type: implementerType })),
                      )
                : tempBasket.persons.filter(x => currentUserIds.indexOf(x.person.userId) === -1),
            userId: isCheck
                ? tempBasket.userId
                      .filter(x => currentUserIds.indexOf(x) === -1)
                      .concat(currentUserIds)
                      .filter(x => !!x)
                : tempBasket.userId.filter(x => currentUserIds.indexOf(x) === -1).filter(x => !!x),
        };

        setTempBasket(result);
    };

    const personClick = {
        onChange: onSelectPerson,
        onClick: onSelectPerson,
        onImageClick: onSelectPerson,
    };

    const onSubmit = () => {
        let personsFull = wishlists
            ?.filter(x => x.persons)
            ?.reduce((arr, wishlist) => arr.concat(wishlist.persons), [])
            ?.filter(x => x.userId);

        tempBasket.persons = tempBasket.persons.map(x => {
            const person = personsFull?.find(i => i.id === x.person.personId);
            return {
                ...x,
                person: person ? person : x.person,
            };
        });

        props.changeBasket(cartName, tempBasket);
        basketActive(cartName, true);
        props.onSubmit();
    };

    const onCancel = () => {
        basketActive(cartName, true);
        props.onCancel();
    };

    const renderTeams = () => (
        <>
            {(!teams || !teams.leading || !person || !person.id) && <Loader />}
            <div className="SelectImplementer__Curator">
                {person.id && (
                    <EvaluationCardItemBlock shadow>
                        <EvaluationPerson
                            basket={tempBasket}
                            {...personClick}
                            item={person}
                            alt={getUserFullName(person)}
                            checkbox
                            dataTest="SelectImplementer_CheckPerson--checkbox"
                            isUser
                        >
                            <EvaluationPersonData
                                data={getUserShortName(person)}
                                position={person.currentPosition}
                                roles={person.roles?.map(x => x.title)}
                            />
                        </EvaluationPerson>
                    </EvaluationCardItemBlock>
                )}
                {person.curator && (
                    <>
                        <EvaluationCardItemBlock shadow>
                            <EvaluationPerson
                                basket={tempBasket}
                                {...personClick}
                                item={person.curator}
                                alt={getUserFullName(person.curator)}
                                checkbox
                                isUser
                                dataTest="SelectImplementer_CheckCurator--checkbox"
                            >
                                <EvaluationPersonData
                                    data={getUserShortName(person.curator)}
                                    position="Эксперт по управлению талантами"
                                    roles={person.curator.roleIds
                                        ?.map(roleId => USER_ROLES.find(i => i.id === roleId)?.name)
                                        .filter(x => x)}
                                />
                            </EvaluationPerson>
                        </EvaluationCardItemBlock>
                    </>
                )}
            </div>
            <Teams
                {...props}
                personId={props.personId}
                onPersonSelect={onSelectPerson}
                withRegistration
                activeCard={ACTIVE_DETAIL_CARD}
                onCheckAll={onCheckAll}
                selectedIds={tempBasket?.userId?.map(x => x)}
                dataTest="SelectImplementer_CheckTeamPersons"
                isUser
                withCheckboxes
            />
        </>
    );

    const renderWishList = () => {
        return (
            <>
                {!wishlists && <Loader />}
                {wishlists && (
                    <WishList
                        items={wishlists}
                        tempBasket={tempBasket}
                        personClick={personClick}
                        selectedIds={tempBasket?.userId?.map(x => x)}
                        onCheckAll={onCheckAll}
                        withCheckboxes
                        loadPersons={loadPersons}
                        isPersonsLoading={isPersonsLoading}
                        dataTest="SelectImplementer"
                    />
                )}
            </>
        );
    };

    const isPersonsRequestsLoading = () => {
        return Object.keys(isPersonsLoading).some(wishlistId => isPersonsLoading[wishlistId]);
    };

    const renderItems = () => {
        switch (implementerType) {
            case TEAM_TYPE:
            default:
                return renderTeams();
            case WISHLIST_TYPE:
                return renderWishList();
            case SEARCH_TYPE:
                return (
                    <Search
                        tempBasket={tempBasket}
                        personClick={personClick}
                        dataTest="SelectImplementer"
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                        isPersonsRequestsLoading={isPersonsRequestsLoading}
                        setSerchFocused={setSerchFocused}
                    />
                );
        }
    };
    
    return (
        <div className="SelectImplementer" id="SelectImplementer">
            <MainCardMenu
                main
                linkMenu={false}
                onClick={onMenuClick}
                activeId={implementerType}
                menuItems={mainCardMenuItems}
            />

            <div className={classnames("SelectImplementer__Container", {"SelectImplementer__Container--focused": isSearchFocused})}>
                <h3 className="LKLayout__Title">Выбрать исполнителя</h3>
                {renderItems()}
            </div>
            {isPersonsRequestsLoading() && !isWishlistOpenLoading && <Loader absolute overlay />}
            {  implementerType !== SEARCH_TYPE &&
                <StickyFooter>
                    <div className="SelectImplementer__Footer">
                        <RoundedButton
                            modifier="withoutPaddingBottom"
                            onClick={onSubmit}
                            disabled={tempBasket?.userId?.length === 0 || isPersonsRequestsLoading()}
                            data-test="SelectImplementer_Select--button"
                        >
                            Применить
                        </RoundedButton>
                        <RoundedButton
                            color="transparent"
                            onClick={onCancel}
                            data-test="SelectImplementer_Cancel--button"
                        >
                            Отменить
                        </RoundedButton>
                    </div>
                </StickyFooter>
            }
        </div>
    );
};

SelectImplementer.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const mapStateToProps = store => ({
    personId: store.auth.user.personId,
    authUser: store.auth.user,
    storePerson: store.person,
    storeTeams: store.teams,
    preselectionBasket: store.baskets.preselectionNewIssue.basket,
    storeBasket: store.baskets.newIssue.basket,
});
const actions = {
    fetchTeams,
    fetchEvaluation,
    changeBasket,
    basketActive,
    setSelectes,
    push,
    fetchPerson,
};

export default connect(
    mapStateToProps,
    actions,
)(SelectImplementer);