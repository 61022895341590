import React from 'react';
import classnames from 'classnames';

const SCALE_VALUES = [
    {
        id: 1,
        percent: { from: 0, to: 29 }, //  '0 - 29%',
        type: 'low',
        compitencies: 'низкий уровень проявленности компетенции в поведении резервиста',
        abilities: 'низкий уровень',
        managerials:
            'значение ниже нормативного, характеристика проявляется у резервиста меньше, чем у большинства других',
    },
    {
        id: 2,
        percent: { from: 30, to: 69 }, // '30 - 69%',
        type: 'medium',
        compitencies: 'средний уровень проявленности компетенции в поведении резервиста',
        abilities: 'средний уровень',
        managerials:
            'нормативное значение, характеристика проявляется у резервиста на том же уровне, что и у большинства других',
    },
    {
        id: 3,
        percent: { from: 70, to: 100 }, // '70 - 100%',
        type: 'high',
        compitencies: 'высокий уровень проявленности компетенции в поведении резервиста',
        abilities: 'высокий уровень',
        managerials:
            'значение выше нормативного, характеристика проявляется у резервиста больше, чем у большинства других ',
    },
];

const ScaleTooltip = props => {
    const { type = 'abilities', withDivider = false } = props;
    return (
        <div className={classnames("", {"ScaleTooltip--with-divider": withDivider})}>
            {SCALE_VALUES.map(item => (
                <div
                    key={item.id}
                    className="ScaleTooltip"
                >
                    <div className="ScaleTooltip__WrapIndicator">
                    <div
                        className={`ScaleTooltip__Indicator ScaleTooltip__Indicator--${item.type}`}
                    ></div>
                    <div
                        className={classnames(
                            'ScaleTooltip__Percent',
                            `ScaleTooltip__Percent--${item.type}`,
                        )}
                    >
                        {item.percent.from}&nbsp;&mdash;&nbsp;{item.percent.to}
                    </div>
                    </div>
                    <div className="ScaleTooltip__Description">{item[type]}</div>
                </div>
            ))}
        </div>
    );
};

export default ScaleTooltip;
