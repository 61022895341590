import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { lkDetailsProfilePersonsWithoutResults } from 'routes';
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/common/Loader';
import classnames from 'classnames';
import ModalSummaryCard from 'components/Lk/Common/ModalPersonSummaryCard';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemPerson from 'components/Lk/Common/CardItemPerson';
import CardItemPersonData from 'components/Lk/Common/CardItemPerson/CardItemPersonData';
import { getUserFullName, wordForm, formatPrice } from 'utils';

import {
    getDashboardPersonsWithoutResultSelect,
} from 'api';
import { fetchPersonWithoutResultAmount } from 'ducks/Dashboard';

const initialState = {
    data: [],
    hasMore: true,
    paging: { pageNum: 1, pageSize: 10 },
    meta: {
        pageCount: 0,
    },
    isLoading: false,
    isSummaryCardOpen: false,
    summaryPersonId: null,
};

class PersonsWithoutResults extends Component {
    _isMounted = false;
    state = initialState;

    fetchSearch = async () => {
        this._isMounted &&
            this.setState({
                isLoading: true,
            });

        this.props.fetchPersonWithoutResultAmount();
        const result = await getDashboardPersonsWithoutResultSelect({
            paging: {
                ...this.state.paging,
            },
        });

        this._isMounted &&
            this.setState((state) => ({
                data: [...state.data, ...result.data.payload],
                meta: { ...result.data.meta },
                isLoading: false,
            }));
    };

    handleLoadMore = () => {
        this._isMounted &&
            this.setState((state) => ({
                hasMore: state.paging.pageNum + 1 < state.meta.pageCount,
                paging: {
                    ...state.paging,
                    pageNum:
                        state.paging.pageNum + 1 < state.meta.pageCount
                            ? state.paging.pageNum + 1
                            : state.paging.pageNum,
                },
            }));
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchSearch();
    }

    componentDidUpdate(pervProps, prevState) {
        if (prevState.paging.pageNum !== this.state.paging.pageNum) {
            this.fetchSearch();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleCardClick = (personId) => () => {
        this._isMounted &&
            this.setState({
                summaryPersonId: personId,
                isSummaryCardOpen: true,
            });
    };

    closeSummaryCard = () => {
        this._isMounted && this.setState({ isSummaryCardOpen: false });
    };

    renderSearchResults = () => {
        const { withoutEvaluation } = this.props;
        return (
            <div className="LKFavoriteResultsWrapper">
                {withoutEvaluation && withoutEvaluation.amount!=null && (
                    <h3 className="LKLayout__Title">
                        {`У ${formatPrice(
                            withoutEvaluation.amount,
                        )} (${withoutEvaluation.percent.toFixed(
                            2,
                        )}%) ${wordForm(withoutEvaluation.amount, [
                            'резервиста',
                            'резервистов',
                            'резервистов',
                        ])}  нет ни одного результата оценки`}
                    </h3>
                )}
                {this.state.data.map((x) => {
                    return (
                        <CardItemBlock key={x.id}>
                            <CardItemPerson
                                person={x}
                                clickable
                                onCardClick={this.handleCardClick(x.id)}
                            >
                                <CardItemPersonData
                                    normalText
                                    data={getUserFullName(x)}
                                    position={x.currentPosition}
                                />
                            </CardItemPerson>
                        </CardItemBlock>
                    );
                })}
            </div>
        );
    };

    render() {
        const { activeCard, setActiveCard } = this.props;
        const { isLoading, hasMore, summaryPersonId, isSummaryCardOpen, data } = this.state;

        const isMobileActive = activeCard === ACTIVE_LIST_CARD;
        setActiveCard(ACTIVE_LIST_CARD);

        return (
            <>
                <div
                    className={classnames('ListCard LKSearchCard', {
                        'ListCard--isMobileActive': isMobileActive,
                        'LKSearchCard--isMobileActive': isMobileActive,
                    })}
                >
                    <MainCardMenu defaultMenuId="favorite" />
                    <div className="LKSearchCard__List">
                        <InfiniteScroll
                            pageStart={1}
                            loadMore={this.handleLoadMore}
                            hasMore={hasMore}
                            initialLoad={false}
                            useWindow={false}
                        >
                            {this.renderSearchResults()}
                            {isLoading && <Loader />}
                        </InfiniteScroll>
                    </div>
                </div>
                {summaryPersonId && (
                    <ModalSummaryCard
                        isOpen={isSummaryCardOpen}
                        onClose={this.closeSummaryCard}
                        person={data.find((x) => x.id === summaryPersonId)}
                        profileRoute={lkDetailsProfilePersonsWithoutResults}
                    />
                )}
            </>
        );
    }
}

PersonsWithoutResults.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const actions = { push, fetchPersonWithoutResultAmount };

const mapStateToProps = ({ dashboard }) => ({
    withoutEvaluation: dashboard.withoutEvaluation,
});

export default connect(mapStateToProps, actions)(PersonsWithoutResults);
