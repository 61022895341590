import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showErrorAlert } from 'ducks/Alert';
import { searchContentCategories, getContentCategories, updateTreePositions } from 'api';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WrappedCard from 'components/Lk/Uikit/WrappedCard';

const moveErrorCode = 1411;
class TreeNode extends Component {
    state = {
        childsLoading: false,
        draggingItem: null,
    };

    _mounted = true;

    fetchChildren = (parentId, removeChildren = false) => async (e) => {
        const {
            fullSearch,
            centerGroupId,
            currentUser: { groupId },
        } = this.props;
        try {
            e && e.stopPropagation();
            this.setState({ children: [], childsLoading: true });

            const children = !removeChildren
                ? fullSearch
                    ? await searchContentCategories({
                          parentId,
                          ...(groupId === centerGroupId ? {} : { groupIds: [groupId] }),
                      })
                    : await getContentCategories(parentId)
                : [];
            const childrenData = !removeChildren ? children.data : children;
            this.props.updateTreeData(parentId, childrenData);
        } catch (e) {
            this.props.showErrorAlert('Ошибка получения данных');
        } finally {
            this._mounted && this.setState({ childsLoading: false });
        }
    };

    generateItemIcon = () => {
        const { item } = this.props;
        const { childsLoading } = this.state;
        let icon = '';
        if (childsLoading) {
            icon = 'spinner';
        } else {
            icon = !item.expanded ? 'chevron-circle-right' : 'chevron-circle-down';
        }

        return <FontAwesomeIcon spin={childsLoading} icon={icon} color="#368ef1" />;
    };

    handleDropZoneToggle = (className, checkDropZone = false) => (e) => {
        e.stopPropagation();
        const { draggingItem } = this.state;
        const item = JSON.parse(draggingItem);
        const target = e.currentTarget;

        checkDropZone
            ? target?.dataset?.iscategory &&
              item.isArticle &&
              e.target.dataset.itemid !== item.parentId &&
              e.currentTarget.classList &&
              e.currentTarget.classList.toggle(className)
            : e.target.classList && e.target.classList.toggle(className);
    };

    handleDragStart = (e) => {
        this.updateDropZonesHeight(10, e.target.dataset.item);
        this.setState({ draggingItem: e.target.dataset.item });
        e.target.style.opacity = '0.3';
        const data = {
            item: e?.target?.dataset?.item,
        };
        e.dataTransfer.setData('text', JSON.stringify(data));
    };

    handleDragEnd = (e) => {
        this.updateDropZonesHeight(0);
        e.target.style.opacity = '1';
        e.dataTransfer.items.clear();
        this._mounted && this.setState({ draggingItem: null });
    };

    updateDropZonesHeight = (height, item) => {
        const isArticle = item && JSON.parse(item).isArticle;
        const itemId = item && JSON.parse(item).id;
        const nodes = document.querySelectorAll('.TreeNode__UpDivider, .TreeNode__DownDivider');
        let prevNodeId = '';
        for (const i of nodes) {
            const isNodeArticle = i.dataset.isarticle === 'true' ? true : false;
            const nodeItemId = i.dataset.itemid;
            const expand =
                isNodeArticle === isArticle && itemId !== nodeItemId && itemId !== prevNodeId;

            i.style.height = `${expand ? height : 0}px`;
            if (height === 0 || !expand) {
                i.style.borderWidth = '0';
            } else {
                i.style.borderWidth = '0.5px';
            }
            prevNodeId = nodeItemId;
        }
    };

    handleDragOver = (e) => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        return false;
    };

    handleDrop = async (e) => {
        try {
            if (e.stopPropagation) {
                e.stopPropagation();
                e.preventDefault();
            }

            e.target.classList.remove('TreeNode__UpDivider--over');
            e.target.classList.remove('TreeNode__DownDivider--over');
            e.currentTarget.classList.remove('TreeNode__Title--over');

            let newIndex = -1,
                newParentId = null;

            const moveIntoCategory = e.currentTarget.classList.contains('TreeNode__Title');

            if (
                (e.target.classList &&
                    (e.target.classList.contains('TreeNode__UpDivider') ||
                        e.target.classList.contains('TreeNode__DownDivider'))) ||
                moveIntoCategory
            ) {
                const item = JSON.parse(JSON.parse(e.dataTransfer.getData('text')).item);

                newIndex = moveIntoCategory ? 0 : e.target.dataset.position;
                newParentId = moveIntoCategory
                    ? e.currentTarget.dataset.itemid
                    : e.target.dataset.parentid || null;

                if (newParentId === item.parentId && newIndex > item.position) {
                    newIndex--;
                }

                newParentId
                    ? await this.props.refreshBranchData(newParentId, () =>
                          this.updateTree(item, +newIndex, newParentId),
                      )
                    : await this.props.refreshRoot(() =>
                          this.updateTree(item, +newIndex, newParentId),
                      );
            }
        } catch (e) {
            this.props.showErrorAlert('Ошибка перемещения');
        }
    };

    updateTree = async (item, newIndex, newParentId) => {
        const res = await this.updateBranch(newParentId, item, newIndex);
        if (item.parentId !== newParentId && res) {
            await this.props.removeItemFromParent(null, item, newParentId);
        }
    };

    updateBranch = async (newParentId, item, newIndex) => {
        const {
            centerGroupId,
            currentUser: { groupId },
        } = this.props;
        try {
            const branchData = await searchContentCategories({
                parentId: newParentId,
                ...(groupId === centerGroupId ? {} : { groupIds: [groupId] }),
            });
            const newItem = branchData.data.find((x) => x.id === item.id);
            let newBranch = branchData.data.filter((x) => x.id !== item.id);
            const finalItem = newItem || item;
            newBranch.splice(newIndex, 0, finalItem);
            newBranch = newBranch.map((x, index) => ({
                ...x,
                parentId: newParentId,
                position: index,
            }));
            await updateTreePositions(newBranch);
            this.props.onItemSelect({
                ...finalItem,
                position: +newIndex,
                parentId: newParentId,
                itemsInCategory: newBranch.length,
            });
            return true;
        } catch (e) {
            this.handleError(e.response.data.code);
            return false;
        }
    };

    handleError = (errorCode) => {
        switch (errorCode) {
            case moveErrorCode:
                const modalHeader = `Ошибка перемещения`;
                const bodyText = (
                    <div style={{ padding: '20px 0' }}>
                        Невозможно переместить инструкцию, т.к. для нее будет очищено обязательное
                        поле «Группа».
                    </div>
                );
                this.props.setModal(modalHeader, undefined, true, true, bodyText);
                return;
            default:
                this.props.showErrorAlert('Ошибка перемещения');
                return;
        }
    };

    componentDidMount() {
        const nodes = document.querySelectorAll('.TreeNode');
        if (this.props.editMode) {
            for (const i of nodes) {
                i.addEventListener('dragstart', this.handleDragStart, false);
                i.addEventListener('dragend', this.handleDragEnd, false);
            }
        }
    }

    componentWillUnmount() {
        this._mounted = false;
        if (this.props.editMode) {
            const nodes = document.querySelectorAll('.TreeNode');
            for (const i of nodes) {
                i.removeEventListener('dragstart', this.handleDragStart);
                i.removeEventListener('dragend', this.handleDragEnd);
            }
        }
    }

    render() {
        const {
            item,
            onItemSelect,
            selectedItem,
            selectedId,
            active,
            lastNode,
            editMode,
        } = this.props;
        const { draggingItem } = this.state;
        const draggingObj = JSON.parse(draggingItem);
        const canDropInCategory =
            draggingObj &&
            draggingObj.isArticle &&
            !item.isArticle &&
            item.id !== draggingObj.parentId;

        return !editMode ? (
            <WrappedCard
                cardOpen={item.expanded && !item.isArticle}
                title={item.title}
                onTitleClick={() => onItemSelect(item)}
                toggleCallback={
                    item.hasChildren ? this.fetchChildren(item.id, item.expanded) : undefined
                }
                loading={this.state.childsLoading}
            >
                {item.expanded
                    ? item.children
                          .filter((x) => x.isArticle)
                          .map((el) => (
                              <div
                                  key={el.id}
                                  className={classnames('TreeNode__ViewArticle', {
                                      'TreeNode__ViewArticle--selected':
                                          (selectedItem && selectedItem.id === el.id) ||
                                          (selectedId && el.id === selectedId),
                                  })}
                                  onClick={() => onItemSelect(el)}
                              >
                                  {el.title}
                              </div>
                          ))
                    : null}
            </WrappedCard>
        ) : (
            <div
                className={classnames('TreeNode', { 'TreeNode--inactive': !active })}
                draggable={editMode}
                data-item={JSON.stringify(item)}
                id={item.id}
            >
                {((!item.parentId && !item.isArticle) || (item.parentId && item.isArticle)) && (
                    <div
                        className="TreeNode__UpDivider"
                        onDragEnter={this.handleDropZoneToggle('TreeNode__UpDivider--over')}
                        onDragLeave={this.handleDropZoneToggle('TreeNode__UpDivider--over')}
                        onDragOver={this.handleDragOver}
                        onDrop={this.handleDrop}
                        data-position={item.position}
                        data-parentid={item.parentId}
                        data-isarticle={item.isArticle || ''}
                        data-itemid={item.id}
                        data-groups={item.groupIds}
                        data-roles={item.roleIds}
                    />
                )}
                <div
                    className={classnames('TreeNode__Title', {
                        'TreeNode__Title--selected':
                            (selectedItem && selectedItem.id === item.id) ||
                            (selectedId && item.id === selectedId),
                    })}
                    onClick={() => onItemSelect(item)}
                    onDragEnter={
                        canDropInCategory
                            ? this.handleDropZoneToggle('TreeNode__Title--over', true)
                            : undefined
                    }
                    onDragLeave={
                        canDropInCategory
                            ? this.handleDropZoneToggle('TreeNode__Title--over', true)
                            : undefined
                    }
                    onDragOver={canDropInCategory ? this.handleDragOver : undefined}
                    onDrop={canDropInCategory ? this.handleDrop : undefined}
                    data-iscategory={!item.isArticle || ''}
                    data-itemid={!item.isArticle ? item.id : ''}
                    data-groups={item.groupIds}
                    data-roles={item.roleIds}
                >
                    <div
                        className="TreeNode__More"
                        onClick={
                            item.hasChildren
                                ? this.fetchChildren(item.id, item.expanded)
                                : undefined
                        }
                    >
                        {item.hasChildren ? this.generateItemIcon() : ''}
                    </div>
                    <div className="TreeNode__Icon">
                        <FontAwesomeIcon
                            icon={
                                item.isArticle
                                    ? 'file-alt'
                                    : item.expanded
                                    ? 'folder-open'
                                    : 'folder'
                            }
                            color={active ? '#368ef1' : '#000'}
                        />
                    </div>
                    <div
                        className={classnames('TreeNode__Text', {
                            'TreeNode__Text--article': item.isArticle,
                        })}
                    >
                        {item.title}
                    </div>
                </div>
                <div className="TreeNode__Children">
                    {item.expanded
                        ? item.children
                              .filter((x) => x.isArticle)
                              .map((el, index) => (
                                  <TreeNode
                                      key={el.id}
                                      item={{
                                          ...el,
                                          itemsInCategory: item.children.length,
                                          position: index,
                                      }}
                                      selectedId={selectedId}
                                      active={el.status === 'Published' || !el.isArticle}
                                      lastNode={item.children.length - 1 === index}
                                      onItemSelect={this.props.onItemSelect}
                                      updateTreeData={this.props.updateTreeData}
                                      selectedItem={this.props.selectedItem}
                                      fullSearch={this.props.fullSearch}
                                      centerGroupId={this.props.centerGroupId}
                                      currentUser={this.props.currentUser}
                                      showErrorAlert={this.props.showErrorAlert}
                                      refreshBranchData={this.props.refreshBranchData}
                                      refreshRoot={this.props.refreshRoot}
                                      treeData={this.props.treeData}
                                      removeItemFromParent={this.props.removeItemFromParent}
                                      setModal={this.props.setModal}
                                      editMode={this.props.editMode}
                                  />
                              ))
                        : null}
                </div>
                {((!item.parentId && !item.isArticle) || (item.parentId && item.isArticle)) &&
                    lastNode && (
                        <div
                            className="TreeNode__DownDivider"
                            onDragEnter={this.handleDropZoneToggle('TreeNode__DownDivider--over')}
                            onDragLeave={this.handleDropZoneToggle('TreeNode__DownDivider--over')}
                            onDragOver={this.handleDragOver}
                            onDrop={this.handleDrop}
                            data-position={item.position + 1}
                            data-parentid={item.parentId}
                            data-isarticle={item.isArticle}
                            data-itemid={item.id}
                            data-groups={item.groupIds}
                            data-roles={item.roleIds}
                        />
                    )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ currentUser: state.auth.user });
const actions = { showErrorAlert };
export default connect(mapStateToProps, actions)(TreeNode);
