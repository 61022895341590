import React from 'react';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';

const WishlistsMassEditMenuItems = props => {
    const { disabledButtons, menuFuncs, selectedItems, buttonsToDisable = [] } = props;
    
    return (
        <>
            <IconButton
                className="EventMenuItem"
                disabled={disabledButtons || buttonsToDisable?.includes('attributeChange')}
                onClick={() => menuFuncs?.onAttributeChange(selectedItems)}
                type="eye"
            >
                <span className="EventMenuItem__Text"></span>
            </IconButton>
            <IconButton
                className="EventMenuItem"
                disabled={disabledButtons || buttonsToDisable?.includes('rename')}
                onClick={menuFuncs?.onRename}
                type="rename"
            >
                <span className="EventMenuItem__Text"></span>
            </IconButton>
            <IconButton
                className="EventMenuItem"
                disabled={disabledButtons || buttonsToDisable?.includes('actionsMenu')}
                onClick={menuFuncs?.onActionsMenu}
                type="settings"
            >
                <span className="EventMenuItem__Text"></span>
            </IconButton>
        </>
    );
};

export default WishlistsMassEditMenuItems;