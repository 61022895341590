import { takeLatest, put, call, all } from 'redux-saga/effects';
import { getError, serviceResultCode, personValidationCodes } from 'serviceErrors';
import { putPersonBlock } from '../api';
import { appName, NOT_SUPPORTED, TOO_LARGE } from '../constants';
import { showErrorAlert } from './Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { fetchPerson, savePhotoSuccess, setShowSnilsError } from './Person';
import { MAX_BYTES_PER_FILE, MAX_UPLOAD_FILES } from 'constants.js';
import { getFileSize } from 'utils';

const moduleName = 'personBlock';
export const SAVE_REQUEST = `${appName}/${moduleName}/SAVE_REQUEST`;
export const SAVE_REQUEST_BLOCKS = `${appName}/${moduleName}/SAVE_REQUEST_BLOCKS`;
export const SAVE_START = `${appName}/${moduleName}/SAVE_START`;
export const SAVE_SUCCESS = `${appName}/${moduleName}/SAVE_SUCCESS`;
export const SAVE_FAILED = `${appName}/${moduleName}/SAVE_FAILED`;
export const SET_EDIT_BLOCK = `${appName}/${moduleName}/SET_EDIT_BLOCK`;

const initialState = {
    loadComplete: false,
    loadTime: new Date(0),
    saving: false,
    editBlock: null,
    lastEditBlock: null,
    id: null,
    data: [],
    error: '',
};


export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case SAVE_SUCCESS:
            return {
                ...state,
                saving: false,
                loadComplete: true,
                loadTime: payload.loadTime,
                id: payload.data?.id,
                data: payload.data,
                editBlock: null,
                lastEditBlock: state.editBlock,
                error: '',
            };

        case SAVE_START:
            return {
                ...state,
                saving: true,
                id: payload.id,
                error: '',
            };

        case SAVE_FAILED:
            return {
                ...state,
                saving: false,
                loadTime: payload.loadTime,
                id: payload.id,
                error: error.message,
            };
        case SET_EDIT_BLOCK:
            return {
                ...state,
                editBlock: payload,
                lastEditBlock: state.editBlock
            };
        default:
            return state;
    }
}


export function saveBlock(block, updatePerson = false) {
    return {
        type: SAVE_REQUEST,
        payload: { block, updatePerson },
    };
}

export const saveStart = id => {
    return {
        type: SAVE_START,
        payload: { id },
    }
}

export const saveSuccess = (data, loadTime) => {
    return {
        type: SAVE_SUCCESS,
        payload: { data, loadTime },
    }
}

export const saveFailed = (id, error, loadTime) => {
    return {
        type: SAVE_FAILED,
        payload: { id, loadTime },
        error,
    }
};

export function saveBlocksArray(blocks, updatePerson = false) {
    return {
        type: SAVE_REQUEST_BLOCKS,
        payload: { blocks, updatePerson },
    };
}

export function setEditBlock(name) {
    return {
        type: SET_EDIT_BLOCK,
        payload: name,
    };
}

export const allActions = {
    saveBlock, saveStart, saveSuccess, saveFailed, saveBlocksArray
};

export function* saveBlockSaga(action) {
    const { block, updatePerson } = action.payload;
    yield put(showPageLoader());
    yield put(saveStart(block.id));

    try {
        delete block.blockRef;
        const response = yield call(putPersonBlock, block);
        yield updatePerson && put(fetchPerson(block.personId, undefined, false));
        yield put(savePhotoSuccess(block.personId));
        const savedBlock = response.data;
        yield put(saveSuccess(savedBlock, new Date()));
    } catch (error) {
        
        const reqError = getError(error, getBlockError,false,true);

        if (
            error.response?.data?.payload?.person?.[0]?.code === personValidationCodes.snilsNotValid
        ) {
            yield put(setShowSnilsError(true));
            window.scroll(0, 0);
        }

        yield put(saveFailed(block.id, reqError, new Date()));
        yield put(showErrorAlert(reqError.message));
    } finally {
        yield put(hidePageLoader());
    }
}

export function* saveBlocksSaga(action) {
    const { blocks, updatePerson } = action.payload;

    yield put(saveStart(blocks.personId));

    try {
        const response = yield all(blocks.blocksArray.map(block => call(putPersonBlock, block)));
        yield put(saveSuccess(response[0], new Date()));
        
        yield updatePerson && put(fetchPerson(blocks.personId, undefined, false));
    } catch (error) {
        const reqError = getError(error, getBlockError,false,true);

        yield put(saveFailed(blocks.personId, reqError, new Date()));
        yield put(showErrorAlert(reqError.message));
    }
}

export const saga = function* () {
    yield all([
        takeLatest(SAVE_REQUEST, saveBlockSaga),
        takeLatest(SAVE_REQUEST_BLOCKS, saveBlocksSaga),
    ]);
}

const getBlockError = (code, payload) => {
    switch (code) {
        case serviceResultCode.PersonPhoneIsInvalid:
            return "Проверьте правильность введенного номера телефона";
        case serviceResultCode.PersonEmailAlreadyExists:
            return "Резервист с таким Email уже существует в системе";
        case serviceResultCode.PersonAlreadyExists:
            return "Резервист с таким ФИО и датой рождения уже существует в системе";
        case serviceResultCode.PersonBirthDateIsNotRange:
            return "Возраст резервиста должен быть от 14 до 100 лет";
        case serviceResultCode.ValidationErrors:
            return extractValidationError(payload);
        case serviceResultCode.PersonUpdateFileMaxSize:
            return `Размер одного файла не должен превышать ${getFileSize(MAX_BYTES_PER_FILE)}`;
        case serviceResultCode.PersonUploadFilesCountIsInValid:
            return `Максимальное количество файлов для загрузки: ${MAX_UPLOAD_FILES}`;
        case serviceResultCode.SocialNetworkLinkIsNotValid:
            return payload;
        case serviceResultCode.AttributeLinkIsNotValid:
            return payload;
        default:
            return "Произошла непредвиденная ошибка"
    }
}

const extractValidationError = (errorPayload) => {
    if (!errorPayload) { return ""; }

    if (errorPayload[NOT_SUPPORTED]) {
        let errorObject = errorPayload[NOT_SUPPORTED];
        return `Неподдерживаемый формат у изображения ${errorObject[0].filename}`;
    }

    if (errorPayload[TOO_LARGE]) {
        let errorObject = errorPayload[TOO_LARGE];
        return `Размер изображения ${errorObject[0].filename} превышает лимит в ${errorObject[0].maxSize >> 20} Мб`;
    }

    return "";
}