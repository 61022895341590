import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getPersonPhotoUri, photoSizes } from '../../api';
import { showErrorAlert } from 'ducks/Alert';
import { fetchPerson, personFullSelector, savePhoto, isPersonEducationEnabled, setMergeData, setIsMergeNext } from 'ducks/Person';
import Page from 'components/common/Page';
import PersonReportHeader from 'components/common/PersonReportHeader';
import FloatMenu from './Edit/FloatMenu';
import PhotoBlock from 'components/common/PhotoBlock';
import { canEditPerson } from 'rightsController';
import LeftMenu from 'components/common/LeftMenu';
import { person, personEvaluate, personProgress, personTeams, personEducationEvents } from 'routes';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import PersonDataCard from './PersonDataCard';

import './Person.scss';
import PersonMerge from './PersonMerge';

const menuItems = [
    { anchor: 'personal', text: 'Личные данные' },
    { anchor: 'education', text: 'Образование' },
    { anchor: 'workExperience', text: 'Опыт работы' },
    { anchor: 'languages', text: 'Иностранные языки' },
    { anchor: 'family', text: 'Семейное положение' },
    { anchor: 'socialNetworks', text: 'Социальные сети' },
    { anchor: 'files', text: 'Файлы' },
    { anchor: 'personnel', text: 'Дополнительная информация' },
    { anchor: 'attributes', text: 'Дополнительные поля' },
    { anchor: 'systemInfo', text: 'Системная информация' },
];

class Person extends Component {
    static getDerivedStateFromProps(props, state) {
        if (state.loadComplete && props.editBlock && !state.isBlockChanged) {
            return null;
        }

        const { person } = props;

        let loadComplete = person.loadComplete;
        if (state.loadComplete === null) {
            loadComplete = loadComplete && person.isActual;
        }

        if (!loadComplete) {
            return { loadComplete };
        }

        return Person.getFreeStateFromPropsAndState(props, state);
    }

    static getFreeStateFromPropsAndState(props, state) {
        const { person } = props;

        const { personalInfo = {} } = person;

        return {
            mainMenuId: state.mainMenuId || props.personProfileBlock || 'profile',
            editBlock: state.editBlock || '',
            editPhoto: state.editPhoto || false,
            isBlockChanged: false,
            updatingPhoto: person.updatingPhoto,
            refetchKey: person.refetchKey,
            loadComplete: true,
            person: {
                personalInfo: {
                    id: personalInfo.id,
                },
            },
        };
    }

    state = {
        person: {},
        loadComplete: null,
        editBlock: '',
        editPhoto: false,
        isBlockChanged: false,
        requestsData: [],
        testsResults: [],
        evaluationMenuItems: [
            { anchor: 'evaluation', text: 'Результаты оценки', visible: true },
            { anchor: 'newTests', text: 'Назначено на оценку', visible: false },
            { anchor: 'rejectedTests', text: 'Отклоненные', visible: false },
            { anchor: 'actualTests', text: 'Актуальные', visible: false },
            { anchor: 'archiveTests', text: 'Архив', visible: false },
        ],
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.updateData();
    }

    componentDidUpdate(prevProps) {
        const { person, showPageLoader, hidePageLoader } = this.props;
        if (prevProps.person.loadComplete !== person.loadComplete) {
            person.loadComplete ? hidePageLoader() : showPageLoader();
        }

        const { menuChanged, evaluationMenuItems } = this.evaluationMenuChanged(
            this.state.evaluationMenuItems,
        );

        if (menuChanged) {
            this.setState((state) => {
                return { ...state, evaluationMenuItems: evaluationMenuItems };
            });
        }

        const { requestsData, testsResults } = this.props.person;

        if (
            requestsData &&
            JSON.stringify(prevProps.person.requestsData) !== JSON.stringify(requestsData)
        ) {
            this.setState((state) => {
                return { ...state, requestsData };
            });
        }

        if (testsResults && prevProps.person.testsResults !== testsResults) {
            this.setState((state) => {
                return { ...state, testsResults };
            });
        }
        if (this.props.location !== prevProps.location) {
            window.scroll(0, 0);
            if (this.props.location && this.props.location.state) {
                this.setState((state) => {
                    return {
                        ...state,
                        ...this.props.location.state,
                    };
                });
            }
        }

        const { id, isMergeNext, setIsMergeNext, setMergeData } = this.props;

        if (prevProps.id !== id) {
            this.updateData();

            if (isMergeNext) {
                setIsMergeNext(false);
            } else {
                setMergeData(null);
            }
        }
    }
    componentWillUnmount() {
        this.props.setMergeData(null);
    }

    updateData = async () => {
        await this.callEffect(async () => {
            await this.props.fetchPerson(this.props.id, photoSizes.small, true);
        });
    };

    handlePhotoOpened = (personId) => (photoFile) => {
        if (!photoFile) {
            return;
        }

        this.props.savePhoto(personId, photoFile);
    };

    handleTogglePhotoBlockMode = () => this.setState({ editPhoto: !this.state.editPhoto });

    renderPhotoBlock = () => {
        const user = this.props.user;
        const refetchKey = this.state.refetchKey;
        const personId = this.props.id;
        const photoUri = getPersonPhotoUri(photoSizes.small, personId, refetchKey);
        const photoLink = getPersonPhotoUri(photoSizes.fullSize, personId, refetchKey);
        return (
            <PhotoBlock
                id="photo-block"
                photoUri={photoUri}
                photoLink={photoLink}
                loading={this.state.updatingPhoto}
                onPhotoOpened={this.handlePhotoOpened(personId)}
                onToggleMode={this.handleTogglePhotoBlockMode}
                readOnly={!this.state.editPhoto}
                canEdit={canEditPerson(user)}
                isDisabled={!this.props.snilsIsOk}
            />
        );
    };

    evaluationMenuChanged = (menu) => {
        if (this.state.loadComplete) {
            const oldMenuCount = menu.filter((x) => x.visible === true).length;
            let newMenuCount = 1;
            const testsResults = this.state.testsResults;
            let evaluationMenuItems = [
                { anchor: 'evaluation', text: 'Результаты оценки', visible: true },
            ];
            const requests = this.state.requestsData ? this.state.requestsData.data : {};

            const newRequests =
                requests &&
                requests.payload &&
                requests.payload.filter(
                    (x) =>
                        x.status !== 'Rejected' &&
                        x.status !== 'Expired' &&
                        x.status !== 'Finished',
                ).length !== 0;
            if (newRequests) {
                evaluationMenuItems.push({
                    anchor: 'newTests',
                    text: 'Назначено на оценку',
                    visible: true,
                });
                newMenuCount++;
            }

            const rejectedRequests =
                requests &&
                requests.payload &&
                requests.payload.filter((x) => x.status === 'Rejected' || x.status === 'Expired')
                    .length !== 0;
            if (rejectedRequests) {
                evaluationMenuItems.push({
                    anchor: 'rejectedTests',
                    text: 'Отклоненные',
                    visible: true,
                });
                newMenuCount++;
            }

            const tests = testsResults && testsResults.data && testsResults.data.length !== 0;
            if (tests) {
                evaluationMenuItems.push({
                    anchor: 'actualTests',
                    text: 'Актуальные',
                    visible: true,
                });
                newMenuCount++;
            }

            const archiveTests =
                testsResults && testsResults.data && this.checkArchiveTests(testsResults.data);
            if (archiveTests) {
                evaluationMenuItems.push({ anchor: 'archiveTests', text: 'Архив', visible: true });
                newMenuCount++;
            }

            if (oldMenuCount !== newMenuCount) {
                return {
                    menuChanged: true,
                    evaluationMenuItems: evaluationMenuItems,
                };
            }
        }
        return {
            menuChanged: false,
            evaluationMenuItems: null,
        };
    };

    checkArchiveTests = (data) => {
        let hasArchive = false;
        for (const x of data) {
            if (x.status === 'Archived') {
                hasArchive = true;
                break;
            }
        }
        return hasArchive;
    };

    onMainMenuItemChange = (item) => {
        const state = {
            mainMenuId: item.id,
        };
        this.props.push(item.route.buildUrl({ id: this.props.id, profileBlock: item.id }), state);
    };

    renderMainMenu = (personEducationEnabled) => {
        let mainMenuItems = [
            {
                id: 'profile',
                title: 'Профиль',
                svgIcon: 'Profile',
                inheritedMenu: this.renderProfileMenu(),
                route: person,
            },
            {
                id: 'evaluate',
                title: 'Оценка',
                svgIcon: 'Evaluate',
                inheritedMenu: this.renderEvaluationMenu(),
                route: personEvaluate,
            },
            {
                id: 'progress',
                title: 'Развитие',
                svgIcon: 'Progress',
                route: personProgress,
            },
        ];

        if (personEducationEnabled) {
            mainMenuItems = [
                ...mainMenuItems,
                {
                    id: 'personEducations',
                    title: 'Обучение',
                    svgIcon: 'Education',
                    route: personEducationEvents,
                },
            ];
        }

        mainMenuItems = [
            ...mainMenuItems,
            {
                id: 'teams',
                title: 'Команды',
                svgIcon: 'Group',
                route: personTeams,
            },
        ];

        return (
            <div className="FloatMenu__Container">
                <LeftMenu
                    items={mainMenuItems}
                    selected={this.state.mainMenuId}
                    onChangePage={this.onMainMenuItemChange}
                />
            </div>
        );
    };

    renderProfileMenu = () => {
        const { attributes } = this.props;
        const filteredMenu =
            attributes?.length === 0
                ? menuItems.filter((x) => x.anchor !== 'attributes')
                : menuItems;
        return (
            <FloatMenu
                items={filteredMenu}
                refs={this.props.cardRef.cardRefs}
                viewCheckMark={false}
            />
        );
    };

    cleanNullRefs = (refsObj) => {
        let newRefs = {};
        for (const [key, value] of Object.entries(refsObj)) {
            if (value.current) {
                newRefs[key] = value;
            }
        }
        return newRefs;
    };

    renderEvaluationMenu = () => {
        return (
            <FloatMenu
                items={this.state.evaluationMenuItems}
                refs={this.cleanNullRefs(this.props.cardRef.evaluationRefs)}
                viewCheckMark={false}
            />
        );
    };

    render() {
        if (!this.state.loadComplete) {
            return null;
        }

        const { personEducationEnabled } = this.props;

        const reportSelection = { personIds: [this.props.id] };

        return (
            <div>
                {this.props.mergeData ? (
                    <PersonMerge
                        id={this.props.id}
                        requestsData={this.state.requestsData}
                    />
                ) : (
                    <>
                        <PersonReportHeader selection={reportSelection} />

                        <Page id="profile-page" w1790 className="Person">
                            <div className="Page__MenuColumn">
                                {this.renderPhotoBlock()}
                                {this.renderMainMenu(personEducationEnabled)}
                            </div>
                            <div className="Page__ContentColumn">
                                <PersonDataCard
                                    id={this.props.id}
                                    requestsData={this.state.requestsData}
                                />
                            </div>
                        </Page>
                    </>
                )}
            </div>
        );
    }

    callEffect = async (callback) => {
        this.props.showPageLoader();

        try {
            await callback();
        } catch (error) {
            this.props.showErrorAlert(error.message);
        } finally {
            this.props.hidePageLoader();
        }
    };
}

Person.propTypes = {
    id: PropTypes.number.isRequired,
    person: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const config = state.config;
    const person = personFullSelector(state, ownProps.id);
    const personEducationEnabled = isPersonEducationEnabled(state);
    const { cardRefs, evaluationRefs } = state.person.cardRef;
    const attributes = state?.attributes?.data?.payload;
    const cardRef = {
        cardRefs,
        evaluationRefs,
    };
    return {
        config,
        cardRef,
        person,
        mergeData: state.person.mergeData,
        isMergeNext: state.person.isMergeNext,
        user: state.auth.user,
        personEducationEnabled,
        attributes,
        snilsIsOk: state.person.snilsIsOk,
    };
};

const actions = {
    fetchPerson,
    showErrorAlert,
    push,
    savePhoto,
    showPageLoader,
    hidePageLoader,
    setMergeData,
    setIsMergeNext,
};

export default connect(mapStateToProps, actions)(withRouter(Person));
