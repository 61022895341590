import React, { useState, useMemo, useEffect } from 'react';
import ResourceItem from './ResourceItem';
import SearchField from './SearchField';
import classnames from 'classnames';
import Slider from 'components/Lk/Uikit/Slider/Slider';
import Loader from 'components/common/Loader';
import useDebounce from 'components/Lk/Hooks/useDebounce';
import CardItemBlock from '../CardItemBlock';
import EvaluationType from '../Evaluate/EvaluationType';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { getResourceCounter, extendedSearchPersonGrowthResources, makeRequestCreator } from 'api';
import { isMobileWidth } from 'utils.js';
import {
    RESOURCE_TYPES,
    RESOURCE_FILTER_TYPES,
    RESOURCE_RECOMEND,
    RESOURCE_ALL,
    RESOURCE_SELECTED,
} from './constants';
import HeaderStep from './HeaderStep';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios';

const getPersonGrowthResourcesOnce = makeRequestCreator(extendedSearchPersonGrowthResources);
const getPersonResourceCounterOnce = makeRequestCreator(getResourceCounter);

const ResourceStep = props => {
    const { currentPlan, changeHandler, scrollParentRef } = props;

    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 30,
    });
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [search, setSearch] = useState('');
    const [resources, setResources] = useState([]);
    const [resourceCount, setResourceCounter] = useState({});
    const [resourceType, setResourceType] = useState(RESOURCE_TYPES[0]);
    const [typeFilter, setTypeFilter] = useState('');
    const [clearData, setClearData] = useState(false);

    const debouncedSearchTerm = useDebounce(search, 500);

    useEffect(() => {
        let mounted = true;

        const loadRecommendedResources = async () => {
            mounted && await onSearch(mounted);
        };

        if (resourceType.type !== RESOURCE_SELECTED) {
            mounted && loadRecommendedResources();
        }

        return () => {
            mounted = false;
        }
        // eslint-disable-next-line
    }, [currentPlan.competencyIds, resourceType.type, debouncedSearchTerm, typeFilter]);

    useEffect(() => {
        let mounted = true;
        const loadResourceCounter = async () => {
            const requestCriteria = {
                filter: {
                    name: debouncedSearchTerm,
                    competencyIds:
                        resourceType.type === RESOURCE_ALL ? [] : currentPlan.competencyIds,
                },
            };
            const response = mounted && await getPersonResourceCounterOnce(requestCriteria);
            if (response) {
                mounted && setResourceCounter({ ...response.data });
            }
        };

        if (resourceType.type !== RESOURCE_SELECTED) {
            mounted && loadResourceCounter();
        }

        return () => {
            mounted = false;
        }
    }, [currentPlan.competencyIds, resourceType.type, debouncedSearchTerm]);

    const setCheckResource = (resource, check) => {
        const { resources = [] } = currentPlan;
        const activeCompetencies = check
            ? [...resources, resource]
            : resources.filter(x => x.id !== resource.id);
        changeHandler('resources', activeCompetencies);
    };

    const handleLoadMore = () => {
        setHasMore(false);
        onSearch();
    };

    const onSearch = async (mounted = true) => {
        mounted && clearData && setResources([]);
        mounted && setLoading(true);
        const requestCriteria = {
            filter: {
                name: debouncedSearchTerm,
                types: typeFilter ? [typeFilter] : [],
                competencyIds: resourceType.type === RESOURCE_ALL ? [] : currentPlan.competencyIds,
            },
            paging: clearData ? { pageNum: 1, pageSize: 30 } : paging,
        };
        try {
            const response = mounted &&  await getPersonGrowthResourcesOnce(requestCriteria);
            mounted && setResources(
                clearData ? [...response.data.payload] : [...resources, ...response.data.payload],
            );
            mounted && setHasMore(response.data.meta.pageCount > paging.pageNum);
            mounted && setPaging(
                clearData
                    ? { pageNum: 2, pageSize: 30 }
                    : { ...paging, pageNum: paging.pageNum + 1 },
            );
            mounted && setClearData(false);
        } catch (e) {
            if (axios.isCancel(e)) {
                return;
            }
        }
        mounted && setLoading(false);
    };

    const isCheckResource = id =>
        currentPlan.resources && !!currentPlan.resources.some(x => x.id === id);

    const slideResourceChange = (e, array) => {
        setClearData(true);
        setResourceType(array[e]);
    };

    const renderRecourcesType = obg => {
        return obg.map((item) => {
            return (
                <CardItemBlock grey key={item.key}>
                    <EvaluationType title={item.title} icon="evaluateType" />
                </CardItemBlock>
            );
        });
    };

    const selectFilter = type => () => {
        setClearData(true);
        setTypeFilter(type !== typeFilter ? type : null);
    };

    const changeSearch = e => {
        setClearData(true);
        setSearch(e.target.value);
    };

    const activeResources = useMemo(() => {
        switch (resourceType.type) {
            case RESOURCE_RECOMEND:
            case RESOURCE_ALL:
                return resources;
            case RESOURCE_SELECTED:
                return currentPlan.resources;
            default:
                return [];
        }
    }, [resources, currentPlan.resources, resourceType.type]);

    const filteredResources = useMemo(
        () =>
            activeResources.filter(
                x =>
                    (!typeFilter || x.type === typeFilter) &&
                    (!debouncedSearchTerm || x.name.includes(debouncedSearchTerm)),
            ),
        [activeResources, typeFilter, debouncedSearchTerm],
    );

    const resourceCounter = useMemo(() => {
        let counters = {
            Video: 0,
            Book: 0,
            File: 0,
            Microcourse: 0,
            Link: 0,
            AudioBook: 0,
        };

        switch (resourceType.type) {
            case RESOURCE_RECOMEND:
            case RESOURCE_ALL:
                return resourceCount;
            case RESOURCE_SELECTED:
                if (activeResources.length === 0) {
                    return counters;
                }
                for (const resource of activeResources.filter(x =>
                    x.name.includes(debouncedSearchTerm),
                )) {
                    counters[resource.type] = counters[resource.type] + 1;
                }

                return counters;
            default:
                return counters;
        }
    }, [resourceType.type, activeResources, resourceCount, debouncedSearchTerm]);

    const isMobile = isMobileWidth();
    const params = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        rebuildOnUpdate: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
    };

    return (
        <>
            <HeaderStep
                title="3. Выберите инструменты для развития"
                descr="Инструменты развития&nbsp;&mdash; это библиотеки партнеров, Альпины и&nbsp;Виртуальной школы Сбербанка. 
                Переход по&nbsp;материалам Альпины происходит автоматически. Для доступа в&nbsp;ВШС введите логин и&nbsp;пароль из&nbsp;письма-приглашения, 
                которое было направлено Вам на&nbsp;почту с&nbsp;адреса info@iskra-system.ru."
            />
            <SearchField onChange={changeSearch} value={search} />
            <Slider
                data={renderRecourcesType(RESOURCE_TYPES)}
                initialSlide={resourceType && resourceType.key}
                slideChange={slideResourceChange}
                resultIds={RESOURCE_TYPES}
                magical={6}
            />

            <div className="LKProgressFilterButton">
                <Swiper {...params} containerClass="LKProgressFilterButton__Swiper">
                    {RESOURCE_FILTER_TYPES.map(type => (
                        <div
                            className={classnames('LKProgressFilterButton__Item', {
                                'LKProgressFilterButton__Item--active': type.type === typeFilter,
                            })}
                            onClick={selectFilter(type.type)}
                            key={type.id}
                        >
                            {type.title} {resourceCounter[type.type]}
                        </div>
                    ))}
                </Swiper>
            </div>
            <InfiniteScroll
                pageStart={0}
                loadMore={handleLoadMore}
                hasMore={hasMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => scrollParentRef.current}
            >
                <div
                    className={classnames('LKProgressResourceList', {
                        'LKProgressResourceList--mobile': isMobile,
                    })}
                >
                    {filteredResources.map(x => (
                        <ResourceItem
                            key={x.id}
                            item={x}
                            mobile={isMobile}
                            checked={isCheckResource(x.id)}
                            onClick={setCheckResource}
                        />
                    ))}
                    {loading && <Loader />}
                </div>
            </InfiniteScroll>
        </>
    );
};

export default ResourceStep;
