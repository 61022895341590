import React from 'react';
import PropTypes from 'prop-types';
import Icons from "components/Lk/Uikit/Icons/Icons";
import classnames from 'classnames';
import Popover from 'components/Lk/Uikit/Popover';

const CardItemBlockType = (props) => {
    const { title, tooltip, onClick, icon, normalText, dataTest } = props;

    const getIcon = () => {
        switch(icon) {
            case 'small':
                return  (
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="48" height="48" rx="8" fill="#EFEFF4" />
                        <path stroke="#0A84FF" d="M17.2488 19.9473C16.9367 18.2826 18.3354 16.7958 20.0161 17.0059L23.5659 17.4496C23.8542 17.4857 24.1458 17.4857 24.4341 17.4496L27.7176 17.0392C29.4608 16.8213 30.8791 18.4219 30.4531 20.1262L29.6968 23.1511C29.5575 23.7085 29.5575 24.2915 29.6968 24.8489L30.3803 27.5829C30.8223 29.3508 29.2853 30.9823 27.4943 30.6465L24.645 30.1122C24.2187 30.0323 23.7813 30.0323 23.355 30.1122L20.2174 30.7005C18.4893 31.0245 16.9755 29.5107 17.2995 27.7826L17.8878 24.645C17.9677 24.2187 17.9677 23.7813 17.8878 23.355L17.2488 19.9473Z" />
                    </svg>
                );
            default:
                return <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="56" height="56" rx="8" fill="#0A84FF"/>
                        </svg>
        }
    };

    return (
        <div className={classnames("CardItemBlockType", {'CardItemBlockType--clickable': onClick})} data-test={dataTest} onClick={onClick}>
            <div className="CardItemBlockType__Icon">
               {getIcon()}
            </div>
            <div className={classnames("CardItemBlockType__Body", {"CardItemBlockType__Body--normal-text": normalText})} >
                {title}
            </div>
            {tooltip && 
            <Popover  tooltip={tooltip}>
                <div className="EvaluationType__Info">
                    <Icons name="help-alt" fill={'#007AFF'} />
                </div>
            </Popover>
            }
        </div>
    );
};

CardItemBlockType.propTypes = {
    id: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
};

export default CardItemBlockType;