import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from 'components/uikit/Input';
import Label from 'components/uikit/Label';
import PasswordField from './PasswordField';

const FirstStage = (props) => {
    const {
        login,
        password,
        rememberMe,
        onChange,
        setResetPasswordStage,
        onRememberMeChanged,
        signInInProgress,
        auth,
    } = props;

    return (
        <>
            <div className="kr_item required">
                <Label htmlFor="login">Логин</Label>
                <Input
                    name="login"
                    id="login"
                    required
                    autoFocus
                    value={login}
                    disabled={auth.signInInProgress}
                    onChange={onChange}
                    data-test="SignInLogin--input"
                />
            </div>
            <div className="SignInForm__PasswordField required">
                <Label htmlFor="password">Пароль</Label>
                <PasswordField
                    value={password}
                    disabled={auth.signInInProgress}
                    onChange={onChange}
                    data-test="SignInPassword--input"
                />
            </div>
            <div className="kr_item">
                <div
                    className="SignInResetPasswordLink"
                    onClick={setResetPasswordStage}
                    data-test="SignInResetPassword--button"
                >
                    Восстановить пароль
                </div>
            </div>
            <div className="kr_checkbox_wrap save_password_section">
                <Input
                    name="save_password"
                    id="save_password"
                    type="checkbox"
                    checked={!rememberMe}
                    disabled={signInInProgress}
                    onChange={onRememberMeChanged}
                    data-test="SignInSavePassword--checkbox"
                />
                <Label htmlFor="save_password">Чужой компьютер</Label>
            </div>
        </>
    );
};

const props = (state) => {
    return {
        auth: state.auth,
    };
};

FirstStage.propTypes = {
    login: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    rememberMe: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    setResetPasswordStage: PropTypes.func.isRequired,
    onRememberMeChanged: PropTypes.func.isRequired,
    signInInProgress: PropTypes.bool.isRequired,
};

export default connect(props)(FirstStage);
