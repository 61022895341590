import React from 'react';
import TeamList from 'components/Lk/Shared/GroupList/TeamList.js';
import Filters from './Filters';


const TeamProgressCard = props => {
    const { selectedSorting, setSelectedSorting, selectedFilter, setSelectedFilter, onPersonSelect, isInCart, filterGroups, sortGroups } = props;

    return (
        <div className='LKMainProgressWrapper'>
            <Filters 
                selectedSorting={selectedSorting}
                setSelectedSorting={setSelectedSorting}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
            />
            <div className="LKScrollWrapper">
                <TeamList 
                    onPersonSelect={onPersonSelect}
                    isInCart={isInCart}
                    {...props} 
                    filters={[
                        {func: filterGroups, params: [selectedFilter]}, 
                        {func: sortGroups, params: [selectedSorting]}
                    ]} 
                />
            </div>
        </div>
    );
};


export default TeamProgressCard;