import React from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import { helpObjectsTypes } from './constants';

import uuid from 'uuid/v4';
import HeaderStep from './HeaderStep';

const GrowthStep = props => {
    const { currentPlan, changeHandler, submit } = props;

    const onAddEvent = () => {
        const newEvent = {
            id: uuid(),
            type: null,
            assistanceDetails: null,
        };

        changeHandler('personGrowthEvents', currentPlan.personGrowthEvents.concat(newEvent));
    };

    const onChangeEvent = (id, name) => value => {
        const newPersonGrowthEvents = currentPlan.personGrowthEvents.map(x =>
            x.id !== id ? x : { ...x, [name]: value },
        );

        changeHandler('personGrowthEvents', newPersonGrowthEvents);
    };

    const onRemove = id => e => {
        e.stopPropagation();
        changeHandler(
            'personGrowthEvents',
            currentPlan.personGrowthEvents.filter(a => a.id !== id),
        );
    };

    const onChangeSelect = (id, name) => record => onChangeEvent(id, name)(record.value);

    const getSelect = type => {
        if (!type) {
            return {};
        }
        const record = helpObjectsTypes.find(x => x.value === type);
        return record || {};
    };

    return (
        <>
            <HeaderStep
                title="5. Что поможет в развитии"
                info="Шаги 4 и 5 являются не обязательными и заполняются по Вашему усмотрению."
                descr="Выберите один или несколько инструментов, которые помогут Вам достигнуть цели.
                    Описание инструмента появится после того, как Вы на него нажмете."
            />
            {currentPlan.personGrowthEvents.map(item => (
                <React.Fragment key={item.id}>
                    <InfoField
                        isEdit
                        title="Выберите тип"
                        invalid={submit && !item.type}
                        icon={{
                            icon: 'minus-circle',
                            size: 'xs',
                            color: 'red',
                            onClick: onRemove(item.id),
                        }}
                        tooltip={getSelect(item.type).tip || 'Выберите тип'}
                        inputProps={{
                            type: 'select',
                            items: helpObjectsTypes,
                            onChange: onChangeSelect(item.id, 'type'),
                            placeholder: 'Выберите тип',
                        }}
                        value={getSelect(item.type).id}
                    />

                    <InfoField
                        isEdit
                        title="Описание"
                        invalid={submit && !item.assistanceDetails}
                        onChange={onChangeEvent(item.id, 'assistanceDetails')}
                        value={item.assistanceDetails}
                    />
                </React.Fragment>
            ))}
            <ProfileButton
                type={submit && currentPlan.personGrowthEvents.length === 0 && 'error'}
                onClick={onAddEvent}
            >
                Добавить
            </ProfileButton>
        </>
    );
};

export default GrowthStep;
