import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_LK_ROLES } from 'rightsController';
import { 
    lkSearch, lkProgressTeam, lkProgressPerson,
    lkProgressFavotite, lkProgressCart, lkWidgetsPersonsWithoutResults,
    lkWidgetsEvaluationRating, lkDetailsEvaluateSelect,
    lkWidgetsTeamGrowth, lkEvaluateSelectResults, lkWidgetsBestInGroup, lkListAdviceTags,
    lkPersonProfile, lkProgressCartProfile, lkIssue, lkNewIssue, lkIssueDraft, lkIssueTracking, lkDetailsCollection, lkIssueEdit,
    lkEvaluateCartProfile,
} from 'routes';
import EvaluateSelect from 'components/Lk/Shared/Evaluate/EvaluateSelect';
import EvaluateResultSelect from 'components/Lk/Shared/Evaluate/EvaluateResultSelect';
import SearchCard from 'components/Lk/Shared/Search/SearchCard';
import PersonsWithoutResults from 'components/Lk/Shared/Widgets/PersonsWithoutResults';
import EvaluationRegionRating from 'components/Lk/Shared/Widgets/EvaluationRegionRating';
import TeamGrowth from 'components/Lk/Shared/Widgets/TeamGrowth';
import BestInGroup from 'components/Lk/Shared/Widgets/BestInGroup';
import PersonCart from 'components/Lk/Common/PersonCart';
import AdviceTags from 'components/Lk/Shared/Advices/AdviceTags';
import ProfileCard from 'components/Lk/Shared/Profile/ProfileCard';
import { progressCardMenuItems, evaluateCardMenuItems } from 'components/Lk/Basic/MainCard/constants';
import IssueItemPage from 'components/Lk/Shared/Issues/IssueItemPage';
import NewIssue from 'components/Lk/Shared/Tasks/NewIssue';
import Tracking from 'components/Lk/Shared/Tracking';
import WishlistPeople from 'components/Lk/Shared/WishLists/WishlistPeople';
import Progress from 'components/Lk/Shared/Progress/';


const ListCard = props => {
    const { activeCard, setActiveCard, id } = props;
    return (
        <Switch>
            <ProtectedRoute
                path={lkDetailsEvaluateSelect.url}
                render={(props) => <EvaluateSelect {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute 
                path={lkEvaluateSelectResults.url} 
                render={(props) => <EvaluateResultSelect {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute
                path={lkDetailsCollection.url}
                render={(props) => <WishlistPeople {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute 
                path={lkSearch.url} 
                render={(props) => <SearchCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />} 
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute 
                path={lkIssueEdit.url}
                render={(props) => <NewIssue {...props}  isEdit activeCard={activeCard} setActiveCard={setActiveCard} />} 
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute
                path={[lkProgressTeam.url, lkProgressFavotite.url, lkProgressPerson.url]}
                render={(props) => <Progress {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkWidgetsPersonsWithoutResults.url}
                render={(props) => <PersonsWithoutResults {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkWidgetsEvaluationRating.url}
                render={(props) => <EvaluationRegionRating {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkWidgetsTeamGrowth.url}
                render={(props) => <TeamGrowth {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkWidgetsBestInGroup.url}
                render={(props) => <BestInGroup {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={[lkProgressCart.url, lkEvaluateCartProfile.url]}
                render={(props) =>
                    <PersonCart
                        {...props}
                        activeCard={activeCard}
                        setActiveCard={setActiveCard}
                        cartTitle={props.match.path === lkProgressCart.url ? 'Индивидуальный план развития' : ''}
                        catrGroups={[]}
                        menuItems={props.match.path === lkProgressCart.url ? progressCardMenuItems : evaluateCardMenuItems}
                        linkParams={{ canSelect: true }}
                        type={props.match.path === lkProgressCart.url ? 'progress' : 'evaluate'}
                        profileRoute={props.match.path === lkProgressCart.url ? lkProgressCartProfile : lkEvaluateCartProfile}
                    />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            
            <ProtectedRoute 
                path={lkNewIssue.url} 
                render={(props) => <NewIssue {...props} activeCard={activeCard} setActiveCard={setActiveCard} />} 
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute 
                path={lkIssueDraft.url} 
                render={(props) => <NewIssue {...props} activeCard={activeCard} setActiveCard={setActiveCard} isEdit status='Draft' />} 
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute
                path={lkListAdviceTags.url}
                render={(props) => <AdviceTags {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
             <ProtectedRoute
                path={lkIssueTracking.url}
                render={(props) => <Tracking {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />

            <ProtectedRoute
                path={lkPersonProfile.url}
                render={(props) => <ProfileCard {...props}
                personId={id}
                activeCard={activeCard} setActiveCard={setActiveCard}/>}
                allowedRoles={ALLOWED_LK_ROLES}
            >
            </ProtectedRoute>
            
            <ProtectedRoute
                path={lkIssue.url}
                render={(props) => <IssueItemPage {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
        </Switch>
    );
};

ListCard.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

export default ListCard;