import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { Component } from "react";
import Sticker from "react-stickyfill";
import { getReportDownloadLink, getReportTemplates, getReportById } from "api";
import Button from "components/uikit/Button";
import { Select } from "components/uikit/Select";
import "./PersonReportHeader.scss"
import { connect } from 'react-redux';
import { fetchData } from 'ducks/Analytics';
import {setNeedAddFavorites} from 'ducks/Wishlists'
import { favorites } from 'routes'

const generatePersonReports = (reportId, personIds) => {
    return getReportById(reportId, personIds);
};

class PersonReportHeader extends Component {
    state = {
        loading: false,
        selectedReport: null,
        reportsList: [],
    }
    
    _mounted = true;

    getReportTemplates = async () => {
        const templates = await getReportTemplates();
        let { base, system, user } = templates.data;
        user = Object.keys(user).flatMap(x => user[x]);
        user = user.map( x => ({...x, isUserReport: true}))
        base.map((x, index) => {
            const replacement = user.find(r => r.isDefault && r.base === x.base);
            if (replacement) {
                user.splice(user.indexOf(replacement), 1);
                return base.splice(index, 1, replacement);
            } else {
               return x; 
            }
        })
        return [...base, ...system, ...user];
    }

    componentDidMount() {
        this.getReportTemplates().then(data => {
            this._mounted && this.setState(state => {
                return {
                    ...state,
                    reportsList: data.map(x => {
                        const className = x.isUserReport ? "UserReport" : "";
                        return { id: x.id, label: x.name, className: className }
                    }),
                }
            })
        })
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    handleDownload = async () => {
        const { selection } = this.props;
        const { personIds } = selection;
        const { selectedReport } = this.state;

        if (personIds.length === 0 || !selectedReport) { return; }

        this.setState({ loading: true });
        try {
            const response = await generatePersonReports(selectedReport.id, personIds);
            if (!response.data.fileId) { return; }

            const downloadLink = getReportDownloadLink(response.data.fileId);
            window.location = downloadLink;
        } finally {
            this.setState({ loading: false });
        }
    }

    handleSelectedReportChange = (selectedReport) => {
        this.setState({ selectedReport });
    }

    handleSelectAll = () => {
        this.props.onSelectAll && this.props.onSelectAll();
    }

    handleClearAll = () => {
        this.props.onClearAll && this.props.onClearAll();
    }

    handleAnalyticsClick = (personIds) => {
        if (this.props.pathname === favorites.url) {
            this.props.setNeedAddFavorites(true)
        }
        this.props.fetchData(personIds)
    }

    renderSelectionControls = () => {
        const { selection, totalCount } = this.props;
        const { personIds } = selection;

        return (
            <div className="PersonSelection">
                <div className="PersonSelection__Item">
                    <Button
                        type="button"
                        size="sm"
                        disabled={personIds.length === 0}
                        onClick={() => this.handleAnalyticsClick(personIds)}
                    >
                        Аналитика
                    </Button>
                    <span className="PersonSelection__Text">Выделено {personIds.length} из {totalCount}</span>
                </div>
                <div className="PersonSelection__Item">
                    <Button
                        type="button"
                        className="PersonSelection__SelectAll"
                        size="sm"
                        onClick={this.handleSelectAll}
                        loading={this.props.selecting}
                    >
                        Выделить всех
                    </Button>
                    <Button
                        type="button"
                        className="PersonSelection__ClearAll"
                        size="sm"
                        color="danger"
                        onClick={this.handleClearAll}
                    >
                        Очистить
                    </Button>
                </div>
            </div>
        );
    }

    render = () => {
        const { selection, totalCount } = this.props;
        const { loading, selectedReport } = this.state;

        const multiMode = totalCount != null;

        const reportHeaderClassName = classNames(["PersonReportHeader"], {
            "PersonReportHeader--multi": multiMode
        });

        const reportBlockClassName = classNames(["PersonReportBlock"], {
            "PersonReportBlock--multi": multiMode
        });

        return (
            <Sticker>
                <div className={reportHeaderClassName}>
                    <div className={reportBlockClassName}>
                        <Select
                            className="PersonReportBlock__Select"
                            value={selectedReport}
                            onChange={this.handleSelectedReportChange}
                            options={this.state.reportsList}
                            placeholder="Выберите отчет"
                        />
                        <Button
                            className="PersonReportBlock__Button"
                            type="button"
                            size="sm"
                            faIcon={faFileDownload}
                            loading={loading}
                            disabled={!selectedReport || selection.personIds.length === 0}
                            onClick={this.handleDownload}
                        >
                            Выгрузить
                        </Button>
                    </div>
                    {multiMode && this.renderSelectionControls()}
                </div>
            </Sticker>
        );
    }
}

const mapStateToProps = state => ({
    pathname: state.router.location.pathname,
})

const actions = {fetchData, setNeedAddFavorites};

export default connect(mapStateToProps, actions)(PersonReportHeader);