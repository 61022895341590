import React from 'react';
import InfoField from './InfoField';

const HiddenInfoField = props => {
    const { isEdit, value, show } = props;

    return isEdit || value || show ? <InfoField {...props} /> : null;
};

export default HiddenInfoField;
