import React from 'react';
import './NoDataBlock.scss';

const NoDataBlock = props => {
    const { text } = props;
    return (
        <div className='LKNoDataBlock'>
            {text}
        </div>
    );
};

export default NoDataBlock;