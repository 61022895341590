import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchPerson } from 'ducks/Person';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import PersonDataCard from 'components/Person/PersonDataCard';
import { PageLoader } from 'components/common/Loader';
import ProfileMenu from './ProfileMenu';
import "./Profile.scss";

const Profile = (props) => {

    const { showPageLoader, hidePageLoader, showErrorAlert, fetchPerson, personId } = props;

    useEffect(() => {        
        const fetchModels = async () => {
            showPageLoader();
            try {
                if (!!!personId) {
                    return <></>;
                }
                await fetchPerson(personId);

            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        }
        fetchModels();
    }, [personId,fetchPerson, hidePageLoader, showErrorAlert, showPageLoader]);

    return (
        <>
            <ProfileMenu id={personId} />
            <div className="Profile__Card">
                <PersonDataCard id={personId} />
                <PageLoader />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        personId: state.auth.user.personId,
    }
}
const actions = { showErrorAlert, showPageLoader, hidePageLoader, fetchPerson };

export default withRouter(connect(mapStateToProps,actions)(Profile));
