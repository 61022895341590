import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import Item from './Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditWishListsBlock = props => {
    const { wishlists, hasMore, selectedItem, loadLists, onItemClick, withAddBlock, setNewWishList } = props;

    return (
        <>
            {withAddBlock && 
                <div className="LKEditWishListsAddBlock" onClick={setNewWishList(true)}>
                    <div className="LKEditWishListsAddBlock__Item">
                        <FontAwesomeIcon icon="plus-circle" color="green" />
                    </div>
                    <div className="LKEditWishListsAddBlock__Item">Добавить подборку</div>
                </div>            
            }
            <InfiniteScroll
                loadMore={loadLists}
                hasMore={hasMore}
                initialLoad={false}
                useWindow={false}
            >
                {wishlists.map(x => (
                    <Item
                        key={x.id}
                        {...x}
                        isSelected={selectedItem === x.id}
                        onClick={onItemClick}
                    />
                ))}
            </InfiniteScroll>
        </>
    );
};

EditWishListsBlock.defaultProps = {
    wishlists: [],
    withAddBlock: false,
    setNewWishList: () => {},
};

EditWishListsBlock.propTypes = {
    wishlists: PropTypes.array,
    hasMore: PropTypes.bool,
    selectedItem: PropTypes.any,
    loadLists: PropTypes.func,
    onItemClick: PropTypes.func,
    withAddBlock: PropTypes.bool,
    setNewWishList: PropTypes.func,
};

export default EditWishListsBlock;
