import React, { Component } from "react";
import "./Expander.scss";
import ChevronButton from 'components/uikit/ChevronButton';

class Expander extends Component {
    state = {
        isOpen: false
    }

    onClick = (e) => {
        e.preventDefault()
        this.setState(state => ({
            isOpen: !state.isOpen
        }));
    }

    render() {
        return (
            <div className="Expander">
                <div className="Expander_Header">
                    <div className="Expander_Header__Title">{this.props.title}</div>
                    <div className="Expander_Header__ChevronButton"><ChevronButton isUp={this.state.isOpen} onClick={this.onClick}></ChevronButton></div>
                </div>
                {!this.state.isOpen ? null :
                    <div className="Expander_Content">
                        <div className="Expander_Content__Wrapper">
                            {this.props.children}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Expander;