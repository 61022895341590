import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './CardItemPersonData.scss';

const CardItemPersonData = (props) => {
    const { 
        data,
        position,
        text,
        normalText = false,
        roles = [],
        blockClassName = classnames("CardItemPersonData", {'CardItemPersonData--withRoles': roles.length > 0}),
    } = props;

    return (
        <div className="CardItemPersonData__Wrapper">
            <div className={blockClassName}>
                {data && <div className="CardItemPersonData__Name">{data}</div>}
                {position && <div className="CardItemPersonData__Position">{position}</div>}
                {text && <div className={classnames("CardItemPersonData__Text", {'CardItemPersonData__Text--normalText' : normalText})}>{text}</div>}
            </div>
            {roles.length > 0 && <div className="Roles">{roles.map((role, idx)=><div key={idx} className="Roles__Item">{role}</div>)}</div>}
        </div>
    );
};

CardItemPersonData.propTypes = {
    data: PropTypes.string,
    position: PropTypes.string,
    text: PropTypes.any,
};

export default CardItemPersonData;