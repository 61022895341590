import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchProfiles } from 'ducks/ExternalSearch';

export const Profile = props => {
    const { fetchProfiles, profiles } = props;

    useEffect(() => {
        if (fetchProfiles) {
            fetchProfiles();
        }
    }, [fetchProfiles]);
    return (
        <div className="ProfileSearch">
            <div className="ProfileSearch__List">
                {profiles.map((profile, idx) => (
                    <div key={idx} className="ProfileSearchItem">
                        <div className="ProfileSearchItem__Icon">
                            <FontAwesomeIcon icon='address-card' size="4x" />
                        </div>
                        <div className="ProfileSearchItem__InfoBlock">
                            {profile.title} ({profile.count})
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = ({ externalSearch }) => ({
    profiles: externalSearch.profiles,
});

export default connect(mapStateToProps, { fetchProfiles })(Profile);
