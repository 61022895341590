import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { lkDetailsEvaluateCart } from 'routes';
import { fullTextSearchSelect } from 'api';
import InfiniteScroll from 'react-infinite-scroller';
import { getFavoritesWishlist } from "ducks/Wishlists";
import classnames from 'classnames';
import { ACTIVE_DETAIL_CARD, EVALUATION_TYPE } from 'components/Lk/Basic/constants';
import EvaluationCardItemBlock from './EvaluationCardItemBlock';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import { evaluateCardMenuItems } from 'components/Lk/Basic/MainCard/constants';
import EvaluationPerson from './EvaluationPerson';
import EvaluationPersonData from './EvaluationPerson/EvaluationPersonData';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import { getUserShortName, getUserFullName } from 'utils';
import { fetchTeams } from 'ducks/Teams';
import { changeBasket } from 'ducks/Baskets';
import { acceptChoice, renderEvaluationType, usePrevious } from './Utils'
import { fetchEvaluation, setSelectes } from 'ducks/Evaluation';
import Slider from 'components/Lk/Uikit/Slider/Slider';
import Input from 'components/Lk/Uikit/Input';
import Button from 'components/Lk/Uikit/Buttons/Button';
import Loader from "components/common/Loader";
import StickyFooter from 'components/Lk/Common/StickyFooter';

import './EvaluationSearch.scss';

const EvaluationSearch = (props) => {

    const { activeCard, setActiveCard, push } = props;

    const initPaging = { pageNum: 1, pageSize: 10 };

    const [preselectionBasket, setPreselectionBasket] = useState([]);
    const [resultIds, setResultIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [paging, setPaging] = useState(initPaging);
    const [pageCount, setPageCount] = useState(0);
    const [searchResult, setSearchResult] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    setActiveCard(ACTIVE_DETAIL_CARD);
    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;


    useEffect(() => {
        setPreselectionBasket(props.preselectionBasket);
    }, [props.preselectionBasket]);

    useEffect(() => {
        setResultIds(EVALUATION_TYPE.map(item => { return item.id }));
    }, []);

    const onSelectperson = (id, item) => e => {
        e.stopPropagation();
        e.preventDefault();

        const isOwn = preselectionBasket.persons && preselectionBasket.persons.some(x => x.person.id === id);
        let result = {};
        if (!isOwn) {
            result = {
                ...preselectionBasket,
                persons: [
                    ...preselectionBasket.persons,
                    {
                        person: item,
                        type: 'search'
                    }
                ],
                [id]: true
            }
        } else {
            delete preselectionBasket[id];
            result = {
                ...preselectionBasket,
                persons: preselectionBasket.persons.filter(item => item.person.id !== id),
            };
        }
        props.changeBasket("preselectionEvaluate", result);
    }

    const renderPerson = data => {
        const personClick = {
            onChange: onSelectperson,
            onClick: onSelectperson,
            onImageClick: onSelectperson,
        }

        return data.map(item => (
            <EvaluationCardItemBlock key={item.id} shadow>
                <EvaluationPerson basket={preselectionBasket} {...personClick} item={item} alt={getUserFullName(item)} checkbox>
                    <EvaluationPersonData data={getUserShortName(item)} position={item.currentPosition} />
                </EvaluationPerson>
            </EvaluationCardItemBlock>
        ))
    }

    const slideChange = (e, array) => {
        const param = {
            id: array[e],
            key: e
        }
        props.setSelectes(param);
    }

    const onChangeSearchTerm = e => {
        setSearchTerm(e.target.value);
    };

    const onSearchFieldKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setSearchResult([]);
            onSearch(e);
        }
    };
    const handleLoadMore = () => {
        setHasMore(false)
        onSearch({ pageNum: paging.pageNum + 1 });
    };

    const searchData = useCallback((pageNum, searchTerm) => {
        setSearchLoading(true)
        return fullTextSearchSelect({
            filter: {
                fields: [
                    {
                        category: "Persons",
                        field: "FullName",
                        operation: "contains",
                        value: searchTerm
                    }
                ]
            }, paging: { pageNum: pageNum, pageSize: 10 } });
    }, []);

    const prevSearchTerm = usePrevious(searchTerm);

    const onSearch = async ({ pageNum = 1, ...tail }) => {
        if (searchTerm.trim() === '' || searchLoading || (pageCount !== 0 && pageCount < pageNum)) { return }

        const newTerm = prevSearchTerm !== searchTerm || tail.key === "Enter";
        if (newTerm) setSearchResult([]);

        setPaging({ pageNum: pageNum, pageSize: 10 });

        const result = await searchData(pageNum, searchTerm);
        const resultPageCount = result.data.meta.pageCount;

        const previousResult = newTerm ? [] : searchResult;
        setSearchResult([...previousResult, ...result.data.payload]);

        if (pageCount !== resultPageCount) setPageCount(resultPageCount);
        setHasMore(pageCount > pageNum);
        setSearchLoading(false);
    };

    const onSubmit = () => {
        acceptChoice(props.changeBasket, preselectionBasket);
        push(lkDetailsEvaluateCart.url);
    }

    const personsSelected = () => {
        return preselectionBasket && preselectionBasket.persons && preselectionBasket.persons.length > 0;
    }

    return (
        <div className={classnames('DetailsCard', { 'DetailsCard--isMobileActive': isMobileActive })}>
            <MainCardMenu main menuItems={evaluateCardMenuItems} />
            <div className='LKProgressSearchLine'>
                <Input
                    value={searchTerm}
                    onChange={onChangeSearchTerm}
                    onKeyPress={onSearchFieldKeyPress}
                />
                <Button onClick={onSearch} />
            </div>
            <div className={classnames("EvaluationSearch", {'EvaluationSearch--with-footer': personsSelected()})}>
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={hasMore}
                    initialLoad={false}
                    useWindow={false}
                >
                    <div>
                        <Slider
                            data={renderEvaluationType(EVALUATION_TYPE, true)}
                            initialSlide={props.selectedTest.key}
                            slideChange={slideChange}
                            resultIds={resultIds}
                        />
                    </div>
                    {searchResult && renderPerson(searchResult)}
                    {searchLoading && <Loader />}

                </InfiniteScroll>
            </div>
            {personsSelected() && 
                <StickyFooter>
                    <RoundedButton onClick={onSubmit}>
                        Выбрать ({preselectionBasket.persons && preselectionBasket.persons.length})
                    </RoundedButton>
                </StickyFooter>
            }
        </div>
    );
};

EvaluationSearch.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const mapStateToProps = state => (
    {
        personId: state.auth.user.personId,
        selectedTest: state.evaluation.selected,
        preselectionBasket: state.baskets.preselectionEvaluate.basket,
        basket: state.baskets.evaluate.basket,
        favorites: state.wishlists,
    }
);
const actions = { push, fetchTeams, fetchEvaluation, changeBasket, getFavoritesWishlist, setSelectes };

export default connect(mapStateToProps, actions)(EvaluationSearch);