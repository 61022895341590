export const FILTERS_FILTER_MENU_ID = 'filters';
export const FILTERS_FILTER_MENU_ID_OLD = 'filters_old';
export const SEARCH_TEMPLATE_MENU_ID = 'searchTemplate';
export const FAVORITES_MENU_ID = 'favorites';

export const filtersMenu = [
    {
        id: FILTERS_FILTER_MENU_ID,
        name: 'Фильтры',
    },
    {
        id: FILTERS_FILTER_MENU_ID_OLD,
        name: 'Фильтры',
    },
    {
        id: SEARCH_TEMPLATE_MENU_ID,
        name: 'Запросы',
    },
    {
        id: FAVORITES_MENU_ID,
        name: 'Подборки',
    },
];
export const MIN_AGE = 14;
export const MAX_AGE = 100;

export const AGE_FILTER_ID = 'age';
export const PROFESSION_FILTER_ID = 'profession';
export const EDUCATION_FILTER_ID = 'education';
export const REGIONS_FILTER_ID = 'regions';
export const COMPITENCIES_FILTER_ID = 'competencies';
export const SOCIAL_FILTER_ID = 'social';
export const MANAGEMENT_FILTER_ID = 'management';
export const WISHLIST_FILTER_ID = 'wishlist';

const parentField = (children, type = '', props) => ({
    type,
    children,
    ...props,
});

export const FIELDS_TYPE = {
    year: 'year',
    input: 'input',
    number: 'number',
    panel: 'panel',
    selectFilters: 'selectFilters',
    date: 'date',
    select: 'select',
    multiSelect:'multiSelect'
};

const FIELDS = {
    Age: [
        {
            type: FIELDS_TYPE.input,
            name: 'ageFrom',
            label: 'возраст от',
            column: 'Age',
            table: 'Persons',
            size: 50,
            inputProps: { type: 'number', min: 14, max: 100 },
        },
        {
            type: FIELDS_TYPE.input,
            name: 'ageTo',
            label: 'возраст до',
            size: 50,
            inputProps: { type: 'number', min: 14, max: 100 },
        },
    ],
    BirthDate: [
        {
            type: FIELDS_TYPE.date,
            label: 'Дата рождения больше',
            name: 'startBD',
            column: 'BirthDate',
            table: 'Persons',
        },
        {
            type: FIELDS_TYPE.date,
            label: 'Дата рождения меньше',
            name: 'endBD',
            column: 'BirthDate',
            table: 'Persons',
        },
    ],

    lastName: {
        type: FIELDS_TYPE.input,
        label: 'Фамилия',
        column: 'BirthDate',
        name: 'lastName',
        table: 'Persons',
    },
    firstName: {
        type: FIELDS_TYPE.input,
        label: 'Имя',
        name: 'firstName',
        column: 'BirthDate',
        table: 'Persons',
    },
    middleName: {
        type: FIELDS_TYPE.input,
        label: 'Отчество',
        name: 'middleName',
        column: 'BirthDate',
        table: 'Persons',
    },
    sex: {
        type: FIELDS_TYPE.input,
        label: 'Пол',
        name: 'sex',
        column: 'BirthDate',
        table: 'Persons',
    },
    documentType: {
        type: FIELDS_TYPE.input,
        label: 'Документ',
        column: 'BirthDate',
        name: 'documentType',
        table: 'Persons',
    },
    documentNumber: {
        type: FIELDS_TYPE.input,
        label: 'Номер документа',
        column: 'BirthDate',
        name: 'documentNumber',
        table: 'Persons',
    },
    documentName: {
        type: FIELDS_TYPE.input,
        label: 'Название документа',
        column: 'BirthDate',
        name: 'documentName',
        table: 'Persons',
    },
    phone: {
        type: FIELDS_TYPE.input,
        label: 'Телефон',
        column: 'BirthDate',
        name: 'phone',
        table: 'Persons',
    },
    email: {
        type: FIELDS_TYPE.input,
        label: 'E-mail',
        column: 'BirthDate',
        name: 'email',
        table: 'Persons',
    },
    personExtraInfo: {
        type: FIELDS_TYPE.input,
        label: 'Биографическая справка',
        column: 'BirthDate',
        name: 'personExtraInfo',
        table: 'Persons',
    },
    familyStatus: {
        type: FIELDS_TYPE.input,
        label: 'Семейный статус',
        column: 'BirthDate',
        name: 'familyStatus',
        table: 'Persons',
    },
    childrenStatus: {
        type: FIELDS_TYPE.input,
        label: 'Кол-во детей',
        column: 'BirthDate',
        name: 'childrenStatus',
        table: 'Persons',
    },
    recommenderName: {
        type: FIELDS_TYPE.input,
        label: 'Рекомендатель',
        column: 'BirthDate',
        name: 'recommenderName',
        table: 'Persons',
    },
    curatorName: {
        type: FIELDS_TYPE.input,
        label: 'Куратор',
        column: 'BirthDate',
        name: 'curatorName',
        table: 'Persons',
    },
};

const AgeFilters = [
    parentField(
        [
            parentField(FIELDS.Age, FIELDS_TYPE.panel, {
                name: 'Age',
                label: 'Возраст (полных лет)',
            }),
            parentField(FIELDS.BirthDate, FIELDS_TYPE.panel, {
                name: 'BirthDate',
                label: 'Дата рождения',
            }),
            FIELDS.lastName,
            FIELDS.firstName,
            FIELDS.middleName,
            FIELDS.sex,
            FIELDS.documentType,
            FIELDS.documentNumber,
            FIELDS.documentName,
            FIELDS.phone,
            FIELDS.email,
            FIELDS.personExtraInfo,
            FIELDS.familyStatus,
            FIELDS.childrenStatus,
            FIELDS.recommenderName,
            FIELDS.curatorName,
        ],
        FIELDS_TYPE.selectFilters,
    ),
];

export const filters = [
    {
        id: AGE_FILTER_ID,
        name: 'Возраст',
        menuFilterId: FILTERS_FILTER_MENU_ID,
        items: AgeFilters,
    },
    { id: PROFESSION_FILTER_ID, name: 'Профессия', menuFilterId: FILTERS_FILTER_MENU_ID },
    { id: EDUCATION_FILTER_ID, name: 'Образование', menuFilterId: FILTERS_FILTER_MENU_ID },
    { id: REGIONS_FILTER_ID, name: 'Связь с регионами', menuFilterId: FILTERS_FILTER_MENU_ID },
    {
        id: SOCIAL_FILTER_ID,
        name: 'Общественная деятельность',
        menuFilterId: FILTERS_FILTER_MENU_ID,
    },
    {
        id: MANAGEMENT_FILTER_ID,
        name: 'Управленческий уровень',
        menuFilterId: FILTERS_FILTER_MENU_ID,
    },
    { id: WISHLIST_FILTER_ID, name: 'Подборки', menuFilterId: FILTERS_FILTER_MENU_ID },
];
