import { useCallback, useEffect } from 'react';
import PropTypes from "prop-types";

let _counter = 0;
let _imagesLength = 0;

const SuspenseImages = props => {
    const { onComplete, startWaiting } = props;

    const incrementCounter = useCallback(() => {
        _counter++;
        if (_counter === _imagesLength && document.fonts) {
            document.fonts.ready.then(() => onComplete());
        }
    }, [onComplete]);


    const setUpListeners = useCallback(() => {
        const imgs = document.images;
        _imagesLength = imgs.length;

        imgs.forEach(img => {
            if(img.complete)
                incrementCounter();
            else
                img.addEventListener('load', incrementCounter, false );
        });
    }, [incrementCounter]);

    const removeListener = useCallback(() => {
        const imgs = document.images;

        imgs.forEach(img => {
            img.removeEventListener('load', incrementCounter );
        });
    }, [incrementCounter]);


    useEffect(() => {
        if (startWaiting) {
            setUpListeners();
        }
        
        return () => {
            removeListener()
        };
    }, [startWaiting, setUpListeners, removeListener]);

    return props.children;
};

SuspenseImages.propTypes = {
    onComplete: PropTypes.func,
    startWaiting: PropTypes.bool
};

SuspenseImages.defaultProps = {
    startWaiting: true
};

export default SuspenseImages;
