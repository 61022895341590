import React from 'react';
import PropTypes from 'prop-types';
import { formatDate, isNullOrWhitespace } from 'utils';
import { converter } from 'libs/showdown';
import './IssueItem.scss';

const IssueItem = (props) => {
    const { onClick, title, description, dueDate, withActions = false } = props;
    
    const renderAction = action => {
        return (
            <div key={action.id} className={`IssueItem__Actions--${action.color}`} onClick={action.onClick} data-test={action.dataTest}>
                {action.text}
            </div>
        );
    };

    return (
        <div className="IssueItem" onClick={onClick} data-test={`IssueItemTitle--text`}>
            <div className="IssueItem__Head">
                <div className="IssueItem__Icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="-0.0463112" y="8" width="11" height="11" rx="1.5" transform="rotate(-45 -0.0463112 8)" stroke="#0A84FF"/>                    </svg>
                </div>
                <h5 className="IssueItem__Title" data-test="IssueElement_Title-text">Задачи</h5>
                <div className="IssueItem__Date" data-test="IssueElement_Date--text">
                    {dueDate ? `${formatDate(dueDate, 'dddd')},  ${formatDate(dueDate, 'DD.MM')}`: ''}
                </div>
                
            </div>
            <div className="IssueItem__Body" data-test="IssueElement_Header--text">
                {!isNullOrWhitespace(title) ? title : <i>(Без названия)</i>}
            </div>
            {description && <div className="IssueItem__Footer" data-test="IssueElement_Footer--section"
                dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(description),
                }}
            />}

            {withActions && Array.isArray(withActions) && withActions.length !== 0 && (
                <div className="IssueItem__Actions">
                    {withActions.map(x => renderAction(x))}
                </div>
            )}

        </div>
    );
};

IssueItem.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    dueDate: PropTypes.string,
};

export default IssueItem;