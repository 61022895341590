import React from 'react';
import { ACTIVE_MAIN_CARD } from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import CardItemExpert from 'components/Lk/Common/CardItemPerson/CardItemExpert';

const ContentPage = props => {
    const { setActiveCard } = props;
    setActiveCard(ACTIVE_MAIN_CARD);

    return (
        <>
            <MainCardMenu main/>
            <div className='LKMainCardServices'>
                <CardItemExpert useShortInfo />
                <div className="LKMainCardServices__Item">
                    <h3 className="LKLayout__Title">Сегодня</h3>
                    <div className="LKMainCardServices__Item-empty">Новостей нет</div>
                </div>
            </div>
        </>
    );
};

export default ContentPage;