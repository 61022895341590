import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from "connected-react-router";
import moment from 'moment';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { getPersonGrowthResourceById, getResourceProviderById, getPersonGrowthResourceSchedule } from 'api';
import { resourceTypes, educationTypes } from 'constants.js';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Select from 'components/uikit/Select';
import Button from 'components/uikit/Button';
import DatePicker from 'components/uikit/DatePicker';
import CheckCard from 'components/common/CheckCard';
import { Grid, Row, Col } from 'react-flexbox-grid';

import './EventDateCreation.scss';

const scheduleRecordsFilterOptions = [
    {code:"subscriptionStarts", label: "дата старта потока"}, 
    {code:"subscriptionEnds", label:"дата окончания потока"}
];

const EventDateCreation = (props) => {
    
    const [resource, setResource] = useState(null);
    const [provider, setProvider] = useState(null);
    const [scheduleRecords, setScheduleRecords] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [searchBy, setSearchBy] = useState(scheduleRecordsFilterOptions[0]);

    useEffect(() => {
        let mounted = true;
        
        const fetchData = async () => {
            try {
                showPageLoader();
                const resourceData = await getPersonGrowthResourceById(props.resourceId);
                if (resourceData  && resourceData.data) {
                    setResource(resourceData.data);
                    
                    const resource = resourceData.data;
                    if (resource.providerId) {
                        const providerData = await getResourceProviderById(resource.providerId);
                        providerData && providerData.data && setProvider(providerData.data);
                    }
                }

                const scheduleData = await getPersonGrowthResourceSchedule(props.resourceId);
                if (mounted && scheduleData && scheduleData.data) {
                    setScheduleRecords(scheduleData.data);
                    setLoaded(true);
                }
            } catch(error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchData();
        
        return () => { mounted = false };
    }, [props.resourceId]);
   
    const renderResourceName = () => {
        return (
            <Field>
                <Label>Программа</Label>
                <div>{resource.name}</div>                 
            </Field>  
        );
    };
    
    const renderResourceType = () => {
        return (
            <Field>
                <Label>Тип обучения</Label>
                <div>{resourceTypes.find(x => x.type === resource.type).name} / {educationTypes.find(x => x.type === resource.educationType).name}</div>
            </Field>
        );
    };
    
    const renderProviderName = () => {
        return (
            <Field>
                <Label>Организация</Label>
                <div>{provider.title}</div>
            </Field>
        );
    };
    
    const renderResourceScheduleItem = (item) => {
      
        const scheduleRecordDates = moment(item.subscriptionStarts).format('L') + ' / ' + 
                                    moment(item.subscriptionEnds).format('L');
        
        const handleRecordChange = item => checked => {
            if (checked) {
                props.scheduleRecordChange(item);
            } else {
                props.scheduleRecordChange(null);
            }
        };
        return (
            <tr key={item.id}>
                <td>
                    <CheckCard
                        title={scheduleRecordDates}
                        value={item.id}
                        checked={props.scheduleId === item.id || false}
                        onChange={handleRecordChange(item)}
                        disabled={moment(item.subscriptionEnds).endOf('day').isBefore(moment(), 'second')}
                    />
                </td>
                <td>
                    <div className="EventDateCreationRow">
                        { item.price && <span className="EventDateCreationRow__PriceTitle">Стоимость: </span>}
                        { item.price && (parseFloat(item.price).toLocaleString('ru') + ' \u20BD') }
                    </div>
                </td>
            </tr>
        );    
    };

    const handleFilterSelectChange = (value) => {
        setSearchBy(value);
    };
    const renderFilterSelect = () => {
        return (
            <Field className="EventDateCreationSelect">
                <Label>Отфильтровать по</Label>
                <Select
                    value={searchBy || scheduleRecordsFilterOptions[0]}
                    options={scheduleRecordsFilterOptions}
                    onChange={handleFilterSelectChange}
                    
                />
            </Field>
        );
    };
    
    const handleFilterStartDateChange = value => setFromDate(value);
    const renderFilterStartDate = () => {
        return (
            <Field>
                <Label>Дата с</Label>
                <DatePicker
                    className="EventDateCreation__DatePicker"
                    selected={fromDate || new Date(moment().startOf('year').toString()) }
                    onChange={handleFilterStartDateChange}
                />
            </Field>
        );
    };

    const handleFilterEndDateChange = (value) => setToDate(value);
    const renderFilterEndDate = () => {
        return (
            <Field>
                <Label>Дата по</Label>
                <DatePicker
                    className="EventDateCreation__DatePicker"
                    selected={toDate || new Date(moment().endOf('year').toString()) }
                    onChange={handleFilterEndDateChange}
                />
            </Field>
        );
    };

    const handleFilterClearBtnOnClick = () => {
        setSearchBy(scheduleRecordsFilterOptions[0]);
        setFromDate(null);
        setToDate(null);
    };
    
    const renderFilterClearButton = () => {
        return (
            <Button onClick={handleFilterClearBtnOnClick} closeLink>Очистить</Button>
        );
    };

    const renderScheduleFilter = () => {
        return (
            <Row between="xs" bottom="xs">
                <Col className="EventDateCreation__FilterColumn">
                    { renderFilterSelect() }
                </Col>
                <Col className="EventDateCreation__FilterColumn">
                    { renderFilterStartDate() }
                </Col>
                <Col className="EventDateCreation__FilterColumn">
                    { renderFilterEndDate() }
                </Col>
                <Col>
                    { renderFilterClearButton() }
                </Col>
            </Row>
        );
    };

    const isCourseNotFinished = (scheduleRecord) => {

        const isNowBeforeSubscriptionEnds = () => {
            return moment().isBefore(moment(scheduleRecord.subscriptionEnds).endOf('day'), 'second');
        };

        if (scheduleRecord && scheduleRecord.subscriptionEnds &&
            !isNowBeforeSubscriptionEnds() ) {
            return false;
        }
        return true;
    };

    const filterByStartDate = (record) => {
        if (fromDate != null) {
            return moment(record[searchBy.code]).startOf('day').isSameOrAfter(moment(fromDate).startOf('day'), 'second');
        }
        return true;
    };
    
    const filterByEndDate = (record) => {
        if (toDate != null) {
            return moment(record[searchBy.code]).endOf('day').isSameOrBefore(moment(toDate).endOf('day'), 'second');
        }
        return true;
    }; 
    
    const applyFilter = (record) => {
        let predicate = isCourseNotFinished(record);
        predicate = filterByStartDate(record) && predicate;
        predicate = filterByEndDate(record) && predicate;
        return predicate;
    };
    
    const renderResourceSchedule = () => {
        return (
            <div>
                { renderScheduleFilter() }
                <table>
                    <tbody>
                        { !!scheduleRecords && !!scheduleRecords.length && scheduleRecords
                                .filter(x => applyFilter(x))
                                .map(renderResourceScheduleItem)
                        }
                        { !!scheduleRecords && !!scheduleRecords.length && scheduleRecords.filter(x => !isCourseNotFinished(x)).map(renderResourceScheduleItem) }
                    </tbody>
                </table>
            </div>
        )
    };
    
    const renderEducationStart = () => {
        return (
            <Field>
                <Label>Дата с</Label>
                <DatePicker
                    className="EventDateCreation__DatePicker"
                    selected={(props.educationStart && new Date(props.educationStart)) || 
                    (props.scheduleId && scheduleRecords.length && new Date(scheduleRecords.find(x => x.id === props.scheduleId).subscriptionStarts)) || ""}
                    onChange={value => props.onChange('educationStart', value)}
                />
            </Field>
        )
    };
    
    const renderEducationEnd = () => {
        return (
            <Field>
                <Label>Дата по</Label>
                <DatePicker
                    className="EventDateCreation__DatePicker"
                    selected={(props.educationEnd && new Date(props.educationEnd)) ||  
                    (props.scheduleId && scheduleRecords.length && new Date(scheduleRecords.find(x => x.id === props.scheduleId).subscriptionEnds)) || ""}
                    onChange={value => props.onChange('educationEnd', value)}
                />
            </Field>
        );
    };

    return (
        <Grid fluid>
            <Col className="EventDateCreation">
            {
                resource && provider && loaded && !!scheduleRecords.length &&
                <div>
                    <Row>
                        { renderResourceName() }
                    </Row>
                    <Row>
                        { renderResourceType() }
                    </Row>
                    <Row>
                        { renderProviderName() }
                    </Row>
                    <Row>
                        <Field>
                            <Label>Выберите поток</Label>
                        </Field>
                    </Row>
                    <Row>
                        { renderResourceSchedule() }
                    </Row>
                    <Row className="EventDateCreation__StudyRow">
                        <Field>
                            <Label>Выберите очные даты обучения</Label>
                        </Field>
                    </Row>
                    <Row>
                        <Col className="EventDateCreation__DatePickerColumn">
                            { renderEducationStart() }
                        </Col>
                        <Col className="EventDateCreation__DatePickerColumn">
                            { renderEducationEnd() }
                        </Col>
                    </Row>
                </div>
            }
            {
                resource && loaded && !scheduleRecords.length &&
                <div>
                    <Row>
                        Указанный инструмент развития не имеет программ в расписании.
                    </Row>
                </div>
            }
            </Col>
        </Grid>
    );
};

const actions = {showErrorAlert, showPageLoader, hidePageLoader, showWarningAlert, push };
export default connect(null, actions)(EventDateCreation);