import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import EventMenu from 'components/Lk/Common/EventMenu';
import WishlistsActionMenu from 'components/Lk/Common/EventMenu/WishlistsActionMenu';
import WishlistAttributeSelector from 'components/Lk/Common/Selectors/WishlistAttributeSelector';
import { attributes } from 'components/Lk/Common/Selectors/WishlistAttributeSelector/constants';
import {
    updateWishlistsVisibility,
    deleteWishlists,
    addWishlistsComment,
    cloneWishLists,
    mergeWishLists,
    getCommemntsWishlists,
} from 'api';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import {
    WISHLIST_REMOVE,
    WISHLIST_MERGE,
    WISHLIST_COMMENT,
    WISHLIST_CLONE,
} from 'components/Lk/Common/EventMenu/constants';
import InfoField from 'components/Lk/Uikit/InfoField';
import Comment from 'components/Lk/Common/CommentsBlock/Comment';
import WishlistModal from 'components/Lk/Common/CommentsBlock/WishlistModal';
import { setMainFilter } from 'ducks/FilterSearch';
import { WISHLIST_FILTER_ID } from '../../constants';

const modalContentProps = {
    attributesContent: { id: 'attributes', header: 'Изменить видимость' },
    deleteWishlistsContent: { id: 'deleteWishlists', header: 'Удалить выбранные подборки?' },
    mergeWishlistsContent: { id: 'mergeWishlists', header: 'Слияние подборок' },

    commentWishlistsContent: {
        id: 'commentWishlists',
        header: 'Добавить заметку к выбранным подборкам',
        autofocusId: 'txtComment',
        showCloseIcon: true,
        fitScreenSize: true,
        width: '100%',
    },
    cloneWishlistsContent: { id: 'cloneWishlists', header: 'Клонировать выбранное?' },
    commentModal: {
        id: 'commentModal',
        header: 'Заметка',
        showCloseIcon: true,
        fitScreenSize: true,
        withFooter: false,
        width: '100%',
    },
};

const initialState = {
    isModalOpen: false,
    isActionsMenuOpen: false,
    modalContent: '',
    selectedAttribute: attributes.everyone,
    newWishlistName: '',
    comment: '',
    paging: { pageNum: 1, pageSize: 10 },
    popupEnabled: true,
    processing: false,
    currentComment: null,
    comments: [],
};
class MassEditMenu extends Component {
    state = initialState;

    isSelectedHasDefaultWishlist = () =>
        this.props.selectedWishlists?.includes(this.props.wishLists?.find((x) => x.isDefault)?.id);
    isSelectedHasOnlyDefaultWishlist = () =>
        this.props.selectedWishlists?.length === 1 && this.isSelectedHasDefaultWishlist();

    menuFuncs = {
        onAttributeChange: () => {
            this.onModalOpen(modalContentProps.attributesContent);
            this.props.handleStateChange({ renameWishlists: false });
            this.setState({ popupEnabled: !this.isSelectedHasOnlyDefaultWishlist() });
        },

        onRename: () => {
            this.props.handleStateChange({ renameWishlists: true, isMassEditDisabled: true });
        },

        onActionsMenu: () => {
            this.setState({ isActionsMenuOpen: true });
            this.props.handleStateChange({ renameWishlists: false });
        },
    };

    onModalClose = async () => {
        await this.setState({ isModalOpen: false });

        setTimeout(() => {
            this.setState({ ...initialState });
        }, 500);
    };

    onModalOpen = (content) => {
        this.setState({
            isModalOpen: true,
            modalContent: content,
        });
    };

    onSelectAttribute = (attribute) => () => {
        this.setState({ selectedAttribute: attribute });
    };

    changeAttributes = async () => {
        const { selectedWishlists, loadLists, wishLists } = this.props;
        const { selectedAttribute } = this.state;
        const defaultWishListId = wishLists?.find((x) => x.isDefault)?.id;

        try {
            this.setState({ processing: true });
            await updateWishlistsVisibility(
                selectedAttribute,
                selectedWishlists.filter((x) => x !== defaultWishListId),
            );
            await loadLists();
            showSuccessAlert('Подборки успешно обновлены');
        } catch (e) {
            showErrorAlert('Ошибка обновления подборок');
        } finally {
            this.setState({ processing: false });
        }
    };

    deleteWishlists = async () => {
        const {
            setSelectedWishlists,
            selectedWishlists = [],
            loadLists,
            handleStateChange,
            wishLists,
            setMainFilter,
            mainFilter,
        } = this.props;

        try {
            this.setState({ processing: true });
            const defaultWishListId = wishLists?.find((x) => x.isDefault)?.id;
            await deleteWishlists(selectedWishlists.filter((x) => x !== defaultWishListId));
            await handleStateChange({
                editWishlistsIds: [
                    ...wishLists
                        .filter(
                            (x) =>
                                !selectedWishlists
                                    .filter((x) => x !== defaultWishListId)
                                    .includes(x.id),
                        )
                        .map((w) => w.id),
                ],
            });
            await loadLists();
            const newMainFilter = mainFilter.filter((x) => x.filterId !== WISHLIST_FILTER_ID);
            setMainFilter(newMainFilter);
            showSuccessAlert('Подборки успешно удалены');
        } catch (e) {
            showErrorAlert('Ошибка удаления подборок');
        } finally {
            setSelectedWishlists([]);
            this.setState({ processing: false });
        }
    };

    mergeWishlists = async () => {
        const { selectedWishlists = [], wishLists, loadLists, handleStateChange } = this.props;
        const { newWishlistName, selectedAttribute } = this.state;

        try {
            this.setState({ processing: true });
            const newWishlist = await mergeWishLists(
                newWishlistName,
                selectedAttribute,
                selectedWishlists,
            );
            await handleStateChange({
                editWishlistsIds: [...wishLists.map((x) => x.id), newWishlist.data],
            });
            await loadLists();
            showSuccessAlert('Подборка успешно создана');
        } catch (e) {
            showErrorAlert('Ошибка слияния подборок');
        } finally {
            this.setState({ processing: false });
        }
    };

    cloneWishlists = async () => {
        const { selectedWishlists, wishLists, loadLists, handleStateChange } = this.props;

        try {
            this.setState({ processing: true });
            const createdData = await cloneWishLists(selectedWishlists);
            handleStateChange({
                editWishlistsIds: [...wishLists.map((x) => x.id), ...createdData.data],
            });
            await loadLists();
            showSuccessAlert('Подборки успешно клонированы');
        } catch (e) {
            showErrorAlert('Ошибка клонирования подборок');
        } finally {
            this.setState({ processing: false });
        }
    };

    commentWishlists = async () => {
        const { selectedWishlists } = this.props;
        const { comment } = this.state;

        try {
            this.setState({ processing: true });
            await addWishlistsComment(selectedWishlists, comment);
            showSuccessAlert('Заметка успешно добавлена');
        } catch (e) {
            showErrorAlert('Ошибка добавления заметки');
        } finally {
            this.setState({ processing: false });
        }
    };

    onMassAction = (action) => {
        switch (action) {
            case WISHLIST_REMOVE:
                return this.onDeleteWishLists();
            case WISHLIST_MERGE:
                this.setState({ popupEnabled: false });
                return this.onMergeWishLists();
            case WISHLIST_COMMENT:
                this.setState({ popupEnabled: false });
                return this.onCommentWishLists();
            case WISHLIST_CLONE:
                return this.onCloneWishlist();
            default:
                return;
        }
    };

    onDeleteWishLists = () => {
        this.onModalOpen(modalContentProps.deleteWishlistsContent);
    };

    onMergeWishLists = () => {
        this.onModalOpen(modalContentProps.mergeWishlistsContent);
    };

    onMergedNameChange = (value) => {
        this.setState({ newWishlistName: value, popupEnabled: value.trim() });
    };

    onCommentChange = (value) => {
        this.setState({ comment: value, popupEnabled: value.trim() });
    };

    onCommentWishLists = async () => {
        const { selectedWishlists } = this.props;
        const result = await getCommemntsWishlists(selectedWishlists);
        await this.setState({ comments: result.data });
        this.onModalOpen(modalContentProps.commentWishlistsContent);
    };
    onSelectComment = async (currentComment) => {
        await this.setState({ currentComment });
        this.onModalOpen({
            ...modalContentProps.commentModal,
            onModalClose: async () => {
                await this.onModalOpen(modalContentProps.commentWishlistsContent);
                await this.setState({ currentComment: null });
            },
        });
    };

    onCloneWishlist = () => {
        this.onModalOpen(modalContentProps.cloneWishlistsContent);
    };

    modalConfirmFunc = (modalContentId) => {
        switch (modalContentId) {
            case modalContentProps.attributesContent.id:
                return this.changeAttributes;
            case modalContentProps.mergeWishlistsContent.id:
                return this.mergeWishlists;
            case modalContentProps.deleteWishlistsContent.id:
                return this.deleteWishlists;
            case modalContentProps.commentWishlistsContent.id:
                return this.commentWishlists;
            case modalContentProps.cloneWishlistsContent.id:
                return this.cloneWishlists;
            default:
                return () => {};
        }
    };

    getHeaderInput = (modalContentId) => {
        switch (modalContentId) {
            case modalContentProps.commentWishlistsContent.id:
                return (
                    <InfoField
                        type="textarea"
                        isEdit
                        title="Добавить заметку"
                        value={this.state.comment}
                        onChange={this.onCommentChange}
                        noIcon
                        inputId="txtComment"
                        placeholder="Ваша заметка..."
                    />
                );
            case modalContentProps.commentModal.id:
                return <Comment comment={this.state.currentComment} />;
            default:
                return null;
        }
    };

    render() {
        const {
            isModalOpen,
            modalContent,
            isActionsMenuOpen,
            newWishlistName,
            popupEnabled,
            processing,
        } = this.state;
        const {
            setSelectedWishlists,
            selectedWishlists,
            wishLists,
            isMassEditDisabled,
        } = this.props;

        return (
            <>
                <EventMenu
                    menuFuncs={this.menuFuncs}
                    menuType="wishlistsMassEdit"
                    data={wishLists}
                    onCheck={setSelectedWishlists}
                    activeSelection={selectedWishlists}
                    allIdsList={wishLists ? wishLists.map((x) => x.id) : []}
                    buttonsToDisable={
                        this.isSelectedHasOnlyDefaultWishlist()
                            ? ['attributeChange', 'rename']
                            : null
                    }
                    forceDisabled={isMassEditDisabled}
                />

                <ModalPopup
                    isModalOpen={isModalOpen}
                    onModalClose={this.onModalClose}
                    header={modalContent.header}
                    confirm={this.modalConfirmFunc(modalContent.id)}
                    enabled={popupEnabled && !processing}
                    processing={processing}
                    autofocusId={modalContent.autofocusId}
                    headerInput={this.getHeaderInput(modalContent.id)}
                    {...modalContent}
                >
                    {modalContent.id === modalContentProps.attributesContent.id && (
                        <WishlistAttributeSelector
                            selected={this.state.selectedAttribute}
                            selectItem={this.onSelectAttribute}
                            disabled={this.isSelectedHasOnlyDefaultWishlist()}
                        />
                    )}
                    {modalContent.id === modalContentProps.mergeWishlistsContent.id && (
                        <>
                            <InfoField
                                isEdit
                                title="Название подборки"
                                value={newWishlistName}
                                onChange={this.onMergedNameChange}
                                noIcon
                            />
                            <WishlistAttributeSelector
                                selected={this.state.selectedAttribute}
                                selectItem={this.onSelectAttribute}
                            />
                        </>
                    )}
                    {modalContent.id === modalContentProps.commentWishlistsContent.id && (
                        <>
                            <div className="LKModalCommentsBlock">
                                {this.state.comments.map((x) => (
                                    <Comment
                                        key={x.operationId}
                                        comment={x}
                                        onWrap={this.onSelectComment}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                    {modalContent.id === modalContentProps.commentModal.id && (
                        <WishlistModal comment={this.state.currentComment} />
                    )}
                </ModalPopup>

                <WishlistsActionMenu
                    isOpen={isActionsMenuOpen}
                    onClose={() => this.setState({ isActionsMenuOpen: false })}
                    onAction={this.onMassAction}
                    selectedWishlists={selectedWishlists}
                    defaultWishListId={wishLists?.find((x) => x.isDefault)?.id}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    list: state.wishlists.allWishLists,
    mainFilter: state.filterSearch.mainFilter,
});
const actions = { showSuccessAlert, showErrorAlert, setMainFilter };

export default connect(mapStateToProps, actions)(MassEditMenu);
