import React from 'react';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Popover from 'components/Lk/Uikit/Popover';
import './CompetencyPersonHelp.scss';
import InputText from 'components/uikit/InputText';
import Row from 'components/uikit/Row';

const tooltip = `Не забудьте проинформировать коллегу, что вы выбрали его наставником для развития
                выбранных компетенций.`;

export const CompetencyPersonHelpTitle = () => (
    <div className="competencyPersonHelp__container__title">
        <Popover tooltip={tooltip}>
            <div
                className="tooltip-link"
                data-tip
                data-for="socialActivityHint"
                style={{ fontSize: 24 + 'px' }}
            >
                ?
            </div>
        </Popover>
    </div>
);

const CompetencyPersonHelp = ({ personGrowthAssistants, onChange, isValid }) => {
    const assistant = (personGrowthAssistants && personGrowthAssistants[0]) || { fullName: '', position: '', assistanceDetails: ''};

    const handleFullNameChange = evt =>
        onChange('personGrowthAssistants', [{ ...assistant, fullName: evt.target.value }]);

    const handlePositionChange = evt =>
        onChange('personGrowthAssistants', [{ ...assistant, position: evt.target.value }]);

    const handleAssistanceDetailsChange = evt =>
        onChange('personGrowthAssistants', [{ ...assistant, assistanceDetails: evt.target.value }]);

    const isRequired = exp =>
        exp ||
        !assistant ||
        (!assistant.fullName && !assistant.position && !assistant.assistanceDetails);

    return (
        <div className="competencyPersonHelp">
            <Row>
                <Field
                    filled={isRequired(assistant && assistant.fullName)}
                    invalid={!isRequired(assistant && assistant.fullName) && !isValid}
                    size="50"
                    required={isRequired(assistant && assistant.fullName)}
                >
                    <Label>Ф.И.О</Label>
                    <div>
                        <Input
                            className="competencyPersonHelp__input"
                            type="text"
                            value={assistant.fullName || ''}
                            onChange={handleFullNameChange}
                        />
                    </div>
                </Field>
                <Field
                    filled={isRequired(assistant && assistant.position)}
                    invalid={!isRequired(assistant && assistant.position) && !isValid}
                    size="50"
                    required={isRequired(assistant && assistant.position)}
                >
                    <Label>Должность</Label>
                    <div>
                        <Input
                            className="competencyPersonHelp__input"
                            type="text"
                            value={assistant.position || ''}
                            onChange={handlePositionChange}
                        />
                    </div>
                </Field>
            </Row>
            <Row>
                <Field
                    filled={isRequired(assistant && assistant.assistanceDetails)}
                    invalid={!isRequired(assistant && assistant.assistanceDetails) && !isValid}
                    size="100"
                    required={isRequired(assistant && assistant.assistanceDetails)}
                >
                    <Label>Чем поможет?</Label>
                    <div>
                        <InputText
                            style={{ resize: 'none', minHeight: 250 + 'px' }}
                            maxLength={4000}
                            value={assistant.assistanceDetails || ''}
                            onChange={handleAssistanceDetailsChange}
                        />
                    </div>
                </Field>
            </Row>
        </div>
    );
};

export default CompetencyPersonHelp;
