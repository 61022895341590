export const ALL_EVALUATIONS_MENU_ID = 'Full';
export const HAS_NO_EVALUATIONS_MENU_ID = 'HasNoEvaluations';
export const HAS_EVALUATIONS_MENU_ID = 'HasEvaluations';

export const hasEvaluationsMenuOptions = [
    {id: ALL_EVALUATIONS_MENU_ID, name: 'Все', type: 'Full'},
    {id: HAS_EVALUATIONS_MENU_ID, name: 'C результатами', type: 'HasEvaluations'},
    {id: HAS_NO_EVALUATIONS_MENU_ID, name: 'Без результатов', type: 'HasNoEvaluations'},
];

export const sortingOptions = [
    { id: 1, label: 'Без сортировки', type: undefined, indicator: '' },
    { id: 2, label: 'Мин', type: 'asc', indicator: 'up' },
    { id: 3, label: 'Макс', type: 'desc', indicator: 'down' },
];