import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { serviceResultCode, getError } from 'serviceErrors';
import {
    getPersonGrowthCompetencies,
    deletePersonGrowthResource as deleteResource,
    extendedSearchPersonGrowthResources,
    makeRequestCreator,
} from 'api';
import { personGrowthResourceRoute, personGrowthResourcesRoute } from 'routes';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { parse as queryStringParse, stringify } from 'query-string';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import Page from 'components/common/Page';
import DataGrid from 'components/common/DataGrid';
import Button from 'components/uikit/Button';
import uuid from 'uuid/v4';
import { resourceTypes, resourceTypeEnum } from 'constants.js';
import moment from 'moment';
import ListTemplate from 'components/uikit/ListTemplate';
import ModalDialog from 'components/common/ModalDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PersonGrowthResources.scss';

const getPersonGrowthResourcesOnce = makeRequestCreator(extendedSearchPersonGrowthResources);

const getUrlFilterParams = (state) => {
    return {
        page: state.paging.pageNum !== null ? Number(state.paging.pageNum) : 1,
        pageSize: state.paging.pageSize !== null ? Number(state.paging.pageSize) : 10,
        name: state.filter.name,
        competencies: state.filter.competencies && state.filter.competencies.map((item) => item.label),
        types: state.filter.types && state.filter.types.map((item) => item.label),
        sortingId: state.sorting[0].id,
        desc: state.sorting[0].desc ? 1 : '',
    };
};

const buildColumns = ({ deletePersonGrowthResource, location }) => [
    {
        Header: () => (
            <div data-test="PersonGrowthResourceHeaderName--text">Название инструмента</div>
        ),
        accessor: 'Name',
        resizable: false,
        sortable: true,
        Cell: ({ original }) => {
            return (
                <Link
                    className="PersonGrowthResources__ViewLinkContainer"
                    to={personGrowthResourceRoute.buildUrl(
                        { id: original.id, regime: 'view' },
                        location.search,
                    )}
                >
                    {original.name}
                </Link>
            );
        },
    },
    {
        Header: 'Тип',
        accessor: 'type',
        maxWidth: 250,
        resizeable: false,
        sortable: false,
    },
    {
        Header: () => <div data-test="PersonGrowthResourceHeaderDate-text">Дата создания</div>,
        accessor: 'created',
        resizable: false,
        sortable: true,
        Cell: ({ original }) => {
            const date = moment(original.created).format('DD.MM.YYYY').toString();
            return <div>{date}</div>;
        },
    },
    {
        Header: 'Действие',
        accessor: 'id',
        maxWidth: 150,
        resizable: false,
        sortable: false,
        Cell: ({ original }) => (
            <div className="ButtonsWrapper">
                <Link
                    className="ButtonsWrapper__Button"
                    to={personGrowthResourceRoute.buildUrl(
                        { id: original.id, regime: 'edit' },
                        location.search,
                    )}
                >
                    <Button
                        size="sm"
                        data-test={`PersonGrowthResourceEdit--id_${original.id}--button`}
                    >
                        <FontAwesomeIcon icon="edit" />
                    </Button>
                </Link>
                <Button
                    size="sm"
                    onClick={deletePersonGrowthResource(original)}
                    className="ButtonsWrapper__Button"
                    color="danger"
                    type="button"
                    data-test={`PersonGrowthResourceDelete--id_${original.id}--button`}
                >
                    <FontAwesomeIcon icon="trash" />
                </Button>
            </div>
        ),
    },
];

const initialState = {
    filter: {
        name: '',
        types: [],
        competencies: [],
    },
    columns: [],
    current: null,
    sorting: [{ id: 'Name', desc: false }],
    paging: { pageNum: 1, pageSize: 10 },
    data: [],
    isDeleting: false,
    fetchedData: {
        payload: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
    competencies: [],
};

const getValuesFromURL = (string, dictionary) => {
    return (
        string &&
        dictionary
            .map((item) => {
                if (string.includes(item.name)) {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                } else return null;
            })
            .filter((x) => x !== null && x !== undefined)
    );
};

class PersonGrowthResources extends Component {
    state = initialState;

    async componentDidMount() {
        await this.loadCompetencies();
        const { paging, filter, sorting } = this.getStateFromUrl();
        this.setState(
            {
                types: resourceTypes.filter((x) => !x.hidden),
                paging,
                filter,
                sorting,
            },
            () => this.performPersonGrowthResourcesFetching(),
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            const { paging, filter, sorting } = this.getStateFromUrl();
            this.setState({ paging, filter, sorting }, () =>
                this.performPersonGrowthResourcesFetching(),
            );
        }

        const { competencies } = this.state;
        if (
            JSON.stringify(prevState.competencies) !== JSON.stringify(competencies) &&
            competencies.length === 0
        ) {
            this.loadCompetencies();
        }
    }

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, () => this.updateURL());
    };

    getStateFromUrl = () => {
        const { competencies } = this.state;
        const {
            types = [],
            competencies: competenciesQuery = [],
            name = '',
            page = 1,
            pageSize = 10,
            sortingId = 'Name',
            desc = '',
        } = queryStringParse(this.props.location.search);
        const filterTypesValues = types && getValuesFromURL(types, resourceTypes);
        const filterCompetenciesValues = competencies && competenciesQuery && getValuesFromURL(competenciesQuery, competencies);
        return {
            paging: {
                ...this.state.paging,
                pageNum: +page,
                pageSize: +pageSize,
            },
            filter: {
                name,
                types: filterTypesValues,
                competencies: filterCompetenciesValues,
            },
            sorting: [
                {
                    id: sortingId,
                    desc: !!desc,
                },
            ],
        };
    };

    handleStateChange = (blockStateKey, blockState) => {
        this.setState((state) => {
            return {
                [blockStateKey]: {
                    ...state[blockStateKey],
                    ...blockState,
                },
            };
        });
    };

    updateURL = () => {
        const filter = getUrlFilterParams(this.state);
        const baseFilter = { ...filter };

        this.props.push({
            pathname: personGrowthResourcesRoute.url,
            search: stringify(baseFilter, { skipEmptyString: true }),
        });
    };

    newPersonGrowthResource = () => {
        this.props.push({
            pathname: personGrowthResourceRoute.buildUrl({ id: uuid(), regime: 'new' }),
            search: this.props.location.search,
        });
    };

    viewPersonGrowthResource = (id) => {
        this.props.push(personGrowthResourceRoute.buildUrl({ id, regime: 'view' }), {
            search: this.props.location.search,
        });
    };

    deletePersonGrowthResource = (record) => () => {
        this.setState({ current: { ...record } });
    };

    onSortedChange = (sorted) => {
        this.setState(
            {
                ...this.state,
                sorting: sorted,
            },
            () => this.updateURL(),
        );
    };

    onClearFilters = (e) => {
        e.preventDefault();
        this.clearFilters();
    };

    clearFilters = () => {
        if (window.location.search !== '?page=1') {
            this.setState({ ...initialState }, () =>
                this.props.push({
                    pathname: personGrowthResourcesRoute.url,
                    search: `?page=1`,
                }),
            );
        } else {
            if (JSON.stringify(this.state.filter) !== JSON.stringify(initialState.filter)) {
                this.setState({ filter: initialState.filter });
            }
        }
    };

    fillSelect = (dictionary) => {
        if (dictionary) {
            return dictionary.map((x) => {
                return { value: x.id, label: x.name };
            });
        } else {
            return [];
        }
    };

    onPageChange = (pageIndex) => {
        const { paging } = this.state;
        this.setState({ paging: { ...paging, pageNum: pageIndex + 1 } }, () => this.updateURL());
    };

    onFilterSubmit = () => {
        const { paging } = this.state;
        this.setState({ paging: { ...paging, pageNum: 1 }, sorting: initialState.sorting }, () =>
            this.updateURL(),
        );
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            await callback();
        } catch (error) {
            const reqError = getError(error, this.getResourceListError);
            this.props.showErrorAlert(reqError.message);
        } finally {
            this.props.hidePageLoader();
        }
    };

    getResourceListError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `${payload}`;
            case serviceResultCode.PersonGrowthResourceListError:
                return `${payload}`;
            case serviceResultCode.PersonGrowthResourceIsInUse:
                return `Инструмент развития удалить нельзя, так как он используется.`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    performPersonGrowthResourcesFetching = async () => {
        const { paging, sorting, filter } = this.state;

        const types =
            filter.types && filter.types.length > 0
                ? filter.types.map((x) => x.value)
                : resourceTypes.filter((x) => !x.hidden).map((x) => x.id);

        const competencyIds = filter.competencies ? filter.competencies.map((x) => x.value) : null;

        const microCourseId = resourceTypes.find((i) => i.type === resourceTypeEnum.Microcourse).id;
        const courseId = resourceTypes.find(
            (i) => i.type === resourceTypeEnum.CourseScormTincan,
        ).id;

        if (types.includes(courseId)) {
            types.push(microCourseId);
        }
        const requestCriteria = {
            filter: { ...filter, types, competencyIds },
            paging,
            name: '',
            sorting: { [sorting[0].id]: sorting[0].desc ? 'desc' : 'asc' },
        };

        this.callEffect(async () => {
            const response = await getPersonGrowthResourcesOnce(requestCriteria);

            if (response && response.status === 200 && response.data.payload) {
                response.data.payload = response.data.payload.map((resource) => ({
                    ...resource,
                    // TODO: Костыль! сливаем 2 категории в 1
                    type:
                        resource.type === resourceTypeEnum.Microcourse
                            ? resourceTypeEnum.CourseScormTincan
                            : resource.type,
                }));

                this.setState({
                    fetchedData: response.data,
                });
            }
        });
    };

    loadCompetencies = async () => {
        try {
            const response = await getPersonGrowthCompetencies();
            this.setState(
                {
                    ...this.state,
                    competencies: response.data,
                },
                () => {
                    return true;
                },
            );
        } catch (e) {
            this.props.showErrorAlert('Ошибка загрузки компетенций.');
        }
    };

    getFriendlyName = (resourceType) => {
        if (resourceType) {
            const searchType =
                resourceType === resourceTypeEnum.Microcourse
                    ? resourceTypeEnum.CourseScormTincan
                    : resourceType;
            return resourceTypes.find((i) => i.type === searchType).name;
        }
    };

    handleFilterTypeOnChange = (item) => {
        this.handleStateChange('filter', { types: item });
    };

    handleFilterTagOnChange = (item) => {
        this.handleStateChange('filter', { competencies: item });
    };

    deleteRequest = async () => {
        const { current } = this.state;
        this.setState({ isDeleting: true });
        try {
            await deleteResource(current.id);
            this.performPersonGrowthResourcesFetching();
            this.props.showSuccessAlert('Инструмент развития успешно удален');
        } catch (error) {
            const reqError = getError(error, this.getResourceListError);
            this.props.showErrorAlert(reqError.message);
        }
        this.setState({ isDeleting: false, current: null });
    };

    onHide = () => this.setState({ current: null });
    handleFilterNameInputChange = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                name: e.target.value,
            },
        });
    };

    handleEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            this.handleFilterNameInputChange(e);
            this.onFilterSubmit();
        }
    };

    render() {
        const customSelectStyles = {
            control: (base) => ({
                ...base,
                backgroundColor: '#f9f9ff',
                borderColor: '#e6dfff',
            }),
            input: (base) => ({
                ...base,
                padding: 0,
                margin: 0,
            }),
            menu: (base) => ({
                ...base,
                color: '#767268',
            }),
            singleValue: (base) => ({
                ...base,
                color: '#767268',
            }),
            valueContainer: (base) => ({
                ...base,
            }),
        };

        const { types, current, competencies, fetchedData, paging, isDeleting } = this.state;

        const { pageCount } = fetchedData.meta;

        const filterForm = {
            title: 'Фильтр',
            line: [
                {
                    type: 'Input',
                    label: 'Инструмент развития',
                    id: 'name',
                    name: 'name',
                    value: this.state.filter.name || '',
                    onChange: this.handleFilterNameInputChange,
                    onKeyDown: this.handleEnterKeyPressed,
                    'data-test': 'PersonGrowthResourceFilterName--input',
                },
                {
                    type: 'select',
                    label: 'Тип',
                    value: this.state.filter.types,
                    inputId: 'selectedType',
                    placeholder: 'Выберите тип',
                    options: this.fillSelect(types),
                    onChange: this.handleFilterTypeOnChange,
                    isMulti: true,
                    styles: customSelectStyles,
                    dataTest: 'PersonGrowthResourceFilterType',
                },
                {
                    type: 'select',
                    label: 'Компетенции',
                    value: this.state.filter.competencies,
                    inputId: 'selectedTag',
                    placeholder: 'Выберите компетенции',
                    options: this.fillSelect(competencies),
                    onChange: this.handleFilterTagOnChange,
                    isMulti: true,
                    styles: customSelectStyles,
                    dataTest: 'PersonGrowthTagsFilterType',
                },
            ],
            btnOnClick: this.onFilterSubmit,
            btnClearOnClick: this.onClearFilters,
            dataTest: 'PersonGrowthFilter',
        };

        const headBtn = {
            onClick: this.newPersonGrowthResource,
            value: 'Создать инструмент развития',
            addLink: true,
            'data-test': 'CreatePersonGrowthResource--button',
        };

        return (
            <Page>
                <div className="resources-wrapper">
                    <ListTemplate
                        title={'Инструменты развития'}
                        headBtn={headBtn}
                        form={filterForm}
                    >
                        <DataGrid
                            data={this.state.fetchedData.payload?.map((x) => ({
                                ...x,
                                type: this.getFriendlyName(x.type),
                            }))}
                            columns={buildColumns({
                                deletePersonGrowthResource: this.deletePersonGrowthResource,
                                location: this.props.location,
                            })}
                            showPagination
                            showPageSizeOptions={false}
                            sorted={this.state.sorting}
                            onSortedChange={this.onSortedChange}
                            foundCount={+this.state.fetchedData.meta.foundCount}
                            pages={+pageCount}
                            page={+paging.pageNum}
                            onPageChange={this.onPageChange}
                            manual
                            dataTest="PersonGrowthResources"
                            changePageAmount={this.changePageAmount}
                            pageSize={this.state.paging.pageSize}
                        />
                        <ModalDialog
                            onClick={this.deleteRequest}
                            onCloseModal={this.onHide}
                            modalOpen={!!current}
                            modalHeader={`Вы уверены, что хотите удалить инструмент развития?`}
                            btnOktext="Удалить"
                            btnCanceltext="Отмена"
                            btnOkColor="danger"
                            isDangerHeader="true"
                            processing={isDeleting}
                            dataTest="PersonGrowthResourceDelete"
                        ></ModalDialog>
                    </ListTemplate>
                </div>
            </Page>
        );
    }
}

function mapStateToProps(state) {
    return {
        search: state.router.location.search,
    };
}

const actions = { showPageLoader, hidePageLoader, showErrorAlert, showSuccessAlert, push };

export default connect(mapStateToProps, actions)(PersonGrowthResources);
