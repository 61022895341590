import React from 'react';
import List from './List';

export const TreeView = props => {
    const { items, onClick, labelField = 'label' } = props;

    return (
        <div>
            <List labelField={labelField} items={items} onClick={onClick} />
        </div>
    );
};

export default TreeView;
