import React from 'react';
import BaseSearch from './BaseSearch';

const MasterSearch = () => {
    return (
        <div className="MasterSearchContainer">
            <BaseSearch />
        </div>
    );
};

export default MasterSearch;
