import React, { useState, useEffect } from 'react';
import SearchGroupCondition from 'components/Lk/Shared/Search/SearchGroupCondition';

const SearchTopMenu = props => {

    const cardRefs = {
        SearchTopMenu: React.createRef(),
    };

    const { titleBtn, condition, isCloseSearchFilter = false, groupCondition, handleGroupConditionChange, fullTextSearch } = props;
    const [decrease, setDecrease] = useState(true);

    useEffect(() => {
        if (isCloseSearchFilter) {
            setDecrease(true);
            condition(true, cardRefs.SearchTopMenu.current.offsetTop - 140);
        }
    }, [isCloseSearchFilter, condition, cardRefs.SearchTopMenu]);

    const ContentClassName = decrease ? ' SearchTopMenu__Content--CloseSearchFilter' : '';

    const handlerSticky = () => {
        const scrollY = window.scrollY;
        window.scrollTo({
            top: scrollY + 1,
        });
        window.scrollTo({
            top: scrollY,
        });
    }

    const onShowAllFilter = e => {
        e.preventDefault();

        const SearchTopMenuTop = cardRefs.SearchTopMenu.current.offsetTop - 140;
        const flag = window.scrollY === SearchTopMenuTop;

        if (decrease || flag) {
            handlerSticky();
        } else {
            window.scrollTo({
                top: SearchTopMenuTop,
                behavior: "smooth"
            });
        }
        setDecrease(!decrease);
    }

    const BtnShowValue = decrease ? 'Показать все фильтры' : 'Скрыть редко используемые фильтры';

    return (
        <div className="SearchTopMenu">
            <div className="SearchTopMenu__FullTextSearch">
                <h1 className="SearchTopMenu__Title">Полнотекстовый поиск</h1>
                {fullTextSearch()}
            </div>
            <div className="SearchTopMenu__Flex" ref={cardRefs.SearchTopMenu}>
                <h1 className="SearchTopMenu__Title">Фильтры</h1>
                {titleBtn && titleBtn}
            </div>
            <div className="SearchTopMenu__SubTitle">
                Выберите любое количество полей, по которым нужно найти резервистов
                <SearchGroupCondition
                    checked={groupCondition === 'OR'}
                    onClick={handleGroupConditionChange}
                    title={false}
                />
            </div>
            <div className={`SearchTopMenu__Content${ContentClassName}`}>
                {props.children[0]}
                <div className="SearchTopMenu__Content--additionally">
                    {props.children[1]}
                </div>
            </div>
            <div className="Search__Filter--btnBlock">
                <a onClick={(e) => { onShowAllFilter(e) }} className="Search__Filter--show" href="/">{BtnShowValue}</a>
                {props.children[2]}
            </div>
        </div>
    )
}

export default SearchTopMenu;