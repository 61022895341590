import React from 'react';
import ReactSelect, { components } from 'react-select';

const Option = props => {
    return (
        <div title={props.label}>
            <components.Option {...props} data-test={props.dataTest} />
        </div>
    );
};

const customStyles = {
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: 'inherit',
    }),
    control: () => ({
        border: 'none',
        display: 'flex',
    }),
    valueContainer: base => ({ ...base, padding: 0 }),
    menu: b => ({
        ...b,
        border: '1px solid rgba(60, 60, 67, 0.29)',
        listStyle: 'none',
        margin: '0',
        padding: '0',
        zIndex: '100',
    }),
    menuList: b => ({ ...b }),
};

export const Select = props => {
    const { items, isMulti, value, dataTest, styles, ...tail } = props;
    const getOptionValue = option => option.id;

    const summaryCustomStyles = styles ? {...customStyles, ...styles} : customStyles;
    
    const getOptionLabel = option => option.name;
    const valueFromId = value ? (isMulti ? value : items.find(o => o.id === value)) : null;

    return (
        <ReactSelect
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            options={items}
            isMulti={isMulti}
            styles={summaryCustomStyles}
            value={valueFromId}
            components={{ Option }}
            placeholder=""
            data-test={dataTest}
            {...tail}
        />
    );
};

export default Select;
