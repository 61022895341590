import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import Loader from 'components/common/Loader';
import classnames from 'classnames';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import './EvaluationRegionRating.scss';
import Popover from 'components/Lk/Uikit/Popover';
import { fetchRegionRating } from 'ducks/Dashboard';

class EvaluationRegionRating extends Component {
    componentDidMount() {
        this.props.fetchRegionRating();
    }

    render() {
        const { activeCard, setActiveCard, regionRating } = this.props;
        const isMobileActive = activeCard === ACTIVE_LIST_CARD;

        setActiveCard(ACTIVE_LIST_CARD);

        return (
            <div
                className={classnames('ListCard LKSearchCard', {
                    'ListCard--isMobileActive': isMobileActive,
                    'LKSearchCard--isMobileActive': isMobileActive,
                })}
            >
                <MainCardMenu defaultMenuId="favorite" />
                <div className="EvaluationRegionRating__Header">
                    Рейтинг регионов по числу резервистов с высоким уровнем способностей
                    <Popover
                        childrenClassName="InlineFlex"
                        tooltip="Рейтинг сформирован на основании среднего балла по тестам способностей: вербальной, числовой и логической секций"
                    >
                        <span className="EvaluationRegionRating__Info">
                            <Icons name="help-alt" fill={'#007AFF'} />
                        </span>
                    </Popover>
                </div>
                {!regionRating.loading ? (
                    <ol className="EvaluationRegionRating__List">
                        {regionRating.data &&
                            regionRating.data.map((item) => (
                                <li key={item.groupId}>{item.groupTitle}</li>
                            ))}
                    </ol>
                ) : (
                    <div>
                        <Loader />
                    </div>
                )}
            </div>
        );
    }
}

EvaluationRegionRating.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const actions = { push, fetchRegionRating };

const mapStateToProps = ({ dashboard }) => ({ regionRating: dashboard.regionRating });

export default connect(mapStateToProps, actions)(EvaluationRegionRating);
