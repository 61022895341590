import React, { PureComponent } from "react";
import Row from "components/uikit/Row";
import "./DataCardBlock.scss";

import ChevronButton from 'components/uikit/ChevronButton';

class DataCardBlock extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: typeof props.defaultIsOpen !== 'undefined' ? props.defaultIsOpen : true,
        };
    }

    toggleOpen = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render = () => {
        const { title, children, isRow = true } = this.props;
        const { isOpen } = this.state;

        return (
            <div className="DataCardBlock">
                <div className="DataCardBlock__Wrapper">
                    <div
                        className={
                            this.props.viewMode
                                ? 'DataCardBlock__Header-View'
                                : 'DataCardBlock__Header'
                        }
                    >
                        <div className="DataCardBlock__Title">{title}</div>
                        <ChevronButton isUp={isOpen} onClick={this.toggleOpen} />
                    </div>
                </div>
                {isOpen && (
                    <div className="DataCardBlock__Content">
                        {isRow
                            ? <Row>{children}</Row>
                            : children
                        }
                        
                    </div>
                )}
            </div>
        );
    };
}

export default DataCardBlock;
