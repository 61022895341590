import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { evaluationsModerationRoute } from 'routes';
import Evaluations from 'pages/manager/moderation/evaluations';
import NotFound from 'pages/common/NotFound';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_EVALUATIONS } from 'rightsController';

class EvaluationsRoutes extends Component {

    render() {
        return (
            <Switch>
                <ProtectedRoute exact path={evaluationsModerationRoute.url} component={Evaluations} allowedRoles={ALLOWED_MODERATE_EVALUATIONS} />             
                <Route component={NotFound} />
            </Switch>
        );
    }
}

export default EvaluationsRoutes;