import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { listManagers, person } from 'routes';
import { getUserFullName, isNullOrWhitespace } from 'utils';
import { push } from 'connected-react-router';
import Page from 'components/common/Page';
import DataGrid from 'components/common/DataGrid';
import ListTemplate from 'components/uikit/ListTemplate/ListTemplate';
import { getPublicPersonAccess, getManagers } from 'api';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { getPersonPhotoUri, photoSizes } from 'api';
import Image from 'components/common/Image';
import ListManagersMenu from 'components/Moderation/ListManagers/ListManagersMenu';
import Popover from 'components/Lk/Uikit/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ACCESS_DIRECTION, USER_ROLES } from 'constants.js';
import { parse as queryStringParse, stringify } from 'query-string';
import useUpdateEffect from 'components/Lk/Hooks/useUpdateEffect';

import './ListManagers.scss';

const getUrlFilterParams = (state) => {
    return {
        page: state.paging.pageNum !== null ? Number(state.paging.pageNum) : 1,
        pageSize: state.paging.pageSize !== null ? Number(state.paging.pageSize) : 10,
        status: state.filter.status ? state.filter.status : 'Approved',
        roles: state.filter.roles ? state.filter.roles : [],
        selectedMenu: state.selectedMenu ? state.selectedMenu : 'Leaders'
    };
};

const stateInit = {
    criteria: {
        filter: {
            status: 'Approved',
            userId: '',
            roles: ['Leader'],
        },
        paging: {
            pageNum: 1,
            pageSize: 10,
        },
    },
    payload: {
        data: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
    isMount: true,
};

const ListManagers = (props) => {
    const {
        showPageLoader,
        hidePageLoader,
        showErrorAlert,
        push,
        user: { id: userId },
    } = props;
    const [state, setState] = useState(stateInit);
    const [sorted, setSorted] = useState([{ id: 'requestedOn', desc: true }]);
    const [selectedMenu, setSelectedMenu] = useState('Leaders');
    const [stringCriteria, setStringCriteria] = useState(JSON.stringify(stateInit.criteria));

    const { payload, criteria } = state;

    const fetchModels = useCallback(
        async (criteria) => {
            try {
                showPageLoader();

                const getUser =
                    criteria.filter.status === 'Approved' &&
                    criteria.filter.roles.length === 1 &&
                    criteria.filter.roles.includes('Leader')
                        ? await getManagers(criteria)
                        : await getPublicPersonAccess(criteria);
                const accessPayload = getUser.data.payload.map((item) => {
                    return {
                        ...item.person,
                        rejectionComment: item.rejectionComment,
                        requestedOn: item.requestedOn,
                        finishedOn:
                            criteria.selectedMenu && criteria.selectedMenu !== 'Leaders'
                                ? item.finishedOn
                                : item.createdOn,
                        status: criteria.filter.status,
                        processingStatus: item.status,
                        roleRemoveReason: item.roleRemoveReason,
                        accessRequestDirection: item.accessRequestDirection,
                        role: item.role,
                    };
                });
                const { meta } = getUser.data;

                setState({
                    payload: {
                        data: accessPayload,
                        meta: {
                            foundCount: meta.foundCount,
                            pageCount: meta.pageCount,
                        },
                    },
                    criteria,
                    isMount: false,
                });
                setSelectedMenu(criteria.selectedMenu ? criteria.selectedMenu : 'Leaders');
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        },
        [hidePageLoader, showErrorAlert, showPageLoader],
    );

    const onChangeItemMenu = (e) => {
        setSelectedMenu(e.id);

        const param = {
            ...criteria,
            filter: {
                ...criteria.filter,
                status: e.status,
                roles: e.roles,
            },
            sorting: e.id !== 'Leaders' ? { requestedOn: 'Desc' } : { fullName: 'Asc' },
            paging: {
                ...criteria.paging,
                pageNum: 1,
            },
            selectedMenu: e.id,
        };

        setStringCriteria(JSON.stringify(param));
    };

    const onPageChange = (pageIndex) => {
        const param = {
            ...criteria,
            paging: {
                ...criteria.paging,
                pageNum: pageIndex + 1,
            },
        };

        setStringCriteria(JSON.stringify(param));
    };

    const changePageAmount = (pageSize) => {
        const param = {
            ...criteria,
            paging: {
                ...criteria.paging,
                pageSize,
            },
        };

        setStringCriteria(JSON.stringify(param));
    };

    const onSortedChange = (sorted) => {
        setSorted(sorted);

        const param = {
            ...criteria,
            sorting: { [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        };
        
        setStringCriteria(JSON.stringify(param));
    };

    useUpdateEffect(() => {
        const updateUrl = () => {
            const filter = getUrlFilterParams(JSON.parse(stringCriteria));
            const baseFilter = { ...filter };

            push({
                pathname: listManagers.url,
                search: stringify(baseFilter),
            });
        };

        updateUrl();
    }, [stringCriteria, push]);

    useEffect(() => {
        fetchModels(getStateFromUrl());
        // eslint-disable-next-line
    }, [props.location.search, fetchModels, userId]);

    const getStateFromUrl = () => {
        if (!props.location.search) {
            return stateInit.criteria;
        }
        const { selectedMenu = 'Leaders', page = 1, pageSize = 10, status = 'Approved' } = queryStringParse(
            props.location.search,
        );

        const queryParams = new URLSearchParams(props.location.search);
        const roles = queryParams.getAll('roles');

        const newCriteria = {
            ...criteria,
            filter: {
                ...criteria.filter,
                userId,
                status,
                roles
            },
            paging: {
                ...criteria.paging,
                pageSize: +pageSize,
                pageNum: +page,
            },
            selectedMenu
        };

        return newCriteria;
    };

    const getPosition = (original) => {
        const position = [];

        if (!isNullOrWhitespace(original.currentJobTitle)) {
            position.push(
                <div className="ListManagers__CurrentPosition" key="CurrentPosition">
                    {original.currentJobTitle}
                </div>,
            );
        } else if (!isNullOrWhitespace(original.currentPosition)) {
            position.push(
                <div className="ListManagers__CurrentPosition" key="CurrentPosition">
                    {original.currentPosition}
                </div>,
            );
        }

        if (!isNullOrWhitespace(original.currentJobCompanyName)) {
            position.push(
                <div className="ListManagers__CurrentCompany" key="CurrentCompany">
                    {original.currentJobCompanyName}
                </div>,
            );
        } else if (!isNullOrWhitespace(original.currentCompany)) {
            position.push(
                <div className="ListManagers__CurrentCompany" key="CurrentCompany">
                    {original.currentCompany}
                </div>,
            );
        }

        return position;
    };

    const statusInfo = (original) => {
        let currentText = '';
        const addAccess =
            selectedMenu === 'Leaders' ||
            original.accessRequestDirection === ACCESS_DIRECTION.ADD_ACCESS;

        switch (original.status) {
            case 'Review':
                currentText = `Заявка на рассмотрении с ${moment
                    .utc(original.requestedOn)
                    .local()
                    .format('DD MMM Y')}`;
                break;
            case 'Rejected':
                if (original.processingStatus === 'FailedToCreateUser') {
                    currentText = `Ошибка при создании пользователя ${moment
                        .utc(original.requestedOn)
                        .local()
                        .format('DD MMM Y')}`;
                } else if (original.processingStatus === 'Cancelled') {
                    currentText = `Заявка отменена ${moment
                        .utc(original.requestedOn)
                        .local()
                        .format('DD MMM Y')}`;
                } else {
                    currentText = `Заявка отклонена ${moment
                        .utc(original.requestedOn)
                        .local()
                        .format('DD MMM Y')}`;
                }
                break;
            default:
                currentText = `Роль ${addAccess ? 'подключена ' : 'исключена'} с ${moment
                    .utc(original.finishedOn)
                    .local()
                    .format('DD MMM Y')}`;
        }

        const comment = original.rejectionComment;
        return (
            <>
                <span className={`ListManagers__Info--${original.status}`}>{currentText} </span>
                {comment && (
                    <span className={`ListManagers__Info--${original.status}`}>
                        <Popover
                            childrenClassName={'InlineFlex'}
                            tooltip={comment}
                            autoPlacement={false}
                            tooltipPlacement={'top'}
                        >
                            &nbsp;(&nbsp;<span className="AccessReject__Description">причина</span>
                            &nbsp;)
                        </Popover>
                    </span>
                )}
            </>
        );
    };

    const roleInfo = (original) => {
        const role =
            USER_ROLES.find((x) => x.code === original.role)?.name ||
            (selectedMenu === 'Leaders' ? 'Руководитель' : '');

        const comment = original.roleRemoveReason;
        return (
            <>
                <span className={`ListManagers__Info--${original.status}`}>{role}</span>
                {comment && (
                    <span className={`ListManagers__Info--${original.status}`}>
                        <Popover
                            childrenClassName={'InlineFlex'}
                            tooltip={comment}
                            autoPlacement={false}
                            tooltipPlacement={'top'}
                        >
                            &nbsp;(&nbsp;<span className="AccessReject__Description">причина</span>
                            &nbsp;)
                        </Popover>
                    </span>
                )}
            </>
        );
    };

    const buildColumns = [
        {
            accessor: 'Photo',
            maxWidth: 100,
            resizable: false,
            sortable: false,
            Cell: ({ original }) => (
                <div className="ListManagers__images">
                    <NavLink
                        to={person.buildUrl(
                            { id: original.id, regime: 'view' },
                            props.location.search,
                        )}
                    >
                        <Image
                            alt={getUserFullName(original)}
                            photoUri={getPersonPhotoUri(photoSizes.tiny, original.id, original.id)}
                            width={60}
                            heigth={60}
                        />
                    </NavLink>
                </div>
            ),
        },
        {
            Header: selectedMenu === 'Leaders' ? 'ФИО руководителя' : 'Пользователь',
            accessor: 'fullName',
            resizable: false,
            sortable: true,
            Cell: ({ original }) => (
                <div>
                    <NavLink
                        className="ListManagers__Name"
                        to={person.buildUrl(
                            { id: original.id, regime: 'view' },
                            props.location.search,
                        )}
                    >
                        {original && getUserFullName(original)}
                    </NavLink>
                    <div className="ListManagers__Job">{getPosition(original)}</div>
                </div>
            ),
        },
        {
            Header: 'Телефон',
            accessor: 'phone',
            maxWidth: 250,
            resizable: false,
            sortable: true,
            Cell: ({ original }) => original.phone && original.phone,
        },
        {
            Header: 'E-mail',
            accessor: 'email',
            resizable: false,
            sortable: true,
            Cell: ({ original }) => (
                <div className="ListManagers__Email">
                    <a href={`mailto:${original.email}`}>{original.email}</a>
                </div>
            ),
        },
        {
            Header: () => (
                <div
                    className="ListManagers__RequestDirectionHeader"
                    data-test="ListManagers_RequestDirectionHeader--text"
                >
                    Тип заявки
                </div>
            ),
            accessor: 'accessRequestDirection',
            show: selectedMenu !== 'Leaders',
            minWidth: 90,
            maxWidth: 90,
            resizable: false,
            sortable: true,
            Cell: ({ original }) => {
                return original.accessRequestDirection === 'AddAccess' ? (
                    <div className="ListManagers__RequestDirectionTableValue">
                        <Popover
                            tooltip={'Заявка на добавление роли'}
                            autoPlacement={false}
                            tooltipPlacement={'top'}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="lg" color={'#207245'} />
                        </Popover>
                    </div>
                ) : (
                    <div className="ListManagers__RequestDirectionTableValue">
                        <Popover
                            tooltip={'Заявка на исключение роли'}
                            autoPlacement={false}
                            tooltipPlacement={'top'}
                        >
                            <FontAwesomeIcon icon={faMinusCircle} size="lg" color={'#cb4a32'} />
                        </Popover>
                    </div>
                );
            },
        },
        {
            Header: 'Роль',
            accessor: 'role',
            show: selectedMenu !== 'Leaders',
            resizable: false,
            sortable: false,
            Cell: ({ original }) => <div className="ListManagers__Role">{roleInfo(original)}</div>,
        },
        {
            Header: selectedMenu === 'Leaders' ? 'Информация о подключении' : 'Информация о заявке',
            maxWidth: 300,
            resizable: false,
            sortable: false,
            Cell: ({ original }) => (
                <div className="ListManagers__Info">{statusInfo(original)}</div>
            ),
        },
        {
            Header: '',
            maxWidth: 20,
            resizable: false,
        },
    ];

    return (
        <Page>
            <ListTemplate title={'Список руководителей'}>
                <ListManagersMenu onChangeMenu={onChangeItemMenu} selected={selectedMenu} />
                <DataGrid
                    data={payload.data}
                    foundCount={payload.meta.foundCount}
                    columns={buildColumns}
                    sorted={sorted}
                    onSortedChange={onSortedChange}
                    showPagination={true}
                    showPageSizeOptions={false}
                    pages={payload.meta.pageCount}
                    page={criteria.paging.pageNum}
                    onPageChange={onPageChange}
                    manual
                    pageSize={state.criteria.paging.pageSize}
                    changePageAmount={changePageAmount}
                />
            </ListTemplate>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

const actions = { showErrorAlert, showPageLoader, hidePageLoader, push };

export default connect(mapStateToProps, actions)(ListManagers);
