import { generatePath, matchPath } from 'react-router';
import { isNullOrWhitespace } from 'utils';

class Route {
    constructor(url, title, name) {
        this.url = url;
        this.title = title;
        this.name = name;
    }

    buildUrl(params, search = null) {
        if (!params) {
            return this.url;
        }

        return generatePath(this.url, params) + (isNullOrWhitespace(search) ? '' : search);
    }
}

export const isPathMatch = (path, route, options = { exact: true, strict: false }) => {
    const routeObj = {
        path: route,
        ...options,
    };

    return matchPath(path, routeObj) != null;
};

export const home = new Route('/', 'Интеллектуальная Система Кадрового Резерва');

export const profileRoute = new Route('/profile', 'Профиль');

export const interfaceSelectorRoute = new Route('/interface', 'Смена интерфейса');

export const search = new Route('/search', 'Поиск');
export const fullTextSearch = new Route('/fullTextSearch', 'Полнотекстовый поиск');
export const analytics = new Route('/analytics', 'Аналитика');
export const reports = new Route('/reports', 'Мои отчёты');
export const importRoute = new Route('/import', 'Импорт');
export const InstructionsViewFromEdit = new Route('/instructions/view/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?/all', 'Инструкции');
export const instructionsView = new Route('/instructions/view/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?', 'Инструкции');
export const instructionsRoute = new Route('/instructions', 'Инструкции');
export const instructionsOldRoute = new Route('/instructions-old', 'Инструкции');
export const favorites = new Route('/favorites', 'Избранное');

export const instructionsEdit = new Route('/instructions/edit', 'Редактор инструкций');
export const instructionsEditItem = new Route('/instructions/edit/item/:id', 'Редактор инструкции');
export const instructionsEditCategory = new Route('/instructions/edit/category/:id', 'Редактор категорий инструкций');

export const teamReport = new Route('/teamReport', 'Командный отчет');

export const personNew = new Route('/person/new', 'Новый резервист');
export const person = new Route('/person/:id(\\d+)', 'Карточка резервиста');
export const personProfileBlock = new Route('/person/:id(\\d+)/:profileBlock', '');
export const personEvaluate = new Route('/person/:id(\\d+)/evaluate', 'Оценки резервиста');
export const personProgress = new Route('/person/:id(\\d+)/progress', 'Развитие резервиста');
export const personTeams = new Route('/person/:id(\\d+)/teams', 'Команды резервиста');
export const personEducationEvents = new Route(
    '/person/:id(\\d+)/educationEvents',
    'Обучение резервиста',
);
export const educationEventRoute = new Route(
    '/person/:id(\\d+)/educationEvents/:eventId?/:mode',
    'План обучения',
);

export const profile = new Route('/profile', 'Профиль');
export const profileEvaluate = new Route('/profile/evaluate', 'Оценки');
export const profileProgress = new Route('/profile/progress', 'Развитие');
export const profileTeams = new Route('/profile/teams', 'Моя команда');

export const users = new Route('/admin/users', 'Пользователи');
export const usersActions = new Route('/admin/users-actions', 'Действия пользователей');
export const userNew = new Route('/admin/user/new', 'Новый пользователь');
export const userEdit = new Route('/admin/user/edit/:id(\\d+)', 'Редактировать пользователя');
export const user = new Route(`/admin/user/:id(\\d+)`, 'Карточка пользователя');

export const msgGate = new Route('/admin/msg', 'Отправка сообщений');

export const groups = new Route('/admin/groups', 'Группы');
export const group = new Route('/admin/group/:id(\\d+)', 'Карточка группы');
export const groupEdit = new Route('/admin/group/edit/:id(\\d+)', 'Редактирование группы');
export const groupNew = new Route('/admin/group/new', 'Создание группы');

export const access = new Route('/admin/access', 'Доступы');
export const adminPersons = new Route('/admin/persons', 'Управление анкетами');

export const changelog = new Route('/changelog', 'Журнал изменений');
export const signIn = new Route('/signin', '');
export const adminSignIn = new Route('/signin/admin', '');
export const accountWizard = new Route('/accountWizard', '');
export const restricted = new Route('/restricted', '');
export const notFound = new Route('/404', '');
export const forbidden = new Route('/403', '');

export const attributes = new Route('/admin/attributes', 'Дополнительные поля');
export const attributeNew = new Route('/admin/attribute/new', 'Новое дополнительное поле');
export const attributeEdit = new Route(
    '/admin/attribute/edit/:id(\\d+)',
    'Редактирование дополнительного поля',
);
export const attribute = new Route('/admin/attribute/:id(\\d+)', 'Дополнительное поле');

export const evolutionPlan = new Route('/newPlan/:id(\\d+)', 'План развития');
export const evolutionEditPlan = new Route(
    '/editPlan/:id(\\d+)/:planId/:isSession?',
    'План развития',
);
export const evaluationsModerationRoute = new Route(
    '/moderation/evaluations/',
    'Разрешение на оценку',
);
export const moderationWelcomeRoute = new Route('/moderation', 'Модерация');

export const quotasRoute = new Route('/moderation/quotas/', 'Квоты');
export const quotasNewRoute = new Route('/moderation/quotas/new', 'Распределение квот');
export const quotasEditRoute = new Route('/moderation/quotas/edit/:id', 'Редактирование квот');
export const quotasViewRoute = new Route('/moderation/quotas/:id', 'Просмотр квот');

export const templatesRoute = new Route('/moderation/templates/', 'Список шаблонов');
export const templatesEditRoute = new Route(
    '/moderation/templates/edit/:id',
    'Редактирование шаблонов',
);
export const templatesCreateRoute = new Route('/moderation/templates/create/', 'Создание шаблона');
export const templatesTypesRoute = new Route(
    '/moderation/templatesTypes/',
    'Выбор шаблонов для уведомлений',
);

export const personGrowthResourcesRoute = new Route(
    '/moderation/personGrowthResources',
    'Инструменты развития',
);
export const personGrowthResourceRoute = new Route(
    '/moderation/personGrowthResource/:id?/:regime',
    'Инструмент развития',
);
export const CompetenceModelsModerationRoute = new Route(
    '/moderation/models/',
    'Разрешение на оценку',
);
export const competenceModelsRoute = new Route('/moderation/models', 'Модели компетенций');
export const competenceModelEditRoute = new Route(
    '/moderation/models/edit/:id',
    'Редактирование модели компетенций',
);
export const competenceModelNewRoute = new Route(
    '/moderation/models/new',
    'Добавление модели компетенций',
);
export const competenceModelCopyRoute = new Route(
    '/moderation/models/copy/:id',
    'Копирование модели компетенций',
);
export const underconstructionRoute = new Route(
    '/underconstruction',
    'Раздел находится в разработке',
);
export const testsRoute = new Route('/moderation/tests', 'Тесты');
export const testEditRoute = new Route('/moderation/tests/edit/:id', 'Редактирование теста');
export const testNewRoute = new Route('/moderation/tests/new', 'Добавление теста');
export const personGrowthTagsRoute = new Route(
    '/moderation/personGrowthTags',
    'Теги инструмента развития',
);
export const personGrowthTagRoute = new Route(
    '/moderation/personGrowthTag/:id?/:regime',
    'Тег инструмента развития',
);
export const listManagers = new Route('/moderation/listManagers', 'Список руководителей');
export const resourceProvidersRoute = new Route(
    '/moderation/resourceProviders',
    'Список провайдеров',
);
export const resourceProviderRoute = new Route(
    '/moderation/resourceProviders/:id?/:regime',
    'Редактор провайдеров',
);

export const issuesRoute = new Route('/moderation/issues', 'Список задач');
export const issuesEditRoute = new Route('/moderation/issues/:id/:mode?', 'Редактор задачи');

export const newsRoute = new Route('/moderation/news', 'Список новостей');
export const newsEditRoute = new Route('/moderation/news/:id/:mode?', 'Редактор новостей');

export const masterSearch = new Route('/search', 'Поиск');
export const externalSearchRoute = new Route(`${masterSearch.url}/external`, 'Расширенный поиск');

// profile routes
export const lkDetailsProfile = new Route('/services/search/profile/:id', '');
export const lkPersonProfile = new Route('/services/profile/:id', '');
export const lkDetailsProfilePersonsWithoutResults = new Route(
    '/widgets/personsWithoutResults/profile/:id',
    '',
);
export const lkWidgetsTeamGrowthProfile = new Route('/widgets/teamGrowth/profile/:id', '');
export const lkWidgetsBestInGroupProfile = new Route('/widgets/bestInGroup/profile/:id', '');
export const lkTeamsProfile = new Route('/teams/profile/:id', '');
export const lkEvaluateCartProfile = new Route('/services/evaluate-cart/profile/:id', '');
export const lkDetailsEvaluateTeamProfile = new Route('/services/evaluate-results/:id', 'Профиль');
export const lkProgressCartProfile = new Route('/services/progress-cart/profile/:id', '');
export const lkProgressTeamProfile = new Route(
    '/services/progress-team/profile/:id',
    'Развитие команды',
);
export const lkProgressFavotiteProfile = new Route(
    '/services/progress-favorite/profile/:id',
    'Развитие команды',
);
export const lkProgressPersonProfile = new Route(
    '/services/progress-search/profile/:id',
    'Развитие резервиста',
);

export const lkMainServices = new Route('/services', 'Сервисы');
export const lkListResults = new Route('/services/evaluate-results', 'Результаты оценки');
export const lkDetailsEvaluateSelect = new Route('/services/evaluate', 'Оценка выбор');
export const lkDetailsEvaluateTeam = new Route('/services/evaluate/team', 'Оценка команда');
export const lkDetailsEvaluatefavorites = new Route(
    '/services/evaluate/favorites',
    'Оценка избранные',
);
export const lkDetailsEvaluateSearch = new Route(
    '/services/evaluate/search',
    'Оценка поиск по команде',
);
export const lkDetailsEvaluateCart = new Route(
    '/services/evaluate/evaluate-cart',
    'Оценка корзина',
);
export const lkEvaluateSelectResults = new Route('/services/evaluate-results', 'Результаты оценки');
export const lkDetailsEvaluateTeamResults = new Route(
    '/services/evaluate-results/team',
    'Результаты оценки в команде',
);
export const lkDetailsEvaluateFavoritesResults = new Route(
    '/services/evaluate-results/favorites',
    'Результаты оценки в избранном',
);
export const lkDetailsEvaluateSearchResults = new Route(
    '/services/evaluate-results/search',
    'Результаты оценки в поиске',
);
export const lkDetailsEvaluatePersonResults = new Route(
    '/services/evaluate/person/results',
    'Оценка персональные результаты',
);
export const lkSearch = new Route('/services/search', 'Поиск');

export const lkProgressTeam = new Route('/services/progress-team', 'Развитие команды');
export const lkProgressFavotite = new Route('/services/progress-favorite', 'Развитие команды');
export const lkProgressPerson = new Route('/services/progress-search', 'Развитие резервиста');
export const lkProgressCart = new Route('/services/progress-cart', '');

export const lkNewIssue = new Route('/services/new-issue', 'Новая задача');
export const lkNewIssueSelectImplementer = new Route(
    '/services/new-issue/select-implementer',
    'Выбрать исполнителя',
);

export const lkMainWidgets = new Route('/widgets', 'Виджеты');
export const lkWidgetsPersonsWithoutResults = new Route(
    '/widgets/personsWithoutResults',
    'Резервисты без результатов оценок',
);
export const lkWidgetsEvaluationRating = new Route(
    '/widgets/evaluationRating',
    'Рейтинг оценок в регионе',
);
export const lkWidgetsTeamGrowth = new Route('/widgets/teamGrowth', 'Развитие моей команды');
export const lkWidgetsBestInGroup = new Route('/widgets/bestInGroup', 'Лучшие в моем регионе');
export const lkMainTeams = new Route('/teams', 'Команды');
export const lkListAdviceTags = new Route('/services/advices-tags/', 'Компетенции советов');
export const lkDetailsAdvices = new Route('/services/advices-tags/:id/advices', 'Советы');

export const lkIssue = new Route('/services/issues/:id', 'Задача');
export const lkIssueDone = new Route('/services/issues/:id/done', 'Задача выполнена');
export const lkIssueDeferred = new Route('/services/issues/:id/deferred', 'Отложить задачу');
export const lkIssueEdit = new Route('/services/issues/:id/edit', 'Редактировать задачу');
export const lkIssueDraft = new Route('/services/issue-draft/:id');
export const lkIssueDraftSelectImplementer = new Route(
    '/services/issue-draft/:id/select-implementer',
);
export const lkIssueEditSelectImplementer = new Route(
    '/services/issues/:id/edit/select-implementer',
);
export const lkIssueTracking = new Route('/services/issues-tracking');
export const lkIssueTrackingProfile = new Route('/services/issues-tracking/profile/:id');
export const lkIssueTrackingSelect = new Route('/services/issues-tracking/:id');
export const lkIssueTrackingEdit = new Route('/services/issues-tracking/edit/:id');

export const lkPersonPlanComment = new Route(
    '/services/issues/:id/plan/:planId?/approve/:approveId?/comment',
    'Комментировать ИПР',
);

export const lkMainContent = new Route('/content', 'Новости и контент');

export const lkDetailsCollection = new Route('/services/search/wishlist/:id');

export const lkDetailsEditWishLists = new Route('/services/search/wishlists');
export const lkDetailsEditWishList = new Route('/services/search/editwishlist/:id');

export const resourceViewRoute = new Route('/private/resource/:id?', 'Просмотр ресурсов');

export const massEmailLists = new Route('/moderation/massEmail');
export const newMassEmail = new Route('/moderation/massEmail/email/new');
export const viewMassEmail = new Route('/moderation/massEmail/email/view/:id');
export const editMassEmail = new Route('/moderation/massEmail/email/edit/:id');
export const massEmailCampaign = new Route('/moderation/massEmail/campaign/:id');

export const sidebarRoutes = {
    home,
    personNew,
    search,
    fullTextSearch,
    favorites,
    analytics,
    reports,
    importRoute,
    users,
    usersActions,
    access,
    groups,
    adminPersons,
    attributes,
    userNew,
    evolutionPlan,
    instructionsEdit,
    msgGate,
};

export const otherRoutes = {
    person,
    userEdit,
    user,
    signIn,
    adminSignIn,
    restricted,
    accountWizard,
    group,
    groupEdit,
    groupNew,
    notFound,
    attribute,
    attributeNew,
    attributeEdit,
    instructionsRoute,
    moderationWelcomeRoute,
    quotasRoute,
    quotasEditRoute,
    evaluationsModerationRoute,
    personGrowthResourcesRoute,
    personGrowthResourceRoute,
    CompetenceModelsModerationRoute,
    competenceModelsRoute,
    personGrowthTagsRoute,
    personGrowthTagRoute,
    templatesRoute,
    masterSearch,
    resourceProvidersRoute,
    resourceProviderRoute,
    educationEventRoute,
    templatesTypesRoute,
    resourceViewRoute,
    massEmailLists,
    newMassEmail,
    massEmailCampaign,
    editMassEmail,
    viewMassEmail,
    externalSearchRoute,
    newsEditRoute,
    newsRoute,
    issuesEditRoute,
    issuesRoute,
    listManagers,
    testNewRoute,
    testEditRoute,
    testsRoute,
    underconstructionRoute,
    competenceModelCopyRoute,
    competenceModelNewRoute,
    competenceModelEditRoute,
    templatesCreateRoute,
    templatesEditRoute,
    quotasViewRoute,
    quotasNewRoute,
    evolutionEditPlan,
    changelog,
    profileTeams,
    profileEvaluate,
    profileProgress,
    personEducationEvents,
    personTeams,
    personProgress,
    personEvaluate,
    personProfileBlock,
    teamReport,
    instructionsOldRoute,
    instructionsView,
    InstructionsViewFromEdit,
    interfaceSelectorRoute,
    instructionsEditItem,
    instructionsEditCategory,
};

const lkRoutes = {
    lkMainServices,
    lkEvaluateSelectResults,
    lkDetailsEvaluateSelect,
    lkDetailsProfile,
    lkDetailsProfileWithoutSearch: lkPersonProfile,
    lkSearch,
    lkProgressTeam,
    lkProgressFavotite,
    lkProgressPerson,
    lkProgressCart,
    lkListAdviceTags,
    lkDetailsAdvices,
    lkIssue,
    lkIssueDone,
    lkIssueDeferred,
    lkIssueEdit,
    lkIssueDraft,
    lkIssueDraftSelectImplementer,
    lkIssueEditSelectImplementer,
    lkIssueTracking,
    lkIssueTrackingProfile,
    lkIssueTrackingSelect,
    lkIssueTrackingEdit,
    lkPersonPlanComment,
    lkMainContent,
    lkDetailsCollection,
    lkDetailsEditWishLists,
    lkDetailsEditWishList,
    lkMainTeams,
    lkWidgetsBestInGroup,
    lkWidgetsTeamGrowth,
    lkWidgetsEvaluationRating,
    lkWidgetsPersonsWithoutResults,
    lkMainWidgets,
    lkNewIssueSelectImplementer,
    lkNewIssue,
    lkDetailsEvaluatePersonResults,
    lkDetailsEvaluateSearchResults,
    lkDetailsEvaluateFavoritesResults,
    lkDetailsEvaluateTeamResults,
    lkDetailsEvaluateCart,
    lkDetailsEvaluateSearch,
    lkDetailsEvaluatefavorites,
    lkDetailsEvaluateTeam,
    lkListResults,
    lkProgressPersonProfile,
    lkProgressFavotiteProfile,
    lkProgressTeamProfile,
    lkProgressCartProfile,
    lkDetailsEvaluateTeamProfile,
    lkEvaluateCartProfile,
    lkTeamsProfile,
    lkWidgetsBestInGroupProfile,
    lkWidgetsTeamGrowthProfile,
    lkDetailsProfilePersonsWithoutResults
};

const allRoutes = { ...sidebarRoutes, ...otherRoutes, ...lkRoutes };

export default allRoutes;
