import React, { useState } from 'react';
import { connect } from 'react-redux';
import { typesDescr, groups } from './chartsHelper';
import TestChart from './TestChart';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import './EvaluateBlock.scss';
import EvaluationType from 'components/Lk/Common/Evaluate/EvaluationType';
import Slider from 'components/Lk/Uikit/Slider/Slider';
import classnames from 'classnames';
import { deepClone } from 'utils';
import { EVALUATIONTEST_STATUS, EVALUATIONTEST_CRITERIONRESULT_STATUS } from 'constants.js';

export const EvaluteBlock = props => {
    const { personId, testProviders, testsResults, competenciesModels } = props;

    const [selectedTest, setSelectes] = useState({ activeGroup: groups[0], key: 0 });

    const [testsExist, setTestsExist] = useState(false);

    const getTests = (data = [], groupId) => {
        const testTypes = typesDescr.filter(x => x.groupId === groupId);
        let tests = [];
        testTypes.map(x => data
            .filter(res => res.kind === x.type && res.resultType === EVALUATIONTEST_STATUS.Relevant)
            .map(test => {
                const criterias = test.result.criteria.filter(x => x.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Relevant);
                tests.some(t => t.result.evaluationTestId === test.result.evaluationTestId)
                    ? tests.find(t => t.result.evaluationTestId === test.result.evaluationTestId)?.result?.criteria?.push(...criterias)
                    : tests.push(deepClone(test))
            }));
        return tests;
    };

    const renderCharts = (personId, selectedGroup) => {
        const testsList = getTests(testsResults, selectedGroup.id).filter(
            x => x.resultType === EVALUATIONTEST_STATUS.Relevant,
        );

        if (testsList.length === 0) {
            if (testsExist) {
                setTestsExist(false);
            }
            return <div className="No-Data">Нет данных</div>;
        }

        if (!testsExist) {
            setTestsExist(true);
        }

        if (selectedGroup.groupResult) {
            return (
                <TestChart
                    personId={personId}
                    test={testsList}
                    chartType={selectedGroup.visualType}
                    config={{ withHeader: true, withDescr: true }}
                    testProviders={testProviders}
                    testsResults={testsResults}
                    competenciesModels={competenciesModels}
                />
            );
        }

        return testsList.map(test => (
            <TestChart
                key={test?.result?.id}
                personId={personId}
                test={test}
                chartType={test.visualizationKind}
                config={{ withHeader: true, withDescr: true }}
                testProviders={testProviders}
                testsResults={testsResults}
                competenciesModels={competenciesModels}
                fixedWidth={testsList.length > 1}
            />
        ));
    };

    const renderEvaluationType = obg => {
        return obg.map((item) => {
            const type = typesDescr.find(x => x.groupId === item.id);
            return (
                <div className="EvaluteBlock__Block" key={item.id}>
                    <CardItemBlock grey>
                        <EvaluationType
                            id={item.id}
                            description={type.Descr}
                            title={item.name}
                            icon="evaluateType"
                            tooltip
                        />
                    </CardItemBlock>
                </div>
            );
        });
    };

    const slideChange = (e, array) => {
        const param = {
            activeGroup: array[e],
            key: e,
        };
        setSelectes(param);
    };

    const { activeGroup } = selectedTest;

    return (
        <div className={classnames('EvaluteBlock', { 'EvaluteBlock--exist': testsExist })}>
            <Slider
                data={renderEvaluationType(groups)}
                initialSlide={selectedTest && selectedTest.key}
                slideChange={slideChange}
                resultIds={groups}
                magical={6}
            />

            {renderCharts(personId, activeGroup)}
        </div>
    );
};

export default connect()(EvaluteBlock);
