import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
    lkMainWidgets,
    lkWidgetsPersonsWithoutResults,
    lkWidgetsEvaluationRating,
    lkWidgetsTeamGrowth,
    lkWidgetsBestInGroup,
} from 'routes';
import { ACTIVE_MAIN_CARD } from 'components/Lk/Basic/constants';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemExpert from 'components/Lk/Common/CardItemPerson/CardItemExpert';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import { wordForm, formatPrice } from 'utils';
import Loader from 'components/common/Loader';

import { getWidgetSettings } from 'api';
import CardLink from 'components/Lk/Common/CardLink';
import {
    fetchPersonWithoutResultAmount,
    fetchRegionRating,
    fetchTeamGrowth,
} from 'ducks/Dashboard';

const initialState = {
    regionRating: {
        position: 0,
        data: [],
        currentRegion: null,
    },
};

class Favorites extends React.Component {
    _isMounted = false;
    state = initialState;

    nextCard = (url, stateParams) => () => {
        this.props.push(url, { back: lkMainWidgets.url, ...stateParams });
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchData = async () => {
        const {
            fetchPersonWithoutResultAmount,
            fetchRegionRating,
            fetchTeamGrowth,
            personId,
        } = this.props;

        fetchPersonWithoutResultAmount();
        fetchRegionRating();
        fetchTeamGrowth(personId);
        const widgetSettings = await getWidgetSettings();
        this._isMounted &&
            this.setState({
                widgetSettings: widgetSettings.data,
            });
    };

    itemsLoaded = () =>
        this.props.withoutEvaluation?.percent != null && this.props.teamGrowth?.percent != null;

    renderService(widgetSetting) {
        const { withoutEvaluation, regionRating, teamGrowth } = this.props;
        switch (widgetSetting.widget.type) {
            case 'TeamGrowth':
                return (
                    withoutEvaluation &&
                    withoutEvaluation.percent != null && (
                        <CardItemBlock key={widgetSetting.id} grey mainServiceBlock>
                            <CardLink
                                icon={widgetSetting.widget.icon}
                                router={lkWidgetsPersonsWithoutResults}
                                name={widgetSetting.widget.name}
                                description={`У ${formatPrice(
                                    withoutEvaluation.amount,
                                )} (${withoutEvaluation.percent.toFixed(
                                    2,
                                )}%) ${wordForm(withoutEvaluation.amount, [
                                    'резервиста',
                                    'резервистов',
                                    'резервистов',
                                ])} нет ни одного результата оценки`}
                            />
                        </CardItemBlock>
                    )
                );
            case 'RegionRating':
                const position =
                    regionRating.findIndex((x) => x.groupId === this.props.groupId) + 1;
                const currentRegion = regionRating.find((x) => x.groupId === this.props.groupId);
                const description =
                    regionRating.length === 0
                        ? ''
                        : position === 0 && currentRegion === undefined
                        ? 'Регион не участвует в рейтинге'
                        : `${
                              currentRegion && currentRegion.groupTitle
                          } занимает ${position} место в рейтинге уровня способностей`;

                return (
                    <CardItemBlock key={widgetSetting.id} grey mainServiceBlock>
                        <CardLink
                            icon={widgetSetting.widget.icon}
                            router={lkWidgetsEvaluationRating}
                            name={widgetSetting.widget.name}
                            description={description}
                        />
                    </CardItemBlock>
                );
            case 'GrowthPlan':
                return (
                    teamGrowth &&
                    teamGrowth.percent != null && (
                        <CardItemBlock key={widgetSetting.id} grey mainServiceBlock>
                            <CardLink
                                icon={widgetSetting.widget.icon}
                                router={lkWidgetsTeamGrowth}
                                name={widgetSetting.widget.name}
                                description={
                                    teamGrowth.percent !== 0
                                        ? `У ${formatPrice(
                                              teamGrowth.amount,
                                          )} (${teamGrowth.percent.toFixed(
                                              2,
                                          )}%) ${wordForm(teamGrowth.amount, [
                                              'резервиста',
                                              'резервистов',
                                              'резервистов',
                                          ])} в моих командах есть план развития`
                                        : 'Нет ни одного результата оценки'
                                }
                            />
                        </CardItemBlock>
                    )
                );
            case 'BestInGroup':
                return (
                    <CardItemBlock key={widgetSetting.id} grey mainServiceBlock>
                        <CardLink
                            icon={widgetSetting.widget.icon}
                            router={lkWidgetsBestInGroup}
                            name={widgetSetting.widget.name}
                            description={widgetSetting.widget.description}
                        />
                    </CardItemBlock>
                );
            default:
                return null;
        }
    }

    renderServices(widgetSettings) {
        widgetSettings = widgetSettings.sort((a, b) => a.sortOrder - b.sortOrder);
        return widgetSettings.map((ws) => this.renderService(ws));
    }

    render() {
        const { setActiveCard } = this.props;
        const { widgetSettings } = this.state;

        setActiveCard(ACTIVE_MAIN_CARD);

        return (
            <>
                <MainCardMenu main />
                <div className="LKMainCardServices">
                    <CardItemExpert useShortInfo />
                    {widgetSettings && this.itemsLoaded() ? (
                        <div className="LKMainCardServices__Widgets">
                            {this.renderServices(widgetSettings)}
                        </div>
                    ) : (
                        <Loader />
                    )}
                </div>
            </>
        );
    }
}

Favorites.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const actions = { push, fetchPersonWithoutResultAmount, fetchRegionRating, fetchTeamGrowth };

const props = (state) => {
    return {
        groupId: state.auth && state.auth.user && state.auth.user.groupId,
        personId: state.auth && state.auth.user && state.auth.user.personId,
        withoutEvaluation: state.dashboard.withoutEvaluation,
        regionRating: state.dashboard.regionRating.data,
        teamGrowth: state.dashboard.teamGrowth,
    };
};

export default connect(props, actions)(Favorites);
