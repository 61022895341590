import React, { Component } from 'react';
import "./Reports.scss";
import Switcher from "components/common/Switcher";
import Button from "components/uikit/Button";
import Select from 'components/uikit/Select';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import { showWarningAlert } from 'ducks/Alert';
import { connect } from 'react-redux';

const fonts = [
    {id: "Arial" , name: "Arial"},
    {id: "Arial Narrow" , name: "Arial Narrow"},
    {id: "Times New Roman" , name: "Times New Roman"}
];

const maxFieldsInReport = {
    biography: {format: "a4", maxFields: 6}
}

class ReportParams extends Component {
    
    render() {
        const { reportSections, reportSettings, reportType } = this.props.report;
        const reportSectionsKeys = Object.keys(reportSections);

        return (
            <div className="ReportParams">
                <div className="ReportParams__Header">Параметры отчета</div>
                {this.renderFontParam(reportSettings.font)}
                {reportType === 'biography' && this.renderFormatParam(reportSettings.format)}
                {reportSections && this.renderReportSections(reportSections, reportSectionsKeys)}
            </div>
        )
    }

    renderFontParam = (fontId = "Times New Roman") => {
        const font = {
            id: fontId,
            name: fontId
        };

        return (
            <div className="ReportParams__Line">
                <div className="Param">
                    <Field
                        className="kr_input_with_text_attribute"
                    >
                        <Label>Шрифт</Label>
                        <Select
                            value={font}
                            options={fonts}
                            catalog
                            onChange={item => this.props.handleStateChange('font', item.id)}
                        />
                    </Field>
                </div>
            </div> 
        );
    }

    renderFormatParam = (format = "a3") => {
        return (
            <div className="ReportParams__Line">
                <div className="Param">
                    <Field>
                        <Label>Необходимый формат</Label>
                            <div className="kr_filter_radio">
                                <div className="kr_like-checkbox-items">
                                    <input
                                        type="radio"
                                        name="a3"
                                        id="a3"
                                        value="a3"
                                        onChange={e => this.correctFieldsNumber(maxFieldsInReport, e.target.value, true) && this.props.handleStateChange('format', e.target.value)}
                                        checked={format === "a3" || !format}
                                    />
                                    <label htmlFor="a3" className="kr_left-border">
                                        A3
                                    </label>

                                    <input
                                        type="radio"
                                        name="a4"
                                        id="a4"
                                        value="a4"
                                        onChange={e => this.correctFieldsNumber(maxFieldsInReport, e.target.value, true) && this.props.handleStateChange('format', e.target.value)}
                                        checked={format && format === "a4"}
                                    />
                                    <label htmlFor="a4" className="kr_right-border">
                                        A4
                                    </label>                    
                                </div>
                            </div>                        
                    </Field>
                </div>
            </div>
        );
    }

    renderReportSections = (reportSections, keys) => {
        return keys.map(key => 
            (reportSections[key] && reportSections[key].name &&
                <div className="ReportParams__Line" key={key}>
                    <div className="Section">
                        <div className="Section__Header-Param">
                            {reportSections[key].name}
                        </div>
                        <div className="Section__Selector">
                            <Switcher checked={this.getSectionOpenStatus(key)}
                                onChange={e => this.props.handleSwitchChange(key, e.target.checked)}
                            />
                        </div>                           
                    </div>
                    {this.getSectionOpenStatus(key) && this.renderReportSectionFields(key, reportSections[key].fields)}
                </div>
            )
        )
    }

    getSectionOpenStatus = (secionKey) => {
        const { reportSettings } = this.props.report;
        return reportSettings[secionKey] && reportSettings[secionKey].enabled;
    }

    renderReportSectionFields = (secionKey, fields) => {
        return fields && fields.map(field => {
            return field &&
            <div className="Param" key={field.code}>
                <div className="Param__Label">
                    {field.name}
                </div>
                <div className={`Param__Icon${this.isFieldSelected(secionKey, field.code) ? "--selected" : ""}`}>
                    <Button faIcon="folder-plus" onClick={() => this.handleSelectionChange(secionKey, field.code)}></Button>
                </div>
            </div>
        });
    }

    correctFieldsNumber = (maxFieldsInReport = {}, currentFormat = "", strictCompare = false) => {
        const reportType = this.props.report.reportType;
        if (!maxFieldsInReport[reportType]) { return true }
        const { format, maxFields } = maxFieldsInReport[reportType];
        const { reportSettings } = this.props.report;
        if (format.toLowerCase() === currentFormat.toLowerCase()) {
            const reportSettingsKeys = Object.keys(reportSettings);
            let count = 0;
            for (const key of reportSettingsKeys) {
                count += reportSettings[key].selectedFields 
                ? reportSettings[key].selectedFields.length
                : 0;
            }

            const res = strictCompare ? count <= maxFields : count < maxFields;

            if (!(res)) {
                this.props.showWarningAlert(`Максимальное количество полей для формата ${format.toUpperCase()} = ${maxFields}`);
            }
            return res;
        }
        return true;
    }

    isFieldSelected = (section, fieldName) => {
        const { reportSettings } = this.props.report;
        const selectedFields = reportSettings[section].selectedFields;
        return selectedFields && selectedFields.find(x => x.code === fieldName);
    }

    handleSelectionChange = (section, fieldName = "") => {
        const { reportSettings } = this.props.report;
        if (this.isFieldSelected(section, fieldName)) {
            this.props.handleSelectionChange(section, fieldName, "delete");
        } else {
            this.correctFieldsNumber(maxFieldsInReport, reportSettings.format) && this.props.handleSelectionChange(section, fieldName, "add");
        }
    }
}

const actions = {
    showWarningAlert,
};

export default  connect(null, actions)(ReportParams);