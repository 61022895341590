import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showErrorAlert } from 'ducks/Alert';
import { fullTextSearchSelect } from 'api';
import SearchLine from 'components/common/SearchLine';
import PersonCard from './PersonCard';
import Loader from 'components/common/Loader';
import Pagination from 'components/common/Pagination';

class Search extends Component {
    state = {
        searchTerm: '',
        sentSearchTerm: '',
        paging: { pageNum: 1, pageSize: 10 },
        loading: false,
        payload: [],
        meta: { foundCount: 0, pageCount: 0 },
    };

    changeSearchTerm = (searchTerm) => this.setState({ searchTerm });

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } });
    };

    search = async (clear = true) => {
        clear &&
            this.setState({
                paging: { pageNum: 1, pageSize: 10 },
                sentSearchTerm: this.state.searchTerm,
            });
        const { searchTerm, paging } = this.state;

        if (!searchTerm.trim()) {
            return;
        }

        try {
            this.setState({ loading: true });
            const results = await fullTextSearchSelect({
                filter: {
                    fields: [
                        {
                            category: 'Persons',
                            field: 'FullName',
                            operation: 'contains',
                            value: searchTerm,
                        },
                    ],
                },
                paging: clear ? { pageNum: 1, pageSize: 10 } : paging,
                idList: true,
            });
            const { payload, meta } = results.data;
            this.setState({ payload, meta });
        } catch (e) {
            this.props.showErrorAlert('Ошибка поиска');
        } finally {
            this.setState({ loading: false });
        }
    };

    changePage = (pageNum) => {
        this.setState({ paging: { ...this.state.paging, pageNum } });
    };

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } });
    };

    componentDidUpdate(prevProps, prevState) {
        const { paging } = this.state;
        if (
            prevState.paging.pageNum !== paging.pageNum ||
            prevState.paging.pageSize !== paging.pageSize
        ) {
            this.search(false);
        }
    }

    render() {
        const { searchTerm, loading, paging, meta, payload, sentSearchTerm } = this.state;
        const { setSelected } = this.props;
        const successSearch = !loading && sentSearchTerm && payload.length === 0;
        return (
            <>
                {loading && <Loader overlay />}
                <div>
                    <div>
                        <SearchLine
                            value={searchTerm}
                            onChangeValue={this.changeSearchTerm}
                            onSubmit={this.search}
                        />
                    </div>
                    <div>
                        {successSearch && (
                            <div className="PersonSelectorSection__NoData">
                                По запросу "{sentSearchTerm}" ничего не найдено.
                            </div>
                        )}
                        {payload.map((x) => {
                            const isPersonChecked = this.props.isSelectedPerson(x.id);
                            return (
                                <PersonCard
                                    key={x.id}
                                    person={x}
                                    checkable
                                    onCheck={() => setSelected([x.id], !isPersonChecked)}
                                    checked={isPersonChecked}
                                />
                            );
                        })}
                    </div>
                    {meta?.pageCount > 1 && (
                        <div className="PersonSelectorSection__Pagination">
                            <Pagination
                                currentPageNum={paging.pageNum}
                                pageCount={meta.pageCount}
                                totalCount={meta.foundCount}
                                onPaginate={this.changePage}
                                changePageAmount={this.changePageAmount}
                                pageSize={paging.pageSize}
                            />
                        </div>
                    )}
                </div>
            </>
        );
    }
}

Search.propTypes = {
    setSelected: PropTypes.func,
    selected: PropTypes.array,
    isSelectedPerson: PropTypes.func,
};

const actions = {
    showErrorAlert,
};

export default connect(null, actions)(Search);
