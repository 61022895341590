import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getPersonPhotoUri, photoSizes } from "api";
import DataCardProperty, { DataCardListProperty, DataCardPropertyBadge } from "components/common/DataCard/DataCardProperty";
import Favorite from "components/common/Favorite";
import Image from "components/common/Image";
import PhotoBlock from "components/common/PhotoBlock";
import { familyStatus } from "../../../enums";
import { canEditPerson } from '../../../rightsController';
import { uploadPhoto } from 'api';
import { connect } from 'react-redux';
import MultiFileUploadButton from 'components/uikit/MultiFileUploadButton';
import { declineAge } from '../../../utils';
import LazyLoad from 'react-lazy-load'
import { USER_ROLES } from 'constants.js';

import classNames from "classnames";
import photoStub from 'assets/images/photo-stub.png';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ResultCard.scss"


class ResultCard extends Component {

    state = {
        updatingPhoto: false,
        files: [],
    };

    handleState = (stateKey, stateValue) => {
        this.setState(state => {
            return {
                ...state,
                [stateKey]: stateValue
            }
        })
    };

    handlePhotoOpened = (personId) => (photoFile) => {
        if (!photoFile) { return; }
        this.handleState("updatingPhoto", true);
        uploadPhoto(personId, photoFile).then(() => this.handleState("updatingPhoto", false));
    };

    renderPersonRole = (rolesData) => {
        if (!rolesData) {
            return [];
        }

        const userRoles = rolesData.map(item => {
            const roleTitle = USER_ROLES.find(x => x.code === item);
            return roleTitle.name;
        });

        if (!userRoles) {
            return [];
        }
        return userRoles;
    };

    renderPhotoBlock = () => {
        const user = this.props.user;
        const refetchKey = new Date().getTime();
        const personId = this.props.person.id;
        const photoUri = getPersonPhotoUri(photoSizes.small, personId, refetchKey);
        const photoLink = getPersonPhotoUri(photoSizes.fullSize, personId, refetchKey);

        return (
            <PhotoBlock
                id={`photo-block_${personId}`}
                photoUri={photoUri}
                photoLink={photoLink}
                readOnly={!canEditPerson(user)}
                onPhotoOpened={this.handlePhotoOpened(personId)}
                loading={this.state.updatingPhoto}
                isImport={this.props.isImport}
            />
        );
    };

    render() {
        const { person, isFavorite, favoriteDisabled, onChangeFavorite, isChecked, onChangeChecked, key } = this.props;
        const photoUri = getPersonPhotoUri(photoSizes.small, person.id);
        const checkboxClassName = classNames(["ResultCardCheckbox"], {
            "ResultCardCheckbox--checked": isChecked
        });
        return (
            <div className="ResultCard" key={key}>
                <div className="ResultCard__col1">
                    {isChecked !== null &&
                        <label className={checkboxClassName}>
                            <input
                                type="checkbox"
                                className="ResultCardCheckbox__Input"
                                checked={isChecked}
                                onChange={() => onChangeChecked(!isChecked)}
                            />
                            {isChecked &&
                                <FontAwesomeIcon
                                    className="ResultCardCheckbox__Checkmark"
                                    icon={faCheck}
                                />
                            }
                        </label>
                    }
                    <div className="ResultCardImg">
                        <LazyLoad offsetVertical={300}>
                            {!this.props.editMode ? (
                                <Link to={`/Person/${person.id}`}>
                                    <Image photoUri={photoUri} photoStub={photoStub} />
                                </Link>
                            ) : (
                                    this.renderPhotoBlock()
                                )}
                        </LazyLoad>
                    </div>

                    <DataCardProperty title="Телефон">{person.phone}</DataCardProperty>
                    <DataCardProperty title="E-mail">{person.email}</DataCardProperty>
                    <DataCardPropertyBadge list={this.renderPersonRole(person.roles)}/>
                    <div className="kr_item_socials ResultCard__socials">
                        {person.socials && person.socials.map((social, index) =>
                            <a key={index} href={social.link} className={"kr_" + social.code}>{" "}</a>
                        )}
                    </div>
                    {this.props.editMode && (
                        <div className="ResultCard__upload-files">
                            <MultiFileUploadButton
                                personId={person.id}
                            />
                        </div>
                    )}
                </div>

                <div className="ResultCard__col2">
                    <div className="ResultCard__name">
                        <Link to={`/Person/${person.id}`}>
                            {person.fio || person.fullName}
                        </Link>
                    </div>
                    {(person.currentPosition || person.currentCompany) && (
                        <div className="ResultCard__Section">
                            {person.currentPosition && (
                                <DataCardProperty>{person.currentPosition}</DataCardProperty>
                            )}
                            {person.currentCompany && (
                                <div className="ResultCard__company">
                                    {person.currentCompany}
                                </div>
                            )}
                            {person.currentGovtServiceKind && (
                                <>
                                    <DataCardProperty>{person.currentGovtServiceRank}</DataCardProperty>
                                    <div className="ResultCard__company">{person.currentGovtServiceBranch}</div>
                                </>
                            )}
                        </div>
                    )}
                    <div className="ResultCard__Section">
                        {person.isRussianCitizen && (
                            <div className="ResultCard__check">Гражданин РФ</div>
                        )}
                        {person.readyMoveToRussia && (
                            <div className="ResultCard__check">Готов к переезду в РФ</div>
                        )}
                        {person.languages && !!person.languages.length && (
                            <DataCardListProperty title="Языки">
                                {person.languages}
                            </DataCardListProperty>
                        )}
                    </div>
                </div>

                <div className="ResultCard__col3">
                    <div className="ResultCard__Section">
                        <DataCardProperty title="Образование">{person.educationLevel}</DataCardProperty>
                        <DataCardProperty title="ВУЗ">{person.university}</DataCardProperty>
                        <DataCardProperty title="Специальность">{person.specialty}</DataCardProperty>
                    </div>
                    <div className="ResultCard__Section">
                        <DataCardProperty title="Регион">{person.region}</DataCardProperty>
                        <DataCardProperty title="Населенный пункт">{person.locality}</DataCardProperty>
                        {person.age > 0 && <DataCardProperty title="Возраст">{declineAge(person.age)}</DataCardProperty>}
                        {(person.familyStatus || person.childrenStatus) && (
                            <DataCardProperty title="Семейное положение">
                                {familyStatus[person.familyStatus]}
                                {(person.familyStatus && person.childrenStatus) ? ", " : null}
                                {person.childrenStatus}
                            </DataCardProperty>
                        )}
                    </div>
                    {typeof isFavorite === "boolean" &&
                        <div className="ResultCard__favorite">
                            <Favorite isFavorite={isFavorite} disabled={favoriteDisabled} onChangeFavorite={onChangeFavorite} />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

export default connect(mapStateToProps)(ResultCard);
