import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import Page from 'components/common/Page';
import Button from 'components/uikit/Button';
import ListTemplate from 'components/uikit/ListTemplate';
import { Row } from 'react-flexbox-grid';
import './TeamReport.scss';
import TestItem from './TestItem';
import Loader from 'components/common/Loader';
import { fetchSettings, saveSettings } from 'ducks/TeamReport';

const TeamReport = props => {
    const { fetchSettings, evaluationTests, loading, loadSettings, saveSettings,isLoaded } = props;

    const [settings, setSettings] = useState([]);

    useEffect(() => {
        !isLoaded&& fetchSettings();
    }, [fetchSettings,isLoaded]);

    useEffect(() => {
        setSettings(loadSettings);
    }, [loadSettings]);

    const handleSaveBtnOnClick = () => {
        saveSettings(settings);
    };

    const onChange = item => {
        setSettings(x => x.map(t => (item.id === t.id ? { ...item } : t)));
    };

    return (
        <Page>
            <Loader show={loading} overlay />

            <ListTemplate>
                {settings.map(x => (
                    <TestItem
                        onChange={onChange}
                        item={x}
                        key={x.id}
                        tests={evaluationTests.filter(t => x.kind === t.kind)}
                    />
                ))}
                {!loading && (
                    <Row end="xs">
                        <div className="TemplatesTypeContainer__SaveBtn">
                            <Button size="sm" onClick={handleSaveBtnOnClick}>
                                Сохранить
                            </Button>
                        </div>
                    </Row>
                )}
            </ListTemplate>
        </Page>
    );
};

const mapStateToProps = state => ({
    loadSettings: state.teamReport.settings,
    evaluationTests: state.teamReport.testList,
    loading: state.teamReport.loading,
    isLoaded: state.teamReport.isLoaded
});

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    showSuccessAlert,
    fetchSettings,
    saveSettings,
};
export default connect(
    mapStateToProps,
    actions,
)(TeamReport);
