import React, { Fragment } from "react";
import classNames from "classnames";
import { connect } from 'react-redux';
import { isITAdmin, isSecurityAdmin } from 'rightsController.js';
import Page, { PageTitle } from "components/common/Page";
import "./Instructions.scss";

function InstructionsOld({ user }) {
    function renderInstruction(title, version, link, isExternalLink = false) {
        const inDevelopment = !link;

        return (
            <li className={classNames("Instructions__Item", { Instructions__Item_inDevelopment: inDevelopment })}>
                <a className="Instructions__ItemLink" href={link} target={isExternalLink ? "_blank" : ""}>
                    <div className="Instructions__ItemTitle">
                        {title}
                    </div>
                    <div className="Instructions__ItemVersion">
                        {inDevelopment ? 'Находится в разработке' : `Версия: ${version}`}
                    </div>
                </a>
            </li>
        );
    }

    function renderDelimeter() {
        return (
            <li className="Instructions__Delimeter" />
        );
    }

    function renderCommonInstructions() {
        return renderInstruction('Общее описание системы', '1.5', '/api/instructions/obshhee-opisanie-sistemy');
    }

    function renderUserInstructions() {
        return !isITAdmin(user) && !isSecurityAdmin(user) && (
            <Fragment>
                {renderInstruction('Вход в систему', '1.9', '/api/instructions/vkhod-v-sistemu')}
                {renderInstruction('Главная страница', '1.8', '/api/instructions/glavnaya-stranicza')}
                {renderInstruction('Ввод данных о резервистах', '1.6', '/api/instructions/vvod-dannykh-o-rezervistakh')}
                {renderInstruction('Поиск резервистов', '1.4', '/api/instructions/poisk-rezervistov')}
                {renderInstruction('Просмотр данных о резервисте', '1.3', '/api/instructions/prosmotr-dannykh-o-rezerviste')}
                {renderInstruction('Роли системы и доступный для них функционал', '1.5', '/api/instructions/roli-sistemy-i-dostupnyj-dlya-nikh-funkczional')}
                {renderInstruction('Импорт. Правила заполнения шаблона', '1.1', '/api/instructions/import-pravila-zapolneniya-shablona')}
                {renderInstruction('Конструктор отчетов', '1.2', '/api/instructions/konstruktor-otchetov')}
                {renderInstruction('Аналитика', '1.3', '/api/instructions/analytics')}
                {renderInstruction('Модерация квот', '1.2', '/api/instructions/moderacziya-kvot')}
                {renderInstruction('Оценка', '1.3', '/api/instructions/oczenka')}
                {renderInstruction('Разрешение на оценку', '1.2', '/api/instructions/razreshenie-na-oczenku')}
                {renderInstruction('Развитие', '1.3', '/api/instructions/razvitie')}
                {renderInstruction('Редактор писем', '1.1', '/api/instructions/redaktor-pisem')}
                {renderInstruction('Редактор провайдеров', '1.0', '/api/instructions/redaktor-provajderov')}
                {renderInstruction('Редактор тестов', '1.0', '/api/instructions/redaktor-testov')}
                {renderInstruction('Редактор тегов', '1.0', '/api/instructions/redaktor-tegov')}
                {renderInstruction('Редактор модели компетенций', '1.0', '/api/instructions/redaktor-modeli-kompetenczij')}
                {renderInstruction('Редактор инструментов развития', '1.0', '/api/instructions/redaktor-instrumentov-razvitiya')}
                {renderInstruction('Создание плана обучения', '1.0', '/api/instructions/sozdanie-plana-obucheniya')}
                {/*renderInstruction('Выгрузка информации о резервисте')*/}
                {renderDelimeter()}
                {renderInstruction('Программа обучения и развития для резервистов', '1.0', 'https://partner.sberbank-school.ru/programs/6993/item/261884', true)}
                {renderInstruction('Программа обучения и развития для специалистов по работе с персоналом', '1.0', 'https://partner.sberbank-school.ru/programs/6994/item/262002', true)}
            </Fragment>
        )
    }

    function renderSuperAdminInstructions() {
        return (isITAdmin(user) || isSecurityAdmin(user)) && (
            <Fragment>
                {renderInstruction('Работа сотрудника с ролью Суперадмин', '1.3', '/api/instructions/rabota-sotrudnika-s-rolyu-superadmin')}
            </Fragment>
        )
    }

    return (
        <Page className="Instructions" theme="primary">
            <PageTitle theme="primary">
                Инструкции
            </PageTitle>
            <ol className="Instructions__Content">
                {renderCommonInstructions()}
                {renderUserInstructions()}
                {renderSuperAdminInstructions()}
            </ol>
        </Page>
    );
}

const mapStateToProps = (state) => ({ user: state.auth.user });

export default connect(mapStateToProps)(InstructionsOld);
