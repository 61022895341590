import memoize from 'fast-memoize';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ToggleOpen from 'components/hoc/ToggleOpen';
import ValidBlock from 'components/hoc/ValidBlock';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { SOCIAL_NETWORKS } from 'ducks/Catalog';
import Select, { IconOption } from 'components/uikit/Select';
import { DataCard } from "components/common/DataCard";
import Field from 'components/uikit/Field';
import Input from "components/uikit/Input";
import Row from "components/uikit/Row";
import Form from "components/common/Form";
import ItemButton from "../ItemButton";
import './SocialNetworksBlock.scss';
import { shortUrlRegex } from 'constants.js'

function SocialInput(props) {
    const { code, value, removeNetwork, networkInfo, onChange, maxLength, className } = props;

    return (
        <Field 
            size="50"
            className={"kr_input_with_text kr_input_social kr_input_social_" + code}
            invalid={!value.match(shortUrlRegex)}
        >
            <Input value={value} onChange={onChange} maxLength={maxLength} className={className} />
            <ItemButton 
                type="remove"
                onClick={() => removeNetwork(networkInfo)} 
            />
        </Field>
    );
}

class AvailableNetworks extends React.Component {
    render() {
        const { availableNetworks } = this.props;
        return (
            !!availableNetworks.length && (
                <Field size="50" className="kr_item_socials">
                    <Select
                        value={null}
                        onChange={newSelected => this.props.addNetwork(newSelected)}
                        options={availableNetworks}
                        catalog
                        components={{ Option: IconOption }}
                        placeholder="Выберите соц. сеть для добавления"
                    />
                </Field>                
            )
        );
    }
}

class SocialNetworksBlock extends PureComponent {

    render() {
        const { 
            networks,
            isInputFilled,
            isEditBlock,
            isInputInvalid,
        } = this.props;
    
        isInputFilled(networks.size);
        isInputInvalid('SocialNetworksBlock', [
            networks.some(x => !x.value.match(shortUrlRegex))
        ])
        return (
            <DataCard 
                shadow
                title="Социальные сети" 
                className="kr_profile-anketa"
                isOpen={this.props.hasValidationErrors}
            >
                <Form autoComplete={false} className="EditPerson__Form">
                    {this.renderNetworks()}
                    <Row>
                        <AvailableNetworks 
                            availableNetworks={this.getAvailableNetworks()}
                            addNetwork={this.addNetwork}
                        />
                    </Row>
                    <Row>{isEditBlock ? this.props.renderEditBlock() : null}</Row>
                </Form>
            </DataCard>
        );
    }
    
    handleChange = (e, networkInfo) => {
        const networks = this.props.networks;
        const newNetworks = networks.set(networks.indexOf(networkInfo), { 
            ...networkInfo, 
            value: e.target.value,
        });
        this.props.handleStateChange({ networks: newNetworks });
    }

    addNetwork = (network) => {
        const networks = this.props.networks;
        const newNetworks = networks.push({ network, value: '' });
        this.props.handleStateChange({ networks: newNetworks });
    }

    removeNetwork = (network) => {
        const networks = this.props.networks;
        const newNetworks = networks.delete(networks.indexOf(network));
        this.props.handleStateChange({ networks: newNetworks });
    }

    getAvailableNetworksMemoized = memoize((currentNetworks, allNetworks) => {
        const alreadySelected = currentNetworks.map(x => x.network.id);
        return allNetworks.filter(x => !alreadySelected.includes(x.id));
    });

    getAvailableNetworks = () => this.getAvailableNetworksMemoized(this.props.networks, this.props[SOCIAL_NETWORKS].data);

    renderNetworks = () => {
        return (
            <Row form>
                {this.props.networks.map(network => this.renderNetwork(network))}
            </Row>
        );
    }
    
    renderNetwork = (networkInfo) => {
        const { network, value } = networkInfo;

        this.props.isInputFilled(value);

        return (
            <SocialInput
                key={network.code}
                code={network.code} 
                value={value} 
                onChange={e => this.handleChange(e, networkInfo)}
                removeNetwork={this.removeNetwork}
                networkInfo={networkInfo}
                maxLength={450}
                className="SocialNetworks__Input"
            />
        );
    }
}

const actions = { 
    showWarningAlert, 
    showErrorAlert,
};

export default connect(null, actions)(ToggleOpen(ValidBlock(SocialNetworksBlock)));
