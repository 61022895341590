import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/uikit/Button';

import ModalDialog from 'components/common/ModalDialog';

import '../../ContentEditor.scss';
import { isNullOrWhitespace } from 'utils';

class ContentEditorToolbar extends Component {
    state = {
        openPublishModal: false,
        openDraftModal: false,
    };

    onClickOkOnPublishModal = () => {
        this.setState({ openPublishModal: false });
        this.props.onExecute('publish');
    };

    onClickOkOnInDraftModal = () => {
        this.setState({ openDraftModal: false });
        this.props.onExecute('draft');
    };

    renderPublishModal = () => {
        return (
            <ModalDialog
                onClick={this.onClickOkOnPublishModal}
                onCloseModal={() => {
                    this.setState({ openPublishModal: false });
                }}
                modalOpen={this.state.openPublishModal}
                modalHeader="Вы уверены, что хотите опубликовать?"
                btnOktext="Да"
                btnCanceltext="Нет"
                size="lg"
                noHeaderDivider
            ></ModalDialog>
        );
    };

    renderDraftModal = () => {
        return (
            <ModalDialog
                onClick={this.onClickOkOnInDraftModal}
                onCloseModal={() => {
                    this.setState({ openDraftModal: false });
                }}
                modalOpen={this.state.openDraftModal}
                modalHeader="Вы уверены, что хотите перевести в черновик?"
                btnOktext="Да"
                btnCanceltext="Нет"
                size="lg"
                noHeaderDivider
            ></ModalDialog>
        );
    };

    render() {
        const { form, prevForm } = this.props;

        return (
            <div className="ContentEditor__WrapperButton">
                <div
                    md={6}
                    className="ContentEditor__ActionButtons ContentEditor__ActionButtons--right"
                >
                    {!!!form.id && (
                        <div className="ContentEditor__ActionButtons--empty">&nbsp;</div>
                    )}
                    <Button
                        size="sm"
                        color="danger"
                        onClick={() => this.props.onExecute('cancel')}
                    >
                        Назад
                    </Button>
                    {!!form.id && (
                        <Button
                            size="sm"
                            color="danger"
                            onClick={() => this.props.onExecute('delete')}
                        >
                            Удалить
                        </Button>
                    )}
                </div>
                <div
                    md={6}
                    className="ContentEditor__ActionButtons ContentEditor__ActionButtons--right"
                >
                    <Button
                        size="sm"
                        disabled={
                            isNullOrWhitespace(form.title) 
                        }
                        onClick={() => this.props.onExecute('save')}
                    >
                        Сохранить
                    </Button>
                    {!!form.id &&
                        (this.props.isPublished ? (
                            <Button
                                size="sm"
                                onClick={() => {
                                    this.setState({ openDraftModal: true });
                                }}
                            >
                                Перевести в черновик
                            </Button>
                        ) : (
                            <Button
                                size="sm"
                                disabled={
                                    isNullOrWhitespace(prevForm.title) ||
                                    isNullOrWhitespace(prevForm.summary)
                                }
                                onClick={() => {
                                    this.setState({ openPublishModal: true });
                                }}
                            >
                                Опубликовать
                            </Button>
                        ))}
                    {!!form.id && (
                        <Button size="sm" onClick={() => this.props.onExecute('copy')}>
                            Копировать
                        </Button>
                    )}

                    <Button
                        size="sm"
                        disabled={!form.id}
                        onClick={() => this.props.onExecute('viewContentMode')}
                    >
                        Просмотр
                    </Button>
                </div>
                {this.renderPublishModal()}
                {this.renderDraftModal()}
            </div>
        );
    }
}

ContentEditorToolbar.propTypes = {
    summaryPreview: PropTypes.bool,
    isPublished: PropTypes.bool,
    viewMode: PropTypes.bool,
    form: PropTypes.object,
    onExecute: PropTypes.func,
};

ContentEditorToolbar.defaultProps = {
    viewMode: false,
    form: {},
};

export default ContentEditorToolbar;
