import React, { useState, useEffect, memo, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import PersonalInfo from './PersonalInfo';
import EducationInfo from './EducationInfo';
import WorkExperienceInfo from './WorkExperienceInfo';
import LanguagesInfo from './LanguagesInfo';
import SocialNetworksInfo from './SocialNetworksInfo';
import FilesInfo from './FilesInfo';
import PersonnelInfo from './PersonnelInfo';
import AttributesInfo from './AttributesInfo';
import FamilyInfo from './FamilyInfo';
import {
    profileMenuItems,
    MENU_PROFILE_PERSONAL,
    MENU_PROFILE_EDUCATION,
    MENU_PROFILE_WORK_EXPERIENCE,
    MENU_PROFILE_LANGUAGES,
    MENU_PROFILE_FAMILY,
    MENU_PROFILE_SOCIAL_NETWORKS,
    MENU_PROFILE_FILES,
    MENU_PROFILE_PERSONNEL,
    MENU_PROFILE_ATTRIBUTES,
    MENU_PROFILE_GOVERNMENT,
    MENU_PROFILE_SYSTEM_INFO,
    internationalPhoneRegex,
} from './constants';
import { personFullSelector } from 'ducks/Person';
import { trimEnd, trimObjectStrings, isNullOrWhitespace } from 'utils';
import { List } from 'immutable';
import uuid from 'uuid/v4';
import { saveBlock, saveBlocksArray } from 'ducks/PersonBlock';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import WithKeyboardHandler from 'components/Lk/Hooks/WithKeyboardHandler';
import LinkMenu from 'components/Lk/Uikit/LinkMenu';
import GovernmentInfo from './GovernmentInfo';
import SystemInfo from './SystemInfo';
import { usePrevious } from 'components/hooks';
import Loader from 'components/common/Loader';
import LoadingUrlBlocker from 'components/Lk/Common/LoadingUrlBlocker';
import { showErrorAlert } from 'ducks/Alert';
import Sticky from 'react-sticky-state';
import ProfileInfoBlock from './ProfileInfoBlock';
import classnames from 'classnames';
import './Sticky.scss';

const setPerson = person => {
    const {
        personalInfo = {},
        educationInfo = {},
        workInfo = {},
        governmentServiceInfo = {},
        evaluationInfo = {},
        languagesInfo = {},
        socialNetworksInfo = {},
        filesInfo = {},
        filesDirectoryId,
        attributesInfo = {},
        personnelInfo = {},
        systemInfo = {},
    } = person;
    return {
        personalInfo: {
            id: personalInfo.id,
            lastName: personalInfo.lastName || '',
            firstName: personalInfo.firstName || '',
            middleName: personalInfo.middleName || '',
            age: personalInfo.age || null,
            sex: personalInfo.sex || null,
            birthDate: personalInfo.birthDate,
            birthPlace: personalInfo.birthPlace || '',
            email: personalInfo.email || '',
            phone: personalInfo.phone || '',
            district: personalInfo.federalDistrict || null,
            region: personalInfo.region || null,
            locality: personalInfo.locality || null,
            localityId: personalInfo.localityId || null,
            document: personalInfo.document || null,
            documentNumber: personalInfo.documentNumber || '',
            documentName: personalInfo.documentName || '',
            isRussianCitizen: personalInfo.isRussianCitizen,
            foreignRegion: personalInfo.foreignRegion || '',
            foreignLocality: personalInfo.foreignLocality || '',
            residenceStartYear: personalInfo.residenceStartYear || '',
            nationality: personalInfo.nationality || null,
            readyMoveToRussia: personalInfo.readyMoveToRussia || false,
            photoUri: personalInfo.photoUri,
            extraInfo: personalInfo.extraInfo || '',
            pastResidences: personalInfo.pastResidences || [],
            birthDateDisplayMode: personalInfo.birthDateDisplayMode,
            snils: personalInfo.snils,
        },
        educationInfo: {
            educations: List(educationInfo.educations),
        },
        workInfo: {
            workPlaces: List(workInfo.workPlaces),
        },
        governmentServiceInfo: {
            governmentServices: List(governmentServiceInfo.governmentServices),
        },
        evaluationInfo: {
            evaluations: List(evaluationInfo.evaluations),
        },
        languagesInfo: {
            knownLanguages: List(languagesInfo.knownLanguages),
        },
        socialNetworksInfo: {
            networks: List(socialNetworksInfo.networks),
        },
        foreignerInfo: {
            nationality: personalInfo.nationality || null,
            readyMoveToRussia: personalInfo.readyMoveToRussia || false,
        },
        familyInfo: {
            familyStatus: personalInfo.familyStatus || null,
            childrenStatus: personalInfo.childrenStatus || null,
        },
        filesInfo: {
            files: List(filesInfo.files),
        },
        personnelInfo: {
            id: personnelInfo.id,
            awards: personnelInfo.awards,
            dataOrigin: personnelInfo.dataOrigin || null,
            recommenderName: personnelInfo.recommenderName || '',
            curatorId: personnelInfo.curator && personnelInfo.curator.id,
            curatorLocal: personnelInfo.curator,
            curatorName: personnelInfo.curatorName,
            experienceLevel: personnelInfo.experienceLevel || null,
            geoLevel: personnelInfo.geoLevel || null,
            socialActivity: personnelInfo.socialActivity || '',
            memberOfSocialOrganizations: personnelInfo.memberOfSocialOrganizations || '',
            socialAchivements: personnelInfo.socialAchivements || '',
            extraInfo: personnelInfo.extraInfo,
            inclusionDate: personnelInfo.inclusionDate,
            managerLevel: personnelInfo.managerLevel,
            reserveLevel: personnelInfo.reserveLevel || '',
            governmentWorkPositions: personnelInfo.governmentWorkPositions || '',
            inclusionReason: personnelInfo.inclusionReason || '',
        },
        attributesInfo: {
            attributes: List(attributesInfo.attributes).map(attribute => ({
                ...attribute,
                attributeValue:
                    attribute.attribute.type.toLowerCase() === 'select' ||
                    attribute.attribute.type.toLowerCase() === 'multiselect'
                        ? JSON.parse(attribute.attributeValue)
                        : attribute.attributeValue,
                attribute: {
                    ...attribute.attribute,
                    local_id: uuid(),
                    payload: attribute.attribute.payload && JSON.parse(attribute.attribute.payload),
                },
            })),
        },
        filesDirectoryId,
        systemInfo: {
            groups: systemInfo.groups,
            group: systemInfo.groupId,
            roles: systemInfo.user?.roles,
            createdUserDate: systemInfo.user?.created,
            selectedPersonGroupIds: personalInfo.personGroupIds
        },
    };
};

const getPersonGeneralInfo = (blockType, person) => {
    const { personalInfo, filesDirectoryId, familyInfo, systemInfo } = person;
    const readyPersInfoObj = {
        ...personalInfo,
        blockType: blockType,
        personId: personalInfo.id,
        birthDate: personalInfo.birthDate,
        federalDistrictId: personalInfo.isRussianCitizen
            ? personalInfo.district && personalInfo.district.id
            : null,
        regionId: personalInfo.isRussianCitizen
            ? personalInfo.region && personalInfo.region.id
            : null,
        localityId: personalInfo.isRussianCitizen ? personalInfo.localityId : null,
        foreignLocality: personalInfo.isRussianCitizen ? null : personalInfo.foreignLocality,
        foreignRegion: personalInfo.isRussianCitizen ? null : personalInfo.foreignRegion,
        residenceStartYear: personalInfo.residenceStartYear,
        nationalityId: personalInfo.isRussianCitizen
            ? null
            : personalInfo.nationality && personalInfo.nationality.id,
        documentId: personalInfo.document.id,
        documentNumber: getNormalizedMask(personalInfo.documentNumber || ''),
        sex: personalInfo.sex.id,
        readyMoveToRussia: personalInfo.isRussianCitizen ? false : personalInfo.readyMoveToRussia,
        filesDirectoryId: filesDirectoryId,
        familyStatus: familyInfo.familyStatus && familyInfo.familyStatus.id,
        childrenStatusId: familyInfo.childrenStatus && familyInfo.childrenStatus.id,
        extraInfo: personalInfo.extraInfo,
        pastResidences: personalInfo.pastResidences.map(item => ({
            ...item,
            id: item.id > 0 ? item.id : undefined,
            federalDistrictId: item.selectedDistrict && item.selectedDistrict.id,
            regionId: item.selectedRegion && item.selectedRegion.id,
            localityId: (item.selectedLocality && item.selectedLocality.id) || item.localityId,
        })),
        groupId: systemInfo.group,
        personGroupIds: systemInfo.selectedPersonGroupIds,
        snils: personalInfo.snils
};
    return trimObjectStrings(readyPersInfoObj);
};

const getNormalizedMask = value => {
    return trimEnd(value, '_');
};

const isPhoneValid = phone => {
    if (isNullOrWhitespace(phone)) {
        return true;
    }
    return phone.match(internationalPhoneRegex);
};

const getPersonToSave = (person, blockType) => {
    const {
        personalInfo,
        educationInfo: { educations },
        workInfo: { workPlaces },
        languagesInfo: { knownLanguages },
        socialNetworksInfo: { networks },
        attributesInfo: { attributes },
        governmentServiceInfo: { governmentServices },
        personnelInfo,
        filesInfo: { files },
    } = person;

    switch (blockType) {
        case 'PersonalInfoBlock':
            return getPersonGeneralInfo(blockType, person);

        case 'EducationBlock':
            const readyBlockObj = {
                personId: personalInfo.id,
                blockType: blockType,
                needPersonId: true,
                isArray: true,
                arr: educations
                    .filter(education => education.educationLevel)
                    .map(education => ({
                        ...trimObjectStrings(education),
                        id: education.id > 0 ? education.id : undefined,
                        educationLevelId: education.educationLevel && education.educationLevel.id,
                        studyFormId: education.studyForm && education.studyForm.id,
                    })),
            };
            return trimObjectStrings(readyBlockObj);

        case 'WorkBlock':
            return {
                personId: personalInfo.id,
                blockType: blockType,
                needPersonId: true,
                isArray: true,
                arr: workPlaces
                    .filter(x => x.company && x.position)
                    .map(workPlace => ({
                        ...trimObjectStrings(workPlace),
                        personId: personalInfo.id,
                        id: workPlace.id > 0 ? workPlace.id : undefined,
                        industryId: workPlace.industry && workPlace.industry.id,
                        workAreaId: workPlace.workArea && workPlace.workArea.id,
                        managementLevelId:
                            workPlace.managementLevel && workPlace.managementLevel.id,
                        managementExperienceId:
                            workPlace.managementExperience && workPlace.managementExperience.id,
                        employeesNumberId:
                            workPlace.employeesNumber && workPlace.employeesNumber.id,
                    })),
            };

        case 'GovernmentServiceBlock':
            return {
                personId: personalInfo.id,
                blockType: blockType,
                needPersonId: true,
                isArray: true,
                arr: governmentServices
                    .filter(x => x.governmentServiceKind?.id)
                    .map(govService => ({
                        ...trimObjectStrings(govService),
                        personId: personalInfo.id,
                        id: govService.id > 0 ? govService.id : undefined,
                        governmentServiceKindId: govService?.governmentServiceKind?.id,
                    })),
            };

        case 'LanguagesBlock':
            return {
                personId: personalInfo.id,
                blockType: blockType,
                needPersonId: true,
                isArray: true,
                arr: knownLanguages
                    .filter(x => x.language && x.languageLevel)
                    .map(x => ({
                        id: x.id > 0 ? x.id : undefined,
                        languageId: x.language.id,
                        languageLevelId: x.languageLevel && x.languageLevel.id,
                        fileAttachmentId: x.fileAttachmentId,
                    })),
            };

        case 'SocialNetworksBlock':
            return {
                personId: personalInfo.id,
                blockType: blockType,
                needPersonId: true,
                isArray: true,
                arr: networks
                    .filter(x => x.value && x.network && x.value.trim())
                    .map(x => ({
                        id: x.id > 0 ? x.id : undefined,
                        networkId: x.network.id,
                        value: x.value.trim(),
                    })),
            };

        case 'CuratorBlock':
        case 'PersonnelInfoBlock':
            return {
                ...trimObjectStrings(personnelInfo),
                blockType,
                personId: personalInfo.id,
                needPersonId: true,
                dataOriginId: personnelInfo.dataOrigin && personnelInfo.dataOrigin.id,
                experienceLevelId:
                    personnelInfo.experienceLevel && personnelInfo.experienceLevel.id,
                curatorId: personnelInfo.curatorId,
                curatorName: personnelInfo.curatorName,
                inclusionDate: personnelInfo.inclusionDate,
            };

        case 'AwardsBlock':
            const { awards } = personnelInfo;
            return {
                ...trimObjectStrings(personnelInfo),
                blockType,
                personId: personalInfo.id,
                needPersonId: true,
                isArray: true,
                arr: awards
                    .filter(award => award.name && Object.keys(award).length)
                    .map(award => ({
                        id: award.id > 0 ? award.id : undefined,
                        competitionLevel:
                            award.selectedCompetitionLevel && award.selectedCompetitionLevel.id,
                        name: award.name.trim(),
                    })),
            };

        case 'FilesBlock':
            const formData = new FormData();
            if (files.filter(x => x.deleted || x.isNew).size === 0) {
                return false;
            }

            files.filter(x => x.isNew).forEach(x => formData.append('files', x.file));
            files
                .filter(x => x.deleted)
                .map(x => x.id)
                .forEach(x => formData.append('removeIds', x));
            return {
                ...trimObjectStrings(personnelInfo),
                blockType,
                personId: personalInfo.id,
                needPersonId: true,
                isArray: true,
                arr: formData,
            };

        case 'AttributesBlock':
            return {
                blockType: blockType,
                personId: personalInfo.id,
                needPersonId: true,
                isArray: true,
                arr: attributes
                    .filter(x => x.attribute)
                    .map(attribute => {
                        const trimmedAttribute = trimObjectStrings(attribute);
                        const hasJsonValue =
                            attribute.attribute.type.toLowerCase() === 'select' ||
                            attribute.attribute.type.toLowerCase() === 'multiselect';
                        const id = attribute.id > 0 ? attribute.id : undefined;

                        return {
                            ...trimmedAttribute,
                            id,
                            attributeValue: hasJsonValue
                                ? JSON.stringify(attribute.attributeValue)
                                : attribute.attributeValue,
                            attribute: {
                                ...trimmedAttribute.attribute,
                                payload: '',
                            },
                        };
                    }),
            };

        case 'SystemInfoBlock':
            return getPersonGeneralInfo(blockType, person);

        default:
            return null;
    }
};

const languageInfoInvalid = languageInfo =>
    !!(languageInfo.language && languageInfo.language.id && !languageInfo.languageLevel);

const isValid = (person, blockType) => {
    const {
        personalInfo,
        workInfo,
        educationInfo,
        attributesInfo,
        languagesInfo,
        personnelInfo,
        systemInfo
    } = person;

    const errors = [];

    switch (blockType) {
        case 'PersonalInfoBlock':
            if (!personalInfo.lastName) {
                errors.push('lastName');
            }
            if (!personalInfo.firstName) {
                errors.push('firstName');
            }
            if (!personalInfo.sex) {
                errors.push('sex');
            }
            if (personalInfo.isRussianCitizen && !personalInfo.district) {
                errors.push('district');
            }
            if (personalInfo.isRussianCitizen && !personalInfo.region) {
                errors.push('region');
            }
            if (!personalInfo.isRussianCitizen && !personalInfo.nationality) {
                errors.push('nationality');
            }
            if (!personalInfo.document) {
                errors.push('document');
            }
            if (
                personalInfo.document &&
                personalInfo.document.label === 'Другое' &&
                !personalInfo.documentName
            ) {
                errors.push('documentName');
            }
            if (!isPhoneValid(personalInfo.phone)) {
                errors.push('phone');
            }
            if (
                !personalInfo.pastResidences.every(
                    x =>
                        !!x.selectedDistrict &&
                        !!x.selectedRegion &&
                        x.yearFrom &&
                        x.yearTo &&
                        +x.yearFrom >= 1895 &&
                        +x.yearFrom <= +x.yearTo &&
                        +x.yearTo <= new Date().getFullYear(),
                )
            ) {
                errors.push('pastResidences');
            }
            break;
        case 'EducationBlock':
            if (!educationInfo.educations.every(x => !!x.educationLevel)) {
                errors.push('educationLevelEmpty');
            }
            break;
        case 'WorkBlock':
            if (!workInfo.workPlaces.every(x => !!x.company)) {
                errors.push('workplaceCompanyEmpty');
            }
            if (!workInfo.workPlaces.every(x => !!x.position)) {
                errors.push('workplacePositionEmpty');
            }
            break;
        case 'AttributesBlock':
            if (!attributesInfo.attributes.every(x => !!x.attributeValue)) {
                errors.push('attributeValueEmpty');
            }
            break;
        case 'LanguagesBlock':
            if (
                languagesInfo.knownLanguages.some(knownLanguage =>
                    languageInfoInvalid(knownLanguage),
                )
            ) {
                errors.push('languageLevel');
            }
            break;
        case 'PersonnelInfoBlock':
            if (!personnelInfo.dataOrigin || !personnelInfo.dataOrigin.id) {
                errors.push('dataOriginEmpty');
            }
            break;
        case 'AwardsBlock':
            if (
                !personnelInfo.awards.every(
                    x => !!x.selectedCompetitionLevel && !!x.selectedCompetitionLevel.id,
                )
            ) {
                errors.push('awardSelectedCompetitionLevelEmpty');
            }
            if (!personnelInfo.awards.every(x => !!x.name)) {
                errors.push('awardNameEmpty');
            }
            break;
        case 'SystemInfoBlock':
            if (!systemInfo.group) {
                errors.push('group');
            }
            break;
        default:
            break;
    }
    return errors.length && errors;
};

const ProfileInfo = props => {
    const {
        person: personInfo,
        scrollToContainer,
        setActiveMenu,
        activeMenu,
        isWrap,
        setWrap,
        isEdit,
        setEditState,
        loadTime,
        saveBlockError,
        setCancelSave,
        cancelSave,
        visibleWrapSection,
        setVisibleWrapSection,
        canLeaderEdit,
        attributeTypes,
        isFocused
    } = props;
    
    const scrollClass = {
        down: 'sticky-scroll-down',
        up: 'sticky-scroll-up',
    };

    const prevProps = usePrevious(props);

    const [saveBlocks, updateSaveBlocks] = useState(new Set());
    const [person, setPersonInfo] = useState(setPerson(personInfo));
    const [errors, setErrors] = useState([]);
    const [editId, setEditId] = useState();
    const [isFileUploading, setFileUploading] = useState(false);
    const [scrollTo, setScrollTo] = useState(null);
    const [isMenuOpen, setMenuOpen] = useState(false);

    const onOpenMenu = useCallback(
        isOpen => {
            setMenuOpen(isOpen);
            setVisibleWrapSection(null);
            isOpen && document.getElementById('ProfileCardContainer').scrollTo(0, 0);
        },
        [setVisibleWrapSection],
    );

    const onCancel = useCallback(() => {
        setPersonInfo(setPerson(personInfo));
        setErrors([]);
        updateSaveBlocks(new Set());
        setEditState(false);
        setActiveMenu(null);
        onOpenMenu(false);
    }, [personInfo, setActiveMenu, setEditState, onOpenMenu]);

    useEffect(() => {
        if (!isEdit && editId) {
            scrollToContainer(editId, 0);
            setEditId(null);
        } else if (isEdit) {
            setEditId(activeMenu);
        }
    }, [isEdit, editId, activeMenu, scrollToContainer]);

    useEffect(() => {
        if (scrollTo) {
            scrollToContainer(scrollTo, 0);
            setScrollTo(null);
        }
    }, [scrollTo, scrollToContainer]);

    useEffect(() => {
        if (visibleWrapSection) {
            scrollToContainer(visibleWrapSection, 0, true);
        }
    }, [visibleWrapSection, scrollToContainer]);

    useEffect(
        () => {
            if (
                person?.personalInfo?.id !== personInfo?.personalInfo?.id ||
                (loadTime && prevProps.loadTime !== loadTime) ||
                prevProps.saveBlockError !== saveBlockError
            ) {
                setPersonInfo(setPerson(personInfo));
            }
        },
        [
            personInfo,
            person,
            loadTime,
            prevProps.loadTime,
            saveBlockError,
            prevProps.saveBlockError,
        ],
    );

    useEffect(() => {
        if (previousFilesRef.current !== personInfo?.filesInfo?.files) {
            setPersonInfo(setPerson(personInfo));
            previousFilesRef.current = personInfo.filesInfo.files;
        }
    }, [personInfo, personInfo.filesInfo.files]);

    useEffect(() => {
        if (cancelSave) {
            onCancel();
            setCancelSave(false);
        }
    }, [setCancelSave, cancelSave, onCancel]);

    const previousFilesRef = useRef(personInfo?.filesInfo?.files);

    const onChange = (person, type) => {
        setPersonInfo(person);
        updateSaveBlocks(saveBlocks.add(type));
    };

    const getMessageForErrorBlock = type => {
        const item = profileMenuItems.find(
            pmi => pmi.blockType === type || pmi.additionalBlockType === type,
        );
        if (item) {
            if (activeMenu !== item.id) {
                onChangeProfileLink(item);
                isWrap && setWrap(false);
                onOpenMenu(false);
            }

            return `Проверьте корректность введенных данных в блоке "${item.name}"`;
        }
        return 'Проверьте корректность введенных данных';
    };

    const onSave = () => {
        try {
            let blocks = { personId: person.personalInfo.id, blocksArray: [] };
            for (let type of saveBlocks.values()) {
                const errors = isValid(person, type);
                if (errors) {
                    setErrors(errors);

                    props.showErrorAlert(getMessageForErrorBlock(type));
                    return false;
                }

                const data = getPersonToSave(person, type);
                if (data) {
                    blocks.blocksArray.push(data);
                }
            }
            props.saveBlocksArray(blocks, true);
        } catch (e) {
            props.showErrorAlert('Произошла ошибка при сохранении');
        }
        setWrap(false);
        updateSaveBlocks(new Set());
        setActiveMenu(null);
        setEditState(false);
        setVisibleWrapSection(null);
    };

    const onChangeProfileLink = link => {
        setActiveMenu(link.id);
    };

    const renderContent = () => {
        const showReadMode = !isEdit && !isWrap;
        const profileBlockProps = {
            setScrollTo,
            isEdit,
            isWrap,
            setWrap,
            setVisibleWrapSection,
        };

        return (
            <div
                className={classnames('LKPersonProfileWrapper', {
                    'LKPersonProfileWrapper--ext': isEdit,
                    'LKPersonProfileWrapper--focused': isFocused,
                })}
                id="ProfileInfo"
            >
                {(showReadMode || activeMenu === MENU_PROFILE_PERSONAL) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_PERSONAL}
                        header="Личные данные"
                        {...profileBlockProps}
                    >
                        <PersonalInfo
                            person={person}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_PERSONAL}
                            onChange={onChange}
                            setWrap={setWrap}
                            isWrap={
                                (isWrap && activeMenu === MENU_PROFILE_PERSONAL) ||
                                (isWrap && !isEdit)
                            }
                        />
                    </ProfileInfoBlock>
                )}
                {(showReadMode ||
                    activeMenu === MENU_PROFILE_EDUCATION ||
                    visibleWrapSection === MENU_PROFILE_EDUCATION) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_EDUCATION}
                        header="Образование"
                        {...profileBlockProps}
                    >
                        <EducationInfo
                            person={person}
                            isWrap={
                                (isWrap && activeMenu === MENU_PROFILE_EDUCATION) ||
                                (isWrap && !isEdit)
                            }
                            setWrap={setWrap}
                            setVisibleWrapSection={setVisibleWrapSection}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_EDUCATION}
                            onChange={onChange}
                            errors={errors}
                            activeMenu={activeMenu}
                        />
                    </ProfileInfoBlock>
                )}

                {(showReadMode ||
                    activeMenu === MENU_PROFILE_WORK_EXPERIENCE ||
                    visibleWrapSection === MENU_PROFILE_WORK_EXPERIENCE) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_WORK_EXPERIENCE}
                        header="Опыт работы"
                        {...profileBlockProps}
                    >
                        <WorkExperienceInfo
                            person={person}
                            isWrap={
                                (isWrap && activeMenu === MENU_PROFILE_WORK_EXPERIENCE) ||
                                (isWrap && !isEdit)
                            }
                            setWrap={setWrap}
                            setVisibleWrapSection={setVisibleWrapSection}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_WORK_EXPERIENCE}
                            onChange={onChange}
                            errors={errors}
                        />
                    </ProfileInfoBlock>
                )}

                {(showReadMode ||
                    activeMenu === MENU_PROFILE_GOVERNMENT ||
                    visibleWrapSection === MENU_PROFILE_GOVERNMENT) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_GOVERNMENT}
                        header="Сведения о службе в вооружённых силах, органах безопасности"
                        {...profileBlockProps}
                    >
                        <GovernmentInfo
                            person={person}
                            isWrap={
                                (isWrap && activeMenu === MENU_PROFILE_GOVERNMENT) ||
                                (isWrap && !isEdit)
                            }
                            setWrap={setWrap}
                            setVisibleWrapSection={setVisibleWrapSection}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_GOVERNMENT}
                            onChange={onChange}
                            errors={errors}
                        />
                    </ProfileInfoBlock>
                )}

                {(showReadMode ||
                    activeMenu === MENU_PROFILE_LANGUAGES ||
                    visibleWrapSection === MENU_PROFILE_LANGUAGES) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_LANGUAGES}
                        header="Иностранные языки"
                        {...profileBlockProps}
                    >
                        <LanguagesInfo
                            person={person}
                            isWrap={
                                (isWrap && activeMenu === MENU_PROFILE_LANGUAGES) ||
                                (isWrap && !isEdit)
                            }
                            setWrap={setWrap}
                            setVisibleWrapSection={setVisibleWrapSection}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_LANGUAGES}
                            onChange={onChange}
                            errors={errors}
                            setFileUploading={setFileUploading}
                            isFileUploading={isFileUploading}
                        />
                    </ProfileInfoBlock>
                )}

                {(showReadMode || activeMenu === MENU_PROFILE_FAMILY) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_FAMILY}
                        header="Семейное положение"
                        {...profileBlockProps}
                    >
                        <FamilyInfo
                            person={person}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_FAMILY}
                            onChange={onChange}
                            errors={errors}
                        />
                    </ProfileInfoBlock>
                )}

                {(showReadMode || activeMenu === MENU_PROFILE_SOCIAL_NETWORKS) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_SOCIAL_NETWORKS}
                        header="Социальные сети"
                        {...profileBlockProps}
                    >
                        <SocialNetworksInfo
                            person={person}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_SOCIAL_NETWORKS}
                            onChange={onChange}
                            errors={errors}
                        />
                    </ProfileInfoBlock>
                )}

                {(showReadMode || activeMenu === MENU_PROFILE_FILES) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_FILES}
                        header="Файлы"
                        {...profileBlockProps}
                    >
                        <FilesInfo
                            person={person}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_FILES}
                            onChange={onChange}
                            errors={errors}
                        />
                    </ProfileInfoBlock>
                )}

                {(showReadMode || activeMenu === MENU_PROFILE_PERSONNEL) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_PERSONNEL}
                        header="Дополнительная информация"
                        {...profileBlockProps}
                    >
                        <PersonnelInfo
                            person={person}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_PERSONNEL}
                            onChange={onChange}
                            errors={errors}
                        />
                    </ProfileInfoBlock>
                )}

                {(showReadMode || activeMenu === MENU_PROFILE_ATTRIBUTES) && attributeTypes?.length !== 0 && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_ATTRIBUTES}
                        header="Дополнительные поля"
                        {...profileBlockProps}
                    >
                        <AttributesInfo
                            person={person}
                            setFileUploading={setFileUploading}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_ATTRIBUTES}
                            onChange={onChange}
                            errors={errors}
                        />
                    </ProfileInfoBlock>
                )}

                {(showReadMode || activeMenu === MENU_PROFILE_SYSTEM_INFO) && (
                    <ProfileInfoBlock
                        blockId={MENU_PROFILE_SYSTEM_INFO}
                        header="Системная информация"
                        {...profileBlockProps}
                    >
                        <SystemInfo
                            person={person}
                            isEdit={isEdit && activeMenu === MENU_PROFILE_SYSTEM_INFO}
                            onChange={onChange}
                            errors={errors}
                        />
                    </ProfileInfoBlock>
                )}
            </div>
        );
    };

    const filteredMenu = attributeTypes?.length === 0 ? profileMenuItems.filter(x => x.id !== MENU_PROFILE_ATTRIBUTES) : profileMenuItems;
    return (
        <div
            className={classnames('LkProfileInfoContainer', {
                'LkProfileInfoContainer--focused': isFocused,
            })}
        >
            {isEdit && (
                <Sticky scrollClass={scrollClass}>
                    <div className="top--55 sticky">
                        <LinkMenu
                            items={filteredMenu}
                            activeId={activeMenu}
                            onChange={onChangeProfileLink}
                            isWrap={isWrap}
                            setWrap={setWrap}
                            defaultOpen={true}
                            onOpenMenu={onOpenMenu}
                        />
                    </div>
                </Sticky>
            )}

            {isFileUploading && <Loader absolute />}
            <LoadingUrlBlocker
                isLoading={isFileUploading}
                message="Выполняется загрузка файла. Пожалуйста, подождите."
            />
            {!isMenuOpen && renderContent()}
            {canLeaderEdit() &&
                (isEdit && activeMenu && !isMenuOpen && (
                    <StickyFooter unstick={isFocused}>
                        <ProfileButton
                            type="cancel"
                            className="LKEditButtons__Item"
                            onClick={onCancel}
                        >
                            Отменить
                        </ProfileButton>
                        <ProfileButton className="LKEditButtons__Item" onClick={onSave}>
                            Готово
                        </ProfileButton>
                    </StickyFooter>
                ))}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    person: personFullSelector(state, ownProps.id),
    auth: state.auth,
    loadTime: state.person.loadTime,
    saveBlockError: state.personBlock.error,
    attributeTypes: state.attributes.data.payload,
});

const actions = { saveBlock, showErrorAlert, saveBlocksArray };

export default connect(mapStateToProps, actions)(WithKeyboardHandler(memo(ProfileInfo), 'ProfileInfo'));
