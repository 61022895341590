import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { setSearchFilter } from '../utils';
import { EDUCATION_FILTER_ID } from '../constants';
import Input from 'components/Lk/Uikit/Input';
import Field from 'components/Lk/Uikit/Field';
import Select from 'components/Lk/Uikit/Select/CustomSelect';
import SearchGroupCondition from '../SearchGroupCondition';
import ActiveFilters from '../ActiveFilters';
import uuid from 'uuid/v4';
import { setActiveFilter } from 'ducks/FilterSearch';
import { queryOperation } from 'components/Search/QueryOperation';
import SuccessButton from '../SuccessButton';
import { FIELDS_TYPE } from '../SearchFilters/constants';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';

const CustomSelect = (props) => {
    const { options, value } = props;
    return (
        <Select {...props} value={options.filter((x) => value && value.indexOf(x.value) >= 0)} />
    );
};

const UNIVERSITY = 'university';
const FACULTY = 'faculty';
const SPECIALTY = 'specialty';
const GRADUATION_YEAR = 'graduation_year';
const EDUCATIONAL_LEVEL = 'educational_level';
const STUDY_FORM = 'study_form';
const EXTRA_INFO = 'extra_info';

const sectionOptions = [
    { value: EDUCATIONAL_LEVEL, label: 'Уровень' },
    { value: STUDY_FORM, label: 'Форма обучения' },
    { value: EXTRA_INFO, label: 'Доп. образование' },
];

const EducationFilter = (props) => {
    const {
        setFiltersState,
        updateFiltersWithCondition,
        getGroupCondition,
        educationalLevels,
        studyForms,
        activeFilter,
        mainFilter,
        onSuccess,
        isFocused,
    } = props;
    const [visibleSection, setVisibleSection] = useState(null);

    const [yearFrom, setYearFrom] = useState();
    const [yearTo, setYearTo] = useState();
    const [educationalLevel, setEducationalLevel] = useState(null);
    const [university, setUniversity] = useState(null);
    const [studyForm, setStudyForm] = useState(null);
    const [faculty, setFaculty] = useState(null);
    const [specialty, setSpecialty] = useState(null);
    const [extraInfo, setExtraInfo] = useState(null);
    const [excludeFromSearch, setExcludeFromSearch] = useState(false);

    const [groupCondition, setGroupCondition] = useState(getGroupCondition(EDUCATION_FILTER_ID));

    const handleCriteriaChange = (e) => {
        setVisibleSection(e ? e.value : null);
    };

    const onSubmit = async (isClose) => {
        await AddFilter();
        clearFields();
        isClose && onSuccess();
    };

    useEffect(() => {
        if (activeFilter === null) {
            return;
        }
        setVisibleSection(activeFilter.section);

        setExcludeFromSearch(activeFilter.excludeFromSearch);

        switch (activeFilter.section) {
            case EDUCATIONAL_LEVEL:
                setEducationalLevel(educationalLevels.filter((x) => activeFilter.values.find((z) => z === x.id)));
                break;
            case UNIVERSITY:
                setUniversity(activeFilter.value);
                break;
            case STUDY_FORM:
                setStudyForm(studyForms.filter((x) => activeFilter.values.find((z) => z === x.id)));
                break;
            case FACULTY:
                setFaculty(activeFilter.value);
                break;
            case SPECIALTY:
                setSpecialty(activeFilter.value);
                break;
            case EXTRA_INFO:
                setExtraInfo(activeFilter.value);
                break;
            case GRADUATION_YEAR:
                const [activeFilters] = mainFilter.filter(
                    (x) => x.filterId === EDUCATION_FILTER_ID,
                );
                const { childs: filters = [] } = activeFilters || {};

                const currentFilters = filters.filter((x) => x.group === activeFilter.group);

                currentFilters.forEach((x) => {
                    x.operation === queryOperation.LessOrEqual
                        ? setYearTo(x.value)
                        : setYearFrom(x.value);
                });
                break;
            default:
                break;
        }
    }, [activeFilter, educationalLevels, studyForms, mainFilter]);

    const buttonEnabled =
        (visibleSection === EDUCATIONAL_LEVEL && educationalLevel) ||
        (visibleSection === STUDY_FORM && studyForm) ||
        (visibleSection === EXTRA_INFO && extraInfo) ||
        university ||
        faculty ||
        specialty ||
        (yearFrom && yearFrom > 0) ||
        (yearTo && yearTo > 0);

    const AddFilter = () => {
        const group = uuid();
        let readyFilters = [];
        extraInfo &&
            readyFilters.push(
                setSearchFilter(
                    'PersonEducations',
                    'ExtraInfo',
                    queryOperation.Contain,
                    extraInfo,
                    `Доп. образование: ${extraInfo}`,
                    'AND',
                    EDUCATION_FILTER_ID,
                    visibleSection,
                    excludeFromSearch,
                ),
            );

        educationalLevel &&
            readyFilters.push(
                setSearchFilter(
                    'PersonEducations',
                    'EducationLevelId',
                    queryOperation.InArray,
                    educationalLevel.map((x) => x.id),
                    educationalLevel?.length !== 0
                        ? `Уровень образования: ${educationalLevel.map((x) => ` ${x.label}`)}`
                        : null,
                    'AND',
                    EDUCATION_FILTER_ID,
                    visibleSection,
                    excludeFromSearch,
                    null,
                    group,
                    FIELDS_TYPE.multiSelect,
                ),
            );

        studyForm &&
            readyFilters.push(
                setSearchFilter(
                    'PersonEducations',
                    'StudyFormId',
                    queryOperation.InArray,
                    studyForm.map((x) => x.id),
                    studyForm?.length !== 0
                        ? `Форма обучения:${studyForm.map((x) => ` ${x.label}`)}`
                        : null,
                    'AND',
                    EDUCATION_FILTER_ID,
                    visibleSection,
                    excludeFromSearch,
                    null,
                    group,
                    FIELDS_TYPE.multiSelect,
                ),
            );

        university &&
            readyFilters.push(
                setSearchFilter(
                    'PersonEducations',
                    'University',
                    queryOperation.Contain,
                    university,
                    `ВУЗ: ${university}`,
                    'AND',
                    EDUCATION_FILTER_ID,
                    UNIVERSITY,
                    excludeFromSearch,
                ),
            );

        faculty &&
            readyFilters.push(
                setSearchFilter(
                    'PersonEducations',
                    'Faculty',
                    queryOperation.Contain,
                    faculty,
                    `Факультет: ${faculty}`,
                    'AND',
                    EDUCATION_FILTER_ID,
                    FACULTY,
                    excludeFromSearch,
                ),
            );

        specialty &&
            readyFilters.push(
                setSearchFilter(
                    'PersonEducations',
                    'Specialty',
                    queryOperation.Contain,
                    specialty,
                    `Специальность: ${specialty}`,
                    'AND',
                    EDUCATION_FILTER_ID,
                    SPECIALTY,
                    excludeFromSearch,
                ),
            );

        const yearUuid = uuid();
        yearFrom &&
            yearFrom > 0 &&
            readyFilters.push(
                setSearchFilter(
                    'PersonEducations',
                    'GraduationYear',
                    queryOperation.GreaterOrEqual,
                    yearFrom,
                    `Год окончания больше: ${yearFrom}`,
                    'AND',
                    EDUCATION_FILTER_ID,
                    GRADUATION_YEAR,
                    excludeFromSearch,
                    null,
                    yearUuid,
                    FIELDS_TYPE.year,
                    yearFrom,
                ),
            );

        yearTo &&
            yearTo > 0 &&
            readyFilters.push(
                setSearchFilter(
                    'PersonEducations',
                    'GraduationYear',
                    queryOperation.LessOrEqual,
                    yearTo,
                    `Год окончания меньше: ${yearTo}`,
                    'AND',
                    EDUCATION_FILTER_ID,
                    GRADUATION_YEAR,
                    excludeFromSearch,
                    null,
                    yearUuid,
                    FIELDS_TYPE.year,
                    yearTo,
                ),
            );

        return readyFilters.length !== 0 && setFiltersState(readyFilters, EDUCATION_FILTER_ID);
    };

    const clearFields = () => {
        setYearFrom(null);
        setYearTo(null);
        setEducationalLevel(null);
        setUniversity(null);
        setStudyForm(null);
        setFaculty(null);
        setSpecialty(null);
        setExtraInfo(null);
        setVisibleSection(null);
        setExcludeFromSearch(false);
    };

    const handleGroupConditionChange = (e) => {
        const condition = e.currentTarget.checked ? 'OR' : 'AND';
        updateFiltersWithCondition(EDUCATION_FILTER_ID, condition);
        setGroupCondition(condition);
    };

    const sectionValue = sectionOptions.find((x) => x.value === visibleSection);

    const defaultFilters = () => (
        <>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden':
                        activeFilter && activeFilter.section !== UNIVERSITY,
                })}
            >
                <Field>
                    <Input
                        onChange={(e) => setUniversity(e.currentTarget.value)}
                        value={university || ''}
                        placeholder="ВУЗ"
                    />
                </Field>
            </div>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden':
                        activeFilter && activeFilter.section !== FACULTY,
                })}
            >
                <Field>
                    <Input
                        onChange={(e) => setFaculty(e.currentTarget.value)}
                        value={faculty || ''}
                        placeholder="Факультет"
                    />
                </Field>
            </div>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden':
                        activeFilter && activeFilter.section !== SPECIALTY,
                })}
            >
                <Field>
                    <Input
                        onChange={(e) => setSpecialty(e.currentTarget.value)}
                        value={specialty || ''}
                        placeholder="Специальность"
                    />
                </Field>
            </div>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden':
                        activeFilter && activeFilter.section !== GRADUATION_YEAR,
                })}
            >
                <Field size={50}>
                    <Input
                        type="number"
                        value={yearFrom || ''}
                        onChange={(e) => setYearFrom(e.currentTarget.value)}
                        placeholder="Год окончания от"
                    />
                </Field>
                <Field size={50}>
                    <Input
                        type="number"
                        value={yearTo || ''}
                        onChange={(e) => setYearTo(e.currentTarget.value)}
                        placeholder="Год окончания до"
                    />
                </Field>
            </div>
        </>
    );

    return (
        <>
            <div className="LKSearchCardVisibleFIlterLine">
                <SearchGroupCondition
                    checked={groupCondition === 'OR'}
                    onClick={(e) => handleGroupConditionChange(e)}
                />
            </div>
            <ActiveFilters filterId={EDUCATION_FILTER_ID} />
            {defaultFilters()}
            {((activeFilter && sectionValue) || !activeFilter) && (
                <div className="LKSearchCardVisibleFIlterLine">
                    <Field>
                        <Select
                            placeholder="Выберите дополнительный критерий"
                            options={sectionOptions}
                            value={sectionValue || null}
                            onChange={handleCriteriaChange}
                            isClearable={!activeFilter}
                        />
                    </Field>
                </div>
            )}
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': visibleSection !== EDUCATIONAL_LEVEL,
                })}
            >
                <Field>
                    <CustomSelect
                        placeholder="Выберите уровень образования"
                        options={educationalLevels}
                        onChange={setEducationalLevel}
                        isMulti
                        value={(educationalLevel || []).map((x) => x.value)}
                        closeMenuOnSelect={false}
                    />
                </Field>
            </div>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': visibleSection !== STUDY_FORM,
                })}
            >
                <Field>
                    <CustomSelect
                        placeholder="Выберите форму обучения"
                        options={studyForms}
                        onChange={setStudyForm}
                        isMulti
                        value={(studyForm || []).map((x) => x.value)}
                        closeMenuOnSelect={false}
                    />
                </Field>
            </div>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': visibleSection !== EXTRA_INFO,
                })}
            >
                <Field>
                    <Input
                        onChange={(e) => setExtraInfo(e.currentTarget.value)}
                        value={extraInfo || ''}
                    />
                </Field>
            </div>

            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': !buttonEnabled,
                })}
            >
                <Field>
                    <StyledCheckBox
                        onChange={setExcludeFromSearch}
                        checked={excludeFromSearch}
                        title="Исключить из поиска"
                        style={{ display: 'inline-block' }}
                    />
                </Field>
            </div>

            <SuccessButton
                onSubmit={onSubmit}
                buttonEnabled={buttonEnabled}
                activeFilter={activeFilter}
                visibleSection={visibleSection}
                defaultFilters={defaultFilters}
                isFocused={isFocused}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        educationalLevels: state.catalogs.educationalLevels.data,
        studyForms: state.catalogs.studyForms.data,
        mainFilter: state.filterSearch.mainFilter,
        activeFilter: state.filterSearch.activeFilter,
    };
};

const actions = { setActiveFilter };

export default connect(mapStateToProps, actions)(EducationFilter);
