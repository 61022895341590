export const TEAM_TYPE = 'team';
export const WISHLIST_TYPE = 'wishlist';
export const SEARCH_TYPE = 'search';

export const mainCardMenuItems = [
    {
        id: TEAM_TYPE,
        name: 'Команды',
        icon: 'team',
    },
    {
        id: WISHLIST_TYPE,
        name: 'Избранное',
        icon: 'favorite',
    },

    {
        id: SEARCH_TYPE,
        name: 'Поиск',
        icon: 'search',
    },
];


export const TITLE_LIMIT = 70;
export const DESCRIPTION_LIMIT = 1000;