import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { masterSearch, externalSearchRoute } from 'routes';
import MasterSearch from 'pages/leader/MasterSearch';
import ExternalSearch from 'pages/leader/MasterSearch/ExternalSearch';
import NotFound from 'pages/common/NotFound';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_USE_EXTERNAL_SEARCH } from 'rightsController';

class MasterSearchRoutes extends Component {
    render() {
        return (
            <Switch>
                <ProtectedRoute
                    exact
                    path={masterSearch.url}
                    component={MasterSearch}
                    allowedRoles={ALLOWED_USE_EXTERNAL_SEARCH}
                />
                <ProtectedRoute
                    exact
                    path={externalSearchRoute.url}
                    component={ExternalSearch}
                    allowedRoles={ALLOWED_USE_EXTERNAL_SEARCH}
                />
                <Route component={NotFound} />
            </Switch>
        );
    }
}

export default MasterSearchRoutes;
