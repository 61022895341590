import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { search, loadDictionaries } from 'ducks/UserActions';
import { selectDictionaries } from 'ducks/Dictionary';
import UserActionSearchPage from './UserActionSearchPage';
import { USERS_DICTIONARY_NAME } from 'constants.js';

function mapStateToProps(state) {
    return {
        users: selectDictionaries(state.dictionary, USERS_DICTIONARY_NAME),
    };
}

function mapDispatchToProps(dispatch) {
    const actions = {
        showErrorAlert,
        showPageLoader,
        hidePageLoader,
        loadDictionaries,
    };

    return {
        ...bindActionCreators(actions, dispatch),
        search,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserActionSearchPage);
