import React, { Component } from 'react';
import Input from '../Input';
import './InputNumeric.scss';
import { isNullOrWhitespace } from '../../../utils';

class InputNumeric extends Component {
    state = {
        count: 1,
        value: "",
        digitNumber: 2,
        numValue: 0,
    }

    changeByCount = (increase, value, numValue, digitNumber) => {
        const count = this.state.count;
        if (this.props.value) {
            value = numValue;
        }
        value = Number.isInteger(value) ? +(parseInt(value)) : +(parseFloat(value)).toFixed(digitNumber);

        value = isNaN(value) ? 0 : value;
        value = increase ? value += count : value -= count;

        return value;
    }

    handleChange = (e, increase) => {
        let value = this.state.value;
        let numValue = this.props.value;

        const digitNumber = this.props.digitNumber || this.state.digitNumber;
        const maximum = this.props.max || null;
        const minimum = this.props.min || null;
        
        if (e) {
            value = e.target.value;
            if (isNullOrWhitespace(value)) {
                this.props.onChange
                    ? this.props.onChange(null)
                    : this.setState({ value: null });
                return;
            }
        } else {
            value = this.changeByCount(increase, value,numValue, digitNumber)
        }
        
        if (maximum && value > maximum) {
            value = maximum;
        }

        else if (minimum && value <= minimum) {
            value = minimum;
        }

        numValue = Number.isInteger(value) ? +(parseInt(value)) : +(parseFloat(value)).toFixed(digitNumber);

        this.props.onChange
            ? this.props.onChange(numValue)
            : this.setState({ value: value });
    }

    disableDotInput = (e) => {
        if (e.keyCode === 190 || e.keyCode === 110) {
            e.preventDefault();
        }
    }

    render() {
        return (
            <div className="InputNumeric__Container">
                    <Input
                        className="InputNumeric"
                        placeholder={this.props.placeholder}
                        type="number"
                        value={this.props.value != null ?this.props.value : this.state.value}
                        onKeyDown={this.disableDotInput}
                        maxLength={this.props.maxLength || 250}
                        onChange={(e) => this.handleChange(e, false)}
                        step="any"
                    />
                <div className="InputNumeric__ButtonContainer">
                    <button className="InputNumeric__Button"
                        onClick={() => this.handleChange(null, true)}>&#11205;</button>
                    <button className="InputNumeric__Button"
                        onClick={() => this.handleChange(null, false)}>&#11206;</button>
                </div>
            </div>

        )
    }
}

export default InputNumeric;