import React from 'react';
import PropTypes from 'prop-types';

const Menu = props => {
    const { onClick } = props;

    const handleClick = e => {
        e.stopPropagation();
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <div className="LKPersonCardMenu" onClick={handleClick}>
            ...
        </div>
    );
};

Menu.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default Menu;
