import React from 'react';
import PropTypes from 'prop-types';
import './CloseButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CloseButton = props => {
    const { onClick } = props;
    return (
        <div className='LKCloseButton' onClick={onClick}>
            <FontAwesomeIcon icon='times-circle' />
        </div>
    );
};

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default CloseButton;