import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { visibilityFilterOptions } from 'constants.js';

const WishlistModal = props => {
    const { comment } = props;
    const [wishlists, setWishlists] = useState([]);
    useEffect(() => {
        if (comment?.wishlists) {
            setWishlists(comment.wishlists);
        }
    }, [comment]);
    const renderWishlist = item => {
        return (
            <div className="LKComment" key={item.id}>
                <div className="LKComment__Wrapper">
                    <div className="LKComment__Text">
                        <p>{item.name}</p>
                    </div>
                </div>

                <div className="LKComment__Info">
                    <div>{visibilityFilterOptions.find(f => f.type === item.visibility)?.name}</div>
                    <div>{item.modified && item.modified}</div>
                </div>
            </div>
        );
    };
    return (
        <div>
            <div className="LKWhishlistComments">
                <div className="LKWhishlistComments__Header">Список подборок</div>
                <div> {wishlists.map(renderWishlist)}</div>
            </div>
        </div>
    );
};

WishlistModal.propTypes = {
    comment: PropTypes.any,
};

export default WishlistModal;
