import React from 'react';
import Image from 'components/common/Image';
import { getUserFullName } from 'utils';
import { getPersonPhotoUri, photoSizes } from 'api';
import { Link } from 'react-router-dom';
import CheckField from 'components/uikit/CheckField';
import PropTypes from 'prop-types';

const PersonCard = props => {
    const { person, checked, checkable, onCheck } = props;
    return (
        <div className="PersonSelectorCard">
            <div className="PersonSelectorCard__Image">
                <Link to={`/person/${person.id}`} target="_blank">
                    <Image
                        alt="Фотография"
                        photoUri={getPersonPhotoUri(photoSizes.small, person.id)}
                        showLoader={false}
                        loading={false}
                        onClick={undefined}
                        onLoad={undefined}
                    />
                </Link>
            </div>
            <div className="PersonSelectorCard__Info">
                <Link to={`/person/${person.id}`} target="_blank">
                    <>{getUserFullName(person)}</>
                </Link>
                <div className="PersonSelectorCard__Info--small">
                    <div>{person.currentJobTitle || person.currentPosition}</div>
                    <div className="PersonSelectorCard__Info--light">
                        {person.currentJobCompanyName || person.currentCompany}
                    </div>
                </div>
            </div>
            {checkable && (
                <div className="PersonSelectorCard__Check">
                    <CheckField checked={checked} onChange={onCheck} />
                </div>
            )}
        </div>
    );
};

PersonCard.defaultProps = {
    checkable: false,
    checked: false,
};

PersonCard.propTypes = {
    person: PropTypes.object.isRequired,
    checkable: PropTypes.bool,
    checked: PropTypes.bool,
    onCheck: PropTypes.func,
};

export default PersonCard;
