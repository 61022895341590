import { appName, ACCESS_STATUS } from 'constants.js';
import {
    getPersonAccess,
    addPersonAccess,
    getPersonAccesses,
    sendReviewAccessRewquestByIT,
    sendReviewAccessRewquestBySecurity,
    removeAccessRequest,
} from '../api';
import { serviceResultCode, getError } from 'serviceErrors';
import { takeEvery, put, call, select, all } from 'redux-saga/effects';
import { showErrorAlert, showSuccessAlert } from './Alert';
import { isSecurityAdmin, isITAdmin } from 'rightsController';
import { fetchDictionaries } from './Dictionary';
import { GROUPS_DICTIONARY_NAME } from 'constants.js';

const moduleName = 'access';
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;

export const REQUEST_START = `${appName}/${moduleName}/REQUEST_START`;
export const REQUEST_SUCCESS = `${appName}/${moduleName}/REQUEST_SUCCESS`;
export const REQUEST_FAILED = `${appName}/${moduleName}/REQUEST_FAILED`;

export const ADD_REQUEST = `${appName}/${moduleName}/ADD_REQUEST`;
export const ADD_REQUEST_SUCCESS = `${appName}/${moduleName}/ADD_REQUEST_SUCCESS`;

export const FETCH_ACCESSES_REQUEST = `${appName}/${moduleName}/FETCH_ACCESSES_REQUEST`;
export const FETCH_ACCESSES_SUCCESS = `${appName}/${moduleName}/FETCH_ACCESSES_SUCCESS`;

export const REVIEW_ACCESS_REQUEST = `${appName}/${moduleName}/REVIEW_ACCESS_REQUEST`;
export const REVIEW_ACCESS_SUCCESS = `${appName}/${moduleName}/REVIEW_ACCESS_SUCCESS`;

export const REMOVE_REQUEST = `${appName}/${moduleName}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${appName}/${moduleName}/REMOVE_SUCCESS`;

const initialState = {
    loading: false,
    loadComplete: true,
    needUpdate: false,
    data: {
        lastOrderingKey: null,
        lastAccessRequest: null,
        accessRequests: [],
        linkedUser: null,
        accessStatus: 'NoOngoingAccessRequest',
    },
    accesses: [],
    meta: {},
    error: '',
};

export const allAccessDictionaries = [GROUPS_DICTIONARY_NAME];

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case REQUEST_START:
            return {
                ...state,
                loading: true,
            };

        case REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                loadComplete: true,
            };

        case REQUEST_FAILED:
            return {
                ...state,
                needUpdate: false,
                loadComplete: true,
                loading: false,
                error: error.message,
            };

        case ADD_REQUEST_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    accessRequests: state.data.accessRequests 
                        ? state.data.accessRequests.concat(payload.data) 
                        : [].concat(payload.data),
                    lastAccessRequest: payload.data,
                    accessStatus: ACCESS_STATUS.ACCESS_REQUEST_PENDING,
                },
            };

        case FETCH_ACCESSES_SUCCESS:
            return {
                ...state,
                needUpdate: false,
                accesses: payload.payload.map(access => ({
                    ...access.accessRequest,
                    matchingExistingUser: access.matchingExistingUser,
                })),
                meta: payload.meta || {},
            };

        case FETCH_SUCCESS:
            return { ...state, data: payload.data };

        case REVIEW_ACCESS_SUCCESS:
            return {
                ...state,
                needUpdate: true,
            };

        case REMOVE_SUCCESS: {
            return {
                ...state,
                needUpdate: true,
                accesses: state.accesses.filter(access => access.id !== payload),
            };
        }
        default:
            return state;
    }
}

export const addAccess = data => {
    return {
        type: ADD_REQUEST,
        payload: data,
    };
};

export const removeAccess = data => {
    return {
        type: ADD_REQUEST,
        payload: data,
    }
};

export const fetchAccess = personId => {
    return {
        type: FETCH_REQUEST,
        payload: { personId },
    };
};

export const fetchPersonAccesses = params => ({
    type: FETCH_ACCESSES_REQUEST,
    payload: params,
});

export const requestStart = () => {
    return {
        type: REQUEST_START,
    };
};

export const requestSuccess = () => {
    return {
        type: REQUEST_SUCCESS,
    };
};

export const requestFailed = error => {
    return {
        type: REQUEST_FAILED,
        error,
    };
};

export const fetchRequestSuccess = data => {
    return {
        type: FETCH_SUCCESS,
        payload: { data },
    };
};

export const addRequestSuccess = data => {
    return {
        type: ADD_REQUEST_SUCCESS,
        payload: { data },
    };
};

export const fetchPersonAccessesSuccess = data => ({
    type: FETCH_ACCESSES_SUCCESS,
    payload: data,
});

export const reviewAccessRequest = data => ({
    type: REVIEW_ACCESS_REQUEST,
    payload: data,
});

export const reviewAccessSuccess = data => ({
    type: REVIEW_ACCESS_SUCCESS,
    payload: data,
});

export const removeRequest = id => ({
    type: REMOVE_REQUEST,
    payload: id,
});

export const removeSuccess = id => ({
    type: REMOVE_SUCCESS,
    payload: id,
});

export const allActions = {
    fetchAccess,
    requestStart,
    requestSuccess,
    requestFailed,
};

const templateRequestSaga = ({ action }) =>
    function*({ payload }) {
        const { auth } = yield select();

        yield put(requestStart());
        yield put(fetchDictionaries(allAccessDictionaries));

        try {
            yield call(action, payload, auth.user);
            yield put(requestSuccess());
        } catch (error) {
            const reqError = getError(error, getGroupError);

            yield put(showErrorAlert(reqError.message));
            yield put(requestFailed(reqError));
        }
    };

const getGroupError = (code, payload) => {
    switch (code) {
        case serviceResultCode.PersonAccessRequestInvalidPhoneNumber:
            return `Проверьте корректность телефонного номера`;
        case serviceResultCode.PersonAccessRequestEmptyEmail:
            return `Проверьте корректность e-mail`;
        case serviceResultCode.PersonPersonnelIsNull:
            return `У резервиста не заполнена дополнительная информация`;
        case serviceResultCode.PersonAccessInvalidRequest:
            return payload;
        case serviceResultCode.RoleNotFound:
            return payload;
        case serviceResultCode.UserUpdateError:
            return payload;
        default:
            return 'Произошла непредвиденная ошибка';
    }
};

const fetchAccessSaga = templateRequestSaga({
    action: function*({ personId }) {
        const response = yield call(getPersonAccess, personId);
        yield put(fetchRequestSuccess(response.data));
    },
    errorMsg: `При загрузке информации о доступах возникла ошибка`,
});

const fetchPersonAccessesSaga = templateRequestSaga({
    action: function*(payload) {
        const response = yield call(getPersonAccesses, payload);
        yield put(fetchPersonAccessesSuccess(response.data));
    },
    errorMsg: `При загрузке информации о доступе возникла ошибка`,
});

const addAccessSaga = templateRequestSaga({
    action: function*(payload) {
        const response = yield call(addPersonAccess, payload);
        if (response.status && response.status === 200) {
            yield put(addRequestSuccess(response.data));
            yield put(showSuccessAlert('Запрос отправлен'));
        }
    },
    errorMsg: `При добавлении доступа возникла ошибка`,
});

const reviewAccessRequestSaga = templateRequestSaga({
    action: function*(payload, user) {
        const reqAction =
            (isITAdmin(user) && sendReviewAccessRewquestByIT) ||
            (isSecurityAdmin(user) && sendReviewAccessRewquestBySecurity) ||
            false;
        if (!reqAction) {
            throw new Error('Only IT and Security');
        }

        const response = yield call(reqAction, payload);
        yield put(reviewAccessSuccess(response.data));
    },
    errorMsg: `При добавлении доступа возникла ошибка`,
});

const removeRequestSaga = templateRequestSaga({
    action: function*({ value }) {
        if (value == null) {
            return false;
        }
        yield call(removeAccessRequest, value);

        yield put(removeSuccess(value));
    },
    errorMsg: `При добавлении доступа возникла ошибка`,
});

export const saga = function*() {
    yield all([
        takeEvery(FETCH_REQUEST, fetchAccessSaga),
        takeEvery(ADD_REQUEST, addAccessSaga),
        takeEvery(FETCH_ACCESSES_REQUEST, fetchPersonAccessesSaga),
        takeEvery(REVIEW_ACCESS_REQUEST, reviewAccessRequestSaga),
        takeEvery(REMOVE_REQUEST, removeRequestSaga),
    ]);
};
