import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectDictionaries } from 'ducks/Dictionary';
import { allUserDictionaries } from 'ducks/User';
import {
    ISSUE_STATUS,
    EVALUATION_REQUEST_STATUS,
    ACCESS_DIRECTION_TYPE,
    ACCESS_STATUS_DICT,
    SUPPORT_STATUS,
    USER_ROLES,
} from 'constants.js';
import { push } from 'connected-react-router';
import { blockUser } from 'ducks/Users';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { loadDictionaries, loadSupprotDictionaries } from 'ducks/UserActions';
import { getAdminUserInfo, blockUserAccount } from 'api';
import { getUserFullName } from 'utils';
import queryString from 'query-string';
import classnames from 'classnames';
import Page from 'components/common/Page';
import MenuSwitcher from 'components/common/MenuSwitcher';
import UserInfo from './UserInfo';
import UserActions from './UserActions';
import UserAddInfo from './UserAddInfo';
import UserIssues from './UserIssues';
import { mainMenuIds, mainMenuItems } from './constants';
import ModalDialog from 'components/common/ModalDialog';
import { isSecurityAdmin, isITAdmin, isSupportLine1, isSupportLine2 } from 'rightsController';
import { formatDate } from 'utils';
import { NavLink } from 'react-router-dom';
import SmsEmailGate from '../SmsEmailGate';
import './User.scss';

class User extends Component {
    state = {
        data: null,
        activeTab: mainMenuIds.personInfo,
        menu: [],
        modalState: { isOpen: false, header: '', confirm: () => {} },
    };

    header = () => {
        const { id } = this.props;
        const {
            data: { user },
        } = this.state;
        const isLocked = user.isDisabled || user.isLocked;
        const header = getUserFullName(user);
        const subHeader = `Id: ${id}`;

        return (
            <div className="AdminUserInfo__Header">
                <div className="AdminUserInfo__Header--main">
                    <div>{header}</div>
                </div>
                <div className="AdminUserInfo__Header--sub">{subHeader}</div>
                <div className="AdminUserInfo__Status">
                    <div className="AdminUserInfo__StatusLabel">Статус:</div>
                    <div
                        className={classnames('', {
                            'AdminUserInfo__Status--active': !isLocked,
                            'AdminUserInfo__Status--blocked': isLocked,
                        })}
                    >
                        {`${isLocked ? 'Заблокирован' : 'Активный'}`}
                    </div>
                </div>
            </div>
        );
    };

    blockUser = async () => {
        const {
            data: { user },
        } = this.state;

        const { showPageLoader, hidePageLoader, showErrorAlert } = this.props;

        const isBlocking = !user.isDisabled;
        const closeAction = async () => {
            try {
                showPageLoader();
                await blockUserAccount(user.id, !user.isDisabled);
                await this.getUser();
                showSuccessAlert(
                    `Пользователь успешно ${isBlocking ? 'заблокирован' : 'разблокирован'}`,
                );
            } catch (e) {
                showErrorAlert(
                    `Ошибка ${isBlocking ? 'блокировки' : 'разблокировки'} пользователя`,
                );
            } finally {
                this.closeModal();
                hidePageLoader();
            }
        };

        this.setModalState({
            isOpen: true,
            header: `${isBlocking ? 'Заблокировать' : 'Разблокировать'} пользователя?`,
            confirm: closeAction,
        });
    };

    setModalState = (state) => {
        this.setState({ modalState: state });
    };

    closeModal = () => {
        this.setModalState({ isOpen: false, header: '', confirm: () => {} });
    };

    isSupport = () => {
        const { currentUser } = this.props;
        return isSupportLine1(currentUser) || isSupportLine2(currentUser);
    };

    getPropInfo = (value, props, data) => {
        if (!value) {
            return null;
        }
        const { dictionary, isDate, link, isBoolean, onlyDate } = props;
        const { dictionaries } = this.props;

        let formatedValue = value;

        if (dictionary) {
            formatedValue = Array.isArray(formatedValue)
                ? dictionaries[dictionary]
                      ?.filter((x) => formatedValue.find((d) => d === x.id))
                      .map((z) => z.name)
                      .join(', ')
                : dictionaries[dictionary]?.find((x) => x.id === formatedValue)?.name;
        }

        if (isDate) {
            if (onlyDate) {
                formatedValue = formatDate(formatedValue, 'DD-MM-YYYY');
            } else {
                formatedValue = formatDate(formatedValue, 'DD-MM-YYYY kk:mm:ss');
            }
        }

        if (isBoolean) {
            formatedValue = formatedValue ? 'Да' : 'Нет';
        }

        if (link) {
            const paramsObj = link.params.reduce((acc, cur) => {
                return Object.assign(acc, { [cur.key]: data[cur.propId] });
            }, {});

            formatedValue = <NavLink to={link.route.buildUrl(paramsObj)}>{formatedValue}</NavLink>;
        }

        return formatedValue;
    };

    setActiveTab = (section) => {
        const { id, currentUser } = this.props;
        const {
            data: { user, personCard, personExpertCard },
        } = this.state;
        switch (section) {
            case mainMenuIds.personInfo:
                user &&
                    this.setState({
                        activeTab: (
                            <UserInfo
                                user={user}
                                personCard={personCard}
                                personExpertCard={personExpertCard}
                                header={this.header()}
                                currentUser={currentUser}
                                blockUser={this.blockUser}
                                getPropInfo={this.getPropInfo}
                            />
                        ),
                    });
                return;
            case mainMenuIds.actions:
                this.setState({
                    activeTab: (
                        <UserActions
                            userId={id}
                            header={this.header()}
                            currentUser={currentUser}
                            isSupport={this.isSupport()}
                        />
                    ),
                });
                return;
            case mainMenuIds.issues:
                this.setState({
                    activeTab: (
                        <UserIssues
                            userId={id}
                            header={this.header()}
                            currentUser={currentUser}
                            isSupport={this.isSupport()}
                            getPropInfo={this.getPropInfo}
                        />
                    ),
                });
                return;
            case mainMenuIds.messages:
                this.setState({
                    activeTab: <SmsEmailGate userPage userId={id} header={this.header()} />,
                });
                return;
            case mainMenuIds.accessesForMe:
            case mainMenuIds.myAccesses:
            case mainMenuIds.leaders:
            case mainMenuIds.myEvaluations:
            case mainMenuIds.evaluationsForMe:
                this.setState({
                    activeTab: (
                        <UserAddInfo
                            userId={id}
                            header={this.header()}
                            user={user}
                            infoType={section}
                            getPropInfo={this.getPropInfo}
                        />
                    ),
                });
                return;
            default:
                this.setState({ activeTab: <></> });
                return;
        }
    };

    getUser = async () => {
        const { id, showPageLoader, hidePageLoader, showErrorAlert } = this.props;
        try {
            showPageLoader();
            const userInfoData = await getAdminUserInfo(id);
            this.setState({ data: userInfoData.data });
        } catch (e) {
            showErrorAlert('Ошибка загрузки пользователя.');
        } finally {
            hidePageLoader();
        }
    };

    getGroups = (groupId, dictionary) => {
        const group = dictionary.find((x) => x.id === groupId);
        return group ? group.name : null;
    };

    changeUrl = (section) => {
        const { location, push } = this.props;
        push({
            pathname: location.pathname,
            search: queryString.stringify({ section }),
        });
    };

    filterMenu = () => {
        const { currentUser } = this.props;
        const isAdmin = isSecurityAdmin(currentUser) || isITAdmin(currentUser);
        const isSupport = this.isSupport();

        const filteredMenu = mainMenuItems.filter(
            (x) =>
                (isAdmin && x.roles.includes('admin')) ||
                (isSupport && x.roles.includes('support')),
        );

        this.setState({ menu: filteredMenu });
    };

    async componentDidMount() {
        const { location } = this.props;
        const isSupport = this.isSupport();
        const { section } = queryString.parse(location.search);
        this.filterMenu();
        await this.getUser();
        isSupport
            ? await this.props.loadSupprotDictionaries()
            : await this.props.loadDictionaries();
        this.setActiveTab(section || mainMenuIds.personInfo);
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;
        const user = this.state.data?.user;
        const { section } = queryString.parse(location.search);

        if (prevProps.location.search !== location.search) {
            this.setActiveTab(section);
        }

        if (user && JSON.stringify(prevState.data?.user) !== JSON.stringify(user)) {
            this.setActiveTab(section);
        }

        if (user && prevState.data?.user.isDisabled !== user.isDisabled) {
            this.setActiveTab(section);
        }
    }

    render() {
        const { dictionaries } = this.props;
        if (!this.state.data || !dictionaries.roles || !dictionaries.groups) {
            return null;
        }

        const { menu, activeTab, modalState } = this.state;
        const { location } = this.props;
        const { section } = queryString.parse(location.search);

        return (
            <Page>
                <div className="AdminUser">
                    <MenuSwitcher
                        menu={menu}
                        selectedMenuId={section}
                        onMenuClick={this.changeUrl}
                        tabs
                        activeTab={activeTab}
                    />
                    <ModalDialog
                        modalHeader={modalState.header}
                        onClick={modalState.confirm}
                        onCloseModal={this.closeModal}
                        modalOpen={modalState.isOpen}
                    />
                </div>
            </Page>
        );
    }
}

User.propTypes = {
    userId: PropTypes.number,
};

const mapStateToProps = (state) => {
    const dictionaries = selectDictionaries(state.dictionary, allUserDictionaries);
    return {
        currentUser: state.auth.user,
        dictionaries: {
            ...dictionaries,
            issueStatus: ISSUE_STATUS.map((x) => ({ id: x.value, name: x.label })),
            evaluationRequestStatus: EVALUATION_REQUEST_STATUS,
            accessDirectionType: ACCESS_DIRECTION_TYPE,
            accessStatus: ACCESS_STATUS_DICT,
            accessRoles: USER_ROLES.map((x) => ({ id: x.code, name: x.name })),
            supportStatus: SUPPORT_STATUS,
        },
    };
};

const actions = {
    push,
    loadDictionaries,
    loadSupprotDictionaries,
    blockUser,
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(mapStateToProps, actions)(User);
