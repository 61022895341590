import React, { useEffect, useState } from 'react';
import { getCatalog } from "api";
import { REGION_LOCALITIES } from 'ducks/Catalog';
import InfoField from 'components/Lk/Uikit/InfoField';
import { yearMask } from "constants.js";
import WithKeyboardHandler from 'components/Lk/Hooks/WithKeyboardHandler';

const EditPastResidence = props => {

    const { isEdit, onChange, person, districts, regions, currentPastResidence } = props;

    const [localities, setLocalities] = useState([]);

    useEffect(() => {
        setRegionalLocalities();
        // eslint-disable-next-line
    }, [currentPastResidence.id, currentPastResidence.selectedDistrict, currentPastResidence.selectedRegion]);
    

    const setRegionalLocalities = () => {
        const { selectedRegion } = currentPastResidence
        if (selectedRegion) {
            const params = [selectedRegion.id];
            getCatalog(REGION_LOCALITIES, params).then((res) => {
                setLocalities(res.data);
                const selectedLocality = res.data.find((x) => x.id === currentPastResidence.localityId);
                onChangeState('selectedLocality')(selectedLocality);
            }) 
        } else {
            setLocalities([]);
            onChangeState('selectedLocality')(null);
        }
    }

    const onChangeState = name => value => {
        const newPastResidence = { ...currentPastResidence, [name]: value, ...name === 'selectedLocality' && {locality: value} };
        const newPastResidences = person.personalInfo.pastResidences.map(item =>
            item.id === currentPastResidence.id ? newPastResidence : item,
        );
        onChange({ ...person, personalInfo: { ...person.personalInfo, pastResidences: newPastResidences } }, 'PersonalInfoBlock');
    };

    const onChangeYear = name => e => {
        const newPastResidence = { ...currentPastResidence, [name]: e.target.value };
        const newPastResidences = person.personalInfo.pastResidences.map(item =>
            item.id === currentPastResidence.id ? newPastResidence : item,
        );
        onChange({ ...person, personalInfo: { ...person.personalInfo, pastResidences: newPastResidences } }, 'PersonalInfoBlock');
    };

    const onChangeDistrict = value => {
        const newPastResidence = { ...currentPastResidence, selectedDistrict: value, selectedRegion: null, selectedLocality: null, localityId: null };
        const newPastResidences = person.personalInfo.pastResidences.map(item =>
            item.id === currentPastResidence.id ? newPastResidence : item,
        );
        onChange({ ...person, personalInfo: { ...person.personalInfo, pastResidences: newPastResidences } }, 'PersonalInfoBlock');
    };


    const onChangeRegion = value => {
        const district = districts.find(x => x.id === value.federalDistrictId);
        const newPastResidence = { ...currentPastResidence, selectedDistrict: district, selectedRegion: value, selectedLocality: null, localityId: null };
        const newPastResidences = person.personalInfo.pastResidences.map(item =>
            item.id === currentPastResidence.id ? newPastResidence : item,
        );
        onChange({ ...person, personalInfo: { ...person.personalInfo, pastResidences: newPastResidences } }, 'PersonalInfoBlock');
    };

    const { yearFrom, yearTo } = currentPastResidence;
    const currentYear = new Date().getFullYear();

    const isStartYearValid = () => {
        return +yearFrom >= 1895 && +yearFrom <= currentYear;
    };

    const isEndYearValid = () => {    
        return +yearFrom <= +yearTo && +yearTo >= 1895 && +yearTo <= currentYear;
    };

    return (
        <div id='EditPastResidenceWrapper'>
            <InfoField
                value={currentPastResidence.selectedDistrict?.id}
                title="Федеральный округ"
                required
                isEdit={isEdit}
                inputProps={{
                    type: 'select',
                    items: districts,
                    onChange: onChangeDistrict,
                }}
                dataTest="PastResidence_District--select"
                noIcon
            />
            <InfoField
                value={currentPastResidence?.selectedRegion?.id}
                title="Регион"
                required
                isEdit={isEdit}
                inputProps={{
                    type: 'select',
                    items: regions,
                    onChange: onChangeRegion,
                }}
                dataTest="PastResidence_Region--select"
                noIcon
            />
            <InfoField
                value={currentPastResidence?.selectedLocality?.id}
                title="Населённый пункт"
                isEdit={isEdit}
                inputProps={{
                    type: 'select',
                    items: localities,
                    onChange: onChangeState('selectedLocality'),
                }}
                dataTest="PastResidence_Locality--select"
                noIcon
            />            
            <InfoField
                value={currentPastResidence.yearFrom || ''}
                required
                invalid={!isStartYearValid()}
                title="Год начала проживания"
                type="inputMask"
                onChange={onChangeYear('yearFrom')}
                isEdit={isEdit}
                dataTest="PastResidence_StartYear--input"
                noIcon
                mask={yearMask} 
            />
            <InfoField
                value={currentPastResidence.yearTo || ''}
                required
                invalid={!isEndYearValid()}
                title="Год окончания проживания"
                type="inputMask"
                onChange={onChangeYear('yearTo')}
                isEdit={isEdit}
                dataTest="PastResidence_EndYear--input"
                noIcon
                mask={yearMask} 
            />
        </div>
    );
};


export default WithKeyboardHandler(EditPastResidence, 'EditPastResidenceWrapper');