import React, {useState} from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FooterAcceptMenu from 'components/Lk/Common/FooterAcceptMenu';
import classnames from 'classnames';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import { showWarningAlert } from 'ducks/Alert';
import WishlistAttributeSelector from 'components/Lk/Common/Selectors/WishlistAttributeSelector';
import './NewWishlist.scss'

const NewWishlist = props => {
    const { setNewWishlist, setActiveCard, activeCard, createWishList }  = props;
    setActiveCard(activeCard);
    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;

    const [selected, setSelected] = useState('Everyone');
    const [title, setTitle] = useState('');

    const selectItem = id => () => {
        setSelected(id);
    };

    const createNewWishList = () => {
        if (!title.trim() || !selected) {
            !title.trim() && setTitle('');
            return showWarningAlert('Не все поля корректно заполнены.');
        }  
        setNewWishlist(false)();
        createWishList({name: title, visibility: selected});
    };

    return (
        <div className={classnames('DetailsCard', { 'DetailsCard--isMobileActive': isMobileActive })}>

            <div className='LkNewWishlist'>
                <div className='LkNewWishlistHeader' onClick={setNewWishlist(false)}>
                    <div className='LkNewWishlistAction'>
                        <FontAwesomeIcon icon='chevron-left' />
                    </div>
                    <div className='LkNewWishlistTitle'>
                        Создать новую подборку
                    </div>
                </div>

                <div className='LkNewWishlist__Item'>
                    <InfoField 
                        noIcon 
                        isEdit 
                        onChange={e => setTitle(e)} 
                        value={title || ''}  
                        title='Название подборки'
                    />
                </div>
                <div className='LkNewWishlist__Item'>
                    <WishlistAttributeSelector selected={selected} selectItem={selectItem} />
                </div>
            </div>
            <FooterAcceptMenu 
                alwaysOpen
                show
                onCancel={setNewWishlist(false)} 
                handleAcceptClick={createNewWishList}
                okText={'Создать и добавить'}
            />
        </div>
    );
};

export default NewWishlist;