import React from 'react';
import { connect } from 'react-redux';
import InfoField from 'components/Lk/Uikit/InfoField';
import classnames from 'classnames';
import RolesAccess from './RolesAccess';
import { getUserFullName } from 'utils';

const SystemInfo = props => {
    const { person, isEdit, onChange } = props;

    const {
        systemInfo: { groups, group, roles, selectedPersonGroupIds },
        personnelInfo,
        personalInfo: { id: personId },
    } = person;

    const curatorSelected = personnelInfo.curatorLocal;

    const curatorFullName = !curatorSelected
        ? null
        : curatorSelected && curatorSelected.fullName
        ? curatorSelected.fullName
        : curatorSelected.lastName
        ? getUserFullName(curatorSelected)
        : null;

    const curatorInner = curatorSelected
        ? { label: curatorFullName, value: { id: curatorSelected.id, fullName: curatorFullName } }
        : null;

    const onChangeGroup = value => {

        if (!value) {
            onChange(
                { ...person, systemInfo: { ...person.systemInfo, group: null, selectedPersonGroupIds: null } },
                'SystemInfoBlock',
            );
            return;
        }

        let selectedCommonGroups = value.filter(x => !x.isScope);

        if (selectedCommonGroups.length > 1) {
            selectedCommonGroups = selectedCommonGroups.filter(x => x.id !== group);
        }

        const selectedPersonGroupIds = value.filter(x => x.isScope).map(x => x.id);
        
        onChange(
            { ...person, systemInfo: { ...person.systemInfo, group: selectedCommonGroups.length === 1 ? selectedCommonGroups[0].id : null, selectedPersonGroupIds } },
            'SystemInfoBlock',
        );
    };

    const onChangeState = name => value => {
        onChange(
            {
                ...person,
                personnelInfo: {
                    ...person.personnelInfo,
                    [name]: value ? value.id : value,
                    curatorLocal: value,
                },
            },
            'CuratorBlock',
        );
    };

    const getSelectedGroups = () => {
        const selectedGroups = selectedPersonGroupIds ? groups.filter(g => g.id === group || (g.isScope && selectedPersonGroupIds.includes(g.id))) : [];
        
        const isExistGroups = groups && groups.length > 0;
        
        return isExistGroups && selectedGroups.map(x => x.isScope ? `${x.name} (Транзитная)` : x.name).join(', ');
    };

    const selectedGroup = groups?.find(x => x.id === group);
    const personGroupIds = selectedPersonGroupIds ? groups?.filter(x => selectedPersonGroupIds.includes(x.id)) : [];
    
    const selectedGroups = [...personGroupIds, selectedGroup];
    
    const colourStyles = {
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.isScope
                    ? '#9dc7f7'
                    : 'hsl(0,0%,90%)',
            };
        },
        option: (styles, { data }) => {
            return {
                ...styles,
                color: data.isScope
                    ? '#007aff'
                    : 'black'
            };
        },
    };

    return (
        <div className={classnames('PersonalInfo', { 'PersonalInfo--bottom-margin': isEdit })}>
            <InfoField
                value={selectedGroups}
                title="Группы"
                isEdit={isEdit}
                invalid={!selectedGroup}
                inputProps={{
                    type: 'select',
                    items: groups,
                    onChange: onChangeGroup,
                    isMulti: true,
                    styles: colourStyles
                }}
                noIcon
            >
                {getSelectedGroups()}
            </InfoField>
            <RolesAccess data={roles} personId={personId} />
            
            <InfoField
                value={curatorFullName}
                title="Эксперт по управлению талантами"
                isEdit={isEdit}
                inputProps={{
                    type: 'userSearchSelect',
                    value: curatorInner ? curatorInner : null,
                    onChange: e => {
                        onChangeState('curatorId')(e ? e.value : e);
                    },
                }}
                dataTest="PersonnelInfo_Curator--text"
                noIcon
            />
        </div>
    );
};

export default connect()(SystemInfo);
