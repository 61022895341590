import React, { Component } from 'react';
import Sticker from 'react-stickyfill';
import throttle from 'lodash.throttle';
import Menu from '../Menu';
import { REPORT_HEADER_HEIGHT_PX } from 'components/common/PersonReportHeader/constants.js';
import './FloatMenu.scss';

// Определяем позицию скролла в виде десятичной дроби
// 0.0 - скролл вверху, 1.0 - скролл внизу
const calculateScrollPos = () => {
    const maxScrollY = document.body.scrollHeight - window.innerHeight;
    return maxScrollY > 0 ? window.pageYOffset / maxScrollY : 0;
};

class FloatMenu extends Component {
    state = {
        currentIndex: null,
        scrollPos: null,
    };

    constructor(props) {
        super(props);

        this.preventHandleScroll = false;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const { items, onClear, ...tail } = this.props;
        const { currentIndex } = this.state;

        return (
            <div className="FloatMenu">
                <Sticker>
                    <div className="FloatMenu__Container">
                        <Menu
                            items={items.map((item, index) => ({ ...item, key: index }))}
                            activeItemKey={currentIndex || 0}
                            onActiveItemChanged={this.handleActiveItemChanged}
                            onClear={onClear}
                            {...tail}
                        />
                    </div>
                </Sticker>
            </div>
        );
    }

    handleScroll = throttle(() => {
        try {
            // Не вычислять текущий блок, если пользователь кликнул на пункт меню, а не проскроллил
            if (this.preventHandleScroll === true) {
                return;
            }

            // Хендлер может выполниться после перехода на другую страницу.
            // В таком случае, this.props.refs не будут ссылаться ни на какие элементы.
            // Поэтому нужно сначала проверить, нет ли отсоедененных ref'ов

            const { items } = this.props;

            const scrollPos = calculateScrollPos();
            this.setState({ scrollPos });

            // Находим блок, пересекающийся с линией
            const entries =
                this.props.refs &&
                Object.entries(this.props.refs)
                    .filter(x => x[1].current != null && items.find(item => item.anchor === x[0]))
                    .map((entry, index) => ({
                        menuItem: items.find(item => item.anchor === entry[0]),
                        topYCoord: entry[1].current?.getBoundingClientRect().top,
                        botYCoord: entry[1].current?.getBoundingClientRect().bottom,
                        origIndex: index,
                    }));

            const item = entries.find(
                entry =>
                    (entry.topYCoord - REPORT_HEADER_HEIGHT_PX >= 0 ||
                    entry.botYCoord - REPORT_HEADER_HEIGHT_PX >= 0 ),
            );

            if (item) {
                this.setState({ currentIndex: item.origIndex });
            }
        } catch (e) {
            console.error(e);
        }
    }, 100);

    handleActiveItemChanged = item => {
        const ref = this.props.refs[item.anchor];

        if (ref) {
            this.preventHandleScroll = true;
            setTimeout(() => {
                this.preventHandleScroll = false;
            }, 100);
            if (ref.current) {
                ref.current.scrollIntoView({
                    behaviour: 'smooth',
                    block: 'start',
                    inline: 'center',
                });

                window.scrollBy(0, -27 - REPORT_HEADER_HEIGHT_PX);
            }
        }

        this.setState({
            currentIndex: item.key,
            scrollPos: calculateScrollPos(),
        });
    };
}

export default FloatMenu;
