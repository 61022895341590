import React, { Component } from 'react';
import "./Reports.scss";
import Select from 'components/uikit/Select';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import CheckField from 'components/uikit/CheckField';
import { Droppable, Draggable } from 'react-beautiful-dnd'; 
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from "components/uikit/Button";
import uuid from "uuid/v4";

class Report extends Component {

    render() {
        const { reportSections, isDefault } = this.props.report;
        const { title } = this.props.report.reportSettings;
        const reportSectionsKeys = Object.keys(reportSections);
        
        return (
            <div className="ReportParams">
                <div className="ReportParams__Header">Отчет</div>
                <div className="ReportParams__Line">
                <CheckField
                    id="isDefault"
                    title="Основной отчет"
                    checked={isDefault || false}
                    onChange={checked => this.props.toggleDefaultReport(checked)}
                    modifier="ReportParams"
                />                    
                </div>
                <div className="ReportParams__Line">
                    <div className="Section">
                        <Field className="kr_input_with_text" required filled={title.trim()}>
                            <Label>Название отчета</Label>
                            <Input
                                value={title}
                                onChange={e => this.changeReportName(e.target.value)}
                                maxLength={250}
                            ></Input>
                        </Field>
                    </div>
                </div>
                {reportSections && this.renderReportSections(reportSections, reportSectionsKeys)}
                <div className="ReportParams__Buttons">
                    <Button onClick={this.saveReport} color="primary" disabled={!title.trim()} >Сохранить</Button>
                </div>
            </div>
        )
    }

    EditButton = (props) => {
        const { onClick } = props;
    
        return (
            <button
                type="button"
                className="kr_icon_edit"
                onClick={onClick}
            />
        );
    }

    changeReportName = (value) => {
        this.props.handleStateChange('title', value);
    }

    makeFinalJSON = () => {
        const { font = "Times New Roman", format = "a4",  title, reportSections, reportSettings, reportType, reportId, isDefault } = this.props.report;
        return {
            id: reportId || uuid(),
            base: reportType,
            name: reportSettings.title,
            isDefault: isDefault,
            config: JSON.stringify(
                {
                    title: title,
                    font: font, 
                    format: format,
                    schema: {
                        ...reportSections,
                    },
                    settings: {
                        ...reportSettings,                 
                    }
                }
            ) 
        }
    }

    saveReport = () => {
        const finalJSON = this.makeFinalJSON();
        this.props.saveReport(finalJSON);
    }

    renderReportSections = (reportSections, keys) => {
        const { reportSettings } = this.props.report;
        return keys.map(key => 
            (this.getSectionOpenStatus(key) && reportSections[key] && reportSections[key].name &&
                <Droppable 
                    droppableId={key} 
                    key={key}
                    type={key}
                >
                    {(provided, snapshot) => (
                        <div 
                            className={classnames({"ReportParams__Line": true, "ReportParams__Line--Dragging": snapshot.isDraggingOver})}
                            key={key}
                            {...provided.droppableProps}
                            ref={provided.innerRef}                            
                        >
                            <div className="Section">
                                <div className="Section__Header-Settings">
                                    <Field className="kr_input_with_text">
                                        <Input
                                            value={reportSettings[key].title}
                                            onChange={e => this.props.handlSectionNameChange(key, 'title', e.target.value)}
                                        ></Input>
                                    </Field>
                                </div>                          
                            </div>
                            {this.getSectionOpenStatus(key) && this.renderReportSectionFields(key)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            )
        );
    }

    renderReportSectionFields = (sectionKey) => {
        const { reportSettings } = this.props.report;
        return reportSettings[sectionKey].selectedFields && reportSettings[sectionKey].selectedFields.map((x, index) => {
            const field = this.getFieldParams(sectionKey, x.code);
            if (field.type === "simple") {
                return field &&
                <Draggable draggableId={x.code} index={index} key={x.code}>
                    {(provided, snapshot) => (
                        <div 
                            className={classnames({"Param": true, "Param__Dragging": snapshot.isDragging})}
                            key={x.code + index}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            <div className="Param__Label">
                                {field.name}
                            </div>
                            <div className="Param__Icon" >
                                <FontAwesomeIcon icon="arrows-alt-v"/>
                            </div>
                        </div>                        
                    )}
                </Draggable>
            } else {
                const options = this.props.getAllOptions(sectionKey, x.code);
                return field && 
                <Draggable draggableId={x.code} index={index} key={x.code}>
                    {(provided, snapshot) => (
                    <div 
                        className={classnames({"Param": true, "Param__Dragging": snapshot.isDragging})}
                        key={x.code + index}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                    >
                        {this.renderMultiSelect(sectionKey, x.code, field.name, options, provided)}
                    </div>
                    )}
                </Draggable>
            }
        });
    }
    
    getSectionOpenStatus = (sectionKey) => {
        const { reportSettings } = this.props.report;
        return reportSettings[sectionKey] && reportSettings[sectionKey].enabled;
    }

    isFieldSelected = (sectionKey, fieldCode) => {
        const { reportSettings } = this.props.report;
        const selectedFields = reportSettings[sectionKey].selectedFields;
        return selectedFields && selectedFields.find(x => x.code === fieldCode);
    }

    getFieldParams = (sectionKey, fieldCode) => {
        const { reportSections } = this.props.report;
        return reportSections[sectionKey].fields.find(x => x.code === fieldCode);
    }
    
    renderMultiSelect = (sectionKey, fieldCode, fieldName, fields, provided) => {
        const selectedOptionsArray = this.props.getSelectedOptions(sectionKey, fieldCode);
        const selectedOptions = selectedOptionsArray.flatMap(option => fields.filter(x => x.code === option));
        return (
            <Field 
                className="kr_input_with_text_attribute"
            >
                <Label {...provided.dragHandleProps}>
                    <div className="Param-Label-Text">
                        {fieldName}
                    </div>
                    <div className="Param-Label-Icon">
                        <FontAwesomeIcon icon="arrows-alt-v"/>
                    </div>
                </Label>
                
                <Select
                    value={selectedOptions.map(x => ({id: x.code, name: x.name}))}
                    options={fields.map(x => ({id: x.code, name: x.name}))}
                    catalog
                    isMulti
                    onChange={item => this.props.handleOptionChange(sectionKey, fieldCode, item)}
                />             
            </Field>    
        )        
    }
}

export default Report;
