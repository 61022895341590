import React, { useState, useEffect } from 'react';
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { showSuccessAlert, showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { personGrowthResourceRoute, testEditRoute } from 'routes';
import { getTestsForPersonGrowthTag, getResourcesForPersonGrowthTag, getTestProvidersActive } from 'api';
import { testStatuses, testGroups } from 'constants.js';
import Page from "components/common/Page";
import ListTemplate from 'components/uikit/ListTemplate'
import DataGrid from 'components/common/DataGrid';
import Button from 'components/uikit/Button';

import './PersonGrowthTagDeps.scss';

const initialTagDepsData = {
    payload: [],
    meta: { foundCount: 0, pageCount: 0 },
    loaded: false,
};

const initialCriteria = {
    filter: { title: '', },
    sorting: { title: 'asc' },
    paging: { pageNum: 1, pageSize: 10 },
};

const initialSorted = [
    { id: 'title', desc: false }
];

const PersonGrowthTagDeps = (props) => {

    const { tagId } = props;

    const [isTestsView, setIsTestsView] = useState(true);
    const [tagDepsData, setTagDepsData] = useState(initialTagDepsData);
    const [criteria, setCriteria ] = useState(initialCriteria);
    const [sorted, setSorted] = useState(initialSorted);
    const [testProviders, setTestProviders] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let mount = true;

        const fetchData = async () => {
            try {
                showPageLoader();
                
                setLoading(true);
                const tagDepsFetched = isTestsView
                    ? await getTestsForPersonGrowthTag(tagId, criteria)
                    : await getResourcesForPersonGrowthTag(tagId, criteria);

                if (mount && tagDepsFetched && tagDepsFetched.data) {
                    const tagDepsData = tagDepsFetched.data;
                    setTagDepsData({...tagDepsData, loaded: true});
                }
            } catch(error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
                setLoading(false);
            }
        };
        fetchData();

        return () => { mount = false };
    }, [tagId, isTestsView, criteria]);

    
    useEffect(() => {
        let mount = true;

        const fetchData = async () => {
            try {
                
                const testProvidersData = await getTestProvidersActive();
                
                if (mount && testProvidersData && testProvidersData.data && Array.isArray(testProvidersData.data)) {
                    setTestProviders(testProvidersData.data);
                }
                
            } catch(error) {
                showErrorAlert(error.message);
            }
        };
        fetchData();

        return () => { mount = false };
    }, []);
    

    const tagTestsColumns = [
        {
            Header: 'Название',
            accessor: 'title',
            resizable: false,
            sortable: true,
        },
        {
            Header: 'Статус',
            accessor: 'status',
            resizable: false,
            sortable: false,
            Cell: ({ original }) => { 
                const status = testStatuses.find(x => x.code === original.status);
                return (status && status.name) || "";
            }
        },
        {
            Header: 'Поставщик теста',
            accessor: 'testProviderId',
            resizable: false,
            sortable: true,
            Cell: ({ original }) => {
                const testProvider = testProviders.find(x => x.id === original.testProviderId);
                return (!!testProvider && testProvider.title) || "";
            }
        },
        {
            Header: 'Группа тестов',
            accessor: 'testGroups',
            resizable: false,
            sortable: true,
            Cell: ({ original }) => {
                const testGroup = testGroups.find(x => x.code === original.kind);
                return (testGroup && testGroup.name) || "";
            }
        },
        {
            Header: 'Действие',
            resizable: false,
            sortable: false,
            maxWidth: 185,
            Cell: ({ original }) =>
                <Button size='sm' onClick={() => props.push(testEditRoute.buildUrl({ id: original.id }))} >
                    Редактировать
                </Button>
        }
    ];

    const tagResourcesColumns = [
        {
            Header: 'Название',
            accessor: 'name',
            resizable: false,
            sortable: true,
            Cell: ({ original }) =>
                <NavLink to={personGrowthResourceRoute.buildUrl({ id: original.id, regime: "view" })}>
                    {original.name}
                </NavLink>,
            
        },
        {
            Header: 'Тип',
            accessor: 'type',
            sortable: false,
            resizable: false,
        },
        {
            Header: 'Ссылка',
            accessor: 'link',
            minWidth: 300,
            sortable: false,
            resizable: false,
            Cell: ({ original }) => 
                <a href={original.link} rel="noopener noreferrer" target="_blank" >
                    {original.link}
                </a>
        },
        {
            Header: 'Действие',
            resizable: false,
            sortable: false,
            maxWidth: 190,
            Cell: ({ original }) =>
                <Button
                    size='sm'
                    onClick={() => editPersonGrowthResource(original.id)}
                >
                    Редактировать
                </Button>
        }
    ];

    const editPersonGrowthResource = (id) => {
        props.push(personGrowthResourceRoute.buildUrl({ id, regime: "edit" }));
    };

    const onPageChange = (pageNum) => {
        setCriteria({...criteria, paging: { ...criteria.paging, pageNum: pageNum + 1 }});
    };

    const onSortedChange = (sorted) => {
        setSorted(sorted);
        setCriteria({...criteria, sorting: {...criteria.sorting,  [sorted[0].id]: sorted[0].desc ? "desc" : "asc" }});
    };

    const handleTagTestsChange = () => {
        setIsTestsView(true);
        setCriteria({
            filter: { title: '', },
            sorting: { title: 'asc' },
            paging: { pageNum: 1, pageSize: 10 }
        });
    };
    
    const handleTagResourcesChange = () => {
        setIsTestsView(false);
        setCriteria({
            filter: { name: '', },
            sorting: { name: 'asc' },
            paging: { pageNum: 1, pageSize: 10 }
        });
    };

    const changePageAmount = (pageSize) => {
        setCriteria({
            ...criteria,
            paging: { pageNum: 1, pageSize }
        });
    };

    return (
        <Page className="PersonGrowthTagDeps">
            <div>
                <Button size="sm" type="button" onClick={handleTagTestsChange}>
                    Тесты
                </Button>
                <Button className="PersonGrowthTagDeps__ResourcesButton" size="sm" type="button" onClick={handleTagResourcesChange}>
                    Инструменты развития
                </Button>
            </div>
            <ListTemplate title={isTestsView ? "Тесты" : "Инструменты развития" }>
                <DataGrid
                    data={(tagDepsData && tagDepsData.payload) || []}
                    columns={isTestsView ? tagTestsColumns : tagResourcesColumns}
                    loading={loading}
                    showPagination={true}
                    showPageSizeOptions={false}
                    sorted={sorted}
                    onSortedChange={onSortedChange}
                    foundCount={tagDepsData && tagDepsData.meta && tagDepsData.meta.foundCount}
                    pages={tagDepsData && tagDepsData.meta && tagDepsData.meta.pageCount}
                    page={criteria.paging.pageNum}
                    onPageChange={onPageChange}
                    manual
                    pageSize={criteria.paging.pageSize}
                    changePageAmount={changePageAmount}
                />
            </ListTemplate>
        </Page>
    );
};

const actions = {showPageLoader, hidePageLoader, showSuccessAlert, showErrorAlert, showWarningAlert, push};

export default connect(null, actions)(PersonGrowthTagDeps);