import React, { useState } from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import EditPastResidence from './EditPastResidence';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import classnames from 'classnames';

const PastResidences = props => {

    const { isEdit, data, onChange, person, setWrap, isWrap } = props;
    
    const [currentId, setCurrentId] = useState(null);
    const [currentPastResidence = {}] = data.filter(item => item.id === currentId);

    const selectPastResidence = id => () => {
        setCurrentId(id);
        setWrap(true);
    };

    const onAdd = () => {
        const id = -new Date().getTime();
        const newPastResidence = { id };
        onChange(
            { ...person, personalInfo: { ...person.personalInfo, pastResidences: [...person.personalInfo.pastResidences, newPastResidence] } },
            'PersonalInfoBlock',
        );
    };

    const onRemove = id => e => {
        e.stopPropagation();
        onChange(
            { ...person, personalInfo: { ...person.personalInfo, pastResidences: data.filter(x => x.id !== id) } },
            'PersonalInfoBlock',
        );
    };

    const ViewPastResidence = ({item}) => {
        return (
            <>
                <InfoField 
                    icon={
                        isEdit && {
                            icon: 'minus-circle',
                            size: 'xs',
                            color: 'red',
                            onClick: onRemove(item.id),
                        }
                    }
                    onWrap={isEdit ? selectPastResidence(item.id) : undefined}
                    dataTest="PastResidence_SelectItem--button"
                    noIcon={!isEdit}
                >
                    {item.selectedDistrict && 
                        <div> 
                            <span className="InfoField__Title">Федеральный округ: </span> 
                            <span className="PersonalInfo__SmallValue">{item.selectedDistrict.label}</span>
                        </div>
                    }
                    {item.selectedRegion && 
                        <div> 
                            <span className="InfoField__Title">Регион: </span> 
                            <span className="PersonalInfo__SmallValue">{item.selectedRegion.label}</span>
                        </div>
                    }
                    {item.locality && 
                        <div> 
                            <span className="InfoField__Title">Населённый пункт: </span> 
                            <span className="PersonalInfo__SmallValue">{item.locality.fullName || item.locality.name}</span>
                        </div>
                    }
                    {item.yearFrom && item.yearTo &&
                        <div> 
                            <span className="InfoField__Title">Период проживания: </span> 
                            <span className="PersonalInfo__SmallValue">{item.yearFrom} - {item.yearTo}</span>
                        </div>
                    }
                    {!item.selectedDistrict && !item.selectedRegion && !item.yearFrom && !item.yearTo && '–'}
                </InfoField>
            </>
        );
    };

    return (
        <>
            {(data?.length !== 0 || isEdit) &&
                <div className={classnames('LkPastResidences', {'LkPastResidences--edit': isEdit})}>
                    Проживание в субъектах РФ
                </div>
            }  
            {isWrap && currentId
                ?
                    <EditPastResidence {...props} currentPastResidence={currentPastResidence} />
                :
                    <>
                        {data.map(x => (
                            <ViewPastResidence item={x} key={x.id} />
                        ))}
                        {isEdit && (
                            <InfoField noIcon>
                                <ProfileButton onClick={onAdd} dataTest="PastResidence_AddPastResidence--button" >добавить</ProfileButton>
                            </InfoField>
                        )}
                    </>
            }
        </>
    );
};

export default PastResidences;