import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { push as pushLocation } from 'connected-react-router';
import { parse as queryStringParse, stringify } from 'query-string';
import DataGrid from 'components/common/DataGrid';
import ListTemplate from 'components/uikit/ListTemplate/ListTemplate';
import { getSentMassEmailCampaigns } from 'api';
import { massEmailLists, massEmailCampaign } from 'routes';
import { NavLink } from 'react-router-dom';
import { formatDate, startOf, endOf } from 'utils';
import { CAMPAIGN_STATUS } from 'constants.js';

const initialFilter = { subject: '', sendOnFrom: null, sendOnTo: null, status: [CAMPAIGN_STATUS.Sent.value] };
class History extends Component {
    state = {
        payload: {
            data: [],
            meta: {
                foundCount: 0,
                pageCount: 0,
            },
        },
        loading: false,
        sorting: {
            sendOn: 'desc',
        },
        filter: initialFilter,
        pageSize: 10,
    };

    onFilterChange = (value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                ...value,
            },
        });
    };

    onFilterSubmit = () => {
        const urlParams = {
            ...this.props.queryParams,
            page: 1,
        };
        if (+this.props.queryParams.page === 1) {
            this.fetchData();
        } else {
            this.props.pushLocation({ pathname: massEmailLists.url, search: stringify(urlParams) });
        }
    };

    handleEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            this.onFilterSubmit();
        }
    };

    onClearFilters = () => {
        this.setState({ filter: initialFilter }, () => this.fetchData());
        const urlParams = {
            ...this.props.queryParams,
            page: 1,
        };
        if (+this.props.queryParams.page === 1) {
            this.fetchData();
        } else {
            this.props.pushLocation({ pathname: massEmailLists.url, search: stringify(urlParams) });
        }
    };

    filterForm = () => ({
        title: 'Фильтры',
        line: [
            {
                type: 'Input',
                label: 'Поиск по теме',
                id: 'subject',
                name: 'subject',
                value: this.state.filter.subject,
                onChange: (e) => this.onFilterChange({ subject: e.target.value }),
                onKeyDown: this.handleEnterKeyPressed,
            },
            {
                type: 'DatePicker',
                label: 'Дата рассылки с',
                id: 'sendOnFrom',
                name: 'sendOnFrom',
                selected: this.state.filter.sendOnFrom,
                onChange: (value) =>
                    this.onFilterChange({ sendOnFrom: new Date(startOf(value, 'day').format()) }),
            },
            {
                type: 'DatePicker',
                label: 'Дата рассылки по',
                id: 'sendOnTo',
                name: 'sendOnTo',
                selected: this.state.filter.sendOnTo,
                onChange: (value) =>
                    this.onFilterChange({ sendOnTo: new Date(endOf(value, 'day').format()) }),
            },
        ],
        btnOnClick: this.onFilterSubmit,
        btnClearOnClick: this.onClearFilters,
    });

    gridColumns = [
        {
            Header: 'Тема',
            resizable: false,
            sortable: true,
            accessor: 'subject',
            Cell: ({ original }) => (
                <NavLink to={massEmailCampaign.buildUrl({ id: original.id })}>
                    {original.subject}
                </NavLink>
            ),
        },

        {
            Header: 'Статус',
            resizable: false,
            accessor: 'status',
            sortable: true,
            maxWidth: 200,
            Cell: ({ original }) => CAMPAIGN_STATUS[original.status].name,
        },

        {
            Header: 'Дата рассылки',
            resizable: false,
            accessor: 'sendOn',
            sortable: true,
            maxWidth: 200,
            Cell: ({ original }) =>
                original.sendOn ? formatDate(original.sendOn, 'YYYY-MM-DD HH:mm') : '',
        },
        { width: 1 },
    ];

    fetchData = async () => {
        const { sorting, filter, pageSize } = this.state;
        const { showPageLoader, hidePageLoader, queryParams } = this.props;
        try {
            const criteria = {
                paging: { pageNum: queryParams.page, pageSize },
                sorting,
                filter,
            };
            this.setState({ loading: true });
            showPageLoader();
            const historyData = await getSentMassEmailCampaigns(criteria);
            this.setState({
                payload: {
                    data: [...historyData.data.payload],
                    meta: { ...historyData.data.meta },
                },
            });
        } catch (e) {
            this.props.showErrorAlert('Ошибка получения истории рассылок');
        } finally {
            this.setState({ loading: false });
            hidePageLoader();
        }
    };

    onPageChange = (pageIndex) => {
        const urlParams = {
            ...this.props.queryParams,
            page: pageIndex + 1,
        };
        this.props.pushLocation({ pathname: massEmailLists.url, search: stringify(urlParams) });
    };

    changePageAmount = (pageSize) => {
        this.setState({ pageSize });
        const urlParams = {
            ...this.props.queryParams,
            page: 1,
        };
        this.props.pushLocation({ pathname: massEmailLists.url, search: stringify(urlParams) });
    };

    onSortedChange = (sorted) => {
        this.setState({
            sorting: { [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        });
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { payload, sorting, pageSize } = this.state;
        const { queryParams } = this.props;

        if (
            prevProps.queryParams.page !== queryParams.page ||
            JSON.stringify(prevState.sorting) !== JSON.stringify(sorting) ||
            prevState.pageSize !== pageSize
        ) {
            this.fetchData();
        }

        if (JSON.stringify(prevState.payload.data) !== JSON.stringify(payload.data)) {
            if (+queryParams.page > payload.meta.pageCount) {
                const urlParams = {
                    ...queryParams,
                    page: payload.meta.pageCount,
                };
                this.props.pushLocation({
                    pathname: massEmailLists.url,
                    search: stringify(urlParams),
                });
            }
        }
    }

    render() {
        const { payload, loading, pageSize } = this.state;
        const { queryParams } = this.props;
        return (
            (payload.data.length !== 0 || !loading) && (
                <ListTemplate title={this.props.menu} form={this.filterForm()}>
                    <DataGrid
                        data={payload.data}
                        foundCount={payload.meta.foundCount}
                        columns={this.gridColumns}
                        showPagination={true}
                        showPageSizeOptions={false}
                        sorted={this.state.sorted}
                        onSortedChange={this.onSortedChange}
                        pages={payload.meta.pageCount}
                        page={+queryParams.page || 1}
                        onPageChange={this.onPageChange}
                        manual
                        pageSize={pageSize}
                        changePageAmount={this.changePageAmount}
                    />
                </ListTemplate>
            )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryParams: queryStringParse(state.router.location.search),
    };
};

const actions = { showErrorAlert, pushLocation, showPageLoader, hidePageLoader };

export default connect(mapStateToProps, actions)(History);
