import React from 'react';

import {Select} from 'components/uikit/Select';
import { Row, Col } from 'react-flexbox-grid';
import classnames from 'classnames';

const CriteriaItem = props => {
    const { item, criterias } = props;

    const onChange = value => {
        props.onChange &&
            props.onChange({
                ...item,
                evaluationTestCriteria: value,
                evaluationTestCriteriaId: value?.id,
            });
    };
    return (
        <Row className={classnames('TeamReportContainer__Item')}>
            <Col xs={6} className="TeamReportLabels">
                {item.title}
            </Col>
            <Col xs={6}>
                <Select
                    options={criterias}
                    isClearable
                    value={item.evaluationTestCriteria}
                    onChange={onChange}
                    getOptionLabel={x => x.title}
                    getOptionValue={x => x.id}
                    placeholder="Выберите критерии"
                />
            </Col>
        </Row>
    );
};

export default CriteriaItem;
