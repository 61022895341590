import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import uuid from "uuid/v4";
import Button from 'components/uikit/Button';
import { getPersonFileUrl, uploadPersonFile, deletePersonFile } from "ducks/Person";
import { MAX_BYTES_PER_FILE, MAX_UPLOAD_FILES } from 'constants.js';
import { getFileSize } from 'utils';
import { isUploadFilesCountValid, isUploadFilesSizeValid } from 'libs/validators';

const DropzoneDecorator = (Container, FileList, FileItem) => class extends Component {
    static propTypes = {
        personId: PropTypes.number,
        files: PropTypes.arrayOf(PropTypes.shape({
            localKey: PropTypes.string.isRequired,
            id: PropTypes.string,
            name: PropTypes.string.isRequired,
            size: PropTypes.number.isRequired,
            progress: PropTypes.number,
            uploadComplete: PropTypes.bool,
            error: PropTypes.string,
        })).isRequired,
        onUpload: PropTypes.func,
        onFileChanged: PropTypes.func,
        onFileRemoved: PropTypes.func,
        onFileRejected: PropTypes.func,
        onUploadError: PropTypes.func,
        accept: PropTypes.string,
        downloadFilesInNewTab: PropTypes.bool,
        className: PropTypes.string,
    }

    static defaultProps = {
        accept: '',
        onUpload: x => x,
        onFileChanged: x => x,
        onFileRemoved: x => x,
    }

    fileChanged = (fileId, changes) => {
        const file = this.getFile(fileId);
        if (file) {
            this.props.onFileChanged({ ...file, ...changes });
        }
    }

    onProgress(fileId, e) {
        const file = this.getFile(fileId);
        if (!file) {
            return;
        }

        const { progress: previousProgress = 0 } = file;
        const currentProgress = e.loaded / e.total * 100;

        // Чтобы событие не срабатывало слишком часто и не тормозило браузер
        if (currentProgress === 100 || currentProgress - previousProgress > 10) {
            this.props.onFileChanged({ ...file, ...{ progress: currentProgress } });
        }
    }

    getFile = fileId => {
        return this.props.files.find(f => fileId === f.localKey || fileId === f.id);
    }

    onDrop = async files => {
        if (!isUploadFilesCountValid(files.length, this.props.files.filter(f => f.isNew).length, MAX_UPLOAD_FILES)) {
            return this.props.onUploadError({ msg: `Максимальное количество файлов для загрузки: ${MAX_UPLOAD_FILES}` });
        }

        if (!isUploadFilesSizeValid(files, MAX_BYTES_PER_FILE)) {
            return this.props.onUploadError({ msg: `Размер одного файла не должен превышать ${getFileSize(MAX_BYTES_PER_FILE)}` });
        }

        let filesToUpload = [];
        for (let file of files) {
            const fileInfo = {
                id: uuid(),
                localKey: uuid(),
                name: file.name,
                mimeType: file.type,
                size: file.size,
                isNew:true,
                uploadComplete: false,
                file
            };
            filesToUpload.push(fileInfo);

            !this.props.local && this.uploadFile(file, fileInfo.localKey);
        }
        this.props.onUpload(filesToUpload);
    }

    uploadFile = async (file, fileLocalKey) => {
        try {
            const response = await uploadPersonFile(this.props.personId)(file, e => this.onProgress(fileLocalKey, e));

            let fileInfoChanges = {
                localKey: fileLocalKey,
                id: response.data.id,
                name: response.data.name,
                mimeType: response.data.mimeType,
                size: response.data.size,
                uploadComplete: true,
            };

            this.fileChanged(fileLocalKey, fileInfoChanges);
        } catch (error) {
            this.fileChanged(fileLocalKey, { uploadComplete: true, error: 'Ошибка' });
        }
    }

    removeFile = async (e, file) => {
        e.preventDefault();
        if(this.props.local){
            this.props.onFileRemoved(file);
            return
        }
        try {
            if (file.id) {
                await deletePersonFile(this.props.personId)(file.id);
                this.props.onFileRemoved(file);
            }
        } catch (error) {
            this.fileChanged(file.localKey, { uploadComplete: true, error: 'Ошибка' });
        }
    }

    renderFiles = () => {
        const { personId, files } = this.props;

        return (
            <FileList>
                {files.map((file, index) =>
                    <FileItem
                        key={index}
                        file={file}
                        getFileUrl={getPersonFileUrl(personId)}
                        remove={this.removeFile}
                    />
                )}
            </FileList>
        );
    }

    renderFooter = (open, isDragAccept) => {
        const message = isDragAccept ? 'Положите файл' : 'Перетащите файлы сюда или';

        return (
            <footer>
                <span>{message}</span>
                <Button color="default" onClick={open} type="button">
                    Выберите файлы*
                </Button>
                <div>
                <small>* Размер файла не должен превышать 100 Мб</small>
                </div>
                
            </footer>
        );
    }

    render() {
        const { className } = this.props;
        return (
            <ReactDropzone
                onDrop={this.onDrop}
                onDropRejected={this.props.onFileRejected}
                onClick={evt => evt.preventDefault()}
                accept={this.props.accept}
            >
                {(props) => {
                    const { getInputProps, isDragAccept, open } = props;

                    return (
                        <Container {...props} className={className}>
                            <input {...getInputProps()} />
                            {this.renderFiles()}
                            {this.renderFooter(open, isDragAccept)}
                        </Container>
                    );
                }}
            </ReactDropzone>
        );
    }
};

export default DropzoneDecorator;