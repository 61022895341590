import React from 'react';
import classnames from 'classnames';

export default function Option(props) {
    const { innerProps, children, isFocused, className, isSelected, dataTest = '' } = props;
    const altClassName = props.data.className;
    const finalClass = classnames(
        'react-select__option',
        'Select__Option--item',
        className,
        altClassName,
        {
            'react-select__option--is-focused': isFocused,
        },
    );
    return (
        <div
            {...innerProps}
            title={props.label}
            className={finalClass}
            data-test={`${dataTest}--id_${props.data.value}--select ${
                isSelected ? `${dataTest}--id_${props.data.value}--selected` : ''
            }`}
        >
            {children}
        </div>
    );
}
