import React, { Component } from 'react';

class InterfaceSelectorIcon extends Component {
    render() {
        return (
            <svg width="16" height="13" viewBox="0 0 16 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.91599 12.1647H13.9301C15.2658 12.1647 15.9294 11.501 15.9294 10.1905V2.13441C15.9294 0.823938 15.2658 0.151886 13.9301 0.151886H5.91599C4.58871 0.151886 3.91667 0.815525 3.91667 2.13441V10.1905C3.91667 11.501 4.58871 12.1647 5.91599 12.1647ZM9.92305 8.31722C7.57091 8.31722 5.8992 9.43448 5.15995 10.9802C4.95833 10.8038 4.84912 10.5182 4.84912 10.1401V2.18481C4.84912 1.46237 5.23554 1.08434 5.9328 1.08434H13.9133C14.6021 1.08434 14.997 1.46237 14.997 2.18481V10.1401C14.997 10.5182 14.8878 10.7954 14.6862 10.9802C13.9553 9.42609 12.334 8.31722 9.92305 8.31722ZM9.92305 7.21675C8.67136 7.19994 7.69691 6.16668 7.68852 4.76379C7.6801 3.44489 8.67136 2.36123 9.92305 2.36123C11.1747 2.36123 12.166 3.44489 12.166 4.76379C12.166 6.16668 11.1747 7.23354 9.92305 7.21675ZM0.789416 11.1655C0.280677 11.1304 0 10.8322 0 10.2796L0 5.35902C0 4.80643 0.280677 4.50821 0.789416 4.47312V11.1655ZM2.75204 11.4253C2.20823 11.3376 1.89247 11.0745 1.89247 10.4693V3.36465C1.89247 2.75945 2.20823 2.49632 2.75204 2.40861V11.4253Z" fill="currentColor"/>
            </svg>

        );
    }
}



export default InterfaceSelectorIcon;