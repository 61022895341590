import React, { useState, useEffect, useCallback, useRef } from 'react';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import { connect } from 'react-redux';
import {
    getCompetencies,
    setPersonGrowthPlanSession,
    updatePersonGrowthPlan,
    updateCachePersonGrowthPlan,
    getPersonGrowthPlanSession,
    getPersonGrowthPlan,
    makeRequestCreator,
} from 'api';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import uuid from "uuid/v4";
import { isMobileWidth } from 'utils.js';
import classnames from 'classnames';
import { COMPETENCIES, TITLE, RESOURCES, ASSISTANT, GROWTH, DATE, STEPS } from './constants';
import CompetenceStep from './CompetenceStep';
import TitleStep from './TitleStep';
import ResourceStep from './ResourceStep';
import AssistantStep from './AssistantStep';
import GrowthStep from './GrowthStep';
import DateStep from './DateStep';
import { isSameOrAfter } from 'utils';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import './EditProgressPlan.scss';
import Loader from 'components/common/Loader';
import { withRouter } from 'react-router';
import ReactDOM from 'react-dom';
import useDebounce from 'components/Lk/Hooks/useDebounce';
import moment from 'libs/moment';

const setPersonGrowthPlanSessionOnce = makeRequestCreator(setPersonGrowthPlanSession);
const updatePersonGrowthPlanOnce = makeRequestCreator(updatePersonGrowthPlan);
const updateCachePersonGrowthPlanOnce = makeRequestCreator(updateCachePersonGrowthPlan);

const defaultFields = {
    personGrowthEvents: [],
    competencyIds: [],
    selectedCompetence: null,
    resources: [],
    personGrowthAssistants: [],
    approverIds: [],
    type: 0,
};

const EditProgressPlan = props => {
    const { plan, onCancel, personId, onSave, onSaveDraft, scrollParentRef, cancelDraft } = props;

    const [step, setStep] = useState(0);
    const [currentPlan, changePlan] = useState({ ...defaultFields, ...plan });
    const [competencies, setCompetencies] = useState([]);
    const [isLoad, setLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const [assistanceDetail = {}] = currentPlan.personGrowthAssistants;
    const [submit, setSubmit] = useState(false);
    const [initialPlan, setInitialPlan] = useState(null);
    const [inPortal, setPortalState] = useState(false);

    const focusWrapperRef = useRef(null);

    const loadCompetencies = useCallback(async () => {
        const response = await getCompetencies(personId);
        setCompetencies(response.data);
        return response.data;
    }, [personId]);

    const onResize = () => {
        setPortalState(window.innerWidth <= 769);
    };

    const isMobile = isMobileWidth();

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
        // eslint-disable-next-line
    }, []);

    const debouncePlanAndStep = useDebounce(JSON.stringify({plan: currentPlan, step: step}), step !== 5 ? 500 : 0);
    
    useEffect(() => {
        const initial = { ...initialPlan, selectedCompetence: currentPlan.selectedCompetence };
        if (JSON.stringify(JSON.parse(debouncePlanAndStep).plan) !== JSON.stringify(initial) && !loading && !isOutdated) {
            handleSaveAndReturn(false);
        }
        // eslint-disable-next-line
    }, [loading, initialPlan, debouncePlanAndStep]);

    const loadSessionPlan = useCallback(
        async competencionList => {
            let session;

            if (currentPlan.id) {
                if (!currentPlan.isSession) {
                    const personGrowthPlan = await getPersonGrowthPlan(currentPlan.id);
                    session = { plan: personGrowthPlan.data, stepId: 'current' };
                } else {
                    session = (await getPersonGrowthPlanSession(personId, currentPlan.id)).data;
                }
            }

            const plan = session ? { ...session.plan } : { ...currentPlan };

            const competencyIds = plan.competencies ? plan.competencies.map(c => c.id) : [];
            const [val] = competencyIds;
            const compIndex =
                val &&
                competencionList
                    .map(x => x.competencies.map(x => x.id))
                    .findIndex(x => x.indexOf(val) >= 0);
            const selectedCompetence = compIndex >= 0 && {
                key: compIndex,
                activeCompetenciesGroup: { ...competencionList[compIndex] },
            };

            const initial = {
                ...plan,
                start: new Date(),
                end: !plan.end || new Date(plan.end) <= new Date() ? null : plan.end,
                competencyIds: plan.competencies ? plan.competencies.map(c => c.id) : [],
                resources: plan.resources ? plan.resources : [],
                selectedCompetence,
                isLoad: true,
                id: plan.id ? plan.id : uuid()
            };

            setInitialPlan({ ...initial });

            changePlan({ ...initial });

            setStep(session ? STEPS.indexOf(session.stepId) : 0);
        },
        [currentPlan, personId],
    );

    useEffect(() => {
        const onInit = async () => {
            setLoad(true);
            const [competencion] = await Promise.all([loadCompetencies()]);
            await loadSessionPlan(competencion);

            setLoading(false);
        };

        if (!isLoad) {
            onInit();
        }
    }, [loadCompetencies, loadSessionPlan, isLoad, loading]);

    const changeHandler = (name, value) => changePlan({ ...currentPlan, [name]: value });

    const changeSelectedCompetence = value => {
        if (currentPlan.isLoad) {
            changePlan({
                ...currentPlan,
                selectedCompetence: value,
                competencyIds: [],
            });
        }
    };

    const canNext = STEPS.length > step + 1;
    const canPrev = step - 1 >= 0;

    const validate = currentStep => {
        let isValid = true;
        if (currentStep == null || STEPS[currentStep] === COMPETENCIES) {
            if (currentPlan.competencyIds.length === 0) {
                showErrorAlert('Не выбрана ни одна компетенция');
                isValid = false;
            }
        }
        if (currentStep == null || STEPS[currentStep] === TITLE) {
            if (!currentPlan.title) {
                showErrorAlert('Не заполнена цель развития');
                isValid = false;
            }
        }
        if (currentStep == null || STEPS[currentStep] === RESOURCES) {
            if (currentPlan.resources.length === 0) {
                showErrorAlert('Не выбран ни один инструмент для развития');
                isValid = false;
            }
        }
        if (currentStep == null || STEPS[currentStep] === ASSISTANT) {
            if (
                currentPlan.personGrowthAssistants.length !== 0 &&
                (currentPlan.personGrowthAssistants[0].fullName ||
                    currentPlan.personGrowthAssistants[0].position ||
                    currentPlan.personGrowthAssistants[0].assistanceDetails) &&
                (!assistanceDetail.fullName ||
                    !assistanceDetail.position ||
                    !assistanceDetail.assistanceDetails)
            ) {
                showErrorAlert('Не все поля корректно заполнены');
                isValid = false;
            }
        }
        if (currentStep == null || STEPS[currentStep] === GROWTH) {
            if (
                currentPlan.personGrowthEvents.length !== 0 &&
                currentPlan.personGrowthEvents.filter(x => !x.type || !x.assistanceDetails).length
            ) {
                showErrorAlert('Не все поля корректно заполнены');
                isValid = false;
            }
        }
        if (currentStep == null || STEPS[currentStep] === DATE) {
            if (!currentPlan.end) {
                showErrorAlert(`Выберите дату завершения`);
                isValid = false;
            } else if (!isSameOrAfter(currentPlan.end, new Date())) {
                showErrorAlert(`Срок выполнения не может быть меньше текущей даты`);
                isValid = false;
            }
        }

        return isValid;
    };
    
    const isOutdated = currentPlan.end && moment(currentPlan.end).isBefore();

    const handleSaveAndReturn = async (manual = true) => {
        const session = {
            plan: {
                ...currentPlan,
                resourceIds: currentPlan.resources ? currentPlan.resources.map(x => x.id) : [],
            },
            stepId: STEPS[step],
        };
        
        switch (true) {
            case plan.isSession:
                if (currentPlan.competencyIds.length === 0) {
                    return;
                }
                break;
            case !plan.isSession && manual:
                if (!validate(step)) {
                    return;
                }
                break;
            case manual:
                if (STEPS[step] === ASSISTANT && !validate(step)) {
                    return;
                }
                break;
            default:
                break;
        }

        try {
            manual && setLoading(true);
            plan.isSession
                ? await setPersonGrowthPlanSessionOnce(session)
                : manual 
                    ? await updatePersonGrowthPlanOnce(session.plan) 
                    : await updateCachePersonGrowthPlanOnce(session.plan);
            
            !manual && !currentPlan.id && changePlan({ ...currentPlan });
        } catch (e) {
            showErrorAlert('Ошибка сохранения черновика');
        } finally {
            manual && setLoading(false);
            await onSaveDraft(manual);
        }
    };

    const onNext = () => {
        if (!validate(step)) {
            setSubmit(true);
            return;
        }
        focusWrapperRef.current.scrollTo(0,0)
        setSubmit(false);
        setStep(canNext ? step + 1 : step);
    };
    const onPrev = () => {
        setStep(canPrev ? step - 1 : step);
    };

    const onSuccess = async () => {
        if (!validate()) {
            return;
        }
        try {
            setLoading(true);
            const request = { ...currentPlan, resourceIds: currentPlan.resources.map(x => x.id) };
            await onSave(request);
        } catch(e) {
            showErrorAlert('Ошибка сохранения');
        } finally {
            setLoading(false);
        }
        
    };

    const onSetRemove = () => () => {
        const initial = { ...initialPlan, selectedCompetence: currentPlan.selectedCompetence };
        if (JSON.stringify(currentPlan) !== JSON.stringify(initial)) {
            cancelDraft();
        } else {
            onCancel();
        }
    };

    const Step = () => {
        switch (STEPS[step]) {
            case COMPETENCIES:
                return (
                    <CompetenceStep
                        competencies={competencies}
                        currentPlan={currentPlan}
                        changeHandler={changeHandler}
                        changeSelectedCompetence={changeSelectedCompetence}
                    />
                );

            case TITLE:
                return (
                    <TitleStep
                        submit={submit}
                        changeHandler={changeHandler}
                        currentPlan={currentPlan}
                    />
                );
            case RESOURCES:
                return (
                    <ResourceStep
                        currentPlan={currentPlan}
                        changeHandler={changeHandler}
                        scrollParentRef={isMobile ? focusWrapperRef : scrollParentRef}
                    />
                );
            case ASSISTANT:
                return <AssistantStep changeHandler={changeHandler} currentPlan={currentPlan} />;
            case GROWTH:
                return (
                    <GrowthStep
                        submit={submit}
                        changeHandler={changeHandler}
                        currentPlan={currentPlan}
                    />
                );
            case DATE:
                return <DateStep changeHandler={changeHandler} currentPlan={currentPlan} />;
            default:
                return null;
        }
    };

    const MainButtons = () => {
        return (
            <div className="LKProgressEditModal__MainButtons">
                {
                    <RoundedButton
                        className={classnames('LKProgressEditModal__Button', {
                            'LKProgressEditModal__Button--hidden': !canPrev,
                        })}
                        color="light-gray"
                        onClick={onPrev}
                    >
                        Назад
                    </RoundedButton>
                }

                {canNext && (
                    <RoundedButton className="LKProgressEditModal__Button" onClick={onNext}>
                        Далее
                    </RoundedButton>
                )}
                {!canNext && (
                    <RoundedButton
                        className="LKProgressEditModal__Button"
                        onClick={onSuccess}
                        disabled={loading}
                    >
                        Создать
                    </RoundedButton>
                )}
            </div>
        );
    };

    const AddButtons = () => {
        return (
            <div className={classnames("LKProgressEditModal__AddButtons", {"LKProgressEditModal__AddButtons--single": !canNext})}>
                <ProfileButton disabled={loading} type="error" onClick={onSetRemove(true)}>
                    Отменить
                </ProfileButton>
                {canNext && (
                    <ProfileButton disabled={isOutdated || loading} onClick={() => handleSaveAndReturn(true)}>
                        Сохранить
                    </ProfileButton>
                )}
            </div>
        );
    };

    const Footer = () => {
        return (
            <div className="LKProgressEditModal__Footer">
                {MainButtons()}
                <AddButtons />
            </div>
        );
    };

    const modalRoot = document.getElementById('fixed-window');

    const content = (
        <div className="LKProgressEdit">
            <div className={classnames('LKProgressEditModal')} ref={focusWrapperRef}>
                <Loader overlay show={loading} />
                {Step()}
            </div>
            <StickyFooter>{Footer()}</StickyFooter>
        </div>
    );

    return (inPortal && modalRoot && ReactDOM.createPortal(content, modalRoot)) || content;
};

const actions = { showErrorAlert, showSuccessAlert };
const mapStateToProps = state => ({
    personId: state.auth && state.auth.user && state.auth.user.personId,
});

export default withRouter(
    connect(
        mapStateToProps,
        actions,
    )(EditProgressPlan),
);
