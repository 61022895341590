import React, { Component } from 'react';
import {connect} from 'react-redux';
import { lkProgressCart, lkProgressPersonProfile } from 'routes';
import { push } from 'connected-react-router';
import { fetchSelectWithProgress as fetchSelect } from 'ducks/ExternalSearch';
import Loader from "components/common/Loader";
import { setProgressCartPersons } from 'ducks/ProgressCart';
import { searchForFavorits } from "ducks/Search";
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import Input from 'components/Lk/Uikit/Input';
import Button from 'components/Lk/Uikit/Buttons/Button';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemPerson from 'components/Lk/Common/CardItemPerson';
import CardItemPersonData from 'components/Lk/Common/CardItemPerson/CardItemPersonData';
import './Progress.scss';
import { getUserShortName } from 'utils';
import InfiniteScroll from 'react-infinite-scroller';


class PersonProgressCard extends Component {

    state = {
        searchTerm: '',
        newData: [],
        hasMore: true,
        paging: { pageNum: 1, pageSize: 10 },
    };

    onSearch = (cleanData = false) => {
        const { searchTerm, paging } = this.state;
        const { fetchSelect } = this.props;

        if (searchTerm.trim() === '') {
            return
        }

        cleanData && this.setState(
            {
                newData: [],
                paging: { pageNum: 1, pageSize: 10 },
            }
        );

        fetchSelect({
            filter: {
                fields: [
                    {
                        category: "Persons",
                        field: "FullName",
                        operation: "contains",
                        value: searchTerm
                    }
                ]
            }, paging: cleanData ? { pageNum: 1, pageSize: 10 } : paging
        });
    };

    handleLoadMore = () => {
        this.setState({ hasMore: false });
        this.onSearch();
    };


    onChangeSearchTerm = e => {
        this.setState({ searchTerm: e.target.value });
    };

    onSearchFieldKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.onSearch(true);
        }
    };


    componentDidMount = async () => {
        const { progressCart } = this.props;

        if (progressCart && progressCart.persons.length !== 0) {
            const personIds = progressCart.persons.filter(x => x.type === 'search').map(p => p.person.id);
            const searchResult = personIds.length !== 0 ? await searchForFavorits({ filter: { personIds: personIds }}) : [];
            const selectedData = searchResult.length !== 0 ? searchResult.ok.payload.data.payload : [];
            this.setState({newData: selectedData});
        }

    }

    componentDidUpdate(prevProps) {

        const { searchResult, searchMeta } = this.props;
        const { newData, paging } = this.state;

        if (prevProps.searchResult !== searchResult) {
            this.setState(() => {
                return {
                    newData: [...newData, ...searchResult],
                    paging: {...paging, pageNum: paging.pageNum + 1},
                    hasMore: searchMeta.pageCount > paging.pageNum,
                };
            });
        }
    }

    onPersonSelect = e => {
        const personId = e.currentTarget.dataset.personId;
        const action = !!this.isInCart(personId) ? 'REMOVE' : 'ADD';
        const person = this.state.newData.find(x => +x.id === +personId);
        this.props.setProgressCartPersons({person, action, type: 'search'})
    };

    isInCart = (personId) => {
        return this.props.progressCart && this.props.progressCart.persons && this.props.progressCart.persons.find(x => +x.person.id === +personId);
    };

    closeSummaryCard = () => {
        this.setState({isSummaryCardOpen: false});
    };

    checkIfSelectionIsInvalid = (person) => {
        if (this.props.location.state && !this.props.location.state.canSelect) {
            return false;
        }

        return !(person.progressPercent || person.percent);
    }

    onCartButtonClick = () => this.props.push(lkProgressCart.url);

    render() {
        const {searchTerm, newData, hasMore} = this.state;
        const {searchLoading, location} = this.props;
        const canSelect = location.state !== undefined && location.state ? location.state.canSelect : false;

        this.props.setActiveCard(ACTIVE_LIST_CARD);
        return (
            <>
                <div className='LKProgressSearchLine'>
                    <Input
                        value={searchTerm}
                        onChange={this.onChangeSearchTerm}
                        onKeyPress={this.onSearchFieldKeyPress}
                    />
                    <Button onClick={() => this.onSearch(true)}/>
                </div>
                <div className="LKScrollWrapper">
                    <InfiniteScroll
                        loadMore={this.handleLoadMore}
                        hasMore={hasMore}
                        initialLoad={false}
                        useWindow={false}
                    >
                        {newData && newData.length !== 0 && newData.map(x => (
                            <CardItemBlock key={x.id}>
                                <CardItemPerson
                                    person={x}
                                    id={x.id}
                                    checkbox={canSelect && this.checkIfSelectionIsInvalid(x)}
                                    clickable
                                    checked={this.isInCart(x.id)}
                                    onCardClick={canSelect && this.checkIfSelectionIsInvalid(x) && this.onPersonSelect}
                                    profileRoute={lkProgressPersonProfile}
                                >
                                    <CardItemPersonData
                                        data={getUserShortName(x)}
                                        normalText
                                        text={x.progressPercent ? `План реализован на ${Math.round(x.progressPercent)}%` : 'Нет данных'}
                                    />
                                </CardItemPerson>
                            </CardItemBlock>
                        ))
                        }
                    </InfiniteScroll>
                    
                    {searchLoading && <Loader/>}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        progressCart: state.progressCart,
        searchResult: state.externalSearch.data.payload,
        searchMeta: state.externalSearch.data.meta,
        searchLoading: state.externalSearch.loading,
    }
};

const actions = {fetchSelect, setProgressCartPersons, push};

export default connect(mapStateToProps, actions)(PersonProgressCard);