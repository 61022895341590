import React from 'react';
import classnames from 'classnames';
import '../DataCard.scss';
import DataCardControls from './DataCardControls';
import DataCardTitle from './DataCardTitle';
import DataCardContent from './DataCardContent';

class DataCardWrapper extends React.PureComponent {
    render() {
        const {
            title,
            children,
            className,
            onChange,
            onDrag,
            controls,
            hasOpen = true,
            isOpen,
            shadow,
            canDrag,
            isDragging,
            toggleDisabled,
            withCheck,
            onCheck,
            checked,
            partlyChecked,
            ...rest
        } = this.props;

        const finalClassName = classnames('DataCard', className, {
            'DataCard--shadow': shadow,
            noDot: isDragging,
        });

        return (
            <div className={finalClassName} {...rest}>
                {!isDragging && (
                    <DataCardControls onEdit={onChange} controls={controls} />
                )}

                {(title || withCheck) && (
                    <DataCardTitle
                        title={title}
                        hasOpen={!isDragging && hasOpen}
                        showDrag={onChange && canDrag}
                        isOpen={isOpen}
                        onToggle={this.props.onToggle}
                        onDrag={onDrag}
                        withCheck={withCheck}
                        onCheck={onCheck}
                        checked={checked}
                        partlyChecked={partlyChecked}
                        toggleDisabled={toggleDisabled}
                    />
                )}

                <DataCardContent isOpen={isOpen}>{children}</DataCardContent>
            </div>
        );
    }
}

DataCardWrapper.defaultProps = {
    toggleDisabled: false,
    withCheck: false,
    onCheck: () => {},
    checked: false,
};

export default DataCardWrapper;
