import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { fileTarget } from '../../../../enums';
import Dropzone from 'components/common/Dropzone';
import { DataCard } from 'components/common/DataCard';
import Row from 'components/uikit/Row';
import Form from 'components/common/Form';
import ValidBlock from 'components/hoc/ValidBlock';
import './FilesBlock.scss';
import { uploadTempFile } from 'api';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

class FilesBlock extends Component {
    onFilesUpload = (files) => {
        if (!this.props.isEditBlock) {
            this.uploadTempFiles(files);
        } else {
            const updatedFiles = this.props.files.push(...files);
            this.handleChange(updatedFiles);
        }
    };

    uploadTempFiles = async (files) => {
        this.props.showPageLoader();
        try {
            const uploadedFiles = await Promise.all(
                files.filter((x) => !x.error).map((x) => uploadTempFile(x.file)),
            );
            const updatedFiles = this.props.files.push(
                ...uploadedFiles.map((f) => ({
                    ...f.data,
                    localKey: files.find((x) => x.name.toLowerCase() === f.data.name.toLowerCase()).localKey,
                })),
            );
            this.handleChange(updatedFiles);
        } catch {
            this.props.showErrorAlert('Ошибка загрузки файла');
        } finally {
            this.props.hidePageLoader();
        }
    };

    handleUploadError = (error) => {
        this.props.showWarningAlert(error.msg);
    };

    onFileChanged = (file) => {
        const index = this.getFileIndex(file.localKey);
        if (index >= 0) {
            const updatedFiles = this.props.files.set(index, file);
            this.handleChange(updatedFiles);
        }
    };

    onFileRemoved = (file) => {
        const newFiles = this.props.files
            .map((x) => (x.id === file.id ? { ...x, deleted: true } : x))
            .filter((x) => !(x.deleted && x.isNew));

        this.handleChange(newFiles);
    };

    getFileIndex = (fileId) => {
        return this.props.files.findIndex((f) => f.localKey === fileId);
    };

    handleChange = (files) => {
        this.props.handleStateChange({ files });
    };

    render() {
        const extraParams = {
            target: fileTarget.PersonFile,
            directoryId: this.props.directoryId,
        };

        const { files, isInputFilled } = this.props;

        isInputFilled(files.size);
        return (
            <DataCard
                shadow
                title="Файлы"
                className="kr_profile-anketa"
                isOpen={this.props.hasValidationErrors}
            >
                <Form autoComplete={false} className="FilesBlockForm">
                    <Row>
                        <Dropzone
                            personId={this.props.personId}
                            files={files.filter((f) => f.deleted !== true).toArray()}
                            onUpload={this.onFilesUpload}
                            onFileChanged={this.onFileChanged}
                            onFileRemoved={this.onFileRemoved}
                            local
                            onCustomDrop={this.onAdd}
                            extraParams={extraParams}
                            className="FilesBlockDownload"
                            onUploadError={this.handleUploadError}
                        />
                    </Row>
                    <Row>{this.props.isEditBlock ? this.props.renderEditBlock() : null}</Row>
                </Form>
            </DataCard>
        );
    }
}

const actions = {
    showWarningAlert,
    showErrorAlert,
    showPageLoader,
    hidePageLoader,
};

export default connect(null, actions)(ToggleOpen(ValidBlock(FilesBlock)));
