import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from "ducks/PageLoader";
import { showErrorAlert } from 'ducks/Alert';
import { ACTIVE_LIST_CARD, PERSONGROWTHTAGS } from "components/Lk/Basic/constants";
import { lkDetailsAdvices } from 'routes';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemBlockType from 'components/Lk/Common/CardItemBlockType';
import classnames from 'classnames';

import './AdviceTags.scss';

const AdviceTags = (props) => {

    const { push, activeCard, setActiveCard } = props;

    const tags = PERSONGROWTHTAGS;
    const isMobileActive = activeCard === ACTIVE_LIST_CARD;

    setActiveCard(ACTIVE_LIST_CARD);

    const handleTagClick = tag => () => {
        return push(lkDetailsAdvices.buildUrl({id: tag.id}));
    };

    const compareByPosition = ( a, b ) => {
        return a.position === b.position ? 0 : a.position > b.position ? 1 : -1;
    };

    const renderAdviceTags = tags => {
        return tags.sort((a, b) => compareByPosition(a, b)).map(tag => (
            <div className='AdviceTags__Block' key={tag.id}>
                <CardItemBlock grey>
                    <CardItemBlockType
                        {...tag}
                        dataTest={`AdvicesBlock--button--id--${tag.id}`}
                        tooltip={tag.description || ''}
                        onClick={handleTagClick(tag)}
                        icon="small"
                        title={tag.title}
                    />
                </CardItemBlock>
            </div>
            )
        );
    };

    return (
        <div className={classnames('ListCard ', {
            'ListCard--isMobileActive': isMobileActive
        })}>
            <MainCardMenu/>
            <div className="AdviceTags">
                <h3 data-test="AdvicesTag--title" className="AdviceTags__Title">Выберите компетенцию, по&nbsp;теме которой хотите прочитать совет</h3>
                {renderAdviceTags(tags)}
            </div>
        </div>
    );
};

AdviceTags.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
    showPageLoader: PropTypes.func.isRequired,
    hidePageLoader: PropTypes.func.isRequired,
    showErrorAlert: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
};

const actions = { showPageLoader, hidePageLoader, showErrorAlert, push };
export default connect(null, actions)(AdviceTags);