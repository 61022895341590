import React, { useState } from 'react';
import Comment from './Comment';
import classnames from 'classnames';
import './CommentsBlock.scss';

const CommentsBlock = props => {
    const { comments } = props;

    const [isVisible, setVisible] = useState(false);
    const toggleComments = () => setVisible(!isVisible);

    return (
        <div className={classnames('LKCommentsBlock', {'LKCommentsBlock--visible': isVisible})}>
            <div className='LKCommentsBlock__Info' onClick={toggleComments}>
                {!isVisible ? `+ Показать заметки (${comments.length})` : `- Скрыть заметки`}
            </div>
            {isVisible && comments.map(x => 
                <Comment comment={x} key={x.operationId} />
            )}
        </div>
    );    
};

export default CommentsBlock;
