import React from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import Icons from 'components/Lk/Uikit/Icons';


const RadioVisible = props => {
    const { onClick, checked, title, subtitle } = props;
    return (
        <div className="LKEditPersonCommentVisibleSelector__Item" onClick={onClick}>
            <div className="LKEditPersonCommentVisibleSelectorIcon">
                <Icons name={checked ? 'radioSelected' : 'radio'} />
            </div>
            <InfoField noIcon value={title} footer={subtitle} />
        </div>
    );
};

export default RadioVisible;
