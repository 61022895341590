import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fieldsToValidateCurrentWork, fieldsToValidatePastWork } from '../../../../constants';
import ToggleOpen from 'components/hoc/ToggleOpen';
import ValidBlock from 'components/hoc/ValidBlock';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import {
    EMPLOYEES_NUMBERS,
    INDUSTRIES,
    MANAGEMENT_EXPERIENCES,
    MANAGEMENT_LEVELS,
    WORK_AREAS,
} from 'ducks/Catalog';
import Select from 'components/uikit/Select';
import CheckField from 'components/uikit/CheckField';
import { DataCard } from 'components/common/DataCard';
import DatePicker from 'components/uikit/DatePicker';
import Field from 'components/uikit/Field';
import Input from 'components/uikit/Input';
import InputText from 'components/uikit/InputText';
import Label from 'components/uikit/Label';
import Row from 'components/uikit/Row';
import Form from 'components/common/Form';
import DataList from '../DataList';
import { DataCardBlock } from 'components/common/DataCard';
import GovernmentServiceBlock from './GovernmentServiceBlock';

class WorkPlace extends Component {
    render() {
        const { value, catalogs, keySelector, severalCurrentPlaces } = this.props;

        const {
            isCurrentWorkPlace,
            isMainWorkPlace,
            company,
            position,
            industry,
            workArea,
            managementLevel,
            managementExperience,
            employeesNumber,
            hireDate,
            quitDate,
            externalInfo,
            professionalAchievements,
        } = value;
        const index = keySelector(value);
        return (
            <>
                <CheckField
                    id={this.getId('currentWorkPlace', index)}
                    title="Текущее место работы"
                    checked={isCurrentWorkPlace}
                    onChange={checked => this.props.onChangeCurrentWorkPlace(index, checked)}
                    size={severalCurrentPlaces ? 50 : 100}
                    modifier="DataCardContent"
                />
                {severalCurrentPlaces && (
                    <CheckField
                        id={this.getId('mainWorkPlace', index)}
                        title="Основное место работы"
                        checked={isMainWorkPlace}
                        onChange={checked => this.props.onChangeMainWorkPlace(index, checked)}
                        size="50"
                        modifier="DataCardContent"
                    />
                )}
                <this.ValidatableField
                    fieldKey="company"
                    fieldValue={company}
                    validationError="Введите компанию"
                    size="50"
                >
                    <Label htmlFor={this.getId('company', index)}>Место работы</Label>
                    <Input
                        id={this.getId('company', index)}
                        value={company || ''}
                        maxLength={250}
                        onChange={e => this.props.onChange(index, { company: e.target.value })}
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="position"
                    fieldValue={position}
                    validationError="Введите должность"
                    size="50"
                >
                    <Label htmlFor={this.getId('position', index)}>Должность</Label>
                    <Input
                        id={this.getId('position', index)}
                        value={position || ''}
                        maxLength={250}
                        onChange={e => this.props.onChange(index, { position: e.target.value })}
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="industry"
                    fieldValue={industry}
                    validationError="Выберите отрасль"
                    size="50"
                >
                    <Label htmlFor={this.getId('industry', index)}>Отрасль</Label>
                    <Select
                        inputId={this.getId('industry', index)}
                        value={industry}
                        onChange={item => this.props.onChange(index, { industry: item })}
                        options={catalogs[INDUSTRIES].data}
                        catalog
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="workArea"
                    fieldValue={workArea}
                    validationError="Выберите область деятельности"
                    size="50"
                >
                    <Label htmlFor={this.getId('workArea', index)}>Область деятельности</Label>
                    <Select
                        inputId={this.getId('workArea', index)}
                        value={workArea}
                        onChange={item => this.props.onChange(index, { workArea: item })}
                        options={catalogs[WORK_AREAS].data}
                        catalog
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="managementLevel"
                    fieldValue={managementLevel}
                    validationError="Выберите уровень менеджмента"
                    size="33"
                >
                    <Label htmlFor={this.getId('managementLevel', index)}>
                        Уровень менеджмента
                    </Label>
                    <Select
                        inputId={this.getId('managementLevel', index)}
                        value={managementLevel}
                        onChange={item => this.props.onChange(index, { managementLevel: item })}
                        options={catalogs[MANAGEMENT_LEVELS].data}
                        catalog
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="managementExperience"
                    fieldValue={managementExperience}
                    validationError="Выберите опыт управления"
                    size="33"
                >
                    <Label htmlFor={this.getId('managementExperience', index)}>
                        Опыт управления
                    </Label>
                    <Select
                        inputId={this.getId('managementExperience', index)}
                        value={managementExperience}
                        onChange={item =>
                            this.props.onChange(index, { managementExperience: item })
                        }
                        options={catalogs[MANAGEMENT_EXPERIENCES].data}
                        catalog
                    />
                </this.ValidatableField>
                <this.ValidatableField size="33">
                    <Label htmlFor={this.getId('employeesNumber', index)}>
                        Кол-во человек в подчинении
                    </Label>
                    <Select
                        inputId={this.getId('employeesNumber', index)}
                        value={employeesNumber}
                        onChange={item => this.props.onChange(index, { employeesNumber: item })}
                        options={catalogs[EMPLOYEES_NUMBERS].data}
                        catalog
                    />
                </this.ValidatableField>
                <this.ValidatableField size="50">
                    <Label htmlFor={this.getId('hireDate', index)}>Начало работы</Label>
                    <DatePicker
                        id={this.getId('hireDate', index)}
                        selected={hireDate ? new Date(hireDate) : null}
                        onChange={value =>
                            this.props.onChange(index, { hireDate: value && value.toDateString() })
                        }
                        noDataBtn
                    />
                </this.ValidatableField>
                {!isCurrentWorkPlace &&
                <this.ValidatableField size="50">
                    <Label htmlFor={this.getId('quitDate', index)}>Окончание работы</Label>
                    <DatePicker
                        id={this.getId('quitDate', index)}
                        selected={!isCurrentWorkPlace ? quitDate ? new Date(quitDate) : null : null}
                        onChange={value => this.props.onChange(index, {quitDate: !isCurrentWorkPlace 
                                    ? value && value.toDateString() 
                                    : null })
                        }
                        noDataBtn
                    />
                </this.ValidatableField>}
                {
                    // TODO: Удалить если заказчик не захочет вернуть после импорта новых резервистов
                    /*<DateRange
                        isNewPerson={!this.props.value.personId}
                        startStateKey='hireDate'
                        endStateKey='quitDate'
                        subBlockName='workPlaces' 
                        index={index} 
                        isInputInvalid={
                            this.props.value.personId 
                            ? this.props.isInputInvalid
                            : this.props.isInputInvalid.bind(null, 'workInfo')
                        }
                        isCurrent={isCurrentWorkPlace}
                        renderDatesRange={this.renderDatesRange}
                    >                   
                    </DateRange>*/
                }
                <this.ValidatableField
                    fieldKey="professionalAchievements"
                    fieldValue={professionalAchievements}
                    validationError="Заполните профессиональные достижения"
                    size="100"
                >
                    <Label htmlFor={this.getId('professionalAchievements', index)}>
                        Профессиональные достижения
                    </Label>
                    <InputText
                        id={this.getId('professionalAchievements', index)}
                        value={professionalAchievements || ''}
                        maxLength={450}
                        onChange={e =>
                            this.props.onChange(index, { professionalAchievements: e.target.value })
                        }
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    fieldKey="externalInfo"
                    fieldValue={externalInfo}
                    validationError="Заполните дополнительные сведения"
                    size="100"
                >
                    <Label htmlFor={this.getId('externalInfo', index)}>
                        Дополнительные сведения
                    </Label>
                    <InputText
                        id={this.getId('externalInfo', index)}
                        value={externalInfo || ''}
                        maxLength={4000}
                        onChange={e => this.props.onChange(index, { externalInfo: e.target.value })}
                    />
                </this.ValidatableField>
            </>
        );
    }

    renderDatesRange = (/* isStartDateValid = false, isEndDateValid = false */) => {
        const { value, keySelector } = this.props;
        const { hireDate, quitDate } = value;
        const index = keySelector(value);
        return (
            <>
                <this.ValidatableField
                    // TODO: Удалить если заказчик не захочет вернуть после импорта новых резервистов
                    //isStart
                    //fieldKey="hireDate"
                    //fieldValue={hireDate}
                    //validationError="Введите дату начала работы"
                    size="50"
                    //invalid={isStartDateValid}
                >
                    <Label htmlFor={this.getId('hireDate', index)}>Начало работы</Label>
                    <DatePicker
                        id={this.getId('hireDate', index)}
                        selected={hireDate ? new Date(hireDate) : null}
                        onChange={value =>
                            this.props.onChange(index, { hireDate: value && value.toDateString() })
                        }
                    />
                </this.ValidatableField>
                <this.ValidatableField
                    // TODO: Удалить если заказчик не захочет вернуть после импорта новых резервистов
                    //fieldKey="quitDate"
                    //fieldValue={quitDate}
                    //validationError="Введите дату окончания работы"
                    size="50"
                    //invalid={isEndDateValid}
                >
                    <Label htmlFor={this.getId('quitDate', index)}>Окончание работы</Label>
                    <DatePicker
                        id={this.getId('quitDate', index)}
                        bsSize="sm"
                        selected={quitDate ? new Date(quitDate) : null}
                        onChange={value =>
                            this.props.onChange(index, { quitDate: value && value.toDateString() })
                        }
                    />
                </this.ValidatableField>
            </>
        );
    };

    getId = (name, index) => {
        return `${name}${index}`;
    };

    ValidatableField = props => {
        const { fieldKey, fieldValue, validationError, ...tail } = props;

        const isCurrentWorkPlace = this.props.value.isCurrentWorkPlace;
        const workErrorKey = `work_${this.props.index}`;
        const validators = [this.validator(fieldValue, validationError)];
        const fieldState = this.getFieldState(
            fieldKey,
            fieldValue,
            validators,
            isCurrentWorkPlace,
            workErrorKey,
        );

        return <Field {...fieldState} {...tail} />;
    };

    validator = (value, errorMessage) => {
        return () => {
            return !!value ? null : errorMessage;
        };
    };

    getFieldState = (fieldKey, fieldValue, validators, isCurrentWorkPlace, workErrorKey) => {
        const required = this.isValidatedField(isCurrentWorkPlace, fieldKey);
        return {
            required,
            filled: required ? this.props.isInputFilled(fieldValue, required) : null,
            invalid: required
                ? this.props.value.personId
                    ? this.props.isInputInvalid(fieldKey, validators, workErrorKey)
                    : this.props.isInputInvalid('workInfo', fieldKey, validators, workErrorKey)
                : null,
        };
    };

    isValidatedField = (isCurrentWorkPlace, fieldId) => {
        return !!this.getValidatedFields(isCurrentWorkPlace).find(elem => elem === fieldId);
    };

    getValidatedFields = isCurrentWorkPlace => {
        return isCurrentWorkPlace ? fieldsToValidateCurrentWork : fieldsToValidatePastWork;
    };
}

class WorkBlock extends Component {
    render() {
        const { isEditBlock, renderEditBlock, workPlaces } = this.props;

        return (
            <DataCard
                shadow
                title="Опыт работы"
                className="kr_profile-anketa"
                isOpen={this.props.hasValidationErrors}
            >
                <Form autoComplete={false} className="EditPerson__Form">
                    <Row>
                        <DataList
                            onAdd={this.addWorkPlace}
                            onRemove={this.removeWorkPlace}
                            canRemove={this.canRemoveWorkBlock}
                            entries={workPlaces.map((workPlace, index) => ({
                                ...workPlace,
                                indexAsKey: index,
                                personId: this.props.personId,
                            }))}
                            keySelector={this.workPlaceKeySelector}
                            renderEntry={workPlace =>
                                this.renderWorkBlock(
                                    workPlace,
                                    workPlaces.filter(x => x.isCurrentWorkPlace).size > 1,
                                )
                            }
                        />
                    </Row>

                    <DataCardBlock title="Сведения о службе в вооружённых силах, органах безопасности">
                        <GovernmentServiceBlock {...this.props} />
                    </DataCardBlock>

                    <Row>{isEditBlock ? renderEditBlock() : null}</Row>
                </Form>
            </DataCard>
        );
    }

    addWorkPlace = () => {
        const workPlaces = this.props.workPlaces.push({
            isCurrentWorkPlace: false,
            isMainWorkPlace: false,
        });
        this.props.handleStateChange('workInfo', { workPlaces });
    };

    removeWorkPlace = workPlace => {
        const workPlaces = this.props.workPlaces.delete(this.workPlaceKeySelector(workPlace));
        this.props.handleStateChange('workInfo', { workPlaces });
    };

    canRemoveWorkBlock = workPlace => {
        return !workPlace.isCurrentWorkPlace;
    };

    changeWorkPlace = (index, changes) => {
        const workPlaces = this.props.workPlaces;
        const newWorkPlaces = workPlaces.set(index, {
            ...workPlaces.get(index),
            ...changes,
        });

        this.props.handleStateChange('workInfo', { workPlaces: newWorkPlaces });
    };

    changeCurrentWorkPlace = (index, isCurrent) => {
        const workPlaces = this.props.workPlaces;

        let newWorkPlaces = workPlaces.update(index, wp => ({
            ...wp,
            isCurrentWorkPlace: isCurrent,
            isMainWorkPlace: isCurrent ? wp.isMainWorkPlace : false,
            quitDate: !!isCurrent ? null : wp.quitDate,
        }));

        let currentPlaces = newWorkPlaces.filter(x => x.isCurrentWorkPlace);

        if (currentPlaces.size === 1) {
            newWorkPlaces = newWorkPlaces.map(x =>
                x.isCurrentWorkPlace ? { ...x, isMainWorkPlace: true } : { ...x },
            );
        }
        this.props.handleStateChange('workInfo', { workPlaces: newWorkPlaces });
    };

    changeMainWorkPlace = (index, isMain) => {
        const { workPlaces } = this.props;
        let newWorkPlaces = workPlaces;
        if (isMain) {
            newWorkPlaces = newWorkPlaces.map(x => ({ ...x, isMainWorkPlace: false }));
        }
        newWorkPlaces = newWorkPlaces.update(index, wp => ({ ...wp, isMainWorkPlace: isMain }));
        this.props.handleStateChange('workInfo', { workPlaces: newWorkPlaces });
    };

    workPlaceKeySelector = workPlace => {
        return workPlace.indexAsKey;
    };

    renderWorkBlock = /* memoize */ (workPlace, severalCurrentPlaces) => {
        return (
            <WorkPlace
                keySelector={this.workPlaceKeySelector}
                value={workPlace}
                onChange={this.changeWorkPlace}
                onChangeCurrentWorkPlace={this.changeCurrentWorkPlace}
                onChangeMainWorkPlace={this.changeMainWorkPlace}
                isInputFilled={this.props.isInputFilled}
                isInputInvalid={this.props.isInputInvalid}
                catalogs={this.props.catalogs}
                severalCurrentPlaces={severalCurrentPlaces}
            />
        );
    };
}

const actions = {
    showWarningAlert,
    showErrorAlert,
};

export default connect(
    null,
    actions,
)(ValidBlock(ToggleOpen(WorkBlock)));
