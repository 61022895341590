import React from 'react';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import { helpEmail, helpPhone } from 'constants.js';

const TechSupport = () => {
    return (
        <div className="SignIn__TechSupport">
            <div>Служба поддержки:</div>
            <div className="SignIn__TechSupportLine">
                <div className="SignIn__TechSupportLineImg">
                    <Icons fill="#368ef1" name="mail" />
                </div>
                <div>
                    <a href={`mailto: ${helpEmail}`}>{helpEmail}</a>
                </div>
            </div>
            <div className="SignIn__TechSupportLine">
                <div className="SignIn__TechSupportLineImg">
                    <Icons fill="#368ef1" name="phone" />
                </div>
                <div>
                    <a href={`tel: ${helpPhone.dial}`}>{helpPhone.show}</a>
                </div>
            </div>
        </div>
    );
};

export default TechSupport;
