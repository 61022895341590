import React from 'react';
import SearchEventMenuItems from './SearchEventMenuItems';
import WishlistEventMenuItems from './WishlistEventMenuItems';
import WishlistsMassEditMenuItems from './WishlistsMassEditMenuItems';

const EventMenuItems = props => {
    const { menuType,  disabledButtons, setMenu, activeSelection, menuFuncs, buttonsToDisable} = props;

    switch(menuType) {
        case 'search':
            return <SearchEventMenuItems disabledButtons={disabledButtons} setMenu={setMenu} />;
        case 'wishlists':
            return <WishlistEventMenuItems disabledButtons={disabledButtons} setMenu={setMenu} selectedItems={activeSelection} menuFuncs={menuFuncs} />;
        case 'wishlistsMassEdit':
            return <WishlistsMassEditMenuItems disabledButtons={disabledButtons} setMenu={setMenu} selectedItems={activeSelection} menuFuncs={menuFuncs} buttonsToDisable={buttonsToDisable} />;
        default:
            return <></>;
    }
};

export default EventMenuItems;