import React, { useMemo } from 'react';
import { Select } from 'components/uikit/Select';
import { Row, Col } from 'react-flexbox-grid';
import './TeamReport.scss';
import CriteriaItem from './CriteriaItem';

const TestItem = props => {
    const { item, tests } = props;

    const onSelectTest = val => {
        props.onChange &&
            props.onChange({
                ...item,
                evaluationTest: val,
                evaluationTestId: val.id,
                criteria: item.criteria.map(x => ({
                    ...x,
                    evaluationTestCriteria: null,
                    evaluationTestCriteriaId: null,
                })),
            });
    };

    const onChangeCriteria = criteria => {
        props.onChange &&
            props.onChange({
                ...item,
                criteria: item.criteria.map(x =>
                    x.id === criteria.id ? { ...criteria } : x,
                ),
            });
    };

    const selectedCriteria = useMemo(
        () =>
            item.criteria
                .filter(c => c.evaluationTestCriteriaId !== null)
                .map(c => c.evaluationTestCriteriaId),
        [item.criteria],
    );

    return (
        <Row className="TeamReportContainer">
            <Col xs={12} >
                <Row className="TeamReportContainer__Heading">
                    <Col xs={6}>{item.title}</Col>
                    <Col xs={6}>
                        <Select
                            options={tests}
                            value={item.evaluationTest}
                            onChange={onSelectTest}
                            getOptionLabel={x => x.title}
                            getOptionValue={x => x.id}
                            placeholder="Выберите критерии"
                        />
                    </Col>
                </Row>
                {item.criteria.map(x => (
                    <CriteriaItem
                        item={x}
                        key={x.id}
                        onChange={onChangeCriteria}
                        criterias={
                            item.evaluationTest?.criteria
                                ? item.evaluationTest.criteria.filter(r => {
                                      return selectedCriteria.indexOf(r.id) === -1;
                                  })
                                : []
                        }
                    />
                ))}
            </Col>
        </Row>
    );
};

export default TestItem;
