import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { templatesRoute, templatesEditRoute } from 'routes';
import PropTypes from 'prop-types';
import { showErrorAlert, showWarningAlert, showInfoAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { getTemplateById, saveTemplate } from 'api';
import { serviceResultCode, getError, getValidationErrors } from 'serviceErrors';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Sticker from 'react-stickyfill';
import Button from 'components/uikit/Button';
import PageCardWrapper from 'components/common/PageCardWrapper';
import InstrumentsPane from 'components/Moderation/Templates/InstrumentsPane';
import Email from 'components/Moderation/Templates/Email';
import uuid from 'uuid/v4';
import Popover from 'components/Lk/Uikit/Popover';

const EditTemplate = (props) => {
    const {
        id,
        isNewTemplate,
        showPageLoader,
        showErrorAlert,
        hidePageLoader,
        showWarningAlert,
        showInfoAlert,
    } = props;
    const blankTemplate = useMemo(() => {
        return {
            data: {
                subject: '',
                name: '',
                type: null,
                groupId: null,
                template: {},
                templateHeaderData: {},
                isSubmitted: false,
                isValid: true,
                body: {
                    id: 'root_list',
                    kind: 'List',
                    attributes: {},
                    children: [],
                },
            },
        };
    }, []);

    const [template, setTemplate] = useState(null);
    const [initialBody, setInitialBody] = useState({});
    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                showPageLoader();
                const templateData = isNewTemplate ? blankTemplate : await getTemplateById(id);
                setTemplate(templateData.data);
                setInitialBody(templateData.data?.body);
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchTemplate();
    }, [id, isNewTemplate, blankTemplate, showPageLoader, showErrorAlert, hidePageLoader]);

    const goBack = () => {
        props.push({
            pathname: templatesRoute.url,
            search: props.location.search,
        });
    };

    const renderFooter = () => {
        const isChange = isNewTemplate || template.changed;
        return (
            <>
                <div>
                    <Popover tooltip={isChange ? 'Для предпросмотра требуется сохранение' : null}>
                        <Button
                            disabled={isChange}
                            size={'sm'}
                            color={'light_primary'}
                            onClick={previewTemplate}
                        >
                            Предпросмотр
                        </Button>
                    </Popover>
                </div>

                <div className="Wrapper__Footer--btn-group">
                    <Button size={'sm'} color={'danger'} onClick={goBack}>
                        Отменить
                    </Button>
                    <Button size={'sm'} onClick={onSaveTemplate}>
                        Сохранить
                    </Button>
                </div>
            </>
        );
    };

    const isTemplateChanged = (changed = true) => setTemplate({ ...template, changed: changed });

    const handleTemplateChildrenChange = (newChildren) => {
        const newBody = { ...template.body, children: [...newChildren] };

        setTemplate({
            ...template,
            changed: JSON.stringify(newBody) !== JSON.stringify(initialBody),
            body: newBody,
        });
    };

    const handleTemplateAttributesChange = (position, newAttributes) => {
        const newChildren = template.body.children.map((x, index) => {
            return position === index ? { ...x, attributes: newAttributes } : { ...x };
        });

        const newBody = { ...template.body, children: [...newChildren] };
        setTemplate({
            ...template,
            changed: JSON.stringify(newBody) !== JSON.stringify(initialBody),
            body: newBody,
        });
    };

    const handleTemplateGroupChange = (e) => setTemplate({ ...template, groupId: e.value });

    const handleTemplateTypeChange = (e) => setTemplate({ ...template, type: e.value });

    const handleTemplateNameChange = (e) => setTemplate({ ...template, name: e.target.value });

    const handleTemplateSubjectChange = (e) =>
        setTemplate({ ...template, subject: e.target.value });

    const previewTemplate = () => {
        let url = `${window.location.origin}/api/emailTemplates/${template.id}/preview/`;
        let win = window.open(url, '_blank');
        win.focus();
    };

    const isValidTemplate = () =>
        template.name.trim() !== '' &&
        template.subject.trim() !== '' &&
        template.type &&
        template.groupId;

    const prepareTemplate = () => {
        const newChildren = template.body.children.map((x) => {
            return {
                ...x,
                attributes: normalizeColorAttribute(x.attributes),
            };
        });
        return {
            ...template,
            id: isNewTemplate ? uuid() : template.id,
            body: { ...template.body, children: [...newChildren] },
        };
    };

    const normalizeColorAttribute = (attributes) => {
        return attributes.color
            ? { ...attributes, color: attributes.color.code }
            : { ...attributes };
    };

    const onSaveTemplate = async () => {
        setTemplate({ ...template, isSubmitted: true });
        try {
            if (!isValidTemplate()) {
                showWarningAlert('Не все поля корректно заполнены');
                window.scrollTo(0, 0);
            } else {
                showPageLoader();
                const templateData = await saveTemplate(prepareTemplate());
                showInfoAlert('Шаблон успешно сохранен');
                isTemplateChanged(false);
                isNewTemplate &&
                    props.push(templatesEditRoute.buildUrl({ id: templateData.data.id }));
                setInitialBody(templateData.data?.body);
            }
        } catch (error) {
            const validationErrors = getValidationErrors(error);
            if (Array.isArray(validationErrors) && validationErrors.length > 0) {
                validationErrors.map((item) => {
                    return showErrorAlert(item.message);
                });
            } else {
                const reqError = getError(error, saveTemplateError);
                showErrorAlert(reqError.message);
            }
        } finally {
            hidePageLoader();
        }
    };

    const saveTemplateError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return payload ? `${payload}` : 'Не найден';
            case serviceResultCode.Forbidden:
                return payload ? `${payload}` : 'Доступ запрещен';
            case serviceResultCode.EntityAlreadyExists:
                return payload ? `${payload}` : 'Сущность уже используется';
            case serviceResultCode.NoDefaultEmailTemplateError:
                return payload;
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };

    return (
        template && (
            <div className="Template">
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="Template__Header">Редактор писем</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4} xs={12}>
                            <Sticker>
                                <div className="Template__StickyWrapper">
                                    <PageCardWrapper header="Инструменты" size="sm">
                                        <InstrumentsPane />
                                    </PageCardWrapper>
                                </div>
                            </Sticker>
                        </Col>
                        <Col xl={8} xs={12}>
                            <PageCardWrapper header="Шаблон" size="sm" footer={renderFooter()}>
                                <Email
                                    {...template}
                                    handleTemplateGroupChange={handleTemplateGroupChange}
                                    handleTemplateTypeChange={handleTemplateTypeChange}
                                    handleTemplateNameChange={handleTemplateNameChange}
                                    handleTemplateSubjectChange={handleTemplateSubjectChange}
                                    handleTemplateChildrenChange={handleTemplateChildrenChange}
                                    handleTemplateAttributesChange={handleTemplateAttributesChange}
                                />
                            </PageCardWrapper>
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    );
};

EditTemplate.propTypes = {
    id: PropTypes.string,
    isNewTemplate: PropTypes.bool,
};

const actions = {
    push,
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    showWarningAlert,
    showInfoAlert,
};

export default connect(null, actions)(EditTemplate);
