import { differenceInYears } from "date-fns";
import React, { Component } from 'react';
import { trimStart } from 'utils';
import { DataCard, DataCardProperty } from "components/common/DataCard";
import Favorite from "components/common/Favorite";
import EditPersonalInfoBlock from './Edit/PersonalInfoBlock';
import { declineAge } from '../../utils';
import "./PersonalInfoBlock.scss";

class PersonalInfoBlock extends Component {
    getFullName = () => {
        const { lastName = '', firstName = '', middleName = '' } = this.props;
        return `${lastName} ${firstName} ${middleName}`;
    };

    getLivingPlace = () => {
        const { region, locality, foreignRegion, foreignLocality, isRussianCitizen, selectedNationality } = this.props;
        let result = '';
        if (isRussianCitizen) {
            if (region) {
                result += region.name;
            }
            if (locality) {
                result += `, ${locality.name}`;
            }
        } else {
            if (selectedNationality) {
                result += `${selectedNationality.name}, `;
            }
            if (foreignRegion) {
                result += foreignRegion;
            }
            if (foreignLocality) {
                result += `, ${foreignLocality}`;
            }
        }

        return trimStart(result, ', ');
    };

    getAge = () => {
        return differenceInYears(new Date(), this.props.birthDate);
    };

    renderPastResidences = (pastResidences) => {
        return pastResidences.map((item, index) => 
            <div key={index}>
                {item.selectedDistrict && <DataCardProperty title="Федеральный округ">{item.selectedDistrict.name}</DataCardProperty>}
                {item.selectedRegion && <DataCardProperty title="Регион">{item.selectedRegion.name}</DataCardProperty>}
                {item.selectedLocality && <DataCardProperty title="Населённый пункт">{item.selectedLocality.name}</DataCardProperty>}
                {item.yearFrom && item.yearTo && <DataCardProperty title="Период проживания">{item.yearFrom} - {item.yearTo}</DataCardProperty>}
                <br />
            </div>
        );
    };

    formatSnils = (snils) => {
        return (
            snils.substring(0, 3) +
            '-' +
            snils.substring(3, 6) +
            '-' +
            snils.substring(6, 9) +
            '-' +
            snils.substring(9, 11)
        );
    };

    renderView = () => {
        const {
            currentWorkPlace,
            region,
            selectedDistrict,
            isRussianCitizen,
            readyMoveToRussia,
            foreignRegion,
            foreignLocality,
            residenceStartYear,
            locality,
            sex,
            extraInfo,
            canEditPerson,
            isFavorite,
            toggleFavoriteFlag,
            birthPlace,
            selectedNationality,

        } = this.props;

        const age = this.getAge();

        return (
            <DataCard 
                shadow
                id="profile-module1"
                className="kr_profile-anketa-view Person__PersonalInfoBlock" 
                onChange={canEditPerson ? () => this.props.setEditBlock('PersonalInfoBlock') : null}
                controls={
                    <Favorite isFavorite={isFavorite} onChangeFavorite={toggleFavoriteFlag}  />
                }
            >
                <div className="kr_item_col2">
                    <div className="kr_section">
                        <div className="kr_item_name">{this.getFullName()}</div>
                        {currentWorkPlace && <DataCardProperty title={currentWorkPlace.position} />}
                        {currentWorkPlace && <div className="kr_item_company">{currentWorkPlace.company}</div>}
                    </div>
                    <div className="kr_section">
                        {this.props.isRussianCitizen && (
                            <>
                                <div className="kr_item_check">Гражданин РФ</div>
                                {this.props.snils ? (
                                    <DataCardProperty title="№ СНИЛС">
                                        {this.formatSnils(this.props.snils)}
                                    </DataCardProperty>
                                ) : (
                                    <div className="DataCard__NoValue">Отсутствует номер СНИЛС</div>
                                )}
                            </>
                        )}
                    </div>
                </div>

                <div className="kr_item_col3">
                    <div className="kr_section">
                        
                        {selectedDistrict && <DataCardProperty title="Федеральный округ">{selectedDistrict.name}</DataCardProperty>}
                        {region && <DataCardProperty title="Регион">{region.name}</DataCardProperty>}
                        {isRussianCitizen && locality && <DataCardProperty title="Населенный пункт">{locality.name}</DataCardProperty>}
                        {!isRussianCitizen && selectedNationality && <DataCardProperty title="Гражданство">{selectedNationality.name}</DataCardProperty>}
                        {!isRussianCitizen && foreignRegion && <DataCardProperty title="Регион">{foreignRegion}</DataCardProperty>}
                        {!isRussianCitizen && foreignLocality && <DataCardProperty title="Населенный пункт">{foreignLocality}</DataCardProperty>}
                        {!Number.isNaN(age) && <DataCardProperty title="Возраст">{declineAge(age)}</DataCardProperty>}
                        <DataCardProperty title="Пол">{sex?.name}</DataCardProperty>
                        {!isRussianCitizen &&  <DataCardProperty title="">{readyMoveToRussia ? "Готов к переезду в РФ" : "Не готов к переезду в РФ"}</DataCardProperty>}
                        {extraInfo && <div className="kr_item_text">{extraInfo}</div>}
                    </div>
                    <div className={extraInfo ? "" : "kr_section"}>
                        <DataCardProperty title="Телефон">{this.props.phone}</DataCardProperty>
                        <DataCardProperty title="E-mail">{this.props.email}</DataCardProperty>
                    </div>
                </div>

                <div className="kr_item_col_full">
                    <div className="kr_section_extra">
                        { this.props.selectedDocument?.name  && (
                            <DataCardProperty title="Документ">{ this.props.selectedDocument?.name === "Другое" ?this.props.documentName: this.props.selectedDocument.name }</DataCardProperty>
                        )}
                       
                        <DataCardProperty title="№ Документа">{ this.props.documentNumber }</DataCardProperty>

                        
                    </div>
                    <div className="kr_section_extra">
                        <DataCardProperty title="Место рождения">{birthPlace}</DataCardProperty>
                    </div>
                    <div className="kr_section">
                        <div className="PersonResidences">
                            <div className="PersonResidences__Column PersonResidences__Column--50">
                                <h4>Текущее место проживания</h4>
                                <div>
                                    {selectedDistrict && <DataCardProperty title="Федеральный округ">{selectedDistrict.name}</DataCardProperty>}
                                    {region && <DataCardProperty title="Регион">{region.name}</DataCardProperty>}
                                    {locality && <DataCardProperty title="Населенный пункт">{locality.name}</DataCardProperty>}
                                    {!isRussianCitizen && selectedNationality && <DataCardProperty title="Гражданство">{selectedNationality.name}</DataCardProperty>}
                                    {!isRussianCitizen && foreignRegion && <DataCardProperty title="Регион">{foreignRegion}</DataCardProperty>}
                                    {!isRussianCitizen && foreignLocality && <DataCardProperty title="Населенный пункт">{foreignLocality}</DataCardProperty>}
                                    {residenceStartYear && <DataCardProperty title="Год начала проживания">{residenceStartYear}</DataCardProperty>}
                                </div>
                            </div>
                            {this.props.pastResidences && this.props.pastResidences.length > 0 &&
                                <div className="PersonResidences__Column PersonResidences__Column--50">
                                    <h4>Проживание в субъектах РФ</h4>
                                    {this.renderPastResidences(this.props.pastResidences)}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </DataCard>
        );
    };

    renderEdit = () => <EditPersonalInfoBlock {...this.props} />;

    render() {
        return this.props.isEditBlock ? this.renderEdit() : this.renderView();
    }
}

export default PersonalInfoBlock;