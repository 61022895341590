import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showWarningAlert, showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import Page from 'components/common/Page';
import ListTemplate from 'components/uikit/ListTemplate';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ModalDialog from 'components/common/ModalDialog';
import PersonsTable from './PersonsTable';
import ResultBlock from './ResultBlock';
import Input from 'components/uikit/Input/';
import Label from 'components/uikit/Label';
import Field from 'components/uikit/Field';
import Button from 'components/uikit/Button';
import Toggle from 'components/common/Toggle';
import { checkPerson, personsFileUpload, setPersonAccess } from 'api';
import { serviceResultCode } from 'serviceErrors';
import { getValidationErrors, getError } from 'serviceErrors';
import { logUIError } from 'api';
import allRoutes from 'routes.js';
import { matchPath } from 'react-router';
import './Persons.scss';

const actions = {
    delete: 'delete',
    restore: 'restore',
};

const actionsArray = [
    { htmlId: 'delete', title: 'Удалить', value: actions.delete },
    { htmlId: 'restore', title: 'Восстановить', value: actions.restore },
];

export const personStatus = {
    Active: 'Active',
    Deleted: 'Deleted',
};

const initialState = {
    newPerson: { id: '', lastName: '', checked: true, found: true },
    persons: [],
    action: actions.delete,
    isModalOpen: false,
    resultResponse: null,
    isAdding: false,
};

class Persons extends Component {
    state = initialState;

    setNewPersonId = (e) => {
        const regexp = /^([0-9]+)?$/g;
        const val = e.target.value?.replace(/^[0]+/g, '');
        if (regexp.test(val) === false) {
            e.preventDefault();
            return;
        }
        this.setState({ newPerson: { ...this.state.newPerson, id: val } });
    };

    setNewPersonName = (e) =>
        this.setState({ newPerson: { ...this.state.newPerson, lastName: e.target.value } });

    checkPersonInTable = (id) => {
        return this.state.persons.find((x) => x.id.toString() === id.toString());
    };

    isActionAllowed = (person, action) => {
        return (
            (person.found && person.status === personStatus.Active && action === actions.delete) ||
            (person.status === personStatus.Deleted && action === actions.restore)
        );
    };

    addPerson = async () => {
        const {
            newPerson: { id, lastName },
        } = this.state;

        const { showPageLoader, hidePageLoader, showErrorAlert, showWarningAlert } = this.props;

        try {
            this.setState({ isAdding: true });
            showPageLoader();
            if (this.checkPersonInTable(id)) {
                showWarningAlert(`Анкета с ID: ${id} уже добавлена в список`);
                return;
            }

            const res = await checkPerson([{ id, lastName }]);
            const isPersonExists = res.data[0]?.found;

            isPersonExists
                ? this.setState({
                      persons: [
                          {
                              id,
                              lastName: res.data[0]?.lastName,
                              checked: true,
                              found: true,
                              status: res.data[0].status,
                              isActionAllowed: this.isActionAllowed(res.data[0], this.state.action),
                          },
                          ...this.state.persons,
                      ],
                      newPerson: { id: '', lastName: '' },
                  })
                : showErrorAlert(`Анкета с ID: ${id} и фамилией ${lastName} не найдена`);
        } catch (e) {
            showErrorAlert('Ошибка проверки пользователя');
        } finally {
            hidePageLoader();
            this.setState({ isAdding: false, resultResponse: null });
        }
    };

    addFromFile = async (e) => {
        const { showPageLoader, hidePageLoader } = this.props;
        try {
            showPageLoader();

            if (e.target.files.length === 0) {
                return;
            }

            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            const loadRes = await personsFileUpload(formData);

            const filteredPersons = loadRes.data.filter(
                (x) => !this.state.persons.find((z) => z.id == x.id),
            );

            this.setState({
                resultResponse: null,
                action: actions.delete,
                persons: [
                    ...this.state.persons,
                    ...filteredPersons.map((x) => ({
                        ...x,
                        checked: x.found,
                        isActionAllowed: this.isActionAllowed(x, this.state.action),
                    })),
                ],
            });
        } catch (e) {
            this.showFileError(e);
        } finally {
            const fileInput = document.getElementById('personsFile');
            fileInput.value = '';
            hidePageLoader();
        }
    };

    showFileError = (error) => {
        const { showErrorAlert, showWarningAlert } = this.props;

        switch (error?.response?.data?.code) {
            case serviceResultCode.ValidationErrors:
                const validationErrors = getValidationErrors(error);
                if (Array.isArray(validationErrors) && validationErrors.length > 0) {
                    validationErrors.map((item) => {
                        return showErrorAlert(item.message);
                    });
                } else {
                    const reqError = getError(error, this.getIssueError());
                    return showErrorAlert(reqError.message);
                }
                break;
            case serviceResultCode.PersonStatusCheckFailed:
                return showWarningAlert(
                    `Не было добавлено ни одной анкеты. Проверьте правильность данных в файле и его кодировку`,
                );
            default:
                const { location } = window;
                const route = Object.values(allRoutes).find(
                    (x) =>
                        x.url === location.pathname ||
                        (matchPath(location.pathname, {
                            path: x.url,
                            exact: x.exact,
                            strict: x.strict,
                        }) &&
                            x.url.includes(':')),
                );

                const errorScope = {
                    title: route?.title,
                    pathName: location.pathname + location.search,
                    errorTitle: error?.response?.data?.code?.toString(),
                    errorInfo: error ? JSON.stringify(error) : null,
                };
                logUIError(errorScope);
                return showErrorAlert(`Ошибка загрузки файла`);
        }
    };

    onEnter = (e) => {
        const {
            newPerson: { id, lastName },
            isAdding,
        } = this.state;
        if (e.key === 'Enter' && id.trim() && lastName.trim() && !isAdding) {
            this.addPerson();
        }
    };

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    sendRequest = async () => {
        this.closeModal();
        const { showPageLoader, hidePageLoader, showErrorAlert, showSuccessAlert } = this.props;
        try {
            const { persons, action } = this.state;
            this.setState({ resultResponse: null });

            const filteredPersons = persons.filter(
                (x) => x.found && x.checked && x.isActionAllowed,
            );
            showPageLoader();

            const res = await setPersonAccess(
                filteredPersons,
                action === actions.restore ? true : false,
            );

            const resultResponse = {
                succeed: res.data.filter((x) => x.status),
                failed: res.data.filter((x) => !x.status),
            };

            this.setState({ resultResponse });

            if (resultResponse?.failed?.length === 0 && resultResponse?.succeed?.length > 0) {
                showSuccessAlert(
                    `${
                        resultResponse.succeed.length > 1
                            ? `Анткеты успешно ${
                                  action === actions.delete ? 'удалены' : 'восстановлены'
                              }`
                            : `Анкета успешно ${
                                  action === actions.delete ? 'удалена' : 'восстановлена'
                              }`
                    }`,
                );
            } else {
                showWarningAlert('Некоторые анкеты не были обработаны');
            }

            this.clearState();
        } catch (e) {
            showErrorAlert('Ошибка обновления анкет');
        } finally {
            hidePageLoader();
            this.setState({ isModalOpen: false });
        }
    };

    clearState = (full = false) => {
        full
            ? this.setState(initialState)
            : this.setState({
                  persons: this.state.persons.filter((x) => !x.checked || !x.isActionAllowed),
              });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    togglePerson = (id) => {
        this.setState({
            persons: this.state.persons.map((x) => ({
                ...x,
                checked: x.id === id ? !x.checked : x.checked,
            })),
        });
    };

    setAction = (action) => this.setState({ action });

    isValidForm = () => {
        const activePersons = this.state.persons.filter(
            (x) => x.checked && x.isActionAllowed,
        ).length;
        return activePersons > 0 && this.state.persons.length > 0;
    };

    cleanTable = () => this.setState({ ...initialState });

    onDeleteClick = (person) => {
        this.setState({
            persons: this.state.persons.filter(
                (x) => !(x.id === person.id && x.lastName === person.lastName),
            ),
        });
    };

    cleanResult = () => {
        this.setState({ resultResponse: null });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.action !== this.state.action) {
            this.setState({
                persons: this.state.persons.map((x) => ({
                    ...x,
                    isActionAllowed: this.isActionAllowed(x, this.state.action),
                })),
            });
        }
    }

    render() {
        const { action, persons, isModalOpen, newPerson, resultResponse, isAdding } = this.state;
        return (
            <Page>
                <ListTemplate title={'Удаление / восстановление анкет'} headBtn={null}>
                    <Grid fluid className="AdminPersons">
                        <Row>
                            <Col xs={2}>
                                <Field required filled={newPerson.id.trim()}>
                                    <Label>ID анкеты</Label>
                                    <Input
                                        value={newPerson.id}
                                        onChange={this.setNewPersonId}
                                        onKeyDown={this.onEnter}
                                    />
                                </Field>
                            </Col>

                            <Col xs={4}>
                                <Field required filled={newPerson.lastName.trim()}>
                                    <Label>Фамилия</Label>
                                    <Input
                                        maxLength="100"
                                        value={newPerson.lastName}
                                        onChange={this.setNewPersonName}
                                        onKeyDown={this.onEnter}
                                    />
                                </Field>
                            </Col>

                            <Col xs={2}>
                                <Field>
                                    <Label>&nbsp;</Label>
                                    <Button
                                        size="sm"
                                        onClick={this.addPerson}
                                        disabled={
                                            !newPerson.id.trim() ||
                                            !newPerson.lastName.trim() ||
                                            isAdding
                                        }
                                    >
                                        Добавить
                                    </Button>
                                </Field>
                            </Col>

                            <Col xs={4}>
                                <Row end="xs">
                                    <Field>
                                        <Label>&nbsp;</Label>
                                        <div className="AdminPersons__File">
                                            <label
                                                htmlFor="personsFile"
                                                className="CommonButton CommonButton--size_sm"
                                            >
                                                Загрузить из файла
                                            </label>
                                            <input
                                                type="file"
                                                hidden="hidden"
                                                id="personsFile"
                                                onChange={this.addFromFile}
                                            />
                                        </div>
                                    </Field>
                                </Row>
                            </Col>
                        </Row>
                        <PersonsTable
                            persons={persons}
                            togglePerson={this.togglePerson}
                            onDeleteClick={this.onDeleteClick}
                            personStatus={personStatus}
                        />
                        {this.state.persons.length !== 0 && (
                            <Row>
                                <Col xs={6}>
                                    <div className="AdminPersons__Action">
                                        <Toggle
                                            value={action}
                                            options={actionsArray}
                                            onChange={this.setAction}
                                        />
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <Row end="xs">
                                        <Button size="sm" closeLink onClick={this.cleanTable}>
                                            Очистить все
                                        </Button>
                                        <Button
                                            size="sm"
                                            disabled={!this.isValidForm()}
                                            onClick={this.openModal}
                                        >
                                            Выполнить
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </Grid>
                </ListTemplate>

                {resultResponse && (
                    <ResultBlock resultResponse={resultResponse} cleanResult={this.cleanResult} />
                )}

                <ModalDialog
                    modalHeader={`${action === actions.delete ? 'Удалить' : 'Восстановить'} ${
                        persons.filter((x) => x.checked && x.found).length > 1
                            ? 'выбранные анкеты'
                            : 'анкету'
                    } ?`}
                    onClick={this.sendRequest}
                    onCloseModal={this.closeModal}
                    modalOpen={isModalOpen}
                />
            </Page>
        );
    }
}

const mapActions = {
    showPageLoader,
    hidePageLoader,
    showWarningAlert,
    showErrorAlert,
    showSuccessAlert,
};

export default connect(null, mapActions)(Persons);
