import classNames from "classnames";
import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import Popover from 'components/Lk/Uikit/Popover';
import { isDevice } from 'utils';
import "./Favorite.scss";

class Favorite extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isClicked: false,
        };
    }

    handleClick = (isFavorite, onChangeFavorite) => () => {
        this.setState({ isClicked: true });

        if (typeof onChangeFavorite === "function") {
            onChangeFavorite(!isFavorite);
        }
    };

    favorite = isMulti => {
        const { isFavorite, onChangeFavorite, disabled } = this.props;
        const { isClicked } = this.state;

        return <div className="Favorite">
            <button
                className="Favorite__Button"
                onClick={this.handleClick(isFavorite, onChangeFavorite)}
                disabled={disabled}
            >
                <FontAwesomeIcon
                    className={classNames({
                        "Favorite__Star": true,
                        "Favorite__Star_Active": isFavorite && !disabled,
                        "Favorite__Star_Disabled": disabled,
                        "Favorite__Star_Multi": isMulti
                    })}
                    icon={disabled ? faStarSolid : (isFavorite || isMulti ? faStarSolid : faStarRegular)}
                />
                <span className={classNames({
                    "Favorite__ClickEffect": true,
                    "Favorite__ClickEffect_Active": isFavorite && isClicked
                })}/>
            </button>
        </div>        
    };

    render = () => {
        const { tooltip = [] } = this.props;
        const isMulti = tooltip ? tooltip.filter(x => !x.isDefault).length > 0 : false;
        
        return (
            isMulti && !isDevice.SM()
            ?
            <Popover
                tooltip={tooltip.map(x => <div key={x.id} className="Favorite__Tooltip">{x.title}</div>)}
                autoPlacement
            >
                {this.favorite(isMulti)}
            </Popover>
            : this.favorite(isMulti)
        );
    }
}

export default Favorite;
