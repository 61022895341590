import React, { useState } from 'react';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { 
    lkProgressTeam, lkProgressPerson,
    lkProgressFavotite, lkProgressCart, lkProgressTeamProfile,
    lkProgressFavotiteProfile,
} from 'routes';
import { ALLOWED_LK_ROLES } from 'rightsController';
import FavoriteProgressCard from 'components/Lk/Shared/Progress/FavoriteProgressCard';
import PersonProgressCard from 'components/Lk/Shared/Progress/PersonProgressCard';
import TeamProgressCard from 'components/Lk/Shared/Progress/TeamProgressCard';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemBlockType from 'components/Lk/Common/CardItemBlockType';
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import { progressCardMenuItems } from 'components/Lk/Basic/MainCard/constants';
import { setProgressCartPersons } from 'ducks/ProgressCart';
import classnames from 'classnames';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import { sortingOptions, ALL_MENU_ID } from './constants';
import { filterGroups, sortGroups, altCardText} from './helpers';
import './Progress.scss';

const Progress = props => {
    const { activeCard, setActiveCard, location, setProgressCartPersons, push, progressCart } = props;

    const canSelect = location.state !== undefined && location.state ? location.state.canSelect : false;

    setActiveCard(ACTIVE_LIST_CARD);
    const isMobileActive = activeCard === ACTIVE_LIST_CARD;

    const onCartButtonClick = () => push(lkProgressCart.url);
    
    const [selectedSorting, setSelectedSorting] = useState(sortingOptions[0]);
    const [selectedFilter, setSelectedFilter] = useState(ALL_MENU_ID);

    const onPersonSelect = (personId, person) => e => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        const action = !!isInCart(personId) ? 'REMOVE' : 'ADD';
        const { pathname }  = location;
        
        const type = pathname && pathname.includes(lkProgressTeam.url) 
            ? 'team'
            : pathname && pathname.includes(lkProgressFavotite.url)
                ? 'favorite'
                : null;
        setProgressCartPersons({ person, action, type });
    };

    const isInCart = personId => {
        return (
            progressCart &&
            progressCart.persons &&
            progressCart.persons.find(x => +x.person.id === +personId)
        );
    };
    
    const getSelectedIds = () => {
        return (         
            progressCart &&
            progressCart.persons &&
            progressCart.persons.map(x => x.person.id)
        );
    };

    const onCheckAll = (items, isCheck) => {
        const { pathname }  = location;
        const action = isCheck ? 'ADD' : 'REMOVE';
        
        const type = pathname && pathname.includes(lkProgressTeam.url)
            ? 'team'
            : pathname && pathname.includes(lkProgressFavotite.url)
                ? 'favorite'
                : null;
        
        items.forEach(person => setProgressCartPersons({ person, action, type }))
    };
    
    const getPersonsNumber = (progressCart) => {
        const cart = progressCart && progressCart.persons && Array.isArray(progressCart.persons) 
            && progressCart.persons.filter((v, i, a) => a.findIndex(x => (x.person.id === v.person.id)) === i);
        return cart ? cart.length : 0;
    };

    return (
        <div className={classnames('ListCard LKMainProgressCard', { 'ListCard--isMobileActive': isMobileActive, 'LKMainProgressCard--isMobileActive': isMobileActive })}>
            <MainCardMenu main menuItems={progressCardMenuItems} defaultMenuId='favorite' linkParams={{ canSelect: canSelect }} />
            <div className="LKMainProgressCardHeader">
                <CardItemBlock grey>
                    <CardItemBlockType
                        id="progress"
                        title="Индивидуальный план развития"
                        icon="small"
                    />
                </CardItemBlock>
            </div>
            <Switch>
                <ProtectedRoute
                    path={lkProgressTeam.url}
                    render={(props) =>
                        <TeamProgressCard {...props} 
                                          activeCard={activeCard} 
                                          setActiveCard={setActiveCard}
                                          onPersonSelect={onPersonSelect}
                                          personProfileUrl={lkProgressTeamProfile}
                                          isInCart={isInCart}
                                          selectedSorting={selectedSorting} setSelectedSorting={setSelectedSorting}
                                          selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter}
                                          filterGroups={filterGroups} sortGroups={sortGroups} altCardText={altCardText}
                                          selectedIds={getSelectedIds()}
                                          onCheckAll={onCheckAll}
                                          existScrollWrapper
                    />}
                    allowedRoles={ALLOWED_LK_ROLES}
                />
                <ProtectedRoute
                    path={lkProgressFavotite.url}
                    personProfileUrl={lkProgressFavotiteProfile}
                    render={(props) =>
                        <FavoriteProgressCard {...props} 
                                              activeCard={activeCard} 
                                              setActiveCard={setActiveCard} 
                                              onPersonSelect={onPersonSelect}
                                              personProfileUrl={lkProgressFavotiteProfile} 
                                              isInCart={isInCart}
                                              selectedSorting={selectedSorting} 
                                              setSelectedSorting={setSelectedSorting}
                                              selectedFilter={selectedFilter} 
                                              setSelectedFilter={setSelectedFilter}
                                              filterGroups={filterGroups} 
                                              sortGroups={sortGroups}
                                              altCardText={altCardText}
                                              selectedIds={getSelectedIds()}
                                              onCheckAll={onCheckAll}
                                              existScrollWrapper
                    />}
                    allowedRoles={ALLOWED_LK_ROLES}
                />
                <ProtectedRoute
                    path={lkProgressPerson.url}
                    render={(props) => <PersonProgressCard 
                        {...props} 
                        activeCard={activeCard} 
                        setActiveCard={setActiveCard} 
                        onPersonSelect={onPersonSelect}
                        isInCart={isInCart}
                        altCardText={altCardText}
                    />}
                    allowedRoles={ALLOWED_LK_ROLES}
                />
            </Switch>
            {canSelect && progressCart.persons.length !== 0 && (
                <StickyFooter>
                    <RoundedButton onClick={onCartButtonClick}>
                        Выбрать ({getPersonsNumber(progressCart)})
                    </RoundedButton>
                </StickyFooter>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        progressCart: state.progressCart,
    };
};

const actions = { setProgressCartPersons, push };

export default connect(mapStateToProps, actions)(Progress);