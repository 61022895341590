export const MENU_PROFILE = 'profile';
export const MENU_EVALUATION = 'evaluation';
export const MENU_PROGRESS = 'progress';
export const MENU_TEAM = 'team';
export const MENU_NOTES = 'notes';

export const menuItems = [
    { id: 1, link: MENU_PROFILE, text: 'Профиль' },
    { id: 2, link: MENU_EVALUATION, text: 'Оценка' },
    { id: 3, link: MENU_PROGRESS, text: 'Развитие' },
    { id: 4, link: MENU_TEAM, text: 'Команда' },
    { id: 5, link: MENU_NOTES, text: 'Заметки' },
];

export const MENU_PROFILE_PERSONAL = 'personal';
export const MENU_PROFILE_EDUCATION = 'education';
export const MENU_PROFILE_WORK_EXPERIENCE = 'workExperience';
export const MENU_PROFILE_GOVERNMENT = 'government';
export const MENU_PROFILE_LANGUAGES = 'languages';
export const MENU_PROFILE_FAMILY = 'family';
export const MENU_PROFILE_SOCIAL_NETWORKS = 'socialNetworks';
export const MENU_PROFILE_FILES = 'files';
export const MENU_PROFILE_PERSONNEL = 'personnel';
export const MENU_PROFILE_ATTRIBUTES = 'attributes';
export const MENU_PROFILE_SYSTEM_INFO = 'systemInfo';

export const profileMenuItems = [
    { id: MENU_PROFILE_PERSONAL, name: 'Личные данные', blockType: 'PersonalInfoBlock' },
    { id: MENU_PROFILE_EDUCATION, name: 'Образование', blockType: 'EducationBlock' },
    { id: MENU_PROFILE_WORK_EXPERIENCE, name: 'Опыт работы', blockType: 'WorkBlock' },
    {
        id: MENU_PROFILE_GOVERNMENT,
        name: 'Сведения о службе в вооружённых силах, органах безопасности', 
        blockType: 'GovernmentServiceBlock'
    },
    { id: MENU_PROFILE_LANGUAGES, name: 'Иностранные языки', blockType: 'LanguagesBlock' },
    { id: MENU_PROFILE_FAMILY, name: 'Семейное положение' },
    { id: MENU_PROFILE_SOCIAL_NETWORKS, name: 'Социальные сети', blockType: 'SocialNetworksBlock' },
    { id: MENU_PROFILE_FILES, name: 'Файлы' },
    { id: MENU_PROFILE_PERSONNEL, name: 'Дополнительная информация', blockType: 'PersonnelInfoBlock', additionalBlockType: 'AwardsBlock' },
    { id: MENU_PROFILE_ATTRIBUTES, name: 'Дополнительные поля', blockType: 'AttributesBlock' },
    { id: MENU_PROFILE_SYSTEM_INFO, name: 'Системная информация', blockType: 'SystemInfoBlock' },
];

export const WORK_DATE_FORMATE = 'DD MMM YYYY';

export const DATE_CONFIG = {
    dateConfig: {
        date: {
            format: 'DD',
            step: 1,
        },
        month: {
            format: 'MM',
            step: 1,
        },

        year: {
            format: 'YYYY',
            step: 1,
        },
    },
    mobileFormat:'DD.MM.YYYY',
    dateFormat: 'dd.MM.yyyy',
};

export const YEAR_DATE_CONFIG = {
    dateConfig: {
        year: {
            format: 'YYYY',
            step: 1,
        },
    },
    mobileFormat:'YYYY',
    dateFormat: 'yyyy',
};

export const internationalPhoneRegex = new RegExp(/(\+[0-9]{1,3}\s)+((\([0-9]{3}\)+[0-9]{7})|((\([0-9]{3}\)|\([0-9]{3}\)+\s|[0-9]{3}\s)+([0-9]{3}-|[0-9]{3}\s)+([0-9]{2}-|[0-9]{2}\s)+([0-9]{2}))|([0-9]{10}))$/);
