import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import WidgetMap from './mapWidgets';
import Dropdown from 'components/common/Dropdown/Dropdown';

const Widget = props => {
    const { settings, type, onEditData, onClose, size } = props;
    const { link, icon, title: widgetTitle, component: Comp } = WidgetMap[type];

    const [showMenu, setMenuState] = useState(false);
    const [isEdit, setEditState] = useState(false);
    const [title, setTitle] = useState(widgetTitle);

    const onMenuToggle = onToggle => () => {
        if (isEdit) {
            setEditState(false);
            return;
        }
        onToggle();
    };

    const onShowEdit = () => {
        setEditState(true);
        setMenuState(false);
    };

    const onDeleteWidget = () => {
        onClose();
        setMenuState(false);
    };

    const contextMenu = ({ onToggle, children }) => (
        <div className="Widget__Icon" onClick={onMenuToggle(onToggle)}>
            <FontAwesomeIcon icon={isEdit ? 'times' : 'cog'} />
            <div onClick={onToggle}>{children}</div>
        </div>
    );

    return (
        <div className="Widget">
            <div className="Widget__Header">
                <Link to={link} className="Widget__Icon">
                    <FontAwesomeIcon icon={icon} />
                </Link>
                <div className="Widget__Title">{title}</div>

                <Dropdown
                    className="Menu"
                    defaultOpen={showMenu}
                    ToggleButtonComponent={contextMenu}
                >
                    <div className="Menu__List">
                        <div className="Menu__Item" onClick={onShowEdit}>
                            Настроить
                        </div>
                        <div className="Menu__Item" onClick={onDeleteWidget}>
                            Удалить
                        </div>
                    </div>
                </Dropdown>
            </div>
            <div className="Widget__Body">
                <Comp
                    isEdit={isEdit}
                    settings={settings}
                    onEditData={onEditData}
                    setEditState={setEditState}
                    setTitle={setTitle}
                    size={size}
                />
            </div>
        </div>
    );
};

Widget.propTypes = {
    settings: PropTypes.object.isRequired,
    type: PropTypes.string,
    onEditData: PropTypes.func,
    onClose: PropTypes.func,
};

export default Widget;
