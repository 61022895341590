import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loader from 'components/common/Loader';
import photoStub from 'assets/images/icon-profile-avatar.png';
import './Image.scss';

class Image extends Component {
    state = {
        loading: true,
        error: false,
    };

    static getDerivedStateFromProps(props, state) {
        return {
            loading: props.loading || state.loading,
            error: state.error || !props.photoUri,
        };
    }

    componentDidUpdate(prevProps) {
        const { photoUri } = this.props;

        if (this.state.loading !== this.props.loading && photoUri === prevProps.photoUri) {
            this.setState({ loading: this.props.loading });
        }

        if (photoUri !== prevProps.photoUri) {
            this.setState({ loading: true });
        }
    }

    getPhotoStub = () => this.props.photoStub || photoStub;

    handleOnLoad = e => {
        this.setState({ loading: false });

        if (this.state.error) {
            this.props.onError && this.props.onError(e);
        } else {
            this.props.onLoad && this.props.onLoad(e);
        }
    };

    handleOnError = e => {
        e.target.src = this.getPhotoStub();
        this.setState({ loading: false, error: true });
    };

    handleOnClick = () => this.props.onClick && this.props.onClick();

    render() {
        const {
            className,
            style,
            alt,
            photoUri,
            showLoader,
            width = 'auto',
            heigth = 'auto',
        } = this.props;
        const { loading } = this.state;

        const classNames = classnames('Image', className);

        return (
            <>
                {loading && showLoader !== false && (
                    <Loader absolute size={this.props.size} noBackground />
                )}
                <div className={classNames} style={style} onClick={this.handleOnClick}>
                    <img
                        className={loading ? 'Image__Img Image__Img--Hidden' : 'Image__Img'}
                        alt={alt}
                        src={photoUri || this.getPhotoStub()}
                        onLoad={this.handleOnLoad}
                        onError={this.handleOnError}
                        width={width}
                        height={heigth}
                    />
                </div>
            </>
        );
    }
}

Image.propTypes = {
    photoUri: PropTypes.string,
    photoStub: PropTypes.string,
    loading: PropTypes.bool,
    alt: PropTypes.string,
    onClick: PropTypes.func,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    size: PropTypes.string,
};

export default Image;
