import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ErrorContent from './ErrorContent';
import { logUIError } from 'api';
import allRoutes from 'routes.js';
import { showErrorAlert } from 'ducks/Alert';
import { matchPath } from 'react-router';

class ErrorBoundary extends Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    logError(error, errorInfo) {
        try {
            const { location } = window;
            const route = Object.values(allRoutes).find(
                (x) =>
                    x.url === location.pathname ||
                    (matchPath(location.pathname, {
                        path: x.url,
                        exact: x.exact,
                        strict: x.strict,
                    }) &&
                        x.url.includes(':')),
            );

            const errorScope = {
                title: route?.title,
                pathName: location.pathname + location.search,
                errorTitle: error.toString(),
                errorInfo: errorInfo ? JSON.stringify(errorInfo) : null,
            };
            logUIError(errorScope);
        } catch (e) {
            this.props.showErrorAlert('Ошибка логирования');
        }
    }

    componentDidCatch(error, errorInfo) {
        this.logError(error, errorInfo);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ hasError: false });
        }
    }

    globalPromiseRejectionHandler = (event) => {
        this.setState({ hasError: true });
        this.logError(event.type, event.reason.stack);
    };

    globalErrorHandler = (message, url, line, column, error) => {
        this.setState({ hasError: true });
        this.logError(message, error.stack);
    };

    componentDidMount() {
        window.onunhandledrejection = this.globalPromiseRejectionHandler;
        window.onerror = this.globalErrorHandler;
    }

    render() {
        if (this.state.hasError) {
            return <ErrorContent />;
        }

        return this.props.children;
    }
}

export default withRouter(connect(null, { showErrorAlert })(ErrorBoundary));
