import React from 'react';
import { getUserShortName, getUserFullName } from 'utils';
import EvaluationPerson from './EvaluationPerson';
import EvaluationPersonData from './EvaluationPerson/EvaluationPersonData';
import EvaluationPersonPercent from './EvaluationPerson/EvaluationPersonPercent';
import EvaluationCardItemBlock from './EvaluationCardItemBlock';
import NoDataBlock from 'components/Lk/Common/NoDataBlock';
import EvaluationPersonDecimal from './EvaluationPerson/EvaluationPersonDecimal';
import { USER_ROLES } from 'constants.js';

const RenderPerson = props => {
    let {
        checkbox = true,
        tests = false,
        isUser,
        userId,
        noDataText,
        onChange,
        onClick,
        onImageClick,
        dataTest,
        littleMargin = false,
    } = props;

    let content = null;
    let num = null;

    return !props.data ? null : props.data.length !== 0 ? (
        props.data.map(item => {
            
            let position = item.currentPosition || item.currentJobTitle;

            if (userId && item.userId === null) {
                position = 'Пользователь не зарегистрирован в системе';
            }

            let roles = [];
            if (item.roles) {
                roles = item.roles?.map(name => USER_ROLES.find(i => i.code === name)?.name) || [];
            } else if (item.roleIds) {
                roles = item.roleIds?.map(id => USER_ROLES.find(i => i.id === id)?.name) || [];
            }

            if (tests) {
                num = item.percent.find(
                    x => x.testId === tests.testId && x.criterionId === tests.criteriaId,
                );

                if (!!num && num.show && num.show === 'decimalValue') {
                    const value = num && num.decimalValue !== undefined ? num.decimalValue : null;
                    const normalizedValue =
                        num && num.normalizedValue !== undefined ? num.normalizedValue : null;

                    content =
                        value !== null ? (
                            <EvaluationPersonDecimal
                                value={value}
                                name={getUserShortName(item)}
                                normalizedValue={normalizedValue}
                            />
                        ) : (
                            <EvaluationPersonPercent name={getUserShortName(item)} />
                        );
                } else {
                    const value =
                        num && num.normalizedValue !== undefined
                            ? Math.floor(num.normalizedValue * 100)
                            : null;

                    content =
                        value !== null ? (
                            <EvaluationPersonPercent value={value} name={getUserShortName(item)} />
                        ) : (
                            <EvaluationPersonPercent name={getUserShortName(item)} />
                        );
                }
            } else {
                content = (
                    <EvaluationPersonData
                        data={getUserShortName(item)}
                        position={position}
                        roles={isUser ? roles : []}
                    />
                );
            }

            if (userId && item.userId === null) {
                return (
                    <EvaluationCardItemBlock key={`u_${item.id}`} shadow littleMargin={littleMargin}>
                        <EvaluationPerson
                            basket={props.basket}
                            item={item}
                            alt={getUserFullName(item)}
                            checkbox={false}
                            dataTest={dataTest}
                        >
                            {content}
                        </EvaluationPerson>
                    </EvaluationCardItemBlock>
                );
            }

            return (
                <EvaluationCardItemBlock
                    key={userId ? item.userId : item.id}
                    shadow
                    littleMargin={littleMargin}
                >
                    <EvaluationPerson
                        basket={props.basket}
                        {...props}
                        onChange={onChange}
                        onClick={onClick}
                        onImageClick={onImageClick}
                        item={item}
                        isUser={userId}
                        alt={getUserFullName(item)}
                        checkbox={checkbox}
                    >
                        {content}
                    </EvaluationPerson>
                </EvaluationCardItemBlock>
            );
        })
    ) : (
        <NoDataBlock text={noDataText} />
    );
};

export default RenderPerson;
