import { appName, ROLES_DICTIONARY_NAME, GROUPS_DICTIONARY_NAME } from '../constants';
import { searchUsers, resetUserPassword, blockUserAccount } from 'api';
import { takeLatest, takeEvery, put, call, all } from 'redux-saga/effects';
import RequestError from '../RequestError';
import { showSuccessAlert, showErrorAlert } from './Alert';
import { show as showAcceptModal } from './AcceptModal';
import { fetchDictionaries } from './Dictionary';
import { getUserFullName } from 'utils';
import { setIsDisabled, setIsLocked, fetchUser } from './User';
import { withPageLoader } from './PageLoader';
import { serviceResultCode } from "serviceErrors";

const moduleName = 'users';
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;
export const CHANGE_CRITERIA = `${appName}/${moduleName}/CHANGE_CRITERIA`;
export const RESET_PASSWORD = `${appName}/${moduleName}/RESET_PASSWORD`;
export const SEND_RESET_PASSWORD = `${appName}/${moduleName}/SEND_RESET_PASSWORD`;
export const BLOCK_USER = `${appName}/${moduleName}/BLOCK_USER`;
export const SEND_BLOCK_USER = `${appName}/${moduleName}/SEND_BLOCK_USER`;

export const allUserDictionaries = [ROLES_DICTIONARY_NAME, GROUPS_DICTIONARY_NAME];

const initialState = {
    loading: false,
    loadComplete: false,
    loadTime: new Date(0),
    data: {
        payload: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
    error: '',
    criteria: {
        filter: {
            lastName: "",
            firstName: "",
            patronymic: "",
            roles: [],
            groups: [],
            showDisabled: false
        },
        sorted: [{ id: "fullName", desc: false }],
    },
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;

    switch (type) {
        case FETCH_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_FAILED:
            return {
                ...state,
                loadComplete: true,
                loadTime: payload.loadTime,
                error: error.message,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                loadComplete: true,
                loadTime: payload.loadTime,
                data: payload.data,
            };
        case CHANGE_CRITERIA:
            return {
                ...state,
                criteria: {
                    [payload.key]: payload.value,
                },
            };
        default:
            return state;
    }
}

export const fetchUsers = (criteria) => {
    return {
        type: FETCH_REQUEST,
        payload: { criteria },
    }
};

export const fetchStart = () => {
    return {
        type: FETCH_START,
    }
};

export const fetchSuccess = (data, loadTime) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data, loadTime },
    }
};

export const fetchFailed = (error, loadTime) => {
    return {
        type: FETCH_FAILED,
        payload: { loadTime },
        error,
    }
};

export const changeCriteria = (key, value) => {
    return {
        type: CHANGE_CRITERIA,
        payload: { key, value },
    };
};

export const resetPassword = (user) => {
    return {
        type: RESET_PASSWORD,
        payload: user,
    };
};

export const blockUser = (user) => {
    return {
        type: BLOCK_USER,
        payload: user,
    };
};

function* fetchUsersDictionariesSaga() {
    yield put(fetchDictionaries(allUserDictionaries));
}

export const fetchUsersSaga = function* (action) {
    yield call(fetchUsersDictionariesSaga);

    yield put(fetchStart());

    const { criteria } = action.payload;
    try {
        const response = yield call(withPageLoader, () => searchUsers(criteria));
        const usersData = response.data;
        yield put(fetchSuccess(usersData, new Date()));
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке пользователей произошла ошибка');
        yield all([
            put(fetchFailed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
};

export const sendResetPassword = function* (action) {
    const { value: user } = action.payload;

    if (!user) {
        return;
    }

    try {
        yield call(resetUserPassword, user.id);
        yield put(showSuccessAlert(`Пользователю "${user.fullName}" отправлена ссылка на почту, для изменения пароля`));
    } catch (error) {
        const reqError = new RequestError(error, `При отправке пользователю "${user.fullName}" ссылки на почту, для изменения пароля произошла ошибка`);
        yield put(showErrorAlert(reqError.message));
    }
};

export const showAcceptForResetPassword = function* (action) {
    const user = action.payload;
    const title = "Подтверждение изменения пароля";
    const question = `Отправить пользователю "${user.fullName}" ссылку на почту для изменения пароля?`;
    const actions = [
        { title: "Отменить", value: null, color: "secondary" },
        { title: "Отправить", value: user, color: "primary" },
    ];

    yield put(showAcceptModal(title, question, actions, SEND_RESET_PASSWORD));
};

export const sendBlockUser = function* (action) {
    const { value: user } = action.payload;

    if (!user) {
        return;
    }

    let blockName = {
        title: user.isDisabled || user.isLocked ? "разблокирован" : "заблокирован",
        error: user.isDisabled || user.isLocked ? "разблокировке" : "блокировке",
    };

    try {
        if (user.isLocked) {
            yield call(blockUserAccount, user.id, false);
            yield put(setIsLocked(false));
            yield put(fetchUser(user.id));
        } else {
            yield call(blockUserAccount, user.id, !user.isDisabled);
            yield put(setIsDisabled(!user.isDisabled));
        }
        yield put(showSuccessAlert(`Пользователь "${getUserFullName(user)}" был успешно ${blockName.title}`));
    } catch (error) {
        const errorMsg = getBlockUserError(error.response.data.code, blockName, user);
        yield put(showErrorAlert(errorMsg));
    }
};

const getBlockUserError = (code, blockName, user) => {
    switch (code) {
        case serviceResultCode.UserRightsInvalidReduce:
            return `Суперадмин не может заблокировать себя.`;
        case serviceResultCode.UserIdentityErrors:
            return `При ${blockName.error} пользователя "${getUserFullName(user)}" произошла ошибка`;
        default:
            return `При ${blockName.error} пользователя "${getUserFullName(user)}" произошла ошибка`;
    }
};

export const showAcceptForBlockUser = function* (action) {
    const user = action.payload;

    let blockName = {
        title: user.isDisabled || user.isLocked ? "разблокировку" : "блокировку",
        question: user.isDisabled || user.isLocked ? "разблокировать" : "заблокировать",
        button: user.isDisabled || user.isLocked ? "Разблокировать" : "Заблокировать"
    };

    const title = `Подтвердите ${blockName.title} пользователя`;
    const question = `Вы действительно хотите ${blockName.question} пользователя "${getUserFullName(user)}"?`;
    const actions = [
        { title: blockName.button, value: user, color: "primary" },
        { title: "Отменить", value: null, color: "secondary" },
    ];

    yield put(showAcceptModal(title, question, actions, SEND_BLOCK_USER));
};

export const isLockedOut = (lockoutEnd, isDisabled) => {
    if (lockoutEnd === null || lockoutEnd === undefined) { return false; }
    const dateInUTCms = Date.parse(lockoutEnd);

    if (Number.isNaN(dateInUTCms)) { return false; }
    if (isDisabled) { return false; }

    return (((Date.now() - dateInUTCms) / (1000 * 60)) < 0);
};

export const saga = function* () {
    yield all([
        takeLatest(FETCH_REQUEST, fetchUsersSaga),
        takeEvery(RESET_PASSWORD, showAcceptForResetPassword),
        takeEvery(SEND_RESET_PASSWORD, sendResetPassword),
        takeEvery(BLOCK_USER, showAcceptForBlockUser),
        takeEvery(SEND_BLOCK_USER, sendBlockUser),
    ]);
};

export const usersSelector = (state) => {
    let { users } = state;

    if (!users.loadComplete) {
        return { loadComplete: false };
    }

    return {
        loadComplete: true,
        data: users.data,
        criteria: users.criteria,
    }
};
