export const appName = "human-reserve";
export const yearMask = "9999";
export const phoneMask = "+7 (999) 999-99-99";
export const phoneMaskWithoutRegion = "(999) 999-99-99";
export const snilsMask = "999-999-999-99";

export const federalCities = [
  "0C5B2444-70A0-4932-980C-B4DC0D3F02B5",
  "C2DEB16A-0330-4F05-821F-1D09C93331E6",
  "6FDECB78-893A-4E3F-A5BA-AA062459463B"
];

// документы
export const defaultDocumentCode = "21"; // Паспорт
export const defaultForeignDocumentCode = "91"; // Другое

// поля для валидации в блоках "Опыт работы" в карточке создания/редактирования резервиста
export const fieldsToValidateCurrentWork = [
  "company",
  "position",
  "industry",
  "workArea",
  "managementExperience",
  "employeesNumber",
  "hireDate"
];
export const fieldsToValidatePastWork = ["company", "position", "hireDate", "quitDate"];

// поля для валидации в блоках "Сведения о службе..." в карточке создания/редактирования резервиста
export const fieldsToValidateCurrentGovernmentService = [
  "governmentServiceKind",
  "startDate"
];
export const fieldsToValidatePastGovernmentService = [
  ...fieldsToValidateCurrentGovernmentService,
  "endDate"
];

// поля для валидации в блоках "Результаты оценки" в карточке создания/редактирования резервиста
export const fieldsToValidateEvaluation = ["kind", "value", "date"];

// url parts for person block updates
export const personBlocksUrls = {
  PersonalInfoBlock: "updateGeneralInfo",
  EducationBlock: "UpdateEducation",
  WorkBlock: "UpdateJob",
  LanguagesBlock: "UpdateLanguage",
  SocialNetworksBlock: "UpdateSocialNetwork",
  FamilyStatusBlock: "updateGeneralInfo",
  AttributesBlock: "updateCustomAttributes",
  PersonnelInfoBlock: "updatePersonnel",
  GovernmentServiceBlock: "updateGovernmentServices",
  EvaluationBlock: "updateEvaluations",
  SystemInfoBlock: "updateGeneralInfo",
  AwardsBlock: "updateAwards",
  FilesBlock: "UpdateFiles",
  CuratorBlock: "updateCurator",
  PersonCommentsBlock: "comments"
};

export const groupType = {
  Default: {id: "Default", value: "Базовая"},
  Scope: {id: "Scope", value: "Транзитная"}
};

export const fieldsToValidateEducation = ["educationLevel"];

export const USERS_DICTIONARY_NAME = "users";
export const ROLES_DICTIONARY_NAME = "roles";
export const GROUPS_DICTIONARY_NAME = "groups";

export const PERESELECTION_EVALUATE_BASKET_NAME = "preselectionEvaluate";
export const EVALUATE_BASKET_NAME = "evaluate";
export const PERESELECTION_NEW_ISSUE_BASKET_NAME = "preselectionNewIssue";
export const NEW_ISSUE_BASKET_NAME = "newIssue";

export const ROLE_IT_ADMIN = "ITAdmin";
export const ROLE_SECURITY_ADMIN = "SecurityAdmin";
export const ROLE_LEADER = "Leader";
export const ROLE_MODERATOR = "Moderator";
export const ROLE_OPERATOR = "Operator";
export const ROLE_USER = "User";
export const ROLE_INSTRUCTION_EDITOR = "InstructionEditor";
export const ROLE_CONTENT_EDITOR = "ContentEditor";
export const ROLE_REPORT_EDITOR = "ReportEditor";
export const ROLE_SUPPORT_LINE1 = "SupportLine1";
export const ROLE_SUPPORT_LINE2 = "SupportLine2";

export const USER_INTERFACE_LEADER = "Leader";
export const USER_INTERFACE_MODERATOR = "Moderator";
export const USER_INTERFACE_ADMIN = "Admin";

export const ROLE_IT_ADMIN_ID = 1;
export const ROLE_MODERATOR_ID = 2;
export const ROLE_OPERATOR_ID = 3;
export const ROLE_USER_ID = 4;
export const ROLE_SECURITY_ADMIN_ID = 5;
export const ROLE_LEADER_ID = 6;
export const ROLE_INSTRUCTION_EDITOR_ID = 7;
export const ROLE_CONTENT_EDITOR_ID = 8;
export const ROLE_REPORT_EDITOR_ID = 11;
export const ROLE_SUPPORT_LINE1_ID = 9;
export const ROLE_SUPPORT_LINE2_ID = 10;

export const GROUP_ROOT_ID = 1;

//Edit user roles selection
export const USER_ROLES = [
  { id: ROLE_IT_ADMIN_ID, code: ROLE_IT_ADMIN, name: "Администратор ИТ", isActive: false },
  { id: ROLE_MODERATOR_ID, code: ROLE_MODERATOR, name: "Региональный координатор", isActive: true },
  { id: ROLE_OPERATOR_ID, code: ROLE_OPERATOR, name: "Эксперт по кадрам", isActive: true },
  { id: ROLE_USER_ID, code: ROLE_USER, name: "Пользователь", isActive: false },
  { id: ROLE_SECURITY_ADMIN_ID, code: ROLE_SECURITY_ADMIN, name: "Администратор ИБ", isActive: false },
  { id: ROLE_LEADER_ID, code: ROLE_LEADER, name: "Руководитель", isActive: true },
  { id: ROLE_INSTRUCTION_EDITOR_ID, code: ROLE_INSTRUCTION_EDITOR, name: "Редактор инструкций пользователя", isActive: true },
  { id: ROLE_CONTENT_EDITOR_ID, code: ROLE_CONTENT_EDITOR, name: "Редактор контента", isActive: false },
  { id: ROLE_REPORT_EDITOR_ID, code: ROLE_REPORT_EDITOR, name: "Редактор отчетов", isActive: false },
  { id: ROLE_SUPPORT_LINE1_ID, code: ROLE_SUPPORT_LINE1, name: "ТП Линия 1", isActive: false },
  { id: ROLE_SUPPORT_LINE2_ID, code: ROLE_SUPPORT_LINE2, name: "ТП Линия 2", isActive: false }
];

export const MAX_BYTES_PER_FILE = 104857600;
export const MAX_BYTES_PER_PHOTO_FILE = 10485760;
export const MAX_UPLOAD_FILES = 5;
export const MAX_BYTES_PER_LANG_FILE = 104857600;

export const API_STATUS_OK = 200;
export const API_STATUS_NOT_FOUND = 404;

// error types

export const NOT_SUPPORTED = 'notSupported';
export const TOO_LARGE = 'tooLarge';

export const INT_32_MAX_VALUE = 2147483647;

// Типы визуализации тестов
export const chartTypes = [
  { id: 0, code: "Signals", name: "Светофор" },
  { id: 1, code: "List", name: "Список" },
  { id: 2, code: "Web", name: "Паутинка" },
  { id: 3, code: "Gradient", name: "Градиент" }
];

// Статусы тестов
export const testStatuses = [
  { id: 0, code: "External", name: "Тестирование вне системы" },
  { id: 1, code: "Internal", name: "Тестирование в ИСКРе" },
];

export const CRITERIA_TYPE_SCALE = "Scale";
export const CRITERIA_TYPE_GRADE = "Grade";
export const CRITERIA_TYPE_OTHER_STRING = "OtherString";
export const CRITERIA_TYPE_OTHER_INTEGER = "OtherInteger";

// Типы критериев теста
export const testCriteriaType = [
  { id: 0, code: CRITERIA_TYPE_SCALE, name: "Числовой" },
  { id: 1, code: CRITERIA_TYPE_GRADE, name: "Буквенный" },
];

//Статусы действий пользователя
export const userActionStatusName = [
  { value: "Info", name: "Информация", class: "UserActions__Status--info" },
  { value: "Warning", name: "Предупреждение", class: "UserActions__Status--warning" },
  { value: "Error", name: "Ошибка", class: "UserActions__Status--danger" }
];

//Тип действий пользователя
export const userActionTypeName = [
  { value: "Action", name: "Действия на странице" },
  { value: "Page", name: "Переход на страницу" },
  { value: "Error", name: "Ошибка клиента" }
];

export const TEST_GROUPS_CODE = {
  MOTIVATION_AND_DESTRUCTION:'MotivationAndDestructors',
  BEHAVIOR:'Behavior'
}

// Группы тестов
export const testGroups = [
  { id: 1, code: "Potential", name: "Потенциал" },
  { id: 2, code: "Competency", name: "Компетенции" },
  { id: 3, code: "Abilities", name: "Способности" },
  { id: 4, code: TEST_GROUPS_CODE.BEHAVIOR, name: "Личностные характеристики" },
  { id: 5, code: TEST_GROUPS_CODE.MOTIVATION_AND_DESTRUCTION, name: "Мотивация и деструкторы" },
  { id: 6, code: "ManagementPotential", name: "Управленческий потенциал" },
];

export const testsReport=[
  { id: 1, value: "Potential", label: "Поведенческий тип" },
  { id: 2, value: "Competency", label: "Карьерные деструкторы" },
  { id: 3, value: "Abilities", label: "Карьерная мотивация" },
]

//Размеры экранов для react-media
export const mediaScreenSizes = {
  xxsmall: `(max-width: 375px)`,
  xsmall: `(min-width: 376px) and (max-width: 575px)`,
  small: `(min-width: 576px) and (max-width: 767px)`,
  medium: `(min-width: 768px) and (max-width: 991px)`,
  large: `(min-width: 992px) and (max-width: 1199px)`,
  xlarge: `(min-width: 1200px) and (max-width: 1701px)`,
  xxlarge: `(min-width: 1702px)`,
};

export const mediaScreenMinThresholds = {
  xsmall: `(min-width: 376px)`,
  small: `(min-width: 576px)`,
  medium: `(min-width: 768px)`,
  large: `(min-width: 992px)`,
  xlarge: `(min-width: 1200px)`,
  xxlarge: `(min-width: 1702px)`,
};

export const leaderMenuItems = [
  { id: "home", name: "Главная", link: "/", isDefault: true },
  { id: "search", name: "Поиск", link: "/search" },
  { id: "charts", name: "Аналитика", link: "/analytics" },
  { id: "evaluation", name: "Оценка", link: "/underconstruction" },
  { id: "progress", name: "Развитие", link: "/underconstruction" },
  { id: "favorite", name: "Избранное", link: "/underconstruction" },
  { id: "kpi", name: "КПЭ", link: "/underconstruction" },
  { id: "notification", name: "Уведомления", link: "/underconstruction" },
  { id: "team", name: "Моя команда", link: "/profile/teams" },
  { id: "region", name: "Мой регион", link: "/underconstruction" },
  { id: "tasks", name: "Задачи и поручения", link: "/underconstruction" },
  { id: "library", name: "Библиотека решений", link: "/underconstruction" },
  { id: "profile", name: "Обо мне", link: "/profile" },
];

export const accessSelectOptions = [
  { value: ROLE_LEADER, label: 'Руководитель' },
  { value: ROLE_OPERATOR, label: 'Эксперт по кадрам' },
  { value: ROLE_USER, label: 'Пользователь' },
  { value: ROLE_INSTRUCTION_EDITOR, label: 'Редактор инструкций пользователя' },
];


export const ACCESS_STATUS = {
  APPROVED_BY_IT: 'ApprovedByIT',
  WAITING_FOR_REVIEW: 'WaitingForReview',
  APPROVED_BY_SECURITY: 'ApprovedBySecurity',
  REJECTED_BY_IT: 'RejectedByIT',
  REJECTED_BY_SECURITY: 'RejectedBySecurity',
  NO_ONGOING_ACCESS_REQUEST: 'NoOngoingAccessRequest',
  ACCESS_REQUEST_PENDING: 'AccessRequestPending',
  USER_CREATION_IN_PROGRESS: 'UserCreationInProgress',
  USER_CREATED_OR_LINKED: 'UserCreatedOrLinked',
  FAILED_TO_CREATE_USER: 'FailedToCreateUser',
  CANCELLED: 'Cancelled',
};

export const ACCESS_STATUS_TEXT = {
  'ApprovedByIT': "Ожидает согласования Администратором ИБ",
  'WaitingForReview': "Ожидает согласования Администратором ИТ",
  'ApprovedBySecurity': "Согласовано",
  'RejectedByIT': "Отклонено",
  'RejectedBySecurity': "Отклонено",
  'NoOngoingAccessRequest': "Нет активного запроса",
  'AccessRequestPending': "На согласовании",
  'UserCreationInProgress': "Пользователь создается",
  'UserCreatedOrLinked': "Пользователь создан",
  'FailedToCreateUser': "Ошибка при создании пользователя",
  'Cancelled': "Отменено",
};

export const ACCESS_STATUS_DICT = [
  {id: ACCESS_STATUS.APPROVED_BY_IT, name: 'Ожидает согласования Администратором ИБ'},
  {id: ACCESS_STATUS.WAITING_FOR_REVIEW, name: 'Ожидает согласования Администратором ИТ'},
  {id: ACCESS_STATUS.APPROVED_BY_SECURITY, name: 'Согласовано'},
  {id: ACCESS_STATUS.REJECTED_BY_IT, name: 'Отклонено'},
  {id: ACCESS_STATUS.REJECTED_BY_SECURITY, name: 'Отклонено'},
  {id: ACCESS_STATUS.NO_ONGOING_ACCESS_REQUEST, name: 'Нет активного запроса'},
  {id: ACCESS_STATUS.ACCESS_REQUEST_PENDING, name: 'На согласовании'},
  {id: ACCESS_STATUS.USER_CREATION_IN_PROGRESS, name: 'Пользователь создается'},
  {id: ACCESS_STATUS.USER_CREATED_OR_LINKED, name:'Пользователь создан'},
  {id: ACCESS_STATUS.FAILED_TO_CREATE_USER, name: 'Ошибка при создании пользователя'},
  {id: ACCESS_STATUS.CANCELLED, name: 'Отменено'},
];

export const ACCESS_DIRECTION = { 
  ADD_ACCESS: 'AddAccess',
  REMOVE_ACCESS: 'RemoveAccess',
};

export const ACCESS_DIRECTION_TYPE = [ 
  {id: 'AddAccess', name: 'Добавить роль'},
  {id: 'RemoveAccess', name: 'Удалить роль'},
];

export const resourceTypes = [
  { id: 1, name: "Видео", type: "Video" },
  { id: 2, name: "Книги", type: "Book" },
  { id: 3, name: "Статьи", type: "File" },
  { id: 4, name: "Микрокурсы", type: "Microcourse", hidden: true },
  { id: 5, name: "Курсы", type: "CourseScormTincan" },
  { id: 6, name: "Аудиокниги", type: "AudioBook" },
  { id: 7, name: "Ссылки", type: "Link" },
];

export const resourceTypeEnum = {
  Video: 'Video',
  Book: 'Book',
  File: 'File',
  Microcourse: 'Microcourse',
  CourseScormTincan: 'CourseScormTincan',
  AudioBook: 'AudioBook',
  Link: 'Link',
};

export const educationTypes = [
  { id: 1, name: "Обучение по компетенциям", type: "ToCompetency" },
  { id: 2, name: "Профессиональное обучение", type: "Professional" },
];

export const tagTypes = [
  { id: 1, name: 'Модель компетенций', type: false },
  { id: 2, name: 'Технический', type: true },
];

export const approveStatuses = [
  { id: 1, status: 'New', label: 'Отправлено на согласование' },
  { id: 2, status: 'InProgress', label: 'Принято к рассмотрению' },
  { id: 3, status: 'ApproveNotRequired', label: 'Согласование не требуется' },
  { id: 4, status: 'Approved', label: 'Согласовано' },
  { id: 5, status: 'CommentsMade', label: 'Предоставлены замечания' },
  { id: 6, status: 'Cancelled', label: 'Отменено' },
  { id: 7, status: 'Resubmitted', label: 'Предоставлено на согласование повторно' },
  { id: 8, status: 'Delegated', label: 'Делегировано' }
];

export const WIDGET_TYPE_TEAM = 'Team';

export const WIDGET_SIZE_SMALL = 3;
export const WIDGET_SIZE_MEDIUM = 6;
export const WIDGET_SIZE_LARGE = 12;

export const WIDGET_SIZE = {
  [WIDGET_SIZE_SMALL]: 'sm',
  [WIDGET_SIZE_MEDIUM]: 'md',
  [WIDGET_SIZE_LARGE]: 'lg',
};

export const availableWidgets = [{ type: WIDGET_TYPE_TEAM, name: 'Команда', id: 1 }]

export const EMPLOYEE_LEVEL_DICT = [
  { value: 'LinearStaff', label: 'Линейный персонал' },
  { value: 'Manager', label: 'Управленец' },
  { value: 'TopManager', label: 'Высший управленец' },
];

export const GEO_LEVEL_DICT = [
  { value: 'Federal', label: 'Федеральный' },
  { value: 'Regional', label: 'Региональный' },
  { value: 'Municipal', label: 'Муниципальный' },
];

export const ISSUE_TYPE_ENUM = {
  Default: 'Default',
  ChangePersonGrowthPlan: 'ChangePersonGrowthPlan',
  ApprovePersonGrowthPlan: 'ApprovePersonGrowthPlan',
};

export const PERSON_COMMENT_TYPE_ENUM = {
  Person: 'Person',
  Wishlist: 'WishList'
};

export const PERSON_COMMENT_VISIBILITY_ENUM = {
  Everyone: 'Everyone',
  User: 'User',
  Group: 'Group'
};

export const ISSUE_ATTRIBUTE_TYPES = {
  UserId: 'UserId',
  PersonName: 'PersonName',
  PersonId: 'PersonId',
  PersonGrowthPlanId: 'PersonGrowthPlanId',
  TestName: 'TestName',
};

export const ISSUE_TYPE = [
  { value: 'Default', label: 'Задача' },
  { value: 'SendToEvaluation', label: 'Оценка' },
  { value: 'MakePersonGrowthPlan', label: 'Создать ИПР' },
  { value: 'ChangePersonGrowthPlan', label: 'Изменить ИПР' },
  { value: 'ApprovePersonGrowthPlan', label: 'Согласовать ИПР' },
];

export const ISSUE_STATUS_ENUM = {
  New: 'New',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Canceled: 'Canceled',
  Deleted: 'Deleted',
  Closed: 'Closed',
  Draft: 'Draft',
};

export const ISSUE_HISTORY_RECORD_TYPES = {
  Created: 'Created',
  Edited: 'Edited', 
  Deferred: 'Deferred',
  Completed: 'Completed',
  Canceled: 'Canceled',
  Deleted: 'Deleted',
  StatusUpdated: 'StatusUpdated'
};

export const ISSUE_PROPERTIES = {
  DueDate: 'DueDate',
  Status: 'Status',
  Description: 'Description',
  Title: 'Title',
  RemoveAssignees: 'RemoveAssignees',
  AddAssignees: 'AddAssignees',
  AddFiles:'AddFiles',
  RemoveFiles:'RemoveFiles'
};

export const BIRTH_DATE_DISPLAY_MODES = {
  FullDate: 'FullDate',
  Month: 'Month',
  Year: 'Year'
};

export const ISSUE_STATUS = [
  { value: 'New', label: 'Новая' },
  { value: 'InProgress', label: 'В работе' },
  { value: 'Completed', label: 'Выполнена' },
  { value: 'Canceled', label: 'Отменена' },
  { value: 'Deleted', label: 'Удалена' },
  { value: 'Closed', label: 'Закрыта', hide: true },
];

export const ISSUE_STATUS_LEADER = [
  { value: 'New', label: 'Ожидает выполнения' },
  { value: 'InProgress', label: 'В работе' },
  { value: 'Completed', label: 'Выполнена' },
  { value: 'Canceled', label: 'Отменена' },
  { value: 'Deleted', label: 'Удалена' },
];

export const NEWS_STATUS_ENUM = {
  Draft: 'Draft',
  Published: 'Published',
  Deleted: 'Deleted',
};

export const NEWS_STATUS = [
  { value: 'Draft', label: 'Черновик' },
  { value: 'Published', label: 'Опубликована' },
];

export const CONTENT_STATUS_ENUM = {
  Draft: 'Draft',
  Published: 'Published',
  Deleted: 'Deleted',
};

export const CONTENT_STATUS = [
  { value: 'Draft', label: 'Черновик' },
  { value: 'Published', label: 'Опубликован' },
];

export const PERSONGROWTHPLAN_STATUS = {
  Process: 'Process',
  Completed: 'Completed',
  Draft: 'Draft',
};

export const PERSONGROWTHPLAN_TYPE = {
  Full: 'Full',
  Short: 'Short',
};

export const SOURCE_TYPE = {
  Alpina:'Alpina',
  Default: 'Default'
};

export const EVALUATIONTEST_CRITERIONRESULT_STATUS = {
  Relevant: 'Relevant',
  Archived: 'Archived',
  Unknown: 'Unknown'
};

export const EVALUATIONTEST_STATUS = {
  Relevant: 'Relevant',
  Archived: 'Archived',
  Unknown: 'Unknown'
};

export const snilsRegex = new RegExp(/\d{3}-?\d{3}-?\d{3}-?\d{2}/);
export const internationalPhoneRegex = new RegExp(/(\+[0-9]{1,3}\s)+((\([0-9]{3}\)+[0-9]{7})|((\([0-9]{3}\)|\([0-9]{3}\)+\s|[0-9]{3}\s)+([0-9]{3}-|[0-9]{3}\s)+([0-9]{2}-|[0-9]{2}\s)+([0-9]{2}))|([0-9]{10}))$/);
export const phoneRegex = new RegExp(/(\+7|7|8|\+7\s|7\s|8\s)+((\([0-9]{3}\)+[0-9]{7})|((\([0-9]{3}\)|\([0-9]{3}\)+\s|[0-9]{3}\s)+([0-9]{3}-|[0-9]{3}\s)+([0-9]{2}-|[0-9]{2}\s)+([0-9]{2}))|([0-9]{10}))$/);
export const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.\-––]+(\.+[a-zA-Z0-9]{1,20})$/)
export const urlRegex = new RegExp(/((https?|ftp):\/\/)([a-z0-9])((\.[a-z0-9-])|([a-z0-9-]))*\.([a-z])(\/?)\S[^()]*/);
export const shortUrlRegex = new RegExp(/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)$/);

export const TEMPLATES_TYPES_ENUM = [
  { value: 'EvaluationRequest', label: 'Уведомление о направлении резервиста на оценку' },
  { value: 'EvaluationRequestConfirmation', label: 'Уведомление о согласовании запроса на направление резервиста на оценку' },
  { value: 'EvaluationRequestRejection', label: 'Уведомление об отклонении запроса на направление резервиста на оценку' },
  { value: 'DirectionForEvaluation', label: 'Уведомление о необходимости пройти тестирование' },
  { value: 'DirectionForEvaluationReceived', label: 'Уведомление о получении резервистом уведомления о необходимости пройти тестирование' },
  { value: 'DirectionForEvaluationOpened', label: 'Уведомление о прочтении резервистом уведомления о необходимости пройти тестирование' },
  { value: 'DirectionForEvaluationDeliveryError', label: 'Уведомление об ошибке доставки уведомления о необходимости пройти тестирование' },
  { value: 'EvaluationTestPassed', label: 'Уведомление о прохождении теста резервистом' },
  { value: 'DirectionForEvaluationReminder', label: 'Уведомление о необходимости пройти тестирование - напоминание' },

  { value: 'IssueCreate', label: 'Уведомление о создании задачи' },
  { value: 'IssueUpdate', label: 'Уведомление об обновлении задачи' },
  { value: 'IssueChangeStatus', label: 'Уведомление о смене статуса задачи' },
  { value: 'DirectionForGrowthPlan', label: 'Уведомление о направлении на ИПР' },
];

export const EVALUATION_TYPE_CAREER = 'c26b649d-f64f-7764-24ba-35f72f57e6f7'

export const SHORT_TEXT = [
  { value: 'способности к абстрактному мышлению', short: 'Абстрактное мышление' },

];

export const visibilityFilterOptions = [
  { id: 0, name: 'Все', type: '' },
  { id: 1, name: 'Общая', type: 'Everyone' },
  { id: 2, name: 'Групповая', type: 'Group' },
  { id: 3, name: 'Персональная', type: 'User' },
];

export const EVALUATION_REQUEST_STATUS = [
  {id: 'New', name: 'Новая'},
  {id: 'Approved', name: 'Согласована'},
  {id: 'Rejected', name: 'Отклонена'},
  {id: 'Confirmed', name: 'Подтверждена'},
  {id: 'Expired', name: 'Период действия истек'},
  {id: 'Finished', name: 'Выполнена'},
];

export const CAMPAIGN_STATUS = {
  Active: {id: 'Active', name: 'Новая', value: 1},
  Sent: {id: 'Sent', name: 'Отправлено', value: 2},
};

export const SUPPORT_STATUS = [
  { id: 'Default', name: 'Стандартный' },
  { id: 'Vip', name: 'Особый' },
];

export const PASSWORD_LENGTH_MINIMUM = 12;

export const dialCode = [
  { name: '+7 (Россия)', id: '+7' },
  { name: '+93 (Афганистан)', id: '+93' },
  { name: '+355 (Албания)', id: '+355' },
  { name: '+213 (Алжир)', id: '+213' },
  { name: '+376 (Андорра)', id: '+376' },
  { name: '+244 (Ангола)', id: '+244' },
  { name: '+672 (Антарктика)', id: '+672' },
  { name: '+54 (Аргентина)', id: '+54' },
  { name: '+374 (Армения)', id: '+374' },
  { name: '+297 (Аруба)', id: '+297' },
  { name: '+61 (Австралия)', id: '+61' },
  { name: '+43 (Австрия)', id: '+43' },
  { name: '+994 (Азербайджан)', id: '+994' },
  { name: '+973 (Бахрейн)', id: '+973' },
  { name: '+880 (Бангладеш)', id: '+880' },
  { name: '+375 (Беларусь)', id: '+375' },
  { name: '+32 (Бельгия)', id: '+32' },
  { name: '+501 (Белиз)', id: '+501' },
  { name: '+229 (Бенин)', id: '+229' },
  { name: '+975 (Бутан)', id: '+975' },
  { name: '+591 (Боливия)', id: '+591' },
  { name: '+387 (Босния и Герцеговина)', id: '+387' },
  { name: '+267 (Ботсвана)', id: '+267' },
  { name: '+55 (Бразилия)', id: '+55' },
  { name: '+246 (Британская территория в Индийском океане)', id: '+246' },
  { name: '+673 (Бруней)', id: '+673' },
  { name: '+359 (Болгария)', id: '+359' },
  { name: '+226 (Буркина-Фасо)', id: '+226' },
  { name: '+257 (Бурунди)', id: '+257' },
  { name: '+855 (Камбоджа)', id: '+855' },
  { name: '+237 (Камерун)', id: '+237' },
  { name: '+1 (США и Канада)', id: '+1' },
  { name: '+238 (Кабо-Верде)', id: '+238' },
  { name: '+236 (Центральноафриканская Республика)', id: '+236' },
  { name: '+235 (Чад)', id: '+235' },
  { name: '+56 (Чили)', id: '+56' },
  { name: '+86 (Китай)', id: '+86' },
  { name: '+61 (Остров Рождества)', id: '+61' },
  { name: '+61 (Кокосовые острова)', id: '+61' },
  { name: '+57 (Колумбия)', id: '+57' },
  { name: '+269 (Коморы)', id: '+269' },
  { name: '+682 (Острова Кука)', id: '+682' },
  { name: '+506 (Коста-Рика)', id: '+506' },
  { name: '+385 (Хорватия)', id: '+385' },
  { name: '+53  (Куба)', id: '+53' },
  { name: '+599 (Кюрасао)', id: '+599' },
  { name: '+357 (Кипр)', id: '+357' },
  { name: '+420 (Чехия)', id: '+420' },
  { name: '+243 (ДР Конго)', id: '+243' },
  { name: '+45 (Дания)', id: '+45' },
  { name: '+253 (Джибути)', id: '+253' },
  { name: '+670 (Восточный Тимор)', id: '+670' },
  { name: '+593 (Эквадор)', id: '+593' },
  { name: '+20 (Египет)', id: '+20' },
  { name: '+503 (Сальвадор)', id: '+503' },
  { name: '+240 (Экваториальная Гвинея)', id: '+240' },
  { name: '+291 (Эритрея)', id: '+291' },
  { name: '+372 (Эстония)', id: '+372' },
  { name: '+251 (Эфиопия)', id: '+251' },
  { name: '+500 (Фолклендские острова)', id: '+500' },
  { name: '+298 (Фарерские острова)', id: '+298' },
  { name: '+679 (Фиджи)', id: '+679' },
  { name: '+358 (Финляндия)', id: '+358' },
  { name: '+33 (Франция)', id: '+33' },
  { name: '+689 (Французская Полинезия)', id: '+689' },
  { name: '+241 (Габон)', id: '+241' },
  { name: '+220 (Гамбия)', id: '+220' },
  { name: '+995 (Грузия)', id: '+995' },
  { name: '+49 (Германия)', id: '+49' },
  { name: '+233 (Гана)', id: '+233' },
  { name: '+350 (Гибралтар)', id: '+350' },
  { name: '+30 (Греция)', id: '+30' },
  { name: '+299 (Гренландия)', id: '+299' },
  { name: '+502 (Гватемала)', id: '+502' },
  { name: '+224 (Гвинея)', id: '+224' },
  { name: '+245 (Гвинея-Бисау)', id: '+245' },
  { name: '+592 (Гайана)', id: '+592' },
  { name: '+509 (Гаити)', id: '+509' },
  { name: '+504 (Гондурас)', id: '+504' },
  { name: '+852 (Гонконг)', id: '+852' },
  { name: '+36 (Венгрия)', id: '+36' },
  { name: '+354 (Исландия)', id: '+354' },
  { name: '+91 (Индия)', id: '+91' },
  { name: '+62 (Индонезия)', id: '+62' },
  { name: '+98 (Иран)', id: '+98' },
  { name: '+964 (Ирак)', id: '+964' },
  { name: '+353 (Ирландия)', id: '+353' },
  { name: '+972 (Израиль)', id: '+972' },
  { name: '+39 (Италия)', id: '+39' },
  { name: '+225 (Кот-д\'Ивуар)', id: '+225' },
  { name: '+81 (Япония)', id: '+81' },
  { name: '+962 (Иордания)', id: '+962' },
  { name: '+7 (Казахстан)', id: '+7' },
  { name: '+254 (Кения)', id: '+254' },
  { name: '+686 (Кирибати)', id: '+686' },
  { name: '+383 (Косово)', id: '+383' },
  { name: '+965 (Кувейт)', id: '+965' },
  { name: '+996 (Киргизия)', id: '+996' },
  { name: '+856 (Лаос)', id: '+856' },
  { name: '+371 (Латвия)', id: '+371' },
  { name: '+961 (Ливан)', id: '+961' },
  { name: '+266 (Лесото)', id: '+266' },
  { name: '+231 (Либерия)', id: '+231' },
  { name: '+218 (Ливия)', id: '+218' },
  { name: '+423 (Лихтенштейн)', id: '+423' },
  { name: '+370 (Литва)', id: '+370' },
  { name: '+352 (Люксембург)', id: '+352' },
  { name: '+853 (Макао)', id: '+853' },
  { name: '+389 (Македония)', id: '+389' },
  { name: '+261 (Мадагаскар)', id: '+261' },
  { name: '+265 (Малави)', id: '+265' },
  { name: '+60 (Малайзия)', id: '+60' },
  { name: '+960 (Мальдивы)', id: '+960' },
  { name: '+223 (Мали)', id: '+223' },
  { name: '+356 (Мальта)', id: '+356' },
  { name: '+692 (Маршалловы о-ва)', id: '+692' },
  { name: '+222 (Мавритания)', id: '+222' },
  { name: '+230 (Маврикий)', id: '+230' },
  { name: '+262 (Майотта)', id: '+262' },
  { name: '+52 (Мексика)', id: '+52' },
  { name: '+691 (Федеративные Штаты Микронезии)', id: '+691' },
  { name: '+373 (Молдова)', id: '+373' },
  { name: '+377 (Монако)', id: '+377' },
  { name: '+976 (Монголия)', id: '+976' },
  { name: '+382 (Черногория)', id: '+382' },
  { name: '+212 (Марокко)', id: '+212' },
  { name: '+258 (Мозамбик)', id: '+258' },
  { name: '+95 (Мьянма)', id: '+95' },
  { name: '+264 (Намибия)', id: '+264' },
  { name: '+674 (Науру)', id: '+674' },
  { name: '+977 (Непал)', id: '+977' },
  { name: '+31 (Нидерланды)', id: '+31' },
  { name: '+599 (Нидерландские Антильские острова)', id: '+599' },
  { name: '+687 (Новая Каледония)', id: '+687' },
  { name: '+64 (Новая Зеландия)', id: '+64' },
  { name: '+505 (Никарагуа)', id: '+505' },
  { name: '+227 (Нигер)', id: '+227' },
  { name: '+234 (Нигерия)', id: '+234' },
  { name: '+683 (Ниуэ)', id: '+683' },
  { name: '+850 (Корейская Народно-Демократическая Республика)', id: '+850' },
  { name: '+47 (Норвегия)', id: '+47' },
  { name: '+968 (Оман)', id: '+968' },
  { name: '+92 (Пакистан)', id: '+92' },
  { name: '+680 (Палау)', id: '+680' },
  { name: '+970 (Палестина)', id: '+970' },
  { name: '+507 (Panama)', id: '+507' },
  { name: '+675 (Папуа-Новая Гвинея)', id: '+675' },
  { name: '+595 (Парагвай)', id: '+595' },
  { name: '+51 (Перу)', id: '+51' },
  { name: '+63 (Филиппины)', id: '+63' },
  { name: '+64 (о-ва Питкэрн)', id: '+64' },
  { name: '+48 (Польша)', id: '+48' },
  { name: '+351 (Португалия)', id: '+351' },
  { name: '+974 (Катар)', id: '+974' },
  { name: '+242 (Конго)', id: '+242' },
  { name: '+262 (Реюньон)', id: '+262' },
  { name: '+40 (Румыния)', id: '+40' },
  { name: '+250 (Руанда)', id: '+250' },
  { name: '+590 (Сен-Бартелеми)', id: '+590' },
  { name: '+290 (Остров Свято́й Елены)', id: '+290' },
  { name: '+590 (Сен-Мартен)', id: '+590' },
  { name: '+508 (Сен-Пьер и Микелон)', id: '+508' },
  { name: '+685 (Самоа)', id: '+685' },
  { name: '+378 (Сан-Марино)', id: '+378' },
  { name: '+239 (Сан-Томе и Принсипи)', id: '+239' },
  { name: '+966 (Саудовская Аравия)', id: '+966' },
  { name: '+221 (Сенегал)', id: '+221' },
  { name: '+381 (Сербия)', id: '+381' },
  { name: '+248 (Сейшелы)', id: '+248' },
  { name: '+232 (Сьерра-Леоне)', id: '+232' },
  { name: '+65 (Сингапур)', id: '+65' },
  { name: '+421 (Словакия)', id: '+421' },
  { name: '+386 (Словения)', id: '+386' },
  { name: '+677 (Соломоновы о-ва)', id: '+677' },
  { name: '+252 (Сомали)', id: '+252' },
  { name: '+27 (Южная Африка)', id: '+27' },
  { name: '+82 (Южная Корея)', id: '+82' },
  { name: '+211 (Южный Судан)', id: '+211' },
  { name: '+34 (Испания)', id: '+34' },
  { name: '+94 (Шри-Ланка)', id: '+94' },
  { name: '+249 (Судан)', id: '+249' },
  { name: '+597 (Суринам)', id: '+597' },
  { name: '+47 (Шпицберген)', id: '+47' },
  { name: '+268 (Эсватини)', id: '+268' },
  { name: '+46 (Швеция)', id: '+46' },
  { name: '+41 (Швейцария)', id: '+41' },
  { name: '+963 (Сирия)', id: '+963' },
  { name: '+886 (Тайвань)', id: '+886' },
  { name: '+992 (Таджикистан)', id: '+992' },
  { name: '+255 (Танзания)', id: '+255' },
  { name: '+66 (Таиланд)', id: '+66' },
  { name: '+228 (Того)', id: '+228' },
  { name: '+690 (Токелау)', id: '+690' },
  { name: '+676 (Тонга)', id: '+676' },
  { name: '+216 (Тунис)', id: '+216' },
  { name: '+90 (Турция)', id: '+90' },
  { name: '+993 (Туркменистан)', id: '+993' },
  { name: '+688 (Тувалу)', id: '+688' },
  { name: '+256 (Уганда)', id: '+256' },
  { name: '+380 (Украина)', id: '+380' },
  { name: '+971 (Объединенные Арабские Эмираты)', id: '+971' },
  { name: '+44 (Великобритания)', id: '+44' },
  { name: '+598 (Уругвай)', id: '+598' },
  { name: '+998 (Узбекистан)', id: '+998' },
  { name: '+379 (Ватикан)', id: '+379' },
  { name: '+58 (Венесуэла)', id: '+58' },
  { name: '+84 (Вьетнам)', id: '+84' },
  { name: '+212 (Западная Сахара)', id: '+212' },
  { name: '+967 (Йемен)', id: '+967' },
  { name: '+260 (Замбия)', id: '+260' },
  { name: '+263 (Зимбабве)', id: '+263' }
];

export const helpEmail = 'help@iskra-system.ru';
export const helpPhone = {dial: '88004445075', show: '8-800-444-50-75'};

export const selectPageAmountOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

export const rusCode = '643';