import React from 'react';
import { getAdminGroupExperts, getAdminGroupCompetencyModels, getAdminGroupQuotas } from 'api';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { connect } from 'react-redux';
import DataGrid from 'components/common/DataGrid';
import { mainMenuIds, columns, extraProps } from './constants';

const initialPaging = { pageNum: 1, pageSize: 20 };

class GroupExtraInfo extends React.Component {
    state = { paging: { ...initialPaging }, data: null, sorting: null };

    fetchData = async () => {
        const { group, type, showPageLoader, showErrorAlert, hidePageLoader } = this.props;
        const { paging, sorting } = this.state;
        try {
            showPageLoader();
            const apiCall = this.getApiCall(type);
            const result = await apiCall(group.id, { paging, sorting });
            this.setState({ data: result.data });
        } catch (e) {
            showErrorAlert('Ошибка загрузки данных.');
        } finally {
            hidePageLoader();
        }
    };

    onSortedChange = (sorted) => {
        this.setState({
            sorting: { [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        });
    };

    getApiCall = type => {
        switch (type) {
            case mainMenuIds.quotas:
                return getAdminGroupQuotas;
            case mainMenuIds.experts:
                return getAdminGroupExperts;
            case mainMenuIds.competencies:
                return getAdminGroupCompetencyModels;
            default:
                return null;
        }
    };

    async componentDidUpdate(prevProps, prevState) {
        const { paging, sorting } = this.state;
        const { type } = this.props;
        if (
            type !== prevProps.type ||
            paging.pageNum !== prevState.paging.pageNum ||
            JSON.stringify(prevState.sorting) !== JSON.stringify(sorting)
        ) {
            type !== prevProps.type && (await this.setState({ paging: initialPaging, data: null, sorting:null }));
            this.fetchData();
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    onPaginate = async pageNum => {
        await this.setState({ paging: { ...this.state.paging, pageNum: pageNum + 1 } });
    };

    render() {
        const { paging, data } = this.state;
        const { type } = this.props;
        return (
            <div className="GroupExtraInfo">
                {!!data?.payload && (
                    <div className="GroupExtraInfo__Table">
                        <DataGrid
                            data={data.payload}
                            foundCount={data.meta.foundCount}
                            columns={columns[type]}
                            showPagination
                            onSortedChange={this.onSortedChange}
                            showPageSizeOptions={false}
                            pages={data.meta.pageCount}
                            page={paging.pageNum}
                            onPageChange={this.onPaginate}
                            manual
                            {...(extraProps[type] || {})}
                            pageSize={paging.pageSize}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(
    null,
    actions,
)(GroupExtraInfo);
