import React from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import SearchMenuJSON from './SearchMenuJSON';
import RenderDataCard from './renderDataCard';

const LanguagesBlock = React.memo(props => {

    const { handleStateChange } = props;

    const methods = {
        handleOneChange: (item) => {
            if(item[0] === 'language' && item[1] === null) {
                handleStateChange('languagesInfo', ["language", null]);
                handleStateChange('languagesInfo', ["languageLevel", null]);
            } else {
                handleStateChange('languagesInfo', item);
            }
        }
    }

    return (
        <RenderDataCard obj={SearchMenuJSON.LanguagesBlock} {...props} {...methods} />
    );
});

export default ToggleOpen(LanguagesBlock)
