import { useUnloadBlocker } from './useUnloadBlocker';
import PropTypes from 'prop-types';

const UnloadBlocker = props => {
    useUnloadBlocker(props.isSaving);
    return props.children;
};

UnloadBlocker.propTypes = {
    isSaving: PropTypes.bool,
};

export default UnloadBlocker;
