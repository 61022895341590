import React, { useState } from 'react';
import { connect } from 'react-redux';
import InfoField from 'components/Lk/Uikit/InfoField';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import { uploadLanguageAttachment, getLanguageAttachmentInfo, getLanguageAttachmentDownloadLink } from 'api';
import FileField from 'components/Lk/Uikit/FileField';
import classnames from 'classnames';
import NoData from './NoData';
import memoize from "fast-memoize";
import { MAX_BYTES_PER_LANG_FILE } from 'constants.js';
import { isUploadFilesSizeValid } from 'libs/validators';
import { getFileSize } from 'utils';
import { showWarningAlert } from "ducks/Alert";
import LoadingUrlBlocker from 'components/Lk/Common/LoadingUrlBlocker';
import { MENU_PROFILE_LANGUAGES } from './constants';

const LanguagesInfo = props => {
    const { person, isWrap, setWrap, onChange, isEdit, languageLevels, languages, setFileUploading, isFileUploading, setVisibleWrapSection } = props;

    const {
        languagesInfo: { knownLanguages },
    } = person;

    const { id: personId } = person.personalInfo;

    const [currentId, setCurrentId] = useState(false);

    const [currentLanguage = {}] = knownLanguages.filter(item => item.id === currentId);
    
    const languageInvalid = () => currentLanguage.language && currentLanguage.language.id && !currentLanguage.languageLevel;

    const selectLanguage = id => () => {
        setCurrentId(id);
        setWrap(true);
        setVisibleWrapSection(MENU_PROFILE_LANGUAGES);
    };

    const getFileInfo = async fileId => {
        const response = await getLanguageAttachmentInfo(personId, fileId);
        return response;
    };

    const onAdd = () => {
        const id = -new Date().getTime();
        const newRecord = { id };
        onChange(
            { ...person, languagesInfo: { knownLanguages: [...knownLanguages, newRecord] } },
            'LanguagesBlock',
        );
    };

    const onInvalidPhotoUpload = message => {
        props.showWarningAlert(message);
    };

    const onFileAdd = async ([file]) => {

        if (!isUploadFilesSizeValid([file], MAX_BYTES_PER_LANG_FILE)) {
            onInvalidPhotoUpload(
                `Размер файла не может превышать ${getFileSize(MAX_BYTES_PER_LANG_FILE)}`,
            );
            return;
        }
        
        setFileUploading(true);
        const response = await uploadLanguageAttachment(personId, file);
        setFileUploading(false);
        if (response.data) {
            changeState('fileAttachmentId', response.data.id);
        }
    };

    const getAvailableLanguages = memoize((alreadySelectedLanguages) => {
        const alreadySelectedIds = alreadySelectedLanguages.filter(x => x.language).map(x => x.language.id);
        return languages.filter(x => (currentLanguage.language && currentLanguage.language.id === x.id) || !alreadySelectedIds.includes(x.id));
    });

    const onRemove = id => e => {
        e.stopPropagation();
        onChange(
            {
                ...person,
                languagesInfo: { knownLanguages: knownLanguages.filter(a => a.id !== id) },
            },
            'LanguagesBlock',
        );
    };

    const onFileRemove = () => () => {
        changeState('fileAttachmentId', null);
    };

    const changeState = (name, value) => {
        const newRecord = { ...currentLanguage, [name]: value };

        const newRecords = knownLanguages.map(item =>
            item.id === newRecord.id ? newRecord : item,
        );
        onChange({ ...person, languagesInfo: { knownLanguages: newRecords } }, 'LanguagesBlock');
    };

    const onChangeState = name => value => changeState(name, value);
    const setLink = id => id && getLanguageAttachmentDownloadLink(personId, id);

    return (
        <div className={classnames("PersonalInfo", {'PersonalInfo--bottom-margin': isEdit})}>
            {((knownLanguages.size && knownLanguages.size !== 0) || (knownLanguages.length && knownLanguages.length !== 0)) 
                ? null
                : !isEdit && <NoData text='Нет данных' />
            }
            {isWrap && currentId !== false ? (
                <>
                    <InfoField
                        title="Язык"
                        value={currentLanguage.language && currentLanguage.language.id}
                        isEdit={isEdit}
                        inputProps={{
                            type: 'select',
                            items: getAvailableLanguages(knownLanguages),
                            onChange: onChangeState('language'),
                        }}
                        noIcon={!isEdit}
                    >
                        {currentLanguage.language && currentLanguage.language.name}
                    </InfoField>
                    <InfoField
                        title="Уровень"
                        isEdit={isEdit}
                        value={currentLanguage.languageLevel && currentLanguage.languageLevel.id}
                        inputProps={{
                            type: 'select',
                            items: languageLevels,
                            onChange: onChangeState('languageLevel'),
                        }}
                        invalid={languageInvalid()}
                        noIcon={!isEdit}
                    >
                        {currentLanguage.languageLevel && currentLanguage.languageLevel.name}
                    </InfoField>
                    <InfoField
                        key={currentLanguage.fileAttachmentId}
                        icon={
                            isEdit && !!currentLanguage.fileAttachmentId && {
                                icon: 'minus-circle',
                                size: 'xs',
                                color: 'red',
                                onClick: onFileRemove(currentLanguage.fileAttachmentId),
                            }
                        }
                        title="Подтверждающий документ"
                        noIcon={!isEdit}
                    >
                        <FileField
                            onFileAdd={onFileAdd}
                            fileId={currentLanguage.fileAttachmentId}
                            getFileInfo={getFileInfo}
                            disabled={!isEdit}
                            getLink={setLink}
                        />
                    </InfoField>
                </>
            ) : (
                <>
                    {knownLanguages.map(({ language, languageLevel, id }) => (
                        <InfoField
                            icon={
                                isEdit && {
                                    icon: 'minus-circle',
                                    size: 'xs',
                                    color: 'red',
                                    onClick: onRemove(id),
                                }
                            }
                            key={id}
                            title={languageLevel && languageLevel.name}
                            onWrap={selectLanguage(id)}
                            noIcon={!isEdit}
                        >
                            {language && language.name}
                        </InfoField>
                    ))}

                    {isEdit && (
                        <InfoField noIcon>
                            <ProfileButton onClick={onAdd}>добавить</ProfileButton>
                        </InfoField>
                    )}
                </>
            )}
            <LoadingUrlBlocker isLoading={isFileUploading} message='Выполняется загрузка файла. Пожалуйста, подождите.' />
        </div>
    );
};

const mapStateToProps = state => ({
    languageLevels: state.catalogs.languageLevels.data,
    languages: state.catalogs.languages.data,
});

const actions = {
    showWarningAlert,
};

export default connect(mapStateToProps, actions)(LanguagesInfo);
