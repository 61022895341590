import React from 'react';
import CardItemBlock from '../CardItemBlock';
import CardItemExpertData from './CardItemExpertData';
import { getUserFullName } from 'utils';
import { connect } from 'react-redux';
import './CardItemExpert.scss';

const CardItemExpert = props => {
    const { curator, useShortInfo, initAllInfo } = props;

    const expert = { ...curator, currentPosition: 'Эксперт по управлению талантами' };

    return (
        curator && (
            <div className="CardItemExpert">
                <CardItemBlock>
                    <CardItemExpertData
                        curator={expert}
                        clickable
                        isUser={true}
                        data={getUserFullName(expert)}
                        position={expert.currentPosition}
                        useShortInfo={useShortInfo}
                        initAllInfo={initAllInfo}
                    />
                </CardItemBlock>
            </div>
        )
    );
};

const mapStateToProps = ({ auth }) => ({ curator: auth.user.curator });

export default connect(mapStateToProps)(CardItemExpert);
