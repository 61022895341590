import React, { useState, useEffect } from 'react';
import { ADD_MENU_ID, teamsEditMenuItems } from './constants';
import './TeamsEditMenu.scss';

const TeamsEditMenu = props => {

    const { isOpen, handleTeamsChange, setActionSelected, onlyAddMenuItem } = props;
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    useEffect(() => {
        setIsOpenMenu(isOpen)
    }, [isOpen]);

    const handleMenuClick = (menuId) => e => {
        e.preventDefault();
        setActionSelected(menuId || false);

        handleTeamsChange(false);
        setIsOpenMenu(false);
    };

    return ( isOpenMenu && 
        <div className='LKTeamsEditMenu'>
            {teamsEditMenuItems.filter(x => !!onlyAddMenuItem ? x.id === ADD_MENU_ID : x).map(x => (
                <div key={x.id} className='LKTeamsEditMenu__MenuItem' onClick={handleMenuClick(x.id)}>
                    <div className={`LKTeamsEditMenuIcon LKTeamsEditMenuIcon__${x.id}`} />
                    <div>
                        {x.name}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TeamsEditMenu;