import React from 'react';
import Popover from 'components/Lk/Uikit/Popover';
import CheckCard from 'components/common/CheckCard';
import PersonShortInfo from 'components/Person/Teams/PersonShortInfo';
import { approveStatuses, ROLE_LEADER_ID } from 'constants.js';
import { Row, Col } from 'react-flexbox-grid';
import './ApproversSelection.scss';

const tooltip = `Выберите одного или нескольких руководителей команд для согласования индивидуального плана развития.`;
export const ApproversSelectionTitle = () => {
    return (
        <div className="ApproversSelectionTitle">
            <Popover tooltip={tooltip}>
                <div
                    className="tooltip-link"
                    data-tip
                    data-for="approversHint"
                    style={{ fontSize: 24 + 'px' }}
                >
                    ?
                </div>
            </Popover>
        </div>
    );
};

const ApproversSelection = props => {
    const { approvers, plan } = props;

    const handleChange = item => selected => {
        const { onChange, plan } = props;

        if (!!plan.approverIds) {
            selected
                ? onChange('approverIds', [item.userId])
                : onChange('approverIds', plan.approverIds.filter(x => x !== item.userId));
        }
    };

    const renderApproverItem = item => {
        if (!item.roleIds.some(x => x === ROLE_LEADER_ID)) {
            return;
        }
        const approveStatus = plan && plan.approves && Array.isArray(plan.approves) && plan.approves.length > 0 &&
            plan.approves.find(x => x.approverId === item.userId)?.status;
        const currStatusObj = approveStatuses.find(x => x.status === approveStatus);
        const currStatus = currStatusObj && currStatusObj.label;
        const checked =
            Array.isArray(props.plan.approverIds) && props.plan.approverIds.length > 0
                ? !!props.plan.approverIds.find(x => x === item.userId)
                : false;
        const disabled =
            !!!item.userId ||
            approveStatus === 'Approved' ||
            approveStatus === 'ApproveNotRequired' ||
            approveStatus === 'Rejected';
        return (
            <div className="ApproversList__Line" key={item.id}>
                <Row between="xs" middle="xs">
                    <Col xs={7}>
                        <PersonShortInfo person={item} />
                    </Col>
                    <Col xs={3}>
                        {!item.userId ? <div>Не зарегистрирован</div> : null}
                        {disabled && currStatus}
                    </Col>
                    <Col xs={1}></Col>
                    <Col xs={1}>
                        <CheckCard
                            value={item.userId || ''}
                            checked={checked}
                            onChange={handleChange(item)}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div className="ApproversList">
            {approvers && Array.isArray(approvers) && approvers.length > 0 ? (
                approvers
                    .filter((item, index, self) => index === self.findIndex(x => x.id === item.id))
                    .map(renderApproverItem)
            ) : (
                <div className="ApproversList__Line">
                    <div className="ApproversList__Text">
                        Резервист не состоит ни в одной команде.
                    </div>
                </div>
            )}
        </div>
    );
};

export default ApproversSelection;
