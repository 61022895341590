import AsyncSelect from './AsyncSelect';
import Select, { components } from './Select';
import VirtualizedSelect from './VirtualizedSelect';
import CategoryDecorator from './CategoryDecorator';
import DictionaryDecorator from './DictionaryDecorator';
import StyleDecorator from './StyleDecorator';
import IconOption from './IconOption';

const StyledSelect = StyleDecorator(Select);
const StyledAsyncSelect = StyleDecorator(AsyncSelect);
const StyledVirtualizedSelect = StyleDecorator(VirtualizedSelect);
const CategorySelect = CategoryDecorator(StyledSelect);
const CategoryVirtualizedSelect = CategoryDecorator(StyledVirtualizedSelect);
const DictionarySelect = DictionaryDecorator(StyledSelect);
const DictionaryVirtualizedSelect = DictionaryDecorator(StyledVirtualizedSelect);

export {
    CategorySelect as default,
    CategoryVirtualizedSelect as VirtualizedSelect,
    DictionarySelect,
    DictionaryVirtualizedSelect,
    StyledSelect as Select,
    StyledAsyncSelect as AsyncSelect,
    components,
    IconOption,
};
