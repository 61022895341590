import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from './Avatar';
import Info from './Info';
import Title from './Title';
import InfoText from './InfoText';
import Comments from './Comments';
import Menu from './Menu';
import Roles from './Roles';
import Action from './Action';
import './PersonCard.scss';

export default class PersonCard extends Component {
    static CardAvatar = Avatar;
    static CardInfo = Info;
    static CardTitle = Title;
    static CardInfoText = InfoText;
    static CardComments = Comments;
    static CardMenu = Menu;
    static CardRoles = Roles;
    static CardAction = Action;

    render() {
        const { onClick, withWrapper } = this.props;
        return (
            <div
                className={classnames('LKPersonCard', {
                    'LKPersonCard--clickable': onClick,
                    'LKPersonCard--wrapped': withWrapper,
                })}
                onClick={onClick}
            >
                {this.props.children}
            </div>
        );
    }
}

PersonCard.defaultProps = {
    withWrapper: true,
};

PersonCard.propTypes = {
    onClick: PropTypes.func,
};
