import React from 'react';
import PropTypes from 'prop-types';

export default Component => class CheckAccess extends React.Component {
    static propTypes = {
        allowedRoles: PropTypes.arrayOf(PropTypes.string),
        userRoles: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {
        allowedRoles: [],
        userRoles: [],
    };

    isAllowed = () =>
        this.props.userRoles.some(role => this.props.allowedRoles.includes(role.name));

    render() {
        const newProps = {...this.props};
        delete newProps.allowedRoles;
        delete newProps.userRoles;
        
        return (
            <Component {...newProps} isAllowed={this.isAllowed()} />
        );
    }
}