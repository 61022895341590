import React, { Fragment } from 'react';
import { DictionarySelect } from 'components/uikit/Select';
import Field from 'components/uikit/Field';
import { operationTypes, conditionTypes } from 'enums';
import CheckField from 'components/uikit/CheckField';
import Fields from './Fields';

export const ConditionTable = props => {
    const { data, onChange } = props;

    const changeRecord = (field, item, value) => onChange({ ...item, [field]: value });
    const onItemChange = (field, item) => value => changeRecord(field, item, value);

    const menuPortal = document.body;
    const menuStyle = { menuPortal: base => ({ ...base, zIndex: 9999 }) };

    const renderConditionList = () => {
        return data.map(x => {
            return (
                <Fragment key={x.id}>
                    <div className="Line">
                        <div className="Col-30">
                            <CheckField
                                checked={x.active}
                                onChange={onItemChange('active', x)}
                                title={x.name}
                            />
                        </div>
                        <div className="Col-30 Col-30--right">
                            <DictionarySelect
                                isDisabled={!x.active}
                                options={operationTypes}
                                value={x.operation}
                                styles={menuStyle}
                                menuPortalTarget={menuPortal}
                                onChange={onItemChange('operation', x)}
                            />
                        </div>
                        <div className="Col-40 Col-40--right">
                            <Field required>
                                <Fields
                                    {...x}
                                    disabled={!x.active}
                                    onChange={onItemChange('value', x)}
                                />
                            </Field>
                        </div>
                    </div>
                    <div className="Line" key={`cond-${x.id}`}>
                        <div className="Line__Logic ">
                            <DictionarySelect
                                isDisabled={!x.active}
                                value={x.condition}
                                options={conditionTypes}
                                styles={menuStyle}
                                menuPortalTarget={menuPortal}
                                onChange={onItemChange('condition', x)}
                            />
                        </div>
                    </div>
                </Fragment>
            );
        });
    };

    const renderTable = (
        <div className="Condition-List">
            <div className="Condition-List__Header">
                <div className="Col-30">Поле</div>
                <div className="Col-30">Условие</div>
                <div className="Col-40 Col-40--right">Значение</div>
            </div>
            <div className="Condition-List__Body Condition-List__Body--no-footer">
                {renderConditionList()}
            </div>
        </div>
    );

    return (
        <div className="Condition-Container">
            <div className="Condition-Container__Body">{renderTable}</div>
        </div>
    );
};

export default ConditionTable;
