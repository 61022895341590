import React, { useState } from 'react';
import classnames from 'classnames';

const StickyFooter = (props) => {
    let { unstick } = props;
    const [height, setHeight] = useState(0);

    const getHeight = (element) => {
        if (element && element.clientHeight !== height) {
            setHeight(element.clientHeight);
        }
    };

    return (
        <>
            <div
                className={classnames('StickyFooter__Phantom', {
                    'StickyFooter__Phantom--unstick': unstick,
                })}
                style={{ minHeight: height }}
            />
            <div
                ref={getHeight}
                className={classnames('StickyFooter', { 'StickyFooter--unstick': unstick })}
            >
                {props.children}
            </div>
        </>
    );
};
export default StickyFooter;
