import React from 'react';
import Dropzone from 'react-dropzone';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import classnames from 'classnames';
import { getIssueFileUrl } from 'api';
import { isUploadFilesCountValid, isUploadFilesSizeValid } from 'libs/validators';
import { MAX_UPLOAD_FILES } from 'constants.js';
import { MAX_BYTES_PER_FILE } from 'constants.js';
import { showWarningAlert } from 'ducks/Alert';
import { getFileSize } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IssueFile = props => {
    const { files = [], onChange, isEdit, issueId } = props;

    const onAdd = async uploadFiles => {
        if (
            !isUploadFilesCountValid(
                uploadFiles.length,
                files.filter(x => x.isNew).length,
                MAX_UPLOAD_FILES,
            )
        ) {
            return showWarningAlert(
                `Максимальное количество файлов для загрузки: ${MAX_UPLOAD_FILES}`,
            );
        }

        if (!isUploadFilesSizeValid(uploadFiles, MAX_BYTES_PER_FILE)) {
            return showWarningAlert(
                `Размер одного файла не должен превышать ${getFileSize(MAX_BYTES_PER_FILE)}`,
            );
        }

        const newRecords = uploadFiles.map((file, ind) => ({
            id: -new Date().getTime() - ind,
            fileName: file.name,
            mimeType: file.mimeType,
            size: file.size,
            isNew: true,
            url: window.URL.createObjectURL(file),
            file,
        }));

        onChange(files.concat(newRecords));
    };

    const getLink = fileId => getIssueFileUrl(fileId);

    const onRemove = id => () => {
        const newFiles = files
            .map(x => (x.id === id ? { ...x, deleted: true } : x))
            .filter(x => !(x.deleted && x.isNew));

        onChange(newFiles);
    };

    const renderDropZone = ({ getRootProps, getInputProps, isDragActive }) => (
        <div
            {...getRootProps()}
            className={classnames('dropzone', {
                'dropzone--isActive': isDragActive,
            })}
        >
            <input {...getInputProps()} />
            <ProfileButton dataTest="IssueFileAdd--button">Добавить</ProfileButton>
        </div>
    );
    if (files.length === 0 && !isEdit) {
        return null;
    }
    return (
        <div>
            <div className="IssueFile">
                {files
                    .filter(x => !x.deleted)
                    .map(item => (
                        <div key={item.id} data-test="IssueFile" className="IssueFile__Item">
                            {isEdit && (!issueId || !item.issueId  || issueId === item.issueId ) && (
                                <span
                                    onClick={onRemove(item.id)}
                                    data-test={`IssueFileRemove--button id_${item.id}`}
                                >
                                    <FontAwesomeIcon
                                        icon="minus-circle"
                                        size="xs"
                                        color="red"
                                        className="IssueFile__RemoveIcon"
                                    />
                                </span>
                            )}

                            <a
                                data-test={`IssueFile--link id_${item.id}`}
                                href={item.isNew ? item.url : getLink(item.id)}
                                download={item.fileName}
                            >
                                {item.fileName}
                            </a>
                        </div>
                    ))}
            </div>

            {isEdit && <Dropzone onDrop={onAdd}>{props.renderDropZone || renderDropZone}</Dropzone>}
        </div>
    );
};

export default IssueFile;
