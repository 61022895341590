import React from 'react';
import Label from 'components/uikit/Label';
import Select from 'components/uikit/Select';
import Popover from 'components/Lk/Uikit/Popover';
import './PersonGrowthEndDate.scss';
import { showErrorAlert } from 'ducks/Alert';
import { connect } from 'react-redux';
import { getMonths, getYears } from 'utils';

const optionsMonths = getMonths();
const availableOptionsMonths = getMonths().filter(x => x.value >= new Date().getMonth());
const optionsYears = getYears(new Date().getFullYear(), 5);

const tooltip = `Рекомендуемый срок реализации цели – 6 месяцев или 1 год.`;

export const PersonGrowthEndTitle = () => {
    return (
        <div className="evolutionEndDate__container__title">
            <Popover tooltip={tooltip}>
                <div
                    className="tooltip-link"
                    data-tip
                    data-for="socialActivityHint"
                    style={{ fontSize: 24 + 'px' }}
                >
                    ?
                </div>
            </Popover>
        </div>
    );
};

const PersonGrowthEndDate = ({ end_year, end_month, onChange }) => {
    return (
        <div className="evolutionEndDate">
            <div className="evolutionEndDate__container">
                <div className="evolutionEndDate__container__row">
                    <div className="evolutionEndDate__container__row__item">
                        <Label htmlFor="endDateYear" className="evolutionEndDate__label">
                            Год
                        </Label>
                        <Select
                            className="evolutionEndDate__container__input"
                            value={optionsYears.find(option => option.value === end_year)}
                            inputId="endDateYear"
                            placeholder={<div>Выберите год</div>}
                            options={optionsYears}
                            onChange={e => onChange('end_year', e.value)}
                        />
                    </div>
                </div>
                <div className="evolutionEndDate__container__row">
                    <div className="evolutionEndDate__container__row__item">
                        <Label htmlFor="endDateMonth" className="evolutionEndDate__label">
                            Месяц
                        </Label>
                        <Select
                            value={optionsMonths.find(option => option.value === end_month)}
                            inputId="endDateMonth"
                            placeholder={<div>Выберите месяц</div>}
                            options={availableOptionsMonths}
                            onChange={e => onChange('end_month', e.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const actions = { showErrorAlert };

export default connect(
    null,
    actions,
)(PersonGrowthEndDate);
