import React from 'react';
import PropTypes from 'prop-types';

const Action = props => {
    const { icon, onClick } = props;
    return <div className={`LKPersonCardAction LKPersonCardAction__${icon}`} onClick={onClick} />;
};

Action.propTypes = {
    icon: PropTypes.oneOf(["move", "delete"]).isRequired,
    onClick: PropTypes.func,
};

export default Action;
